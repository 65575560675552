import { isEmpty } from 'lodash';
import { Box, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useRef } from 'react';
import PropTypes from 'prop-types';

import PatientExternalCareGapDetails from '@/pages/TaskBasedWorkflow/components/patientDetailView/actionCard/PatientExternalCareGapDetails';
import useDismissExternalUpdates from '@/pages/TaskBasedWorkflow/hooks/useDismissExternalUpdates';
import queryKeyFactory from '@/pages/TaskBasedWorkflow/queryKeyFactory';
import { externalCareGapRecentActivityShape } from '@/utils/transformExternalCareGapRecentActivity';
import { actionShape } from '@/utils/transformFrontendAction';

function normalizeLatestExternalCareGap(latest) {
  return {
    externalCareGapId: latest.externalCareGapId,
    externalHistoryStatus: latest.status,
    cptCodes: latest.cptCodes,
    providerName: latest.userName,
    dateOfService: latest.dateOfService,
    recordedAt: latest.recordedAt,
    sourceType: latest.sourceType,
    externalCareGapStatus: 'dismissed',
    statusUpdatedBy: undefined,
    statusUpdatedAt: new Date().toISOString(),
  };
}

export default function PatientExternalCareGapPanel({
  action,
  externalCareGapRecentActivity,
  addDismissedExternalCareGaps,
  setNotesAndRecentActivityExpanded,
}) {
  const latestExternalCareGap =
    action.v2Dto?.contextualInfo?.latestExternalCareGap;
  const queryClient = useQueryClient();
  const showPanel = useRef(true);
  const onDismissSuccess = () => {
    queryClient.invalidateQueries(queryKeyFactory.taskCounts());
    showPanel.current = false;
  };

  const { mutate, reset } = useDismissExternalUpdates({
    onSuccess: onDismissSuccess,
  });

  const onDismiss = () => {
    reset();
    mutate({
      actionId: action.id,
      externalCareGapId: latestExternalCareGap.externalCareGapId,
    });
    addDismissedExternalCareGaps([
      normalizeLatestExternalCareGap(latestExternalCareGap),
      ...externalCareGapRecentActivity,
    ]);
    setNotesAndRecentActivityExpanded(true);
  };

  return !isEmpty(latestExternalCareGap) && showPanel.current ? (
    <Box>
      <PatientExternalCareGapDetails
        latestExternalCareGap={latestExternalCareGap}
      />
      <IconButton
        aria-label="close"
        color="primary"
        size="small"
        sx={{ mt: 1, ml: 1 }}
        onClick={onDismiss}
      >
        <CloseIcon fontSize="inherit" />{' '}
        <Typography variant="bodymedium" sx={{ ml: 1 }}>
          Dismiss updates from EHR
        </Typography>
      </IconButton>
    </Box>
  ) : null;
}

PatientExternalCareGapPanel.propTypes = {
  action: actionShape.isRequired,
  externalCareGapRecentActivity: PropTypes.arrayOf(
    externalCareGapRecentActivityShape,
  ),
  addDismissedExternalCareGaps: PropTypes.func,
  setNotesAndRecentActivityExpanded: PropTypes.func,
};

PatientExternalCareGapPanel.defaultProps = {
  externalCareGapRecentActivity: [],
  addDismissedExternalCareGaps: () => {},
  setNotesAndRecentActivityExpanded: () => {},
};
