/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import useId from '@mui/utils/useId';
import { forwardRef } from 'react';

import {
  inputDisabledStyles,
  inputFocus,
  inputHoverStyles,
  inputStyles,
} from '@/themeV2/components';
import palette from '@/themeV2/palette';

const BaseTextarea = forwardRef(function BaseTextarea(
  {
    'data-dd-synthetics-id': dataDdSyntheticsId,
    'data-pendo-id': dataPendoId,
    autoFocus,
    className,
    color,
    disabled,
    error,
    fullWidth,
    id: idOverride,
    maxRows,
    minRows,
    name,
    onBlur,
    onChange,
    placeholder,
    sx,
    TextareaProps,
    value,
  },
  ref,
) {
  const id = useId(idOverride);
  const maxLength = TextareaProps && TextareaProps.maxLength;
  const shouldDisplayCharacterCounter = maxLength && value !== undefined;

  return (
    <Box
      className={className}
      sx={sx}
      ref={ref}
      fullwidth={fullWidth}
      display={fullWidth ? undefined : 'inline-block'}
    >
      {shouldDisplayCharacterCounter ? (
        <Box sx={{ pl: 1 }} textAlign="right">
          {value.length}/{maxLength}
        </Box>
      ) : null}
      <StyledTextarea
        {...TextareaProps}
        $color={color}
        $error={error}
        $fullWidth={fullWidth}
        autoFocus={autoFocus}
        data-dd-synthetics-id={dataDdSyntheticsId}
        data-pendo-id={dataPendoId}
        disabled={disabled}
        id={id}
        maxRows={maxRows}
        minRows={minRows}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    </Box>
  );
});

const StyledTextarea = styled(TextareaAutosize)((props) => ({
  fontFamily: 'Avenir, sans-serif',
  width: '270px',
  height: '72px',
  ...inputStyles,
  ...inputHoverStyles,
  ':hover': { ...(props.disabled && inputDisabledStyles) },
  ':focus': inputFocus,
  ':active': {
    ...inputFocus,
    ...(props.disabled && inputDisabledStyles),
  },
  ...(props.$color === 'success' && {
    boxShadow: `0 0 0 1px ${palette.status.successForeground}`,
  }),
  ...(props.$error && {
    boxShadow: `0 0 0 1px ${palette.status.errorForeground}`,
  }),
  ...(props.$fullWidth && {
    width: '100%',
  }),
  ...(props.disabled && inputDisabledStyles),
  '&::placeholder': {
    color: palette.text.placeholder,
  },
}));

BaseTextarea.propTypes = {
  'data-dd-synthetics-id': PropTypes.string,
  'data-pendo-id': PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  sx: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  TextareaProps: PropTypes.shape({
    maxLength: PropTypes.number,
  }),
  value: PropTypes.string,
};

BaseTextarea.defaultProps = {
  'data-dd-synthetics-id': undefined,
  'data-pendo-id': undefined,
  autoFocus: false,
  className: undefined,
  color: undefined,
  disabled: false,
  error: false,
  fullWidth: false,
  id: undefined,
  maxRows: undefined,
  minRows: 4,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  placeholder: undefined,
  sx: undefined,
  TextareaProps: undefined,
  value: undefined,
};

export default BaseTextarea;
