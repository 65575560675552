import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import queryKeyFactory from '../queryKeyFactory';

function transformHistory(history) {
  return {
    daysSupply: history.days_supply,
    drugName: history.drug_name,
    fromDate: history.from_date,
    pharmacy: history.pharmacy,
    prescriber: history.prescriber,
  };
}

function transformResponse(data) {
  return data.history.map(transformHistory);
}

async function fetchPrescriptionHistory({ patientId, actionId }) {
  const response = await axios.get(
    `/api/tbw/prescription_history/${patientId}/${actionId}`,
  );

  return response.data;
}

export default function useGetPrescriptionHistory({
  patientId,
  actionId,
  enabled,
}) {
  const params = { patientId, actionId };

  return useQuery(
    queryKeyFactory.prescriptionHistory(params),
    () => fetchPrescriptionHistory(params),
    {
      enabled,
      select: transformResponse,
    },
  );
}
