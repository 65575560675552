/**
 * A wrapper for history.pushState().
 * @param {string|URL} url
 * @param {object} [state]
 */
function push(url, state) {
  window.history.pushState(state, '', url);
}

/**
 * @param {string|URL} url
 * @param {object} [state]
 */
function replace(url, state) {
  window.history.replaceState(state, '', url);
}

/**
 * @param {function} listener
 * @returns A function to remove the listener
 */
function addPopListener(listener) {
  const handlePopState = (e) => {
    listener({
      ...e,
      state: e.state || {},
    });
  };
  window.addEventListener('popstate', handlePopState);
  const unlisten = () => {
    window.removeEventListener('popstate', handlePopState);
  };
  return unlisten;
}

/**
 * A wrapper for history.back()
 */
function back() {
  window.history.back();
}

/**
 * A wrapper for history.forward()
 */
function forward() {
  window.history.forward();
}

/**
 * A wrapper for location.reload()
 */
function reload() {
  window.location.reload();
}

/**
 * Returns the current URL
 * @returns {URL}
 */
function getCurrentLocation() {
  return new URL(window.location);
}

/**
 * @typedef HistoryUtils
 * @property {(fn: function) => () => void} addPopListener
 * @property {() => void} back
 * @property {() => void} forward
 * @property {() => URL} getCurrentLocation
 * @property {(url: string|URL, state: object) => void} push
 * @property {(url: string|URL, state: object) => void} replace
 * @property {() => void} reload
 */

const history = {
  addPopListener,
  back,
  forward,
  getCurrentLocation,
  push,
  reload,
  replace,
  get state() {
    return window.history.state || {};
  },
};

export default history;
