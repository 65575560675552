export const totalFormsKey = 'form-TOTAL_FORMS';
export const pcpVisitDateOfServiceKey = 'pcp_visit_date_of_service';
export const pcpVisitServicingProviderKey = 'pcp_visit_servicing_provider';
export const servicingProviderKey = 'servicing_provider';
export const userAddedDiagCodesKey = 'userAddedDiagnosisCodes';
export const correlationUidKey = 'correlation_uid';
export const updatedFileNameKey = 'updatedFileName';
export const originalFilenameKey = 'chart_provider_filenamename';
export const systemFilenameKey = 'chartupload_system_filename';
export const dateOfServiceOfChartUpload = 'date_of_service_of_chart_upload';
export const textKey = 'text';
export const tagsKey = 'tags';
export const headlineKey = 'headline';

export const OTHER_ICD_SPECIFIED = 'otherIcdSpecified';
export const CONDITION_INCORRECT = 'conditionIncorrect';
export const CONDITION_NOT_PRESENT = 'conditionNotPresent';
export const COMPLETE_WITHOUT_CODE = 'completeWithoutCode';
export const SUPERSEDED = 'superseded';

export const URL_DATE_FORMAT = 'MM/dd/yyyy';
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';

// Due to non-breaking requirements in ModalDatePicker, these are non-breaking hyphens and spaces
// If you edit these strings, be extremely careful about maintaining them!
export const DISPLAY_DATE_FORMAT = 'MM‑dd‑yyyy';
export const DISPLAY_DATE_TIME_FORMAT = 'MM‑dd‑yyyy h:mm bbbb';

export const ACTION_SUBTYPE_ANNUAL_CARE_VISIT = 'annual_care_visit';
export const DIAGNOSIS_ACTION_SUBTYPE_RECAPTURE = 'recapture';
export const DIAGNOSIS_ACTION_SUBTYPE_SUGGESTION = 'suggestion';
export const STATE_COMPLETE = 'complete';
export const STATE_INCOMPLETE = 'incomplete';
export const STATE_SKIPPED = 'skipped';

export const CHECK = '✓';
export const RIGHT_ARROW = '→';

export const ActionType = {
  diagnosis: 'diagnosis',
  medication: 'medication',
  quality: 'quality',
} as const;

export const ShowDiagnosisActionHistorySetting = {
  off: 'OFF',
  awaitingClaim: 'AWAITING_CLAIM',
  completed: 'COMPLETED',
} as const;

export const DiagnosisActionState = {
  complete: STATE_COMPLETE,
  incomplete: STATE_INCOMPLETE,
  notPresent: 'not_present',
  notEvaluated: 'not_evaluated',
  diagnosisIncorrect: 'diagnosis_incorrect',
  diagnosisConfirmedByData: 'diagnosis_confirmed_by_data', // Confirmed by Claim
  diagnosisIcdCodeEntered: 'diagnosis_icd_code_entered',
} as const;

export const CareGapsActionState = {
  complete: STATE_COMPLETE,
  incomplete: STATE_INCOMPLETE,
  scheduled: 'scheduled',
  rescheduled: 'rescheduled',
  addressed: 'addressed',
} as const;
