import PropTypes from 'prop-types';
import { Input } from '@mui/material';
import { useDebounce } from 'react-use';
import { useRef, useState } from 'react';

import { ReactComponent as MagnifyingGlassIcon } from '@/assets/icons/magnifyingGlass.svg';

export default function SearchTextField({
  'data-pendo-id': dataPendoId,
  debounceTimeout,
  debouncedOnChange,
  initialValue,
  placeholder,
  sx,
  width,
}) {
  const [value, setValue] = useState(initialValue);
  const isInitialized = useRef(false);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useDebounce(
    () => {
      if (isInitialized.current) {
        debouncedOnChange(value);
      } else {
        isInitialized.current = true;
      }
    },
    debounceTimeout,
    [value],
  );

  return (
    <Input
      inputProps={{
        'aria-label': placeholder,
        'data-pendo-id': dataPendoId,
      }}
      type="search"
      value={value}
      onChange={handleChange}
      startAdornment={
        <MagnifyingGlassIcon
          style={{ width: '16px', height: '16px', marginRight: '6px' }}
        />
      }
      sx={[{ py: 0.5, px: 1, width }, ...(Array.isArray(sx) ? sx : [sx])]}
      placeholder={placeholder}
    />
  );
}

SearchTextField.propTypes = {
  'data-pendo-id': PropTypes.string,
  debounceTimeout: PropTypes.number,
  debouncedOnChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  width: PropTypes.string,
};

SearchTextField.defaultProps = {
  'data-pendo-id': undefined,
  debounceTimeout: 250,
  initialValue: '',
  placeholder: 'Search patients',
  sx: undefined,
  width: '156px',
};
