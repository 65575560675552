import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

async function updateFlag({ taskId, flagReason, note }) {
  return axios.post(`/api/tbw/flags/${taskId}/update`, {
    flag_input: {
      state: flagReason,
      note,
    },
  });
}

export default function useUpdateFlag({ onSuccess = undefined } = {}) {
  return useMutation(updateFlag, {
    onSuccess,
  });
}
