import { number, object } from 'prop-types';
import { Box, Typography } from '@mui/material';

import { computeDifficulties } from '@/pages/Homepage/components/goalsWidget/utils';

function getGoalCountProgressColor(percentCompleted, difficulties) {
  if (percentCompleted === 0) {
    return 'text.placeholder';
  }
  if (percentCompleted >= difficulties.easy) {
    return 'status.successForeground';
  }
  if (percentCompleted >= difficulties.medium) {
    return 'colorPool.yellow.600';
  }
  return 'colorPool.red.800';
}

export default function CountOfTotalCompleted({
  percentCompleted,
  completed,
  total,
  sx,
}) {
  const difficulties = computeDifficulties(new Date());
  return (
    <Box sx={sx}>
      <Typography
        component="span"
        color={getGoalCountProgressColor(percentCompleted, difficulties)}
        variant="metricsmall"
      >
        {completed}
      </Typography>
      <Typography
        component="span"
        variant="metricsmall"
      >{` / ${total}`}</Typography>
      <Typography variant="bodysmall" component="span">
        {' '}
        <Box whiteSpace="nowrap" component="span">
          actions completed
        </Box>
      </Typography>
    </Box>
  );
}

CountOfTotalCompleted.propTypes = {
  completed: number.isRequired,
  total: number.isRequired,
  percentCompleted: number.isRequired,
  sx: object,
};

CountOfTotalCompleted.defaultProps = {
  sx: undefined,
};
