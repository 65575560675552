import { Typography, styled } from '@mui/material';
import { format, isBefore, isValid, parse } from 'date-fns';

import { actionShape } from '@/utils/transformFrontendAction';
import useDate from '@/hooks/useDate';
import useFeatureFlag from '@/utils/useFeatureFlag';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';

function getDateOfServiceForAction(
  action,
  contextualInfo,
  today,
  autoCloseAVsEnabled = false,
) {
  const externalDateOfService =
    contextualInfo?.latestExternalCareGap?.dateOfService;
  if (autoCloseAVsEnabled && externalDateOfService) {
    return parse(externalDateOfService, 'yyyy-MM-dd', today);
  }
  return parse(action.v2Dto.dateOfService, 'MM-dd-yyyy', today);
}

export default function AppointmentDate({ action }) {
  const today = useDate();
  const autoCloseAVsEnabled = useFeatureFlag('autoclose_avs');
  const { data: contextualInfo, isLoading } = useGetContextualInfo(action);

  if (isLoading) {
    return <FieldSkeleton />;
  }

  const date = getDateOfServiceForAction(
    action,
    contextualInfo,
    today,
    autoCloseAVsEnabled,
  );

  return (
    <Date>
      {isValid(date) ? (
        <Typography
          component="span"
          variant={isBefore(date, today) ? 'bodymediumbold' : 'bodymedium'}
        >
          {format(date, 'MM-dd-yyyy')}
        </Typography>
      ) : (
        'Not scheduled'
      )}
    </Date>
  );
}

AppointmentDate.propTypes = {
  action: actionShape.isRequired,
};

const Date = styled('span')`
  white-space: nowrap;
`;
