import PropTypes from 'prop-types';

import { patientShape } from '@/utils/transformFrontendPatient';

import PriorityScoreBadge from './PriorityScoreBadge';

export default function PriorityBadge({ patient, abbreviated }) {
  // userInputTasks here should be tasks limited to the page configuration
  const highestPriorityTask = patient.userInputTasks.reduce((prev, current) =>
    prev.score > current.score ? prev : current,
  );
  return (
    <PriorityScoreBadge
      abbreviated={abbreviated}
      score={highestPriorityTask.score}
    />
  );
}

PriorityBadge.propTypes = {
  // abbreviated will cause only the first letter of the priority to render.
  abbreviated: PropTypes.bool,
  patient: patientShape.isRequired,
};

PriorityBadge.defaultProps = {
  abbreviated: false,
};
