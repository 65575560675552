import DynamicFormTextarea from './DynamicFormTextarea';

export default function DynamicFormTextareaV2(props) {
  return DynamicFormTextarea({
    ...props,
    fullWidth: true,
    maxRows: 1,
    placeholder: 'Enter note',
  });
}
