import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart, LinearScale } from 'chart.js';
import { format, parseISO } from 'date-fns';
import { get } from 'lodash';

Chart.register(CategoryScale, LinearScale, BarElement);

export const MONTHS_TO_SHOW = 6;

export default function SvuHistoryChart({ months, color }) {
  const theme = useTheme();
  const labels = months.map((month) =>
    format(parseISO(month.startDate), 'MMM'),
  );
  const values = months.map((month) => month.totalValue);

  const weeklyChartData = {
    // We need as many labels as bars
    labels,
    datasets: [
      {
        label: 'label',
        backgroundColor: Array(months.length).fill(get(theme.palette, color)),
        data: values,
      },
    ],
  };

  const weeklyChartOptions = {
    barThickness: 8,
    maxBarThickness: 8,
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    hover: {
      mode: false,
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: {
            weight: (context) =>
              context.index === MONTHS_TO_SHOW - 1 ? 'bold' : 'normal',
          },
        },
      },
      y: {
        position: 'right',
        grid: {
          borderWidth: 0,
          color: theme.palette.border.base,
        },
        ticks: {
          crossAlign: 'far',
          color: theme.palette.text.secondary,
          font: {
            size: 8,
            color: theme.palette.text.secondary,
          },
        },

        // Make sure the top of the chart is padded up a bit, so we never cut
        // off the label at the top of the highest bar. Additionally, round it
        // so we don't end up with a weird floating point.
        suggestedMax: Math.ceil(Math.max(...values) * 1.2),
      },
    },
  };

  return (
    <Box
      height={164}
      width={220}
      display="inline-block"
      pt={2}
      role="figure"
      aria-label="Svu History"
    >
      <Bar options={weeklyChartOptions} data={weeklyChartData} />
    </Box>
  );
}

SvuHistoryChart.propTypes = {
  color: PropTypes.string.isRequired,
  months: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
};
