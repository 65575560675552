import { createContext, useContext } from 'react';
import axios from 'axios';

const AxiosContext = createContext(axios);

/**
 * Used to late-bind the axios value, so that we can more-easily mock its
 * implementation in unit tests.
 *
 * @see useGetActions.tests.jsx
 * @returns {AxiosStatic}
 */
export default function useAxios() {
  return useContext(AxiosContext);
}

export const AxiosProvider = AxiosContext.Provider;
