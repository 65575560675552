import { Box, Typography } from '@mui/material';
import { number } from 'prop-types';

function SvuDisplay({ incompleteSvus, completeSvus }) {
  return (
    <Box sx={{ display: 'flex' }}>
      {incompleteSvus > 0 ? (
        <Box
          sx={{
            backgroundColor: 'svu.lightest',
            px: 1,
            // This seems overly complex, but setting borderRadius on the
            // parent element didn't have the right effect
            borderRadius: completeSvus > 0 ? '4px 0 0 4px' : 1,
            color: 'svu.dark',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography variant="bodysmall">SVUs Available</Typography>
          <Typography variant="bodybold" sx={{ textAlign: 'right' }}>
            {incompleteSvus}
          </Typography>
        </Box>
      ) : null}
      {completeSvus > 0 ? (
        <Box
          sx={{
            backgroundColor: 'status.successBackground',
            px: 1,
            borderRadius: incompleteSvus > 0 ? '0 4px 4px 0' : 1,
            color: 'status.successForeground',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography variant="bodysmall">Earned</Typography>
          <Typography variant="bodybold" sx={{ textAlign: 'right' }}>
            {completeSvus}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}

SvuDisplay.propTypes = {
  completeSvus: number,
  incompleteSvus: number,
};
SvuDisplay.defaultProps = {
  completeSvus: 0,
  incompleteSvus: 0,
};

export default SvuDisplay;
