import {
  Box,
  Paper,
  ThemeProvider,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { createElement } from 'react';
import PropTypes from 'prop-types';

import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';
import themeV2 from '@/themeV2';

import DynamicFormTextareaV2 from './DynamicForm/fieldComponents/DynamicFormTextareaV2';
import DynamicForm from './DynamicForm/DynamicForm';
import DynamicFormFields from './DynamicForm/DynamicFormFields';
import DynamicFormButtons from './DynamicForm/DynamicFormButtons';
import DynamicFormRadioGroup from './DynamicForm/fieldComponents/DynamicFormRadioGroup';

function fieldComponentMapper(field) {
  switch (field.type) {
    case 'text':
    case 'textarea':
      return DynamicFormTextareaV2;
    case 'radiogroup':
    case 'radio':
      return DynamicFormRadioGroup;
    default:
      // Fall back to the default field component
      return null;
  }
}

const WidgetZIndex = {
  inactive: 0,
  active: 1,
};

export default function BaseFormWidget({
  alert,
  cancelButtonPendoId,
  IconComponent,
  iconAriaLabel,
  onCancel,
  onIconClick,
  onSubmit,
  iconPendoId,
  iconPrimaryActiveColor,
  iconSecondaryActiveColor,
  lockIconActiveColor,
  submitButtonLabel,
  submitButtonPendoId,
  isLoading,
  title,
  fields,
  showPopover,
}) {
  const theme = useTheme();

  return (
    <ThemeProvider theme={themeV2}>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          pl: 1,
        }}
      >
        {showPopover && (
          <Popover aria-label="popover" sx={{ textAlign: 'left' }}>
            <DynamicForm
              onSubmit={onSubmit}
              defaultValues={{ bulkRemove: true }}
              mode="onChange"
            >
              <Box
                sx={{
                  p: 3,
                  borderBottom: '1px solid',
                  borderBottomColor: 'stroke.light',
                }}
              >
                <Typography variant="h4">{title}</Typography>

                {alert ? <Box mt={1.5}>{alert}</Box> : null}

                <Box mt={3}>
                  <DynamicFormFields
                    fieldComponentMapper={fieldComponentMapper}
                    fields={fields}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  bgcolor: 'background.secondary',
                }}
              >
                <DynamicFormButtons
                  cancelButtonPendoId={cancelButtonPendoId}
                  submitButtonPendoId={submitButtonPendoId}
                  submitButtonLabel={submitButtonLabel}
                  onCancel={onCancel}
                  ButtonSx={{
                    margin: theme.spacing(2, 3),
                  }}
                  isLoading={isLoading}
                />
              </Box>
            </DynamicForm>
          </Popover>
        )}

        <button
          aria-label={iconAriaLabel}
          data-pendo-id={iconPendoId}
          onClick={onIconClick}
          type="button"
        >
          <StyledIcon
            $active={showPopover}
            $lockIconActiveColor={lockIconActiveColor}
            $iconPrimaryActiveColor={iconPrimaryActiveColor}
            $iconSecondaryActiveColor={iconSecondaryActiveColor}
            IconComponent={IconComponent}
          />
        </button>
      </Box>
    </ThemeProvider>
  );
}

BaseFormWidget.propTypes = {
  alert: PropTypes.node,
  // an SVG colored with "currentColor" (i.e. `fill="currentColor"`, `stroke="currentColor"`)
  IconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
    .isRequired,
  iconAriaLabel: PropTypes.string.isRequired,
  cancelButtonPendoId: PropTypes.string,
  onCancel: PropTypes.func,
  onIconClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  iconPendoId: PropTypes.string,
  iconPrimaryActiveColor: PropTypes.string,
  iconSecondaryActiveColor: PropTypes.string,
  lockIconActiveColor: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  submitButtonPendoId: PropTypes.string,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(executionRequirementShape).isRequired,
  showPopover: PropTypes.bool.isRequired,
};

BaseFormWidget.defaultProps = {
  alert: undefined,
  cancelButtonPendoId: undefined,
  onCancel: () => {},
  iconPendoId: undefined,
  iconPrimaryActiveColor: 'default',
  iconSecondaryActiveColor: 'default',
  lockIconActiveColor: false,
  submitButtonLabel: undefined,
  submitButtonPendoId: undefined,
  isLoading: false,
};

const Popover = styled(Paper)(({ theme }) => ({
  zIndex: WidgetZIndex.active,
  borderRadius: `${4 * theme.shape.borderRadius}px`,
  overflow: 'hidden',
  position: 'absolute',
  right: theme.spacing(-2.5),
  top: theme.spacing(-2.5),
  width: '360px',
}));

const StyledIcon = styled(({ IconComponent, children, ...props }) =>
  createElement(IconComponent, props, children),
)(
  ({
    theme,
    $active,
    $lockIconActiveColor,
    $iconPrimaryActiveColor,
    $iconSecondaryActiveColor,
  }) => ({
    verticalAlign: 'middle',
    position: 'relative',
    zIndex: $active ? WidgetZIndex.active : WidgetZIndex.inactive,
    cursor: $active ? 'default' : 'pointer',
    color:
      $active || $lockIconActiveColor
        ? $iconPrimaryActiveColor
        : theme.palette.icon.placeholder,
    circle: {
      fill:
        $active || $lockIconActiveColor ? $iconSecondaryActiveColor : 'default',
    },
    ':hover': {
      color:
        $active || $lockIconActiveColor
          ? $iconPrimaryActiveColor
          : theme.palette.icon.secondary,
    },
  }),
);
