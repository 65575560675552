import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import challengeSearchQueryKeyFactory from '@ot/hooks/queryKeyFactories/challengeSearchQueryKeyFactory';

async function postUpdateChallenges(challengeIdList, challengeUpdates) {
  const params = {
    challenge_ids: challengeIdList,
    challenge_updates: challengeUpdates,
  };
  const response = await axios.post(
    `/ops_tools/api/challenges/update_challenges`,
    params,
  );
  return response.data;
}

export default function usePostUpdateChallenges() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ challengeIdList, challengeUpdates }) =>
      postUpdateChallenges(challengeIdList, challengeUpdates),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['challenges-page-challenges']);
        queryClient.invalidateQueries(challengeSearchQueryKeyFactory.all());
      },
    },
  );
}
