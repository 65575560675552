import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

/**
 * !!! THIS FUNCTION HAS SIDE EFFECTS !!!
 *
 * Executing this function will initialize ChartJS and register all its registerables, making them globally available.
 * As a result, your bundle size will increase.
 */
export default function registerChartJS() {
  ChartJS.register(
    // Register all available imports
    ...registerables,

    // Register plugins
    ChartDataLabels,
  );
}
