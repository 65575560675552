import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

export default function EhrNotification({ message }) {
  const [visible, setVisible] = useState(true);

  // Hide the notification after 3 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return visible ? (
    <Typography component="span" variant="bodylarge">
      <PopupNotificationContainer>
        <PopupNotification>{message}</PopupNotification>
      </PopupNotificationContainer>
    </Typography>
  ) : null;
}

EhrNotification.propTypes = {
  message: PropTypes.string,
};

EhrNotification.defaultProps = {
  message: '',
};

const PopupNotificationContainer = styled.div`
  /* Flexbox Centering */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupNotification = styled.div`
  /* Basic Styles */
  width: fit-content;
  background-color: ${(p) => p.theme.palette.background.paper};

  /* Borders and Box Model */
  border: 2px solid ${(p) => p.theme.palette.border.base};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(2)};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);

  /* Positioning */
  position: fixed;
  top: ${(p) => p.theme.spacing(7)};
  z-index: 2;
`;
