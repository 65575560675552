import PropTypes from 'prop-types';
import { Box, ThemeProvider, Typography, styled } from '@mui/material';
import { useMemo, useState } from 'react';
import { parse } from 'date-fns';

import theme from '@/theme';
import useCsrfToken from '@/hooks/useCsrfToken';
import useDate from '@/hooks/useDate';
import { patientShape } from '@/utils/transformFrontendPatient';
import { actionShape } from '@/utils/transformFrontendAction';
import { stepShape } from '@/utils/transformFrontendCareGapAction';
import DynamicForm from '@/components/DynamicForm/DynamicForm';
import DynamicFormFields from '@/components/DynamicForm/DynamicFormFields';
import DynamicFormDatePicker from '@/components/DynamicForm/fieldComponents/DynamicFormDatePicker';
import DynamicFormIntegerField from '@/components/DynamicForm/fieldComponents/DynamicFormIntegerField';
import DynamicFormTextarea from '@/components/DynamicForm/fieldComponents/DynamicFormTextarea';
import DynamicFormButtons from '@/components/DynamicForm/DynamicFormButtons';
import DynamicFormFieldUpload from '@/components/DynamicForm/fieldComponents/DynamicFormFileUpload';
import DynamicFormProviderDropdown from '@/components/DynamicForm/fieldComponents/DynamicFormProviderDropdown';
import DynamicFormOfficeDropdown from '@/components/DynamicForm/fieldComponents/DynamicFormOfficeDropdown';
import DynamicFormRadioGroup from '@/components/DynamicForm/fieldComponents/DynamicFormRadioGroup';
import DynamicFormTextField from '@/components/DynamicForm/fieldComponents/DynamicFormTextField';
import DynamicFormDropdown from '@/components/DynamicForm/fieldComponents/DynamicFormDropdown';
import DynamicFormRiskFactors from '@/components/DynamicForm/fieldComponents/DynamicFormRiskFactors';

import useExecuteStep from '../../hooks/useExecuteStep';
import buildExecutionInput from '../../utils/buildExecutionInput';
import { Page } from '../../constants';
import { usePage } from '../../contexts/PageContext';
import ErrorAlert from '../ErrorAlert';

import { StepExecutionProvider } from './stepExecutionPanel/StepExecutionContext';
import QualityScreeningsReferral from './stepExecutionPanel/QualityScreeningsReferral';

function fieldComponentMapper(field) {
  switch (field.type) {
    case 'radio':
      return DynamicFormRadioGroup;
    case 'dropdown':
      return DynamicFormDropdown;
    case 'date':
      return DynamicFormDatePicker;
    case 'int':
      return DynamicFormIntegerField;
    case 'text':
      return field.name === 'notes' || field.name === 'address_input'
        ? DynamicFormTextarea
        : DynamicFormTextField;
    case 'textarea':
      return DynamicFormTextarea;
    case 'fileupload':
      return DynamicFormFieldUpload;
    case 'provider':
      return DynamicFormProviderDropdown;
    case 'office':
      return DynamicFormOfficeDropdown;
    case 'referral':
      return QualityScreeningsReferral;
    case 'riskFactors':
      return DynamicFormRiskFactors;

    default:
      // Fall back to the default field component
      return null;
  }
}

function getDefaultValues(page, patient, action, now) {
  if (!page || !patient || !action) {
    return {};
  }

  const appointmentProviderId =
    action.v2Dto.contextualInfo?.latestExternalCareGap?.providerId;
  const appointmentDate =
    action.v2Dto.contextualInfo?.latestExternalCareGap?.dateOfService;
  const completedDate = appointmentDate
    ? parse(appointmentDate, 'yyyy-MM-dd', now)
    : parse(action.v2Dto.dateOfService, 'MM-dd-yyyy', now);

  switch (page) {
    case Page.medicationManagement:
      return {
        from_date: now,
      };
    case Page.visits:
      return {
        completed_date: completedDate || '',
        servicing_provider_id: appointmentProviderId || patient.provider.id,
      };
    case Page.transitionOfCare:
    case Page.priorityPatient:
      return {
        servicing_provider_id: patient.provider.id,
      };
    case Page.programEnrollment:
      return {
        phone_number_input:
          // Using || operator here because empty strings aren't phone numbers
          action.v2Dto.phoneNumberInput || patient.address?.phoneNumber,
      };
    default:
      return {};
  }
}

function StepExecutionPanel({ action, patient, step, onCancel, onSuccess }) {
  const page = usePage();
  const {
    isLoading,
    mutate,
    isError: isErrorMutation,
    reset: resetMutation,
  } = useExecuteStep({ onSuccess });
  const [isErrorPreparing, setIsErrorPreparing] = useState(false);
  const csrfToken = useCsrfToken();
  const currentDate = useDate();
  const defaultValues = getDefaultValues(page, patient, action, currentDate);
  const { dateOfService } = parse(
    action?.v2Dto?.date_of_service,
    'MM-dd-yyyy',
    currentDate,
  );
  const additionalProps = useMemo(
    () => ({
      action,
      dateOfService: dateOfService || currentDate,
      patient,
      executionRequirements: step?.modifiedExecutionRequirements,
    }),
    [action, step, patient, dateOfService, currentDate],
  );

  const reset = () => {
    resetMutation();
    setIsErrorPreparing(false);
  };

  const isError = isErrorMutation || isErrorPreparing;

  const onSubmit = async (data) => {
    let executionInput;

    reset();

    try {
      executionInput = await buildExecutionInput({
        action,
        patient,
        csrfToken,
        currentDate,
        data,
      });
    } catch (e) {
      setIsErrorPreparing(true);
    }

    if (executionInput) {
      mutate({
        actionId: action.v2Dto.actionId,
        stepId: step.stepId,
        executionInput: {
          pcp_visit_servicing_provider_id: patient.provider?.id,
          ...executionInput,
        },
      });
    }
  };

  const handleCloseAlert = () => {
    reset();
  };

  return (
    <StepExecutionPanelRoot>
      {action && step ? (
        <>
          {isError ? (
            <ErrorAlert
              message="We couldn't confirm your changes"
              handleCloseAlert={handleCloseAlert}
            />
          ) : null}

          <Typography variant="h3" component="h2">
            {step.description}
          </Typography>
          <Typography variant="bodymedium" mt={0.5}>
            {action.v2Dto.description}
          </Typography>

          {/* DynamicForm is not ready for themeV2 yet */}
          <ThemeProvider theme={theme}>
            <StepExecutionProvider
              action={action}
              patient={patient}
              step={step}
            >
              <DynamicForm
                onSubmit={onSubmit}
                defaultValues={defaultValues}
                sx={{ mt: 2 }}
                mode="onChange"
              >
                <DynamicFormFields
                  additionalProps={additionalProps}
                  fields={step.modifiedExecutionRequirements}
                  fieldComponentMapper={fieldComponentMapper}
                />

                <Box mt={3}>
                  <DynamicFormButtons
                    ButtonSx={{
                      mr: 3,
                    }}
                    isLoading={isLoading}
                    onCancel={onCancel}
                  />
                </Box>
              </DynamicForm>
            </StepExecutionProvider>
          </ThemeProvider>
        </>
      ) : null}
    </StepExecutionPanelRoot>
  );
}

StepExecutionPanel.propTypes = {
  action: actionShape,
  step: stepShape,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  patient: patientShape.isRequired,
};

StepExecutionPanel.defaultProps = {
  action: undefined,
  step: undefined,
};

const StepExecutionPanelRoot = styled('div')`
  background-color: ${(p) => p.theme.palette.background.base};
  padding: ${(p) => p.theme.spacing(3)};
  max-width: 420px;
`;

export default StepExecutionPanel;
