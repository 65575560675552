import PropTypes from 'prop-types';

import camelCaseify from './camelCaseify';

/**
 * Transform data that came from the `FrontendPreferredReferral` class.
 */
export default function transformFrontendPreferredReferrals(
  preferredReferrals,
) {
  if (!preferredReferrals || preferredReferrals.length === 0) {
    return [];
  }
  return preferredReferrals.map((preferredReferral) =>
    camelCaseify(preferredReferral),
  );
}

export const preferredReferralShape = PropTypes.shape({
  id: PropTypes.number,
  phone: PropTypes.string,
  address: PropTypes.string,
  providerSingleName: PropTypes.string,
  providerFirstName: PropTypes.string,
  providerLastName: PropTypes.string,
  actionSubtype: PropTypes.string,
});
