import PropTypes from 'prop-types';
import { createContext, useContext, useMemo } from 'react';

import { patientShape } from '@/utils/shapes';
import { actionShape } from '@/utils/transformFrontendAction';
import { stepShape } from '@/utils/transformFrontendCareGapAction';

const StepExecutionContext = createContext({
  action: undefined,
  patient: undefined,
  step: undefined,
});

/**
 * Returns the selected action, patient, and step displayed in the StepExecutionPanel.
 */
export function useStepExecutionContext() {
  return useContext(StepExecutionContext);
}

/**
 * Context provider that contains the selected action, patient, and step displayed in the StepExecutionPanel.
 *
 * Note: It's possible for `action.v2Dto` and `step` to be undefined if the action is V1.
 */
export function StepExecutionProvider({ action, patient, step, children }) {
  const contextValue = useMemo(
    () => ({ action, patient, step }),
    [action, patient, step],
  );
  return (
    <StepExecutionContext.Provider value={contextValue}>
      {children}
    </StepExecutionContext.Provider>
  );
}

StepExecutionProvider.propTypes = {
  action: actionShape.isRequired,
  children: PropTypes.node,
  patient: patientShape.isRequired,
  step: stepShape,
};

StepExecutionProvider.defaultProps = {
  step: undefined,
};

StepExecutionProvider.defaultProps = {
  children: undefined,
};
