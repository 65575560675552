import data from './actionExecutionRequirements';

const emptyArray = [];
const emptyObject = {};

/**
 * Molding the Execution Requirements to convey
 * extra information to Dynamic Form Modals.
 *
 * Required = False -> add ' (optional)' to existing
 *                     label
 */
function enhanceExecutionRequirement(execReq) {
  const updatedExecReq = { ...execReq };
  if (execReq.required === false && execReq.type !== 'label') {
    updatedExecReq.label = `${execReq.label} (optional)`;
  }
  return updatedExecReq;
}

/**
 * Given an action subtype and the desired new state,
 * return a list of statically-defined execution requirements
 * for dynamic modal generation.
 *
 * @param {string} subtype
 * @param {string} newState
 */
export default function getActionExecutionRequirements(subtype, newState) {
  const execReqsAll = data[subtype] || emptyObject;
  const actionExecReqs = execReqsAll[newState] || emptyArray;
  return actionExecReqs.map((req) => enhanceExecutionRequirement(req));
}
