import styled, { css } from 'styled-components';
import { useState } from 'react';
import Autorenew from '@mui/icons-material/Autorenew';
import ReactDOMServer from 'react-dom/server';

import focusOutline from '@/mixins/focusOutline';
import { actionProgressShape } from '@/utils/transformFrontendCareGapAction';
import {
  CHECK,
  STATE_COMPLETE,
  STATE_INCOMPLETE,
} from '@/pages/PatientSummary/utils/constants';
import palette from '@/theme/palette';

import { BulletWrapper, StyledTooltip } from '../../utils/styles';
import useGetUrl from '../../hooks/useGetUrl';

const FAILED = 'failed';
const INACTIVE = 'inactive';

const bulletColors = {
  [STATE_COMPLETE]: palette.status.successForeground,
  [STATE_INCOMPLETE]: palette.background.primaryLightest,
  [FAILED]: palette.status.errorForeground,
  [INACTIVE]: palette.status.noneForeground,
};

const bulletTextColors = {
  [STATE_COMPLETE]: palette.status.successBackground,
  [STATE_INCOMPLETE]: palette.background.primary,
  [FAILED]: palette.status.errorBackground,
  [INACTIVE]: palette.status.noneBackground,
};
const continuousBulletColors = {
  ...bulletColors,
  [STATE_INCOMPLETE]: palette.background.primary,
};

function ActionProgress({ progress }) {
  const [enabled, setEnabled] = useState(false);
  const { data, isLoading } = useGetUrl(progress.dataUrl, { enabled });
  const content = isLoading ? 'Loading...' : data;
  let lines = '';
  if (content && !isLoading) {
    const serverHtml = content.split(/\r?\n/);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < serverHtml.length; i++) {
      let line = serverHtml[i];
      if (line.includes('yellow-continuous')) {
        line = ReactDOMServer.renderToString(
          <td>
            <ContinuousBullet
              style={{ color: continuousBulletColors[STATE_INCOMPLETE] }}
            />
          </td>,
        );
      } else if (line.includes('green-continuous')) {
        line = ReactDOMServer.renderToString(
          <td>
            <ContinuousBullet
              style={{ color: continuousBulletColors[STATE_COMPLETE] }}
            />
          </td>,
        );
      } else if (line.includes('red-continuous')) {
        line = ReactDOMServer.renderToString(
          <td>
            <ContinuousBullet
              style={{ color: continuousBulletColors[FAILED] }}
            />
          </td>,
        );
      }
      lines = `${lines + line}\n`;
    }
  }

  const handleTooltipLoad = () => {
    setEnabled(true);
  };

  if (progress.bullets.length === 0) {
    return null;
  }

  const bullets = progress.bullets.map((bullet, index) => {
    if (bullet.displayType === 'continuous_step_type') {
      return (
        <ContinuousBullet
          bgcolor={bullet.stepActive ? bullet.state : INACTIVE}
        />
      );
    }
    return (
      <ProgressBullet
        bgColor={bullet.stepActive ? bullet.state : INACTIVE}
        // eslint-disable-next-line react/no-array-index-key
        key={`${bullet.displayType}-${index}`}
      >
        {bullet.state === STATE_COMPLETE ? CHECK : index + 1}
      </ProgressBullet>
    );
  });
  return (
    <StyledTooltip
      // eslint-disable-next-line react/no-danger
      title={<div dangerouslySetInnerHTML={{ __html: lines }} />}
    >
      <ProgressDiv
        onMouseEnter={handleTooltipLoad}
        onFocus={handleTooltipLoad}
        tabIndex="0"
      >
        {bullets.map((bullet, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <BulletWrapper key={index}>{bullet}</BulletWrapper>
        ))}
      </ProgressDiv>
    </StyledTooltip>
  );
}

export default ActionProgress;

ActionProgress.propTypes = {
  progress: actionProgressShape,
};

ActionProgress.defaultProps = {
  progress: undefined,
};

const ContinuousBullet = styled(Autorenew)`
  width: 25px;
  height: 25px;
  color: ${({ bgcolor }) => continuousBulletColors[bgcolor]};
  vertical-align: middle;
`;

export const ProgressBullet = styled.span`
  display: inline-block;
  line-height: 1.25;
  width: 20px;
  height: 20px;
  border: none;
  background: ${({ bgColor }) => bulletColors[bgColor]};
  color: ${({ bgColor }) => bulletTextColors[bgColor]};
  ${({ bgColor }) =>
    bgColor === STATE_INCOMPLETE &&
    css`
      outline: 1px solid ${bulletTextColors[bgColor]};
      outline-offset: -1px;
    `}
  font-weight: 800;
  text-align: center;
  border-radius: 10px;
  padding-top: 4px;
  padding-bottom: 2px;
  font-size: 11px;
`;

export const ProgressDiv = styled.div`
  ${focusOutline}
`;
