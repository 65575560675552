import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import auditDataQueryKeyFactory from '@ot/pages/SvuMenu/AuditPage/auditDataQueryKeyFactory';

async function getAuditData(params) {
  const response = await axios.get('/ops_tools/api/svu_menu/audit/data', {
    params,
  });

  return response.data;
}

export default function useGetAuditData({ modelId, type }) {
  const params = { modelId, type };
  return useQuery(
    auditDataQueryKeyFactory.auditData({
      modelId,
      type,
    }),
    () => getAuditData(params),
  );
}
