import { Alert, Box, Typography } from '@mui/material';

import { latestExternalCareGapShape } from '@/utils/transformLatestExternalCareGap';
import useFeatureFlag from '@/utils/useFeatureFlag';

function getContentsPerSourceType(latestExternalCareGap, autoCloseAVsEnabled) {
  if (['vim', 'innovaccer'].includes(latestExternalCareGap.sourceType)) {
    return {
      title:
        'Updates were found in the EHR that might help with completing this task.',
      tableContents: [
        {
          label: 'Updates made by',
          value: latestExternalCareGap.userName,
          secondLabel: 'on',
          secondValue: `${latestExternalCareGap.dateOfService}:`,
        },
        { label: 'Action:', value: latestExternalCareGap.status },
        { label: 'Reason:', value: latestExternalCareGap.reason },
        { label: 'CPT Code:', value: latestExternalCareGap.cptCodes },
        { label: 'Reason description:', value: latestExternalCareGap.notes },
      ],
    };
  }

  if (
    latestExternalCareGap.sourceType === 'appointment' &&
    autoCloseAVsEnabled
  ) {
    return {
      title:
        'Updates were found in your EHR that indicate the visit was completed.',
      tableContents: [
        {
          label: 'Visit status:',
          value: latestExternalCareGap.details?.appointmentStatus,
        },
        { label: 'Visit date:', value: latestExternalCareGap.dateOfService },
        {
          label: 'Visit type:',
          value: latestExternalCareGap.details?.appointmentType,
        },
        { label: 'Visit provider:', value: latestExternalCareGap.userName },
        {
          label: 'Complete the visit on the right to accept SVUs.',
        },
      ],
    };
  }

  return null;
}

export default function PatientExternalCareGapDetails({
  latestExternalCareGap,
}) {
  const autoCloseAVsEnabled = useFeatureFlag('autoclose_avs');
  const contents = getContentsPerSourceType(
    latestExternalCareGap,
    autoCloseAVsEnabled,
  );

  return contents ? (
    <Alert
      severity="info"
      sx={{ mt: 1 }}
      data-testid="patient-external-care-gap-details-alert"
    >
      <Box>
        <Typography variant="bodysmall">{contents.title}</Typography>
      </Box>
      {contents.tableContents.map((row) => (
        <Box key={row.label}>
          <Typography variant="bodymedium" component="span">
            {row.label}
          </Typography>
          {row.value ? (
            <Typography variant="bodymediumbold" component="span" ml={1}>
              {row.value}
            </Typography>
          ) : null}
          {row.secondLabel ? (
            <>
              <Typography variant="bodymedium" component="span" ml={1}>
                {row.secondLabel}
              </Typography>
              <Typography variant="bodymediumbold" component="span" ml={1}>
                {row.secondValue}
              </Typography>
            </>
          ) : null}
        </Box>
      ))}
    </Alert>
  ) : null;
}

PatientExternalCareGapDetails.propTypes = {
  latestExternalCareGap: latestExternalCareGapShape.isRequired,
};
