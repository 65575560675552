import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@mui/material/Button';

import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/chevronLeft.svg';
import focusOutline from '@/mixins/focusOutline';

const FiltersSidebar = memo(function Sidebar({
  className,
  onClose,
  sx,
  filterCount,
  onClearFilters,
  children,
  onApplyFilters,
  disableApplyButton,
}) {
  const [containerOffset, setContainerOffset] = useState(0);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const handleScroll = () => {
    const { top } = document
      .getElementById('sections-container')
      .getBoundingClientRect();
    setContainerOffset(top);
  };

  const handleResize = () => {
    setInnerHeight(window.innerHeight);
  };

  // When the page scrolls or resizes we need to move around the sidebar and
  // change the size so that the whole thing is as accessible as possible and
  // the user can access the largest amount of content without having to scroll
  // the outside page. This isn't perfect and the top of the sidebar may end up
  // cut off at the bottom of the page, but it's pretty good.
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sectionsContainerHeight = Math.min(
    innerHeight,
    innerHeight - containerOffset,
  );

  return (
    <Container
      className={className}
      data-testid="filter-sidebar"
      id="filters-sidebar-container"
      sx={sx}
    >
      <Wrapper>
        <Header>
          <Heading>Filters</Heading>

          <CollapseButton aria-label="Collapse filters" onClick={onClose}>
            <ChevronLeftIcon width="16" />
          </CollapseButton>
        </Header>

        <SectionsContainer
          $height={`${sectionsContainerHeight}px`}
          id="sections-container"
        >
          {children}
        </SectionsContainer>
        <Footer>
          <Button
            onClick={onClearFilters}
            variant="outlined"
            disabled={filterCount === 0}
            sx={{ mr: 3 }}
            size="large"
            data-testid="clear-all-filters-button"
          >
            Clear All
          </Button>
          <Button
            type="submit"
            size="large"
            sx={{ mr: 1 }}
            onClick={onApplyFilters}
            disabled={filterCount === 0 || disableApplyButton}
            data-pendo-id="filter-sidebar-apply-button"
          >
            Apply
          </Button>
        </Footer>
      </Wrapper>
    </Container>
  );
});

FiltersSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  filterCount: PropTypes.number.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func,
  disableApplyButton: PropTypes.bool,
};

FiltersSidebar.defaultProps = {
  className: undefined,
  sx: undefined,
  disableApplyButton: false,
  onApplyFilters: undefined,
};

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minWidth: '300px',
  width: '300px',
  position: 'sticky',
  top: '75px',
  alignSelf: 'flex-start',
  left: 0,
  zIndex: 2,
}));

const SectionsContainer = styled.div`
  overflow-y: scroll;
  height: ${(props) => props.$height};
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  background-color: ${(p) => p.theme.palette.background.paper};
  border: 1px solid;
  border-top: 0;
  border-color: ${(p) => p.theme.palette.border.base};
`;
const Wrapper = styled.div`
  position: relative;
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  minwidth: 300px;
  background-color: ${(p) => p.theme.palette.background.paper};
  border: 1px solid;
  border-color: ${(p) => p.theme.palette.border.base};
  position: fixed;
  bottom: 0;
  height: 64px;
`;

const Heading = styled.h2`
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bold;
  margin: 10.5px auto 10.5px 12px;
`;

const CollapseButton = styled.button`
  ${focusOutline}
  background-color: transparent;
  border: none;
  cursor: pointer;
  line-height: 0;
  padding: 15px;

  :hover {
    background-color: ${(p) => p.theme.palette.stroke.light};
    color: white;
  }
`;

export default FiltersSidebar;
