import { createContext, createElement, useContext, useMemo } from 'react';
import { bool, node, object } from 'prop-types';

const FeatureFlagsContext = createContext({});

/**
 * Use this to inject feature flags into the React context.
 *
 * @param value    feature flags object to load into context
 * @param merge    if true, it will merge in feature flags from the parent
 *                 context, defaults to false for compatibility reasons.
 * @param children
 */
export function FeatureFlagsProvider({ value, merge, children }) {
  const features = useContext(FeatureFlagsContext);

  const val = useMemo(() => {
    if (merge) {
      return {
        ...(features || {}),
        ...(value || {}),
      };
    }
    return value;
  }, [value, merge, features]);

  return createElement(FeatureFlagsContext.Provider, { value: val }, children);
}
FeatureFlagsProvider.propTypes = {
  value: object,
  merge: bool,
  children: node.isRequired,
};
FeatureFlagsProvider.defaultProps = {
  value: undefined,
  merge: false,
};

/**
 * Returns true if the named feature flag is enabled.
 *
 * @param flagName
 * @returns boolean - true if the feature flag is enabled, otherwise false
 */
export default function useFeatureFlag(flagName) {
  const featureFlags = useContext(FeatureFlagsContext) || {};
  return Boolean(featureFlags[flagName]);
}
