import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';

export default function BaseDialogTitle({ sx, children, open, ...props }) {
  return (
    <DialogTitle
      sx={{
        height: '10%',
        maxHeight: '128px',
        fontWeight: 900,
        fontSize: '24px',
        ...sx,
      }}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {children}
    </DialogTitle>
  );
}

BaseDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  open: PropTypes.bool,
};
BaseDialogTitle.defaultProps = {
  sx: undefined,
  open: undefined,
};
