import PropTypes from 'prop-types';

const addressShape = PropTypes.shape({
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zipcode: PropTypes.string.isRequired,
});

export default addressShape;
