import { useController } from 'react-hook-form';
import useId from '@mui/utils/useId';

import BaseTextarea from '@/components/BaseTextarea';
import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';
import FormErrorMessage from '@/components/FormErrorMessage';

import DynamicFormLabel from '../DynamicFormLabel';

export default function DynamicFormTextarea({ field, ...otherProps }) {
  const { label, minRows, name, placeholder, required, sx } = field;
  const controller = useController({
    name,
    // You can override this defaultValue by passing defaultValues to DynamicForm.
    defaultValue: '',
    rules: {
      required: required ? 'This field is required' : undefined,
    },
  });
  const fieldId = useId();

  return (
    <>
      <DynamicFormLabel htmlFor={fieldId}>{label}</DynamicFormLabel>
      <BaseTextarea
        id={fieldId}
        fullWidth
        minRows={minRows}
        placeholder={placeholder}
        sx={{ mt: 0.5 }}
        TextareaProps={{ sx }}
        data-pendo-id={field.dataPendoId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controller.field}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
      <FormErrorMessage errors={controller.formState.errors} name={name} />
    </>
  );
}

DynamicFormTextarea.propTypes = {
  field: executionRequirementShape.isRequired,
};
