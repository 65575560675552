import axios from 'axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

// @ts-expect-error - js module
import { useQueryParameters } from '@/contexts/QueryParametersContext';

import { Goal, InternalPerformanceMetric } from '../types';

type ResponseData = Array<Goal>;

/**
 * Hits the same endpoint as `useGetGoals()`, which fetches all goals, and then finds and returns the specified goal.
 */
async function getGoal({ queryKey }: QueryFunctionContext) {
  const [, internalPerformanceMetric, dateMonth] = queryKey;
  const response = await axios.get<ResponseData>('/api/goals', {
    params: { date_month: dateMonth },
  });
  return response.data.find(
    (x) => x.internalPerformanceMetric === internalPerformanceMetric,
  );
}

export default function useGetGoal(
  internalPerformanceMetric: InternalPerformanceMetric,
  { enabled = true } = {},
) {
  const { parameters } = useQueryParameters();
  return useQuery({
    queryFn: getGoal,
    queryKey: ['goals', internalPerformanceMetric, parameters.dateMonth],
    enabled,
  });
}
