import { AppBar, Button, Toolbar, Typography } from '@mui/material';

import { ButtonLabel, CancelIcon, PrintIcon } from '../utils/printStyles';

function PrintToolBar() {
  return (
    <AppBar
      position="sticky"
      sx={{
        borderRadius: 0,
        backgroundColor: 'white',
        color: 'black',
        boxShadow: 2,
        displayPrint: 'none',
      }}
    >
      <Toolbar sx={{ width: '216mm', mx: 'auto' }}>
        <Button
          variant="outlined"
          onClick={() => {
            // This is a hack to allow the script to close its own tab
            window.open('about:blank', '_self');
            window.close();
          }}
        >
          <CancelIcon />
          <ButtonLabel>Cancel</ButtonLabel>
        </Button>
        <Typography variant="h2" sx={{ mx: 'auto' }}>
          Print Preview
        </Typography>
        <Button onClick={() => window.print()}>
          <PrintIcon />
          <ButtonLabel>Print</ButtonLabel>
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default PrintToolBar;
