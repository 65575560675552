import { TableCell, TableRow, Typography } from '@mui/material';
import styled from 'styled-components';

import { careGapActionShape } from '@/utils/transformFrontendCareGapAction';
import SvuAmount from '@/components/SvuAmount';

import ActionTrigger from './ActionTrigger';
import ActionProgress from './ActionProgress';

// TODO: parameterize absolutely everything that's shared with CareGapActionRow.jsx

export function CompletedSubtext({ action }) {
  if (
    !action.mostRecentlyCompletedByName &&
    !action.mostRecentlyCompletedByDate
  ) {
    return null;
  }
  return (
    <>
      Data entered
      {action.mostRecentlyCompletedByName
        ? ` by ${action.mostRecentlyCompletedByName}`
        : null}
      {action.mostRecentlyCompletedByDate ? (
        <>
          {' '}
          on{' '}
          <Typography
            component="span"
            fontSize="inherit"
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {action.mostRecentlyCompletedByDate}
          </Typography>
        </>
      ) : null}
    </>
  );
}

CompletedSubtext.propTypes = {
  action: careGapActionShape.isRequired,
};

function CompletedCareGapActionRow({ action }) {
  return (
    <TableRow>
      <TableCell>
        <Typography>{action.typeDisplay}</Typography>
        {action.staff ? <Subtext>staff</Subtext> : null}
      </TableCell>
      <TableCell>{action.text}</TableCell>
      <TableCell>
        {action.trigger ? <ActionTrigger trigger={action.trigger} /> : null}
      </TableCell>
      <TableCell>
        {action.providerName ? (
          <Typography>{action.providerName}</Typography>
        ) : (
          <Typography>Provider N/A</Typography>
        )}
        {action.date ? <Typography>{action.date}</Typography> : null}
      </TableCell>
      <TableCell align="center">
        {action.progress?.show ? (
          <ActionProgress progress={action.progress} />
        ) : null}
      </TableCell>
      <TableCell align="center">
        <Typography
          sx={{
            '::first-letter': {
              textTransform: 'capitalize',
            },
          }}
        >
          {action.state.replaceAll('_', ' ')}
        </Typography>
        <Typography variant="subtext" component="span" color="text.secondary">
          <CompletedSubtext action={action} />
        </Typography>
      </TableCell>
      <TableCell align="center">
        <SvuAmount
          svuPrePromo={action.svusBeforePromo}
          svuPostPromo={action.svusAfterPromo}
          sx={{ display: 'block' }}
          isComplete
        />
      </TableCell>
    </TableRow>
  );
}

export default CompletedCareGapActionRow;

CompletedCareGapActionRow.propTypes = {
  action: careGapActionShape,
};

CompletedCareGapActionRow.defaultProps = {
  action: undefined,
};

const Subtext = styled.span`
  font-style: italic;
  font-size: 80%;
  color: ${(p) => p.theme.palette.text.secondary};
`;
