import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as WarningIcon } from '@/assets/icons/warning.svg';

export default function ErrorDialog({
  open,
  title,
  warningMessage,
  errors,
  handleClose,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <StyledWarningIcon />
        <Typography variant="headermediumbold">{title}</Typography>
      </DialogTitle>

      <DialogContent>
        <Box textAlign="left">
          <Typography variant="bodylargebold" mt={1}>
            {warningMessage}
          </Typography>
          {errors.map((error) => (
            <Typography variant="body" mt={1}>
              {error.title} - {error.description}
            </Typography>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

ErrorDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  warningMessage: PropTypes.string,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  handleClose: PropTypes.func.isRequired,
};

ErrorDialog.defaultProps = {
  open: false,
  title: PropTypes.string,
  warningMessage: PropTypes.string,
  errors: [],
};

const StyledWarningIcon = styled(WarningIcon)`
  width: 32px;
  height: 32px;
  color: ${(p) => p.theme.palette.status.danger};
`;
