import { useController } from 'react-hook-form';
import { styled } from '@mui/material';

import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';
import BaseCheckbox from '@/components/BaseCheckbox';

export default function DynamicFormCheckbox({ field }) {
  const { label, name, required } = field;
  const controller = useController({
    name,
    // You can override this defaultValue by passing `defaultValues` to DynamicForm.
    defaultValue: '',
    rules: {
      required: required === true ? 'Select a value' : required,
    },
  });

  const error = Boolean(controller.fieldState.error);
  const checked = Boolean(controller.field.value);

  return (
    <StyledCheckbox
      checked={checked}
      label={label}
      onChange={controller.field.onChange}
      name={controller.field.name}
      error={error}
      sx={{ alignItems: 'flex-start' }}
    />
  );
}

DynamicFormCheckbox.propTypes = {
  field: executionRequirementShape.isRequired,
};

const StyledCheckbox = styled(BaseCheckbox)`
  display: flex;
  &:not(:first-child) {
    margin-top: ${(p) => p.theme.spacing(2)};
  }
`;
