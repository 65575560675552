import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import BaseTextarea from '@/components/BaseTextarea';

export default function RhfTextArea({
  control,
  'data-dd-synthetics-id': dataDdSyntheticsId,
  'data-pendo-id': dataPendoId,
  defaultValue,
  id,
  minRows,
  name,
  placeholder,
  rules,
  sx,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <BaseTextarea
          data-dd-synthetics-id={dataDdSyntheticsId}
          data-pendo-id={dataPendoId}
          id={id}
          name={name}
          fullWidth
          minRows={minRows}
          placeholder={placeholder}
          onChange={field.onChange}
          value={field.value}
          TextareaProps={sx}
        />
      )}
    />
  );
}

RhfTextArea.propTypes = {
  'data-dd-synthetics-id': PropTypes.string,
  'data-pendo-id': PropTypes.string,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  minRows: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  sx: PropTypes.object,
};

RhfTextArea.defaultProps = {
  'data-dd-synthetics-id': undefined,
  'data-pendo-id': undefined,
  defaultValue: '',
  id: undefined,
  minRows: 3,
  placeholder: undefined,
  sx: undefined,
};
