import { Box, FormLabel } from '@mui/material';
import PropTypes from 'prop-types';
import useId from '@mui/utils/useId';

import RhfTextArea from '@/components/reactHookFormComponents/RhfTextArea';
import FormErrorMessage from '@/components/FormErrorMessage';

export default function ModalTextArea({
  'data-dd-synthetics-id': dataDdSyntheticsId,
  'data-pendo-id': dataPendoId,
  label,
  defaultValue,
  placeholder,
  errorMsg,
  actionId,
  BoxSx,
  InputSx,
  name,
  control,
  errors,
  minRows,
}) {
  const idPrefix = useId(actionId);
  return (
    <Box
      sx={{
        display: 'flex',
        mt: 1,
        flexDirection: 'column',
        ...BoxSx,
      }}
    >
      <FormLabel htmlFor={`${idPrefix}_${name}`} sx={{ mr: 1, mt: 1 }}>
        {label}
      </FormLabel>
      <RhfTextArea
        data-dd-synthetics-id={dataDdSyntheticsId}
        data-pendo-id={dataPendoId}
        id={`${idPrefix}_${name}`}
        name={name}
        defaultValue={defaultValue}
        rules={errorMsg ? { required: errorMsg } : {}}
        control={control}
        placeholder={placeholder}
        sx={InputSx}
        minRows={minRows}
      />
      <FormErrorMessage name={name} errors={errors} />
    </Box>
  );
}

ModalTextArea.propTypes = {
  'data-dd-synthetics-id': PropTypes.string,
  'data-pendo-id': PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  actionId: PropTypes.number,
  defaultValue: PropTypes.string,
  BoxSx: PropTypes.object,
  InputSx: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  minRows: PropTypes.number,
};

ModalTextArea.defaultProps = {
  'data-dd-synthetics-id': undefined,
  'data-pendo-id': undefined,
  actionId: undefined,
  defaultValue: '',
  errorMsg: undefined,
  placeholder: '',
  BoxSx: undefined,
  InputSx: undefined,
  minRows: 3,
};
