import PropTypes from 'prop-types';

import { UsStatesTupleWithNone } from '@/utils/transformFrontendAddress';
import RhfSingleSelectAutocomplete from '@ot/components/reactHookFormComponents/RhfSingleSelectAutocomplete';

export default function RhfStatePicker({
  control,
  name,
  id,
  'data-pendo-id': dataPendoId,
  rules,
  sx,
}) {
  return (
    <RhfSingleSelectAutocomplete
      control={control}
      name={name}
      options={UsStatesTupleWithNone.map((tuple) => tuple[0])}
      getOptionLabel={(option) => option || '--------'}
      id={id}
      isOptionEqualToValue={(option, value) => option === value}
      data-pendo-id={dataPendoId}
      rules={rules}
      sx={sx}
    />
  );
}

RhfStatePicker.propTypes = {
  'data-pendo-id': PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RhfStatePicker.defaultProps = {
  'data-pendo-id': undefined,
  name: undefined,
  id: undefined,
  sx: undefined,
};
