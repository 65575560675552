import { Box, DialogActions, Paper, TableBody, TableHead } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import BaseLinkButton from '@/components/BaseLinkButton';
import { medicalGroupShape } from '@ot/pages/commonShapes';

export default function TinVerificationResultsSection({
  absentTins,
  mgs,
  removeMedicalGroup,
  onCloseAndResetDialogState,
  onBackClick,
  onSubmitTins,
}) {
  return (
    <>
      <Box sx={{ ml: 5, mb: 3, mr: 3 }}>
        <Paper variant="outlined" sx={{ mt: 1 }}>
          <TableContainer sx={{ maxHeight: '448px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Medical Group</TableCell>
                  <TableCell>Error</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {absentTins.map((tin) => {
                  return (
                    <TableRow key={tin}>
                      <ErrorneousTableCell>N/A</ErrorneousTableCell>
                      <ErrorneousTableCell>Tin:[{tin}]</ErrorneousTableCell>
                      <ErrorneousTableCell>Yes</ErrorneousTableCell>
                      <TableCell style={{ width: 20 }} />
                    </TableRow>
                  );
                })}
                {mgs.map((medicalGroup) => {
                  return (
                    <TableRow key={medicalGroup.id}>
                      <TableCell>{medicalGroup.id}</TableCell>
                      <TableCell>
                        {medicalGroup.name} - Tin:[{medicalGroup.tin}]
                      </TableCell>
                      <TableCell style={{ width: 20 }} />
                      <TableCell style={{ width: 20 }}>
                        <Box
                          sx={{ height: 16, cursor: 'pointer' }}
                          onClick={() => removeMedicalGroup(medicalGroup)}
                        >
                          <CloseIcon width="16px" height="16px" />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <DialogActions disableSpacing>
        <div style={{ flex: '1 0 0' }} />
        <BaseLinkButton
          underline="hover"
          onClick={onCloseAndResetDialogState}
          sx={{ mr: 2 }}
        >
          Cancel
        </BaseLinkButton>
        <Button variant="outlined" onClick={onBackClick} sx={{ mr: 2 }}>
          Back
        </Button>
        <Button
          onClick={onSubmitTins}
          disabled={isEmpty(mgs)}
          data-pendo-id="submit-tins-button"
        >
          Submit
        </Button>
      </DialogActions>
    </>
  );
}

TinVerificationResultsSection.propTypes = {
  mgs: PropTypes.arrayOf(medicalGroupShape).isRequired,
  absentTins: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCloseAndResetDialogState: PropTypes.func.isRequired,
  removeMedicalGroup: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onSubmitTins: PropTypes.func.isRequired,
};

const ErrorneousTableCell = styled(TableCell)`
  color: #c42245;
`;
