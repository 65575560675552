import {
  Button,
  Collapse,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import PropTypes from 'prop-types';

import { formatISODateIgnoreTimeZone } from '@/utils/formatDate';
import useGetAdtEventHistory from '@/pages/TaskBasedWorkflow/hooks/useGetAdtEventHistory';

import Separator from '../Separator';

function AdtEventHistoryLoadingRows() {
  return (
    <>
      <TableRow>
        <StyledTableCell colSpan={5}>
          <Skeleton height={28} />
        </StyledTableCell>
      </TableRow>

      <TableRow>
        <StyledTableCell colSpan={5}>
          <Skeleton height={28} />
        </StyledTableCell>
      </TableRow>
    </>
  );
}

function DiagnosesCell({ history }) {
  return history.diagnoses.map((diagnosis) => {
    return (
      <div key={`${history.id}_diagnosis_${diagnosis.id}`}>
        {diagnosis.code}
        {diagnosis.description && <>, {diagnosis.description}</>}
      </div>
    );
  });
}

export default function TocHistory({ actionId }) {
  const { data: adtEventHistoryData, isLoading: adtEventHistoryIsLoading } =
    useGetAdtEventHistory({
      actionId,
    });

  const [showHistoryTable, toggleShowHistoryTable] = useToggle(false);

  const noHistoryToDisplay =
    adtEventHistoryData === undefined || adtEventHistoryData?.length === 0;
  const showHistoryTableButton = adtEventHistoryData?.length > 1;

  if (noHistoryToDisplay) {
    return null;
  }

  return (
    <>
      <Typography variant="bodymedium" color="text.secondary" display="inline">
        {`Previous: Discharged from ${adtEventHistoryData[0].facilityName} on ${adtEventHistoryData[0].eventDate}`}
      </Typography>
      {showHistoryTableButton && (
        <>
          <Separator />
          <StyledHistoryButton
            aria-expanded={showHistoryTable}
            aria-controls="adt-event-history"
            onClick={toggleShowHistoryTable}
            variant="text"
            data-pendo-id="toc-history-button"
          >
            {showHistoryTable ? 'Hide History' : 'History'}
          </StyledHistoryButton>
        </>
      )}
      <Collapse mountOnEnter in={showHistoryTable}>
        <Table id="adt-event-history">
          <StyledTableHead>
            <TableRow>
              <StyledTableCell> Date </StyledTableCell>
              <StyledTableCell> Event </StyledTableCell>
              <StyledTableCell> Facility </StyledTableCell>
              <StyledTableCell> Type </StyledTableCell>
              <StyledTableCell> Length of Stay (days) </StyledTableCell>
              <StyledTableCell> Diagnoses </StyledTableCell>
            </TableRow>
          </StyledTableHead>

          <TableBody>
            {adtEventHistoryIsLoading ? (
              <AdtEventHistoryLoadingRows />
            ) : (
              adtEventHistoryData?.map((history) => {
                return (
                  <TableRow key={`${history.id}_${history.eventType}`}>
                    <StyledTableCell>
                      {formatISODateIgnoreTimeZone(history.eventDate)}
                    </StyledTableCell>
                    <StyledTableCell>{history.eventType}</StyledTableCell>
                    <StyledTableCell>{history.facilityName}</StyledTableCell>
                    <StyledTableCell>{history.facilityType}</StyledTableCell>
                    <StyledTableCell>
                      {history.lengthOfStay > 0
                        ? history.lengthOfStay
                        : 'Unknown'}
                    </StyledTableCell>
                    <StyledTableCell>
                      <DiagnosesCell history={history} />
                    </StyledTableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Collapse>
    </>
  );
}
TocHistory.propTypes = {
  actionId: PropTypes.number.isRequired,
};

export const StyledHistoryButton = styled(Button)`
  padding: 0;
  height: min-content;

  &,
  &:hover {
    color: ${(p) => p.theme.palette.text.secondary};
    font-size: ${(p) => p.theme.typography.bodymedium.fontSize};
    background: none;
    text-decoration: underline;
  }

  &:hover {
    color: ${(p) => p.theme.palette.text.primary};
  }

  &:focus {
    box-shadow: 0 0 0 0;
  }
`;

export const StyledTableHead = styled(TableHead)`
  padding: 6px;
`;

export const StyledTableCell = styled(TableCell)`
  background-color: ${(p) => p.theme.palette.background.base};
  border-bottom-color: ${(p) => p.theme.palette.border.base};
  color: ${(p) => p.theme.palette.text.primary};
  font-size: ${(p) => p.theme.typography.bodysmall.fontSize};
  vertical-align: text-top;
  ${StyledTableHead} & {
    box-shadow: none;
    font-weight: ${(p) => p.theme.typography.bodybold.fontWeight};
  }
`;
