import { createContext } from 'react';

const defaultNavigation = {
  reload() {
    window.location.reload();
  },
  back() {
    window.navigation.back();
  },
};

export const ErrorBoundaryNavigationContext = createContext(defaultNavigation);

export const ErrorBoundaryNavigationProvider =
  ErrorBoundaryNavigationContext.Provider;
