import { Box, Typography } from '@mui/material';
import { node } from 'prop-types';

export default function CompletedTaskSvuBadge({ children }) {
  return (
    <Box
      sx={{
        backgroundColor: 'svu.lightest',
        px: 1,
        borderRadius: 2,
        color: 'svu.dark',
        whiteSpace: 'nowrap',
        display: 'inline-block',
      }}
    >
      <Typography variant="bodybold" sx={{ textAlign: 'left' }} padding={1}>
        {children}
      </Typography>
    </Box>
  );
}
CompletedTaskSvuBadge.propTypes = {
  children: node.isRequired,
};
