import { bool, elementType, number, string } from 'prop-types';
import { Box, Stack, Typography, styled } from '@mui/material';

import { color, spacing } from '@/utils/stylingUtils';

function Icon({ IconComponent }) {
  return <IconComponent height={24} width={24} />;
}

Icon.propTypes = {
  IconComponent: elementType,
};

Icon.defaultProps = {
  IconComponent: undefined,
};

export default function CompactPatientSummarySectionHeader({
  icon,
  headline,
  selected,
  svus,
}) {
  return (
    <StyledSectionHeaderEhr selected={selected} alignItems="center">
      <Stack direction="row">
        {icon && <Icon IconComponent={icon} />}
        <Typography variant="h4" sx={{ px: 1 }}>
          {headline}
        </Typography>
      </Stack>
      <SvuBox>
        <Typography variant="bodybold">{svus}</Typography>
      </SvuBox>
    </StyledSectionHeaderEhr>
  );
}

CompactPatientSummarySectionHeader.propTypes = {
  icon: elementType,
  headline: string.isRequired,
  selected: bool,
  svus: number,
};
CompactPatientSummarySectionHeader.defaultProps = {
  selected: false,
};

CompactPatientSummarySectionHeader.defaultProps = {
  icon: undefined,
  svus: 0,
};

const StyledSectionHeaderEhr = styled(Box)`
  padding-top: ${spacing(1)};
  padding-bottom: ${spacing(1)};
  display: flex;
  justify-content: space-between;
  color: ${({ selected }) =>
    selected ? color('background.primaryDarkest') : color('text.secondary')};
`;

const SvuBox = styled(Box)`
  background-color: ${color('svu.lightest')};
  color: ${color('svu.dark')};
  border-radius: ${spacing(0.5)};
  padding: 0 ${spacing(1)};
`;
