import styled from 'styled-components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { RIGHT_ARROW } from './constants';

export const Strikeout = styled.s`
  color: ${(p) => p.theme.palette.status.errorForeground};
`;

export const StyledTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} arrow placement="left" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.base,
    borderRadius: 2 * theme.shape.borderRadius,
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
    color: theme.palette.text.primary,
    fontSize: '.875rem',
    lineHeight: 1.25,
    maxWidth: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.base,
  },
  ' .info-yellow': {
    color: theme.palette.background.primary,
    backgroundColor: theme.palette.background.primaryLightest,
    outline: `1px solid ${theme.palette.background.primary}`,
    outlineOffset: '-1px',
  },
  ' .info-green': {
    color: theme.palette.status.successBackground,
    backgroundColor: theme.palette.status.successForeground,
  },
  ' .info-grey': {
    color: theme.palette.status.noneBackground,
    backgroundColor: theme.palette.status.noneForeground,
  },
  ' .info-red': {
    color: theme.palette.status.errorBackground,
    backgroundColor: theme.palette.status.errorForeground,
  },
  color: `${theme.palette.text.primary} !important`,
  '#action_progress table tr td:nth-child(3)': {
    color: theme.palette.svu.dark,
  },
  '#action_progress table td': { color: theme.palette.text.primary },
}));

export const BulletWrapper = styled.div`
  display: inline-block;
  font-size: 11px;
  line-height: 1.25;
  :not(:first-of-type) {
    :before {
      content: '${RIGHT_ARROW}';
      padding: 2px;
      color: ${(p) => p.theme.palette.icon.primary};
    }
  }
`;
