const agreementsKey = 'agreements';
const aboundToken = 'aboundToken';

const queryKeyFactory = {
  agreements: ({ slugs = undefined }) => {
    if (!slugs) {
      return [agreementsKey];
    }
    return [agreementsKey, { slugs }];
  },
  aboundToken: () => {
    return [aboundToken];
  },
};

export default queryKeyFactory;
