import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import queryKeyFactory from '../queryKeyFactory';

function transformRatingCounts(ratingCounts) {
  return {
    1: ratingCounts.one,
    2: ratingCounts.two,
    3: ratingCounts.three,
    4: ratingCounts.four,
    5: ratingCounts.five,
    NA: ratingCounts.na,
  };
}
function transformDateBucket(dateBucket) {
  return {
    id: dateBucket.id,
    label: dateBucket.label,
    shortLabel: dateBucket.short_label,
    totalVisitFollowups: dateBucket.total_visit_followups,
    totalHelperCoordCare: dateBucket.total_helper_coord_care,
    totalHelperCoordMeds: dateBucket.total_helper_coord_meds,
    completedHelperCoordCare: dateBucket.helper_coord_care_completed,
    completedHelperCoordMeds: dateBucket.helper_coord_meds_completed,
    visitFollowupsWithCompletedSurvey:
      dateBucket.visit_followups_with_completed_survey,
    avgPatientExperienceRating: dateBucket.avg_patient_experience_rating,
    patientExperienceRatingCounts: transformRatingCounts(
      dateBucket.patient_experience_rating_counts,
    ),
  };
}

function transformResponse(data) {
  return {
    dateBuckets: data.date_buckets.map(transformDateBucket),
  };
}

async function fetchPatientExperienceLandingPageData() {
  const response = await axios.get(
    '/api/charts/patient_experience_landing_page',
  );

  return response.data;
}

export default function useGetPatientExperienceLandingData() {
  return useQuery(
    queryKeyFactory.patientExperienceLandingData(),
    () => fetchPatientExperienceLandingPageData(),
    {
      select: transformResponse,
    },
  );
}
