import { arrayOf, exact, func, node, oneOfType, string } from 'prop-types';

// An array where each item in the array indicates a column should be
// displayed in table mode, or a subrow in card mode. Each item has three
// required properties:
// - id: the unique id of the column, which is used as a key for the table
//       headers in table mode.
// - label: the column header or cell label for this piece of data
// - renderCell: a render delegate that should return a properly-formatted
//               version of the data as jsx.
export const singleColumnDefinition = exact({
  id: string.isRequired,
  label: oneOfType([node, func]).isRequired,
  renderCell: func.isRequired,
});

export const columnDefinition = arrayOf(singleColumnDefinition.isRequired);

// Configuration for each row in the list. rowId is required and is used to
// generate a unique React key for each row in the table.
export const rowDefinition = exact({
  rowId: func.isRequired,

  // If specified, and returns valid jsx, will trigger an additional "action
  // row" to be rendered beneath evey data row.
  renderActions: func,

  // If specified and returns valid JSX, will render a badge for the row
  // in question. Note that some layout changes will be triggered if all or
  // zero rows in the list generate a row badge.
  renderRowBadge: func,

  // If specified, this function will be invoked to generate an initial state
  // for every row.
  initialState: func,
});
