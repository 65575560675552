import { FormLabel, styled } from '@mui/material';

import useGetVisitsAndDxHistory from '@/pages/PrintSummary/hooks/useGetVisitsAndDxHistory';
import { useStepExecutionContext } from '@/pages/TaskBasedWorkflow/components/patientDetailView/stepExecutionPanel/StepExecutionContext';
import useFeatureFlag from '@/utils/useFeatureFlag';
import ModalTextDisplay from '@/pages/PatientSummary/components/modals/ModalTextDisplay';

const RISK_FACTORS_NOT_ENABLED_DESCRIPTION =
  'This patient was selected for recurring follow-up visits due to increased risk of avoidable acute events. Schedule a visit to review their risks and care plan.';
const RISK_FACTORS_NOT_ENABLED_REMOVAL_TEXT =
  'If these issues have already been addressed in another visit (e.g. annual), remove this action.';
const RISK_FACTORS_ENABLED_DESCRIPTION =
  'This patient was selected for follow-up visits (in person or telehealth) to address the following issues:';
const RISK_FACTORS_ENABLED_REMOVAL_TEXT =
  "If these issues have already been addressed in another visit (e.g. annual) feel free to remove this action. You'll see a new action when it's time for the next follow-up.";

export default function DynamicFormRiskFactors() {
  const { action } = useStepExecutionContext();
  const patientId = action?.v2Dto?.patientId || action?.patientId;
  const isShowRiskFactorsOnActionDetailsEnabled = useFeatureFlag(
    'showRiskFactorsOnActionDetails',
  );
  const {
    data: visitsAndDxHistoryData,
    isLoading: visitsAndDxHistoryIsLoading,
  } = useGetVisitsAndDxHistory(
    isShowRiskFactorsOnActionDetailsEnabled
      ? { patientIds: [patientId] }
      : { patientIds: [] },
  );
  const risks = visitsAndDxHistoryData[patientId]?.risks;

  if (visitsAndDxHistoryIsLoading && isShowRiskFactorsOnActionDetailsEnabled) {
    return null;
  }

  if (!isShowRiskFactorsOnActionDetailsEnabled || !risks) {
    return (
      <>
        <ModalInstructionalTextDisplay
          label={RISK_FACTORS_NOT_ENABLED_DESCRIPTION}
        />
        <ModalInstructionalTextDisplay
          label={RISK_FACTORS_NOT_ENABLED_REMOVAL_TEXT}
        />
      </>
    );
  }

  return (
    <>
      <ModalInstructionalTextDisplay label={RISK_FACTORS_ENABLED_DESCRIPTION} />
      <ul>
        {risks.map((risk) => (
          <li key={risk}>
            <FormLabel>{risk}</FormLabel>
          </li>
        ))}
      </ul>
      <ModalInstructionalTextDisplay
        label={RISK_FACTORS_ENABLED_REMOVAL_TEXT}
      />
    </>
  );
}

const ModalInstructionalTextDisplay = styled(ModalTextDisplay)`
  margin-bottom: 5px;
  margin-left: 0px;
  padding: 0px;
`;
