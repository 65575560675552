import { styled } from '@mui/material';

import { actionShape } from '@/utils/transformFrontendAction';

export default function PcpVisitDateOfService({ action }) {
  if (action?.v2Dto?.pcpVisitDateOfService) {
    return <Date>{action.v2Dto.pcpVisitDateOfService}</Date>;
  }
  return null;
}

PcpVisitDateOfService.propTypes = {
  action: actionShape.isRequired,
};

const Date = styled('span')`
  white-space: nowrap;
`;
