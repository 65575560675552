import { func } from 'prop-types';
import { useTheme } from '@mui/material';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';
import transformFlagHistory from '@/utils/transformFlagHistory';
import getFlagReasons from '@/utils/getFlagReasons';

import useUpdateFlag from '../hooks/useUpdateFlag';
import queryKeyFactory from '../queryKeyFactory';
import useGetTaskCounts from '../hooks/useGetTaskCounts';

import BaseFollowUpWidget from './BaseFollowUpWidget';
import IsFlaggedAlert from './IsFlaggedAlert';

export default function FollowUpWidget({ pendoIdPrefixer, userInputTask }) {
  const { flagHistories, id: taskId, isFlagged: isFlaggedInit } = userInputTask;

  const { data: { flagReasonFilterOptions = [] } = {} } = useGetTaskCounts();

  const [latestFlagHistory, setLatestFlagHistory] = useState(
    flagHistories.length > 0 ? flagHistories[0] : undefined,
  );
  const [isFlagged, setIsFlagged] = useState(isFlaggedInit);
  const [showPopover, setShowPopover] = useState(false);

  const queryClient = useQueryClient();

  const onUpdateFlagSuccess = (response) => {
    queryClient.invalidateQueries(queryKeyFactory.tasksSearch());
    queryClient.invalidateQueries(queryKeyFactory.patientsSearch());
    queryClient.invalidateQueries(queryKeyFactory.taskCounts());

    const flagHistory = transformFlagHistory(
      response.data.task.flag_histories[0],
    );
    setLatestFlagHistory(flagHistory);
    setIsFlagged(!isFlagged);
    setShowPopover(false);
  };

  const { isLoading, mutate, reset } = useUpdateFlag({
    onSuccess: onUpdateFlagSuccess,
  });

  const flagReasons = getFlagReasons(flagReasonFilterOptions);

  const handleCancel = () => {
    setShowPopover(false);
  };

  const handleIconClick = () => {
    if (!showPopover) {
      setShowPopover(true);
    }
  };

  const onSubmit = (data) => {
    reset();
    mutate({
      taskId,
      flagReason: isFlagged ? 'unflagged' : data.flagReason,
      note: data.note || undefined,
    });
  };

  const noteField = {
    name: 'note',
    label: 'Note (optional)',
    type: 'text',
    required: false,
    minRows: 1,
    placeholder: 'Enter note',
  };
  const theme = useTheme();
  const cancelButtonPendoId = pendoIdPrefixer('cancel');
  const iconPendoId = pendoIdPrefixer('open');

  const submitButtonLabel = isFlagged ? 'Resolve' : 'Save';
  const title = isFlagged ? 'Marked for followup' : 'Mark for followup';
  const lockIconActiveColor = Boolean(isFlagged);
  const fields = isFlagged
    ? [noteField]
    : [
        {
          name: 'flagReason',
          label: 'Reason',
          type: 'radiogroup',
          required: true,
          choices: flagReasons,
        },
        noteField,
      ];
  const alert = isFlagged ? (
    <IsFlaggedAlert flagHistory={latestFlagHistory} flagReasons={flagReasons} />
  ) : undefined;

  const submitButtonPendoId = isFlagged
    ? pendoIdPrefixer('resolve')
    : pendoIdPrefixer('confirm');

  return (
    <BaseFollowUpWidget
      theme={theme}
      submitButtonLabel={submitButtonLabel}
      title={title}
      lockIconActiveColor={lockIconActiveColor}
      alert={alert}
      fields={fields}
      isLoading={isLoading}
      onCancel={handleCancel}
      onIconClick={handleIconClick}
      onSubmit={onSubmit}
      showPopover={showPopover}
      cancelButtonPendoId={cancelButtonPendoId}
      iconPendoId={iconPendoId}
      submitButtonPendoId={submitButtonPendoId}
    />
  );
}

FollowUpWidget.propTypes = {
  pendoIdPrefixer: func,
  userInputTask: userInputTaskShape.isRequired,
};

FollowUpWidget.defaultProps = {
  pendoIdPrefixer: () => {},
};
