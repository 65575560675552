import PropTypes from 'prop-types';

import snakeToCamel from '@/utils/snakeToCamel';

export default function transformFeatureFlags(featureFlags) {
  return Object.keys(featureFlags).reduce(
    (acc, k) => ({ ...acc, [snakeToCamel(k)]: featureFlags[k] }),
    {},
  );
}

export const featureFlagsShape = PropTypes.shape({
  isStaff: PropTypes.bool,
  showMemberNumberOnSummary: PropTypes.bool,

  // Digital Patient Summary
  providerVisitsTab: PropTypes.bool,
  referralToFacility: PropTypes.bool,
  patientSummaryV2EnableCompleteWithoutCode: PropTypes.bool,
  patientSummaryV2LinkToLegacyPs: PropTypes.bool,
  patientSummaryV2LinkToMockEhr: PropTypes.bool,
  patientSummaryV2MorphDxWithoutCode: PropTypes.bool,
  psShowHistoricConditionsMenuItem: PropTypes.bool,
  summaryCopyPaste: PropTypes.bool,
  summaryCopyPasteLineBreaks: PropTypes.bool,
  hideFilesTabPsv2: PropTypes.bool,
  psViewPriorDateOfService: PropTypes.bool,

  // Print Patient Summary
  showMedicalGroupLogoInSummaryPdf: PropTypes.bool,
  enablePrintedSummaryRiskFactors: PropTypes.bool,
  enableClinicalRecommendations: PropTypes.bool,
});
