import { Typography, styled } from '@mui/material';
import PropTypes, { number } from 'prop-types';

export default function PatientExperienceRowFollowupSurvey({ value }) {
  return value[1] ? (
    <>
      <CurrentValue component="span">{value[0]}</CurrentValue>
      <MaximumValue component="span">/{value[1]}</MaximumValue>
    </>
  ) : (
    <CurrentValue component="span">--</CurrentValue>
  );
}

PatientExperienceRowFollowupSurvey.propTypes = {
  value: PropTypes.arrayOf(number).isRequired,
};

const MaximumValue = styled(Typography)`
  font-size: ${(p) => p.theme.typography.bodysmall?.fontSize};
  color: ${(p) => p.theme.palette.text.secondary};
`;

const CurrentValue = styled(Typography)`
  font-size: ${(p) => p.theme.typography.metricsmall?.fontSize};
`;
