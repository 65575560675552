import { styled } from '@mui/material';

import ModalSelect from '@/pages/PatientSummary/components/modals/ModalSelect';

const ModalRatingDropdown = styled(ModalSelect)`
  flex-direction: column;
  gap: 10px;
`;

ModalRatingDropdown.defaultProps = {
  InputSx: { width: '100%' },
};

export default ModalRatingDropdown;
