import styled from 'styled-components';
import { Alert, Link, alertClasses } from '@mui/material';
import { func, string } from 'prop-types';

export default function ErrorAlert({ handleCloseAlert, message }) {
  return (
    <StyledAlert severity="error" onClose={handleCloseAlert} sx={{ mb: 3 }}>
      {message}. Check your internet connection and try again. If the problem
      continues,{' '}
      <Link
        href="https://stellarhealth.zendesk.com/hc/en-us/requests/new?ticket_form_id=4426845056397"
        target="_blank"
        rel="noopener noreferrer"
      >
        contact support
      </Link>
    </StyledAlert>
  );
}

ErrorAlert.propTypes = {
  handleCloseAlert: func,
  message: string.isRequired,
};

ErrorAlert.defaultProps = {
  handleCloseAlert: undefined,
};

const StyledAlert = styled(Alert)`
  border: 1px solid;
  color: ${(p) => p.theme.palette.status.errorForeground};
  border-color: ${(p) => p.theme.palette.status.errorForeground};
  background-color: ${(p) => p.theme.palette.status.errorBackground};
  padding: ${(p) => p.theme.spacing(1, 2)};
  line-height: 1.5rem;

  a {
    color: ${(p) => p.theme.palette.status.errorForeground};
  }

  .${alertClasses.action} {
    margin-right: 0;
  }
`;
