/**
 * Convert a server-provided HCC value to a diagnosis representation within
 * the state manager.
 *
 * @param {HccResult} hccResult
 * @returns {Diagnosis}
 */
export default function hccToDiagnosis(hccResult) {
  return {
    description: hccResult.label,
    icd: hccResult.value,
    pastDiagnoses: [], // ??
    raf: true, // this needs to be set from the hcc result, likely??
    hcc: hccResult.hcc,
  };
}
