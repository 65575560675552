import { useEffect } from 'react';

import palette from '@/themeV2/palette';
import typography from '@/themeV2/typography';
import { inputStyles } from '@/themeV2/components';

const customTheme = {
  text: {
    componentLabelSize: '14px',
    componentLabelWeight: 'bold',
    componentSize: '12px',
    fontFamily: typography.fontFamily,
    size: typography.bodymedium.fontSize,
    weight: typography.bodymedium.fontWeight,
  },
  shape: {
    componentBorderWidth: '1px',
    componentCornerRadius: inputStyles.borderRadius,
  },
  color: {
    background: palette.background.base,
    componentBackground: palette.background.base,
    componentBorder: palette.border.input,
    componentPlaceholderText: palette.text.placeholder,
    componentText: palette.text.primary,
    danger: palette.status.errorForeground,
    primary: palette.border.input,
    text: palette.text.primary,
  },
  button: {
    color: {
      activeBackground: palette.background.primary,
      disabledBackground: '#BFBFCA',
      disabledText: palette.text.primaryInverse,
      loadingBackground: '#e0e0e0',
      loadingBorder: '#e0e0e0',
      loadingText: palette.text.placeholder,
    },
    shape: {
      borderWidth: '1px',
      cornerRadius: inputStyles.borderRadius,
    },
    text: {
      fontFamily: typography.fontFamily,
      size: typography.buttonextralarge.fontSize,
      weight: typography.buttonextralarge.fontWeight,
    },
  },
};

const customContent = {
  submitButton: 'Continue',
  loadingButton: 'Verifying',
  loadingPrompt: 'Please wait',
};

const useAbound = ({
  targetId,
  accessToken,
  onSubmitSuccess,
  onSubmit,
  onLoadError,
  onSubmitError,
}) => {
  useEffect(() => {
    const fetchData = async () => {
      // temporary v3 fallback
      // v4 access tokens are 340 chars while v3 are 456
      if (accessToken.length > 400) {
        const AboundClient = await import(
          // eslint-disable-next-line import/extensions,import/no-unresolved
          /* webpackIgnore: true */ 'https://js.withabound.com/latest/abound-client-sdk.js'
        );
        // eslint-disable-next-line new-cap
        const abound = new AboundClient.default({
          accessToken,
        });

        abound.renderTaxProfile({
          targetId,
          onSubmitSuccess: () =>
            onSubmitSuccess({ payee: { tinVerificationStatus: 'MATCH' } }),
          onSubmitError,
          theme: customTheme,
          content: customContent,
        });
        return;
      }

      const AboundClient = await import(
        // eslint-disable-next-line import/extensions,import/no-unresolved
        /* webpackIgnore: true */ 'https://js.withabound.com/latest-major/abound-client-sdk.js'
      );

      AboundClient.renderW9Collection({
        accessToken,
        targetId,
        onSubmitSuccess,
        onSubmitError,
        onSubmit,
        theme: customTheme,
        content: customContent,
      });
    };

    fetchData().catch(() => {
      if (onLoadError) {
        onLoadError();
      }
    });
  }, [
    accessToken,
    targetId,
    onSubmitSuccess,
    onSubmit,
    onLoadError,
    onSubmitError,
  ]);
};

export default useAbound;
