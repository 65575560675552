import styled from 'styled-components';

import { ReactComponent as HiddenIcon } from '@/assets/icons/eye.svg';

export default function HideForNowIcon() {
  return <StyledHiddenIcon />;
}

const StyledHiddenIcon = styled(HiddenIcon)`
  color: ${(p) => p.theme.palette.background.primary};
  height: 13px;
  width: 16px;
  margin-left: ${(p) => p.theme.spacing(1)};
`;
