import PropTypes from 'prop-types';
import { FormLabel } from '@mui/material';
import useId from '@mui/utils/useId';

import RhfInput from '@/components/reactHookFormComponents/RhfInput';
import InputContainer from '@/pages/PaymentInstructions/components/InputContainer';
import FormErrorMessage from '@/components/FormErrorMessage';
import stringIsPositiveWholeNumber from '@/pages/PaymentInstructions/utils/stringIsPositiveWholeNumber';

const INVALID_ACCOUNT_NUMBER_ERROR = 'Invalid bank account number.';

export default function BankAccountNumberInputs({
  bankAccountNumberInputName,
  confirmBankAccountNumberInputName,
  control,
  formErrors,
  getValues,
}) {
  const bankAccountNumberId = useId();
  const confirmBankAccountNumberId = useId();

  return (
    <>
      <InputContainer>
        <FormLabel htmlFor={bankAccountNumberId}>Bank account number</FormLabel>
        <RhfInput
          name={bankAccountNumberInputName}
          id={bankAccountNumberId}
          control={control}
          type="password"
          rules={{
            required: true,
            minLength: {
              value: 5,
              message: INVALID_ACCOUNT_NUMBER_ERROR,
            },
            maxLength: {
              value: 18,
              message: INVALID_ACCOUNT_NUMBER_ERROR,
            },
            validate: {
              validAccountNumber: (v) =>
                stringIsPositiveWholeNumber(v) || INVALID_ACCOUNT_NUMBER_ERROR,
            },
          }}
        />
        <FormErrorMessage
          errors={formErrors}
          name={bankAccountNumberInputName}
        />
      </InputContainer>
      <InputContainer>
        <FormLabel htmlFor={confirmBankAccountNumberId}>
          Confirm bank account number
        </FormLabel>
        <RhfInput
          name={confirmBankAccountNumberInputName}
          id={confirmBankAccountNumberId}
          control={control}
          type="password"
          rules={{
            required: true,
            validate: {
              numbersMatching: (v) =>
                v === getValues(bankAccountNumberInputName) ||
                'Bank account numbers do not match.',
              validAccountNumber: (v) =>
                stringIsPositiveWholeNumber(v) || INVALID_ACCOUNT_NUMBER_ERROR,
            },
          }}
        />
        <FormErrorMessage
          errors={formErrors}
          name={confirmBankAccountNumberInputName}
        />
      </InputContainer>
    </>
  );
}

BankAccountNumberInputs.propTypes = {
  bankAccountNumberInputName: PropTypes.string.isRequired,
  confirmBankAccountNumberInputName: PropTypes.string.isRequired,
  formErrors: PropTypes.objectOf(PropTypes.object),
  getValues: PropTypes.func.isRequired,
};

BankAccountNumberInputs.defaultProps = { formErrors: {} };
