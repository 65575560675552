import PropTypes from 'prop-types';
import { Typography, css, styled } from '@mui/material';
import first from 'lodash/first';

import { Priority } from '../constants';

/**
 * Returns the priority label for the given score.
 * The ranges and labels must match the backend tbw_export.py file.
 * @param {number} score
 * @returns
 */
function getPriority(score) {
  if (score >= 8) return Priority.urgent;
  if (score >= 6) return Priority.veryHigh;
  if (score >= 4) return Priority.high;
  if (score >= 2) return Priority.moderate;
  return Priority.standard;
}

function getColors(priority) {
  switch (priority) {
    case Priority.urgent:
      return {
        color: 'text.primaryInverse',
        bgcolor: 'status.errorForeground',
      };
    case Priority.veryHigh:
      return {
        color: 'status.errorForeground',
        bgcolor: 'status.errorBackground',
      };
    case Priority.high:
      return {
        color: 'status.warningForeground',
        bgcolor: 'status.warningBackground',
      };
    case Priority.moderate:
      return {
        color: 'status.moderateForeground',
        bgcolor: 'status.moderateBackground',
      };
    case Priority.standard:
      return {
        color: 'status.noneForeground',
        bgcolor: 'status.noneBackground',
      };
    default:
      return {
        color: undefined,
        bgcolor: undefined,
      };
  }
}

/**
 * This component consumes a task's `score` field and displays a corresponding badge.
 */
export default function PriorityScoreBadge({ score, abbreviated, sx }) {
  const priority = getPriority(score);
  const content = abbreviated
    ? // Display only the first letter of each word
      priority.split(' ').map(first).join('')
    : priority;

  const { color, bgcolor } = getColors(priority);

  return (
    <PriorityScoreBadgeRoot
      $abbreviated={abbreviated}
      sx={[{ color, bgcolor }, sx]}
    >
      <Typography variant="bodysmallbold" component="span">
        {content}
      </Typography>
    </PriorityScoreBadgeRoot>
  );
}

PriorityScoreBadge.propTypes = {
  // abbreviated will cause only the first letter of the priority to render.
  abbreviated: PropTypes.bool,
  // detailed will cause the priority to show up as "{Priority} Priority".
  score: PropTypes.number.isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PriorityScoreBadge.defaultProps = {
  abbreviated: false,
  sx: undefined,
};

const PriorityScoreBadgeRoot = styled('span')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: ${(p) => p.theme.spacing(0, 1)};
  border-radius: ${(p) => 4 * p.theme.shape.borderRadius}px;
  vertical-align: middle;

  ${(p) =>
    p.$abbreviated &&
    css`
      // The badge should be a circle if abbreviated.
      width: 24px;
      height: 24px;
      padding: 0;
    `}
`;
