import { camelCase, isEmpty } from 'lodash';
import { parseISO } from 'date-fns';

import { POSITION_FORM_COMPONENT } from '@/pages/PatientSummary/utils/executionRequirementUtils';
import enhanceStepExecutionRequirements, {
  dynamicPropertiesByStepId,
} from '@/utils/enhanceStepExecutionRequirements';

function getDisplayOnlyProperties(
  visibleExecutionRequirements,
  step,
  position,
) {
  const actionServiceDefinedExecReqs = visibleExecutionRequirements.map(
    (er) => er.name,
  );

  const additionalDisplayOnlyProperties = [];
  if (dynamicPropertiesByStepId[step.stepId]) {
    Object.entries(dynamicPropertiesByStepId[step.stepId]).forEach(
      ([execReqName, execReq]) => {
        if (
          !actionServiceDefinedExecReqs.includes(execReqName) &&
          execReq.position === position
        ) {
          additionalDisplayOnlyProperties.push(execReq);
        }
      },
    );
  }

  return additionalDisplayOnlyProperties;
}

const STEPS_TO_INCLUDE_GAP_CLOSE_DATE_MESSAGE = [
  'breast_cancer_screening-base-1-complete_bcs-1',
];

/**
 * Given a Step from the ActionService from Patient Outcomes team
 * extract all the execution requirements that we desire to show
 * on the PatientSummary DynamicFormModal
 * Exclude any requirements that have visible_to_user=False
 *
 * Please see transformStep in transformFrontendCareGapAction.js for step shape
 * @param step
 * @returns [{}]
 */
export default function getVisibleStepExecutionRequirements(step) {
  if (step === undefined || !Array.isArray(step.executionRequirements)) {
    return [];
  }

  const filteredExecutionRequirements = step.executionRequirements.filter(
    (req) => req && req.visible_to_user !== false,
  );

  // Gather display only properties meant to be presented at the
  // very top of the Dynamic Modal
  const displayOnlyPropertiesTop = getDisplayOnlyProperties(
    filteredExecutionRequirements,
    step,
    POSITION_FORM_COMPONENT.TOP,
  );

  // Gather display only properties meant to be presented at the
  // very bottom of the Dynamic Modal
  const displayOnlyPropertiesBottom = getDisplayOnlyProperties(
    filteredExecutionRequirements,
    step,
    POSITION_FORM_COMPONENT.BOTTOM,
  );

  // Sandwich ActionService defined execution requirements
  // between the top and bottom display only elements
  let allRequirements = displayOnlyPropertiesTop.concat(
    filteredExecutionRequirements.map((req) =>
      enhanceStepExecutionRequirements(
        req,
        step,
        STEPS_TO_INCLUDE_GAP_CLOSE_DATE_MESSAGE,
      ),
    ),
  );
  allRequirements = allRequirements.concat(displayOnlyPropertiesBottom);

  return allRequirements;
}

// Collect data from stepHistories by merging every key from their executionInfo
// If fields appear multiple times, the most recent data will be used
// as stepHistories appear in order from least recent to most
export function getHistoricData(action) {
  return action.stepHistories?.length > 0
    ? action.stepHistories.reduce((prev, currHistory) => {
        return { ...prev, ...currHistory.executionInfo };
      }, {})
    : null;
}

// If there's past data found, use the values to replace the label text
// wherever the field name appears, insert the corresponding value
// Ex: "Dose 1 Provider: dose_1_provider" in the .yml
// Becomes: "Dose 1 Provider: Doctor Name" in the executionRequirements
export function includeHistoricContextualData(
  executionRequirements,
  defaultValues,
  historicData,
  contextualData,
) {
  const newDefaults = { ...defaultValues };
  let newExecReqs = [];
  const data = { ...historicData, ...contextualData };

  if (data && !isEmpty(executionRequirements)) {
    executionRequirements.forEach((req) => {
      const newReq = { ...req };
      Object.entries(data).forEach(([key, value]) => {
        if (newReq.name === key || camelCase(newReq.name) === key) {
          if (newReq.type === 'label') {
            newReq.label = req.label.replace(String(newReq.name), value);
          } else if (newReq.type === 'date') {
            newDefaults[newReq.name] = parseISO(value);
          }
        }
      });
      newExecReqs.push(newReq);
    });
  } else {
    newExecReqs = executionRequirements;
  }

  return [newExecReqs, newDefaults];
}

// Injects pendoIdPrefixer and data-pendo-id attributes into the form fields
// and adds properties to the textarea field.
export function adjustPxExecutionRequirements(
  executionRequirements,
  pendoIdPrefixer,
) {
  return executionRequirements.map((field) => ({
    ...field,
    // Provide both `pendoIdPrefixer` and `data-pendo-id`.
    // The field component will use whichever is applicable.
    pendoIdPrefixer,
    'data-pendo-id': pendoIdPrefixer(field.name),
    ...(field.type === 'textarea' && {
      minRows: 1,
      placeholder: 'Enter note',
      InputSx: {
        width: '60%',
      },
    }),
  }));
}
