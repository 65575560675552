/* eslint-disable react/forbid-prop-types */
import useId from '@mui/utils/useId';
import { Box, FormLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

import FormErrorMessage from '@/components/FormErrorMessage';
import BaseSelect from '@/components/BaseSelect';
import useGetUserFilters from '@/hooks/useGetUserFilters';
import transformOption, { getDefaultValue } from '@/utils/transformOption';

export default function ModalProviderDropdown({
  label,
  defaultValue,
  errorMsg,
  BoxSx,
  InputSx,
  inputProps,
  name,
  control,
  errors,
}) {
  const fieldId = useId();
  const { resetField } = useFormContext();
  const { data } = useGetUserFilters({
    onSuccess: (data) => {
      const dataDefaultValue = getDefaultValue(data.providers);
      if (dataDefaultValue) {
        resetField(name, { defaultValue: dataDefaultValue });
      }
    },
  });
  const { providers = [] } = data || {};

  const options = useMemo(() => providers.map(transformOption), [providers]);

  return (
    <Box
      sx={{ display: 'flex', mt: 1, justifyContent: 'space-between', ...BoxSx }}
    >
      <FormLabel htmlFor={fieldId} sx={{ mr: 1, mt: 1 }}>
        {label}
      </FormLabel>
      <div style={{ width: InputSx.width ? InputSx.width : {} }}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || getDefaultValue(data?.providers)}
          rules={{
            required: errorMsg,
          }}
          render={({ field }) => {
            return (
              <BaseSelect
                label={label}
                options={options}
                id={fieldId}
                sx={InputSx}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                inputProps={inputProps}
              />
            );
          }}
        />
        <FormErrorMessage name={name} errors={errors} />
      </div>
    </Box>
  );
}

ModalProviderDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  BoxSx: PropTypes.object,
  InputSx: PropTypes.object,
  inputProps: PropTypes.shape({ 'data-testid': PropTypes.string }),
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

ModalProviderDropdown.defaultProps = {
  errorMsg: undefined,
  defaultValue: '',
  BoxSx: {},
  InputSx: { width: 200 },
  inputProps: {},
};
