/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line filenames/match-exported
import PropTypes from 'prop-types';
import { Box, Divider, Stack, ThemeProvider, css, styled } from '@mui/material';
import { kebabCase } from 'lodash';

import themeV2 from '@/themeV2';
import SvuTracker from '@/widgets/SvuTracker/SvuTracker';

import { ReactComponent as StellarHealthSmallLogo } from '../assets/stellar-health-small.svg';

import UserDropdown from './UserDropdown';
import PatientSearch from './PatientSearch';

// The duration of transitions in milliseconds;
const TRANSITION_TIMEOUT = 150;

function HomeLogo({ logoDataUrl }) {
  return (
    <ListItemLink
      href="/"
      aria-label="Stellar Health"
      sx={(theme) => ({
        width: 'auto',
        maxWidth: '200px',
        [theme.breakpoints.down('lg')]: {
          maxWidth: '120px',
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: '80px',
        },
      })}
    >
      {logoDataUrl ? (
        <Box component="img" alt="Home" src={logoDataUrl} width="100%" />
      ) : (
        <StellarHealthSmallLogo />
      )}
    </ListItemLink>
  );
}
HomeLogo.propTypes = {
  logoDataUrl: PropTypes.string,
};
HomeLogo.defaultProps = {
  logoDataUrl: undefined,
};

function NavItemLink({ href, label, openInNewTab }) {
  return (
    <ListItemLink
      $active={
        // TODO: Pass in this value from the backend
        window.location.pathname.indexOf(href) > -1
      }
      data-pendo-id={`navbar-${kebabCase(label)}`}
      href={href}
      target={openInNewTab ? '_blank' : undefined}
      rel={openInNewTab ? 'noopener noreferrer' : undefined}
      role="listitem"
    >
      {label}
    </ListItemLink>
  );
}
NavItemLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool,
};
NavItemLink.defaultProps = {
  openInNewTab: false,
};

function TheGlobalNavbar({ navbarConfigs, sticky }) {
  const { userDropdown, navbarSections, userIsAuthenticated, logoDataUrl } =
    navbarConfigs;

  return (
    <TheGlobalNavbarRoot $sticky={sticky}>
      <HomeLogo logoDataUrl={logoDataUrl} />

      <Stack
        direction="row"
        role="list"
        component="nav"
        divider={<NavbarDivider />}
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            gridColumn: '2 / span 2',
          },
        })}
      >
        {navbarSections.map((section, index) => (
          <Stack direction="row" key={index}>
            {section.map(({ href, label, openInNewTab }) => (
              <NavItemLink
                key={label}
                href={href}
                label={label}
                openInNewTab={openInNewTab}
              />
            ))}
          </Stack>
        ))}
      </Stack>
      {userIsAuthenticated && (
        <PatientSearch
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('xl')]: {
              gridRow: '2',
              gridColumn: '2 / -1',
              ml: 1.5,
            },
            [theme.breakpoints.between('lg', 'xl')]: {
              mr: 1.5,
            },
            [theme.breakpoints.down('lg')]: {
              gridColumn: '2',
            },
          })}
        />
      )}

      {userIsAuthenticated && (
        <Stack
          direction="row"
          alignItems="center"
          sx={(theme) => ({
            [theme.breakpoints.down('xl')]: {
              gridRow: '1',
              gridColumn: '3',
              justifySelf: 'end',
            },
            [theme.breakpoints.down('lg')]: {
              gridRow: '2',
              gridColumn: '3',
            },
          })}
        >
          <SvuTracker sx={{ ml: 1.5 }} />

          <UserDropdown userDropdown={userDropdown} sx={{ ml: 2 }} />
        </Stack>
      )}
    </TheGlobalNavbarRoot>
  );
}
TheGlobalNavbar.propTypes = {
  navbarConfigs: PropTypes.shape({
    userDropdown: PropTypes.object,
    navbarSections: PropTypes.array.isRequired,
    userIsAuthenticated: PropTypes.bool.isRequired,
    logoDataUrl: PropTypes.string,
  }).isRequired,
  sticky: PropTypes.bool,
};
TheGlobalNavbar.defaultProps = {
  sticky: true,
};

export default function TheGlobalNavbarContainer(props) {
  return (
    <ThemeProvider theme={themeV2}>
      <TheGlobalNavbar {...props} />
    </ThemeProvider>
  );
}

const TheGlobalNavbarRoot = styled('div')`
  background-color: ${(p) => p.theme.palette.background.base};
  border-bottom: 1px solid ${(p) => p.theme.palette.border.base};
  padding: ${(p) => p.theme.spacing(0, 1)};
  display: grid;
  align-items: stretch;
  grid-template-columns: auto auto 1fr auto;
  grid-auto-rows: 80px;

  ${(p) => p.theme.breakpoints.down('xl')} {
    grid-template-columns: auto 1fr auto;
  }

  ${(p) =>
    p.$sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `}
`;

const ListItemLink = styled('a')`
  text-decoration: none !important;
  width: 80px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.2rem;
  transition: color ${TRANSITION_TIMEOUT}ms;
  margin: ${(p) => p.theme.spacing(0, 1.5)};
  color: ${(p) => p.theme.palette.text.primary};
  position: relative;
  outline: none;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${(p) => p.theme.palette.text.link};
    left: 0;
    right: 0;
    bottom: 0px;
    height: 3px;
    transform: scaleX(0);
    transition: transform ${TRANSITION_TIMEOUT}ms;
  }

  &:hover,
  &:focus-visible {
    color: ${(p) => p.theme.palette.text.link};

    :before {
      transform: scaleX(1);
    }
  }

  ${(p) =>
    p.$active &&
    css`
      &:not(:hover):not(:focus-visible):before {
        transform: scaleX(1);
        background-color: ${p.theme.palette.text.secondary};
      }
    `}
`;

const NavbarDivider = styled((props) => (
  <Divider flexItem orientation="vertical" {...props} />
))`
  height: 58px;
  margin-top: auto;
  margin-bottom: auto;
`;
