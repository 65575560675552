import PropTypes from 'prop-types';
import { createGlobalStyle, css } from 'styled-components';

const FOOTER_SELECTOR = '#stellar-app-footer';

const FooterStyleOverrides = createGlobalStyle`
${(p) =>
  p.$removeFooter &&
  css`
    ${FOOTER_SELECTOR} {
      display: none;
    }
  `}
`;

/**
 * A component to manipulate the legacy footer.
 */
export default function TheLegacyFooterAdapter({ removeFooter }) {
  return <FooterStyleOverrides $removeFooter={removeFooter} />;
}

TheLegacyFooterAdapter.propTypes = {
  removeFooter: PropTypes.bool,
};

TheLegacyFooterAdapter.defaultProps = {
  removeFooter: false,
};
