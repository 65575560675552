import styled from 'styled-components';

export const PENDING_REVIEW = 'pending_review';
export const COMPLETED = 'completed';
export const IN_PROGRESS = 'in_progress';

export function getTextColorForStatus(status) {
  if (status === PENDING_REVIEW) return 'status.infoForeground';
  if (status === COMPLETED) return 'status.successForeground';
  return 'text.primary';
}

export const SvuContainer = styled('span')`
  color: ${(p) => {
    if (p.$status === COMPLETED) {
      return p.theme.palette.status.successForeground;
    }
    if (p.$status === PENDING_REVIEW) {
      return p.theme.palette.status.infoForeground;
    }
    return p.theme.palette.svu.dark;
  }};
  display: inline-flex;
  align-items: center;
  padding: ${(p) => p.theme.spacing(0.5, 1)};
  background-color: ${(p) => {
    if (p.$status === COMPLETED) {
      return p.theme.palette.status.successBackground;
    }
    if (p.$status === PENDING_REVIEW) {
      return p.theme.palette.status.infoBackground;
    }
    return p.theme.palette.svu.lightest;
  }};
  border-radius: ${(p) => p.theme.shape.borderRadius * 4}px;
`;
