import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

export const FieldSkeletonSizes = {
  date: 'date',
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export default function FieldSkeleton({ size }) {
  let width;
  if (size === FieldSkeletonSizes.small) {
    width = 80;
  } else if (size === FieldSkeletonSizes.medium) {
    width = 100;
  } else if (size === FieldSkeletonSizes.large) {
    width = 120;
  }

  return <Skeleton variant="rounded" height={20} width={width} />;
}

FieldSkeleton.propTypes = {
  size: PropTypes.oneOf(Object.values(FieldSkeletonSizes)),
};

FieldSkeleton.defaultProps = {
  size: FieldSkeletonSizes.medium,
};
