import { Box, styled } from '@mui/material';
import { bool, elementType, func, node, number } from 'prop-types';

import { color, spacing } from '@/utils/stylingUtils';

import CompactPatientSummarySectionHeader from './CompactPatientSummarySectionHeader';

/**
 * @param {React.ReactNode} children  The body of the section.
 * @param {React.ReactNode} headline  The title of the section.
 * @param {React.ReactElement} icon  The icon that will go next to the section title, if any.
 * @param {() => void?} onSetSelected  If specified, it will trigger an event when the user "selects" (clicks on) this section.
 * @param {boolean?} selected  If true, this section will appear in a selected state.
 * @param {number?} svus  If specified, the total number of SVUs on offer for the actions within the section.
 * @returns {React.ReactElement}
 */
export default function CompactPatientSummarySection({
  children,
  headline,
  icon,
  onSetSelected,
  selected,
  svus,
  aboveBodySlot,
}) {
  const handleClick = () => {
    if (onSetSelected) onSetSelected();
  };

  return (
    <Box>
      <CompactPatientSummarySectionHeader
        headline={headline}
        icon={icon}
        selected={selected}
        svus={svus}
      />
      {aboveBodySlot}
      <BodyContainer selected={selected} onClick={handleClick}>
        {children}
      </BodyContainer>
    </Box>
  );
}
CompactPatientSummarySection.propTypes = {
  children: node.isRequired,
  headline: node.isRequired,
  icon: elementType,
  onSetSelected: func,
  selected: bool,
  svus: number,
  aboveBodySlot: node,
};
CompactPatientSummarySection.defaultProps = {
  icon: undefined,
  onSetSelected: undefined,
  selected: false,
  svus: undefined,
  aboveBodySlot: undefined,
};

const BodyContainer = styled(Box)`
  background-color: ${color('background.base')};
  border: 1px solid
    ${({ selected }) =>
      selected ? color('background.primaryDarkest') : color('border.base')};
  color: ${(p) => p.theme.palette.text.primary};
  margin-bottom: ${spacing(1)};
  padding: ${spacing(1)};
`;
