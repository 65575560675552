import { actionShape } from '@/utils/transformFrontendAction';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';

export default function ActionTestType({ action }) {
  const { data: contextualInfo, isLoading } = useGetContextualInfo(action);

  return isLoading ? <FieldSkeleton /> : contextualInfo.testType || '--';
}

ActionTestType.propTypes = {
  action: actionShape.isRequired,
};
