import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Accordion,
  AccordionSummary,
  Button,
  Divider,
  Drawer,
  FormHelperText,
  FormLabel,
  Grid,
  accordionSummaryClasses,
} from '@mui/material';
import { drawerClasses } from '@mui/material/Drawer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import BaseForm from '@/components/BaseForm';
import SidebarItem from '@ot/pages/Challenges/ChallengesView/components/SidebarItem';
import { challengeShape } from '@ot/pages/Challenges/ChallengesView/hooks/useGetChallenges';
import ErrorMessages from '@ot/pages/Challenges/ChallengesView/components/ErrorMessages';
import { formatDateIgnoreTimeZone } from '@/utils/formatDate';
import RhfCheckbox from '@ot/components/reactHookFormComponents/RhfCheckbox';
import RhfDatePicker from '@ot/components/reactHookFormComponents/RhfDatePicker';
import RhfInput from '@ot/components/reactHookFormComponents/RhfInput';
import RhfTextField from '@ot/components/reactHookFormComponents/RhfTextField';
import RhfTextArea from '@ot/components/reactHookFormComponents/RhfTextArea';
import NestedChallengesDetails from '@ot/pages/Challenges/ChallengesView/components/NestedChallengesDetails';
import PlanDetails from '@ot/pages/Challenges/ChallengesView/components/PlanDetails';

const HTML_IDS = {
  challengeName: 'challenge-name',
  challengeDescription: 'challenge-description',
  rewardText: 'reward-text',
  revealDate: 'reveal-date',
  startDate: 'start-date',
  endDate: 'end-date',
  svuAwardFields: 'svu-award-fields',
  goal: 'goal',
  hidden: 'hidden',
};

function DetailsSidebar({
  challenge,
  activityLogs,
  onClose,
  open,
  onApproveChallenge,
  isChallengeNotEditable,
  isEditEnabled,
  onEnableEdit,
  isSubmitting,
  errors,
  updateFailedMessages,
  handleSubmit,
  onSubmitSaveChanges,
  onSubmitCancel,
  validateEndDate,
  validateGoal,
  isDirty,
  control,
}) {
  // Sort plan names
  const planNamesSorted = challenge?.planNames
    ? `${
        challenge.planNames.split(',').length
      } total plan(s): ${challenge.planNames
        .split(',')
        .map((s) => s.trim())
        .sort(function sortCaseInsensitive(a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        })
        .join(', ')}`
    : 'N/A';

  const isApproveButtonShown =
    (challenge?.type === 'custom' && challenge?.status === 'in_progress') ||
    challenge?.status === 'pending_review';

  return (
    <StyledDrawer
      anchor="right"
      width="100px"
      open={open}
      onClose={onClose}
      BackdropProps={{ 'data-testid': 'backdrop' }}
    >
      {challenge && (
        <BaseForm onSubmit={handleSubmit(onSubmitSaveChanges)}>
          <Accordion disableGutters defaultExpanded>
            <StyledAccordionSummary
              sx={{ backgroundColor: 'lightgray' }}
              expandIcon={<ExpandMoreIcon />}
            >
              <SectionHeading>Basic Information</SectionHeading>
            </StyledAccordionSummary>
            <StyledSection>
              <Box sx={{ m: 1, my: 2 }}>
                {challenge.status &&
                isChallengeNotEditable(
                  challenge.type,
                  challenge.status,
                  challenge.endDate,
                ) ? (
                  <Typography variant="h3" gutterBottom component="div">
                    Editing disabled since the Challenge is either completed or
                    expired
                  </Typography>
                ) : (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isEditEnabled}
                        disabled={isSubmitting}
                        onChange={onEnableEdit}
                      />
                    }
                    label="Enable Editing"
                  />
                )}
              </Box>
              <Grid container>
                {isEditEnabled ? (
                  <Grid item xs={6} sx={{ p: 1 }}>
                    <RhfTextField
                      id={HTML_IDS.challengeName}
                      name="challengeName"
                      control={control}
                      rules={{ required: true }}
                      label="Challenge Name:"
                      placeholder="Challenge Name"
                      sx={{ width: 376 }}
                    />
                    {errors.challengeName?.type === 'required' && (
                      <FormHelperText error>
                        Challenge name required
                      </FormHelperText>
                    )}
                  </Grid>
                ) : (
                  <SidebarItem title="Challenge Name" value={challenge.name} />
                )}
                <SidebarItem title="Type" value={challenge.type} />
                {challenge.type === 'stamp_card' && (
                  <SidebarItem title="Grid Size" value={challenge.gridSize} />
                )}
                {challenge.parentChallengeId && (
                  <SidebarItem
                    title="Parent Challenge"
                    value={challenge.parentChallengeId}
                  />
                )}
                <SidebarItem
                  title="Challenge Template"
                  value={challenge.templateName || 'N/A'}
                  width={6}
                />
                {challenge.curriculumId && (
                  <SidebarItem
                    title="Curriculum ID"
                    value={challenge.curriculumId}
                  />
                )}
                {isEditEnabled ? (
                  <Grid item xs={6} sx={{ pl: 1 }}>
                    <FormLabel htmlFor={HTML_IDS.svuAwardFields} sx={{ mt: 2 }}>
                      SVU Award Amount:
                    </FormLabel>
                    <RhfInput
                      name="challengeSVUAward"
                      type="number"
                      control={control}
                      placeholder="SVU Award Amount"
                      id={HTML_IDS.svuAwardFields}
                      rules={{
                        required: true,
                        min: 0,
                      }}
                    />
                    {errors?.challengeSVUAward?.type === 'required' && (
                      <FormHelperText error>
                        Svu award value is required
                      </FormHelperText>
                    )}
                    {errors?.challengeSVUAward?.type === 'min' && (
                      <FormHelperText error>
                        Svu award value must be greater than or equal to 0
                      </FormHelperText>
                    )}
                  </Grid>
                ) : (
                  <SidebarItem
                    title="SVU Award Amount"
                    value={challenge.svuAward}
                  />
                )}
                <SidebarItem
                  title="Medical Group"
                  value={`${challenge.mgName} (TIN: ${challenge.mgTin})`}
                />
                <SidebarItem
                  title="Practice User (user challenge only)"
                  value={challenge.userNameUnique || 'N/A'}
                />
                <SidebarItem
                  title="Start Date"
                  value={formatDateIgnoreTimeZone(challenge.startDate)}
                />
                {/* We can't edit the end date on nested challenges directly. It is done by editing the end date of the parent challenge */}
                {isEditEnabled &&
                !challenge.parentChallengeId &&
                !challenge.curriculumId ? (
                  <Grid item xs={6} sx={{ pl: 1 }}>
                    <FormLabel htmlFor={HTML_IDS.endDate} sx={{ mt: 2 }}>
                      End Date:
                    </FormLabel>
                    <RhfDatePicker
                      name="challengeEndDate"
                      rules={{
                        required: true,
                        validate: (endDate) => validateEndDate(endDate),
                      }}
                      id={HTML_IDS.endDate}
                      control={control}
                    />
                    {errors.challengeEndDate?.type === 'required' && (
                      <FormHelperText error>End date required</FormHelperText>
                    )}
                    {errors.challengeEndDate?.type === 'validate' && (
                      <FormHelperText error>
                        End Date cannot be before today&#39;s date
                      </FormHelperText>
                    )}
                  </Grid>
                ) : (
                  <SidebarItem
                    title="End Date"
                    value={formatDateIgnoreTimeZone(challenge.endDate)}
                  />
                )}
                <SidebarItem
                  title="Reveal Date"
                  value={formatDateIgnoreTimeZone(challenge.revealDate)}
                />
                <SidebarItem
                  title="Approval Date"
                  value={formatDateIgnoreTimeZone(challenge.approvalDate)}
                />
                <SidebarItem title="Patient Tag" value={challenge.patientTag} />
                <SidebarItem
                  title="Selected Plans"
                  value={planNamesSorted}
                  width={12}
                />
              </Grid>
            </StyledSection>
          </Accordion>
          <Divider />
          <Accordion disableGutters>
            <StyledAccordionSummary
              sx={{ backgroundColor: 'gainsboro' }}
              expandIcon={<ExpandMoreIcon />}
            >
              <SectionHeading>Additional Details</SectionHeading>
            </StyledAccordionSummary>
            <StyledSection>
              <Grid container>
                <SidebarItem
                  title="SVU Award Amount"
                  value={challenge.svuAward}
                />
                <SidebarItem
                  title="SVU awarded to"
                  value={challenge.svusAwardedTo}
                />
                <SidebarItem
                  title="SVU split info"
                  value={challenge.svuSplitInfo}
                />
                {!challenge.parentChallengeId &&
                  (isEditEnabled ? (
                    <Grid item xs={6} sx={{ pl: 1 }}>
                      <FormLabel htmlFor={HTML_IDS.hidden} sx={{ mt: 2 }}>
                        Hidden:
                      </FormLabel>
                      <RhfCheckbox
                        name="challengeHidden"
                        label="hidden"
                        control={control}
                        defaultValue={false}
                        id={HTML_IDS.hidden}
                      />
                    </Grid>
                  ) : (
                    <SidebarItem
                      title="Hidden"
                      value={challenge.hidden.toString()}
                    />
                  ))}
                <SidebarItem
                  title="Created by"
                  value={challenge.createdByEmail}
                />
                <SidebarItem
                  title="Challenge Template"
                  value={challenge.templateName || 'N/A'}
                />
                <SidebarItem
                  title="Condition Groups"
                  value={challenge.conditionGroupNames}
                  width={12}
                />
                {isEditEnabled ? (
                  <Grid item xs={12} sx={{ pl: 1 }}>
                    <FormLabel
                      htmlFor={HTML_IDS.challengeDescription}
                      sx={{ mt: 2 }}
                    >
                      Description:
                    </FormLabel>
                    <RhfTextArea
                      id={HTML_IDS.challengeDescription}
                      name="challengeDescription"
                      control={control}
                      rules={{ required: true }}
                      labelId={HTML_IDS.challengeDescription}
                      placeholder="Enter challenge description"
                    />
                    {errors.challengeDescription?.type === 'required' && (
                      <FormHelperText error>
                        Challenge description required
                      </FormHelperText>
                    )}
                  </Grid>
                ) : (
                  <SidebarItem
                    title="Description"
                    value={challenge.description}
                    width={12}
                  />
                )}
                {isEditEnabled ? (
                  <Grid item xs={12} sx={{ pl: 1, mb: 2 }}>
                    <FormLabel htmlFor={HTML_IDS.rewardText} sx={{ mt: 2 }}>
                      Reward Text:
                    </FormLabel>
                    <RhfTextArea
                      name="challengeRewardText"
                      control={control}
                      rules={{
                        required: !challenge.parentChallengeId,
                      }}
                      id={HTML_IDS.rewardText}
                      placeholder="Enter reward text"
                    />
                    {errors.challengeRewardText?.type === 'required' && (
                      <FormHelperText error>
                        Challenge reward text required
                      </FormHelperText>
                    )}
                  </Grid>
                ) : (
                  <SidebarItem
                    title="Reward Text"
                    value={challenge.rewardText}
                    width={12}
                  />
                )}
              </Grid>
            </StyledSection>
          </Accordion>
          {challenge?.challengePlans?.length > 0 && (
            <PlanDetails
              control={control}
              challengePlans={challenge.challengePlans}
              isEditingEnabled={isEditEnabled}
              planMix={challenge.planMix}
            />
          )}
          {challenge?.nestedChallenges?.length > 0 && (
            <NestedChallengesDetails
              nestedChallenges={challenge.nestedChallenges}
            />
          )}
          <Divider />
          <Accordion disableGutters defaultExpanded>
            <StyledAccordionSummary
              sx={{ backgroundColor: 'whitesmoke' }}
              expandIcon={<ExpandMoreIcon />}
            >
              <SectionHeading>Activity</SectionHeading>
            </StyledAccordionSummary>
            <StyledSection>
              <Grid container>
                <SidebarItem
                  title="Status"
                  value={challenge.status}
                  width={4}
                />
                {isEditEnabled && challenge?.type === 'standard' ? (
                  <Grid item xs={2} sx={{ pl: 1 }}>
                    <FormLabel htmlFor={HTML_IDS.goal} sx={{ mt: 2 }}>
                      Goal:
                    </FormLabel>
                    <RhfInput
                      name="challengeGoal"
                      type="number"
                      control={control}
                      placeholder="Goal"
                      id={HTML_IDS.goal}
                      rules={{
                        required: true,
                        min: 0,
                        validate: (goal) =>
                          validateGoal(goal, challenge?.progress),
                      }}
                    />
                    {errors?.challengeGoal?.type === 'required' && (
                      <FormHelperText error>
                        Goal value is required
                      </FormHelperText>
                    )}
                    {errors?.challengeGoal?.type === 'min' && (
                      <FormHelperText error>
                        Goal value must be greater than or equal to 0
                      </FormHelperText>
                    )}
                    {errors.challengeGoal?.type === 'validate' && (
                      <FormHelperText error>
                        Goal cannot be less than progress
                      </FormHelperText>
                    )}
                  </Grid>
                ) : (
                  <SidebarItem title="Goal" value={challenge.goal} width={2} />
                )}
                <SidebarItem
                  title="Progress"
                  value={challenge.progress}
                  width={3}
                />
                <Grid item xs={3}>
                  {isApproveButtonShown && (
                    <Button
                      disabled={isSubmitting}
                      onClick={() => onApproveChallenge(challenge)}
                      data-pendo-id="challenge-sidebar-approve-challenge-button"
                    >
                      Approve
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <StyledLabel>Condition Satisfied</StyledLabel>
                    </TableCell>
                    <TableCell>
                      <StyledLabel>Responsible Stellar User</StyledLabel>
                    </TableCell>
                    <TableCell>
                      <StyledLabel>Responsible Provider</StyledLabel>
                    </TableCell>
                    <TableCell>
                      <StyledLabel>Relevant Patient ID</StyledLabel>
                    </TableCell>
                    <TableCell>
                      <StyledLabel>Action History Info</StyledLabel>
                    </TableCell>
                    <TableCell>
                      <StyledLabel>Step History Info</StyledLabel>
                    </TableCell>
                    <TableCell>
                      <StyledLabel>Tracked Challenge Info</StyledLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activityLogs?.map((log) => (
                    <TableRow key={log.id}>
                      <TableCell>{log.condition}</TableCell>
                      <TableCell>{log.responsibleUser}</TableCell>
                      <TableCell>{log.responsibleProvider}</TableCell>
                      <TableCell>{log.relevantPatientId}</TableCell>
                      <TableCell>
                        {log.actionHistoryId && (
                          <span>
                            ID: {log.actionHistoryId} <br />
                            {log.actionHistoryCreationDate}
                          </span>
                        )}
                      </TableCell>
                      <TableCell>
                        {log.stepHistoryId && (
                          <span>
                            ID: {log.stepHistoryId} <br />
                            {log.stepHistoryCreationDate}
                          </span>
                        )}
                      </TableCell>
                      <TableCell>
                        {log.trackedChallengeId && (
                          <span>
                            ID: {log.trackedChallengeId} <br />
                            {log.trackedChallengeApprovalDate}
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledSection>
          </Accordion>
          <Divider />
          <Box sx={{ m: 2 }}>
            {Object.keys(errors).length > 0 && isEditEnabled && (
              <>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  style={{ color: 'red' }}
                >
                  Please correct the following error(s):
                </Typography>
                {errors.challengeName?.type === 'required' && (
                  <FormHelperText error>Challenge name required</FormHelperText>
                )}
                {errors.challengeDescription?.type === 'required' && (
                  <FormHelperText error>
                    Challenge description required
                  </FormHelperText>
                )}
                {errors?.challengeSVUAward?.type === 'required' && (
                  <FormHelperText error>
                    Svu award value is required
                  </FormHelperText>
                )}
                {errors?.challengeSVUAward?.type === 'min' && (
                  <FormHelperText error>
                    Svu award value must be greater than or equal to 0
                  </FormHelperText>
                )}
                {errors?.challengeGoal?.type === 'required' && (
                  <FormHelperText error>Goal value is required</FormHelperText>
                )}
                {errors?.challengeGoal?.type === 'min' && (
                  <FormHelperText error>
                    Goal value must be greater than or equal to 0
                  </FormHelperText>
                )}
                {errors.challengeGoal?.type === 'validate' && (
                  <FormHelperText error>
                    Goal cannot be less than progress
                  </FormHelperText>
                )}
                {errors.challengeEndDate?.type === 'required' && (
                  <FormHelperText error>End date required</FormHelperText>
                )}
                {errors.challengeEndDate?.type === 'validate' && (
                  <FormHelperText error>
                    End Date cannot be before today&#39;s date
                  </FormHelperText>
                )}
                {errors.challengeDescription?.type === 'required' && (
                  <FormHelperText error>
                    Challenge description required
                  </FormHelperText>
                )}
                {errors.challengeRewardText?.type === 'required' && (
                  <FormHelperText error>
                    Challenge reward text required
                  </FormHelperText>
                )}
              </>
            )}
            {updateFailedMessages &&
            Object.keys(updateFailedMessages).length > 0 ? (
              <ErrorMessages failedMessages={updateFailedMessages} />
            ) : null}
          </Box>
          <Box sx={{ m: 2 }}>
            <Button
              disabled={!isDirty || !isEditEnabled}
              data-pendo-id="challenge-sidebar-save-challenge-button"
              type="submit"
            >
              Save Changes
            </Button>
            <Button
              sx={{ ml: 2 }}
              onClick={onSubmitCancel}
              disabled={!isDirty || !isEditEnabled}
              variant="text"
            >
              Cancel
            </Button>
          </Box>
        </BaseForm>
      )}
    </StyledDrawer>
  );
}

DetailsSidebar.propTypes = {
  activityLogs: PropTypes.arrayOf(PropTypes.object),
  challenge: challengeShape, // not required when it's closed
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onApproveChallenge: PropTypes.func.isRequired,
  isChallengeNotEditable: PropTypes.func.isRequired,
  isEditEnabled: PropTypes.bool.isRequired,
  onEnableEdit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.object).isRequired,
  updateFailedMessages: PropTypes.objectOf(PropTypes.string),
  handleSubmit: PropTypes.func.isRequired,
  onSubmitSaveChanges: PropTypes.func.isRequired,
  onSubmitCancel: PropTypes.func.isRequired,
  validateGoal: PropTypes.func.isRequired,
  validateEndDate: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
};

DetailsSidebar.defaultProps = {
  activityLogs: undefined,
  challenge: undefined,
  updateFailedMessages: undefined,
};

// This doesn't currently work, not sure why.
const StyledAccordionSummary = styled(AccordionSummary)`
  .${accordionSummaryClasses.root} {
    background-color: lightgray;
  }
`;

const SectionHeading = styled.h2`
  font-size: 20px;
  margin-bottom: 0;
`;

const StyledSection = styled.div`
  padding: 0 10px 10px;
`;

const StyledDrawer = styled(Drawer)`
  .${drawerClasses.paperAnchorRight} {
    width: 800px;
  }
`;

const StyledLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #73767b;
`;

export default DetailsSidebar;
