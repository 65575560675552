/**
 * Palette Customization
 *
 * @type {import('@mui/material').PaletteOptions}
 * @deprecated These colors are deprecated
 *
 * Newly added fields should be added to the mui.d.ts file.
 *
 * Documentation: https://mui.com/customization/palette/
 *
 * WARNING: this file is deprecated. you should probably be changing
 * frontend/themev2/palette.js
 */

const paletteV1 = {
  background: {
    darker: '#f5f5f5',
    default: '#fafafa',
  },

  cta: {
    background: '#ea6e480d', // 5% opacity
    default: '#fc6237',
    hover: '#d14523',
  },

  dataViz: {
    qualitative6: '#ffa600',
  },

  font: {
    default: '#222529',
    lightest: '#73767b',
  },

  icon: {
    dark: '#72767b',
    default: '#999999',
    primaryActive: '#0c73e9',
    secondaryActive: '#e0efff',
  },

  input: {
    border: '#ced4da',
    disabled: '#e9ecef',
  },

  status: {
    danger: '#dc3545',
    success: '#55d307',
  },

  stellar: {
    primary: '#fd7e14',
    white: '#ffffff',
  },

  stroke: {
    dark: '#999999',
    light: '#e6e6e6',
  },

  /**
   * Palette options from the default palette are below.
   *
   * We want to apply Stellar colors to MUI components, and it's easier to override the default palette
   * than it is to override each component to use the new palette above.
   */
  primary: {
    main: '#fc6237',
    dark: '#d14523',
  },
  secondary: {
    main: '#73767b',
  },
  error: {
    main: '#dc3545',
  },
};

/* This is the current palette for our codebase. It is merged non-destructively
 * with PaletteV1 before export for the purposes of maintaining the code that
 * was already built on the old palette and which has not yet been updated to
 * use the new palette.
 *
 * This palette should be kept strictly in agreement with the colors and
 * aliases in figma, both in terms of aliases and hex values. If you think you
 * need to make a change to this palette it should be explicitly signed off by
 * design. At time of writing, that would be Kevin Lanuk. A new color or alias
 * appearing in Figma is not evidence enough that a new alias or color should
 * be added to the palette. It may be a mistake in Figma.
 *
 * Adding new colors to the v2 palette, please use a nested structure where appropriate. ie
 * token "DataViz/Categorical/Purple", should be accessible with palette.dataViz.categorical.purple
 * not palette.dataViz.categoricalPurple
 */
const paletteV2 = {
  background: {
    base: '#ffffff',
    primary: '#5b57ea',
    primaryDarker: '#484cd4',
    primaryDarkest: '#2839be',
    primaryLighter: '#d4d3ff',
    primaryLightest: '#ebebff',
    secondary: '#f8f8ff',
    tableZebra: '#fbfbfe',
    transparent: '#ffffff00', // 0% opacity
  },

  primary: {
    main: '#5b57ea',
    dark: '#484CD4',
  },

  border: {
    base: '#e3e3ee',
    disabled: '#bfbfca',
    input: '#71717d',
    primaryActive: '#5b57ea',
  },

  brand: {
    logo: '#ff6600',
  },

  dataViz: {
    categorical: {
      neutral300: '#bfbfca',
      neutral500: '#90929d',
      purple: '#484cd4',
    },
  },

  disabled: {
    disabled: '#bfbfca',
  },

  focus: {
    border: '#5b57ea',
    shadow: '#5b57ea7f', // 50% opacity
  },

  icon: {
    label: '#42424d',
    link: '#5b57ea',
    placeholder: '#bfbfca',
    primary: '#2b2b36',
    primaryInverse: '#ffffff',
    secondary: '#585864',
  },

  overlay: {
    overlay: '#2b2b367f', // 50% opacity
  },

  status: {
    errorBackground: '#ffe2e0',
    errorBackgroundHover: '#ffcccc',
    errorForeground: '#c42245',
    infoBackground: '#e0efff',
    infoBackgroundHover: '#a7e8fb',
    infoForeground: '#0a60c2',
    moderateBackground: '#fff686',
    moderateBackgroundHover: '#ffea61',
    moderateForeground: '#705b00',
    noneBackground: '#e3e3ee',
    noneBackgroundHover: '#bfbfca',
    noneForeground: '#585864',
    successBackground: '#d6fff3',
    successBackgroundHover: '#9dfbdf',
    successForeground: '#157F5F',
    warningBackground: '#ffebdb',
    warningBackgroundHover: '#ffca9e',
    warningForeground: '#b8320a',
  },

  svu: {
    dark: '#12708c',
    lighter: '#a7e8fb',
    lightest: '#d6f6ff',
  },

  text: {
    label: '#42424d',
    link: '#5b57ea',
    placeholder: '#bfbfca',
    primary: '#2b2b36',
    primaryInverse: '#ffffff',
    secondary: '#585864',
  },
};

// There should not be any collisions here
const palette = {
  background: {
    ...paletteV1.background,
    ...paletteV2.background,
  },

  border: paletteV2.border,

  brand: paletteV2.brand,

  cta: paletteV1.cta,

  dataViz: {
    ...paletteV1.dataViz,
    ...paletteV2.dataViz,
  },

  disabled: paletteV2.disabled,

  error: {
    main: paletteV2.status.errorForeground,
  },

  focus: paletteV2.focus,

  font: paletteV1.font,

  icon: {
    ...paletteV1.icon,
    ...paletteV2.icon,
  },

  input: paletteV1.input,

  overlay: paletteV2.overlay,

  primary: paletteV2.primary,

  secondary: paletteV1.secondary,

  status: {
    ...paletteV1.status,
    ...paletteV2.status,
  },

  stellar: paletteV1.stellar,

  stroke: paletteV1.stroke,

  svu: paletteV2.svu,

  text: paletteV2.text,
};

export default palette;
