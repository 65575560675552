import { Box } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * BaseForm is a `<form>` element that blocks page navigation when the form is submitted.
 */
function BaseForm({
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledby,
  'aria-describedby': ariaDescribedby,
  children,
  className,
  id,
  name,
  onSubmit,
  sx,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <Box
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      aria-describedby={ariaDescribedby}
      action="post"
      className={className}
      component="form"
      id={id}
      name={name}
      onSubmit={handleSubmit}
      sx={sx}
    >
      {children}
    </Box>
  );
}

BaseForm.propTypes = {
  'aria-describedby': PropTypes.string,
  'aria-label': PropTypes.string,
  'aria-labelledby': PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

BaseForm.defaultProps = {
  'aria-label': undefined,
  'aria-labelledby': undefined,
  'aria-describedby': undefined,
  className: undefined,
  id: undefined,
  name: undefined,
  sx: undefined,
};

export default BaseForm;
