/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  Box,
  Grid,
  InputBase,
  Paper,
  PaperProps,
  Popper,
  Stack,
  SxProps,
  Typography,
  autocompleteClasses,
  inputBaseClasses,
  styled,
} from '@mui/material';
import { MouseEventHandler, SyntheticEvent, useState } from 'react';
import { format, isValid, parseISO } from 'date-fns';

import useDebouncedValue from '@/utils/useDebouncedValue';
import { ReactComponent as MagnifyingGlassIcon } from '@/assets/icons/magnifyingGlass.svg';

import useGetSearchPatients, { Patient } from '../hooks/useGetSearchPatients';

const COLUMN_SPACING = 1;

const StyledPopper = styled(Popper)`
  .${autocompleteClasses.paper} {
    min-width: 300px;
  }
  .${autocompleteClasses.listbox} {
    padding: 0;
    max-height: 60vh;
  }
  .${autocompleteClasses.option}.Mui-focused {
    background-color: ${(p) => p.theme.palette.background.primaryLightest};
  }
`;

const StyledInput = styled(InputBase)`
  border: 1px solid ${(p) => p.theme.palette.border.input};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(0, 0.75)};
  width: 100%;
  .${inputBaseClasses.input} {
    background-color: transparent;
  }
`;

/**
 * Returns a date string in the MM-DD-YYYY format if the input is valid. Otherwise, returns the input.
 * @param input An ISO date string
 */
function safeFormatDate(input: string) {
  const date = parseISO(input);
  return isValid(date) ? format(date, 'MM-dd-yyyy') : input;
}

function getOptionLabel(option: Patient) {
  return `${option.firstName} ${option.lastName}`;
}

function PaperWithHeader({ children, ...otherProps }: PaperProps) {
  return (
    <Paper {...otherProps}>
      <Grid container px={2} pt={1} pb={0.5} columnSpacing={COLUMN_SPACING}>
        <Grid item xs={6}>
          <Typography component="span" variant="bodymediumbold">
            Patient
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography component="span" variant="bodymediumbold">
            PCP
          </Typography>
        </Grid>
      </Grid>
      {children}
    </Paper>
  );
}

type PatientSearchProps = {
  sx: SxProps;
};

export default function PatientSearch({ sx }: PatientSearchProps) {
  const [inputValue, setInputValue] = useState('');

  const debouncedInputValue = useDebouncedValue(inputValue);

  const { isLoading, data: options = [] } =
    useGetSearchPatients(debouncedInputValue);

  const handleInputChange = (_e: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleClickOption: MouseEventHandler = (e) => {
    // Block Autocomplete from updating the text input value.
    e.stopPropagation();
  };

  return (
    <Autocomplete
      clearOnBlur={false}
      onInputChange={handleInputChange}
      options={options}
      getOptionLabel={getOptionLabel}
      // @ts-expect-error - incompatible prop types
      PaperComponent={options.length ? PaperWithHeader : Paper}
      PopperComponent={StyledPopper}
      filterOptions={(x) => x} // Disable Autocomplete's built-in filtering
      renderInput={(params) => (
        <StyledInput
          data-pendo-id="navbar-patient-search"
          fullWidth
          inputProps={params.inputProps}
          placeholder="Search patients"
          ref={params.InputProps.ref}
          startAdornment={
            <MagnifyingGlassIcon
              style={{ width: '16px', height: '16px', marginRight: '6px' }}
            />
          }
        />
      )}
      noOptionsText="No patients found"
      renderOption={(props, option) => {
        return (
          // @ts-expect-error - incompatible prop types
          <Box
            {...props}
            component="li"
            key={option.id}
            onClick={handleClickOption}
          >
            <Grid
              key={option.id}
              container
              component="a"
              columnSpacing={COLUMN_SPACING}
              sx={{
                color: 'text.primary',
                ':hover': { color: 'text.primary' },
              }}
              href={`/patient/${option.id}/summary_v2`}
            >
              <Grid component="span" item xs={6}>
                <Stack component="span">
                  <span>{getOptionLabel(option)}</span>
                  <Typography
                    component="span"
                    variant="bodysmall"
                    lineHeight="1rem"
                  >
                    {safeFormatDate(option.dateOfBirth)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid component="span" item xs={6}>
                {option.provider.name}
              </Grid>
            </Grid>
          </Box>
        );
      }}
      loading={Boolean(inputValue && isLoading)}
      sx={sx}
    />
  );
}
