import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';
import { useQueryParameters } from '@/contexts/QueryParametersContext';

import FollowUpWidget from '../FollowUpWidget';

export default function TaskFlagged({ task }) {
  const {
    parameters: { printPreview = false },
  } = useQueryParameters();

  const handleClick = (e) => {
    // Block the row's click handler from being called.
    e.stopPropagation();
  };

  if (printPreview) {
    return task.isFlagged ? 'Yes' : 'No';
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={handleClick}>
      <FollowUpWidget userInputTask={task} />
    </div>
  );
}

TaskFlagged.propTypes = {
  task: userInputTaskShape.isRequired,
};
