import { Box } from '@mui/material';

export default function Separator() {
  return (
    <Box
      color="text.placeholder"
      display="inline"
      sx={{ mx: 1, mb: -0.5, position: 'relative', bottom: '-2px' }}
    >
      |
    </Box>
  );
}
