import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { arrayOf, bool, func, number, object } from 'prop-types';

import { ReactComponent as CheckTrueIcon } from '@/assets/icons/checkTrue.svg';
import getTotalSvusOnActionLatestProgress from '@/pages/TaskBasedWorkflow/utils/getTotalSvusOnActionLatestProgress';
import CompletedTaskSvuBadge from '@/pages/TaskBasedWorkflow/components/taskTableView/completedTasks/CompletedTaskSvuBadge';

export default function CompletedTasksInterstitialModal({
  completedActions,
  onCancel,
  onContinue,
  open,
  totalSvuOpportunity,
}) {
  const totalActionsCompleted = completedActions.length;
  const totalSvusEarned = getTotalSvusOnActionLatestProgress(completedActions);

  const visits = totalActionsCompleted === 1 ? 'visit' : 'visits';
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" gap={2}>
          <StyledCheckTrueIcon />
          <Typography variant="headermediumbold">
            {totalActionsCompleted} {visits} completed and {totalSvusEarned}{' '}
            SVUs earned
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <Box textAlign="left">
          <Typography variant="bodylargebold" mt={1}>
            Earn additional SVUs
          </Typography>

          <Typography variant="body" mt={1}>
            There likely were other actions addressed during these visits.
          </Typography>
          <Typography variant="body">
            Update these actions and earn additional SVUs.
          </Typography>

          <Typography variant="bodysmall" mt={2}>
            Estimated total SVU opportunity
          </Typography>
          <CompletedTaskSvuBadge>
            {totalSvuOpportunity} SVUs
          </CompletedTaskSvuBadge>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          variant="text"
          data-pendo-id="completed-tasks-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={onContinue}
          data-pendo-id="completed-tasks-modal-continue-button"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
CompletedTasksInterstitialModal.propTypes = {
  completedActions: arrayOf(object.isRequired).isRequired,
  onCancel: func.isRequired,
  onContinue: func.isRequired,
  open: bool,
  totalSvuOpportunity: number.isRequired,
};

CompletedTasksInterstitialModal.defaultProps = {
  open: false,
};

const StyledCheckTrueIcon = styled(CheckTrueIcon)`
  width: 32px;
  height: 32px;
  color: ${(p) => p.theme.palette.status.success};
`;
