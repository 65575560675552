import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';

export default function BaseDialog({ sx, children, open, ...props }) {
  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '90%',
            maxWidth: '1328px',
            height: '70%',
            maxHeight: '800px',
          },
        },
        ...sx,
      }}
      open={open}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {children}
    </Dialog>
  );
}

BaseDialog.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  open: PropTypes.bool,
};
BaseDialog.defaultProps = {
  sx: undefined,
  open: undefined,
};
