import styled from 'styled-components';
import { useState } from 'react';
import { Popover } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import useId from '@mui/utils/useId';

import focusOutline from '@/mixins/focusOutline';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';

function ColumnHelp({ 'aria-label': ariaLabel, helpContent }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverButtonId = useId();
  return (
    <>
      <StyledButton
        type="button"
        aria-describedby={popoverButtonId}
        aria-label={ariaLabel}
        onClick={handlePopoverOpen}
      >
        <StyledInfoIcon />
      </StyledButton>
      <Popover
        id={popoverButtonId}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handlePopoverClose}
        PaperProps={{
          sx: {
            width: '280px',
            p: 1,
            border: '1px solid',
            borderColor: 'border.base',
            borderRadius: 1,
          },
        }}
      >
        <Typography variant="bodysmall">{helpContent}</Typography>
      </Popover>
    </>
  );
}

export default ColumnHelp;

ColumnHelp.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  helpContent: PropTypes.string.isRequired,
};

const StyledButton = styled('button')`
  line-height: 0;
  vertical-align: middle;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin-left: 2px;
  color: ${(p) => p.theme.palette.icon.secondary};
  ${focusOutline}
`;
