import PropTypes from 'prop-types';
import { last } from 'lodash';
import { createContext, useContext } from 'react';

export function getPage() {
  const { pathname } = window.location;

  // Remove any leading or trailing slashes, then take the last part of the path.
  // Example: Given '/tbw/transition-of-care/', returns 'transition-of-care'.
  return last(pathname.replace(/^\/|\/$/g, '').split('/'));
}

const PageContext = createContext(getPage());

export function usePage() {
  return useContext(PageContext);
}

export function PageProvider({ children, page }) {
  return <PageContext.Provider value={page}>{children}</PageContext.Provider>;
}

PageProvider.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
};
