import PropTypes from 'prop-types';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';

import themeV2 from '@/themeV2';
import useGetCurrentUserStats from '@/widgets/SvuTracker/useGetCurrentUserStats';
import AnimatedNumber from '@/widgets/SvuTracker/AnimatedNumber';
import SvuModal from '@/widgets/SvuTracker/SvuModal/SvuModal';

export default function SvuTracker({ sx }) {
  const { data, isSuccess, refetch } = useGetCurrentUserStats();
  const [svuModalOpen, setSvuModalOpen] = useState(false);

  const queryClient = useQueryClient();

  // add a callback on window so other places can refresh the tracker
  useEffect(() => {
    if (isSuccess) {
      window.stellar = window.stellar || {};
      window.stellar.refreshSvuTracker = () => {
        refetch();
        queryClient.invalidateQueries({
          queryKey: ['goals'],
          refetchType: 'all',
        });
      };
    }
    return () => {
      if (window.stellar?.refreshSvuTracker) {
        delete window.stellar.refreshSvuTracker;
      }
    };
  }, [isSuccess, queryClient, refetch]);

  if (!isSuccess) {
    return null;
  }

  const { startDate, totalValue, shouldShowModal } = data;
  const startDateAsDate = parseISO(startDate);

  return (
    <ThemeProvider theme={themeV2}>
      <Box bgcolor="background.base" sx={sx}>
        <Box
          height="56px"
          borderRadius={2}
          border="1px solid"
          borderColor="border.base"
          display="flex"
          width="fit-content"
          ml={1}
          sx={{
            '&:hover': shouldShowModal
              ? {
                  borderColor: 'border.primaryActive',
                  cursor: shouldShowModal ? 'pointer' : undefined,
                }
              : undefined,
          }}
          bgcolor="transparent"
          alignItems="center"
          overflow="hidden"
          data-pendo-id={
            shouldShowModal
              ? 'svu-tracker-modal-open'
              : 'svu-tracker-modal-blocked'
          }
          onClick={
            shouldShowModal ? () => setSvuModalOpen((s) => !s) : undefined
          }
        >
          <Box
            color="text.label"
            px={1}
            bgcolor="background.secondary"
            display="flex"
            alignContent="center"
            flexDirection="column"
            justifyContent="center"
            alignSelf="stretch"
          >
            <Typography variant="bodylargebold" display="block">
              {format(startDateAsDate, 'LLL')}
            </Typography>
            <Typography variant="bodymedium" display="block">
              {format(startDateAsDate, 'yyyy')}
            </Typography>
          </Box>
          <Box
            color="svu.dark"
            px={1}
            bgcolor="background.base"
            borderRadius={2}
            textAlign="left"
          >
            <Typography variant="bodysmall" whiteSpace="nowrap">
              You contributed
            </Typography>
            <Typography fontSize="1.5rem" fontWeight="900" as="span">
              <AnimatedNumber value={totalValue} />
            </Typography>{' '}
            <Typography variant="bodysmallbold" as="span">
              SVU
            </Typography>
          </Box>
        </Box>
      </Box>
      {shouldShowModal && (
        <SvuModal
          open={svuModalOpen}
          onClose={() => {
            setSvuModalOpen(false);
          }}
        />
      )}
    </ThemeProvider>
  );
}
SvuTracker.propTypes = {
  sx: PropTypes.object,
};
SvuTracker.defaultProps = {
  sx: undefined,
};
