import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { Dialog, Grid, Stack } from '@mui/material';
import { isEqual } from 'lodash';

import DialogCloseIcon from '@ot/components/DialogCloseIcon';
import BaseSpinner from '@/components/BaseSpinner';
import BaseDialogTitle from '@ot/components/BaseDialogTitle';
import BaseDialogContent from '@ot/components/BaseDialogContent';
import addressShape from '@/pages/PaymentInstructions/utils/addressShape';
import BaseDialogActions from '@ot/components/BaseDialogActions';
import AddressSelection from '@/pages/PaymentInstructions/components/PaymentMethodStep/AddressSelection';
import Address from '@/pages/PaymentInstructions/components/PaymentMethodStep/Address';
import ErrorMessage from '@/pages/PaymentInstructions/components/ErrorMessage';

export default function CheckPaymentMethodDialog({
  open,
  onClose,
  validatedAddress,
  inputtedAddress,
  isLoading,
  isError,
  onSubmit,
  payableToName,
}) {
  const [selectedAddress, setSelectedAddress] = useState(validatedAddress);

  const handleOnSubmit = () => {
    onSubmit(selectedAddress);
  };

  return (
    <Dialog open={open}>
      <BaseDialogTitle>
        <DialogCloseIcon onClose={onClose} />
        Confirm
      </BaseDialogTitle>
      <BaseDialogContent>
        <Stack spacing={1}>
          <Typography>
            Please double check your payment method information.
          </Typography>
          <Grid container rowSpacing={1}>
            <Grid item xs={3}>
              <Typography variant="bodybold">Payable to</Typography>
            </Grid>
            <Grid item xs={9}>
              {payableToName}
            </Grid>
          </Grid>
          {isEqual(inputtedAddress, validatedAddress) ? (
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="bodybold">Address</Typography>
              </Grid>
              <Grid item xs={9}>
                <Address address={selectedAddress} />
              </Grid>
            </Grid>
          ) : (
            <AddressSelection
              onSelectAddress={setSelectedAddress}
              validatedAddress={validatedAddress}
              inputtedAddress={inputtedAddress}
            />
          )}
          {isError ? <ErrorMessage error="Something went wrong." /> : null}
        </Stack>
      </BaseDialogContent>
      <BaseDialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {isLoading ? (
          <BaseSpinner size={20} />
        ) : (
          <Button
            onClick={handleOnSubmit}
            data-pendo-id="payment-self-service-submit-check-address-button"
          >
            Ok
          </Button>
        )}
      </BaseDialogActions>
    </Dialog>
  );
}

CheckPaymentMethodDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  validatedAddress: addressShape.isRequired,
  inputtedAddress: addressShape.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  payableToName: PropTypes.string.isRequired,
};

CheckPaymentMethodDialog.defaultProps = {
  isLoading: false,
  isError: false,
};
