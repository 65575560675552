import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material';
import styled from 'styled-components';
import { forwardRef } from 'react';

import { ReactComponent as DefaultIcon } from '@/assets/icons/checkboxDefault.svg';
import { ReactComponent as CheckedIcon } from '@/assets/icons/checkboxChecked.svg';
import { ReactComponent as IndeterminateIcon } from '@/assets/icons/checkboxMixed.svg';
import { ReactComponent as DisabledCheckbox } from '@/assets/icons/checkboxDisabled.svg';

/**
 * BaseCheckbox is a complete form control including a label and input.
 *
 * Documentation: https://mui.com/components/checkboxes/
 *
 * Checkbox API: https://mui.com/api/checkbox/
 */
const BaseCheckbox = forwardRef(function BaseCheckbox(
  {
    checked,
    className,
    disabled,
    error,
    highEmphasis,
    labelPlacement,
    id,
    indeterminate,
    label,
    name,
    onChange,
    sx,
    value,
    'data-dd-synthetics-id': dataDdSyntheticsId,
    'data-pendo-id': dataPendoId,
  },
  ref,
) {
  return (
    <FormControlLabel
      $variant="checkbox"
      className={className}
      data-dd-synthetics-id={dataDdSyntheticsId}
      data-pendo-id={dataPendoId}
      $error={error}
      $highEmphasis={highEmphasis}
      labelPlacement={labelPlacement}
      control={
        <StyledCheckbox
          checkedIcon={<CheckedIcon />}
          checked={checked}
          disabled={disabled}
          icon={disabled ? <StyledDisabledCheckbox /> : <StyledDefaultIcon />}
          id={id}
          indeterminate={indeterminate}
          indeterminateIcon={<IndeterminateIcon />}
          name={name}
          onChange={onChange}
          value={value}
          ref={ref}
        />
      }
      label={label}
      sx={sx}
    />
  );
});

BaseCheckbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  highEmphasis: PropTypes.bool,
  labelPlacement: PropTypes.string,
  id: PropTypes.string,
  indeterminate: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  'data-dd-synthetics-id': PropTypes.string,
  'data-pendo-id': PropTypes.string,
};

BaseCheckbox.defaultProps = {
  checked: undefined,
  className: undefined,
  disabled: undefined,
  error: false,
  highEmphasis: false,
  labelPlacement: undefined,
  id: undefined,
  indeterminate: undefined,
  name: undefined,
  onChange: undefined,
  sx: undefined,
  value: undefined,
  'data-dd-synthetics-id': undefined,
  'data-pendo-id': undefined,
};

const StyledCheckbox = styled(Checkbox)`
  margin-right: 8px;
  border-radius: 2px;
  flex: 0 0;
`;

const StyledDisabledCheckbox = styled(DisabledCheckbox)`
  fill: ${(p) => p.theme.palette.background.base};
  stroke: ${(p) => p.theme.palette.disabled.disabled};
`;

const StyledDefaultIcon = styled(DefaultIcon)`
  color: ${(p) => p.theme.palette.border.input};
`;

export default BaseCheckbox;
