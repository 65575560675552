import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import BaseRadioGroup from '@/components/BaseRadioGroup';
import BaseRadioButton from '@/components/BaseRadioButton';

export default function RhfRadioGroup({
  name,
  control,
  defaultValue,
  rules,
  ...RadioGroupProps
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <BaseRadioGroup
          name={name}
          onChange={(event, item) => {
            field.onChange(item);
          }}
          value={field.value || ''}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...RadioGroupProps}
        />
      )}
    />
  );
}

RhfRadioGroup.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  name: PropTypes.string,
  // An array of the props to pass to each BaseRadioButton component
  options: PropTypes.arrayOf(PropTypes.shape(BaseRadioButton.propTypes))
    .isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
RhfRadioGroup.defaultProps = {
  className: undefined,
  defaultValue: '',
  label: undefined,
  name: undefined,
  sx: undefined,
};
