import { useMemo } from 'react';

function getPotentialSvus(action) {
  if (!action) return 0;
  if (action.version) {
    return action.possibleSvusRemainingPostPromo
      ? Number(action.possibleSvusRemainingPostPromo)
      : Number(action.possibleSvusRemainingPrePromo);
  }

  return action.svusAfterPromo
    ? Number(action.svusAfterPromo)
    : Number(action.svusBeforePromo);
}

function getTotalPotentialSvus(actions) {
  return actions.reduce((total, action) => {
    const actionSvus = getPotentialSvus(action);
    return total + actionSvus;
  }, 0);
}

export function getEarnedSvus(action) {
  if (!action) return 0;
  if (action.version) {
    return action.stepHistories.reduce((total, history) => {
      const stepSvus = history.svusPostPromo
        ? Number(history.svusPostPromo)
        : Number(history.svusPrePromo);
      return total + stepSvus;
    }, 0);
  }

  if (action.progress) {
    return action.progress.bullets
      .filter((bullet) => bullet.state === 'complete')
      .reduce((total, bullet) => {
        const bulletSvus = bullet.svuAwardAmountAfterPromo
          ? Number(bullet.svuAwardAmountAfterPromo)
          : Number(bullet.svuAwardAmount);
        return total + bulletSvus;
      }, 0);
  }
  return 0;
}

function getTotalEarnedSvus(actions) {
  return actions.reduce((total, action) => {
    const earnedSvus = getEarnedSvus(action);
    return total + earnedSvus;
  }, 0);
}

export default function useSvuTotals(actions) {
  return useMemo(() => {
    const { recaptureDiagnosis, suggestedDiagnosis, nonDiagnosis } = actions;

    const recaptureIncompleteSvus = getTotalPotentialSvus(
      recaptureDiagnosis.incomplete,
    );
    const suggestedIncompleteSvus = getTotalPotentialSvus(
      suggestedDiagnosis.incomplete,
    );
    const nonDiagnosisIncompleteSvus = getTotalPotentialSvus(
      nonDiagnosis.incomplete,
    );
    const totalIncompleteSvus =
      recaptureIncompleteSvus +
      suggestedIncompleteSvus +
      nonDiagnosisIncompleteSvus;

    const recaptureCompleteSvus = getTotalEarnedSvus(
      recaptureDiagnosis.complete,
    );
    const suggestedCompleteSvus = getTotalEarnedSvus(
      suggestedDiagnosis.complete,
    );
    const nonDiagnosisCompleteSvus = getTotalEarnedSvus(nonDiagnosis.complete);
    const totalCompleteSvus =
      recaptureCompleteSvus + suggestedCompleteSvus + nonDiagnosisCompleteSvus;

    return {
      recapture: recaptureIncompleteSvus,
      suggested: suggestedIncompleteSvus,
      nonDiagnosis: nonDiagnosisIncompleteSvus,
      totalIncomplete: totalIncompleteSvus,
      totalComplete: totalCompleteSvus,
    };
  }, [actions]);
}
