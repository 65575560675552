import { startOfYear } from 'date-fns';
import { Stack, Typography } from '@mui/material';
import { func, instanceOf, object } from 'prop-types';

import BaseDatePicker from '@/components/BaseDatePicker';

export default function DxDateOfServicePicker({
  dateOfService,
  setDateOfService,
  sx,
}) {
  return (
    <Stack sx={{ pb: 2, ...sx }}>
      <Typography variant="label">Date of patient visit</Typography>
      <BaseDatePicker
        onChange={setDateOfService}
        size="large"
        value={dateOfService}
        placeholder="mm-dd-yyyy"
        data-pendo-id="modal-ps-date-of-dx-picker"
        sx={{ width: '130px', mt: 0.5 }}
        maxDate={new Date()}
        minDate={startOfYear(new Date())}
      />
    </Stack>
  );
}
DxDateOfServicePicker.propTypes = {
  dateOfService: instanceOf(Date),
  setDateOfService: func.isRequired,
  sx: object,
};

DxDateOfServicePicker.defaultProps = {
  sx: {},
  dateOfService: undefined,
};
