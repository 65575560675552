import { Box, Typography, styled, useTheme } from '@mui/material';
import { filter, floor, get, inRange, reduce, sum, zipWith } from 'lodash';
import PropTypes from 'prop-types';

import colorPool from '@/themeV2/colorPool';

// Please contact @RayTsai if you're trying to make this component reusable!
// Right now the component is built specifically for the Visits page, and let's be careful when we make it shareable.

const ChartConfigs = {
  small: {
    radius: 48,
    arcWidthSmall: 8,
    arcWidthLarge: 12,
  },
  medium: {
    radius: 62,
    arcWidthSmall: 12,
    arcWidthLarge: 18,
  },
};

const MINIMUM_BUCKET_SIZE = 0.01; // percentage

/**
 * Converts polar coordinates to cartesian.
 * @param {number} centerX
 * @param {number} centerY
 * @param {number} radius
 * @param {number} angleInDegrees
 */
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

/**
 * Builds a string describing an arc to be passed in as the definition of a svg path.
 *
 * The coordinate system is oriented such that 0deg is North and 90deg is East.
 * @param {number} x
 * @param {number} y
 * @param {number} radius
 * @param {number} startAngle
 * @param {number} endAngle
 */
function buildArcDefinition(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, startAngle);
  const end = polarToCartesian(x, y, radius, endAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  const sweepFlag = 1;
  const moveTo = ['M', start.x, start.y];
  const arc = ['A', radius, radius, 0, largeArcFlag, sweepFlag, end.x, end.y];
  const definition = [...moveTo, ...arc].join(' ');
  return definition;
}

/**
 * Returns a copy of the array with a minimum bucket size applied.
 *
 * Buckets smaller than the minimum are increased in size with the difference being taken from the larger buckets.
 *
 * Buckets of size 0 are ignored.
 *
 * @param {number[]} buckets - An array of numbers
 * @param {number} minimumPercentage - The minimum bucket size as a percentage (between 0 and 1)
 * @returns
 */
export function applyMinimumBucketSize(buckets, minimumPercentage) {
  const total = sum(buckets);
  const minimumBucketSize = total * minimumPercentage;
  const amountToTake = reduce(
    buckets,
    (acc, curr) => {
      if (curr > 0 && curr < minimumBucketSize) {
        return acc + minimumBucketSize - curr;
      }
      return acc;
    },
    0,
  );
  const amountToTakeFrom = sum(
    buckets.filter((bucket) => bucket > minimumBucketSize),
  );

  return buckets.map((bucket) => {
    if (bucket === 0) {
      return 0;
    }
    if (inRange(bucket, minimumBucketSize)) {
      return minimumBucketSize;
    }

    return bucket - (amountToTake * bucket) / amountToTakeFrom;
  });
}

/**
 * Returns an array of arc elements.
 */
function renderArcs({
  theme,
  chartConfigs,
  visitsCompletedCount,
  visitsReadyToCompleteCount,
  upcomingAppointmentsCount,
  visitsToBeScheduledCount,
}) {
  const { radius, arcWidthSmall, arcWidthLarge } = chartConfigs;
  const size = 2 * (chartConfigs.radius + chartConfigs.arcWidthLarge);
  const center = size / 2;
  const buckets = applyMinimumBucketSize(
    [
      visitsCompletedCount,
      visitsReadyToCompleteCount,
      upcomingAppointmentsCount,
      visitsToBeScheduledCount,
    ],
    MINIMUM_BUCKET_SIZE,
  );
  const validCounts = filter(
    zipWith(
      [
        { color: 'status.successForeground' },
        {
          color: colorPool.emerald[500],
          large: true,
        },
        { color: colorPool.emerald[300] },
        {
          color: 'status.infoBackgroundHover',
          large: true,
        },
      ],
      buckets,
      (a, b) => ({ color: a.color, large: a.large, count: b }),
    ),
    (x) => x.count > 0,
  );

  // Because there is a 1deg gap between the arcs, the total number of degrees might be <360deg.
  const degrees = 360 - validCounts.length;
  const totalCount = sum(validCounts.map((x) => x.count));
  const arcs = [];
  let startAngle = 0;
  validCounts.forEach(({ count, color, large }, index) => {
    const endAngle = startAngle + degrees * (count / totalCount);
    // The "ready to complete" and "not scheduled" arcs are larger to call out that they're actionable.
    const arcWidth = large ? arcWidthLarge : arcWidthSmall;
    const stroke = get(theme.palette, color, color);
    arcs.push(
      <path
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        d={buildArcDefinition(
          center,
          center,
          radius + arcWidth / 2,
          startAngle,
          endAngle,
        )}
        stroke={stroke}
        fill="transparent"
        strokeLinecap="butt"
        strokeWidth={arcWidth}
      />,
    );
    // Create a 1deg gap between the arcs
    startAngle = endAngle + 1;
  });

  return arcs;
}

/**
 * Takes in a decimal and returns a pretty number to be displayed.
 * @param {number} percentageCompletedOrScheduled - a number between 0 and 1
 */
export function getPercentageToDisplay(percentageCompletedOrScheduled) {
  if (percentageCompletedOrScheduled === 0) {
    return '0%';
  }
  if (inRange(percentageCompletedOrScheduled, 0.01)) {
    return '<1%';
  }
  return `${floor(100 * percentageCompletedOrScheduled)}%`;
}

export default function WidgetVisitsProgress({
  visitsCompletedCount,
  visitsReadyToCompleteCount,
  upcomingAppointmentsCount,
  visitsToBeScheduledCount,
  small,
}) {
  const theme = useTheme();
  const totalVisits =
    visitsCompletedCount +
    visitsReadyToCompleteCount +
    upcomingAppointmentsCount +
    visitsToBeScheduledCount;
  const percentageCompletedOrScheduled =
    totalVisits === 0
      ? 0
      : (visitsCompletedCount +
          visitsReadyToCompleteCount +
          upcomingAppointmentsCount) /
        totalVisits;
  const percentageToDisplay = getPercentageToDisplay(
    percentageCompletedOrScheduled,
  );
  const chartConfigs = small ? ChartConfigs.small : ChartConfigs.medium;
  const size = 2 * (chartConfigs.radius + chartConfigs.arcWidthLarge);
  return (
    <Box sx={{ width: size, height: size, position: 'relative' }}>
      <svg style={{ width: size, height: size }}>
        {renderArcs({
          theme,
          chartConfigs,
          visitsCompletedCount,
          visitsReadyToCompleteCount,
          upcomingAppointmentsCount,
          visitsToBeScheduledCount,
        })}
      </svg>

      <CenteredText
        sx={{
          color: 'status.successForeground',
          left: chartConfigs.arcWidthLarge,
          right: chartConfigs.arcWidthLarge,
        }}
      >
        <Typography
          variant={small ? 'metricsmallbold' : 'metricmediumbold'}
          mt={0.5}
        >
          {percentageToDisplay}
        </Typography>
        <Typography variant="bodysmall" lineHeight={1.5}>
          scheduled or completed
        </Typography>
      </CenteredText>
    </Box>
  );
}
WidgetVisitsProgress.propTypes = {
  small: PropTypes.bool,
  visitsCompletedCount: PropTypes.number.isRequired,
  visitsReadyToCompleteCount: PropTypes.number.isRequired,
  upcomingAppointmentsCount: PropTypes.number.isRequired,
  visitsToBeScheduledCount: PropTypes.number.isRequired,
};
WidgetVisitsProgress.defaultProps = {
  small: false,
};

const CenteredText = styled('div')`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
`;
