/**
 * Transforms a single patient action into something the UI wants to consume.
 *
 * @param {PatientActionsResponse} patientAction
 * @returns {PatientActionsResponseForUi}
 */
import camelCaseify from '@/pages/PatientSummary/utils/camelCaseify';

export default function transformPatientAction(patientAction) {
  return camelCaseify(patientAction, {
    contextual_info: (contextualInfo) =>
      camelCaseify(contextualInfo, {
        latest_external_gap_history: (latestExternalGapHistory) =>
          camelCaseify(latestExternalGapHistory, {
            details: camelCaseify,
          }),
      }),
    step_histories: (stepHistories) => stepHistories.map(camelCaseify),
    preferred_path: (preferredPath) => preferredPath.map(camelCaseify),
    available_next_steps: (nextSteps) => nextSteps.map(camelCaseify),
  });
}
