import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import LinkRenderer from './LinkRenderer';
import ListItemRenderer from './ListItemRenderer';

export default function Markdown({ children, listItemPadding }) {
  return (
    <ReactMarkdown
      components={{
        a: LinkRenderer,
        // pass in padding value this way. Open to better option if available
        // eslint-disable-next-line react/no-unstable-nested-components
        li: ({ children: listItemChildren }) => (
          <ListItemRenderer padding={listItemPadding}>
            {listItemChildren}
          </ListItemRenderer>
        ),
      }}
    >
      {children}
    </ReactMarkdown>
  );
}

Markdown.propTypes = {
  children: PropTypes.node.isRequired,
  listItemPadding: PropTypes.number.isRequired,
};
