import styled from 'styled-components';
import { Accordion, AccordionSummary, TableContainer } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';

import { nestedChallengesShape } from '@ot/pages/Challenges/ChallengesView/hooks/useGetChallenges';

function NestedChallengesDetails({ nestedChallenges }) {
  return (
    <Accordion disableGutters>
      <AccordionSummary
        sx={{ backgroundColor: 'gainsboro' }}
        expandIcon={<ExpandMoreIcon />}
      >
        <SectionHeading>Nested Challenges</SectionHeading>
      </AccordionSummary>
      <StyledSection>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Grid Position</TableCell>
                <TableCell> Challenge Template </TableCell>
                <TableCell> Goal </TableCell>
                <TableCell> Progress </TableCell>
                <TableCell> Svu Award Value </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nestedChallenges.map((challenge) => {
                return (
                  <TableRow key={challenge.id}>
                    <TableCell> {challenge.id} </TableCell>
                    <TableCell> {challenge.name} </TableCell>
                    <TableCell> {challenge.gridPosition} </TableCell>
                    <TableCell> {challenge.templateName} </TableCell>
                    <TableCell> {challenge.goal} </TableCell>
                    <TableCell> {challenge.progress} </TableCell>
                    <TableCell> {challenge.svuAward} </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledSection>
    </Accordion>
  );
}

const SectionHeading = styled.h2`
  font-size: 20px;
  margin-bottom: 0;
`;

const StyledSection = styled.div`
  padding: 0 10px 10px;
`;

export default NestedChallengesDetails;

NestedChallengesDetails.propTypes = {
  nestedChallenges: PropTypes.arrayOf(nestedChallengesShape),
};

NestedChallengesDetails.defaultProps = {
  nestedChallenges: undefined,
};
