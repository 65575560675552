import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import queryKeyFactory from '@/pages/TaskBasedWorkflow/queryKeyFactory';

async function fetchPatientProgramEnrollments({ patientId }) {
  const url = `/api/tbw/patients/${patientId}/patient_program_enrollment_statuses`;
  const response = await axios.get(url);

  return response.data;
}

export default function useGetPatientProgramEnrollments({ patientId }) {
  const params = { patientId };

  return useQuery({
    queryKey: queryKeyFactory.patientProgramEnrollments(params),
    queryFn: () => fetchPatientProgramEnrollments({ patientId }),
  });
}
