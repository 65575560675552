import Button from '@mui/material/Button';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

export default function DialogCloseIcon({ onClose }) {
  return (
    <Button onClick={() => onClose()} variant="text" style={{ float: 'right' }}>
      <StyledCloseIcon />
    </Button>
  );
}
DialogCloseIcon.propTypes = {
  onClose: PropTypes.func.isRequired,
};
const StyledCloseIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  pointer-events: none;
`;
