import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import getSvuMenuNamesAndIdsQueryKeyFactory from '@ot/hooks/queryKeyFactories/getSvuMenuNamesAndIdsQueryKeyFactory';

async function fetchSvuMenuNamesAndIds(query) {
  const params = { query, panel_counts: 1 };
  const response = await axios.get('/ops_tools/api/svu_menu/names_and_ids', {
    params,
  });
  return response.data;
}

export default function useGetSvuMenuNamesAndIds(query) {
  const queryKey = getSvuMenuNamesAndIdsQueryKeyFactory(query);
  const enabled = query?.length > 1;

  return useQuery(queryKey, () => fetchSvuMenuNamesAndIds(query), { enabled });
}
