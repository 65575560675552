import styled from 'styled-components';

import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';
import focusOutline from '@/mixins/focusOutline';

export default function HelpTextInfoIcon() {
  return <StyledInfoIcon />;
}

const StyledInfoIcon = styled(InfoIcon)`
  height: 13px;
  width: 16px;
  color: ${(p) => p.theme.palette.icon.link};
  ${focusOutline}
`;
