import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { usePage } from '../contexts/PageContext';
import queryKeyFactory from '../queryKeyFactory';

function transformFilterTabConfiguration(filterTabConfiguration) {
  return {
    filterTab: filterTabConfiguration.filter_tab,
    label: filterTabConfiguration.label,
  };
}

function transformColumnConfiguration(columnConfiguration) {
  return {
    columnName: columnConfiguration.name,
    sortable: columnConfiguration.sortable,
    sortField: columnConfiguration.sort_field,
    label: columnConfiguration.label,
    help: columnConfiguration.help,
  };
}

function transformResponse(data) {
  return {
    bannerTitle: data.banner_title,
    bannerText: data.banner_text,
    columnConfigurations: data.column_configurations.map(
      transformColumnConfiguration,
    ),
    stepLabelConfiguration: data.step_label_configuration,
    filterTabConfigurations: data.filter_tab_configurations
      ? data.filter_tab_configurations.map(transformFilterTabConfiguration)
      : null,
    defaultFilterTab: data.default_filter_tab ? data.default_filter_tab : null,
  };
}

async function fetchPageMetadata({ queryKey }) {
  const [, page] = queryKey;

  const response = await axios.get(`/api/tbw/${page}/metadata`);
  return response.data;
}

export default function useGetPageMetadata({ enabled = true } = {}) {
  const page = usePage();

  return useQuery(
    {
      queryKey: queryKeyFactory.metadata(page),
      queryFn: fetchPageMetadata,
      select: transformResponse,
    },
    { enabled },
  );
}
