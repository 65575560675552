import {
  Box,
  Link,
  Popper,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';

import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';
import formatNumber from '@/utils/formatNumber';
import useGetGoal from '@/components/GoalTiles/hooks/useGetGoal';
// @ts-expect-error - js module
import useFeatureFlag from '@/utils/useFeatureFlag';
// @ts-expect-error - js module
import { InternalPerformanceMetric } from '@/components/GoalTiles/constants';
// @ts-expect-error - js module
import GoalProgressAndTitle from '@/components/GoalTiles/GoalProgressAndTitle';
// @ts-expect-error - js module
import CountOfTotalCompleted from '@/components/GoalTiles/CountOfTotalCompleted';
// @ts-expect-error - js module
import getGoalCalculations from '@/components/GoalTiles/utils/getGoalCalculations';
// @ts-expect-error - js module
import SubtypePrioritization from '@/components/GoalTiles/SubtypePrioritization';
import concatSx from '@/utils/concatSx';

type Props = {
  sx: SxProps;
};

const StyledInfoIcon = styled(InfoIcon)`
  height: 16px;
  width: 16px;
  display: block;
  color: ${(p) => p.theme.palette.text.secondary};
`;

const StyledPopper = styled(Popper)`
  .${tooltipClasses.tooltip} {
    max-width: 260px;
    background-color: ${(p) => p.theme.palette.background.base};
    color: ${(p) => p.theme.palette.text.primary};
    padding: ${(p) => p.theme.spacing(1)};
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    border: 1px solid ${(p) => p.theme.palette.border.base};
  }
`;

export default function WidgetQualityScreenings({ sx }: Props) {
  const goalBannerQualityScreenings = useFeatureFlag(
    'goal_banner_quality_screenings',
  );
  const { data: goal } = useGetGoal(InternalPerformanceMetric.qualityC, {
    enabled: goalBannerQualityScreenings,
  });

  if (!goal || !goalBannerQualityScreenings) return null;

  const { availableSvu } = goal;
  const { percentCompleted, isComplete } = getGoalCalculations(goal);

  return (
    <Stack
      direction="row"
      sx={concatSx(
        [
          {
            bgcolor: 'background.base',
            minWidth: '448px',
            position: 'relative',
          },
        ],
        sx,
      )}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 3,
          bgcolor: 'background.primaryMidnight',
          color: 'text.primaryInverse',
          borderRadius: 2,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Typography component="span" variant="bodymedium" whiteSpace="nowrap">
          {!isComplete && (
            <>
              Estimated
              <br />
              <Typography component="span" variant="bodylargebold">
                {' '}
                {formatNumber(availableSvu)} SVU
              </Typography>{' '}
              <br />
              potential
            </>
          )}
        </Typography>
      </Box>

      <Stack
        py={1.5}
        direction="row"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
        textAlign="center"
        sx={(theme) => ({
          ml: 2,
          [theme.breakpoints.down('lg')]: { mx: 0 },
        })}
      >
        <Box
          sx={(theme) => ({
            mx: 4,
            [theme.breakpoints.down('lg')]: { mx: 2 },
          })}
        >
          <GoalProgressAndTitle
            percentCompleted={percentCompleted}
            zIndex={isComplete ? 1 : 0}
            progressSizePx={104}
          />

          <CountOfTotalCompleted
            total={goal.total}
            completed={goal.completed}
            percentCompleted={percentCompleted}
            sx={{ mt: 1 }}
          />
        </Box>

        <Stack maxWidth="384px" spacing={1}>
          <Typography variant="bodymediumbold">Monthly Goal</Typography>

          {goal.subtypeFocus ? <SubtypePrioritization goal={goal} /> : null}

          <Stack direction="row" alignItems="center" justifyContent="center">
            <Link
              href="/goals_dashboard"
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  color: 'background.primaryDarker',
                },
              }}
            >
              <Typography variant="bodysmall" component="span">
                View all gaps that count towards this goal
              </Typography>
            </Link>

            <Tooltip
              arrow={false}
              PopperComponent={StyledPopper}
              placement="bottom-end"
              title={
                <Typography variant="bodysmall">
                  This page only includes Quality gaps that require patient
                  outreach (scheduling or referrals). Your{' '}
                  <Link href="/goals_dashboard" sx={{ color: 'text.primary' }}>
                    Practice Management Dashboard
                  </Link>{' '}
                  will have all quality gaps that count toward your monthly
                  goal, including those that are typically addressed during a
                  primary care visit.
                </Typography>
              }
            >
              <Box ml={0.5} component="button" aria-label="Monthly goal info">
                <StyledInfoIcon />
              </Box>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
