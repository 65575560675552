import PropTypes, { arrayOf } from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import useId from '@mui/utils/useId';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import { programEnrollmentShape } from '@/utils/shapes';
import { formatISODateIgnoreTimeZone } from '@/utils/formatDate';

export default function PatientProgramEnrollmentsModal({
  className,
  onClose,
  open,
  sx,
  title,
  patientName,
  programEnrollments,
}) {
  const titleId = useId();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className, elevation: 2, sx }}
    >
      <Box display="flex" alignItems="start">
        <DialogTitle
          id={titleId}
          variant="h2"
          sx={{ flex: '1 0 auto', color: 'text.primary', pb: '0' }}
        >
          {title}
        </DialogTitle>

        <StyledButton aria-label="Close modal" onClick={onClose}>
          <CloseIcon />
        </StyledButton>
      </Box>

      <DialogContent sx={{ pt: 0 }}>
        <Typography
          data-dd-action-name="Patient Name"
          variant="h4"
          sx={{ mt: '8px', color: 'text.secondary' }}
        >
          {patientName}
        </Typography>
        <List sx={{ mt: '1.5rem' }}>
          {programEnrollments.map(({ program, enrolledAt }) => {
            return (
              <ListItem key={program.id} sx={{ px: 0 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  gap={1}
                  flexGrow={1}
                  flexShrink={1}
                >
                  <Typography variant="h4">{program.name}</Typography>
                  <Typography variant="bodylarge">
                    Enrolled {formatISODateIgnoreTimeZone(enrolledAt)}
                  </Typography>
                </Stack>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}

PatientProgramEnrollmentsModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  patientName: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  programEnrollments: arrayOf(programEnrollmentShape).isRequired,
};

PatientProgramEnrollmentsModal.defaultProps = {
  className: undefined,
  sx: undefined,
};

const StyledButton = styled.button`
  flex: 0 0 auto;
  margin-right: ${(p) => p.theme.spacing(3)};
  margin-top: ${(p) => p.theme.spacing(3)};

  color: ${(p) => p.theme.palette.icon.primary};

  :hover {
    color: ${(p) => p.theme.palette.icon.link};
  }
`;
