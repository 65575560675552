import { useController } from 'react-hook-form';
import { ThemeProvider, styled } from '@mui/material';

import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';
import BaseRadioGroup from '@/components/BaseRadioGroup';
import themeV2 from '@/themeV2';

export default function DynamicFormRadioGroup({ field }) {
  const { label, name, required, choices } = field;
  const controller = useController({
    name,
    // You can override this defaultValue by passing `defaultValues` to DynamicForm.
    defaultValue: '',
    rules: {
      required: required === true ? 'Select a value' : required,
    },
  });

  const error = Boolean(controller.fieldState.error);
  return (
    <ThemeProvider theme={themeV2}>
      <StyledRadioGroup
        helperText={controller.fieldState.error?.message}
        label={label}
        onBlur={controller.field.onBlur}
        onChange={controller.field.onChange}
        name={controller.field.name}
        value={controller.field.value}
        error={error}
        options={choices.map((choice) => ({
          value: choice[0],
          label: choice[1],
        }))}
      />
    </ThemeProvider>
  );
}

DynamicFormRadioGroup.propTypes = {
  field: executionRequirementShape.isRequired,
};

const StyledRadioGroup = styled(BaseRadioGroup)`
  display: flex;
  &:not(:first-child) {
    margin-top: ${(p) => p.theme.spacing(2)};
  }
`;
