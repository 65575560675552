/**
 * Global state!
 *
 * This stores a mapping from a configuration function to the dependencies it
 * has declared up-front. This is looked up by the `getDependencies` function.
 *
 * A WeakMap is used here so that if, for any reason, a function falls out of
 * scope everywhere else, it may be garbage collected properly and not retained
 * indefinitely.
 *
 * @type {WeakMap<function, string[]>}
 */
const dependencyMap = new WeakMap();

/**
 * Registers the named function with the dependency map. Expects dependencies
 * to be declared as an array of strings, which are expected to be the names of
 * other fields in the DynamicForm where this function resides.
 *
 * @param dependencies {string[]}
 * @param fn {function}
 * @returns {function}
 */
export default function register(dependencies, fn) {
  if (typeof fn !== 'function') {
    throw new TypeError(`Expected fn to be a function, found ${typeof fn}`);
  }
  if (dependencyMap.has(fn)) {
    throw new RangeError(`Cannot register the same function twice`);
  }
  if (!Array.isArray(dependencies)) {
    throw new TypeError(
      `Expected dependencies to be an array, found ${typeof dependencies}`,
    );
  }
  if (dependencies.length === 0) {
    throw new RangeError(
      `Expected dependencies to be declared, found empty array`,
    );
  }
  if (!dependencies.every((v) => typeof v === 'string')) {
    const ts = dependencies.map((v) => typeof v);
    throw new TypeError(
      `Expected dependencies to be an array of strings, found ${ts}`,
    );
  }
  dependencyMap.set(fn, dependencies);
  return fn;
}

/**
 * Given a function, get the dependencies it has registered previously, if any.
 *
 * @param fn
 * @returns {string[] | undefined}
 */
export function getDependencies(fn) {
  return dependencyMap.get(fn);
}

// A cheap memoization function: given a point-free function ensure that it
// is evaluated only once for each argument value. Value is compared by
// reference identity, so caveat emptor!
function memo(fn, cache = new WeakMap()) {
  function memoized(arg) {
    if (!cache.has(arg)) {
      cache.set(arg, fn(arg));
    }
    return cache.get(arg);
  }
  memoized.displayName = fn.displayName;
  return memoized;
}

export const supportedProperties = [
  'visible',
  'defaultValue',
  'minDate',
  'maxDate',
  'required',
  'disabled',
  'label',
  'providers', // used for type=referral to prefill referrals on some actions
  'actionSubtype', // used for type=referral to filter referrals on some actions
  'options', // allow dynamically-defined lists of choices
  'choices',
];

/**
 * Given a DynamicForms field, return a list of _all_ dependencies this field
 * has on other named form fields in the DynamicForm.
 *
 * @param field
 * @returns {string[]}
 */
export const getFieldDependencyNames = memo(
  function getFieldDependencyNames(field) {
    const allDeps = new Set();

    supportedProperties.forEach((property) => {
      const fieldProperty = field[property];
      if (typeof fieldProperty === 'function') {
        const deps = getDependencies(fieldProperty);
        if (!deps) return;
        deps.forEach((dep) => allDeps.add(dep));
      }
    });

    return Array.from(allDeps);
  },
);

/**
 * Given a DynamicForms field list (i.e. a form), return a list of all
 * dependencies for that field list.
 *
 * @param field {Field[]}
 * @returns {string[]}
 */
export const getFormDependencyNames = memo(
  function getFormDependencyNames(fields) {
    const allDeps = new Set();

    fields.forEach((field) => {
      getFieldDependencyNames(field).forEach((dep) => allDeps.add(dep));
    });

    return Array.from(allDeps);
  },
);
