import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { useState } from 'react';

import { ReactComponent as FilterIcon } from '@/assets/icons/filter.svg';
import useFilterCount from '@/components/FiltersSidebar/hooks/useFilterCount';
import PulsatingButton from '@/components/FiltersSidebar/PulsatingButton';

function BaseFilterButton({
  className,
  onClick,
  sx,
  variant: variantProp,
  size,
  pulseEnabled,
}) {
  const filterCount = useFilterCount();
  const [shouldPulse] = useState(filterCount > 0 && pulseEnabled);

  // PulsatingButton adds a pulse effect on page load if the filter is on
  const ButtonComponent = shouldPulse ? PulsatingButton : Button;

  const text = filterCount > 0 ? `Filter - ${filterCount} selected` : 'Filter';
  const variant = filterCount > 0 ? 'primary' : 'secondary';

  return (
    <ButtonComponent
      color="primary"
      className={className}
      variant={variantProp || variant}
      onClick={onClick}
      size={size}
      sx={sx}
    >
      <StyledFilterIcon width="16" height="16" />
      <span>{text}</span>
    </ButtonComponent>
  );
}

BaseFilterButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  variant: PropTypes.string,
  pulseEnabled: PropTypes.bool,
};

BaseFilterButton.defaultProps = {
  className: undefined,
  size: 'large',
  sx: undefined,
  variant: undefined,
  pulseEnabled: true,
};

const StyledFilterIcon = styled(FilterIcon)`
  margin-right: 6px;
`;

export default BaseFilterButton;
