import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useThrottle } from 'react-use';
import { arrayOf, bool, func, number, object } from 'prop-types';

import BaseAutocomplete from '@/components/BaseAutocomplete';
import { patientShape } from '@/utils/shapes';
import useAxios from '@/hooks/useAxios';

function useDiagnosisIcdSearch({ term, hcc, patientId, actionId }) {
  const axios = useAxios();
  const cacheKey = ['ehr-summary', patientId, 'search', term, hcc];
  return useQuery(
    cacheKey,
    async () => {
      const response = await axios.get(`/patient/${patientId}/search`, {
        params: { term, hcc, action_id: actionId },
      });
      return response.data;
    },
    {
      enabled: term.length >= 3,
    },
  );
}

export default function DiagnosisIcdSearch({
  additionalResults,
  hcc,
  onSelectIcd,
  patient,
  selectedIcd,
  disabled,
  actionId,
}) {
  const [term, setTerm] = useState('');
  const { data: searchResults = [] } = useDiagnosisIcdSearch({
    term: useThrottle(term),
    patientId: patient.id,
    hcc,
    actionId,
  });

  const areEqual = (a, b) => a.value === b.value;

  const options = useMemo(() => {
    if (selectedIcd) {
      const filteredAddlResults = additionalResults.filter(
        (ar) => !areEqual(ar, selectedIcd),
      );
      const filteredSearchResults = searchResults.filter(
        (sr) => !areEqual(sr, selectedIcd),
      );
      return [selectedIcd, ...filteredAddlResults, ...filteredSearchResults];
    }
    return [...additionalResults, ...searchResults];
  }, [additionalResults, selectedIcd, searchResults]);

  const optionLabel = (option) => {
    if (typeof option === 'object') {
      return [option.value, option.label]
        .filter((v) => typeof v === 'string')
        .join(': ');
    }
    return option;
  };

  return (
    <BaseAutocomplete
      aria-label="Diagnosis code search"
      autoHighlight
      fullWidth
      getOptionLabel={optionLabel}
      isOptionEqualToValue={areEqual}
      onChange={(e, value) => {
        onSelectIcd(value);
      }}
      onInputChange={(e, value) => setTerm(value)}
      options={options}
      placeholder="Enter a diagnosis code"
      value={selectedIcd}
      disabled={disabled}
    />
  );
}
DiagnosisIcdSearch.propTypes = {
  additionalResults: arrayOf(object.isRequired),
  hcc: number.isRequired,
  onSelectIcd: func.isRequired,
  patient: patientShape.isRequired,
  selectedIcd: object,
  disabled: bool,
  actionId: number.isRequired,
};
DiagnosisIcdSearch.defaultProps = {
  additionalResults: [],
  selectedIcd: null,
  disabled: false,
};
