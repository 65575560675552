import { Stack, Typography } from '@mui/material';
import { node } from 'prop-types';
import useId from '@mui/utils/useId';

export default function DiagnosisFormSection({ header, children }) {
  const sectionId = useId();

  return (
    <Stack aria-labelledby={sectionId} component="section">
      <Typography id={sectionId} variant="bodysmallbold" component="h3">
        {header}
      </Typography>
      {children}
    </Stack>
  );
}
DiagnosisFormSection.propTypes = {
  header: node.isRequired,
  children: node.isRequired,
};
