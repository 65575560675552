import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';

export default function ListItemRenderer({ children, padding }) {
  return (
    <ListItem sx={{ display: 'list-item', padding: { padding } }}>
      {children}
    </ListItem>
  );
}

ListItemRenderer.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.number,
};

ListItemRenderer.defaultProps = {
  padding: 0,
};
