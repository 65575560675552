import { Typography, styled } from '@mui/material';

const DynamicFormNote = styled(Typography)`
  color: ${(p) => p.theme.palette.status.infoForeground};
  font-style: italic;
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;

export default DynamicFormNote;
