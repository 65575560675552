import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

async function fetchFundingPlans() {
  const response = await axios.get(
    '/ops_tools/api/challenges/get_funding_plans',
  );
  return response.data;
}

export default function useGetFundingPlans() {
  const queryKey = ['get_funding_plans'];

  return useQuery(queryKey, () => fetchFundingPlans());
}
