import PropTypes from 'prop-types';
import { useState } from 'react';
import { useThrottle } from 'react-use';
import styled from 'styled-components';
import { chipClasses } from '@mui/material/Chip';

import BaseAutocomplete from '@/components/BaseAutocomplete';
import useGetSvuMenuNamesAndIds from '@ot/hooks/useGetSvuMenuNamesAndIds';

function SvuMenuAutocomplete({ SvuMenus, onChange, id, disabled }) {
  const [searchQuery, setSearchQuery] = useState('');
  const throttledQuery = useThrottle(searchQuery);

  const { data: SvuMenuOptions, isFetched } =
    useGetSvuMenuNamesAndIds(throttledQuery);

  const handleInputChangeSvuMenu = async (e) => {
    if (e) {
      setSearchQuery(e.target.value);
    }
  };

  const handleBlur = () => {
    setSearchQuery('');
  };

  const handleChangeSvuMenu = (e, newSvuMenus) => {
    setSearchQuery('');
    onChange(e, newSvuMenus);
  };

  return (
    <div>
      <StyledBaseAutocomplete
        autoHighlight
        disableListWrap
        disabled={disabled}
        fullWidth
        id={id}
        onChange={handleChangeSvuMenu}
        onBlur={handleBlur}
        onInputChange={handleInputChangeSvuMenu}
        options={isFetched ? SvuMenuOptions : []}
        getOptionLabel={(option) => `${option.name} - ID: [${option.id}]`}
        placeholder="Type SVU menu name or ID to search"
        value={SvuMenus}
        open={isFetched}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </div>
  );
}
SvuMenuAutocomplete.propTypes = {
  SvuMenus: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

SvuMenuAutocomplete.defaultProps = {
  SvuMenus: undefined,
  id: undefined,
  disabled: false,
};

export default SvuMenuAutocomplete;

const StyledBaseAutocomplete = styled(BaseAutocomplete)`
  .${chipClasses.root} {
    height: initial;
  }
  .${chipClasses.label} {
    white-space: initial;
  }
`;
