import { Box, Link, Stack, SxProps, Typography, styled } from '@mui/material';

import formatNumber from '@/utils/formatNumber';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import concatSx from '@/utils/concatSx';

// @ts-expect-error - js module
import GoalProgressAndTitle from './GoalProgressAndTitle';
// @ts-expect-error - js module
import CountOfTotalCompleted from './CountOfTotalCompleted';
// @ts-expect-error - js module
import getGoalCalculations from './utils/getGoalCalculations';
// @ts-expect-error - js module
import SubtypePrioritization from './SubtypePrioritization';
import useGetGoal from './hooks/useGetGoal';
import { InternalPerformanceMetric } from './types';

const StyledButton = styled('button')`
  position: absolute;
  top: 12px;
  right: 12px;

  color: ${(p) => p.theme.palette.icon.primary};

  :hover {
    color: ${(p) => p.theme.palette.icon.link};
  }
`;

type Props = {
  sx?: SxProps;
  onClose?: () => void;
  small?: boolean;
  noBorder?: boolean;
  internalPerformanceMetric: InternalPerformanceMetric;
  title: string;
};

export default function GoalBanner({
  internalPerformanceMetric,
  title,
  small = false,
  noBorder = false,
  onClose = undefined,
  sx = undefined,
}: Props) {
  const { data: goal } = useGetGoal(internalPerformanceMetric);

  if (!goal) return null;

  const { availableSvu } = goal;
  const { percentCompleted, isComplete } = getGoalCalculations(goal);

  return (
    <Stack
      direction="row"
      sx={concatSx(
        {
          bgcolor: 'background.base',
          position: 'relative',
        },
        sx,
      )}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: small ? 2 : 4,
          bgcolor: 'background.primaryMidnight',
          color: 'text.primaryInverse',
          borderRadius: 2,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Typography component="span" variant="bodymedium" whiteSpace="nowrap">
          {!isComplete && (
            <>
              Estimated
              <br />
              <Typography component="span" variant="bodyextralargebold">
                {' '}
                {formatNumber(availableSvu)} SVU
              </Typography>{' '}
              <br />
              potential
            </>
          )}
        </Typography>
      </Box>

      <Stack
        py={small ? undefined : 2}
        direction="row"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
        sx={
          noBorder
            ? {}
            : {
                borderLeft: 'none',
                border: '1px solid',
                borderColor: 'border.base',
                borderRadius: 2,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }
        }
      >
        <GoalProgressAndTitle
          percentCompleted={percentCompleted}
          zIndex={isComplete ? 1 : 0}
          progressSizePx={104}
          sx={{ mx: 4 }}
        />

        <div>
          <Typography variant="bodymediumbold">{title}</Typography>

          <CountOfTotalCompleted
            total={goal.total}
            completed={goal.completed}
            percentCompleted={percentCompleted}
          />

          {goal.subtypeFocus ? <SubtypePrioritization goal={goal} /> : null}

          <Box mt={1}>
            <Link href="/goals_dashboard">
              <Typography variant="bodysmall" component="span">
                More details
              </Typography>
            </Link>
          </Box>
        </div>
      </Stack>

      {onClose && (
        <StyledButton aria-label="Close modal" onClick={onClose}>
          <CloseIcon />
        </StyledButton>
      )}
    </Stack>
  );
}
