import { object } from 'prop-types';

import BaseFormWidget from '@/components/BaseFormWidget';
import { ReactComponent as FlagIcon } from '@/assets/icons/flagWithCircle.svg';

export default function BaseFollowUpWidget({ theme, ...props }) {
  return (
    <BaseFormWidget
      IconComponent={FlagIcon}
      iconAriaLabel="Flag Icon"
      iconPrimaryActiveColor={theme.palette.input.primaryActive}
      iconSecondaryActiveColor={theme.palette.input.secondaryActive}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

BaseFollowUpWidget.propTypes = {
  theme: object.isRequired,
};
