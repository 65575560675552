import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';

export default function BaseDialogContent({ sx, children, ...props }) {
  return (
    <DialogContent
      sx={{
        display: 'flex',
        flexFlow: 'column',
        height: '80%',
        ...sx,
      }}
      dividers
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {children}
    </DialogContent>
  );
}

BaseDialogContent.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
BaseDialogContent.defaultProps = {
  sx: undefined,
};
