import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useError } from 'react-use';

import useAxios from '@/hooks/useAxios';
import useCsrfToken from '@/hooks/useCsrfToken';

import { objectToFormData, stateForSubmission } from './stateForSubmission';

const URL_DATE_FORMAT = 'MM/dd/yyyy';

function ehrUrlFormatter({ patientId, dateOfService }) {
  return `/patient/${patientId}/ehr/actions/update/dos/${format(
    dateOfService,
    URL_DATE_FORMAT,
  )}`;
}

function mutator({
  diagnosisAction,
  axios,
  csrfToken,
  dateOfService,
  formState,
  patientId,
  selectedProviderId,
  state,
  urlFormatter = ehrUrlFormatter,
}) {
  const postData = objectToFormData(
    stateForSubmission({
      diagnosisAction,
      dateOfService,
      formState,
      selectedProviderId,
      state,
    }),
  );

  const url = urlFormatter({
    patientId,
    dateOfService,
  });

  const params = {};
  if (state.notAssessed) {
    params.not_assessed_dx_action_ids = [diagnosisAction.id];
  }
  return axios.post(url, postData, {
    params,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-CSRFToken': csrfToken,
    },
  });
}

/**
 * @typedef DiagnosisActionMutatorHook
 */

/**
 * @returns {DiagnosisActionMutatorHook}
 */
export default function useDiagnosisActionMutator({
  dateOfService,
  formState,
  patientId,
  onSaveDiagnosisActionWriteToEhr,
  canWriteToEhr,
  onEhrUpdateSuccess,
  isDemo,
  urlFormatter = ehrUrlFormatter,
}) {
  const axios = useAxios();
  const csrfToken = useCsrfToken();
  const queryClient = useQueryClient();
  const dispatchError = useError();

  return useMutation(
    ({ diagnosisAction, selectedProviderId, state }) => {
      return mutator({
        diagnosisAction,
        axios,
        csrfToken,
        dateOfService,
        formState,
        patientId,
        selectedProviderId,
        state,
        urlFormatter,
      });
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(['ehr-summary']);
      },
      onError: (error) => {
        dispatchError(error);
      },
      onSuccess: (data) => {
        window.stellar?.refreshSvuTracker?.();
        if (canWriteToEhr) {
          if (isDemo) {
            onEhrUpdateSuccess('ICD10 code written to Assessment.');
          } else {
            const updatedActionIds = data?.data?.updated_action_ids;
            // only one action can be updated by the API in the EHR view
            if (updatedActionIds?.length === 1) {
              onSaveDiagnosisActionWriteToEhr({
                actionId: updatedActionIds[0],
                patientId,
              });
            }
          }
        }
      },
    },
  );
}
