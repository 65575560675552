import Papa from 'papaparse';

export const HEADERS = {
  ops_request: 'ops_request',
  target_id: 'target_id',
  set_expiration_date: 'set_expiration_date',
};

export function transformParseCsvOutput(csvRows, filename) {
  return csvRows.map((rowData, index) => ({
    ops_request: rowData[HEADERS.ops_request],
    target_id: rowData[HEADERS.target_id],
    set_expiration_date: rowData[HEADERS.set_expiration_date],
    source_csv_file_name: filename,
    row_number: index + 1,
  }));
}

export default function parseCsv(file) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      complete(results) {
        if (results.errors.length) {
          reject(results.errors);
        } else {
          resolve({
            isSuccessful: true,
            data: transformParseCsvOutput(results.data, file.name),
          });
        }
      },
      error(err) {
        reject(err);
      },
    });
  });
}
