import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import queryKeyFactory from '@/pages/TaskBasedWorkflow/queryKeyFactory';

async function bulkExecuteStepsExternal({ actionIds }) {
  return axios.post('/api/tbw/steps/bulk-execute-external', {
    action_ids: actionIds,
  });
}

export default function useBulkExecuteStepsExternal({
  onSuccess = undefined,
  onError = undefined,
} = {}) {
  const queryClient = useQueryClient();
  return useMutation(bulkExecuteStepsExternal, {
    onSuccess,
    onError,
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKeyFactory.tasksSearch());
    },
  });
}
