import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import BaseSelect from '@/components/BaseSelect';

export default function RhfSelect({
  control,
  name,
  defaultValue,
  rules,
  ...SelectProps
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <StyledSelect
          value={field.value || ''}
          onChange={field.onChange}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...SelectProps}
        />
      )}
    />
  );
}

RhfSelect.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  labelId: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  disabled: PropTypes.bool,
  includeNoneOption: PropTypes.bool,
  multiple: PropTypes.bool,
};

RhfSelect.defaultProps = {
  defaultValue: undefined,
  options: [],
  labelId: undefined,
  sx: {
    width: 300,
  },
  disabled: false,
  includeNoneOption: false,
  multiple: false,
};

const StyledSelect = styled(BaseSelect)`
  padding: 0;
  background-color: ${(p) => p.theme.palette.background.base};
`;
