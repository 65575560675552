import { useState } from 'react';
import { Container, styled } from '@mui/material';
import {
  arrayOf,
  bool,
  exact,
  func,
  node,
  number,
  object,
  shape,
  string,
} from 'prop-types';

import { reactQueryStatusValues } from '@/utils/shapes';
import { patientShape } from '@/utils/transformFrontendPatient';
import { actionShape } from '@/utils/transformFrontendAction';
import EhrNotification from '@/pages/PatientSummaryEhr/components/EhrNotification';

import useSvuTotals from '../hooks/useSvuTotals';

import CompactPatientDetails from './CompactPatientDetails';
import NonDiagnosisSection from './NonDiagnosisSection';
import RecaptureDiagnosisSection from './RecaptureDiagnosisSection';
import SuggestedDiagnosisSection from './SuggestedDiagnosisSection';

/**
 * @typedef Actions
 * @property {Action[]} incomplete
 * @property {Action[]} complete
 */
/**
 * @typedef ActionMap
 * @property {Actions} recaptureDiagnosis
 * @property {Actions} suggestedDiagnosis
 * @property {Actions} nonDiagnosis
 */

/**
 *
 * @param {ActionMap} actions
 * @param {number?} defaultProviderChoiceId
 * @param formState
 * @param onSaveDiagnosisAction
 * @param onSaveNonDiagnosisAction
 * @param patient
 * @param saveStatus
 * @param showEhrRibbon
 * @param user
 * @param ehrUpdateMessage
 * @returns {JSX.Element}
 */
export default function CompactPatientSummary({
  actions,
  defaultProviderChoiceId,
  formState,
  onSaveDiagnosisAction,
  onSaveNonDiagnosisAction,
  patient,
  saveStatus,
  showEhrRibbon,
  user,
  ehrUpdateMessage,
  dxDateOfServicePicker,
  isDxSubmitDisabled,
}) {
  const [selectedSection, setSelectedSection] = useState('');

  const {
    recapture: recaptureSvus,
    suggested: suggestedSvus,
    nonDiagnosis: nonDiagnosisSvus,
    totalIncomplete: totalIncompleteSvus,
    totalComplete: totalCompleteSvus,
  } = useSvuTotals(actions);

  return (
    <>
      {ehrUpdateMessage && <EhrNotification message={ehrUpdateMessage} />}
      <CompactPatientDetails
        completeSvus={totalCompleteSvus}
        incompleteSvus={totalIncompleteSvus}
        showEhrRibbon={showEhrRibbon}
        patient={patient}
        user={user}
      />
      <PaddedContainer sx={{ bgcolor: 'background.secondary' }}>
        <RecaptureDiagnosisSection
          actions={actions.recaptureDiagnosis}
          defaultProviderChoiceId={defaultProviderChoiceId}
          onSaveDiagnosisAction={onSaveDiagnosisAction}
          onSetSelected={() => setSelectedSection('recapture')}
          patient={patient}
          saveStatus={saveStatus.diagnosis}
          selected={selectedSection === 'recapture'}
          svus={recaptureSvus}
          aboveBodySlot={dxDateOfServicePicker}
          isSubmitDisabled={isDxSubmitDisabled}
        />
        <SuggestedDiagnosisSection
          actions={actions.suggestedDiagnosis}
          defaultProviderChoiceId={defaultProviderChoiceId}
          onSaveDiagnosisAction={onSaveDiagnosisAction}
          onSetSelected={() => setSelectedSection('suggested')}
          saveStatus={saveStatus.diagnosis}
          selected={selectedSection === 'suggested'}
          svus={suggestedSvus}
          aboveBodySlot={dxDateOfServicePicker}
          isSubmitDisabled={isDxSubmitDisabled}
        />
        <NonDiagnosisSection
          actions={actions.nonDiagnosis}
          defaultProviderChoiceId={defaultProviderChoiceId}
          formState={formState}
          onSaveNonDiagnosisAction={onSaveNonDiagnosisAction}
          onSetSelected={() => setSelectedSection('non-diagnosis')}
          patient={patient}
          saveStatus={saveStatus.nonDiagnosis}
          selected={selectedSection === 'non-diagnosis'}
          svus={nonDiagnosisSvus}
        />
      </PaddedContainer>
    </>
  );
}

CompactPatientSummary.propTypes = {
  actions: exact({
    recaptureDiagnosis: exact({
      incomplete: arrayOf(actionShape).isRequired,
      complete: arrayOf(actionShape).isRequired,
    }).isRequired,
    suggestedDiagnosis: exact({
      incomplete: arrayOf(actionShape).isRequired,
      complete: arrayOf(actionShape).isRequired,
    }),
    nonDiagnosis: exact({
      incomplete: arrayOf(actionShape).isRequired,
      complete: arrayOf(actionShape).isRequired,
    }).isRequired,
  }).isRequired,
  defaultProviderChoiceId: number,
  formState: object,
  onSaveDiagnosisAction: func,
  onSaveNonDiagnosisAction: func,
  patient: patientShape.isRequired,
  saveStatus: exact({
    diagnosis: reactQueryStatusValues.isRequired,
    nonDiagnosis: reactQueryStatusValues.isRequired,
  }).isRequired,
  showEhrRibbon: bool,
  user: shape({
    id: number,
    name: string,
  }).isRequired,
  ehrUpdateMessage: string,
  dxDateOfServicePicker: node,
  isDxSubmitDisabled: bool,
};
CompactPatientSummary.defaultProps = {
  defaultProviderChoiceId: undefined,
  formState: {},
  onSaveDiagnosisAction: undefined,
  onSaveNonDiagnosisAction: undefined,
  showEhrRibbon: true,
  ehrUpdateMessage: '',
  dxDateOfServicePicker: undefined,
  isDxSubmitDisabled: false,
};

/**
 * A Container that contains the correct max-width and padding for PatientSummaryPageEhr.
 */
const PaddedContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  [theme.breakpoints.down('md')]: { p: 0 },
  [theme.breakpoints.up('xs')]: {
    maxWidth: 1264,
    padding: theme.spacing(0, 1),
  },
}));
