import {
  List,
  ListItem,
  ListItemButton,
  Popover,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';
import { toLower } from 'lodash';

import { useQueryParameters } from '@/contexts/QueryParametersContext';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/chevronDown.svg';

import { DEFAULT_SORT_DIRECTION, DEFAULT_SORT_FIELD } from '../../constants';
import useGetPageMetadata from '../../hooks/useGetPageMetadata';

export default function SortSelect() {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    parameters: { sortField = DEFAULT_SORT_FIELD },
    mergeParameters,
  } = useQueryParameters();

  const { data: metadata } = useGetPageMetadata();
  const { columnConfigurations = [] } = metadata || {};

  const sortOptions = columnConfigurations
    .filter((config) => config.sortable)
    .map((config) => ({ value: config.sortField, label: config.label }));

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOption = (newValue) => {
    const { sortDirection: newSortDirection = DEFAULT_SORT_DIRECTION } =
      sortOptions.find((option) => option.value === newValue);
    mergeParameters({
      sortField: newValue,
      sortDirection: newSortDirection,
    });
    setAnchorEl(null);
  };

  const selectedOption = sortOptions.find(
    (option) => option.value === sortField,
  );

  const open = Boolean(anchorEl);

  return (
    <SortSelectRoot>
      <StyledButton onClick={handleClick}>
        <Typography component="span" variant="bodysmall">
          Sort by {toLower(selectedOption?.label)}
        </Typography>
        <StyledChevronDownIcon />
      </StyledButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          {sortOptions.map((option) => (
            <StyledListItem key={option.value}>
              <StyledListItemButton
                onClick={() => handleClickOption(option.value)}
              >
                {option.label}
              </StyledListItemButton>
            </StyledListItem>
          ))}
        </List>
      </Popover>
    </SortSelectRoot>
  );
}

const StyledButton = styled('button')`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.palette.text.secondary};
  position: relative;
`;

const SortSelectRoot = styled('div')``;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  height: 16px;
  width: 16px;
  margin-left: ${(p) => p.theme.spacing(0.5)};
`;

const StyledListItem = styled(ListItem)`
  padding: 0;
`;

const StyledListItemButton = styled(ListItemButton)`
  &:hover {
    background-color: ${(p) => p.theme.palette.background.primaryLightest};
  }
`;
