import { Link } from '@mui/material';

/**
 * A button that looks like a link.
 */
function BaseLinkButton(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link {...props} component="button" />;
}

export default BaseLinkButton;
