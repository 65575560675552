import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

async function postChartUploadCsv(requests) {
  const params = {
    requests,
  };
  const response = await axios.post(
    `/ops_tools/api/actions/chart_upload_csv`,
    params,
  );
  return response.data;
}

export default function usePostChartUploadCsv(options) {
  return useMutation(({ requests }) => postChartUploadCsv(requests), options);
}
