import { Button, Dialog, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useError } from 'react-use';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import CompactPatientSummaryContainer from '@/components/CompactPatientSummary/components/CompactPatientSummaryContainer';
import { useExecuteSteps } from '@/pages/TaskBasedWorkflow/hooks/useExecuteStep';
import DxDateOfServicePicker from '@/components/CompactPatientSummary/DxDateOfServicePicker';
import useFeatureFlag from '@/utils/useFeatureFlag';

function urlFormatter({ patientId }) {
  return `/patient/${patientId}/actions/update`;
}

export default function PatientSummaryModal({ onClose, open, patientId }) {
  const dispatchError = useError();
  const queryClient = useQueryClient();
  // Flag to indicate the endpoint should attempt to create patient visits for quality actions
  // DX actions create the visits regardless (different endpoint)
  const createPatientVisit = useFeatureFlag(
    'patient_summary_modal_creates_patient_visits',
  );
  const { mutateAsync, status } = useExecuteSteps({
    onSuccess: () => window.stellar?.refreshSvuTracker?.(),
    onError: dispatchError,
    onSettled: async () => {
      await queryClient.invalidateQueries(['ehr-summary']);
    },
    createPatientVisits: createPatientVisit,
  });

  const [dxDateOfService, setDxDateOfService] = useState(null);
  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={onClose}
      PaperProps={{ sx: { minWidth: 300, minHeight: 300, maxWidth: 825 } }}
    >
      <Stack direction="row" justifyContent="flex-end" p={2}>
        <Button variant="text" onClick={onClose}>
          Close
        </Button>
      </Stack>

      <CompactPatientSummaryContainer
        patientId={patientId}
        onSaveNonDiagnosisAction={mutateAsync}
        nonDxSaveStatus={status}
        showCompleted={false}
        urlFormatter={urlFormatter}
        dxDateOfService={dxDateOfService}
        isDxSubmitDisabled={!dxDateOfService}
        dxDateOfServicePicker={
          <DxDateOfServicePicker
            dateOfService={dxDateOfService}
            setDateOfService={setDxDateOfService}
          />
        }
      />
    </Dialog>
  );
}

PatientSummaryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  patientId: PropTypes.number.isRequired,
};
