import PropTypes from 'prop-types';
import { useToggle } from 'react-use';
import { Button, styled } from '@mui/material';

import { stepShape } from '@/utils/transformFrontendCareGapAction';
import { actionShape } from '@/utils/transformFrontendAction';
import { findRelatedActions } from '@/utils/relatedActions';
import { completedTaskShape, userInputTaskShape } from '@/utils/shapes';

import ActionCard from './ActionCard';
import CompletedActionCard from './CompletedActionCard';

export const actionCardsMarginDefault = '16px';

export default function CenterPanel({
  userInputTasks,
  onRemoveStep,
  onSelectStep,
  removedActions,
  selectedAction,
  selectedStep,
  completedActions,
  sx,
  completedTasks,
}) {
  const [showHiddenTasks, toggleShowHiddenTasks] = useToggle(false);

  const hiddenTaskCount = userInputTasks.reduce(
    (acc, task) => (task.hiddenForNow ? acc + 1 : acc),
    0,
  );
  const visibleUserInputTasks = showHiddenTasks
    ? userInputTasks
    : userInputTasks.filter((task) => !task.hiddenForNow);

  // adjust data to common fields so we can reuse codes across PS and TBW
  const getActionsToGroup = (actions) => {
    return actions
      .filter((task) => !!task.action.v2Dto.contextualInfo.adtEventDate)
      .map((task) => {
        return {
          id: task.action.id,
          description: task.action.v2Dto.description,
          availableNextSteps: task.action.v2Dto.availableNextSteps,
          adtEventDate: task.action.v2Dto.contextualInfo.adtEventDate,
        };
      });
  };
  const actionsToGroup = getActionsToGroup(userInputTasks);

  return (
    <CenterPanelRoot sx={sx}>
      {completedTasks.map((task) => (
        <CompletedActionCard key={task.task.id} task={task} />
      ))}
      {visibleUserInputTasks.map((userInputTask) => (
        <ActionCard
          action={userInputTask.action}
          completedActions={completedActions}
          key={userInputTask.id}
          onRemoveStep={onRemoveStep}
          onSelectStep={onSelectStep}
          removedActions={removedActions}
          selectedAction={selectedAction}
          selectedStep={selectedStep}
          userInputTask={userInputTask}
          relatedActions={findRelatedActions(
            userInputTask.action?.v2Dto?.contextualInfo?.adtEventDate,
            actionsToGroup,
          )}
        />
      ))}

      {!showHiddenTasks && hiddenTaskCount > 0 ? (
        <Button variant="text" sx={{ m: 2 }} onClick={toggleShowHiddenTasks}>
          View {hiddenTaskCount} hidden care gap
          {hiddenTaskCount > 1 ? 's' : null}
        </Button>
      ) : null}
    </CenterPanelRoot>
  );
}

CenterPanel.propTypes = {
  completedActions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRemoveStep: PropTypes.func.isRequired,
  onSelectStep: PropTypes.func.isRequired,
  removedActions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedAction: actionShape,
  selectedStep: stepShape,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  userInputTasks: PropTypes.arrayOf(userInputTaskShape).isRequired,
  completedTasks: PropTypes.arrayOf(completedTaskShape).isRequired,
};

CenterPanel.defaultProps = {
  selectedAction: undefined,
  selectedStep: undefined,
  sx: undefined,
};

const CenterPanelRoot = styled('div')``;
