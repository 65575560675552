import { ReactComponent as InpatientIcon } from '@/assets/icons/inpatient.svg';
import { ReactComponent as LabIcon } from '@/assets/icons/lab.svg';
import { ReactComponent as OfficeIcon } from '@/assets/icons/office.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';

export function getIconComponent(sourceType) {
  if (sourceType === '') return InfoIcon;
  if (sourceType === 'INPATIENT') return InpatientIcon;
  if (sourceType === 'LAB') return LabIcon;
  return OfficeIcon;
}

export function locationAndDateText(diagnosis) {
  const { sourceName, date } = diagnosis;
  let locationString;
  let dateString;

  // The server passes "--" as a way to indicate that the location or date
  // are not known. Be future-proof here, so if the server ever decides to
  // reconsider and pass `null` instead, this function won't break.
  const isSet = (v) => v && v !== '--';
  if (isSet(sourceName) && isSet(date)) {
    locationString = `${sourceName} `;
    dateString = `on ${date}`;
  } else if (isSet(date)) {
    dateString = `On ${date}`;
  } else if (isSet(sourceName)) {
    locationString = sourceName;
  }

  return { locationString, dateString };
}
