import { styled } from '@mui/material';

import { actionShape } from '@/utils/transformFrontendAction';

export default function ActionCompleteBy({ action }) {
  if (action.v2Dto.completeBy) {
    return <Date>{action.v2Dto.completeBy}</Date>;
  }
  return <Date>{action.v2Dto.expiresAt}</Date>;
}

ActionCompleteBy.propTypes = {
  action: actionShape.isRequired,
};

const Date = styled('span')`
  white-space: nowrap;
`;
