import { Typography, styled } from '@mui/material';
import { arrayOf, bool, exact, func, number, object } from 'prop-types';
import { isEmpty } from 'lodash';

import { ReactComponent as Quality } from '@/assets/icons/quality.svg';
import TableCard from '@/components/TableCard';
import { patientShape, reactQueryStatusValues } from '@/utils/shapes';
import { findRelatedActions, getActionsToGroup } from '@/utils/relatedActions';
import { color, spacing, typography } from '@/utils/stylingUtils';
import { CompletedSubtext as CompletedSubtextV1 } from '@/pages/PatientSummary/components/SectionCareGaps/CompletedCareGapActionRow';
import { CompletedSubtext as CompletedSubtextV2 } from '@/pages/PatientSummary/components/SectionCareGaps/CompletedCareGapActionRowV2';
import useFeatureFlag from '@/utils/useFeatureFlag';

import SvuCell from '../SvuCell';
import CompactPatientSummarySection from '../CompactPatientSummarySection';

import ExecutionRequirementsPanel from './ExecutionRequirementsPanel';
import WriteStatusCell from './WriteStatusCell';
import ReadStatusCell, { getStatusAndDetail } from './ReadStatusCell';
import MeasureCell from './MeasureCell';
import CompletedStatusCell from './CompletedStatusCell';
import EhrStatusCell from './EhrStatusCell';

function hasEhrEvidence(action) {
  return (
    action.contextualInfo?.latestExternalGapHistory?.sourceType === 'fhir_bulk'
  );
}

function getMeasureCell(action) {
  const text = action.version
    ? // V2 actions, depending on when they were built, use different fields for
      // action name. displayName is preferred today, but used to be description.
      action.displayName || action.description
    : action.text;
  const date = action.version
    ? action.contextualInfo?.mostRecentDateForDisplay
    : action.date;
  const providerName = action.version
    ? action.contextualInfo?.mostRecentProviderNameForDisplay
    : action.providerName;

  const measureTextValues = ['Last performed'];
  if (providerName) {
    measureTextValues.push(`by ${providerName}`);
  }
  if (date) {
    measureTextValues.push(`on ${date}`);
  }

  return (
    <MeasureCell
      text={text}
      date={date}
      providerName={providerName}
      measureTextValues={measureTextValues}
    />
  );
}

export function getWriteStatusCell(action, open, setOpen) {
  const choices = action.version
    ? action.availableNextSteps.map((step) => {
        return { value: step.stepId, label: step.description };
      })
    : action.respondChoices.filter((choice) => choice.value !== action.state);
  const [status, extraDetail] = getStatusAndDetail(action);
  return (
    <WriteStatusCell
      choices={choices}
      status={status}
      extraDetail={extraDetail}
      open={open}
      setOpen={setOpen}
    />
  );
}

const getIncompleteStatusCell = (
  canWriteNonDxActions,
  action,
  open,
  setOpen,
) => {
  return canWriteNonDxActions ? (
    getWriteStatusCell(action, open, setOpen)
  ) : (
    <ReadStatusCell action={action} />
  );
};

const getEhrStatusCell = (
  actionsToGroup,
  onSaveNonDiagnosisAction,
  patient,
  action,
  open,
  setOpen,
) => {
  const relatedActions = findRelatedActions(
    action.contextualInfo?.adtEventDate,
    actionsToGroup,
  );
  const choices = action.version
    ? action.availableNextSteps.map((step) => {
        return { value: step.stepId, label: step.description };
      })
    : null;
  return action.version ? (
    <EhrStatusCell
      action={action}
      open={open}
      setOpen={setOpen}
      onSaveNonDiagnosisAction={onSaveNonDiagnosisAction}
      patient={patient}
      relatedActions={relatedActions}
      choices={choices}
    />
  ) : null;
};

const EHR_COLUMN_DEFINITION = [
  {
    id: 'measure',
    label: 'Measure',
    renderCell: ({ data }) => getMeasureCell(data),
  },
  {
    id: 'status',
    label: 'Status',
    renderCell: ({
      additionalProps: { actionsToGroup, onSaveNonDiagnosisAction, patient },
      data,
      open,
      setOpen,
    }) =>
      getEhrStatusCell(
        actionsToGroup,
        onSaveNonDiagnosisAction,
        patient,
        data,
        open,
        setOpen,
      ),
  },
];

const INCOMPLETE_COLUMN_DEFINITION = [
  {
    id: 'measure',
    label: 'Measure',
    renderCell: ({ data }) => getMeasureCell(data),
  },
  {
    id: 'status',
    label: 'Status',
    renderCell: ({
      additionalProps: { onSaveNonDiagnosisAction },
      data,
      open,
      setOpen,
    }) =>
      getIncompleteStatusCell(
        Boolean(onSaveNonDiagnosisAction),
        data,
        open,
        setOpen,
      ),
  },
];

const INCOMPLETE_ROW_DEFINITION = {
  rowId: (r) => (r.version ? r.actionId : r.id),
  renderActions: ({
    additionalProps: {
      actionsToGroup,
      defaultProviderChoiceId,
      formState,
      onSaveNonDiagnosisAction,
      patient,
      saveStatus,
    },
    open,
    data,
    setOpen,
  }) => {
    return open ? (
      <ExecutionRequirementsPanel
        action={data}
        defaultProviderChoiceId={defaultProviderChoiceId}
        formState={formState}
        open={open}
        onCancel={() => setOpen(false)}
        onSaveNonDiagnosisAction={onSaveNonDiagnosisAction}
        patient={patient}
        relatedActions={findRelatedActions(
          data.contextualInfo?.adtEventDate,
          actionsToGroup,
        )}
        saveStatus={saveStatus}
      />
    ) : null;
  },
  renderRowBadge: ({ data }) => <SvuCell action={data} />,
};

// adapted from PatientSummary/CompletedCareGapActionRow/V2
function getCompletedStatusCell(action) {
  const lastHistory =
    action.stepHistories?.length > 0
      ? action.stepHistories[action.stepHistories.length - 1]
      : {};
  const qualityEvidence = hasEhrEvidence(action)
    ? action.contextualInfo.latestExternalGapHistory
    : null;
  const status = action.version
    ? lastHistory.description || 'Completed'
    : action.state.replaceAll('_', ' ');
  const subtext = action.version ? (
    <CompletedSubtextV2
      lastHistory={lastHistory}
      qualityEvidence={qualityEvidence}
    />
  ) : (
    <CompletedSubtextV1 action={action} />
  );

  return <CompletedStatusCell status={status} subtext={subtext} />;
}

const COMPLETE_COLUMN_DEFINITION = [
  {
    id: 'measure',
    label: 'Measure',
    renderCell: ({ data }) => getMeasureCell(data),
  },
  {
    id: 'status',
    label: 'Status',
    renderCell: ({ data }) => getCompletedStatusCell(data),
  },
];
const COMPLETE_ROW_DEFINITION = {
  rowId: (r) => (r.version ? r.actionId : r.id),
  renderRowBadge: ({ data }) => <SvuCell action={data} complete />,
};

/**
 *
 * @param {{ incomplete: Action[], complete: Action[] }} actions
 * @param {object?} formState
 * @param {number?} defaultProviderChoiceId
 * @param {() => Promise<unknown>?} onSaveNonDiagnosisAction
 * @param {(section: string) => void?} onSetSelected
 * @param {Patient} patient
 * @param {'idle' | 'loading' | 'error' | 'success'} saveStatus
 * @param {boolean?} selected
 * @param {number?} svus
 * @returns {JSX.Element|null}
 */
export default function NonDiagnosisSection({
  actions,
  formState,
  defaultProviderChoiceId,
  onSaveNonDiagnosisAction,
  onSetSelected,
  patient,
  saveStatus,
  selected,
  svus,
}) {
  const showEhrQualityEvidence = useFeatureFlag('showEhrQualityEvidence');
  const evidenceActions = showEhrQualityEvidence
    ? actions.incomplete.filter((action) => hasEhrEvidence(action))
    : [];
  const incompleteActions = showEhrQualityEvidence
    ? actions.incomplete.filter((action) => !hasEhrEvidence(action))
    : actions.incomplete;

  const hasIncompleteActions = !isEmpty(incompleteActions);
  const hasEvidenceActions = !isEmpty(evidenceActions);
  const hasCompletedActions = !isEmpty(actions.complete);

  const actionsToGroupIncomplete = getActionsToGroup(incompleteActions);
  const actionsToGroupEvidence = getActionsToGroup(evidenceActions);

  // If there are no evidence or incomplete or complete actions, render nothing.
  if (!hasEvidenceActions && !hasIncompleteActions && !hasCompletedActions)
    return null;

  return (
    <CompactPatientSummarySection
      headline="Quality Measures"
      icon={Quality}
      onSetSelected={onSetSelected}
      selected={selected}
      svus={svus}
    >
      {showEhrQualityEvidence &&
        (hasEvidenceActions ? (
          <>
            <StyledIncompleteLabel>
              Completed in EHR ({evidenceActions.length})
            </StyledIncompleteLabel>
            <TableCard
              data={evidenceActions}
              cardBreakpoint={400}
              rowDefinition={INCOMPLETE_ROW_DEFINITION}
              columnDefinition={EHR_COLUMN_DEFINITION}
              additionalProps={{
                actionsToGroup: actionsToGroupEvidence,
                defaultProviderChoiceId,
                formState,
                onSaveNonDiagnosisAction,
                patient,
                saveStatus,
              }}
            />
          </>
        ) : (
          <>
            <StyledIncompleteLabel sx={{ color: 'text.secondary' }}>
              Completed in EHR (0)
            </StyledIncompleteLabel>
            <Typography variant="bodysmall" sx={{ px: 1 }}>
              No updated gaps found
            </Typography>
          </>
        ))}
      {hasIncompleteActions ? (
        <>
          <StyledIncompleteLabel>
            Not started or in progress ({incompleteActions.length})
          </StyledIncompleteLabel>
          <TableCard
            data={incompleteActions}
            cardBreakpoint={400}
            rowDefinition={INCOMPLETE_ROW_DEFINITION}
            columnDefinition={INCOMPLETE_COLUMN_DEFINITION}
            additionalProps={{
              actionsToGroup: actionsToGroupIncomplete,
              defaultProviderChoiceId,
              formState,
              onSaveNonDiagnosisAction,
              patient,
              saveStatus,
            }}
          />
        </>
      ) : null}

      {hasCompletedActions ? (
        <>
          <StyledCompletedLabel>
            Completed ({actions.complete.length})
          </StyledCompletedLabel>
          <Typography component="span" variant="bodysmall" sx={{ pl: 1 }}>
            Some of the completed measures below may earn additional SVUs once a
            claim confirms completion.
          </Typography>
          <TableCard
            data={actions.complete}
            cardBreakpoint={400}
            rowDefinition={COMPLETE_ROW_DEFINITION}
            columnDefinition={COMPLETE_COLUMN_DEFINITION}
          />
        </>
      ) : null}
    </CompactPatientSummarySection>
  );
}

NonDiagnosisSection.propTypes = {
  actions: exact({
    complete: arrayOf(object.isRequired).isRequired,
    incomplete: arrayOf(object.isRequired).isRequired,
  }).isRequired,
  formState: object,
  defaultProviderChoiceId: number,
  onSaveNonDiagnosisAction: func,
  onSetSelected: func.isRequired,
  saveStatus: reactQueryStatusValues.isRequired,
  selected: bool,
  svus: number,
  patient: patientShape.isRequired,
};

NonDiagnosisSection.defaultProps = {
  formState: {},
  defaultProviderChoiceId: undefined,
  onSaveNonDiagnosisAction: undefined,
  selected: false,
  svus: undefined,
};

const StyledCompletedLabel = styled(Typography)`
  color: ${color('status.successForeground')};
  padding: ${spacing(1)};
  ${typography('bodybold')}
`;

const StyledIncompleteLabel = styled(Typography)`
  color: ${color('text.link')};
  padding: ${spacing(1)};
  ${typography('bodybold')}
`;
