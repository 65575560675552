import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
import { ErrorMessage } from '@hookform/error-message';

export default function FormErrorMessage({ errors, name }) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message, messages }) => {
        // More than one error message can be associated with a field. In which
        // case, they are passed as a map of { error_name: "error_message" }.
        // Print all these out:
        if (messages) {
          return Object.entries(messages).map(([key, value]) => (
            <FormHelperText key={key} error sx={{ mt: 0.5 }} role="alert">
              {value}
            </FormHelperText>
          ));
        }

        return (
          <FormHelperText error sx={{ mt: 0.5 }} role="alert">
            {message}
          </FormHelperText>
        );
      }}
    />
  );
}

FormErrorMessage.propTypes = {
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
