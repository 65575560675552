import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

async function fetchUser() {
  const response = await axios.get('/api/user');
  return response.data;
}

export default function useGetUser({ enabled = true } = {}) {
  return useQuery({
    queryFn: fetchUser,
    queryKey: ['user'],
    enabled,
  });
}
