import { Typography } from '@mui/material';

import { actionShape } from '@/utils/transformFrontendAction';

export default function ActionSvu({ action }) {
  return (
    <Typography color="svu.dark" component="span" variant="bodymedium">
      {action.v2Dto.possibleSvusRemainingPostPromo ||
        action.v2Dto.possibleSvusRemainingPrePromo}
    </Typography>
  );
}

ActionSvu.propTypes = {
  action: actionShape.isRequired,
};
