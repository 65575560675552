import { FormLabel, Stack } from '@mui/material';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import useId from '@mui/utils/useId';
import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import RhfCheckbox from '@/components/reactHookFormComponents/RhfCheckbox';
import Electronic1099Form from '@/pages/PaymentInstructions/components/PaymentMethodStep/Electronic1099Form';
import RhfInput from '@/components/reactHookFormComponents/RhfInput';
import FormErrorMessage from '@/components/FormErrorMessage';

/* eslint-disable-next-line */
const EMAIL_REGEX = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;

const INVALID_EMAIL_MESSAGE = 'Email has an invalid format.';

export default function EmailInputs({
  control,
  formErrors,
  emailFor1099InputName,
  checkboxForDigital1099Name,
  electronic1099SignatureName,
  resetField,
  paymentNotificationEmailName,
  agreement,
  isLoadingAgreement,
}) {
  const agreedToDigital1099 = useWatch({
    control,
    name: checkboxForDigital1099Name,
  });

  const paymentNotificationEmailId = useId();

  const handleOnChangeCheckbox = (checked) => {
    if (!checked) {
      resetField(emailFor1099InputName);
      resetField(electronic1099SignatureName);
    }
  };

  return (
    <Stack spacing={1} alignItems="center">
      <Typography variant="h2">Notification preferences</Typography>
      <Stack spacing={2} width="480px">
        <InputContainer>
          <FormLabel htmlFor={paymentNotificationEmailId}>
            Email address for payment notifications
          </FormLabel>
          <RhfInput
            name={paymentNotificationEmailName}
            id={paymentNotificationEmailId}
            control={control}
            rules={{
              pattern: {
                value: EMAIL_REGEX,
                message: INVALID_EMAIL_MESSAGE,
              },
              required: true,
            }}
          />
          <FormErrorMessage
            errors={formErrors}
            name={paymentNotificationEmailName}
          />
        </InputContainer>
        <RhfCheckbox
          name={checkboxForDigital1099Name}
          label={
            <Typography align="justify">
              Yes, I consent to receive my 1099 filing electronically instead of
              physical mail (check the box indicating your consent).
            </Typography>
          }
          control={control}
          onChangeExtraHandler={handleOnChangeCheckbox}
        />
        {agreedToDigital1099 ? (
          <Electronic1099Form
            emailFor1099InputName={emailFor1099InputName}
            electronic1099SignatureName={electronic1099SignatureName}
            agreement={agreement}
            isLoadingAgreement={isLoadingAgreement}
            formErrors={formErrors}
            control={control}
          />
        ) : null}
      </Stack>
    </Stack>
  );
}

const InputContainer = styled(Box)`
  max-width: 480px;
  width: 100%;
`;

EmailInputs.propTypes = {
  formErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  emailFor1099InputName: PropTypes.string.isRequired,
  checkboxForDigital1099Name: PropTypes.string.isRequired,
  resetField: PropTypes.func.isRequired,
  paymentNotificationEmailName: PropTypes.string.isRequired,
  isLoadingAgreement: PropTypes.bool,
  agreement: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    version: PropTypes.number,
    text: PropTypes.string,
  }),
  electronic1099SignatureName: PropTypes.string.isRequired,
};

EmailInputs.defaultProps = {
  formErrors: undefined,
  isLoadingAgreement: false,
  agreement: undefined,
};
