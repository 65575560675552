import { Checkbox, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffectOnce } from 'react-use';

import { actionShape } from '@/utils/transformFrontendAction';
import { useSelectedTasksContext } from '@/pages/TaskBasedWorkflow/contexts/SelectedTasksContext';
import { userInputTaskShape } from '@/utils/shapes';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';

function hasLatestExternalUpdate(contextualInfo) {
  return !isEmpty(contextualInfo?.latestExternalCareGap);
}

export default function EhrVisitStatus({ action, task }) {
  const { selectTask, unselectTask, isTaskSelected } =
    useSelectedTasksContext();

  const {
    data: contextualInfo,
    isError,
    isLoading,
  } = useGetContextualInfo(action, {
    onSuccess: (contextualInfo) => {
      if (hasLatestExternalUpdate(contextualInfo)) {
        selectTask(task);
      }
    },
  });

  useEffectOnce(() => {
    // If contextualInfo is not lazy-loaded, the `onSuccess` callback above does not get called.
    // Therefore, duplicate the logic inside `onSuccess` to handle the non-lazy-loaded case.
    if (!isLoading) {
      if (hasLatestExternalUpdate(contextualInfo)) {
        selectTask(task);
      }
    }
  });

  if (isLoading) return <FieldSkeleton />;
  if (isError) return null;

  const latestExternalCareGap = contextualInfo?.latestExternalCareGap;
  const checked = isTaskSelected(task);

  const handleClick = (e) => {
    // Block the row's click handler from being called.
    e.stopPropagation();

    if (e.target.checked) {
      selectTask(task);
    } else {
      unselectTask(task);
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography component="span" variant="bodymedium">
        {latestExternalCareGap?.details?.appointmentStatus}
      </Typography>
      {hasLatestExternalUpdate(contextualInfo) ? (
        <Checkbox
          onClick={handleClick}
          checked={checked}
          inputProps={{ 'data-testid': 'select-task-checkbox' }}
          data-pendo-id="ehr-task-select-checkbox"
        />
      ) : null}
    </Stack>
  );
}

EhrVisitStatus.propTypes = {
  action: actionShape.isRequired,
  task: userInputTaskShape.isRequired,
};
