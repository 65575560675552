import { string } from 'prop-types';
import { FormLabel } from '@mui/material';
import useId from '@mui/utils/useId';

import RhfInput from '@/components/reactHookFormComponents/RhfInput';
import InputContainer from '@/pages/PaymentInstructions/components/InputContainer';

export default function AccountHolderNameInput({ name, control, label }) {
  const accountHolderNameId = useId();

  return (
    <InputContainer>
      <FormLabel htmlFor={accountHolderNameId}>{label}</FormLabel>
      <RhfInput
        name={name}
        id={accountHolderNameId}
        control={control}
        rules={{ required: true }}
      />
    </InputContainer>
  );
}

AccountHolderNameInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
};
