import { Box, LinearProgress, Stack, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';

import { CommonProgressText } from '@/pages/TaskBasedWorkflow/components/taskTableView/PatientExperience/commonStyles';

const HelpTaskCompletionLabel = styled(Typography)`
  font-size: ${(p) => p.theme.typography.bodymedium.fontSize};
`;

const HelpCoordTaskLabel = styled(Typography)`
  font-size: ${(p) => p.theme.typography.bodymedium.fontSize};
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  padding-top: ${(p) => p.theme.spacing(1)};
`;

const HelpTaskCompletionPercent = styled(Typography)`
  font-size: ${(p) => p.theme.typography.metric.fontSize};
  color: ${(p) => p.theme.palette.status.black};
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  padding-right: ${(p) => p.theme.spacing(0.625)};
`;

const HelpTaskProgressLabel = styled(CommonProgressText)`
  font-size: ${(p) => p.theme.typography.bodysmall.fontSize};
`;

const HelpTaskProgressPercent = styled(CommonProgressText)`
  font-size: ${(p) => p.theme.typography.metricsmall.fontSize};
`;

const HelpTaskCompleteLabel = styled(HelpTaskProgressLabel)`
  color: ${(p) => p.theme.palette.status?.successForeground};
`;

const HelpTaskCompletePercent = styled(HelpTaskProgressPercent)`
  color: ${(p) => p.theme.palette.status?.successForeground};
`;

const HelpTaskIncompleteLabel = styled(HelpTaskProgressLabel)`
  color: ${(p) => p.theme.palette.status?.warningForeground};
`;

const HelpTaskIncompletePercent = styled(HelpTaskProgressPercent)`
  color: ${(p) => p.theme.palette.status?.warningForeground};
`;

const ProgressBar = styled(LinearProgress)`
  background-color: ${(p) => p.theme.palette.status?.warningBackgroundHover};
  & .MuiLinearProgress-bar {
    background-color: #26ab83;
  }
  width: 100%;
  height: 8px;
  border-radius: ${(p) => 0.5 * p.theme.shape.borderRadius}px;
`;

const HelpProgressBox = styled(Box)`
  width: 100%;
  padding: ${(p) => p.theme.spacing(1, 2)};
`;

const BoxCenter = styled(Box)`
  text-align: center;
`;

export default function PatientExperienceHelpTaskCompletion({
  totalHelperCoordCare,
  completedHelperCoordCare,
  totalHelperCoordMeds,
  completedHelperCoordMeds,
}) {
  const coordCareCompletionRate =
    completedHelperCoordCare / totalHelperCoordCare;
  const coordMedsCompletionRate =
    completedHelperCoordMeds / totalHelperCoordMeds;
  const coordCareCompletionPercent = coordCareCompletionRate * 100;
  const coordMedsCompletionPercent = coordMedsCompletionRate * 100;
  const helpTaskCompletePercent =
    ((coordCareCompletionRate + coordMedsCompletionRate) / 2) * 100;
  const incompleteHelperCoordCare =
    totalHelperCoordCare - completedHelperCoordCare;
  const incompleteHelperCoordMeds =
    totalHelperCoordMeds - completedHelperCoordMeds;

  return (
    <Stack
      width="100%"
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <BoxCenter paddingX={2} paddingY={2}>
        <Typography fontWeight="bold">Help task completion</Typography>
      </BoxCenter>
      <Box>
        <HelpTaskCompletionPercent component="span">
          {totalHelperCoordCare ? helpTaskCompletePercent.toFixed(1) : '0'}%
        </HelpTaskCompletionPercent>
        <HelpTaskCompletionLabel component="span">
          of help tasks completed
        </HelpTaskCompletionLabel>
      </Box>
      <Box height={180}>
        <HelpProgressBox>
          <HelpCoordTaskLabel>Help with care coordination</HelpCoordTaskLabel>
          <ProgressBar
            variant="determinate"
            value={coordCareCompletionPercent}
          />
          <Stack direction="row">
            <Box width={185}>
              <HelpTaskCompletePercent component="span">
                {completedHelperCoordCare}{' '}
              </HelpTaskCompletePercent>
              <HelpTaskCompleteLabel component="span">
                patients received help
              </HelpTaskCompleteLabel>
            </Box>
            <Box>
              <HelpTaskIncompletePercent component="span">
                {incompleteHelperCoordCare}{' '}
              </HelpTaskIncompletePercent>
              <HelpTaskIncompleteLabel component="span">
                did not yet receive help
              </HelpTaskIncompleteLabel>
            </Box>
          </Stack>
        </HelpProgressBox>
        <HelpProgressBox>
          <HelpCoordTaskLabel>Help with medications</HelpCoordTaskLabel>
          <ProgressBar
            variant="determinate"
            value={coordMedsCompletionPercent}
          />
          <Stack direction="row">
            <Box width={185}>
              <HelpTaskCompletePercent component="span">
                {completedHelperCoordMeds}{' '}
              </HelpTaskCompletePercent>
              <HelpTaskCompleteLabel component="span">
                patients received help
              </HelpTaskCompleteLabel>
            </Box>
            <Box>
              <HelpTaskIncompletePercent component="span">
                {incompleteHelperCoordMeds}{' '}
              </HelpTaskIncompletePercent>
              <HelpTaskIncompleteLabel component="span">
                did not yet receive help
              </HelpTaskIncompleteLabel>
            </Box>
          </Stack>
        </HelpProgressBox>
      </Box>
    </Stack>
  );
}

PatientExperienceHelpTaskCompletion.propTypes = {
  totalHelperCoordCare: PropTypes.number,
  completedHelperCoordCare: PropTypes.number,
  totalHelperCoordMeds: PropTypes.number,
  completedHelperCoordMeds: PropTypes.number,
};

PatientExperienceHelpTaskCompletion.defaultProps = {
  totalHelperCoordCare: 0,
  completedHelperCoordCare: 0,
  totalHelperCoordMeds: 0,
  completedHelperCoordMeds: 0,
};
