import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

import {
  CHECK,
  STATE_COMPLETE,
  STATE_INCOMPLETE,
  STATE_SKIPPED,
} from '@/pages/PatientSummary/utils/constants';
import { ReactComponent as SkippedStepIcon } from '@/assets/icons/skipStep.svg';

import { BulletWrapper, StyledTooltip } from '../../utils/styles';

import { ProgressBullet, ProgressDiv } from './ActionProgress';

function getBulletSymbol(bulletState) {
  switch (bulletState) {
    case STATE_COMPLETE:
      return CHECK;
    case STATE_SKIPPED:
      return <SkippedProgressBullet />;
    default:
      return undefined;
  }
}

function ActionProgressTooltip({ bulletData, discs }) {
  return (
    <>
      <Typography variant="h3" align="center">
        Steps to Completion
      </Typography>
      <Table>
        <TableBody>
          {bulletData.map((bullet, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`${bullet.description}-${index}`}>
                <TableCell align="center">{discs[index]}</TableCell>
                <TableCell>
                  <Typography variant="label">{bullet.description}</Typography>
                  <Typography variant="bodysmall">
                    {bullet.stepsToCompletionDetails}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="bodysmall" sx={{ color: 'svu.dark' }}>
                    {bullet.svusPostPromo ? (
                      <>
                        <s>{bullet.svusPrePromo}</s> {bullet.svusPostPromo}
                      </>
                    ) : (
                      bullet.svusPrePromo
                    )}{' '}
                    SVUs
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

function ActionProgressV2({ action }) {
  const history = [];
  const seenStepIds = [];
  action.stepHistories?.forEach((step) => {
    step.skippedSteps?.forEach((skippedStep) => {
      history.push({
        description: skippedStep.description,
        stepstoCompletionDetails: skippedStep.stepsToCompletionDetails,
        state: STATE_SKIPPED,
        svusPrePromo: 0, // Zero SVUs awarded for skipped steps
        svusPostPromo: 0, // Zero SVUs awarded for skipped steps
      });
    });

    if (!seenStepIds.includes(step.stepId)) {
      history.push({
        description: step.description,
        stepsToCompletionDetails: step.stepsToCompletionDetails,
        state: STATE_COMPLETE,
        svusPrePromo: Number(step.svusPrePromo),
        svusPostPromo: Number(step.svusPostPromo),
      });
      seenStepIds.push(step.stepId);
    }
  });

  const future = action.preferredPath?.map((step) => {
    return {
      description: step.description,
      stepsToCompletionDetails: step.stepsToCompletionDetails,
      state: STATE_INCOMPLETE,
      svusPrePromo: Number(step.svuPrePromo),
      svusPostPromo: Number(step.svuPostPromo),
    };
  });

  const bulletData = [...(history ?? []), ...(future ?? [])];

  const bullets = bulletData.map((bullet, index) => {
    return (
      <ProgressBullet
        bgColor={bullet.state}
        // eslint-disable-next-line react/no-array-index-key
        key={`standard_step_type-${index}`}
      >
        {getBulletSymbol(bullet.state) || index + 1}
      </ProgressBullet>
    );
  });

  const tooltip = (
    <ActionProgressTooltip bulletData={bulletData} discs={bullets} />
  );

  return (
    <StyledTooltip title={tooltip}>
      <ProgressDiv tabIndex="0">
        {bullets.map((bullet, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <BulletWrapper key={index}>{bullet}</BulletWrapper>
        ))}
      </ProgressDiv>
    </StyledTooltip>
  );
}

export default ActionProgressV2;

ActionProgressTooltip.propTypes = {
  bulletData: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      state: PropTypes.string,
      svusPrePromo: PropTypes.number,
      svusPostPromo: PropTypes.number,
    }),
  ),
  discs: PropTypes.arrayOf(PropTypes.element),
};

ActionProgressTooltip.defaultProps = {
  bulletData: [],
  discs: [],
};

ActionProgressV2.propTypes = {
  action: PropTypes.object.isRequired,
};

const SkippedProgressBullet = styled(SkippedStepIcon)`
  width: 20px;
  height: 20px;
  vertical-align: bottom;
  margin-bottom: -2px;
  color: ${(p) => p.theme.palette.status.noneForeground};
`;
