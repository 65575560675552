import { FormProvider, useForm } from 'react-hook-form';
import { object, string } from 'prop-types';

import BaseForm from '../BaseForm';

function DynamicForm({ children, defaultValues, onSubmit, mode, ...rest }) {
  const form = useForm({ defaultValues, mode, shouldUnregister: true });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <BaseForm {...rest} onSubmit={form.handleSubmit(onSubmit)}>
        {children}
      </BaseForm>
    </FormProvider>
  );
}

DynamicForm.propTypes = {
  // We accept everything BaseForm does, we just do different stuff with it.
  ...BaseForm.propTypes,
  defaultValues: object,
  mode: string,
};

DynamicForm.defaultProps = {
  defaultValues: {},
  mode: 'onSubmit',
};

export default DynamicForm;
