import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import queryKeyFactory from '../queryKeyFactory';

function transformLogOutreach(logOutreach) {
  return {
    id: logOutreach.id,
    note: logOutreach.note?.text,
    createdAt: logOutreach.created_at,
    user: logOutreach.user_full_name,
    outcome: logOutreach.outcome[1],
  };
}

function transformResponse(data) {
  return data.map(transformLogOutreach);
}

async function fetchLogOutreach({ patientId }) {
  const response = await axios.get(`/api/patient/${patientId}/outreaches`);

  return response.data;
}

export default function useGetLogOutreach({ patientId }) {
  const params = { patientId };

  return useQuery(
    queryKeyFactory.logOutreach(params),
    () => fetchLogOutreach(params),
    {
      select: transformResponse,
    },
  );
}
