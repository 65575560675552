import { number, object, string } from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';

import concatSx from '@/utils/concatSx';
import { computeDifficulties } from '@/pages/Homepage/components/goalsWidget/utils';

import CircularProgressBar from './CircularProgressBar';
import useIsHistoricalData from './hooks/useIsHistoricalData';

function getProgressColor(percentCompleted, difficulties, isHistoricalData) {
  if (percentCompleted >= 100) {
    return 'colorPool.emerald.500';
  }
  if (isHistoricalData) {
    return 'dataViz.categorical.neutral500';
  }
  if (percentCompleted >= difficulties.easy) {
    return 'colorPool.emerald.500';
  }
  if (percentCompleted >= difficulties.medium) {
    return 'colorPool.yellow.200';
  }
  return 'colorPool.red.600';
}

export default function GoalProgressAndTitle({
  goalTitle,
  percentCompleted,
  progressSizePx,
  zIndex,
  sx,
}) {
  const difficulties = computeDifficulties(new Date());
  const isHistoricalData = useIsHistoricalData();
  return (
    <Stack spacing={1} alignItems="center" sx={concatSx({ zIndex }, sx)}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          display: 'flex',
          width: progressSizePx,
        }}
      >
        <CircularProgressBar
          size={progressSizePx}
          progressArray={[Math.min(percentCompleted, 100)]}
          indicatorColorArray={[
            getProgressColor(percentCompleted, difficulties, isHistoricalData),
          ]}
          trackColor="background.secondary"
          trackWidth={7.5}
        />
        <Typography
          component="div"
          variant="metric"
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          {`${percentCompleted}%`}
        </Typography>
      </Box>
      {Boolean(goalTitle) && (
        <Typography
          variant="bodyextralargebold"
          color="text.secondary"
          sx={{ width: 'max-content' }}
        >
          {goalTitle}
        </Typography>
      )}
    </Stack>
  );
}

GoalProgressAndTitle.propTypes = {
  goalTitle: string,
  percentCompleted: number.isRequired,
  zIndex: number,
  progressSizePx: number.isRequired,
  sx: object,
};

GoalProgressAndTitle.defaultProps = {
  goalTitle: undefined,
  zIndex: 0,
  sx: undefined,
};
