import { Popover, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import { actionTriggerShape } from '@/utils/transformFrontendCareGapAction';
import useGetUrl from '@/pages/PatientSummary/hooks/useGetUrl';
import BaseLinkButton from '@/components/BaseLinkButton';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

function ActionTrigger({
  'data-pendo-id': dataPendoId,
  actionId,
  trigger,
  color,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const { data, isInitialLoading } = useGetUrl(trigger.dataUrl, { enabled });
  const content = isInitialLoading ? 'Loading...' : data;

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget);
    // Enable the query only after the user opens the popover for the first time
    setEnabled(true);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const getV1PopoverContent = (htmlContent) => {
    return (
      <>
        {/* eslint-disable-next-line react/no-danger */}
        <PopoverDiv dangerouslySetInnerHTML={{ __html: htmlContent }} />
        <StyledButton aria-label="Close modal" onClick={handlePopoverClose}>
          <CloseIcon />
        </StyledButton>
      </>
    );
  };

  const getV2PopoverContent = (header, tableContent) => {
    const headerKeys = Object.keys(header);
    return (
      <TableContainer component={Paper} sx={{ maxHeight: 700 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headerKeys.map((headerKey) => (
                <TableCell key={`${actionId}-${headerKey}`}>
                  {header[headerKey]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(tableContent) &&
              tableContent.map((row, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={`${actionId}-${index}`}>
                    {headerKeys.map((headerKey) => (
                      <TableCell key={`${actionId}-${row.id}-${headerKey}`}>
                        {row[headerKey]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return trigger.dataUrl ? (
    <>
      <BaseLinkButton
        data-pendo-id={dataPendoId}
        onClick={handlePopoverOpen}
        sx={{ color, textAlign: 'left' }}
      >
        {trigger.text}
      </BaseLinkButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handlePopoverClose}
      >
        {content?.header
          ? getV2PopoverContent(content.header, content.content)
          : getV1PopoverContent(content)}
      </Popover>
    </>
  ) : (
    <Typography>{trigger.text}</Typography>
  );
}

export default ActionTrigger;

ActionTrigger.propTypes = {
  'data-pendo-id': PropTypes.string,
  actionId: PropTypes.number,
  trigger: actionTriggerShape,
  color: PropTypes.string,
};

ActionTrigger.defaultProps = {
  'data-pendo-id': undefined,
  actionId: undefined,
  trigger: undefined,
  color: 'text.link',
};

const PopoverDiv = styled.div`
  width: 800px;
  padding: 16px 0 32px;
  color: ${(p) => p.theme.palette.text.primary};
  & table th,
  .table td {
    border-top: 1px solid ${(p) => p.theme.palette.border.base};
    background-color: ${(p) => p.theme.palette.background.base};
  }
  & thead {
    color: ${(p) => p.theme.palette.text.label};
  }
`;

const StyledButton = styled.button`
  line-height: 0;
  position: absolute;
  right: 16px;
  top: 16px;

  color: ${(p) => p.theme.palette.icon.primary};

  :hover {
    color: ${(p) => p.theme.palette.icon.link};
  }
`;
