import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

export default function BaseTooltip({
  children,
  title,
  placement,
  verticalOffset,
}) {
  return (
    <Tooltip
      title={title}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'background.baseInverse',
            fontSize: '14px',
          },
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, verticalOffset],
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  );
}

BaseTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  placement: PropTypes.string,
  verticalOffset: PropTypes.number,
};
BaseTooltip.defaultProps = {
  title: '',
  placement: 'bottom-start',
  verticalOffset: -30,
};
