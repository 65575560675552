import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import Footer from '@/pages/PaymentInstructions/components/Footer';
import Agreements from '@/pages/PaymentInstructions/components/AgreementStep/Agreements';
import SignatureInput from '@/pages/PaymentInstructions/components/AgreementStep/SignatureInput';
import SimpleFormWithError from '@/pages/PaymentInstructions/components/SimpleFormWithError';

const ACCEPTED_BY = 'acceptedBy';

export default function AgreementsForm({
  error,
  isError,
  isLoadingSubmitAgreements,
  agreements,
  handleClickAgree,
  pendoId,
}) {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      [ACCEPTED_BY]: '',
    },
  });

  // We want to render the terms and conditions for payment first as it contains language binding the user to the agreements
  const sortedAgreements = [...agreements].sort((a) => {
    if (a.slug === 'terms_and_conditions_for_payment') {
      return -1;
    }
    return 1;
  });

  return (
    <SimpleFormWithError
      error={error}
      onSubmit={(formData) => handleSubmit(handleClickAgree)(formData)}
      isError={isError}
    >
      <Stack spacing={2} alignItems="center" mb={3}>
        <Agreements agreements={sortedAgreements} />
        <SignatureInput name={ACCEPTED_BY} control={control} />
      </Stack>
      <Footer
        buttonName="Agree"
        isLoading={isLoadingSubmitAgreements}
        isSubmitButtonDisabled={!isValid}
        data-pendo-id={pendoId}
      />
    </SimpleFormWithError>
  );
}

AgreementsForm.propTypes = {
  error: PropTypes.object,
  isError: PropTypes.bool,
  isLoadingSubmitAgreements: PropTypes.bool,
  agreements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  handleClickAgree: PropTypes.func,
  pendoId: PropTypes.string,
};

AgreementsForm.defaultProps = {
  error: '',
  isError: false,
  isLoadingSubmitAgreements: false,
  agreements: [],
  handleClickAgree: () => {},
  pendoId: '',
};
