import { useCallback } from 'react';

export default function usePendoId({ prefix = [], separator = '-' } = {}) {
  const prefixString = prefix.join(separator);
  return useCallback(
    (...segments) => {
      const segs = prefixString ? [prefixString, ...segments] : segments;
      return segs.join(separator);
    },
    [prefixString, separator],
  );
}
