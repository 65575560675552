import PropTypes, { node, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';

/**
 * Please see ModalInformationalText.stories.jsx for proper usage
 * of nested children parameter.
 *
 * @param content
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function ModalInformationalText({ content, children, sx }) {
  return (
    <StyledBox sx={sx}>
      <Box
        sx={{
          p: 1,
          color: 'status.infoForeground',
        }}
      >
        <StyledInfoIcon aria-label="Modal help" tabIndex="0" />
        <Typography sx={{ fontSize: 'inherit' }}>
          {content} {children}
        </Typography>
      </Box>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  border: 1px solid;
  color: ${(p) => p.theme.palette.status.infoForeground};
  background-color: ${(p) => p.theme.palette.status.infoBackground};
  border-color: ${(p) => p.theme.palette.status.infoForeground};
  border-radius: 8px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  height: 1em;
  width: 1em;
  color: ${(p) => p.theme.palette.status.infoForeground};
  margin-bottom: ${(p) => p.theme.spacing(1)};
  margin-right: ${(p) => p.theme.spacing(1)};
  margin-top: ${(p) => p.theme.spacing(0.25)};
  font-size: inherit;
  float: left;
  vertical-align: center;
`;

ModalInformationalText.propTypes = {
  content: string,
  children: node,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ModalInformationalText.defaultProps = {
  content: undefined,
  children: undefined,
  sx: undefined,
};
