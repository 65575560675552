import {
  accordionDetailsClasses,
  accordionSummaryClasses,
  alertClasses,
  alertTitleClasses,
  buttonBaseClasses,
  buttonClasses,
  circularProgressClasses,
  dialogClasses,
  inputClasses,
  svgIconClasses,
} from '@mui/material';
import { loadingButtonClasses } from '@mui/lab';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/chevronDown.svg';
import { ReactComponent as RadioDefaultIcon } from '@/assets/icons/radioDefault.svg';
import { ReactComponent as RadioCheckedIcon } from '@/assets/icons/radioChecked.svg';

import palette from './palette';
import typography, { LINE_HEIGHT } from './typography';

import '@mui/lab/themeAugmentation';

/**
 * These selectors use MUI's global state classes to select a given state.
 *
 * Docs: https://mui.com/customization/how-to-customize/#heading-state-classes
 */
const stateClasses = {
  active: 'Mui-active',
  checked: 'Mui-checked',
  completed: 'Mui-completed',
  disabled: 'Mui-disabled',
  error: 'Mui-error',
  expanded: 'Mui-expanded',
  focusVisible: 'Mui-focusVisible', // https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
  focused: 'Mui-focused',
  required: 'Mui-required',
  selected: 'Mui-selected',
};

const stateSelectors = {
  active: '&.Mui-active',
  checked: '&.Mui-checked',
  completed: '&.Mui-completed',
  disabled: '&.Mui-disabled',
  error: '&.Mui-error',
  expanded: '&.Mui-expanded',
  focusVisible: '&.Mui-focusVisible',
  focused: '&.Mui-focused',
  required: '&.Mui-required',
  selected: '&.Mui-selected',
};

const focusOutline = {
  [`:focus, :focus-within, ${stateSelectors.focused}, ${stateSelectors.focusVisible}`]:
    {
      boxShadow: `0 0 0 2px ${palette.focus.border}`,
      borderRadius: '8px',
      outline: 'none !important',
    },
};

const muiButtonFocus = {
  backgroundColor: palette.background.primary,
  border: `2px solid ${palette.background.base}`,
  boxShadow: `0 0 0 2px ${palette.background.primary}`,
  color: palette.text.primaryInverse,
};

const muiButtonActive = {
  backgroundColor: palette.background.primaryDarkest,
  border: `2px solid ${palette.background.base}`,
  boxShadow: `0 0 0 2px ${palette.background.primary}`,
  color: palette.text.primaryInverse,
};

export const inputStyles = {
  borderRadius: '4px',
  backgroundColor: palette.background.base,
  border: `2px solid ${palette.background.transparent}`,
  boxShadow: `0 0 0 1px ${palette.border.disabled}`,
  padding: '4px 8px',
};
export const inputFocus = {
  border: `2px solid ${palette.border.primaryActive}`,
  boxShadow: `0 0 0 3.2px ${palette.focus.shadow}`,
  outline: 'none',
};
export const inputHoverStyles = {
  ':hover': {
    boxShadow: `0 0 0 1px ${palette.border.input}`,
  },
};

export const inputDisabledStyles = {
  boxShadow: `0 0 0 1px ${palette.border.disabled}`,
  backgroundColor: palette.background.base,
  border: `2px solid ${palette.background.transparent}`,
};

/**
 * Component Customization
 *
 * @type {import('@mui/material').Components}
 *
 * Documentation: https://mui.com/customization/theme-components/
 */
const components = {
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        border: '1px solid',
        fontSize: '0.875rem',
        padding: '7px',

        [`.${buttonBaseClasses.root} svg`]: {
          fontSize: '28px',
        },
      },
      action: {
        marginRight: 0,
        padding: '0 0 0 20px',
      },
      message: {
        padding: 0,
      },
      icon: {
        padding: 0,
        marginRight: '8px',
        fontSize: '24px',
      },
      standardSuccess: {
        backgroundColor: palette.status.successBackground,
        borderColor: palette.status.successForeground,
        color: palette.status.successForeground,

        [`.${svgIconClasses.root}`]: {
          color: palette.status.successForeground,
        },

        '& a': {
          color: palette.status.successForeground,
        },
      },

      standardWarning: {
        backgroundColor: palette.status.warningBackground,
        borderColor: palette.status.warningForeground,
        color: palette.status.warningForeground,

        [`.${svgIconClasses.root}`]: {
          color: palette.status.warningForeground,
        },

        '& a': {
          color: palette.status.warningForeground,
        },
      },

      standardError: {
        backgroundColor: palette.status.errorBackground,
        borderColor: palette.status.errorForeground,
        color: palette.status.errorForeground,

        [`.${svgIconClasses.root}`]: {
          color: palette.status.errorForeground,
        },

        '& a': {
          color: palette.status.errorForeground,
        },
      },

      standardInfo: {
        backgroundColor: palette.status.infoBackground,
        borderColor: palette.status.infoForeground,
        color: palette.status.infoForeground,

        [`.${svgIconClasses.root}`]: {
          color: palette.status.infoForeground,
        },

        '& a': {
          color: palette.status.infoForeground,
        },
      },
    },
  },

  MuiAlertTitle: {
    styleOverrides: {
      root: {
        margin: 0,
        fontWeight: 800,
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
      },
    },
  },

  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
      elevation: 0,
      TransitionProps: { unmountOnExit: true }, // Improve performance by rendering content only when expanded.
    },
    variants: [
      {
        props: { variant: 'disclosure' },
        style: {
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },

          [`.${accordionSummaryClasses.root}`]: {
            display: 'inline-flex',
            minHeight: 'auto',
            padding: 0,

            [stateSelectors.focusVisible]: {
              backgroundColor: 'transparent',
              borderRadius: '2px',
              ...focusOutline,
            },
          },
          [`.${accordionSummaryClasses.content}`]: {
            margin: 0,
            flexGrow: 0,
          },
          [`.${accordionDetailsClasses.root}`]: {
            padding: 0,
          },
          [`.${accordionSummaryClasses.expandIconWrapper}`]: {
            marginLeft: '8px',
          },
        },
      },
    ],
  },

  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <ChevronDownIcon width={24} height={24} />,
    },
  },

  MuiAppBar: {
    defaultProps: {
      elevation: 3,
    },
  },

  MuiAutocomplete: {
    styleOverrides: {
      root: {
        'label + &': {
          marginTop: '4px',
        },

        // Override the Input padding styles added by MuiAutocomplete
        [`&& .${inputClasses.root} .${inputClasses.input}`]: {
          padding: 0,
        },
        // Override the Input padding styles added by MuiAutocomplete
        [`&& .${inputClasses.root}`]: {
          paddingBottom: '3px',
        },
      },
      endAdornment: {
        right: '8px',
      },
    },
    variants: [
      {
        props: { size: 'large' },
        style: {
          [`&& .${inputClasses.root}`]: {
            paddingBottom: '7px',
          },
        },
      },
    ],
  },

  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
      variant: 'primary',
    },
    variants: [
      {
        props: { size: 'small', icon: true },
        style: {
          padding: '7px',
          width: '32px',
        },
      },
      {
        props: { size: 'medium', icon: true },
        style: {
          padding: '10px',
          width: '40px',
        },
      },
      {
        props: { size: 'large', icon: true },
        style: {
          padding: '13px',
          width: '48px',
        },
      },
    ],
    styleOverrides: {
      root: ({ ownerState }) => ({
        boxSizing: 'border-box',
        transition: 'none',
        textTransform: 'none',
        borderRadius: '8px',
        fontWeight: 400,
        minWidth: 'auto',

        [stateSelectors.disabled]: {
          color: palette.border.disabled,
        },

        ...(ownerState.size === 'small' && {
          padding: '9px 14px',
          height: '32px',
          fontSize: '0.875rem',
          lineHeight: '0.875rem',

          [`.${buttonClasses.iconSizeSmall} svg, .${svgIconClasses.root}`]: {
            fontSize: '1.050rem',
          },
        }),
        ...(ownerState.size === 'medium' && {
          padding: '12px 14px',
          height: '40px',
          fontSize: '1rem',
          lineHeight: '1rem',

          [`.${buttonClasses.iconSizeMedium} svg, .${svgIconClasses.root}`]: {
            fontSize: '1.200rem',
          },
        }),
        ...(ownerState.size === 'large' && {
          padding: '15px 14px',
          height: '48px',
          fontSize: '1.125rem',
          lineHeight: '1.125rem',

          [`.${buttonClasses.iconSizeLarge} svg, .${svgIconClasses.root}`]: {
            fontSize: '1.350rem',
          },
        }),
        ...(ownerState.size === 'xlarge' && {
          padding: '15px 14px',
          height: '56px',
          fontSize: '1.25rem',
          lineHeight: '1.25rem',

          [`.${buttonClasses.iconSizeLarge} svg, .${svgIconClasses.root}`]: {
            fontSize: '1.25rem',
          },
        }),
      }),

      primary: {
        border: `2px solid ${palette.background.primary}`,
        backgroundColor: palette.background.primary,
        color: palette.text.primaryInverse,

        ':hover': {
          backgroundColor: palette.background.primaryDarker,
          border: `2px solid ${palette.background.primaryDarker}`,
        },
        ':focus': { ...muiButtonFocus },
        ':active': { ...muiButtonActive },

        [stateSelectors.disabled]: {
          backgroundColor: palette.border.disabled,
          color: palette.text.primaryInverse,
          border: `2px solid ${palette.border.disabled}`,
        },
      },

      secondary: ({ ownerState }) => ({
        backgroundColor: palette.background.transparent,
        boxShadow: `0 0 0 1px ${palette.background.primary}`,
        border: `2px solid ${palette.background.transparent}`,
        color: palette.text.link,

        ...(ownerState.size === 'medium' && {
          height: '38px',
        }),
        ...(ownerState.size === 'large' && {
          height: '46px',
        }),

        ':hover': {
          backgroundColor: palette.background.primary,
          color: palette.text.primaryInverse,
        },
        ':focus': { ...muiButtonFocus },
        ':active': { ...muiButtonActive },

        [stateSelectors.disabled]: {
          boxShadow: `0 0 0 1px ${palette.border.disabled}`,
        },
      }),

      tertiary: {
        color: palette.text.link,
        border: `2px solid ${palette.background.transparent}`,

        ':hover': {
          backgroundColor: palette.background.primaryLightest,
          color: palette.background.primaryDarker,
        },
        ':focus': {
          ...muiButtonFocus,
          backgroundColor: palette.background.primaryLighter,
          color: palette.text.link,
        },
        ':active': {
          ...muiButtonActive,
          backgroundColor: palette.background.primaryLighter,
          color: palette.text.link,
        },
      },
    },
  },

  MuiLoadingButton: {
    defaultProps: {
      loadingPosition: 'start',
    },

    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.loading === true && {
          ...(ownerState.size === 'small' && {
            paddingLeft: '35px',

            [`.${circularProgressClasses.root}`]: {
              width: '10px!important',
              height: '10px!important',
            },

            [`.${loadingButtonClasses.loadingIndicator}`]: {
              left: '13px',
            },
          }),

          ...(ownerState.size === 'medium' && {
            paddingLeft: '40px',

            [`.${circularProgressClasses.root}`]: {
              width: '12px!important',
              height: '12px!important',
            },

            [`.${loadingButtonClasses.loadingIndicator}`]: {
              left: '15px',
            },
          }),

          ...(ownerState.size === 'large' && {
            paddingLeft: '45px',

            [`.${circularProgressClasses.root}`]: {
              width: '14px!important',
              height: '14px!important',
            },

            [`.${loadingButtonClasses.loadingIndicator}`]: {
              left: '17px',
            },
          }),
        }),
      }),
    },
  },

  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        '& [role="presentation"]': {
          overflow: 'visible', // Prevent clipping of the dropdown arrow that opens up the Year Picker.
        },
      },
    },
  },

  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
      },
    },
  },

  MuiChip: {
    variants: [
      {
        props: { variant: 'call-to-action' },
        style: {
          backgroundColor: palette.status.warningBackground,
          borderRadius: 8,
          color: palette.status.warningForeground,
          fontSize: '0.875rem',
          textTransform: 'uppercase',

          '& .MuiChip-label': {
            padding: '4px 8px',
          },
        },
      },
    ],
  },

  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
    variants: [
      {
        props: { disabled: true },
        style: {
          '&&& svg': {
            color: palette.disabled.disabled,
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        marginRight: '6px',
        padding: '4px 0',
        width: '18px',
        height: '24px',
      },
    },
  },

  MuiScopedCssBaseline: {
    styleOverrides: {
      root: {
        lineHeight: LINE_HEIGHT,
      },
    },
  },

  MuiCssBaseline: {
    styleOverrides: {
      body: {
        lineHeight: LINE_HEIGHT,
      },
      label: {
        margin: 0, // override spacing added by bootstrap within app.scss
      },
      ul: {
        paddingInlineStart: '20px',
      },
      p: {
        margin: 0,
      },
      button: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        margin: 0,
        padding: 0,
        borderRadius: '2px',
        ...focusOutline,
      },

      // Fixes a bug where tooltips inside modals are being rendered behind the modal.
      [`.${dialogClasses.root} + [role="tooltip"]`]: {
        zIndex: 1301, // modals have a z-index of 1300
      },
    },
  },

  MuiDrawer: {
    defaultProps: { elevation: 3 },
  },

  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    },
    styleOverrides: {
      root: {
        [`.${alertClasses.root}`]: {
          width: '430px',
          borderRadius: '4px',
          borderRight: 'none',
          borderBottom: 'none',
          borderTop: 'none',
          borderLeft: '4px solid',
          padding: '16px 24px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)',
        },
        [`.${alertClasses.action}`]: {
          padding: '0 0 0 16px',
        },
        [`.${alertTitleClasses.root}`]: {
          marginBottom: '4px',
        },
      },
      anchorOriginBottomRight: {
        bottom: '16px!important',
        right: '16px!important',
      },
    },
  },

  MuiSnackbarContent: {
    defaultProps: { elevation: 3 },
  },

  MuiCircularProgress: {
    defaultProps: {
      disableShrink: true,
    },
  },

  MuiDialog: {
    defaultProps: { scroll: 'body', PaperProps: { elevation: 2 } },
    styleOverrides: {
      root: {
        // We have to specify the line height here, because modals get rendered in a portal
        // and won't get the correct line-height if ScopedCssBaseline is being used.
        lineHeight: LINE_HEIGHT,
      },
      paper: {
        margin: '128px 32px',
        maxWidth: '680px',
        borderRadius: '16px',
      },
    },
  },

  MuiDialogActions: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.default,
        boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)',
        justifyContent: 'space-between',
        padding: '16px 24px',
      },
    },
  },

  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '24px',
      },
    },
  },

  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: '24px 24px 16px',
      },
    },
  },

  MuiFormHelperText: {
    variants: [
      {
        props: { color: 'success' },
        style: {
          color: palette.status.successForeground,
        },
      },
    ],

    styleOverrides: {
      root: {
        ...typography.helper,
        color: palette.text.secondary,
        margin: 0,
        [stateSelectors.disabled]: {
          color: palette.status.placeholder,
        },
      },
    },
  },

  MuiFormControlLabel: {
    variants: [
      {
        props: { $variant: 'checkbox', $error: false, $highEmphasis: false },
        style: {
          '& svg': {
            color: palette.icon.secondary,
          },
          ':hover': {
            [`.${stateClasses.checked} svg`]: {
              color: palette.background.primaryDarker,
            },
            '& svg': { color: palette.icon.label },
          },
          ':active': {
            [`.${stateClasses.checked} svg`]: {
              color: palette.background.primaryDarkest,
            },
            '& svg': { color: palette.background.primaryDarkest },
          },
          [`.${stateClasses.checked}`]: {
            '& svg': {
              color: palette.background.primary,
            },
          },
        },
      },
      {
        props: { $variant: 'checkbox', $error: true, $highEmphasis: false },
        style: {
          svg: {
            color: palette.status.errorForeground,
          },
          ':active': {
            '& svg': { color: palette.status.errorForeground },
          },
          [`.${stateClasses.checked}`]: {
            '& svg': { color: palette.status.errorForeground },
          },
        },
      },
      {
        props: { $variant: 'checkbox', $error: false, $highEmphasis: true },
        style: {
          svg: {
            color: palette.background.primary,
          },
          ':hover': {
            [`.${stateClasses.checked} svg`]: {
              color: palette.background.primaryDarker,
            },
            '& svg': {
              color: palette.background.primaryDarker,
            },
          },
          ':active': {
            [`.${stateClasses.checked} svg`]: {
              color: palette.background.primaryDarkest,
            },
            '& svg': { color: palette.background.primaryDarkest },
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'flex-start',
        ':not(:first-of-type)': {
          marginTop: '8px',
        },
      },
      label: {
        color: palette.icon.secondary,
        [stateSelectors.disabled]: { color: palette.disabled.disabled },
      },
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      root: {
        ...typography.label,
        paddingBottom: '1px',
        color: palette.text.label,
        [stateSelectors.error]: {
          color: palette.text.label,
        },
        [stateSelectors.focused]: {
          color: palette.text.label,
        },
        [stateSelectors.disabled]: {
          color: palette.text.placeholder,
        },
        [stateSelectors.error]: {
          color: palette.status.errorForeground,
        },

        // target the spacing between a textfield and the label
        [`& + .${inputClasses.root}`]: {
          marginTop: '1px',
        },
        // target the spacing between a textarea and the label
        [`& + textarea`]: {
          marginTop: '1px',
        },
      },
    },
  },

  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: 0,
        borderRadius: '1px',
      },
    },
  },

  MuiInputAdornment: {
    styleOverrides: {
      root: {
        position: 'relative',
        [`.${svgIconClasses.root}`]: {
          fontSize: '1.048rem',
          color: palette.icon.secondary,
        },
      },
      positionStart: {
        left: '-4px',
      },
      positionEnd: {
        right: '-4px',
      },
    },
  },

  MuiInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        backgroundColor: palette.background.base,
        height: '30px',
        width: '270px',
        ...inputStyles,
        ...inputHoverStyles,

        'label + &': { marginTop: 0 },
        ':active': inputFocus,

        [stateSelectors.focused]: inputFocus,
        [stateSelectors.disabled]: inputDisabledStyles,
        [stateSelectors.error]: {
          boxShadow: `0 0 0 1px ${palette.status.errorForeground}`,
        },

        ...(ownerState.color === 'success' && {
          boxShadow: `0 0 0 1px ${palette.status.successForeground}`,
        }),
      }),
      input: {
        height: 'auto',
        padding: '0',
        color: palette.text.primary,
        ...typography.bodylarge,
        '&::placeholder': {
          color: palette.text.placeholder,
        },
      },
      sizeSmall: {
        width: '101px',
      },
      fullWidth: {
        width: '100%',
      },
    },
  },

  MuiLink: {
    styleOverrides: {
      root: {
        color: palette.text.link,
        textDecorationColor: 'inherit',
        textDecorationThickness: '1px',
        textUnderlineOffset: '1px',
        '&:hover': {
          color: palette.background.primaryDarker,
          textDecoration: 'underline !important', // override `text-decoration: none;` from app.scss
        },
      },
    },
    variants: [
      // Styles for our <BaseLinkButton> component
      {
        props: { component: 'button' },
        style: { borderRadius: '2px', ...focusOutline },
      },
    ],
  },

  MuiListItemButton: {
    defaultProps: {
      disableRipple: true,
    },
  },

  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&&': {
          [`:hover, ${stateSelectors.focused}, ${stateSelectors.focusVisible}`]:
            {
              backgroundColor: `${palette.background.primaryLightest}`,
            },
          [`${stateSelectors.selected}:hover, ${stateSelectors.selected}`]: {
            backgroundColor: `${palette.background.primaryLighter}`,
            color: palette.text.link,
          },
        },
      },
    },
  },

  MuiPaper: {
    defaultProps: {
      elevation: 2, // "regular" shadow
    },
    styleOverrides: {
      root: {
        borderRadius: '8px',
      },
    },
  },

  MuiPickersDay: {
    styleOverrides: {
      root: {
        boxShadow: 'none !important', // Disable our custom focusOutline because DatePicker has a built-in focus.
      },
    },
  },

  MuiPopover: {
    defaultProps: { elevation: 3 },
  },

  MuiFormGroup: {
    styleOverrides: {
      root: {
        marginTop: '4px',
      },
    },
  },

  MuiRadio: {
    defaultProps: {
      disableRipple: true,
      icon: <RadioDefaultIcon />,
      checkedIcon: <RadioCheckedIcon />,
    },
    styleOverrides: {
      root: {
        marginRight: '8px',
        padding: 0,
        color: palette.icon.secondary,

        [stateSelectors.checked]: {
          color: palette.icon.secondary,
        },

        [`.${stateClasses.error} &`]: {
          color: palette.status.errorForeground,
        },
      },
    },
  },

  MuiSelect: {
    styleOverrides: {
      select: {
        // The default padding has ridiculously high specificity, so use this '&&&' trick to increase the specificity.
        '&&&': {
          paddingRight: '30px',
        },
      },
    },
  },

  MuiStep: {
    styleOverrides: {
      root: {
        padding: 0,

        '&:not(:first-of-type)': {
          marginLeft: '16px',
        },
      },
    },
  },

  MuiStepConnector: {
    styleOverrides: {
      root: {
        display: 'none',
      },
    },
  },

  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: palette.stellar.primary,
        opacity: 0.5,

        [stateSelectors.active]: {
          opacity: 1,
        },
      },
      text: {
        fontWeight: 700,
        fontSize: '1rem',
        transform: 'translateY(1px)', // re-position because of the modified fontSize
      },
    },
  },

  MuiStepLabel: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.darker,
        borderRadius: '16px',
        paddingRight: '16px',

        [stateSelectors.disabled]: {
          backgroundColor: 'transparent',
        },
      },
      label: {
        [stateSelectors.active]: {
          ...typography.bodybold,
          lineHeight: '24px',
        },
      },
    },
  },

  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: palette.text.primary,
          backgroundColor: palette.background.primaryLightest,
          fontWeight: 900,
        },
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
        lineHeight: 1.5,
        padding: '8px 16px',
      },
      paddingNone: {
        padding: '0px',
      },
      head: {
        fontSize: '0.875rem',
        color: palette.text.secondary,
      },
    },
  },

  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },

    styleOverrides: {
      tooltip: {
        padding: '4px',
        backgroundColor: palette.background.baseInverse,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)',
        ...typography.bodymedium,
      },
      arrow: {
        color: palette.icon.primary,
      },
      tooltipPlacementTop: {
        '&&&': {
          marginBottom: '12px',
        },
      },
      tooltipPlacementBottom: {
        '&&&': {
          marginTop: '12px',
        },
      },
      tooltipPlacementLeft: {
        '&&&': {
          marginRight: '12px',
        },
      },
      tooltipPlacementRight: {
        '&&&': {
          marginLeft: '12px',
        },
      },
    },
  },

  MuiTypography: {
    defaultProps: {
      variant: 'body',
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        body: 'p',
        bodybold: 'span',
        bodysmall: 'p',
        bodysmallbold: 'span',
        bodymedium: 'p',
        bodymediumbold: 'span',
        bodylarge: 'p',
        bodylargebold: 'span',
        bodyextralarge: 'p',
        bodyextralargebold: 'span',
        bodydeemphasized: 'p',
        inherit: 'p',
        subtext: 'p',
        label: 'p',
      },
    },
  },
};

export default components;
