import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
} from '@mui/material';
import useId from '@mui/utils/useId';

import BaseRadioButton from './BaseRadioButton';

/**
 * BaseRadioGroup renders a group of radio buttons and handles all the form and accessibility logic for you.
 *
 * `options` is an array of props that will be passed to each BaseRadioButton component,
 * but the only required fields in each object are `label` and `value`.
 *
 * Documentation: https://mui.com/components/radio-buttons
 */
export default function BaseRadioGroup({
  className,
  defaultValue,
  error,
  helperText,
  label,
  name,
  onChange,
  onBlur,
  sx,
  value,
  options,
  row,
  'data-pendo-id': dataPendoId,

  // ⬇ themeV2 props only ⬇
  relaxed,
  highEmphasis,
}) {
  const labelId = useId();

  return (
    <FormControl
      className={className}
      error={error}
      sx={sx}
      data-pendo-id={dataPendoId}
    >
      {label && <FormLabel id={labelId}>{label}</FormLabel>}
      <RadioGroup
        row={row}
        aria-labelledby={labelId}
        // We must manually add Mui-error until we update to the latest version of MUI
        // Fix: https://github.com/mui/material-ui/pull/30656
        className={error ? 'Mui-error' : undefined}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      >
        {options.map((option, index) => (
          <BaseRadioButton
            checked={value ? value === option.value : undefined}
            data-pendo-id={`${dataPendoId}-button`}
            highEmphasis={highEmphasis}
            key={option.value}
            sx={{
              alignItems: 'flex-start',
              ml: index > 0 && row ? 2 : 0,
              mt: row ? '0 !important' : undefined,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...option}
            relaxed={relaxed}
          />
        ))}
      </RadioGroup>

      {helperText && (
        <FormHelperText sx={{ mt: 0.5 }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

BaseRadioGroup.propTypes = {
  className: PropTypes.string,
  'data-pendo-id': PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  highEmphasis: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  // An array of the props to pass to each BaseRadioButton component
  options: PropTypes.arrayOf(PropTypes.shape(BaseRadioButton.propTypes))
    .isRequired,
  relaxed: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  row: PropTypes.bool,
};

BaseRadioGroup.defaultProps = {
  className: undefined,
  'data-pendo-id': undefined,
  defaultValue: undefined,
  error: false,
  helperText: undefined,
  highEmphasis: false,
  label: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  relaxed: false,
  sx: undefined,
  value: undefined,
  row: undefined,
};
