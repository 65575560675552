import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { bool, func, number, object } from 'prop-types';

import { reactQueryStatusValues } from '@/utils/shapes';
import BaseForm from '@/components/BaseForm';

import useDiagnosisActionStateManager from '../../hooks/useDiagnosisActionStateManager';
import useConditionsForDisplay from '../../hooks/useConditionsForDisplay';
import DiagnosisActionFormControls from '../DiagnosisActionFormControls';
import DiagnosisProviderSelect from '../DiagnosisProviderSelect';

import CollapsedDiagnosisActionForm from './CollapsedDiagnosisActionForm';

export default function DiagnosisActionFormShort({
  defaultProviderChoiceId,
  diagnosisAction,
  narrow,
  onCancel,
  onExpandLongForm,
  onExpandShortForm,
  onSaveDiagnosisAction,
  saveStatus,
  isSubmitDisabled,
}) {
  const [selectedProviderId, setSelectedProviderId] = useState(
    defaultProviderChoiceId,
  );

  const { state, selectDiagnosis } = useDiagnosisActionStateManager();
  const { pastDiagnoses } = useConditionsForDisplay(diagnosisAction);

  const handleSubmit = () => {
    onSaveDiagnosisAction({
      selectedProviderId,
      diagnosisAction,
      state,
    });
  };

  const firstDiagnosis = pastDiagnoses[0];

  useEffect(() => {
    // Automatically select the first historic diagnosis:
    selectDiagnosis(firstDiagnosis);
    // Note that we only want to run the diagnosis selection once when the
    // component mounts and never again. We do not expect firstDiagnosis
    // to ever change, so that should be fine.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CollapsedDiagnosisActionForm
        firstDiagnosis={firstDiagnosis}
        narrow={narrow}
        onExpandLongForm={onExpandLongForm}
        onExpandShortForm={onExpandShortForm}
      />
      <BaseForm onSubmit={handleSubmit}>
        <Stack px={1} gap={1}>
          <Typography variant="bodybold">
            {firstDiagnosis.icd} addressed in this visit
          </Typography>
          <DiagnosisProviderSelect
            selectedProviderId={selectedProviderId}
            loading={saveStatus === 'loading'}
            onChangeSelectedProviderId={setSelectedProviderId}
          />
          <DiagnosisActionFormControls
            onCancel={onCancel}
            loading={saveStatus === 'loading'}
            disabled={!selectedProviderId || isSubmitDisabled}
          />
        </Stack>
      </BaseForm>
    </>
  );
}

DiagnosisActionFormShort.propTypes = {
  defaultProviderChoiceId: number,
  diagnosisAction: object.isRequired,
  narrow: bool.isRequired,
  onCancel: func.isRequired,
  onExpandLongForm: func.isRequired,
  onExpandShortForm: func.isRequired,
  onSaveDiagnosisAction: func.isRequired,
  saveStatus: reactQueryStatusValues.isRequired,
  isSubmitDisabled: bool.isRequired,
};

DiagnosisActionFormShort.defaultProps = {
  defaultProviderChoiceId: undefined,
};
