import { actionShape } from '@/utils/transformFrontendAction';
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';

export default function PYMSAbsoluteFailDate({ action }) {
  const { data: contextualInfo, isLoading } = useGetContextualInfo(action);
  return isLoading ? (
    <FieldSkeleton />
  ) : (
    <span style={{ whiteSpace: 'nowrap' }}>
      {contextualInfo.absoluteFailDate}
    </span>
  );
}

PYMSAbsoluteFailDate.propTypes = {
  action: actionShape.isRequired,
};
