import { Box, FormLabel } from '@mui/material';
import PropTypes from 'prop-types';
import useId from '@mui/utils/useId';

import RhfInput from '@/components/reactHookFormComponents/RhfInput';
import FormErrorMessage from '@/components/FormErrorMessage';

function ModalTextField({
  label,
  defaultValue,
  errorMsg,
  actionId,
  disabled,
  BoxSx,
  InputSx,
  name,
  control,
  errors,
}) {
  const idPrefix = useId(actionId);

  return (
    <Box
      sx={{
        display: 'flex',
        mt: 1,
        justifyContent: 'space-between',
        ...BoxSx,
      }}
    >
      <FormLabel htmlFor={`${idPrefix}_${name}`} sx={{ mr: 1, mt: 1 }}>
        {label}
      </FormLabel>
      <div>
        <RhfInput
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={
            errorMsg
              ? {
                  required: errorMsg,
                }
              : {}
          }
          disabled={disabled}
          id={`${idPrefix}_${name}`}
          type="text"
          sx={InputSx}
        />
        <FormErrorMessage name={name} errors={errors} />
      </div>
    </Box>
  );
}

export default ModalTextField;

ModalTextField.propTypes = {
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  actionId: PropTypes.number,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  BoxSx: PropTypes.object,
  InputSx: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

ModalTextField.defaultProps = {
  actionId: undefined,
  disabled: false,
  defaultValue: '',
  BoxSx: undefined,
  InputSx: { width: 200 },
  errorMsg: undefined,
};
