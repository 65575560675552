export type Banner = {
  header: string;
  body: string;
  iconUrl: string;
};

type APIBanner = {
  header: string;
  body: string;
  icon_url: string;
};

export default function transformFrontendBanner(banner: APIBanner): Banner {
  return {
    header: banner.header,
    body: banner.body,
    iconUrl: banner.icon_url,
  };
}
