import { format, parseISO } from 'date-fns';

/**
 * Takes any date string and returns it in MM/DD/YYYY format
 * @param {string} date
 */
function formatDate(date: string): string {
  if (!date) return '';

  // Hack: add a time with no TZ to parse as local time
  // otherwise it's parsed as T00:00:00Z and returns as yesterday
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#using_date.parse
  const dateObj = new Date(Date.parse(`${date}T00:00:00`));
  return format(dateObj, 'MM/dd/yyyy');
}

/**
 * Takes a date object or date in YYYY-MM-DD format and
 * returns it in MM/DD/YYYY format while ignoring timezone
 * @param {string|Date} date
 * @param formatStr
 */
function formatDateIgnoreTimeZone(
  date: string | Date,
  formatStr = 'MM/dd/yyyy',
) {
  if (!date) return '';

  let dateObj: Date;
  if (typeof date === 'string') {
    dateObj = parseISO(date);
  } else {
    dateObj = date;
  }

  return format(dateObj, formatStr);
}

/**
 * Formats an ISO datetime string (YYYY-MM-DDTHH:mm:ss.sssZ) or an ISO date string(YYYY-MM-DD) to the specified format.
 * @param {string} input
 * @param {string} formatStr
 */
function formatISODateIgnoreTimeZone(input: string, formatStr = 'MM-dd-yyyy') {
  if (!input) return '';
  // Remove the time and time zone from the ISO date string
  const dateStringWithoutTimeZone = input.split('T')[0];
  const date = parseISO(dateStringWithoutTimeZone!);
  return format(date, formatStr);
}

export { formatDate, formatDateIgnoreTimeZone, formatISODateIgnoreTimeZone };
