import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableContainer from '@mui/material/TableContainer';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import { Button, useTheme } from '@mui/material';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HiddenIcon from '@mui/icons-material/VisibilityOff';

import { challengeShape } from '@ot/pages/Challenges/ChallengesView/hooks/useGetChallenges';

function isChallengeNotEditable(type, status, endDate) {
  if (!endDate) return false;

  if (type === 'custom') {
    return !(status === 'in_progress' || status === 'pending_review');
  }

  let now = new Date().toLocaleDateString();
  let parts = now.split('/');
  now = new Date(parts[2], parts[0] - 1, parts[1]);

  parts = endDate.split('-');
  const endDateObj = new Date(parts[0], parts[1] - 1, parts[2]);

  return (
    status === 'completed' || (status === 'in_progress' && now > endDateObj)
  );
}

function ChallengesTable({
  challenges,
  selectable,
  selectedIds,
  onSelect,
  onToggleSelectAll,
  ariaLabel,
  onClickChallenge,
  showApprovalDate,
}) {
  const theme = useTheme();
  const challengesCountMessage =
    challenges.length === 1
      ? `${challenges.length} Challenge`
      : `${challenges.length} Challenges`;
  return (
    <div>
      <ChallengeCount>{challengesCountMessage}</ChallengeCount>
      <TableContainer
        sx={{
          height: '600px',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: theme.palette.border.base,
        }}
      >
        <Table aria-label={ariaLabel} stickyHeader>
          <TableHead>
            <TableRow>
              {selectable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedIds.length > 0 &&
                      selectedIds.length < challenges.length
                    }
                    checked={
                      challenges.length > 0 &&
                      selectedIds.length === challenges.length
                    }
                    onChange={onToggleSelectAll}
                  />
                </TableCell>
              )}
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              {showApprovalDate && <TableCell>Approval Date</TableCell>}
              <TableCell>Progress</TableCell>
              <TableCell>Days Remaining</TableCell>
              <TableCell>Medical Group</TableCell>
              <TableCell>Office</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Condition Template</TableCell>
              <TableCell>Parent Challenge ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(challenges) &&
              challenges.map((challenge) => {
                const {
                  id,
                  name,
                  type,
                  status,
                  progress,
                  goal,
                  mgName,
                  officeName,
                  mgTin,
                  startDate,
                  endDate,
                  templateName,
                  remainingDays,
                  userName,
                  approvalDate,
                  hidden,
                  parentChallengeId,
                } = challenge;
                return (
                  <TableRow key={id}>
                    {selectable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedIds.indexOf(id) > -1}
                          onChange={(event) => onSelect(event, id)}
                          disabled={isChallengeNotEditable(
                            type,
                            status,
                            endDate,
                          )}
                        />
                      </TableCell>
                    )}
                    <TableCell>{id}</TableCell>
                    <TableCell>
                      <Button
                        variant="text"
                        onClick={() => onClickChallenge(challenge)}
                        data-pendo-id="challenge-side-panel-details-button"
                      >
                        {`${name} `}{' '}
                        {hidden && (
                          <StyledHiddenIcon titleAccess="This Challenge is hidden" />
                        )}
                        {status === 'completed' && (
                          <StyledCheckCircleIcon titleAccess="This Challenge is completed" />
                        )}
                      </Button>
                    </TableCell>
                    <TableCell>{type}</TableCell>
                    <TableCell>{status}</TableCell>
                    {showApprovalDate && <TableCell>{approvalDate}</TableCell>}
                    {type === 'custom' ? (
                      <TableCell>N/A</TableCell>
                    ) : (
                      <TableCell>
                        {progress} / {goal}
                      </TableCell>
                    )}
                    <TableCell>
                      {remainingDays < 0 ? 0 : remainingDays}
                    </TableCell>
                    <TableCell>
                      {mgName} (TIN: {mgTin})
                    </TableCell>
                    <TableCell>{officeName}</TableCell>
                    <TableCell>{userName}</TableCell>
                    <TableCell>{startDate || 'N/A'}</TableCell>
                    <TableCell>{endDate || 'N/A'}</TableCell>
                    <TableCell>{templateName}</TableCell>
                    <TableCell>{parentChallengeId}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default ChallengesTable;

ChallengesTable.propTypes = {
  challenges: PropTypes.arrayOf(challengeShape).isRequired,
  selectable: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  onSelect: PropTypes.func,
  onToggleSelectAll: PropTypes.func,
  ariaLabel: PropTypes.string.isRequired,
  onClickChallenge: PropTypes.func,
  showApprovalDate: PropTypes.bool,
};
ChallengesTable.defaultProps = {
  showApprovalDate: false,
  selectable: false,
  selectedIds: undefined,
  onSelect: undefined,
  onToggleSelectAll: undefined,
  onClickChallenge: undefined,
};

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  height: 22,
  width: 24,
  color: theme.palette.status.success,
  marginLeft: theme.spacing(1),
}));

const StyledHiddenIcon = styled(HiddenIcon)(({ theme }) => ({
  height: 22,
  width: 24,
  color: theme.palette.icon.dark,
  marginLeft: theme.spacing(1),
}));

const ChallengeCount = styled.div(({ theme }) => ({
  color: theme.palette.font.lighter,
}));
