import { useFormContext } from 'react-hook-form';
import { Box, FormLabel } from '@mui/material';
import PropTypes from 'prop-types';
import useId from '@mui/utils/useId';

import RhfCheckbox from '@/components/reactHookFormComponents/RhfCheckbox';
import FormErrorMessage from '@/components/FormErrorMessage';

function ModalCheckboxList({
  label,
  options,
  actionId,
  BoxSx,
  InputSx,
  control,
  minSelectionCount,
}) {
  const idPrefix = useId(actionId);
  const {
    getValues,
    register,
    formState: { errors },
  } = useFormContext();

  const validateCheckboxes = (checkboxOptions) => {
    const values = getValues();
    return (
      checkboxOptions.map((opt) => opt.value).filter((v) => Boolean(values[v]))
        .length >= minSelectionCount || `Select at least ${minSelectionCount}`
    );
  };
  return (
    <Box>
      <FormLabel htmlFor={`${idPrefix}_${label}`} sx={{ mt: 1 }}>
        {label}
      </FormLabel>
      <Box
        sx={{
          display: 'flex',
          mt: 1,
          flexDirection: 'column',
          ...BoxSx,
        }}
      >
        {options.map((option) => {
          const reg = register(option.value, {
            validate: validateCheckboxes.bind(null, options),
          });
          return (
            <RhfCheckbox
              key={option.value}
              control={control}
              name={option.value}
              defaultValue={option.checked}
              label={option.label}
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...reg}
              sx={{ fontSize: 'body.small', ...InputSx }}
              disabled={false}
            />
          );
        })}
        <FormErrorMessage errors={errors} name={options[0].value} />
      </Box>
    </Box>
  );
}

export default ModalCheckboxList;

ModalCheckboxList.propTypes = {
  label: PropTypes.string.isRequired,
  // options is given as value, label to match ModalSelect options
  // note that this means values must be unique as they are used as the RHF name
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),
  actionId: PropTypes.number,
  BoxSx: PropTypes.object,
  InputSx: PropTypes.object,
  control: PropTypes.object.isRequired,
  minSelectionCount: PropTypes.number,
};

ModalCheckboxList.defaultProps = {
  actionId: undefined,
  options: [],
  BoxSx: undefined,
  InputSx: undefined,
  minSelectionCount: 0,
};
