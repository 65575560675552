import PropTypes from 'prop-types';
import { Box, Skeleton, Typography, styled } from '@mui/material';
import { get, range } from 'lodash';
import { getYear } from 'date-fns';

import useDate from '@/hooks/useDate';
import useFeatureFlag from '@/utils/useFeatureFlag';
import colorPool from '@/themeV2/colorPool';
import useGetTbwInsights from '@/pages/TaskBasedWorkflow/hooks/useGetTbwInsights';

import WidgetVisitsProgress from './WidgetVisitsProgress';

function renderCount(
  count,
  label,
  inCarousel,
  color = undefined,
  large = false,
) {
  return (
    <Typography variant="bodymedium" mt={inCarousel ? undefined : 0.5}>
      {color ? (
        <LegendColor $color={color} $large={large} sx={{ mr: 1 }} />
      ) : null}
      <Typography variant="bodymediumbold">{count}</Typography> {label}
    </Typography>
  );
}

function useTitle(inCarousel) {
  const year = getYear(useDate());
  const annualVisitToOfficeVisit = useFeatureFlag(
    'annual_visit_to_office_visit',
  );
  if (inCarousel) return `${year} Progress (Year to Date)`;
  if (annualVisitToOfficeVisit) return 'Visit progress';
  return 'Preventative visit progress';
}

function WidgetVisitsLoading({ small, sx }) {
  const size = small ? 120 : 132;
  return (
    <WidgetVisitsRoot sx={sx}>
      <Skeleton variant="circular" width={size} height={size} />
      <Box width={200} ml={4}>
        {range(small ? 5 : 6).map((index) => (
          <Skeleton key={index} sx={{ mt: small ? 0 : 0.5 }} />
        ))}
      </Box>
    </WidgetVisitsRoot>
  );
}
WidgetVisitsLoading.propTypes = {
  small: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
WidgetVisitsLoading.defaultProps = {
  small: false,
  sx: undefined,
};

export default function WidgetVisits({ inCarousel, sx }) {
  const { data, isLoading } = useGetTbwInsights();
  const title = useTitle(inCarousel);

  if (isLoading) {
    return <WidgetVisitsLoading sx={sx} small={inCarousel} />;
  }

  const {
    completedTasksCount,
    notScheduledCount,
    readyToCompleteCount,
    upcomingApptCount,
  } = data.visits;

  const totalVisits =
    completedTasksCount +
    readyToCompleteCount +
    upcomingApptCount +
    notScheduledCount;

  return (
    <WidgetVisitsRoot data-dd-synthetics-id="schedule-visits-widget" sx={sx}>
      <WidgetVisitsProgress
        visitsCompletedCount={completedTasksCount}
        upcomingAppointmentsCount={upcomingApptCount}
        visitsReadyToCompleteCount={readyToCompleteCount}
        visitsToBeScheduledCount={notScheduledCount}
        small={inCarousel}
      />

      <Box ml={4}>
        <Typography variant="bodymediumbold" component="p">
          {title}
        </Typography>
        {!inCarousel && (
          <>
            {renderCount(totalVisits, 'total visits', inCarousel)}
            <HorizontalRule />
          </>
        )}
        {renderCount(
          completedTasksCount,
          'visits completed',
          inCarousel,
          'status.successForeground',
        )}
        {renderCount(
          readyToCompleteCount,
          'visits ready to complete',
          inCarousel,
          colorPool.emerald[500],
          true,
        )}
        {renderCount(
          upcomingApptCount,
          'upcoming appointments',
          inCarousel,
          colorPool.emerald[300],
        )}
        {renderCount(
          notScheduledCount,
          'visits to be scheduled',
          inCarousel,
          'status.infoBackgroundHover',
          true,
        )}
      </Box>
    </WidgetVisitsRoot>
  );
}

WidgetVisits.propTypes = {
  inCarousel: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

WidgetVisits.defaultProps = {
  inCarousel: false,
  sx: undefined,
};

const WidgetVisitsRoot = styled('div')`
  display: flex;
  align-items: center;
`;

const HorizontalRule = styled('div')`
  border-top: 1px solid ${(p) => p.theme.palette.border.base};
`;

const LegendColor = styled('span')`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: ${(p) => 1.25 * p.theme.shape.borderRadius}px;
  background-color: ${(p) => get(p.theme.palette, p.$color, p.$color)};
  vertical-align: middle;
`;
