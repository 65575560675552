export default function routingNumberCheckSum(routingNumber) {
  if (!routingNumber) {
    return false;
  }

  const digits = routingNumber.split('').map((d) => parseInt(d, 10));

  if (digits.length !== 9) {
    return false;
  }

  const checksum =
    3 * (digits[0] + digits[3] + digits[6]) +
    7 * (digits[1] + digits[4] + digits[7]) +
    (digits[2] + digits[5] + digits[8]);

  return checksum % 10 === 0;
}
