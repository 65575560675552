import { useController } from 'react-hook-form';
import useId from '@mui/utils/useId';

import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';
import DynamicFormLabel from '@/components/DynamicForm/DynamicFormLabel';
import BaseSelect from '@/components/BaseSelect';
import FormErrorMessage from '@/components/FormErrorMessage';
import DynamicFormNote from '@/components/DynamicForm/DynamicFormNote';

export default function DynamicFormDropdown({ field }) {
  const { label, name, required, choices, preset, note } = field;
  const fieldId = useId();
  const controller = useController({
    name,
    defaultValue: preset,
    rules: {
      required: required === true ? 'Select a value' : required,
    },
  });

  const error = Boolean(controller.fieldState.error);
  return (
    <>
      <DynamicFormLabel htmlFor={fieldId}>{label}</DynamicFormLabel>
      <BaseSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controller.field}
        data-pendo-id={field.dataPendoId}
        fullWidth
        label={label}
        error={error}
        options={choices.map((choice) => ({
          value: choice[0],
          label: choice[1],
        }))}
      />
      <FormErrorMessage errors={controller.formState.errors} name={name} />
      {note && (
        <DynamicFormNote variant="bodysmall">Note: {note}</DynamicFormNote>
      )}
    </>
  );
}

DynamicFormDropdown.propTypes = {
  field: executionRequirementShape.isRequired,
};
