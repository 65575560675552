import { actionShape } from '@/utils/transformFrontendAction';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';
import FieldSkeleton, {
  FieldSkeletonSizes,
} from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';

export default function PYMSAllowableDaysLeft({ action }) {
  const { data: contextualInfo, isLoading } = useGetContextualInfo(action);

  return isLoading ? (
    <FieldSkeleton size={FieldSkeletonSizes.small} />
  ) : (
    <span style={{ whiteSpace: 'nowrap' }}>
      {contextualInfo.adherenceDaysLeft}
    </span>
  );
}

PYMSAllowableDaysLeft.propTypes = {
  action: actionShape.isRequired,
};
