import { isSameDay, isValid, parse, set } from 'date-fns';

import useDate from '@/hooks/useDate';
import { useQueryParameters } from '@/contexts/QueryParametersContext';

/**
 * Returns true if the user is viewing historical data.
 *
 * Returns false if the user is viewing the current month's data.
 */
export default function useIsHistoricalData() {
  const {
    parameters: { dateMonth },
  } = useQueryParameters();
  const now = useDate();

  const currentDateMonth = set(now, { date: 1 });
  const parsedDateMonth = parse(dateMonth, 'yyyy-MM-dd', now);

  if (!dateMonth || !isValid(parsedDateMonth)) {
    // If date_month does not exist or is invalid, the user is viewing this month's goals.
    return false;
  }

  return !isSameDay(currentDateMonth, parsedDateMonth);
}
