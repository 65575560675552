import TableCell from '@mui/material/TableCell';
import { FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';

export default function RequiredFieldTableCell({ cellContents, errorMessage }) {
  return (
    <TableCell>
      {cellContents || <FormHelperText error>{errorMessage}</FormHelperText>}
    </TableCell>
  );
}
RequiredFieldTableCell.propTypes = {
  cellContents: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorMessage: PropTypes.string,
};
RequiredFieldTableCell.defaultProps = {
  cellContents: undefined,
  errorMessage: 'Required field missing',
};
