export default function getTotalSvusOnCompletionSteps(selectedTasks) {
  return selectedTasks
    .filter((task) => task.action.v2Dto.version)
    .map((task) =>
      task.action.v2Dto.availableNextSteps.find(
        (step) => step.isCompletionStep,
      ),
    )
    .reduce(
      (total, step) => total + (step.svuPostPromo || step.svuPrePromo),
      0,
    );
}
