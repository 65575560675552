import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@mui/material/Button';

import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/chevronLeft.svg';
import BaseSpinner from '@/components/BaseSpinner';
import { useQueryParameters } from '@/contexts/QueryParametersContext';

import Filter from './Filter';
import { FILTER_KEYS } from './constants';
import useFilterCount from './hooks/useFilterCount';

const FiltersSidebar = memo(function Sidebar({
  className,
  filterOptions,
  filterSections,
  isLoading,
  onClose,
  sx,
}) {
  const { mergeParameters } = useQueryParameters();
  const filterCount = useFilterCount();
  const handleClickClear = () => {
    const newParameters = { page: undefined };
    FILTER_KEYS.forEach((key) => {
      newParameters[key] = undefined;
    });
    mergeParameters(newParameters);
  };
  const [containerOffset, setContainerOffset] = useState(0);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const handleScroll = () => {
    const { top } = document
      .getElementById('sections-container')
      .getBoundingClientRect();
    setContainerOffset(top);
  };

  const handleResize = () => {
    setInnerHeight(window.innerHeight);
  };

  // When the page scrolls or resizes we need to move around the sidebar and
  // change the size so that the whole thing is as accessible as possible and
  // the user can access the largest amount of content without having to scroll
  // the outside page. This isn't perfect and the top of the sidebar may end up
  // cut off at the bottom of the page, but it's pretty good.
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sectionsContainerHeight = Math.min(
    innerHeight,
    innerHeight - containerOffset,
  );

  const filterSectionsToDisplay = filterSections.filter(
    ({ filterOptionsKey }) => !filterOptions.hideSections?.[filterOptionsKey],
  );
  return (
    <Container
      className={className}
      data-testid="filter-sidebar"
      id="filters-sidebar-container"
      sx={sx}
    >
      <Header>
        <Heading>Filters</Heading>
        {filterCount > 0 && (
          <Button onClick={handleClickClear} variant="text">
            Clear All
          </Button>
        )}
        <CollapseButton
          aria-label="Collapse filters"
          onClick={onClose}
          data-pendo-id="collapse-filters"
        >
          <StyledChevronLeftIcon width="16" />
        </CollapseButton>
      </Header>

      <SectionsContainer
        $height={`${sectionsContainerHeight}px`}
        id="sections-container"
      >
        {isLoading ? (
          <SpinnerWrapper data-testid="loading-spinner">
            <BaseSpinner size={40} />
          </SpinnerWrapper>
        ) : (
          filterSectionsToDisplay.map(
            ({ filterKey, filterOptionsKey, label, search }) => (
              <Filter
                filterKey={filterKey}
                key={filterKey}
                label={label}
                options={filterOptions[filterOptionsKey]}
                search={search}
              />
            ),
          )
        )}
      </SectionsContainer>
    </Container>
  );
});

export const filterOptionsShape = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.objectOf(PropTypes.bool),
  ]),
);

export const filterSectionShape = PropTypes.shape({
  filterKey: PropTypes.string.isRequired,
  filterOptionsKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  search: PropTypes.bool,
  showFilterKey: PropTypes.string,
});

FiltersSidebar.propTypes = {
  className: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filterOptions: filterOptionsShape,
  filterSections: PropTypes.arrayOf(filterSectionShape).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

FiltersSidebar.defaultProps = {
  className: undefined,
  filterOptions: {},
  sx: undefined,
};

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.base,
  minWidth: '300px',
  width: '300px',
  position: 'sticky',
  top: '75px',
  alignSelf: 'flex-start',
  left: 0,
  borderRight: `1px solid ${theme.palette.border.base}`,
}));

const StyledChevronLeftIcon = styled(ChevronLeftIcon)(({ theme }) => ({
  color: theme.palette.icon.secondary,
}));

const SectionsContainer = styled.div`
  overflow-y: auto;
  height: ${(props) => props.$height};
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  background-color: ${(p) => p.theme.palette.background.base};
  box-shadow: inset 0px -1px 0px ${(p) => p.theme.palette.border.base};
`;

const Heading = styled.h2`
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 800;
  margin: 10.5px auto 10.5px 12px;
  color: ${(p) => p.theme.palette.text.primary};
`;

const CollapseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  line-height: 0;
  padding: 15px;

  :hover {
    background-color: ${(p) => p.theme.palette.background.primaryLightest};
  }
  :active {
    background-color: ${(p) => p.theme.palette.background.primaryLighter};
  }
`;

const SpinnerWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`;

export default FiltersSidebar;
