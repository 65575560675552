import { useQueries } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';

import useFeatureFlag from '@/utils/useFeatureFlag';
import transformVisitsAndDxHistory from '@/pages/PrintSummary/utils/transformVisitsAndDxHistory';

async function fetchVisitsAndDxHistory(patientId) {
  return axios
    .get(`/patient/${patientId}/visits-and-dx-history`)
    .then((response) => response.data);
}

function transformResponses(patients, responses) {
  const data = {};
  patients.forEach((patient, index) => {
    if (responses[index].data) {
      data[patient.patientInfo.id] = transformVisitsAndDxHistory(
        responses[index].data,
      );
    }
  });
  return data;
}

export default function useGetVisitsAndDxHistory({ patientIds, printSummary }) {
  const isVisitsAndDxHistoryEnabled = useFeatureFlag(
    'enablePriorityPatientVisitsClaimsData',
  );
  const queries = [];
  const patients = [];
  if (isVisitsAndDxHistoryEnabled) {
    patientIds.forEach((patientId) =>
      patients.push({ patientInfo: { id: patientId } }),
    );
  } else if (printSummary) {
    printSummary.patients.forEach((patient) => patients.push(patient));
  }
  patients.forEach((patient) => {
    const enabled =
      Boolean(isVisitsAndDxHistoryEnabled) ||
      (Boolean(printSummary.featureFlags.enablePrintedSummaryRiskFactors) &&
        Boolean(patient.priorityPatientVisitInfo));
    queries.push({
      queryKey: ['patient-visits-and-dx-history', patient.patientInfo.id],
      queryFn: () => fetchVisitsAndDxHistory(patient.patientInfo.id),
      enabled,
    });
  });
  const responses = useQueries({ queries });

  const anyErrors = responses.some((r) => r.isError);
  const anyLoading = responses.some(
    (r) => r.isLoading && r.fetchStatus === 'fetching',
  );

  return useMemo(
    () => {
      if (anyErrors) {
        return {
          status: 'error',
          isLoading: false,
          isError: true,
          isSuccess: false,
          data: {},
          errors: responses
            .filter((r) => r.isError)
            .map((r) => r.error.response),
        };
      }
      if (anyLoading) {
        return {
          status: 'loading',
          isLoading: true,
          isError: false,
          isSuccess: false,
          data: {},
        };
      }

      return {
        status: 'success',
        isLoading: false,
        isError: false,
        isSuccess: true,
        data: transformResponses(patients, responses) ?? {},
      };
    },
    // These dependencies are here because react-query
    // doesn't offer the same reference identity for `responses` or for any
    // individual response between renders. It _does_ treat all sub-properties
    // of responses as reference-stable. So we use the status property as a
    // shorthand for detecting if the request state has changed.
    //
    // We care about this because the transformResponses function does some
    // fairly serious data transformation that shouldn't happen on every
    // render cycle.
    //
    // Extra note: checking all data cause an issue with memory variables
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [anyErrors, anyLoading, !printSummary],
  );
}
