import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

import BaseSpinner from '@/components/BaseSpinner';

export default function LoadingPage() {
  const theme = useTheme();
  return (
    <Box
      sx={{ margin: 5, p: 3 }}
      bgcolor="white"
      border="1px solid"
      borderColor={theme.palette.border.base}
      borderRadius="8px"
      padding="24px"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.075)"
      height="832px"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <BaseSpinner size={50} sx={{ ml: 1 }} />
    </Box>
  );
}
