import { Typography, styled } from '@mui/material';

import { patientShape } from '@/utils/transformFrontendPatient';

export default function PatientTags({ patient }) {
  return (
    <PatientTagsRoot aria-label="Patient tags">
      {patient.tags.map((tag) => (
        <Tag key={tag} component="li" variant="bodysmall" title={tag}>
          {tag}
        </Tag>
      ))}
    </PatientTagsRoot>
  );
}

PatientTags.propTypes = {
  patient: patientShape.isRequired,
};

const PatientTagsRoot = styled('ul')`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  margin-top: ${(p) => p.theme.spacing(1.5)};
`;

const Tag = styled(Typography)`
  max-width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid;
  border-color: ${(p) => p.theme.palette.border.base};
  border-radius: ${(p) => 2 * p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(0, 1.5)};

  :not(:first-of-type) {
    margin-left: ${(p) => p.theme.spacing(0.5)};
  }
`;
