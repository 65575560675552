import axios from 'axios';

export default async function uploadFile({
  actionId,
  file,
  fileName,
  patientId,
  csrfToken,
}) {
  const fileForm = new FormData();

  fileForm.append('file', file, fileName);
  fileForm.append('csrfmiddlewaretoken', csrfToken);
  fileForm.append('action_id', actionId);

  const url = `/patient/${patientId}/upload_file`;

  return axios.post(url, fileForm);
}
