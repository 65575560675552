import { useState } from 'react';
import { arrayOf, bool, func, object, objectOf } from 'prop-types';

import { patientShape } from '@/utils/shapes';
import getTotalSvusOpportunity from '@/pages/TaskBasedWorkflow/utils/getTotalSvusOpportunity';
import useGetPatientSvuSummaries from '@/pages/TaskBasedWorkflow/hooks/useGetPatientSvuSummaries';

import CompletedTasksInterstitialModal from './CompletedTasksInterstitialModal';
import CompleteAdditionalActionsModal from './CompleteAdditionalActionsModal';

const stepToComponent = {
  interstitial: CompletedTasksInterstitialModal,
  complete_more: CompleteAdditionalActionsModal,
};

export default function CompletedSelectedTasksDialog({
  open,
  completedActions,
  onClose,
  patientMap,
}) {
  const patientIds = completedActions.map((a) => a.v2Dto.patientId);
  const { data: patientSvuSummaries = {} } = useGetPatientSvuSummaries({
    patientIds,
    enabled: patientIds.length > 0,
  });
  const [step, setStep] = useState('interstitial');
  const Component = stepToComponent[step];

  const totalSvuOpportunity = getTotalSvusOpportunity(
    completedActions,
    patientSvuSummaries,
  );

  const onCancel = () => {
    setStep('interstitial');
    onClose();
  };

  const onContinue = () => {
    setStep('complete_more');
  };

  return (
    <Component
      completedActions={completedActions}
      onCancel={onCancel}
      onContinue={onContinue}
      open={open}
      patientMap={patientMap}
      totalSvuOpportunity={totalSvuOpportunity}
    />
  );
}

CompletedSelectedTasksDialog.propTypes = {
  completedActions: arrayOf(object.isRequired).isRequired,
  onClose: func.isRequired,
  open: bool,
  patientMap: objectOf(patientShape.isRequired).isRequired,
};

CompletedSelectedTasksDialog.defaultProps = {
  open: false,
};
