import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { object } from 'prop-types';
import { Box, Stack } from '@mui/material';

import useGetUserFilters from '@/hooks/useGetUserFilters';
import { getDefaultValue } from '@/utils/transformOption';

import BaseSelect from '../../BaseSelect';
import BaseFieldset from '../../BaseFieldset';
import DynamicFormLabel from '../DynamicFormLabel';

import DynamicFormTextField from './DynamicFormTextField';
import DynamicFormTextarea from './DynamicFormTextarea';

// eslint-disable-next-line react/prop-types
function DynamicFormReferralFirstLastName({ firstNameField, lastNameField }) {
  return (
    <Stack direction="row" spacing={1} mt={2}>
      <Box sx={{ flexGrow: 1 }}>
        <DynamicFormTextField field={firstNameField} />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <DynamicFormTextField field={lastNameField} />
      </Box>
    </Stack>
  );
}

const DEFAULT_DESCRIPTION =
  'Referral information will appear on the printed referral';

/**
 * The NAME_MAPPING field converts between a FrontendPreferredReferral
 * entity (which contains the properties named by the keys, namely,
 * providerFirstName, providerLastName, providerSingleName, phone, and
 * address). The properties of each child object are the user-facing label
 * for each field and the internal name to which it maps. The defaults are
 * selected to be compatible with non-step-based legacy action types, where
 * breast, colorectal, and cervical cancer screenings all use the
 * providerSingleName property, while diabetes eye exam actions use both the
 * first and last name fields.
 */
const DEFAULT_NAME_MAPPING = {
  providerFirstName: {
    label: 'First Name',
    name: 'optometrist_first_name',
  },
  providerLastName: {
    label: 'Last Name',
    name: 'optometrist_last_name',
  },
  providerSingleName: {
    label: 'Provider Name',
    name: 'speciality_provider_single_name',
  },
  phone: { label: 'Phone Number', name: 'phone_number_input' },
  address: { label: 'Address', name: 'address_input' },
};

/**
 * Generates synthetic executionRequirement fields using the name mapping,
 * (described above). It uses the name property, and generates a field for
 * first and last, or single name (if so configured), phone, and address.
 *
 * In addition, it exposes a callback for assigning values into those form
 * fields, using RHF's setValue command.
 *
 * It also exposes a callback for resetting values in those form fields,
 * using RHF's resetField command.
 */
function useFieldNameMapping(mapping, required, hasFirstAndLastNames) {
  const { setValue, resetField } = useFormContext();
  const fieldMap = useMemo(() => {
    return Object.keys(mapping).reduce((acc, key) => {
      const { label, name } = mapping[key];
      return {
        ...acc,
        [key]: {
          label,
          name,
          required,
          sx: { width: 1 },
        },
      };
    }, {});
  }, [mapping, required]);

  const assignProvider = useCallback(
    (provider) => {
      const getFieldName = (n) => mapping[n].name;

      if (hasFirstAndLastNames) {
        setValue(
          getFieldName('providerFirstName'),
          provider.providerFirstName || '',
        );
        setValue(
          getFieldName('providerLastName'),
          provider.providerLastName || '',
        );
      } else {
        setValue(
          getFieldName('providerSingleName'),
          provider.providerSingleName || '',
        );
      }

      setValue(getFieldName('phone'), provider.phone || '');
      setValue(getFieldName('address'), provider.address || '');
    },
    [mapping, setValue, hasFirstAndLastNames],
  );

  const resetProvider = useCallback(() => {
    // Reset all fields:
    Object.keys(mapping).forEach((key) => {
      resetField(mapping[key].name);
    });
  }, [mapping, resetField]);

  return {
    fieldMap,
    resetProvider,
    assignProvider,
  };
}

function getProviderLabel(provider) {
  if (provider.providerSingleName) {
    return provider.providerSingleName;
  }
  return [provider.providerFirstName, provider.providerLastName].join(' ');
}

function providersToOptions(providers) {
  if (!providers) return [];

  return providers.map((p) => ({
    value: p.id,
    label: getProviderLabel(p),
  }));
}

/**
 * Generates a collection of fields for filling in referral-related properties
 * on an action. In particular:
 * - provider first name and last name, **OR** a single provider name/dba
 * - the provider phone number, and;
 * - the provider address
 *
 * Can be used with DynamicForms with type=referral.
 *
 * @param field
 * @returns {JSX.Element}
 * @constructor
 */
export default function DynamicFormReferral({ field }) {
  const {
    fieldNameMapping = DEFAULT_NAME_MAPPING,
    hasFirstAndLastNames,
    label,
    providers,
    required,
    description = DEFAULT_DESCRIPTION,
    name,
    actionSubtype = '',
  } = field;

  const [selectedProviderId, setSelectedProviderId] = useState('');
  const { fieldMap, assignProvider, resetProvider } = useFieldNameMapping(
    fieldNameMapping,
    required,
    hasFirstAndLastNames,
  );
  const { resetField } = useFormContext();
  const { data, isLoading } = useGetUserFilters({
    onSuccess: (data) => {
      const defaultValue = getDefaultValue(data.offices);
      if (defaultValue) {
        resetField(name, { defaultValue });
      }
    },
  });
  const userFilterReferralProviders = data?.preferredReferrals || [];
  const preferredProviders =
    providers && providers.length > 0 ? providers : userFilterReferralProviders;
  let filteredProviders = preferredProviders;
  if (actionSubtype) {
    filteredProviders = preferredProviders.filter(
      (provider) => actionSubtype === provider.actionSubtype,
    );
  }
  const options = useMemo(
    () => providersToOptions(filteredProviders),
    [filteredProviders],
  );

  const handleChange = (e) => {
    setSelectedProviderId(e.target.value);
    const provider = filteredProviders.find((p) => p.id === e.target.value);
    if (provider) {
      assignProvider(provider);
    } else {
      resetProvider();
    }
  };
  // Don't pass a value until the data is finished loading.
  // Otherwise MUI will complain that the form's default value (if there is one) isn't an available option.
  const value = isLoading ? undefined : selectedProviderId;

  // N.B.: The following form field is _not_ registered with RHF. This has the
  // effect of excluding it from the submission data, which is what we want. The
  // act of selecting something from this box changes the state of other form
  // fields. but should not appear in the submission data.  --NH
  const select =
    options && options.length ? (
      <>
        <DynamicFormLabel>Provider</DynamicFormLabel>
        <BaseSelect
          noneOption="Other (fill in)"
          label="Provider"
          onChange={handleChange}
          options={options}
          sx={{ mt: 1 / 2, width: 1 }}
          value={value}
        />
      </>
    ) : null;

  return (
    <BaseFieldset
      label={`${label} ${required ? '(optional)' : ''}`}
      description={description}
    >
      {select}
      {hasFirstAndLastNames ? (
        <DynamicFormReferralFirstLastName
          firstNameField={fieldMap.providerFirstName}
          lastNameField={fieldMap.providerLastName}
        />
      ) : (
        <DynamicFormTextField field={fieldMap.providerSingleName} />
      )}
      <DynamicFormTextField field={fieldMap.phone} />
      <DynamicFormTextarea field={fieldMap.address} />
    </BaseFieldset>
  );
}

DynamicFormReferral.propTypes = {
  field: object.isRequired,
};
