import {
  Button,
  List,
  ListItemButton,
  Typography,
  listItemButtonClasses,
  styled,
} from '@mui/material';
import { useToggle } from 'react-use';
import { Fragment, useState } from 'react';
import { format, parseISO, startOfToday } from 'date-fns';
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types';

import { ReactComponent as ChevronUpIcon } from '@/assets/icons/chevronUp.svg';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/chevronDown.svg';
import BaseLinkButton from '@/components/BaseLinkButton';
import { color, spacing, typography } from '@/utils/stylingUtils';
import buildExecutionInput from '@/pages/TaskBasedWorkflow/utils/buildExecutionInput';
import { calculateActionsToSave } from '@/components/CompactPatientSummary/components/NonDiagnosisSection/ExecutionRequirementsPanel';
import ErrorAlert from '@/pages/TaskBasedWorkflow/components/ErrorAlert';
import useCsrfToken from '@/hooks/useCsrfToken';
import snakeToCamel from '@/utils/snakeToCamel';
import { relatedActionShape } from '@/utils/relatedActions';
import { actionShape } from '@/utils/transformFrontendAction';
import { patientShape } from '@/utils/shapes';
import CptPopover from '@/pages/PatientSummary/components/SectionCareGaps/CptPopover';
import { DISPLAY_DATE_FORMAT } from '@/pages/PatientSummary/utils/constants';

function getExecutionData(executionRequirements, gapHistory) {
  const executionData = {};
  for (const req of executionRequirements) {
    if (req.required) {
      executionData[req.name] = gapHistory.details[snakeToCamel(req.name)];
    }
    if (
      req.name === 'date_of_service' ||
      req.name === 'date_of_screening' ||
      req.name === 'scheduled_date_of_screening'
    ) {
      executionData[req.name] = gapHistory.dateOfService;
    }
  }

  return executionData;
}

export default function EhrStatusCell({
  action,
  open,
  setOpen,
  onSaveNonDiagnosisAction,
  patient,
  relatedActions,
  choices,
}) {
  const [error, setError] = useState(null);
  const [choicesOpen, toggleChoicesOpen] = useToggle(false);
  const completedStep = action.availableNextSteps.find(
    (step) => step.isCompletionStep,
  );
  const gapHistory = action.contextualInfo.latestExternalGapHistory;
  const cptCodesArray = gapHistory.cptCodes.split(' ');
  const csrfToken = useCsrfToken();
  const today = startOfToday();

  const handleManualClick = () => {
    toggleChoicesOpen();
    if (open) {
      setOpen(false);
    } else {
      setOpen(completedStep.stepId);
    }
  };

  const handleSubmit = async () => {
    setError(null);
    const executionData = getExecutionData(
      completedStep.executionRequirements,
      gapHistory,
    );

    try {
      const executionInput = await buildExecutionInput({
        action,
        patient,
        csrfToken,
        currentDate: today,
        data: executionData,
      });

      const actionsToSave = calculateActionsToSave({
        actionId: action.version ? action.actionId : action.id,
        stepId: completedStep.stepId,
        relatedActions,
        providerId: patient.provider?.id,
        executionData,
        executionInput,
      });

      await onSaveNonDiagnosisAction(actionsToSave);
    } catch (e) {
      setError(e); // todo: determine error type
    }
  };

  return (
    <>
      {error ? (
        <ErrorAlert
          message="We couldn't confirm your changes"
          handleCloseAlert={() => setError(null)}
        />
      ) : null}
      <Typography variant="bodymedium">Completed in EHR:</Typography>
      {gapHistory ? (
        <>
          {cptCodesArray.map((code, idx) => (
            <Fragment key={code}>
              <CptPopover cptCode={code} cptText="Some placeholder text" />
              {idx < cptCodesArray.length - 1 ? ', ' : ' '}
            </Fragment>
          ))}
          <Typography variant="bodymedium">
            on {format(parseISO(gapHistory.dateOfService), DISPLAY_DATE_FORMAT)}
          </Typography>
        </>
      ) : null}
      <Button variant="outlined" onClick={handleSubmit}>
        Confirm
      </Button>
      <StyledLinkButton onClick={handleManualClick}>
        Enter manually
        {choicesOpen ? <StyledChevronUpIcon /> : <StyledChevronDownIcon />}
      </StyledLinkButton>
      {choicesOpen && (
        <StyledList
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
        >
          {choices.map((choice) => (
            <StyledListItemButton
              key={choice.value}
              onClick={() => setOpen(choice.value)}
              selected={open === choice.value}
            >
              {choice.label}
            </StyledListItemButton>
          ))}
        </StyledList>
      )}
    </>
  );
}

EhrStatusCell.propTypes = {
  action: actionShape.isRequired,
  open: oneOfType([bool, string]).isRequired,
  setOpen: func.isRequired,
  onSaveNonDiagnosisAction: func.isRequired,
  patient: patientShape.isRequired,
  relatedActions: arrayOf(relatedActionShape),
  choices: arrayOf(shape({ value: string, label: string })).isRequired,
};

EhrStatusCell.defaultProps = {
  relatedActions: [],
};

const StyledLinkButton = styled(BaseLinkButton)`
  color: ${color('text.link')};
  text-decoration: none;
  margin: ${spacing(0.5)} 0;
  padding: ${spacing(0.5)} 0;

  &:hover {
    background-color: ${color('background.tableZebra')};
  }
`;

const StyledChevronUpIcon = styled(ChevronUpIcon)`
  height: 16px;
  padding: 0 4px;
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  height: 16px;
`;

const StyledList = styled(List)`
  padding: 0;
`;

const StyledListItemButton = styled(ListItemButton)`
  width: 100%;
  text-align: left;
  padding: ${spacing(1)};
  border-bottom: 1px solid;
  border-bottom-color: ${color('border.base')};
  color: ${color('text.link')};
  text-decoration: none;

  &:hover {
    background-color: ${color('background.tableZebra')};
    border-bottom-color: ${color('border.disabled')};
  }

  &.${listItemButtonClasses.selected} {
    ${typography('bodybold')}
    background-color: ${color('background.base')};

    &:hover {
      cursor: default;
      background-color: ${color('background.secondary')};
    }
  }
`;
