import { styled } from '@mui/material';
import { number } from 'prop-types';

import {
  CommonFirstColumn,
  CommonProgressBar,
  CommonProgressText,
} from './commonStyles';

export default function PatientExperienceRowAverageRating({ value }) {
  const normalizedValue = (value / 5) * 100;
  return (
    <>
      <Text>{value ? value.toFixed(1) : '--'}</Text>
      <ProgressBar variant="determinate" value={normalizedValue} />
    </>
  );
}

PatientExperienceRowAverageRating.propTypes = {
  value: number,
};

PatientExperienceRowAverageRating.defaultProps = {
  value: undefined,
};

const Text = styled(CommonProgressText)`
  color: ${(p) => p.theme.palette.status?.infoForeground};
`;

const ProgressBar = styled(CommonProgressBar)`
  background-color: ${(p) => p.theme.palette.status?.infoBackground};
  & .MuiLinearProgress-bar {
    background-color: ${(p) => p.theme.palette.status?.infoForeground};
  }
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;

export const RowAverageRatingFirstColumn = styled(CommonFirstColumn)`
  color: ${(p) => p.theme.palette.status?.infoForeground};
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;
