import PropTypes from 'prop-types';
import useId from '@mui/utils/useId';
import { Autocomplete, Input } from '@mui/material';

/**
 * BaseAutocomplete is a light wrapper around MUI's Autocomplete component.
 *
 * Documentation: https://mui.com/components/autocomplete/
 */
function BaseAutocomplete({
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledby,
  'data-pendo-id': dataPendoId,
  autoHighlight,
  autoSelect,
  className,
  disabled,
  disableListWrap,
  error,
  fullWidth,
  getOptionLabel,
  id: idProp,
  multiple,
  onBlur,
  onChange,
  onInputChange,
  open,
  options,
  placeholder,
  size,
  sx,
  value,
  isOptionEqualToValue,
  renderOption,
  limitTags,
  disableCloseOnSelect,
  freeSolo,
  inputValue,
}) {
  const id = useId(idProp);

  return (
    <Autocomplete
      inputValue={inputValue}
      freeSolo={freeSolo}
      data-pendo-id={dataPendoId}
      autoHighlight={autoHighlight}
      isOptionEqualToValue={isOptionEqualToValue}
      autoSelect={autoSelect}
      className={className}
      disabled={disabled}
      disableListWrap={disableListWrap}
      fullWidth={fullWidth}
      getOptionLabel={getOptionLabel}
      multiple={multiple}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={onInputChange}
      open={open}
      options={options}
      size={size}
      sx={sx}
      renderOption={renderOption}
      value={value}
      limitTags={limitTags}
      disableCloseOnSelect={disableCloseOnSelect}
      ListboxProps={{
        'aria-label': ariaLabel,
        'aria-labelledby': ariaLabelledby,
      }}
      renderInput={(params) => (
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params.InputProps}
          disabled={disabled}
          error={error}
          fullWidth={params.fullWidth}
          inputProps={{
            ...params.inputProps,
            'aria-label': ariaLabel,
            'aria-labelledby': ariaLabelledby,
            id,
          }}
          placeholder={placeholder}
          size={size}
        />
      )}
    />
  );
}

/**
 * Checked the MUI site for the full list of props.
 *
 * Full documentation: https://mui.com/api/autocomplete/
 */
BaseAutocomplete.propTypes = {
  // The `aria-label` attribute applied to both the text field and the listbox
  'aria-label': PropTypes.string,
  // The aria-labelledby attribute applied to both the text field and the listbox
  'aria-labelledby': PropTypes.string,
  'data-pendo-id': PropTypes.string,
  autoHighlight: PropTypes.bool,
  autoSelect: PropTypes.bool,
  className: PropTypes.string,
  disableListWrap: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  // The id that gets applied to the text field.
  id: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  open: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ]),
  ).isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isOptionEqualToValue: PropTypes.func,
  renderOption: PropTypes.func,
  limitTags: PropTypes.number,
  disableCloseOnSelect: PropTypes.bool,
  freeSolo: PropTypes.bool,
  inputValue: PropTypes.string,
};

BaseAutocomplete.defaultProps = {
  'aria-label': undefined,
  'aria-labelledby': undefined,
  'data-pendo-id': undefined,
  autoHighlight: false,
  autoSelect: false,
  className: undefined,
  disableListWrap: undefined,
  disabled: false,
  error: false,
  fullWidth: false,
  getOptionLabel: undefined,
  id: undefined,
  multiple: false,
  onBlur: undefined,
  onChange: undefined,
  onInputChange: undefined,
  open: undefined,
  placeholder: undefined,
  size: 'medium',
  sx: undefined,
  value: undefined,
  isOptionEqualToValue: undefined,
  renderOption: undefined,
  limitTags: undefined,
  disableCloseOnSelect: false,
  freeSolo: undefined,
  inputValue: undefined,
};

export default BaseAutocomplete;
