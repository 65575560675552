import { Box, Typography } from '@mui/material';
import { bool, number } from 'prop-types';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import { add, format } from 'date-fns';
import { useEffect } from 'react';

import BaseFormModal from '@/components/BaseFormModal';
import register from '@/components/DynamicForm/register';
import { ReactComponent as HiddenIcon } from '@/assets/icons/eyeWithCircle.svg';
import { ISO_DATE_FORMAT } from '@/pages/PatientSummary/utils/constants';

import useUpdateHideForNow from '../hooks/useUpdateHideForNow';
import queryKeyFactory from '../queryKeyFactory';

const matchesValue = (field, value) =>
  register([field], (formState) => {
    const formValue = formState[field];
    return value === formValue;
  });

const today = new Date();

const IN_1_WEEK = 'in_1_week';
const ON_A_SPECIFIC_DATE = 'on_a_specific_date';

const formFields = [
  {
    name: 'show_again',
    label: 'Show again',
    type: 'radiogroup',
    required: true,
    choices: [
      [IN_1_WEEK, 'in 1 week'],
      ['in_4_weeks', 'in 4 weeks'],
      [ON_A_SPECIFIC_DATE, 'on a specific date'],
    ],
  },
  {
    type: 'date',
    name: 'date',
    label: 'Date',
    required: true,
    visible: matchesValue('show_again', ON_A_SPECIFIC_DATE),
  },
  {
    name: 'note',
    label: 'Note (optional)',
    type: 'textarea',
    required: false,
    minRows: 1,
    placeholder: 'Enter note',
    sx: { resize: 'vertical', padding: '4px 8px' },
  },
];

const formDefaultValues = {
  show_again: IN_1_WEEK,
};

export default function HideForNowModal({ isHidden, taskId }) {
  const [showModal, toggleModal] = useToggle(false);

  const queryClient = useQueryClient();

  const onUpdateHideForNowSuccess = () => {
    queryClient.invalidateQueries(queryKeyFactory.tasksSearch());
    queryClient.invalidateQueries(queryKeyFactory.patientsSearch());
    queryClient.invalidateQueries(queryKeyFactory.taskCounts());

    toggleModal(false);
  };

  const { isLoading, isSuccess, mutate, reset } = useUpdateHideForNow({
    onSuccess: onUpdateHideForNowSuccess,
  });

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  const handleButtonClick = () => {
    if (isHidden) {
      mutate({
        taskId,
        hideUntil: undefined,
        note: undefined,
      });
    } else {
      toggleModal();
    }
  };

  const getHideUntilDate = (showAgain, date) => {
    if (showAgain === ON_A_SPECIFIC_DATE) {
      return date;
    }
    if (showAgain === IN_1_WEEK) {
      return add(today, { weeks: 1 });
    }
    return add(today, { weeks: 4 });
  };

  const handleSubmit = (data) => {
    mutate({
      taskId,
      hideUntil: format(
        getHideUntilDate(data.show_again, data.date),
        ISO_DATE_FORMAT,
      ),
      note: data.note || undefined,
    });
  };

  return (
    <Box pl={1}>
      <StyledBaseFormModal
        alert={
          <Typography>
            This care gap will be hidden from your worklist. You can still find
            it by checking “Show hidden” at the top of the page, and it’ll still
            be visible in the patient summary.
          </Typography>
        }
        defaultValues={formDefaultValues}
        fields={formFields}
        onCancel={toggleModal}
        onClose={toggleModal}
        onSubmit={handleSubmit}
        open={showModal}
        submitButtonLabel="Confirm"
        title="Hide for now"
        isLoading={isLoading}
      />
      <button
        aria-label={isHidden ? 'Unhide task' : 'Hide task'}
        onClick={handleButtonClick}
        type="submit"
      >
        <StyledHiddenIcon $active={showModal || isHidden} />
      </button>
    </Box>
  );
}

HideForNowModal.propTypes = {
  isHidden: bool,
  taskId: number.isRequired,
};

HideForNowModal.defaultProps = {
  isHidden: false,
};

const StyledBaseFormModal = styled(BaseFormModal)`
  width: 376px;
`;

const StyledHiddenIcon = styled(HiddenIcon)(({ theme, $active }) => ({
  verticalAlign: 'middle',
  width: '32px',
  height: '32px',
  color: $active ? theme.palette.icon.link : theme.palette.icon.placeholder,

  ':hover': {
    color: $active ? theme.palette.icon.link : theme.palette.icon.secondary,
  },
}));
