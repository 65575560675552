import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';

function ErrorPopover({ anchorEl, closePopover, children }) {
  const handleClosePopover = () => {
    closePopover();
  };

  const openPopover = Boolean(anchorEl);

  return (
    <Popover
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {children}
    </Popover>
  );
}

ErrorPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  closePopover: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

ErrorPopover.defaultProps = {
  anchorEl: null,
};

export default ErrorPopover;
