import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { medicalGroupParticipantShape } from '@ot/pages/SvuMenu/utils/commonShapes';

const ROWS_PER_PAGE = 10;

export default function ParticipantsTable({ participants }) {
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell>Group Name</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>TIN</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {participants
            .slice(page * ROWS_PER_PAGE, (page + 1) * ROWS_PER_PAGE)
            .map((participant) => (
              <TableRow key={participant.id}>
                <TableCell>{participant.name}</TableCell>
                <TableCell>{participant.id}</TableCell>
                <TableCell>{participant.tin}</TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={3}
              count={participants.length}
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[ROWS_PER_PAGE]}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

ParticipantsTable.propTypes = {
  participants: PropTypes.arrayOf(medicalGroupParticipantShape).isRequired,
};
