const rootKey = 'challenges-filter-search';

const challengeSearchQueryKeyFactory = {
  all: () => [rootKey],
  challenges: (params) => {
    return [rootKey, params];
  },
};

export default challengeSearchQueryKeyFactory;
