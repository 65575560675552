import { Box, Typography } from '@mui/material';
import { string } from 'prop-types';

import { formatISODateIgnoreTimeZone } from '@/utils/formatDate';

export default function ContextualInfoDate({ label, contextualInfoVal }) {
  return (
    <Box mr={5}>
      <Typography variant="bodysmall" color="text.label">
        {label}
      </Typography>
      <Typography>
        {contextualInfoVal
          ? formatISODateIgnoreTimeZone(contextualInfoVal)
          : null}
      </Typography>
    </Box>
  );
}

ContextualInfoDate.propTypes = {
  label: string.isRequired,
  contextualInfoVal: string,
};

ContextualInfoDate.defaultProps = {
  contextualInfoVal: undefined,
};
