/* eslint-disable no-shadow */
import { Box, styled } from '@mui/material';
import { any, bool } from 'prop-types';

import { columnDefinition, rowDefinition } from './propTypes';
import TableCardRow from './TableCardRow';

const StyledTableCardRows = styled(Box)`
  display: contents;
`;

export default function TableCardRows({
  columnDefinition,
  data,
  hasBadges,
  narrow,
  rowDefinition,
  additionalProps,
}) {
  return (
    <StyledTableCardRows role={narrow ? null : 'rowgroup'}>
      {data.map((d) => {
        const id = rowDefinition.rowId(d);

        return (
          <TableCardRow
            key={id}
            additionalProps={additionalProps}
            columnDefinition={columnDefinition}
            data={d}
            hasBadges={hasBadges}
            narrow={narrow}
            rowDefinition={rowDefinition}
          />
        );
      })}
    </StyledTableCardRows>
  );
}
TableCardRows.propTypes = {
  columnDefinition: columnDefinition.isRequired,
  data: any.isRequired,
  hasBadges: bool.isRequired,
  narrow: bool.isRequired,
  rowDefinition: rowDefinition.isRequired,
  additionalProps: any,
};
TableCardRows.defaultProps = {
  additionalProps: null,
};
