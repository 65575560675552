export type PatientNote = {
  date: string;
  author: string;
  action: string;
  text: string;
  printUrl: string;
};

export type APIPatientNote = {
  date: string;
  author: string;
  action: string;
  text: string;
  print_url: string;
};
function transformPatientNote(patientNote: APIPatientNote): PatientNote {
  return {
    date: patientNote.date,
    author: patientNote.author,
    action: patientNote.action,
    text: patientNote.text,
    printUrl: patientNote.print_url,
  };
}

export default function transformFrontendPatientNotes(
  patientNotes: Array<APIPatientNote>,
) {
  return patientNotes ? patientNotes.map(transformPatientNote) : [];
}
