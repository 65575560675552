import { Stack, Typography, styled } from '@mui/material';
import { object } from 'prop-types';

import { ReactComponent as CheckIcon } from '@/assets/icons/checkFalse.svg';
import { ReactComponent as WarningIcon } from '@/assets/icons/warning.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';
import { relativeDiagnosisSeverity } from '@/utils/diagnosisUtils';

const style = (Icon) => styled(Icon)`
  height: 16px;
  width: 16px;
  flex-shrink: 0;
`;

const StyledCheckIcon = style(CheckIcon);
const StyledWarningIcon = style(WarningIcon);
const StyledInfoIcon = style(InfoIcon);

// eslint-disable-next-line react/prop-types
function Message({ children, color, icon: Icon }) {
  return (
    <Stack direction="row" alignItems="center" gap={1} my={0.5} sx={{ color }}>
      <Icon />
      <Typography variant="bodymedium">{children}</Typography>
    </Stack>
  );
}

export function CommonCodeSeverityMessage({
  condition,
  diagnosisAction,
  hccTrumping,
}) {
  const severity = relativeDiagnosisSeverity(
    condition,
    diagnosisAction,
    hccTrumping,
  );

  if (severity === 'EQUAL_SEVERITY') {
    return (
      <Message icon={StyledCheckIcon} color="status.successForeground">
        Code matches condition category.
      </Message>
    );
  }
  if (severity === 'HIGHER_SEVERITY') {
    return (
      <Message icon={StyledCheckIcon} color="status.infoForeground">
        When a claim is received with this code, this will increase the severity
        of this condition.
      </Message>
    );
  }
  if (severity === 'LOWER_SEVERITY') {
    return (
      <Message icon={StyledInfoIcon} color="status.infoForeground">
        When a claim is received with this code, this will decrease the severity
        of this condition.
      </Message>
    );
  }

  return (
    <Message icon={StyledWarningIcon} color="status.warningForeground">
      Completed, but the selected ICD code will not close gap.
    </Message>
  );
}

CommonCodeSeverityMessage.propTypes = {
  condition: object.isRequired,
  diagnosisAction: object.isRequired,
  hccTrumping: object.isRequired,
};

export function RelatedConditionSeverityMessage({
  condition,
  diagnosisAction,
  hccTrumping,
  relatedCondition,
}) {
  const severity = relativeDiagnosisSeverity(
    condition,
    diagnosisAction,
    hccTrumping,
  );

  if (condition.value === Symbol.for('COMPLETE_WITHOUT_CODE')) {
    return (
      <Message icon={StyledWarningIcon} color="status.warningForeground">
        This will change this condition to a {relatedCondition.name} (HCC{' '}
        {relatedCondition.code}) action in an incomplete state after submitting
        this patient.
      </Message>
    );
  }
  if (severity === 'HIGHER_SEVERITY') {
    return (
      <Message icon={StyledCheckIcon} color="status.infoForeground">
        When a claim is received with this code, this will increase the severity
        of this condition.
      </Message>
    );
  }
  if (severity === 'LOWER_SEVERITY') {
    return (
      <Message icon={StyledCheckIcon} color="status.successForeground">
        When a claim is received with this code, this will decrease the severity
        of this condition.
      </Message>
    );
  }

  return (
    <Message icon={StyledWarningIcon} color="status.warningForeground">
      This code doesn&apos;t match the condition you&apos;ve selected. Please
      select this code in &ldquo;Specify Another Code.&rdquo;
    </Message>
  );
}

RelatedConditionSeverityMessage.propTypes = {
  condition: object.isRequired,
  diagnosisAction: object.isRequired,
  hccTrumping: object.isRequired,
  relatedCondition: object.isRequired,
};
