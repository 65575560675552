/* eslint-disable no-shadow */
import { useReducer } from 'react';

import useFeatureFlag from '@/utils/useFeatureFlag';

import {
  changeToRelatedCondition,
  completeWithoutCode,
  conditionNotAssessed,
  conditionNotPresent,
  initialState,
  reducer,
  selectDiagnosis,
  unselectDiagnosis,
  updateNote,
} from './reducer';

/**
 * @typedef DiagnosisHcc
 * @property {number} code
 * @property {string} description
 */
/**
 * @typedef DiagnosisAction
 * @property {string} type
 * @property {string} subtype
 * @property {DiagnosisHcc?} hcc
 * @property {number} updatedAt
 * @property {string} state
 * @property {string} dateOfService
 * @property {string?} date
 * @property {string?} mostRecentProviderForDisplay
 * @property {string?} mostRecentExternalCareGapSource
 * @property {string?} mostRecentlyCompletedByName
 * @property {string?} mostRecentExternalCareGapDate
 * @property {string?} mostRecentlyCompletedByDate
 * @property {string[]?} userAddedDiagnosisCodes
 */
/**
 * @typedef Diagnosis
 * @property {string} description
 * @property {number?} hcc
 * @property {string} icd
 * @property {boolean} raf
 */
/**
 * @typedef HccResult
 * @property {string} value
 * @property {number} hcc
 * @property {string} label
 */

/**
 * @typedef DiagnosisStateManager
 * @property {DiagnosisReducerState} state
 * @property {() => void} completeWithoutCode
 * @property {(condition: HccResult | null) => void} changeToRelatedCondition
 * @property {() => void} conditionNotPresent
 * @property {(diagnosis: Diagnosis) => void} selectDiagnosis
 * @property {(note: string) => void} updateNote
 * @property {(diagnosis: Diagnosis) => void} unselectDiagnosis
 */

/**
 * Manages the state of a diagnosis action's form.
 *
 * @returns {DiagnosisStateManager}
 */
export default function useDiagnosisActionStateManager() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const completeWithoutCodeEnabled = useFeatureFlag(
    'patientSummaryV2EnableCompleteWithoutCode',
  );
  const changeToRelatedConditionEnabled = useFeatureFlag(
    'changeToRelatedConditionEnabled',
  );

  return {
    state,
    changeToRelatedCondition(condition) {
      if (!changeToRelatedConditionEnabled) {
        // eslint-disable-next-line no-console
        console.warn(
          `Using changeToRelatedCondition without the feature flag enabled!`,
        );
      }
      dispatch(
        changeToRelatedCondition({
          condition,
        }),
      );
    },
    completeWithoutCode(shouldCompleteWithoutCode, selectedHcc = null) {
      if (!completeWithoutCodeEnabled) {
        // eslint-disable-next-line no-console
        console.warn(
          `Using completeWithoutCode without the feature flag enabled!`,
        );
      }

      dispatch(
        completeWithoutCode({
          completeWithoutCode: shouldCompleteWithoutCode,
          selectedHcc,
        }),
      );
    },
    conditionNotPresent(diagnosisIncorrect) {
      dispatch(conditionNotPresent({ diagnosisIncorrect }));
    },
    conditionNotAssessed(notAssessed) {
      dispatch(conditionNotAssessed({ notAssessed }));
    },
    selectDiagnosis(diagnosis) {
      dispatch(selectDiagnosis({ diagnosis }));
    },
    updateNote(note) {
      dispatch(updateNote({ note }));
    },
    unselectDiagnosis(diagnosis) {
      dispatch(unselectDiagnosis({ diagnosis }));
    },
  };
}
