import styled from 'styled-components';
import { bool, func, object, string } from 'prop-types';
import { Popover, ThemeProvider, Typography } from '@mui/material';

import themeV2 from '@/themeV2';

export default function TransitionOfCarePopover({
  id,
  open,
  anchorEl,
  onClose,
}) {
  return (
    <ThemeProvider theme={themeV2}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onClose}
      >
        <PopoverDiv>
          <Typography variant="headersmallbold">
            Updated due to a more recent discharge event
          </Typography>
          <Typography variant="bodysmall" sx={{ mt: 2 }}>
            This patient has multiple discharge events in the last 30 days.
            These may or may not be related to one another; however, the details
            here have been updated to reflect the latest discharge event.
          </Typography>
          <Typography variant="bodysmallbold" sx={{ mt: 2 }}>
            If this patient is currently scheduled for a followup visit, you may
            wish to reschedule it to account for the latest discharge date.
          </Typography>
        </PopoverDiv>
      </Popover>
    </ThemeProvider>
  );
}

TransitionOfCarePopover.propTypes = {
  id: string,
  open: bool.isRequired,
  anchorEl: object,
  onClose: func.isRequired,
};

TransitionOfCarePopover.defaultProps = {
  id: undefined,
  anchorEl: undefined,
};

const PopoverDiv = styled.div`
  width: 500px;
  margin: 16px;
  p {
    margin-bottom: 8px;
  }
`;
