import { isValid, parseISO } from 'date-fns';
import { useCallback } from 'react';

import useCookie from './useCookie';
import useSetCookie from './useSetCookie';

function parseDate(input) {
  const output = parseISO(input);
  return isValid(output) ? output : null;
}

/**
 * Saves and retrieve a timestamp from a cookie.
 * Useful for storing the time that something last happened.
 *
 * Returns an array. The first element is a Date object (or null),
 * and the second element is a callback to update the timestamp.
 *
 * @param {string} identifier a unique string to be used for the cookie
 */
export default function useTimestampCookie(identifier) {
  if (!identifier) {
    throw new TypeError('Expected `identifier` to be defined.');
  }

  const cookie = useCookie(identifier);
  const timestamp = parseDate(cookie);

  const setCookie = useSetCookie();
  const update = useCallback(() => {
    setCookie(identifier, new Date().toISOString());
  }, [identifier, setCookie]);

  return [timestamp, update];
}
