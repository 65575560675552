/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { Fragment } from 'react';

// The duration of transitions in milliseconds;
const TRANSITION_TIMEOUT = 150;

function DropdownMenu({ dropdownSections }) {
  return (
    <>
      {dropdownSections.map(({ header, items }, index) => (
        <Fragment key={index}>
          {index > 0 ? <Divider /> : null}

          <List
            sx={{ py: 1 }}
            subheader={
              header ? (
                <ListSubheader disableSticky>
                  <Typography
                    variant="bodymediumbold"
                    color="text.primary"
                    py={1}
                  >
                    {header}
                  </Typography>
                </ListSubheader>
              ) : undefined
            }
          >
            {items.map(({ href, label, openInNewTab }) => (
              <ListItem key={label} disablePadding>
                <StyledListItemButton
                  component="a"
                  href={href}
                  target={openInNewTab ? '_blank' : undefined}
                  rel={openInNewTab ? 'noopener noreferrer' : undefined}
                >
                  {label}
                </StyledListItemButton>
              </ListItem>
            ))}
          </List>
        </Fragment>
      ))}
    </>
  );
}
DropdownMenu.propTypes = {
  dropdownSections: PropTypes.array.isRequired,
};

export default function UserDropdown({ userDropdown, sx }) {
  const { userName, medicalGroupName, dropdownSections } = userDropdown;
  return (
    <UserDropdownRoot sx={sx}>
      <StyledTooltip
        title={<DropdownMenu dropdownSections={dropdownSections} />}
        arrow={false}
        placement="bottom-end"
      >
        <Box
          data-pendo-id="navbar-user-dropdown"
          component="button"
          type="button"
          sx={{
            backgroundColor: 'transparent',
            border: 'none',
            px: 1.5,
            py: 1,
            textAlign: 'left',
            transition: `width ${TRANSITION_TIMEOUT}`,
            '&:hover': {
              color: 'text.link',
            },
          }}
        >
          <Typography variant="bodymedium">{userName}</Typography>
          <Typography variant="bodymediumbold">{medicalGroupName}</Typography>
        </Box>
      </StyledTooltip>
    </UserDropdownRoot>
  );
}

UserDropdown.propTypes = {
  sx: PropTypes.object,
  userDropdown: PropTypes.shape({
    userName: PropTypes.string,
    medicalGroupName: PropTypes.string,
    dropdownSections: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};
UserDropdown.defaultProps = {
  sx: undefined,
};

const UserDropdownRoot = styled('div')`
  white-space: nowrap;
  overflow: visible;
`;

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.base,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[2],
    padding: 0,
    maxHeight: '75vh',
    overflow: 'auto',
  },
}));

const StyledListItemButton = styled(ListItemButton)`
  color: ${(p) => p.theme.palette.text.primary};
  transition: color ${TRANSITION_TIMEOUT};

  &:hover {
    color: ${(p) => p.theme.palette.text.link};
    background-color: ${(p) => p.theme.palette.background.primaryLightest};
  }
`;
