import { useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CancelIcon from '@mui/icons-material/Cancel';
import PaidIcon from '@mui/icons-material/Paid';
import EventIcon from '@mui/icons-material/Event';
import ErrorIcon from '@mui/icons-material/Error';
import PropTypes from 'prop-types';

import usePostApproveChallenges from '@ot/hooks/usePostApproveChallenges';
import usePostInvalidateChallenges from '@ot/hooks/usePostInvalidateChallenges';
import usePostUpdateChallenges from '@ot/hooks/usePostUpdateChallenges';
import ErrorPopover from '@ot/pages/Challenges/ChallengesView/components/ErrorPopover';
import SnackbarNotification from '@ot/pages/Challenges/ChallengesView/components/SnackbarNotification';
import ChallengeActionBarButton from '@ot/pages/Challenges/ChallengesView/components/ChallengeActionBarButton';
import ChallengeActionBarEditGoal from '@ot/pages/Challenges/ChallengesView/components/ChallengeActionBarEditGoal';
import ErrorMessages from '@ot/pages/Challenges/ChallengesView/components/ErrorMessages';
import BaseDatePicker from '@/components/BaseDatePicker';

function isNonnegativeInteger(str) {
  if (typeof str !== 'string') {
    return false;
  }

  const num = Number(str);

  return Number.isInteger(num) && num > -1;
}

// Error sources (for choosing which error to display, we only want to display the most recent)
const SOURCE_INVALIDATE = 'invalidate';
const SOURCE_APPROVE = 'approve';
const SOURCE_HIDE = 'hide';
const SOURCE_UNHIDE = 'hide';
const SOURCE_EDIT_SVU = 'edit_svu';
const SOURCE_EDIT_GOAL = 'edit_goal';
const SOURCE_EDIT_END_DATE = 'edit_end_date';

function ChallengesActionBar({
  selectedChallengeIds,
  clearSelectedChallengeIds,
  allowApprove,
}) {
  const [SVU, setSVU] = useState('');
  const [SVUInputErrorMessage, setSVUInputErrorMessage] = useState(null);
  const [endDate, setEndDate] = useState(undefined);

  const {
    mutate: mutateApproveChallenges,
    data: failedMessagesApprove,
    isLoading: isLoadingApproveChallenges,
    isSuccess: isSuccessApproveChallenges,
  } = usePostApproveChallenges();

  const {
    mutate: mutateInvalidateChallenges,
    data: failedMessagesInvalidate,
    isLoading: isLoadingInvalidateChallenges,
    isSuccess: isSuccessInvalidateChallenges,
  } = usePostInvalidateChallenges();

  const {
    mutate: mutateHideChallenges,
    data: failedMessagesHide,
    isLoading: isLoadingHideChallenges,
    isSuccess: isSuccessHideChallenges,
  } = usePostUpdateChallenges();

  const {
    mutate: mutateUnhideChallenges,
    data: failedMessagesUnhide,
    isLoading: isLoadingUnhideChallenges,
    isSuccess: isSuccessUnhideChallenges,
  } = usePostUpdateChallenges();

  const {
    mutate: mutateEditSVUChallenges,
    data: failedMessagesEditSVU,
    isLoading: isLoadingEditSVUChallenges,
    isSuccess: isSuccessEditSVUChallenges,
  } = usePostUpdateChallenges();

  const {
    mutate: mutateEditGoalChallenges,
    data: failedMessagesEditGoal,
    isLoading: isLoadingEditGoalChallenges,
    isSuccess: isSuccessEditGoalChallenges,
  } = usePostUpdateChallenges();

  const {
    mutate: mutateEditEndDateChallenges,
    data: failedMessagesEditEndDate,
    isLoading: isLoadingEditEndDateChallenges,
    isSuccess: isSuccessEditEndDateChallenges,
  } = usePostUpdateChallenges();

  const [mostRecentErrorSource, setMostRecentErrorSource] = useState('');

  //= =================Confirmation dialog stuff========
  const [openInvalidateConfirmation, setOpenInvalidateConfirmation] =
    useState(false);

  const handleCloseInvalidateConfirmation = () => {
    setOpenInvalidateConfirmation(false);
  };

  const handleCloseInvalidateConfirmationConfirm = () => {
    setOpenInvalidateConfirmation(false);

    const invalidatePayload = { invalidate: true };
    mutateInvalidateChallenges({
      challengeIdList: selectedChallengeIds,
      challengeUpdates: invalidatePayload,
    });
    setMostRecentErrorSource(SOURCE_INVALIDATE);

    clearSelectedChallengeIds();
  };

  const handleCloseInvalidateConfirmationCancel = () => {
    setOpenInvalidateConfirmation(false);
  };
  //= ==================================================

  const handleButtonClickApprove = () => {
    const approvePayload = { approve: true };
    mutateApproveChallenges({
      challengeIdList: selectedChallengeIds,
      challengeUpdates: approvePayload,
    });
    setMostRecentErrorSource(SOURCE_APPROVE);

    clearSelectedChallengeIds();
  };

  const handleButtonClickHide = () => {
    const hiddenPayload = { hidden: true };
    mutateHideChallenges({
      challengeIdList: selectedChallengeIds,
      challengeUpdates: hiddenPayload,
    });
    setMostRecentErrorSource(SOURCE_HIDE);

    clearSelectedChallengeIds();
  };

  const handleButtonClickUnhide = () => {
    const hiddenPayload = { hidden: false };
    mutateUnhideChallenges({
      challengeIdList: selectedChallengeIds,
      challengeUpdates: hiddenPayload,
    });
    setMostRecentErrorSource(SOURCE_UNHIDE);

    clearSelectedChallengeIds();
  };

  const handleButtonClickInvalidate = () => {
    setOpenInvalidateConfirmation(true);
  };

  //= ==============Popover stuff===============
  const [anchorElSVU, setAnchorElSVU] = useState(null);
  const [anchorElEndDate, setAnchorElEndDate] = useState(null);

  function closePopover(setAnchor) {
    setAnchor(false);
  }
  //= ==========================================

  const handleSVUChange = (event) => {
    setSVUInputErrorMessage(null);

    setSVU(event.target.value);
  };

  const handleChallengesEditSVU = (event) => {
    if (!SVU || !isNonnegativeInteger(SVU)) {
      setAnchorElSVU(event.currentTarget);

      setSVUInputErrorMessage('Integer must be 0 or greater');

      return;
    }

    const svuPayload = { svu_award: SVU };
    mutateEditSVUChallenges({
      challengeIdList: selectedChallengeIds,
      challengeUpdates: svuPayload,
    });
    setMostRecentErrorSource(SOURCE_EDIT_SVU);

    setSVU('');
    clearSelectedChallengeIds();
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleChallengesEditEndDate = (event) => {
    if (!endDate) {
      setAnchorElEndDate(event.currentTarget);
      return;
    }

    const endDatePayload = { end_date: endDate };
    mutateEditEndDateChallenges({
      challengeIdList: selectedChallengeIds,
      challengeUpdates: endDatePayload,
    });
    setMostRecentErrorSource(SOURCE_EDIT_END_DATE);

    setEndDate(undefined);
    clearSelectedChallengeIds();
  };

  const isAnythingLoading =
    isLoadingApproveChallenges ||
    isLoadingInvalidateChallenges ||
    isLoadingHideChallenges ||
    isLoadingUnhideChallenges ||
    isLoadingEditSVUChallenges ||
    isLoadingEditEndDateChallenges;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={3} sx={{ bgcolor: 'chocolate' }}>
        <Toolbar variant="dense" sx={{ flexWrap: 'wrap' }}>
          <ChallengeActionBarButton
            label="Approve"
            icon={<StyledCheckCircleIcon />}
            handleButtonClick={handleButtonClickApprove}
            disabled={
              !allowApprove ||
              isAnythingLoading ||
              selectedChallengeIds.length === 0
            }
            showCircularProgress={
              isLoadingApproveChallenges && !isSuccessApproveChallenges
            }
            data-pendo-id="challenge-action-bar-approve-button"
          />
          <ChallengeActionBarButton
            label="Hide"
            icon={<StyledVisibilityIcon />}
            handleButtonClick={handleButtonClickHide}
            disabled={isAnythingLoading || selectedChallengeIds.length === 0}
            showCircularProgress={
              isLoadingHideChallenges && !isSuccessHideChallenges
            }
            data-pendo-id="challenge-action-bar-hide-button"
          />
          <ChallengeActionBarButton
            label="Unhide"
            icon={<StyledVisibilityOffIcon />}
            handleButtonClick={handleButtonClickUnhide}
            disabled={isAnythingLoading || selectedChallengeIds.length === 0}
            showCircularProgress={
              isLoadingUnhideChallenges && !isSuccessUnhideChallenges
            }
            data-pendo-id="challenge-action-bar-unhide-button"
          />

          <ChallengeActionBarButton
            label="Invalidate"
            icon={<StyledCancelIcon />}
            handleButtonClick={handleButtonClickInvalidate}
            disabled={isAnythingLoading || selectedChallengeIds.length === 0}
            showCircularProgress={
              isLoadingInvalidateChallenges && !isSuccessInvalidateChallenges
            }
            data-pendo-id="challenge-action-bar-invalidate-button"
          />

          <Dialog
            open={openInvalidateConfirmation}
            onClose={handleCloseInvalidateConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Note: Invalidating will set SVU award to 0; set it to hidden;
                set it to expire today; add [IGNORE] to the challenge name.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseInvalidateConfirmationCancel}
                autoFocus
              >
                Cancel
              </Button>
              <Button onClick={handleCloseInvalidateConfirmationConfirm}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <ChallengeActionBarButton
            label="Edit SVU Amount"
            icon={<StyledPaidIcon />}
            handleButtonClick={handleChallengesEditSVU}
            disabled={isAnythingLoading || selectedChallengeIds.length === 0}
            showCircularProgress={
              isLoadingEditSVUChallenges && !isSuccessEditSVUChallenges
            }
            marginLeft={1}
            marginRight={-1}
            data-pendo-id="challenge-action-bar-edit-svu-amount-button"
          />

          <ErrorPopover
            anchorEl={anchorElSVU}
            closePopover={() => closePopover(setAnchorElSVU)}
          >
            <Typography
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <ErrorIcon color="error" fontSize="medium" sx={{ mr: 1 }} />{' '}
              Please use integer of 0 or greater
            </Typography>
          </ErrorPopover>

          <StyleInputField>
            <TextField
              error={!SVU || !isNonnegativeInteger(SVU)}
              helperText={SVUInputErrorMessage}
              type="number"
              value={SVU}
              disabled={isAnythingLoading || selectedChallengeIds.length === 0}
              onChange={handleSVUChange}
              hiddenLabel
              size="small"
              variant="outlined"
              inputProps={{
                style: {
                  height: '10px',
                },
              }}
              sx={{ width: 100 }}
            />
          </StyleInputField>

          <ChallengeActionBarEditGoal
            mutateEditGoalChallenges={mutateEditGoalChallenges}
            isAnythingLoading={isAnythingLoading}
            isLoadingEditGoalChallenges={isLoadingEditGoalChallenges}
            isSuccessEditGoalChallenges={isSuccessEditGoalChallenges}
            selectedChallengeIds={selectedChallengeIds}
            setMostRecentErrorSource={setMostRecentErrorSource}
            clearSelectedChallengeIds={clearSelectedChallengeIds}
            errorSource={SOURCE_EDIT_GOAL}
          />

          <ChallengeActionBarButton
            label="Edit End Date"
            icon={<StyledEventIcon />}
            handleButtonClick={handleChallengesEditEndDate}
            disabled={isAnythingLoading || selectedChallengeIds.length === 0}
            showCircularProgress={
              isLoadingEditEndDateChallenges && !isSuccessEditEndDateChallenges
            }
            marginLeft={1}
            marginRight={-1}
            data-pendo-id="challenge-action-bar-edit-end-date-button"
          />

          <ErrorPopover
            anchorEl={anchorElEndDate}
            closePopover={() => closePopover(setAnchorElEndDate)}
          >
            <Typography
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <ErrorIcon color="error" fontSize="medium" sx={{ mr: 1 }} />{' '}
              Please enter a valid end date that is today or after and not
              before start date
            </Typography>
          </ErrorPopover>

          {isSuccessApproveChallenges && (
            <SnackbarNotification
              openSnackbar={isSuccessApproveChallenges}
              errorCount={
                failedMessagesApprove &&
                Object.keys(failedMessagesApprove).length
              }
            />
          )}
          {isSuccessInvalidateChallenges && (
            <SnackbarNotification
              openSnackbar={isSuccessInvalidateChallenges}
              errorCount={
                failedMessagesInvalidate &&
                Object.keys(failedMessagesInvalidate).length
              }
            />
          )}
          {isSuccessHideChallenges && (
            <SnackbarNotification
              openSnackbar={isSuccessHideChallenges}
              errorCount={
                failedMessagesHide && Object.keys(failedMessagesHide).length
              }
            />
          )}
          {isSuccessUnhideChallenges && (
            <SnackbarNotification
              openSnackbar={isSuccessUnhideChallenges}
              errorCount={
                failedMessagesUnhide && Object.keys(failedMessagesUnhide).length
              }
            />
          )}
          {isSuccessEditSVUChallenges && (
            <SnackbarNotification
              openSnackbar={isSuccessEditSVUChallenges}
              errorCount={
                failedMessagesEditSVU &&
                Object.keys(failedMessagesEditSVU).length
              }
            />
          )}
          {isSuccessEditGoalChallenges && (
            <SnackbarNotification
              openSnackbar={isSuccessEditGoalChallenges}
              errorCount={
                failedMessagesEditGoal &&
                Object.keys(failedMessagesEditGoal).length
              }
            />
          )}
          {isSuccessEditEndDateChallenges && (
            <SnackbarNotification
              openSnackbar={isSuccessEditEndDateChallenges}
              errorCount={
                failedMessagesEditEndDate &&
                Object.keys(failedMessagesEditEndDate).length
              }
            />
          )}

          <StyleInputField>
            <BaseDatePicker
              value={endDate}
              onChange={handleEndDateChange}
              disabled={isAnythingLoading || selectedChallengeIds.length === 0}
              PopperProps={{ style: { zIndex: 2 } }}
            />
          </StyleInputField>
        </Toolbar>
      </AppBar>
      <br />
      {mostRecentErrorSource === SOURCE_APPROVE &&
        !isLoadingApproveChallenges && (
          <ErrorMessages failedMessages={failedMessagesApprove} />
        )}
      {mostRecentErrorSource === SOURCE_INVALIDATE &&
        !isLoadingInvalidateChallenges && (
          <ErrorMessages failedMessages={failedMessagesInvalidate} />
        )}
      {mostRecentErrorSource === SOURCE_HIDE && !isLoadingHideChallenges && (
        <ErrorMessages failedMessages={failedMessagesHide} />
      )}
      {mostRecentErrorSource === SOURCE_UNHIDE &&
        !isLoadingUnhideChallenges && (
          <ErrorMessages failedMessages={failedMessagesUnhide} />
        )}
      {mostRecentErrorSource === SOURCE_EDIT_SVU &&
        !isLoadingEditSVUChallenges && (
          <ErrorMessages failedMessages={failedMessagesEditSVU} />
        )}
      {mostRecentErrorSource === SOURCE_EDIT_GOAL &&
        !isLoadingEditGoalChallenges && (
          <ErrorMessages failedMessages={failedMessagesEditGoal} />
        )}
      {mostRecentErrorSource === SOURCE_EDIT_END_DATE &&
        !isLoadingEditEndDateChallenges && (
          <ErrorMessages failedMessages={failedMessagesEditEndDate} />
        )}
    </Box>
  );
}

const StyleInputField = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.9),
  },

  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 'auto',
  },
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledVisibilityIcon = styled(VisibilityIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledVisibilityOffIcon = styled(VisibilityOffIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledPaidIcon = styled(PaidIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledEventIcon = styled(EventIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export default ChallengesActionBar;

ChallengesActionBar.propTypes = {
  selectedChallengeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  clearSelectedChallengeIds: PropTypes.func,
  allowApprove: PropTypes.bool,
};
ChallengesActionBar.defaultProps = {
  clearSelectedChallengeIds: undefined,
  allowApprove: false,
};
