import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import PropTypes from 'prop-types';

import buildPydanticErrorMessages from '@/utils/buildPydanticErrorMessages';

const INVALID_REQUEST_ERROR = 'invalid_request_error';

export default function ErrorMessage({ errorType, error }) {
  const standardMessage =
    'If you continue to have trouble, reach out to support@stellar.health.';

  return (
    <>
      {errorType === INVALID_REQUEST_ERROR ? (
        buildPydanticErrorMessages(error).map((message) => (
          <ErrorText key={message}>{message}</ErrorText>
        ))
      ) : (
        <ErrorText>{error}</ErrorText>
      )}
      <ErrorText>{standardMessage}</ErrorText>
    </>
  );
}

const ErrorText = styled(Typography)`
  color: ${(p) => p.theme.palette.status.danger};
  text-align: center;
  margin-top: 8px;
`;

ErrorMessage.propTypes = {
  errorType: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ErrorMessage.defaultProps = {
  errorType: undefined,
  error: undefined,
};
