import PropTypes from 'prop-types';
import './odometer.css';
import ReactOdometer from 'react-odometerjs';

function AnimatedNumber({ value }) {
  return <ReactOdometer value={value} format="(,ddd).dd" />;
}

export default AnimatedNumber;

AnimatedNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
