import PropTypes from 'prop-types';
import { Divider, Stack } from '@mui/material';

import BaseModal from '@/components/BaseModal';
import useGetCurrentUserModalStats from '@/widgets/SvuTracker/useGetCurrentUserModalStats';

import SvuMonthlyOverview from './SvuMonthlyOverview';
import SvuHistory from './SvuHistory';

function shouldShowHistory(history) {
  return history?.medicalGroup?.length ?? 0 > 1;
}

export default function SvuModal({ open, onClose }) {
  const { data = {}, isSuccess } = useGetCurrentUserModalStats({
    enabled: open,
  });

  const { currentDate, medicalGroupName, monthlyOverview, history } = data;

  return (
    <BaseModal
      title="Stellar Value Unit Tracker"
      open={open && isSuccess}
      onClose={onClose}
      sx={{
        width: '720px',
        maxWidth: '720px',
        '& .MuiDialogContent-root': {
          pt: 0,
        },
      }}
    >
      <Stack divider={<Divider />} spacing={3}>
        {monthlyOverview && (
          <SvuMonthlyOverview
            monthOverview={monthlyOverview}
            medicalGroupName={medicalGroupName}
            currentDate={currentDate}
          />
        )}
        {shouldShowHistory(history) && (
          <SvuHistory
            medicalGroupHistory={history.medicalGroup}
            userHistory={history.user}
            medicalGroupName={medicalGroupName}
          />
        )}
      </Stack>
    </BaseModal>
  );
}

SvuModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
