import PropTypes from 'prop-types';

function ErrorMessages({ failedMessages }) {
  return (
    /* eslint-disable react/jsx-no-useless-fragment */
    <>
      {failedMessages && Object.keys(failedMessages).length > 0 ? (
        <div>
          <p>
            <b>The following challenges failed to update:</b>
          </p>
          {Object.entries(failedMessages).map(([key, value]) => (
            <p key={key}>
              {key}: {value}
            </p>
          ))}
        </div>
      ) : null}
    </>
  );
}

ErrorMessages.propTypes = {
  failedMessages: PropTypes.objectOf(PropTypes.string),
};

ErrorMessages.defaultProps = {
  failedMessages: undefined,
};

export default ErrorMessages;
