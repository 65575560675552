import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useError } from 'react-use';

import useAxios from '@/hooks/useAxios';
import useCsrfToken from '@/hooks/useCsrfToken';

// Modeled on Tasks/useExecuteStep
async function executeStep({
  axios,
  csrfToken,
  actionId,
  stepId,
  executionInput = undefined,
}) {
  return axios.post(
    '/api/ehr/steps/execute',
    {
      action_id: actionId,
      step_id: stepId,
      execution_input: executionInput,
    },
    {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    },
  );
}

// Modeled heavily on useDiagnosisActionMutator
export default function useNonDiagnosisActionMutator() {
  const axios = useAxios();
  const csrfToken = useCsrfToken();
  const queryClient = useQueryClient();
  const dispatchError = useError();

  return useMutation(
    (actions) => {
      return Promise.all(
        actions.map((action) => {
          return executeStep({
            axios,
            csrfToken,
            actionId: action.actionId,
            stepId: action.stepId,
            executionInput: action.executionInput,
          });
        }),
      );
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(['ehr-summary']);
      },
      onError: (error) => {
        dispatchError(error);
      },
      onSuccess: () => {
        window.stellar?.refreshSvuTracker?.();
      },
    },
  );
}
