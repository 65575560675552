import { DiagnosisActionState } from '@/pages/PatientSummary/utils/constants';

/**
 * Returns the relative diagnosis severity based on the HCC code of the
 * provided action and the hcc of the provided diagnosis. Takes an HCC trumping
 * map, which determines if the diagnosis supersedes, is superseded by, or is
 * equal in severity to the action diagnosis.
 *
 * If the provided diagnosis is unrelated to the action's diagnosis, the
 * function returns null.
 *
 * @param {{ hcc: number }} diagnosis
 * @param {{ hcc: { code: number }}} action
 * @param {Record<string, { supersedes: number[], supersededBy: number[] }>} hccTrumpingMap
 * @returns {'EQUAL_SEVERITY' | 'HIGHER_SEVERITY' | 'LOWER_SEVERITY' | null}
 */
export function relativeDiagnosisSeverity(diagnosis, action, hccTrumpingMap) {
  if (diagnosis.hcc === action.hcc.code) {
    return 'EQUAL_SEVERITY';
  }

  if (!hccTrumpingMap[action.hcc.code]) return null;
  const { supersedes, supersededBy } = hccTrumpingMap[action.hcc.code];

  if (supersededBy.includes(diagnosis.hcc)) {
    return 'HIGHER_SEVERITY';
  }
  if (supersedes.includes(diagnosis.hcc)) {
    return 'LOWER_SEVERITY';
  }

  return null;
}

/**
 * Returns true if the provided diagnosis matches the HCC we are attempting to
 * change the diagnosis to, and also is in the HCC trumping map.
 *
 * @param {{ hcc: number } | null} diagnosis
 * @param {number} overrideHcc
 * @param {{ hcc: { code: number }}} diagnosisAction
 * @param {Record<string, { supersedes: number[], supersededBy: number[] }>} hccTrumpingMap
 * @returns {boolean}
 */
export function isIcdCodeRelevant(
  diagnosis,
  overrideHcc,
  diagnosisAction,
  hccTrumpingMap,
) {
  if (!diagnosis) return false;

  // The newly-selected diagnosis needs to match the HCC of the condition we
  // want to change the diagnosis to. Otherwise, it is not relevant and should
  // not be selected in the UI.
  if (diagnosis.hcc !== overrideHcc) return false;

  // If the newly-selected diagnosis code matches the HCC, check that this
  // diagnosis is in the hccTrumpingMap and return true if it is. Otherwise,
  // return false.
  return Boolean(
    relativeDiagnosisSeverity(diagnosis, diagnosisAction, hccTrumpingMap),
  );
}

/**
 * Returns a readable string showing various important information about a
 * diagnosis. This is adapted from PSv2's function of the same name, but
 * where that private function takes many properties of a diagnosis action,
 * this takes a diagnosis action and finds the properties itself.
 *
 * @param {DiagnosisAction} diagnosisAction
 * @returns {string}
 */
export function getDiagnosisHistorySubtext(diagnosisAction) {
  if (diagnosisAction.mostRecentlyCompletedByName === 'claims') {
    return `Through claims on ${diagnosisAction.mostRecentlyCompletedByDate}`;
  }

  const stateString =
    diagnosisAction.state === DiagnosisActionState.complete
      ? 'Assessed and Present'
      : 'Assessed and Not Present';

  const codesString = diagnosisAction.userAddedDiagnosisCodes.join('; ');

  const vimDetails =
    diagnosisAction.mostRecentExternalCareGapSource &&
    diagnosisAction.mostRecentExternalCareGapDate ===
      diagnosisAction.dateOfService
      ? `through ${diagnosisAction.mostRecentExternalCareGapSource.toUpperCase()} `
      : '';

  const userString =
    diagnosisAction.mostRecentlyCompletedByName === '' &&
    diagnosisAction.mostRecentlyCompletedByDate === ''
      ? `Marked as ${stateString} ${vimDetails}`
      : `Marked as ${stateString} by ${diagnosisAction.mostRecentlyCompletedByName} ${vimDetails}on ${diagnosisAction.mostRecentlyCompletedByDate}`;

  if (!codesString) {
    return userString;
  }

  if (diagnosisAction.userAddedDiagnosisCodes.length === 1) {
    return userString.concat(` with code ${codesString}`);
  }

  return userString.concat(` with codes ${codesString}`);
}

/**
 * Returns the most recent servicing provider and date for a diagnosis action,
 * if those properties are set.
 *
 * @param {DiagnosisAction} diagnosisAction
 * @returns {null|string}
 */
export function getMostRecentServiceProvider(diagnosisAction) {
  if (diagnosisAction.mostRecentProviderForDisplay) {
    if (diagnosisAction.date) {
      return `Most recent service provider: ${diagnosisAction.mostRecentProviderForDisplay} on ${diagnosisAction.date}`;
    }
    return `Most recent service provider: ${diagnosisAction.mostRecentProviderForDisplay}`;
  }
  return null;
}
