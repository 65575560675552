/**
 * A translation map of product and designed status label for the Patient
 *   Summary extrapolated from the next preferred step of an action.
 *
 * Key: Template Step Name
 * Value: New extrapolated status based on next preferred step
 */
const INCOMPLETE = 'Incomplete';
const SCHEDULED = 'Scheduled';
const statusTranslations = {
  schedule_visit: 'Not Scheduled',
  complete_visit: SCHEDULED,
  schedule_ccs: INCOMPLETE,
  schedule_bcs: INCOMPLETE,
  schedule_chl: INCOMPLETE,
  schedule_colo: INCOMPLETE,
  schedule_dee: INCOMPLETE,
  schedule_osteo: INCOMPLETE,
  complete_ccs: SCHEDULED,
  complete_bcs: SCHEDULED,
  complete_chl: SCHEDULED,
  complete_colo: SCHEDULED,
  complete_dee: SCHEDULED,
  complete_osteo: SCHEDULED,
};
/**
 * A translation map of product and designed status label for the Patient
 *   Summary extrapolated from the next preferred step of an action for
 *   when the latest completed StepHistory is a system step. Note the
 *   mapping will not override the default "Not Started" status for actions
 *   in node A.
 *
 * Key: Template Step Name
 * Value: New extrapolated status based on next preferred step
 */
const lastStepHistorySystemCompletedStatusTranslations = {
  follow_up: 'Follow-up needed',
  enter_reading: 'Documentation needed',
};
/**
 * A translation map of product and designed status label for the Patient
 *   Summary extrapolated from the next preferred step of an action.
 *
 * Key: Step ID
 * Value: New extrapolated status based on next preferred step
 */
const NOT_REPORTED = 'Not reported';
const CONTROLLED = 'Controlled';
const UNCONTROLLED = 'Uncontrolled';
const NOT_STARTED = 'Not Started';
const preferredNextStepIdStatusTranslations = {
  'controlling_high_blood_pressure-base-1-enter_reading-1': NOT_REPORTED,
  'controlling_high_blood_pressure-base-1-enter_reading-2': UNCONTROLLED,
  'controlling_high_blood_pressure-base-1-bill_claim-5': CONTROLLED,
  'glycemic_status_patients_with_diabetes-base-1-enter_reading-1': NOT_REPORTED,
  'glycemic_status_patients_with_diabetes-base-1-enter_reading-2': UNCONTROLLED,
  'glycemic_status_patients_with_diabetes-base-1-bill_claim-6': CONTROLLED,
  'peds_nutrition_counseling-base-1-complete_visit-1': NOT_STARTED,
  'coa_functional_status_assessment-base-1-complete-1': INCOMPLETE,
  'coa_medication_review-base-1-complete-1': INCOMPLETE,
};
/**
 * The Patient Summary page requires the displaying of
 * an actions state to remain consistent across
 * Legacy V1 Actions and V2 Step-Based Actions. Since the
 * notion of a status is deprecated with Step-Based Actions
 * we must guess at the status given the next preferred step.
 *
 * statusTranslations gives us a mapping of next preferred step
 * to status. If no mapping exists, we fall back to the last step
 * executed by a user (not system) from the stepHistories.
 *
 * Finally, we default to 'Not Started' if no status can be
 * discerned.
 *
 * @param preferredPath
 * @param stepHistories
 * @returns {string|*}
 */
export default function generateStatusFromAction(preferredPath, stepHistories) {
  const nextPreferredStep = preferredPath[0];
  const lastStepHistory = stepHistories[stepHistories.length - 1];

  const userCompletedStepHistories = stepHistories.filter(
    (stepHistory) => stepHistory.initiator === 'user',
  );

  const lastUserCompletedStepHistory =
    userCompletedStepHistories[userCompletedStepHistories.length - 1];

  if (nextPreferredStep?.stepId in preferredNextStepIdStatusTranslations) {
    return preferredNextStepIdStatusTranslations[nextPreferredStep.stepId];
  }
  if (
    lastStepHistory?.initiator === 'system' &&
    nextPreferredStep?.templateStep in
      lastStepHistorySystemCompletedStatusTranslations
  ) {
    return lastStepHistorySystemCompletedStatusTranslations[
      nextPreferredStep.templateStep
    ];
  }
  if (nextPreferredStep?.templateStep in statusTranslations) {
    return statusTranslations[nextPreferredStep.templateStep];
  }
  if (lastUserCompletedStepHistory) {
    return lastUserCompletedStepHistory.description;
  }
  return 'Not Started';
}
