import { actionShape } from '@/utils/transformFrontendAction';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';

export default function PYMSRenewalDueDate({ action }) {
  const { data: contextualInfo, isLoading } = useGetContextualInfo(action);
  return isLoading ? (
    <FieldSkeleton />
  ) : (
    <span style={{ whiteSpace: 'nowrap' }}>
      {contextualInfo.renewalDueDate}
    </span>
  );
}

PYMSRenewalDueDate.propTypes = {
  action: actionShape.isRequired,
};
