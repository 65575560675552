import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

async function fetchCurrentUserModalStatsJson() {
  const response = await axios.get('/svus/current_user_stats_modal_json');
  return response.data;
}

export default function useGetCurrentUserModalStats(options = {}) {
  return useQuery(
    ['current-user-modal-stats'],
    fetchCurrentUserModalStatsJson,
    options,
  );
}
