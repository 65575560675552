import { useController } from 'react-hook-form';
import { Input } from '@mui/material';
import useId from '@mui/utils/useId';

import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';

import DynamicFormLabel from '../DynamicFormLabel';
import FormErrorMessage from '../../FormErrorMessage';

export default function DynamicFormIntegerField({ field }) {
  const { label, name } = field;
  const controller = useController({
    name,
    // You can override this defaultValue by passing defaultValues to DynamicForm.
    defaultValue: '',
    rules: {
      required: field.required ? 'Enter a number' : undefined,
      pattern: {
        value: /^\d+$/,
        message: 'Enter a whole number',
      },
      min: {
        value: field.min,
        message:
          field.min === 0
            ? 'Enter a number greater or equal to zero'
            : `Enter a number greater than ${field.min - 1}`,
      },
      max: {
        value: field.max,
        message: `Enter a number less than ${field.max + 1}`,
      },
    },
  });
  const fieldId = useId();

  return (
    <>
      <DynamicFormLabel htmlFor={fieldId}>{label}</DynamicFormLabel>
      {/* For usability reasons, don't use type="number" on the input */}
      {/* https://mui.com/material-ui/react-text-field/#type-quot-number-quot */}
      <Input
        id={fieldId}
        inputProps={{ inputMode: 'numeric' }}
        sx={{ width: 80 }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controller.field}
      />
      <FormErrorMessage errors={controller.formState.errors} name={name} />
    </>
  );
}

DynamicFormIntegerField.propTypes = {
  field: executionRequirementShape.isRequired,
};

DynamicFormIntegerField.defaultProps = {};
