import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { patientExperienceRatingCountsShape } from '@/utils/shapes';

const AverageRatingLabel = styled(Typography)`
  font-size: ${(p) => p.theme.typography.bodymedium.fontSize};
`;

const AverageRatingValue = styled(Typography)`
  font-size: ${(p) => p.theme.typography.metric.fontSize};
  color: ${(p) => p.theme.palette.status.infoForeground};
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  padding-right: ${(p) => p.theme.spacing(0.625)};
`;

const BoxCenter = styled(Box)`
  text-align: center;
`;

export default function PatientExperienceRating({
  ratingCounts,
  avgPatientExperienceRating,
}) {
  const theme = useTheme();
  const ratingData = [
    ratingCounts['1'],
    ratingCounts['2'],
    ratingCounts['3'],
    ratingCounts['4'],
    ratingCounts['5'],
    ratingCounts.NA,
  ];
  const labels = [
    'Terrible — 1',
    'Poor — 2',
    'Neutral — 3',
    'Good — 4',
    'Excellent — 5',
    'No response',
  ];
  const ratingBarColor = theme.palette.status.infoForeground;
  const ratingDatalabelColor = theme.palette.status.infoForeground;
  const noResponseBarColor = theme.palette.status.noneBackgroundHover;
  const noResponseDatalabelColor = theme.palette.status.noneForeground;
  const barColors = new Array(5)
    .fill(ratingBarColor)
    .concat([noResponseBarColor]);
  const chartData = {
    labels,
    datasets: [
      {
        axis: 'y',
        label: null,
        data: ratingData,
        backgroundColor: barColors,
        borderRadius: theme.shape.borderRadius * 0.5,
        borderSkipped: false,
        barThickness: 12,
      },
    ],
  };
  const chartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 20,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          drawBorder: false,
          lineWidth: 0,
        },
      },
      y: {
        grid: {
          drawBorder: false,
          lineWidth: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        color(context) {
          if (context.dataIndex < labels.length - 1) {
            return ratingDatalabelColor;
          }
          return noResponseDatalabelColor;
        },
        font: {
          weight: theme.typography.fontWeightBold,
        },
        anchor: 'end',
        align: 'end',
      },
    },
  };
  return (
    <Stack
      width="100%"
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <BoxCenter paddingX={2} paddingY={2}>
        <Typography fontWeight="bold">Patient experience rating</Typography>
      </BoxCenter>
      <Box>
        <AverageRatingValue component="span">
          {avgPatientExperienceRating
            ? avgPatientExperienceRating.toFixed(1)
            : '--'}
        </AverageRatingValue>
        <AverageRatingLabel component="span">
          average patient experience rating
        </AverageRatingLabel>
      </Box>
      <Box height={180}>
        <Bar options={chartOptions} data={chartData} />
      </Box>
    </Stack>
  );
}

PatientExperienceRating.propTypes = {
  ratingCounts: patientExperienceRatingCountsShape,
  avgPatientExperienceRating: PropTypes.number,
};

PatientExperienceRating.defaultProps = {
  ratingCounts: undefined,
  avgPatientExperienceRating: undefined,
};
