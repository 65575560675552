import orderBy from 'lodash/orderBy';
import { isValid, parse } from 'date-fns';

import camelCaseify from '@/pages/PatientSummary/utils/camelCaseify';

function transformGroupClaim(groupClaim) {
  return camelCaseify(groupClaim, {
    claim_rows: (claims) => {
      return claims.map((claim) =>
        camelCaseify(claim, {
          diagnoses: (diagnoses) => {
            return diagnoses.map((dx) => camelCaseify(dx));
          },
        }),
      );
    },
    most_recent_diagnoses: (diagnoses) => {
      return diagnoses.map((dx) => camelCaseify(dx));
    },
  });
}

function transformOfficeVisit(officeVisit) {
  return camelCaseify(officeVisit, {
    most_recent_diagnoses: (diagnoses) => {
      return diagnoses.map((dx) => camelCaseify(dx));
    },
    other_visits_to_this_provider: (otherVisits) => {
      return otherVisits.map((otherVisit) =>
        camelCaseify(otherVisit, {
          diagnoses: (diagnoses) => {
            return diagnoses.map((dx) => camelCaseify(dx));
          },
        }),
      );
    },
  });
}

function extractDateField(fieldName) {
  // eslint-disable-next-line func-names
  return function (sortObject) {
    const parsedDateValue = parse(
      sortObject[fieldName],
      'yyyy-MM-dd',
      new Date(),
    );
    if (isValid(parsedDateValue)) {
      return parsedDateValue;
    }
    return new Date(-8640000000000000); // return minimum date to make invalid dates appear at the end
  };
}

function extractHccName(dx) {
  const { hcc } = dx;
  if (hcc) {
    return hcc.name;
  }
  return null;
}

function extractProviderName(officeVisit) {
  const { provider } = officeVisit;
  if (provider) {
    return provider.name;
  }
  return null;
}

function transformEvidence(evidence) {
  return camelCaseify(evidence, {
    // Order Inpatient ER Visits by groupThruDate(desc), then groupFromDate(desc), then facilityName(asc)
    inpatient_er_visits: (groupClaims) => {
      return orderBy(
        groupClaims.map((groupClaim) => transformGroupClaim(groupClaim)),
        [
          extractDateField('groupThruDate'),
          extractDateField('groupFromDate'),
          'facilityName',
        ],
        ['desc', 'desc', 'asc'],
      );
    },
    // Order DX History by diagnosisDate(desc), then HCC Name(asc)
    dx_history: (diagnoses) => {
      return orderBy(
        diagnoses.map((dx) => camelCaseify(dx)),
        [extractDateField('diagnosisDate'), extractHccName],
        ['desc', 'asc'],
      );
    },
    // Order Office visits by fromDate(desc), then provider name(asc)
    office_visits: (officeVisits) => {
      return orderBy(
        officeVisits.map((officeVisit) => transformOfficeVisit(officeVisit)),
        [extractDateField('fromDate'), extractProviderName],
        ['desc', 'asc'],
      );
    },
  });
}

export default function transformVisitsAndDxHistory(data) {
  const isMedicalHistoryEnabled = data.is_medical_history_enabled ?? false;
  const ppvCadence = data.ppv_cadence;
  const evidence = data.evidence ?? {};
  const risks = data.risks ?? [];
  const stats = data?.stats ? camelCaseify(data.stats) : undefined;
  return {
    isMedicalHistoryEnabled,
    ppvCadence,
    evidence: transformEvidence(evidence),
    risks,
    stats,
  };
}
