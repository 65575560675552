import { Box, ButtonBase, Typography } from '@mui/material';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';

import { nestedChallengesShape } from '../hooks/useGetStampCardChallenges';
import {
  COMPLETED,
  IN_PROGRESS,
  PENDING_REVIEW,
  SvuContainer,
  getTextColorForStatus,
} from '../utils/utils';

import { ReactComponent as CheckPending } from './stampCard/checkPending.svg';
import { ReactComponent as CheckSuccess } from './stampCard/checkSuccess.svg';
import { ReactComponent as Plus } from './stampCard/plus.svg';

export default function StampCardTile({ nestedChallenge, openModal }) {
  const isInProgress = nestedChallenge.status === IN_PROGRESS;
  const canNavigateToUrl = isInProgress && nestedChallenge.url;
  const MaybeButton = canNavigateToUrl ? ButtonBase : Box;
  const maybeButtonProps = canNavigateToUrl
    ? {
        disableRipple: true,
        onClick: () => {
          document.location.assign(nestedChallenge.url);
        },
        'data-pendo-id': nestedChallenge.pendoId,
      }
    : {};

  return (
    <MaybeButton
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...maybeButtonProps}
      component="div"
      sx={{ display: 'block' }}
    >
      <Tile
        $canNavigateToUrl={canNavigateToUrl}
        $status={nestedChallenge.status}
      >
        <TileHeader>
          {nestedChallenge.svuAward > 0 ? (
            <SvuContainer $status={nestedChallenge.status}>
              <Typography variant="label" component="span">
                {nestedChallenge.svuAward} SVU
              </Typography>
            </SvuContainer>
          ) : (
            <>&nbsp;</>
          )}
        </TileHeader>
        <TileBody>
          <TileChallengeDetails>
            {(() => {
              if (nestedChallenge.status === COMPLETED) {
                return <CheckSuccess />;
              }
              if (nestedChallenge.status === PENDING_REVIEW) {
                return <CheckPending />;
              }
              return <Plus />;
            })()}
            <Typography
              variant="metricmediumbold"
              color={getTextColorForStatus(nestedChallenge.status)}
            >
              {nestedChallenge.status === COMPLETED ||
              nestedChallenge.status === PENDING_REVIEW
                ? `Done${
                    nestedChallenge.status === PENDING_REVIEW
                      ? ' (in review)'
                      : '!'
                  }`
                : `${nestedChallenge.progress} / ${nestedChallenge.goal}`}
            </Typography>
            <TileChallengeNameAndDescription>
              <Typography
                variant="bodylargebold"
                color={getTextColorForStatus(nestedChallenge.status)}
              >
                {nestedChallenge.name}
              </Typography>
              {nestedChallenge.description && (
                <ButtonBase
                  onClick={(e) => openModal(e, nestedChallenge)}
                  data-pendo-id="stamp-card-challenge-button-open-modal"
                >
                  <StyledInfoIcon $status={nestedChallenge.status} />
                </ButtonBase>
              )}
            </TileChallengeNameAndDescription>
          </TileChallengeDetails>
        </TileBody>
      </Tile>
    </MaybeButton>
  );
}

StampCardTile.propTypes = {
  nestedChallenge: nestedChallengesShape.isRequired,
  openModal: PropTypes.func.isRequired,
};

const StyledInfoIcon = styled(InfoIcon)`
  color: ${(p) => {
    if (p.$status === COMPLETED) {
      return p.theme.palette.status.successForeground;
    }
    if (p.$status === PENDING_REVIEW) {
      return p.theme.palette.status.infoForeground;
    }
    return p.theme.palette.icon.secondary;
  }};
`;

const Tile = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(p) => p.theme.spacing(1)};
  height: 211px;

  background: ${(p) => {
    if (p.$status === COMPLETED) {
      return p.theme.palette.status.successBackground;
    }
    if (p.$status === PENDING_REVIEW) {
      return p.theme.palette.status.infoBackground;
    }
    return p.theme.palette.background.base;
  }};

  border: 2px solid
    ${(p) => {
      if (p.$status === COMPLETED) {
        return p.theme.palette.status.successForeground;
      }
      if (p.$status === PENDING_REVIEW) {
        return p.theme.palette.status.infoForeground;
      }
      return p.theme.palette.border.base;
    }};

  border-radius: ${(p) => p.theme.shape.borderRadius * 2}px;

  ${(p) =>
    p.$canNavigateToUrl
      ? `
      &:hover,
      &:focus {
        background-color: ${p.theme.palette.background.primaryLightest};
        border-color: ${p.theme.palette.border.primaryActive};
      }
      &:active {
        background-color: ${p.theme.palette.background.secondary};
        border-color: ${p.theme.palette.border.primaryActive};
      }`
      : ''};
`;

const TileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  height: 32px;

  align-self: stretch;
`;

const TileBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(p) => p.theme.spacing(2)};
`;

const TileChallengeDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(p) => p.theme.spacing(1)};
`;
const TileChallengeNameAndDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${(p) => p.theme.spacing(0.75)};
`;
