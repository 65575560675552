import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

async function fetchMedicalGroups(query, tinOnly = false) {
  const params = { query, panel_counts: 1, tin_only: tinOnly };
  const response = await axios.get('/ops_tools/api/medical_groups', { params });
  return response.data;
}

export default function useGetMedicalGroups(query, tinOnly = false) {
  const queryKey = ['file-management-medical-groups', query];
  const enabled = query?.length > 1;

  return useQuery(queryKey, () => fetchMedicalGroups(query, tinOnly), {
    enabled,
  });
}
