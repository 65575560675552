import { Box, Button, Stack, Typography, styled } from '@mui/material';

import {
  patientExperienceDetailsDataShape,
  userInputTaskShape,
} from '@/utils/shapes';
import { useQueryParameters } from '@/contexts/QueryParametersContext';

import PatientExperienceRating from './PatientExperienceRating';
import PatientExperienceHelpTaskCompletion from './PatientExperienceHelpTaskCompletion';

export const CONTACT_PATIENTS_BUTTON_TEXT = 'Contact patients';
export const HELP_PATIENTS_BUTTON_TEXT = 'Help patients';

const CONTACT_PATIENTS_TEMPLATE_STEP = 'visit_follow_up_complete';
const HELP_PATIENTS_TEMPLATE_STEP = 'complete_px_helper';

const BoxCenter = styled(Box)`
  text-align: center;
`;

const BannerBackgroundNoPadding = styled('div')`
  background-color: ${(p) => p.theme.palette.background.base};
  border: 1px solid ${(p) => p.theme.palette.border.base};
  border-radius: ${(p) => 2 * p.theme.shape.borderRadius}px;
`;

const Section = styled(Box)`
  width: 816px;
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

const CaptionSection = styled(Box)`
  padding: ${(p) => p.theme.spacing(1, 2)};
  background-color: ${(p) => p.theme.palette.background.tableZebra};
  border-radius: ${(p) => 2 * p.theme.shape.borderRadius}px;
`;

export default function PatientExperienceDetails({ firstTask, detailsData }) {
  const { mergeParameters } = useQueryParameters();
  const handleNextStep = (preferredNextStep) => {
    mergeParameters({
      preferredNextStep,
      openFirstTask: true,
      // Pass this parameter to view the first patient on the task panel instead of previously selected patient
      openFirstPatient: true,
    });
  };
  const {
    label,
    avgPatientExperienceRating,
    patientExperienceRatingCounts,
    totalHelperCoordCare,
    totalHelperCoordMeds,
    completedHelperCoordCare,
    completedHelperCoordMeds,
  } = detailsData;
  return (
    <Section>
      <BoxCenter>
        <Typography fontWeight="bold" marginBottom={2}>
          {label}
        </Typography>
      </BoxCenter>
      <Stack direction="row" spacing={2} width="100%" justifyContent="center">
        <BannerBackgroundNoPadding>
          <PatientExperienceRating
            avgPatientExperienceRating={avgPatientExperienceRating}
            ratingCounts={patientExperienceRatingCounts}
          />
          <CaptionSection>
            <Typography fontSize={14}>
              Take action – contact patients to complete their post visit
              follow-up
            </Typography>
            <BoxCenter margin={1}>
              <Button
                variant="secondary"
                size="small"
                onClick={() => handleNextStep(CONTACT_PATIENTS_TEMPLATE_STEP)}
                disabled={!firstTask}
                data-pendo-id="px-banner-contact-patient"
              >
                {CONTACT_PATIENTS_BUTTON_TEXT}
              </Button>
            </BoxCenter>
          </CaptionSection>
        </BannerBackgroundNoPadding>
        <BannerBackgroundNoPadding>
          <PatientExperienceHelpTaskCompletion
            totalHelperCoordCare={totalHelperCoordCare}
            completedHelperCoordCare={completedHelperCoordCare}
            totalHelperCoordMeds={totalHelperCoordMeds}
            completedHelperCoordMeds={completedHelperCoordMeds}
          />
          <CaptionSection>
            <Typography fontSize={14}>
              Take action – help patients with care coordination and medications
            </Typography>
            <BoxCenter margin={1}>
              <Button
                variant="secondary"
                size="small"
                onClick={() => handleNextStep(HELP_PATIENTS_TEMPLATE_STEP)}
                disabled={!firstTask}
                data-pendo-id="px-banner-contact-patient"
              >
                {HELP_PATIENTS_BUTTON_TEXT}
              </Button>
            </BoxCenter>
          </CaptionSection>
        </BannerBackgroundNoPadding>
      </Stack>
    </Section>
  );
}

PatientExperienceDetails.propTypes = {
  firstTask: userInputTaskShape,
  detailsData: patientExperienceDetailsDataShape,
};

PatientExperienceDetails.defaultProps = {
  firstTask: undefined,
  detailsData: undefined,
};
