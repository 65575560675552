import PropTypes from 'prop-types';
import {
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  selectClasses,
} from '@mui/material';
import useId from '@mui/utils/useId';
import { forwardRef, useMemo } from 'react';
import { visuallyHidden } from '@mui/utils';
import styled from 'styled-components';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/chevronDown.svg';

const BaseSelect = forwardRef(
  (
    {
      className,
      'data-dd-synthetics-id': dataDdSyntheticsId,
      'data-pendo-id': dataPendoId,
      disabled,
      fullWidth,
      id,
      includeNoneOption,
      inputProps,
      label,
      labelId: labelIdProp,
      multiple,
      noneOption,
      onChange,
      options: optionsProp,
      size,
      sx,
      value,
    },
    ref,
  ) => {
    const labelId = useId();
    const options = useMemo(
      () =>
        optionsProp.map((option) =>
          typeof option === 'string'
            ? { value: option, label: option }
            : option,
        ),
      [optionsProp],
    );

    const shouldIncludeNone =
      typeof noneOption === 'string' || // noneOption is a string, indicating it should be there
      noneOption === true || // noneOption is true, so use default text
      includeNoneOption; // previous flag, includeNoneOption is truthy

    // Use NBSP if noneOption is an empty string, this resolves some formatting issues:
    const noneOptionText = noneOption === '' ? ' ' : noneOption || 'None';
    const noneOptionElement = shouldIncludeNone ? (
      <MenuItem value="" disableRipple>
        <em>{noneOptionText}</em>
      </MenuItem>
    ) : null;

    return (
      <>
        {label && !labelIdProp && (
          <InputLabel id={labelId} sx={visuallyHidden}>
            {label}
          </InputLabel>
        )}

        <Select
          className={className}
          displayEmpty
          labelId={labelIdProp || labelId}
          // eslint-disable-next-line no-use-before-define
          IconComponent={StyledChevronDownIcon}
          input={<StyledInputBase />}
          onChange={onChange}
          value={value}
          multiple={multiple}
          sx={sx}
          size={size}
          disabled={disabled}
          id={id}
          ref={ref}
          inputProps={inputProps}
          SelectDisplayProps={{
            'data-pendo-id': dataPendoId,
            'data-dd-synthetics-id': dataDdSyntheticsId,
          }}
          fullWidth={fullWidth}
        >
          {noneOptionElement}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} disableRipple>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  },
);

const optionsShape = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
);

BaseSelect.propTypes = {
  className: PropTypes.string,
  'data-dd-synthetics-id': PropTypes.string,
  'data-pendo-id': PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  includeNoneOption: PropTypes.bool,
  inputProps: PropTypes.shape({ 'data-testid': PropTypes.string }),
  label: PropTypes.string,
  labelId: PropTypes.string,
  multiple: PropTypes.bool,
  noneOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
  // An array of either strings or objects with shape { value, label }.
  options: optionsShape.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    optionsShape,
  ]),
};

BaseSelect.defaultProps = {
  className: undefined,
  'data-dd-synthetics-id': undefined,
  'data-pendo-id': undefined,
  disabled: false,
  fullWidth: false,
  id: undefined,
  includeNoneOption: false,
  inputProps: undefined,
  label: undefined,
  labelId: undefined,
  multiple: false,
  noneOption: undefined,
  onChange: undefined,
  size: undefined,
  sx: undefined,
  value: '',
};

export default BaseSelect;

function getPadding(size) {
  switch (size) {
    case 'small':
      return '3px 7px';
    case 'large':
      return '7px 15px';
    default:
      return '3px 11px';
  }
}
function getFontSize(size) {
  switch (size) {
    case 'small':
      return '0.875rem';
    case 'large':
      return '1.125rem';
    default:
      return '1rem';
  }
}
function getLineHeight(size) {
  switch (size) {
    case 'small':
      return '19px';
    case 'large':
      return '25px';
    default:
      return '22px';
  }
}

const StyledInputBase = styled(InputBase)(({ theme, size }) => ({
  [`.${selectClasses.select}`]: {
    lineHeight: getLineHeight(size),
    padding: getPadding(size),
    fontSize: getFontSize(size),
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.border.input}`,
  },
}));

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  height: 16px;
  right: 8px;
  width: 16px;
  color: ${(p) => p.theme.palette.icon.secondary};
`;
