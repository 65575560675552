import { useMemo, useState } from 'react';
import { Box, Stack, ThemeProvider } from '@mui/material';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import { format, parseISO, startOfToday } from 'date-fns';
import produce from 'immer';

import theme from '@/theme';
import { patientShape, reactQueryStatusValues } from '@/utils/shapes';
import { actionShape } from '@/utils/transformFrontendAction';
import {
  BULK_REMOVE,
  appendExecutionRequirements,
  relatedActionShape,
} from '@/utils/relatedActions';
import useFeatureFlag from '@/utils/useFeatureFlag';
import useCsrfToken from '@/hooks/useCsrfToken';
import DynamicForm from '@/components/DynamicForm/DynamicForm';
import DynamicFormFields from '@/components/DynamicForm/DynamicFormFields';
import DynamicFormButtons from '@/components/DynamicForm/DynamicFormButtons';
import getActionExecutionRequirements from '@/pages/PatientSummary/utils/getActionExecutionRequirements';
import getVisibleStepExecutionRequirements, {
  adjustPxExecutionRequirements,
  getHistoricData,
  includeHistoricContextualData,
} from '@/pages/PatientSummary/utils/getVisibleStepExecutionRequirements';
import buildExecutionInput from '@/pages/TaskBasedWorkflow/utils/buildExecutionInput';
import ErrorAlert from '@/pages/TaskBasedWorkflow/components/ErrorAlert';
import { StepExecutionProvider } from '@/pages/TaskBasedWorkflow/components/patientDetailView/stepExecutionPanel/StepExecutionContext';
import DynamicFormFileUpload from '@/components/DynamicForm/fieldComponents/DynamicFormFileUpload';

function fieldComponentMapper(field) {
  switch (field.type) {
    case 'fileupload':
      return DynamicFormFileUpload;
    default:
      // Fall back to the default field component
      return null;
  }
}

/**
 * Returns a date in the format YYYY-MM-DD.
 * @param {Date} date
 */
function formatDate(date) {
  return format(date, 'yyyy-MM-dd');
}

async function getRemovalExecutionInput({ data }) {
  return produce(data, (draft) => {
    Object.entries(draft).forEach(([key, value]) => {
      // Convert date objects to strings.
      if (value instanceof Date) {
        draft[key] = formatDate(value);
      }

      // Convert File objects to strings.
      if (value instanceof File) {
        draft[key] = value.name;
      }
    });
  });
}

function getExecutionInputBuilder(action, data) {
  return action.type === 'chart_upload' && data?.removal_reason
    ? getRemovalExecutionInput
    : buildExecutionInput;
}

// Adapted from PatientSummary/CareGapActionRowV2
export function calculateExecutionRequirements({
  selectedStep,
  visitChecklistV2Enabled,
  action,
  relatedActions,
}) {
  let visibleRequirements =
    getVisibleStepExecutionRequirements(selectedStep) ?? [];
  // Adapted from PatientSummary/CareGapRowModal
  if (
    visitChecklistV2Enabled &&
    [
      'patient_experience_checklist_px',
      'patient_experience_print_handout_px',
    ].includes(action.subtype)
  ) {
    const pendoIdPrefixer = (id) => `${action.subtype}_${id}`;
    visibleRequirements = adjustPxExecutionRequirements(
      visibleRequirements,
      pendoIdPrefixer,
    );
  }
  visibleRequirements = selectedStep?.isRemovalStep
    ? appendExecutionRequirements(
        visibleRequirements,
        relatedActions,
        action.actionId,
      )
    : visibleRequirements;
  return includeHistoricContextualData(
    visibleRequirements,
    {}, // TODO restore this
    getHistoricData(action),
    action.contextualInfo,
  );
}

export function calculateActionsToSave({
  actionId,
  relatedActions,
  stepId,
  providerId,
  executionData,
  executionInput,
}) {
  // Adapted from PatientSummary/CareGapActionRowV2
  if (executionData[BULK_REMOVE]) {
    // Find all removal steps and return them, so that they can be closed.
    return (
      relatedActions
        .map((relatedAction) => {
          const steps = relatedAction.availableNextSteps.filter(
            (step) => step.isRemovalStep,
          );
          if (steps.length === 0) return null;
          return {
            actionId: relatedAction.version
              ? relatedAction.actionId
              : relatedAction.id,
            stepId: steps[0].stepId,
            executionInput: {
              pcp_visit_servicing_provider_id: providerId,
              ...executionInput,
            },
          };
        })
        // Filter out nonmatching related actions
        .filter(Boolean)
    );
  }

  // Otherwise just act on the provided action:
  return [
    {
      actionId,
      stepId,
      executionInput: {
        pcp_visit_servicing_provider_id: providerId,
        ...executionInput,
      },
    },
  ];
}

export default function ExecutionRequirementsPanel({
  action,
  defaultProviderChoiceId,
  formState,
  open,
  onSaveNonDiagnosisAction,
  onCancel,
  patient,
  relatedActions,
  saveStatus,
}) {
  const [error, setError] = useState(null);
  const visitChecklistV2Enabled = useFeatureFlag(
    'pxPatientExperienceChecklist',
  );

  const today = startOfToday();
  const selectedStep =
    action.availableNextSteps?.find((s) => s.stepId === open) ||
    open ||
    action.state;

  const [v2ExecutionRequirements, v2DefaultValues] = useMemo(
    () =>
      calculateExecutionRequirements({
        selectedStep,
        visitChecklistV2Enabled,
        action,
        relatedActions,
      }),
    [selectedStep, visitChecklistV2Enabled, action, relatedActions],
  );

  const executionRequirements = action.version
    ? v2ExecutionRequirements
    : getActionExecutionRequirements(action.subtype, open || action.state);

  const defaultValues = action.version ? v2DefaultValues : formState[action.id];
  if (defaultProviderChoiceId) {
    defaultValues.servicing_provider_id = defaultProviderChoiceId;
  }
  const specifyPatientOffice = patient.attributedMgOffice?.id;
  if (specifyPatientOffice && action.type === 'scheduled') {
    defaultValues.office_id = specifyPatientOffice;
  }

  const additionalProps = useMemo(() => {
    return {
      action,
      dateOfService: today,
      patient,
      executionRequirements,
    };
  }, [action, executionRequirements, patient, today]);

  const csrfToken = useCsrfToken();

  // Adapted from Tasks/StepExecutionPanel
  const handleSubmit = async (executionData) => {
    setError(null);
    try {
      // Because we are fetching the data from a psv2 endpoint but submitting it with
      // TBW code paths, we need to transform it slightly here before using the
      // buildExecutionInput from TBW
      const actionTransformed = { ...action, v2Dto: {}, id: action.actionId };
      actionTransformed.v2Dto.contextualInfo = action.contextualInfo;
      if (actionTransformed?.v2Dto?.contextualInfo?.parentDateOfService) {
        actionTransformed.v2Dto.contextualInfo.parentDateOfService = format(
          parseISO(actionTransformed.v2Dto.contextualInfo.parentDateOfService),
          'MM-dd-yyyy',
        );
      }
      const executionInputBuilder = getExecutionInputBuilder(
        actionTransformed,
        executionData,
      );

      const executionInput = await executionInputBuilder({
        action: actionTransformed,
        patient,
        csrfToken,
        currentDate: today,
        data: executionData,
      });

      const actionsToSave = calculateActionsToSave({
        actionId: action.version ? action.actionId : action.id,
        stepId: open,
        relatedActions,
        providerId: patient.provider?.id,
        executionData,
        executionInput,
      });

      await onSaveNonDiagnosisAction(actionsToSave);
    } catch (e) {
      setError(e); // todo: determine error type
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* DynamicForm is not ready for themeV2 yet */}
      <StepExecutionProvider
        action={action}
        patient={patient}
        step={action.version ? selectedStep : undefined}
      >
        {error ? (
          <ErrorAlert
            message="We couldn't confirm your changes"
            handleCloseAlert={() => setError(null)}
          />
        ) : null}
        <DynamicForm
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          sx={{ mt: 2 }}
          mode="onChange"
          key={selectedStep}
        >
          <Stack spacing={2}>
            <DynamicFormFields
              isLoading={saveStatus === 'loading'}
              additionalProps={additionalProps}
              fields={executionRequirements}
              fieldComponentMapper={fieldComponentMapper}
            />

            <Box mt={3}>
              <DynamicFormButtons
                ButtonSx={{
                  mr: 3,
                }}
                isLoading={saveStatus === 'loading'}
                onCancel={onCancel}
                submitButtonPendoId="compact-patient-summary-non-diagnosis-submit"
              />
            </Box>
          </Stack>
        </DynamicForm>
      </StepExecutionProvider>
    </ThemeProvider>
  );
}

ExecutionRequirementsPanel.propTypes = {
  action: actionShape.isRequired,
  defaultProviderChoiceId: number,
  formState: object,
  open: oneOfType([bool, string]).isRequired,
  onCancel: func.isRequired,
  onSaveNonDiagnosisAction: func.isRequired,
  patient: patientShape.isRequired,
  relatedActions: arrayOf(relatedActionShape),
  saveStatus: reactQueryStatusValues.isRequired,
};

ExecutionRequirementsPanel.defaultProps = {
  relatedActions: [],
  formState: {},
  defaultProviderChoiceId: undefined,
};
