import { useState } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, ScopedCssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import defaultTheme from '@/theme';
import TheFontStyles from '@/components/TheFontStyles';
import { FeatureFlagsProvider } from '@/utils/useFeatureFlag';
import { QueryParametersProvider } from '@/contexts/QueryParametersContext';

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

function getQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // Undo React Query's aggressive default settings.
        // Docs: https://react-query.tanstack.com/guides/important-defaults
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        staleTime: twentyFourHoursInMs,
      },
    },
  });
}

/**
 * A component that contains all of our app's Providers.
 */
export default function TheProvider({
  children,
  featureFlags,
  scopedCss,
  theme,
}) {
  const [queryClient] = useState(getQueryClient);

  const contents = scopedCss ? (
    <ScopedCssBaseline>{children}</ScopedCssBaseline>
  ) : (
    <>
      <CssBaseline />
      <TheFontStyles />
      {children}
    </>
  );

  return (
    <FeatureFlagsProvider value={featureFlags}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryParametersProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>{contents}</ThemeProvider>
          </QueryClientProvider>
        </QueryParametersProvider>
      </LocalizationProvider>
    </FeatureFlagsProvider>
  );
}

TheProvider.propTypes = {
  children: PropTypes.node.isRequired,
  featureFlags: PropTypes.object,
  scopedCss: PropTypes.bool,
  theme: PropTypes.object,
};

TheProvider.defaultProps = {
  featureFlags: undefined,
  scopedCss: false,
  theme: defaultTheme,
};
