import PropTypes from 'prop-types';
import { Accordion } from '@mui/material';

export default function BaseAccordion({
  sx,
  children,
  defaultExpanded,
  expanded,
  onChange,
}) {
  return (
    <Accordion
      sx={{
        border: '1px solid',
        borderColor: 'border.base',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)',
        borderRadius: '8px',
        '&:before': {
          display: 'none',
        },
        ...sx,
      }}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={onChange}
    >
      {children}
    </Accordion>
  );
}

BaseAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  defaultExpanded: PropTypes.bool,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
};
BaseAccordion.defaultProps = {
  sx: undefined,
  defaultExpanded: undefined,
  expanded: undefined,
  onChange: undefined,
};
