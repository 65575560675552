import { Box, Stack } from '@mui/material';
import { bool } from 'prop-types';
import { useMemo } from 'react';

import { actionShape } from '@/utils/transformFrontendAction';
import { getEarnedSvus } from '@/components/CompactPatientSummary/hooks/useSvuTotals';

export default function SvuCell({ action, complete }) {
  const svusBeforePromo = action.version
    ? Number(action.possibleSvusRemainingPrePromo)
    : Number(action.svusBeforePromo);
  const svusAfterPromo = action.version
    ? Number(action.possibleSvusRemainingPostPromo)
    : Number(action.svusAfterPromo);

  const svusEarned = useMemo(() => {
    return getEarnedSvus(action);
  }, [action]);

  return complete ? (
    <Stack
      height="fit-content"
      direction="row"
      gap={0.5}
      sx={{
        backgroundColor: 'status.successBackground',
        color: 'status.successForeground',
        borderRadius: 1,
        px: 0.5,
        whiteSpace: 'nowrap',
        mt: 1,
      }}
    >
      <>
        <Box width="50%"> </Box>
        <Box>{svusEarned}</Box>
      </>
    </Stack>
  ) : (
    <Stack
      height="fit-content"
      direction="row"
      gap={0.5}
      sx={{
        backgroundColor: 'svu.lightest',
        color: 'svu.dark',
        borderRadius: 1,
        px: 0.5,
        whiteSpace: 'nowrap',
        mt: 1,
      }}
    >
      {svusAfterPromo > svusBeforePromo ? (
        <>
          <Box width="50%" sx={{ textDecoration: 'line-through' }}>
            {svusBeforePromo}
          </Box>
          <Box>{svusAfterPromo}</Box>
        </>
      ) : (
        <>
          <Box width="50%"> </Box>
          <Box>{svusBeforePromo}</Box>
        </>
      )}
    </Stack>
  );
}

SvuCell.propTypes = {
  action: actionShape.isRequired,
  complete: bool,
};

SvuCell.defaultProps = {
  complete: false,
};
