import queryString from 'query-string';

/**
 * Returns the current URL's query parameters as an object.
 *
 * Using this function allows you to easily mock out the query params during tests.
 *
 * @param {queryString.ParseOptions} options
 */
export default function getQueryParameters(options = undefined) {
  return queryString.parse(window.location.search, options);
}
