import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import BaseCheckbox from '@/components/BaseCheckbox';

const RhfCheckbox = forwardRef(function RhfCheckbox(
  {
    control,
    name,
    defaultValue,
    label,
    sx,
    disabled,
    'data-pendo-id': dataPendoId,
  },
  ref,
) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <StyledCheckbox
          checked={field.value}
          label={label}
          onChange={(e) => field.onChange(e.target.checked)}
          sx={sx}
          disabled={disabled}
          ref={ref}
          data-pendo-id={dataPendoId}
        />
      )}
    />
  );
});

export default RhfCheckbox;

RhfCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  label: PropTypes.string.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  disabled: PropTypes.bool,
  'data-pendo-id': PropTypes.string,
};

RhfCheckbox.defaultProps = {
  defaultValue: false,
  sx: undefined,
  disabled: false,
  'data-pendo-id': undefined,
};

const StyledCheckbox = styled(BaseCheckbox)`
  align-items: baseline;
`;
