import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useUnmount, useUpdate } from 'react-use';

import { useSelectedTasksContext } from '@/pages/TaskBasedWorkflow/contexts/SelectedTasksContext';
import transformFrontendCareGapAction from '@/utils/transformFrontendCareGapAction';
import BaseSpinner from '@/components/BaseSpinner';
import getTotalSvusOnCompletionSteps from '@/pages/TaskBasedWorkflow/utils/getTotalSvusOnCompletionSteps';
import { userInputTaskShape } from '@/utils/shapes';
import useBulkExecuteStepsExternal from '@/pages/TaskBasedWorkflow/hooks/useBulkExecuteStepsExternal';

import ErrorDialog from '../ErrorDialog';

import CompletedSelectedTasksDialog from './CompletedSelectedTasksDialog';

// This nasty little piece of code takes a list of patients and turns it into a
// map from id->patient. It never forgets a patient record that it sees, so if
// patients A,B,C are passed in, it will return { A, B, C }. If, later, it is
// passed patients B,C,D, it will return { A, B, C, D }, acting as an
// append-only ledger of patients this hook has seen.
//
// This is necessary because, from the perspective of the component in this
// file, it might be passed many different lists of patients that may not line
// up with the patient records it happens to need to do its work. But we know
// at the point of writing that it _will have seen_ the patient records needed,
// at some point, so we can just save them to the side for when they are
// needed.
//
// This hook makes use of refs and an update function rather than setState
// because the values within are treated as _mutable_. This is usually bad,
// so do not replicate this pattern elsewhere unless you know what you're doing.
function useAppendOnlyPatientMap(patients) {
  const update = useUpdate();
  const patientIdsRef = useRef(new Set());
  const patientsMapRef = useRef({});
  const patientIds = patientIdsRef.current;
  const patientsMap = patientsMapRef.current;

  useEffect(() => {
    const patientsToAdd = patients.filter((p) => !patientIds.has(p.id));
    if (patientsToAdd.length) {
      patientsToAdd.forEach((p) => {
        patientIds.add(p.id);
        patientsMap[p.id] = p;
      });
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients]);

  useUnmount(() => {
    // paranoia: on unmount, reset the refs but don't delete
    patientIdsRef.current = new Set();
    patientsMapRef.current = {};
  });

  return patientsMap;
}

export default function CompleteSelectedTasksButton({ tasks }) {
  const { clearSelectedTasks, isTaskSelected } = useSelectedTasksContext();
  const [errors, setErrors] = useState([]);
  const onError = ({ response }) => {
    // TODO: Great candidate for usage of an upcoming error boundary.
    const responseErrors = Object.keys(response.data).map((key) => ({
      title: key,
      description: response.data[key],
    }));
    setErrors(responseErrors);
    clearSelectedTasks();
  };

  const onSuccess = () => {
    window.stellar?.refreshSvuTracker?.();
    clearSelectedTasks();
  };

  const {
    isLoading,
    mutate: mutateBulk,
    data,
    reset,
  } = useBulkExecuteStepsExternal({
    onError,
    onSuccess,
  });

  const completedActions = useMemo(() => {
    if (!data) return [];
    return data.data
      .filter((resp) => resp.response_code === 200)
      .map((val) => transformFrontendCareGapAction(val.action));
  }, [data]);

  const { selectedTasks, patients } = useMemo(() => {
    const selectedTasks = tasks.filter((task) => isTaskSelected(task));
    const patients = selectedTasks.map((task) => task.patient);
    return { selectedTasks, patients };
  }, [isTaskSelected, tasks]);
  const patientMap = useAppendOnlyPatientMap(patients);
  const totalSvus = getTotalSvusOnCompletionSteps(selectedTasks);

  const completeTasks = () => {
    const actionIds = selectedTasks.map((task) => task.action.v2Dto.actionId);

    mutateBulk({ actionIds });
  };

  return (
    <>
      {selectedTasks.length > 0 && (
        <Button
          onClick={completeTasks}
          disabled={isLoading}
          data-pendo-id="complete-selected-tasks-button"
        >
          {isLoading ? <BaseSpinner size={20} sx={{ mr: 1 }} /> : null}
          Confirm completion for selected tasks ({totalSvus} SVUs)
        </Button>
      )}
      <CompletedSelectedTasksDialog
        completedActions={completedActions}
        onClose={reset}
        open={!isEmpty(completedActions)}
        patientMap={patientMap}
      />
      <ErrorDialog
        open={errors.length > 0 && completedActions.length === 0}
        handleClose={() => setErrors([])}
        errors={errors}
        title="Something went wrong while trying to complete the selected tasks."
        warningMessage="Please see below for details and get in touch with our customer support team."
      />
    </>
  );
}

CompleteSelectedTasksButton.propTypes = {
  tasks: PropTypes.arrayOf(userInputTaskShape).isRequired,
};
