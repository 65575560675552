import { Input } from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as MagnifyingGlassIcon } from '@/assets/icons/magnifyingGlass.svg';

export default function SearchBar({
  sx,
  onChange,
  ariaLabel,
  id,
  placeholder,
}) {
  return (
    <Input
      inputProps={{
        'aria-label': ariaLabel,
      }}
      id={id}
      type="search"
      onChange={onChange}
      placeholder={placeholder}
      startAdornment={<StyledMagnifyingGlassIcon />}
      sx={sx}
    />
  );
}
SearchBar.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
  sx: undefined,
  onChange: undefined,
  ariaLabel: undefined,
  id: undefined,
  placeholder: undefined,
};

const StyledMagnifyingGlassIcon = styled(MagnifyingGlassIcon)`
  width: 17px;
  margin-right: 6px;
`;
