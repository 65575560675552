import { Typography } from '@mui/material';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import { arrayOf } from 'prop-types';

import { diagnosisShape } from '@/utils/transformFrontendAction';
import { color } from '@/utils/stylingUtils';

import DiagnosisDisplay from '../DiagnosisDisplay';

export default function PastDiagnosesCell({ diagnoses }) {
  const [expandPastDiagnoses, toggleExpandPastDiagnoses] = useToggle(false);
  const [firstDiagnosis, ...restDiagnosis] = diagnoses;

  const previousCount = diagnoses.length - 1;
  const previousCountText = expandPastDiagnoses
    ? `Hide additional historical ICD10s`
    : `View additional historical ICD10s (${previousCount})`;

  return (
    <>
      <DiagnosisDisplay diagnosis={firstDiagnosis} variant="bodymedium" />
      {restDiagnosis.length > 0 ? (
        <PreviousText onClick={toggleExpandPastDiagnoses}>
          {previousCountText}
        </PreviousText>
      ) : null}
      {expandPastDiagnoses
        ? restDiagnosis.map((diagnosis, i) => (
            <DiagnosisDisplay
              // eslint-disable-next-line react/no-array-index-key
              key={`${diagnosis.icd}-${i}`}
              diagnosis={diagnosis}
              variant="bodymedium"
            />
          ))
        : null}
    </>
  );
}
PastDiagnosesCell.propTypes = {
  diagnoses: arrayOf(diagnosisShape.isRequired).isRequired,
};

const PreviousText = styled(Typography)`
  ${({ theme }) => theme.typography.bodysmall}
  flex: 0 0 96px;
  color: ${color('text.secondary')};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
