import { actionShape } from '@/utils/transformFrontendAction';
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';

export default function PYMSMedicationName({ action }) {
  const { data: contextualInfo, isLoading } = useGetContextualInfo(action);

  return isLoading ? <FieldSkeleton /> : contextualInfo.medicationName;
}

PYMSMedicationName.propTypes = {
  action: actionShape.isRequired,
};
