import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import transformPayload from '@ot/utils/transformPayload';
import transformResponse from '@ot/utils/transformResponse';

async function postAcceptedAgreements(acceptedBy, acceptedAgreementIds) {
  const params = {
    acceptedBy,
    acceptedAgreementIds,
  };
  const response = await axios.post(
    `/payment_instructions/user/agreements/accept`,
    transformPayload(params),
  );
  return transformResponse(response.data);
}

export default function usePostAcceptedAgreements() {
  return useMutation(({ acceptedBy, acceptedAgreementIds }) =>
    postAcceptedAgreements(acceptedBy, acceptedAgreementIds),
  );
}
