import { Box, Collapse, Typography } from '@mui/material';
import { useToggle } from 'react-use';
import useId from '@mui/utils/useId';

import { actionShape } from '@/pages/MedicationManagement/hooks/useGetPatients';
import Separator from '@/pages/TaskBasedWorkflow/components/Separator';
import PrescriptionHistory from '@/pages/TaskBasedWorkflow/components/patientDetailView/actionCard/PrescriptionHistory';
import { StyledHistoryButton } from '@/pages/TaskBasedWorkflow/components/taskDetailView/TocHistory';

export default function ActionCardHeader({ action }) {
  const prescriptionHistoryId = useId();
  const [showPrescriptionHistory, toggleShowPrescriptionHistory] =
    useToggle(false);
  const isMedAction = action.v2Dto.type === 'medication';
  return (
    <>
      {isMedAction ? (
        <Box>
          <Typography
            color="text.secondary"
            variant="bodymedium"
            display="inline"
          >
            Last prescribed{' '}
            {action.v2Dto.contextualInfo?.mostRecentDateForDisplay} by{' '}
            {action.v2Dto.contextualInfo?.mostRecentProviderForDisplay}
          </Typography>

          <Separator />

          <StyledHistoryButton
            aria-expanded={showPrescriptionHistory}
            aria-controls={prescriptionHistoryId}
            onClick={toggleShowPrescriptionHistory}
            variant="text"
          >
            {showPrescriptionHistory ? 'Hide History' : 'History'}
          </StyledHistoryButton>
        </Box>
      ) : null}

      {action.v2Dto.patientId ? (
        <Collapse mountOnEnter in={showPrescriptionHistory}>
          <PrescriptionHistory
            id={prescriptionHistoryId}
            actionId={action.id}
            patientId={action.v2Dto.patientId}
          />
        </Collapse>
      ) : null}

      <Box sx={{ mt: 1, mb: 1, display: 'flex' }}>
        {action.v2Dto.contextualInfo?.fieldsToDisplay?.map((field, index) => {
          return (
            <Box
              key={field.label}
              sx={{
                ml: index === 0 ? 0 : 5,
              }}
            >
              <Typography variant="bodysmall" color="text.label">
                {field.label}
              </Typography>
              {field.type === 'Date' ? (
                <Typography style={{ whiteSpace: 'nowrap' }}>
                  {field.value}
                </Typography>
              ) : (
                <Typography>{field.value}</Typography>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
}

ActionCardHeader.propTypes = {
  action: actionShape.isRequired,
};
