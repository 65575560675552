import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { useState } from 'react';

import ChallengeActionBarButton from '@ot/pages/Challenges/ChallengesView/components/ChallengeActionBarButton';
import ErrorPopover from '@ot/pages/Challenges/ChallengesView/components/ErrorPopover';

function isNonnegativeInteger(str) {
  if (typeof str !== 'string') {
    return false;
  }

  const num = Number(str);

  return Number.isInteger(num) && num > -1;
}

function ChallengeActionBarEditGoal({
  mutateEditGoalChallenges,
  isAnythingLoading,
  isLoadingEditGoalChallenges,
  isSuccessEditGoalChallenges,
  selectedChallengeIds,
  setMostRecentErrorSource,
  clearSelectedChallengeIds,
  errorSource,
}) {
  const [goal, setGoal] = useState('');
  const [goalInputErrorMessage, setGoalInputErrorMessage] = useState(null);
  const [anchorElGoal, setAnchorElGoal] = useState(null);

  function closePopover(setAnchor) {
    setAnchor(false);
  }

  const handleChallengesEditGoal = (event) => {
    if (!goal || !isNonnegativeInteger(goal)) {
      setAnchorElGoal(event.currentTarget);

      setGoalInputErrorMessage('Integer must be 0 or greater');

      return;
    }

    const goalPayload = { goal };
    mutateEditGoalChallenges({
      challengeIdList: selectedChallengeIds,
      challengeUpdates: goalPayload,
    });
    setMostRecentErrorSource(errorSource);

    setGoal('');
    clearSelectedChallengeIds();
  };

  const handleSubmitGoalChange = (event) => {
    setGoalInputErrorMessage(null);

    setGoal(event.target.value);
  };

  return (
    <>
      <ChallengeActionBarButton
        label="Edit Goal"
        icon={<StyledFlagCircleIcon />}
        handleButtonClick={handleChallengesEditGoal}
        disabled={isAnythingLoading || selectedChallengeIds.length === 0}
        showCircularProgress={
          isLoadingEditGoalChallenges && !isSuccessEditGoalChallenges
        }
        marginLeft={1}
        marginRight={-1}
        data-pendo-id="challenge-action-bar-edit-goal-button"
      />

      <ErrorPopover
        anchorEl={anchorElGoal}
        closePopover={() => closePopover(setAnchorElGoal)}
      >
        <Typography
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <ErrorIcon color="error" fontSize="medium" sx={{ mr: 1 }} /> Please
          use integer of 0 or greater
        </Typography>
      </ErrorPopover>

      <StyleInputField>
        <TextField
          error={!goal || !isNonnegativeInteger(goal)}
          helperText={goalInputErrorMessage}
          type="number"
          value={goal}
          disabled={isAnythingLoading || selectedChallengeIds.length === 0}
          onChange={handleSubmitGoalChange}
          hiddenLabel
          size="small"
          variant="outlined"
          inputProps={{
            style: {
              height: '10px',
            },
          }}
          sx={{ width: 100 }}
        />
      </StyleInputField>
    </>
  );
}

const StyledFlagCircleIcon = styled(FlagCircleIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyleInputField = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.9),
  },

  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 'auto',
  },
}));

ChallengeActionBarEditGoal.propTypes = {
  mutateEditGoalChallenges: PropTypes.func.isRequired,
  isAnythingLoading: PropTypes.bool,
  isLoadingEditGoalChallenges: PropTypes.bool,
  isSuccessEditGoalChallenges: PropTypes.bool,
  selectedChallengeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setMostRecentErrorSource: PropTypes.func.isRequired,
  clearSelectedChallengeIds: PropTypes.func.isRequired,
  errorSource: PropTypes.string.isRequired,
};

ChallengeActionBarEditGoal.defaultProps = {
  isAnythingLoading: undefined,
  isLoadingEditGoalChallenges: undefined,
  isSuccessEditGoalChallenges: undefined,
};

export default ChallengeActionBarEditGoal;
