import PropTypes from 'prop-types';
import { Box, Button, Stack, Typography, styled } from '@mui/material';

import { ReactComponent as TrendSame } from '@/assets/icons/trendSame.svg';

import GoalProgressAndTitle from './GoalProgressAndTitle';
import { goalShape } from './shapes';
import CountOfTotalCompleted from './CountOfTotalCompleted';
import { ReactComponent as CelebrationFlags } from './assets/celebrationFlags.svg';
import getGoalCalculations from './utils/getGoalCalculations';
import SubtypePrioritization from './SubtypePrioritization';
import useTilePendoId from './hooks/useTilePendoId';
import GoalTileHeader from './GoalTileHeader';
import useIsHistoricalData from './hooks/useIsHistoricalData';

export default function HorizontalGoalTile({ goal, sx, isGoalsDashboard }) {
  const { awardedSvu } = goal;
  const { buttonCopy, goalTitle, percentCompleted, isComplete, link } =
    getGoalCalculations(goal);
  const isHistoricalData = useIsHistoricalData();
  const pendoId = useTilePendoId();
  return (
    <Box
      sx={[
        {
          bgcolor: 'background.base',
          borderRadius: 2,
          overflow: 'hidden',
          minWidth: '448px',
          position: 'relative',
          border: '1px solid',
          borderColor: 'background.base',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <GoalTileHeader goal={goal} />

      <Box sx={{ overflow: 'hidden', position: 'relative' }}>
        <Stack
          sx={{
            bgcolor: 'background.base',
            padding: 3,
            overflow: 'auto',
            minWidth: '448px',
            maxWidth: '540px',
            mx: 'auto',
          }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={4}
        >
          <GoalProgressAndTitle
            goalTitle={goalTitle}
            percentCompleted={percentCompleted}
            zIndex={isComplete ? 1 : 0}
            progressSizePx={104}
          />

          <Stack
            spacing={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            maxWidth="384px"
          >
            <CountOfTotalCompleted
              total={goal.total}
              completed={goal.completed}
              percentCompleted={percentCompleted}
            />

            {Boolean(isGoalsDashboard && !isHistoricalData && !isComplete) && (
              <Typography variant="bodysmall">
                {awardedSvu || 0}{' '}
                <Box component="span" sx={{ fontSize: 9 }}>
                  SVU claimed so far
                </Box>
              </Typography>
            )}

            {goal.subtypeFocus ? <SubtypePrioritization goal={goal} /> : null}

            {isHistoricalData ? null : (
              <Button
                variant={isComplete ? 'tertiary' : 'secondary'}
                size="small"
                sx={{
                  width: '100%',
                }}
                endIcon={<StyledTrendSame />}
                href={link}
                data-pendo-id={pendoId(
                  goal.internalPerformanceMetric,
                  'cta-button',
                )}
                data-dd-synthetics-id={`goals-${goal.internalPerformanceMetric}-cta-button`}
              >
                {isComplete ? 'Keep going' : buttonCopy}
              </Button>
            )}

            {!isGoalsDashboard && (
              <Button
                variant="tertiary"
                href="/goals_dashboard"
                sx={{ width: '100%' }}
                data-pendo-id={pendoId('more-details')}
              >
                <Typography variant="bodyxsmall">More details</Typography>
              </Button>
            )}
          </Stack>
        </Stack>

        {isComplete && <StyledCelebrationFlags />}
      </Box>
    </Box>
  );
}

HorizontalGoalTile.propTypes = {
  goal: goalShape.isRequired,
  isGoalsDashboard: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

HorizontalGoalTile.defaultProps = {
  isGoalsDashboard: false,
  sx: undefined,
};

const StyledTrendSame = styled(TrendSame)`
  width: 14px;
  height: 14px;
`;

const StyledCelebrationFlags = styled(CelebrationFlags)`
  position: absolute;
  top: -8px;
  z-index: 0;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
`;
