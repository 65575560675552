import { Typography } from '@mui/material';
import { startCase } from 'lodash';
import propTypes from 'prop-types';

import generateStatusFromAction from '@/pages/PatientSummary/utils/generateStatusFromAction';

function getIncompleteStatus(action) {
  return action.version
    ? generateStatusFromAction(action.preferredPath, action.stepHistories)
    : startCase(action.state);
}
export function getStatusAndDetail(action) {
  const status = getIncompleteStatus(action);
  let extraDetail = action.version
    ? action.contextualInfo?.actionDetails
    : action.extraDetailForDisplay.concat(
        action.isScheduled ? ` by ${action.userWhoLastClicked}` : '',
      );

  if (extraDetail && extraDetail.startsWith(status)) {
    extraDetail = extraDetail.replace(status, '');
  }

  return [status, extraDetail];
}

export default function ReadStatusCell({ action }) {
  const [status, extraDetail] = getStatusAndDetail(action);
  return (
    <>
      <Typography
        variant="bodysmallbold"
        sx={{ color: 'status.infoForeground' }}
      >
        {status}
      </Typography>
      <Typography variant="bodysmall">{extraDetail}</Typography>
    </>
  );
}

ReadStatusCell.propTypes = {
  action: propTypes.object.isRequired,
};
