import { useCookies } from 'react-cookie';
import { Cookie } from 'universal-cookie';

/**
 * Returns the value of a single cookie.
 *
 * A wrapper around react-cookie for easier test mocking.
 *
 * @param {string} cookieName
 */
export default function useCookie(cookieName: string): Cookie {
  const [cookies] = useCookies([cookieName]);

  return cookies[cookieName];
}
