import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function ArrayErrorTable({ errorMessages }) {
  return (
    <TableContainer
      sx={{
        backgroundColor: 'background.base',
        border: '1px solid',
        borderColor: 'border.base',
        borderRadius: '8px',
        maxHeight: 400,
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Row</TableCell>
            <TableCell>Errors</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(errorMessages).map(([rowNum, errors]) => {
            return (
              <TableRow key={rowNum}>
                <TableCell>{rowNum}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                    }}
                  >
                    {errors.map((errorMessage) => {
                      return (
                        <Typography key={errorMessage}>
                          {errorMessage}
                        </Typography>
                      );
                    })}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default ArrayErrorTable;
ArrayErrorTable.propTypes = {
  errorMessages: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
};
