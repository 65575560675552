const rootKey = 'svu-menu-names-and-ids';

const getSvuMenuNamesAndIdsQueryKeyFactory = (query) => {
  if (!query) {
    return [rootKey];
  }
  return [rootKey, query];
};

export default getSvuMenuNamesAndIdsQueryKeyFactory;
