import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import useGetUserFilters from '@/hooks/useGetUserFilters';
import DynamicFormLabel from '@/components/DynamicForm/DynamicFormLabel';
import BaseSelect from '@/components/BaseSelect';
import DynamicFormTextField from '@/components/DynamicForm/fieldComponents/DynamicFormTextField';
import DynamicFormTextarea from '@/components/DynamicForm/fieldComponents/DynamicFormTextarea';
import BaseFieldset from '@/components/BaseFieldset';

import { useStepExecutionContext } from './StepExecutionContext';

// Execution requirement field names. These values must match the field names defined in the Action Config.
const PROVIDER_SINGLE_NAME = 'speciality_provider_single_name';
const PHONE_NUMBER_INPUT = 'phone_number_input';
const ADDRESS_INPUT = 'address_input';

/**
 * QualityScreeningsReferral generates its own fields (as opposed to using the form config)
 * in order to format them differently and manually update the field values when a dropdown selection is made.
 */
const FIELDS = {
  providerSingleName: {
    name: PROVIDER_SINGLE_NAME,
    label: 'Provider name (optional)',
  },
  phoneNumberInput: {
    name: PHONE_NUMBER_INPUT,
    label: 'Phone number (optional)',
  },
  addressInput: {
    name: ADDRESS_INPUT,
    label: 'Address (optional)',
  },
};

/**
 * Converts a provider object to a dropdown option.
 */
function providerToOption(provider) {
  return {
    value: provider.id,
    label: provider.providerSingleName,
  };
}

/**
 * This is a component for all Quality Screenings actions except diabetic_eye_exam_dee.
 */
export default function QualityScreeningsReferral() {
  const { setValue, resetField } = useFormContext();
  const { action } = useStepExecutionContext();
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const { data, isLoading } = useGetUserFilters();
  const { preferredReferrals = [] } = data || {};
  const options =
    preferredReferrals
      .filter(
        (referral) =>
          // Filter for only referrals of the correct action subtype.
          referral.actionSubtype === action.subtype,
      )
      .map(providerToOption) ?? [];

  const handleSelectProvider = (e) => {
    setSelectedProviderId(e.target.value);
    const provider = preferredReferrals.find((p) => p.id === e.target.value);
    if (provider) {
      setValue(PROVIDER_SINGLE_NAME, provider.providerSingleName || '');
      setValue(PHONE_NUMBER_INPUT, provider.phone || '');
      setValue(ADDRESS_INPUT, provider.address || '');
    } else {
      resetField(PROVIDER_SINGLE_NAME);
      resetField(PHONE_NUMBER_INPUT);
      resetField(ADDRESS_INPUT);
    }
  };
  // Don't pass a value until the data is finished loading.
  // Otherwise MUI will complain that the form's default value (if there is one) isn't an available option.
  const value = isLoading ? undefined : selectedProviderId;

  return (
    <BaseFieldset label="Referral information (optional)" sx={{ mt: 2 }}>
      {options?.length > 0 && (
        <>
          <DynamicFormLabel>Provider</DynamicFormLabel>
          <BaseSelect
            noneOption="Other (fill in)"
            label="Provider"
            onChange={handleSelectProvider}
            options={options}
            sx={{ mt: 1 / 2, width: 1 }}
            value={value}
          />
        </>
      )}
      <DynamicFormTextField field={FIELDS.providerSingleName} />
      <DynamicFormTextField field={FIELDS.phoneNumberInput} />
      <DynamicFormTextarea field={FIELDS.addressInput} />
    </BaseFieldset>
  );
}
