import {
  PX_HELPERS_COORD_CARE,
  PX_HELPERS_COORD_MEDS,
  VISIT_FOLLOW_UP_PX,
} from '@/pages/TaskBasedWorkflow/constants';

import { ContextualInfoSource, ContextualInfoType } from '../ContextualInfo';

export function isVisitFollowUpAction(action) {
  return action.subtype === VISIT_FOLLOW_UP_PX;
}

export function isPxHelperAction(action) {
  return [PX_HELPERS_COORD_CARE, PX_HELPERS_COORD_MEDS].includes(
    action.subtype,
  );
}

// starting code for configuration type approach for ContextualInfo
// the hope is that we can reuse the styling from each data types
export function getContextualInfoConfiguration(action) {
  let contextualInfoConfig;
  if (isVisitFollowUpAction(action)) {
    contextualInfoConfig = [
      {
        name: 'mostRecentProviderForDisplay',
        label: 'Provider',
        type: ContextualInfoType.label,
      },
      {
        name: 'pcpVisitDateOfService',
        label: 'Date of visit',
        type: ContextualInfoType.date,
        source: ContextualInfoSource.v2Dto,
      },
    ];
  } else if (isPxHelperAction(action)) {
    contextualInfoConfig = [
      {
        name: 'mostRecentProviderForDisplay',
        label: 'Provider',
        type: ContextualInfoType.label,
      },
      {
        name: 'pcpVisitDateOfService',
        label: 'Date of visit',
        type: ContextualInfoType.date,
        source: ContextualInfoSource.v2Dto,
      },
      {
        name: 'pxRating',
        label: 'Patient experience rating',
        type: ContextualInfoType.label,
        source: ContextualInfoSource.v2Dto,
        default: 'N/A - No Answer',
      },
      {
        name: 'createdAt', // px helpers are created immediately after PX survey completion
        label: 'Date of post-visit survey',
        type: ContextualInfoType.label,
        source: ContextualInfoSource.v2Dto,
      },
    ];
  }
  return contextualInfoConfig;
}
