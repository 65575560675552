import { Button } from '@mui/material';
import { bool, func, object, string } from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';
import styled from 'styled-components';

export default function DynamicFormButtons({
  ButtonSx,
  isLoading,
  onCancel,
  cancelButtonPendoId,
  submitButtonPendoId,
  submitButtonLabel,
}) {
  const { control } = useFormContext();
  const { isValid } = useFormState({ control });

  return (
    <>
      <StyledSecondaryButton
        data-pendo-id={cancelButtonPendoId}
        disabled={isLoading}
        onClick={onCancel}
        variant="outlined"
        size="large"
        sx={ButtonSx}
      >
        Cancel
      </StyledSecondaryButton>
      <StyledPrimaryButton
        data-pendo-id={submitButtonPendoId}
        type="submit"
        disabled={!isValid || isLoading}
        size="large"
        sx={ButtonSx}
      >
        {isLoading ? 'Saving...' : submitButtonLabel}
      </StyledPrimaryButton>
    </>
  );
}

DynamicFormButtons.propTypes = {
  ButtonSx: object,
  cancelButtonPendoId: string,
  submitButtonPendoId: string,
  isLoading: bool.isRequired,
  onCancel: func.isRequired,
  submitButtonLabel: string,
};

DynamicFormButtons.defaultProps = {
  ButtonSx: {},
  cancelButtonPendoId: undefined,
  submitButtonPendoId: undefined,
  submitButtonLabel: 'Confirm',
};

// TODO: Remove this once the default colors are updated on the page
// https://app.asana.com/0/1202219043272645/1202808136890709/f
const StyledPrimaryButton = styled(Button)`
  background-color: ${(p) => p.theme.palette.background.primary};
  border-color: ${(p) => p.theme.palette.background.primary};
  color: ${(p) => p.theme.palette.background.primaryInverse};

  &:hover {
    background-color: ${(p) => p.theme.palette.background.primaryDarker};
    border-color: ${(p) => p.theme.palette.background.primaryDarker};
    color: ${(p) => p.theme.palette.background.primaryInverse};
  }
`;

// TODO: Remove this once the default colors are updated on the page
// https://app.asana.com/0/1202219043272645/1202808136890709/f
const StyledSecondaryButton = styled(Button)`
  background-color: ${(p) => p.theme.palette.background.transparent};
  border-color: ${(p) => p.theme.palette.background.primary};
  color: ${(p) => p.theme.palette.text.link};

  &:hover {
    background-color: ${(p) => p.theme.palette.background.primary};
    border-color: ${(p) => p.theme.palette.background.primary};
    color: ${(p) => p.theme.palette.text.primaryInverse};
  }
`;
