import { Box, FormLabel, Input } from '@mui/material';
import { isFinite } from 'lodash';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import useId from '@mui/utils/useId';

import FormErrorMessage from '@/components/FormErrorMessage';

export default function ModalFloatField({
  label,
  defaultValue,
  errorMsg,
  actionId,
  enforcePositive,
  min,
  max,
  BoxSx,
  InputSx,
  name,
  control,
  errors,
}) {
  const idPrefix = useId(actionId);
  const id = `${idPrefix}_${name}`;
  const getMinErrorString = () => {
    if (min === 0) {
      return 'Enter a number greater or equal to zero';
    }
    return `Enter a number greater than or equal to ${min}`;
  };
  return (
    <Box
      sx={{ display: 'flex', mt: 1, justifyContent: 'space-between', ...BoxSx }}
    >
      <FormLabel htmlFor={id} sx={{ mr: 1, mt: 1 }}>
        {label}
      </FormLabel>
      <div style={{ width: InputSx.width ? InputSx.width : {} }}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{
            required: errorMsg,
            validate: {
              isNumber: (v) => isFinite(Number(v)) || 'Enter a number',
              isPositive: (v) =>
                v === '' ||
                !enforcePositive ||
                Number(v) > 0 ||
                'Enter a positive number',
            },
            min: {
              value: min,
              message: getMinErrorString(),
            },
            max: {
              value: max,
              message: `Enter a number less than or equal to ${max}`,
            },
          }}
          render={({ field }) => {
            return (
              <Input
                id={id}
                sx={InputSx}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                inputProps={{
                  'data-testid': name,
                }}
              />
            );
          }}
        />
        <FormErrorMessage name={name} errors={errors} />
      </div>
    </Box>
  );
}

ModalFloatField.propTypes = {
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  actionId: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  enforcePositive: PropTypes.bool,
  BoxSx: PropTypes.object,
  InputSx: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

ModalFloatField.defaultProps = {
  errorMsg: undefined,
  actionId: undefined,
  defaultValue: '',
  enforcePositive: false,
  BoxSx: undefined,
  InputSx: { width: 100 },
  min: undefined,
  max: undefined,
};
