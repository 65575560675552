import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import TheProvider from '@/components/TheProvider';
import TheLegacyNavbarAdapter from '@/components/TheLegacyNavbarAdapter';
import TheGlobalNavbar from '@/components/TheGlobalNavbar';

import configureAxiosGlobalDefaults from './utils/configureAxiosGlobalDefaults';
import TheLegacyFooterAdapter from './components/TheLegacyFooterAdapter';

configureAxiosGlobalDefaults();

export default function renderPage(
  ui,
  {
    featureFlags = undefined,
    removeShadowFromLegacyNavbar = false,
    removeStickyFromLegacyNavbar = false,
    removeFooter = false,
    theme = undefined,
  } = {},
) {
  ReactDOM.render(
    <StrictMode>
      <TheLegacyFooterAdapter removeFooter={removeFooter} />
      <TheProvider
        featureFlags={{
          new_react_global_navbar: Boolean(global.new_react_global_navbar),
          ...featureFlags,
        }}
        theme={theme}
      >
        {global.new_react_global_navbar ? (
          <TheGlobalNavbar
            // TODO: rename `removeStickyFromLegacyNavbar` to `removeStickyFromNavbar`
            sticky={!removeStickyFromLegacyNavbar}
            navbarConfigs={global.navbar_configs}
          />
        ) : (
          <TheLegacyNavbarAdapter
            removeShadow={removeShadowFromLegacyNavbar}
            removeSticky={removeStickyFromLegacyNavbar}
          />
        )}
        {ui}
      </TheProvider>
    </StrictMode>,
    document.getElementById('app'),
  );
}
