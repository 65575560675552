import { useController } from 'react-hook-form';
import useId from '@mui/utils/useId';
import { Input } from '@mui/material';

import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';

import DynamicFormLabel from '../DynamicFormLabel';
import FormErrorMessage from '../../FormErrorMessage';

/**
 * A basic, boring text field that has the same contract as the other fields
 * in this directory: it takes a single field prop which describes how it
 * will behave, roughly matching action executionRequirements.
 */
export default function DynamicFormTextField({ field }) {
  const { label, name, required, sx, startAdornment, endAdornment } = field;
  const controller = useController({
    name,
    defaultValue: '',
    rules: {
      required: required ? 'This field is required' : undefined,
    },
  });
  const fieldId = useId();

  return (
    <>
      <DynamicFormLabel htmlFor={fieldId}>{label}</DynamicFormLabel>
      <Input
        id={fieldId}
        sx={sx}
        fullWidth
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controller.field}
      />
      <FormErrorMessage errors={controller.formState.errors} name={name} />
    </>
  );
}

DynamicFormTextField.propTypes = {
  field: executionRequirementShape.isRequired,
};

DynamicFormTextField.defaultProps = {};
