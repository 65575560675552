import { Popover, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { string } from 'prop-types';

import BaseLinkButton from '@/components/BaseLinkButton';

function CptPopover({ cptCode, cptText }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <LinkButton
        onClick={handlePopoverOpen}
        onKeyDown={(e) => e.key === 'Enter' && handlePopoverOpen(e)}
      >
        {cptCode}
      </LinkButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handlePopoverClose}
      >
        <Typography sx={{ p: 1, m: 0.5 }}>{cptText}</Typography>
      </Popover>
    </>
  );
}

export default CptPopover;

CptPopover.propTypes = {
  cptCode: string.isRequired,
  cptText: string.isRequired,
};

const LinkButton = styled(BaseLinkButton)`
  font-weight: bold;
  color: ${(p) => p.theme.palette.text.primary};
  :hover {
    color: ${(p) => p.theme.palette.text.secondary};
  }
`;
