import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Input } from '@mui/material';

function transformValue(value, type) {
  if (value !== '' && type === 'number') {
    return Number(value);
  }
  return value;
}

export default function RhfInput({
  control,
  defaultValue,
  name,
  rules,
  id,
  type,
  placeholder,
  disabled,
  sx,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <Input
            fullWidth
            type={type}
            value={typeof field.value !== 'undefined' ? field.value : ''}
            onChange={(event) =>
              field.onChange(transformValue(event.target.value, type))
            }
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            sx={sx}
            inputProps={{
              'data-testid': name,
            }}
          />
        );
      }}
    />
  );
}

RhfInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

RhfInput.defaultProps = {
  id: undefined,
  defaultValue: '',
  type: undefined,
  placeholder: undefined,
  disabled: false,
  sx: undefined,
};
