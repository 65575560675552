import { createContext, useContext } from 'react';

import historyUtils from './history';

export const HistoryContext = createContext(historyUtils);
HistoryContext.displayName = 'HistoryContext';

/**
 * @deprecated Use in unit tests only please.
 * @type {React.Provider<HistoryUtils>}
 */
export const HistoryProvider = HistoryContext.Provider;

export default function useHistory() {
  return useContext(HistoryContext);
}
