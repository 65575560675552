import PropTypes from 'prop-types';
import { Box, Button, styled } from '@mui/material';

import { ReactComponent as PrinterIcon } from '@/assets/icons/printer.svg';
import { ReactComponent as ExportIcon } from '@/assets/icons/export.svg';

import useExportCsv from '../hooks/useExportCsv';

import ExportPrintLoadingDialog from './ExportPrintLoadingDialog';

export default function ExportPrintBar({ sx }) {
  const { exportCsv, isLoading: isLoadingExport } = useExportCsv();

  const handleClickExport = () => {
    exportCsv();
  };

  const handleClickPrint = () => {
    const url = new URL(window.location.href);
    // print_preview gets parsed into printPreview
    url.searchParams.set('print_preview', 'true');
    window.open(url.toString(), '_blank');
  };

  return (
    <>
      <ExportPrintBarRoot textAlign="right" mb={1} sx={sx}>
        <IconButton
          aria-label="Export to CSV"
          data-pendo-id="tbw-export-button"
          variant="tertiary"
          onClick={handleClickExport}
        >
          <StyledExportIcon />
        </IconButton>
        <IconButton
          aria-label="Open print preview"
          data-pendo-id="tbw-print-button"
          variant="tertiary"
          onClick={handleClickPrint}
        >
          <StyledPrinterIcon />
        </IconButton>
      </ExportPrintBarRoot>

      <ExportPrintLoadingDialog open={isLoadingExport} />
    </>
  );
}

ExportPrintBar.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ExportPrintBar.defaultProps = {
  sx: undefined,
};

const ExportPrintBarRoot = styled(Box)``;

const IconButton = styled(Button)`
  height: 32px;
  color: ${(p) => p.theme.palette.text.secondary};
  padding: ${(p) => p.theme.spacing(1, 1.5)};
`;

const StyledExportIcon = styled(ExportIcon)`
  height: 14px;
  width: 14px;
`;

const StyledPrinterIcon = styled(PrinterIcon)`
  height: 14px;
  width: 14px;
`;
