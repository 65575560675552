import { styled } from '@mui/material';

import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';

export default function TaskDischargeDate({ task }) {
  return <Date>{task.dischargeDate}</Date>;
}

TaskDischargeDate.propTypes = {
  task: userInputTaskShape.isRequired,
};
const Date = styled('span')`
  white-space: nowrap;
`;
