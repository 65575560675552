export type MedicalGroup = {
  id: number;
  name: string;
};

export default function transformFrontendMedicalGroup(
  medicalGroup: MedicalGroup,
): MedicalGroup {
  return {
    id: medicalGroup.id,
    name: medicalGroup.name,
  };
}
