import styled from 'styled-components';
import { useState } from 'react';
import { Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import useId from '@mui/utils/useId';

import TransitionOfCarePopover from '@/components/TransitionOfCarePopover';
import BaseLinkButton from '@/components/BaseLinkButton';

function ActionUpdatedAlert() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverId = useId();
  return (
    <>
      <Alert severity="info">
        <Typography>
          Updated due to a more recent discharge event.{' '}
          <LinkButton
            type="button"
            aria-describedby={popoverId}
            aria-label="toc-update-alert"
            variant="contained"
            onClick={handlePopoverOpen}
          >
            Learn more
          </LinkButton>
        </Typography>
      </Alert>
      <TransitionOfCarePopover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      />
    </>
  );
}

export default ActionUpdatedAlert;

const LinkButton = styled(BaseLinkButton)`
  font-family: inherit;
  color: ${(p) => p.theme.palette.status.infoForeground};
  text-decoration: underline;
`;
