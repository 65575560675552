import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

async function postVerifyAndCreateChallenges(challenges, commit) {
  const params = {
    challenges,
    commit,
  };
  const response = await axios.post(
    `/ops_tools/api/challenges/verify_and_create_challenges`,
    params,
  );
  return response.data;
}

export default function useMutateChallenges() {
  return useMutation(({ challenges, commit }) =>
    postVerifyAndCreateChallenges(challenges, commit),
  );
}
