import { FormLabel } from '@mui/material';
import Box from '@mui/material/Box';
import useId from '@mui/utils/useId';
import PropTypes from 'prop-types';

import RhfInput from '@/components/reactHookFormComponents/RhfInput';
import RhfStatePicker from '@/components/reactHookFormComponents/RhfStatePicker';

export default function AddressForm({
  control,
  address1InputName,
  address2InputName,
  cityInputName,
  stateInputName,
  zipCodeInputName,
}) {
  const address1Id = useId();
  const address2Id = useId();
  const cityId = useId();
  const stateId = useId();
  const zipCodeId = useId();
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <FormLabel htmlFor={address1Id}>Address 1</FormLabel>
        <RhfInput
          name={address1InputName}
          id={address1Id}
          control={control}
          rules={{
            required: true,
          }}
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <FormLabel htmlFor={address2Id}>Address 2</FormLabel>
        <RhfInput name={address2InputName} id={address2Id} control={control} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Box sx={{ mr: 1, flexGrow: 1 }}>
          <FormLabel htmlFor={cityId}>City</FormLabel>
          <RhfInput
            name={cityInputName}
            id={cityId}
            control={control}
            rules={{
              required: true,
            }}
          />
        </Box>
        <Box sx={{ mr: 1, width: '96px' }}>
          <FormLabel htmlFor={stateId}>State</FormLabel>
          <RhfStatePicker
            control={control}
            name={stateInputName}
            id={stateId}
            rules={{
              required: true,
            }}
          />
        </Box>
        <Box sx={{ width: '80px' }}>
          <FormLabel htmlFor={zipCodeId}>Zip</FormLabel>
          <RhfInput
            name={zipCodeInputName}
            id={zipCodeId}
            control={control}
            rules={{
              required: true,
            }}
          />
        </Box>
      </Box>
    </>
  );
}

AddressForm.propTypes = {
  address1InputName: PropTypes.string.isRequired,
  address2InputName: PropTypes.string.isRequired,
  cityInputName: PropTypes.string.isRequired,
  stateInputName: PropTypes.string.isRequired,
  zipCodeInputName: PropTypes.string.isRequired,
};
