import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

function transformResponse(response) {
  return response.results.map((log) => ({
    id: log.id,
    condition: log.condition_name,
    responsibleUser: log.responsible_user,
    responsibleProvider: log.responsible_provider,
    actionHistoryId: log.action_history_id,
    relevantPatientId: log.get_relevant_patient_id,
    actionHistoryCreationDate: log.action_history_creation_date,
    stepHistoryId: log.step_history_id,
    stepHistoryCreationDate: log.step_history_creation_date,
    trackedChallengeId: log.tracked_challenge_id,
    trackedChallengeApprovalDate: log.tracked_challenge_approval_date,
  }));
}

async function fetchActivityLogs({ challengeId }) {
  const response = await axios.get(
    `/ops_tools/api/challenges/${challengeId}/activity_logs`,
  );
  return response.data;
}

export default function useGetActivityLogs({ challengeId }) {
  const params = {
    challengeId,
  };
  const queryKey = ['challenges-activity-logs', params];
  const enabled = !!challengeId;
  return useQuery(queryKey, () => fetchActivityLogs(params), {
    select: transformResponse,
    enabled,
  });
}
