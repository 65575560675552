import PropTypes from 'prop-types';
import { Box, Button, Divider, Stack, Typography, styled } from '@mui/material';
import { useState } from 'react';

import { userInputTaskShape } from '@/utils/shapes';
import { useQueryParameters } from '@/contexts/QueryParametersContext';
import useGetPatientExperienceLandingData from '@/pages/TaskBasedWorkflow/hooks/useGetPatientExperienceLandingData';
import BaseSpinner from '@/components/BaseSpinner';

import PatientExperienceDetails from './PatientExperienceDetails';
import PatientExperienceSummary, {
  RowConfigType,
} from './PatientExperienceSummary';

export const GO_TO_NEXT_TASK_BUTTON_TEXT = 'Go to next task';
export const LEARN_MORE_LINK_URL =
  'https://stellarhealth.zendesk.com/hc/en-us/articles/18436004709389-Patient-Experience';

const getFollowupSurveyRowData = (dateBucket) => {
  return [
    dateBucket.visitFollowupsWithCompletedSurvey,
    dateBucket.totalVisitFollowups,
  ];
};
const getAverageRatingRowData = (dateBucket) => {
  return dateBucket.avgPatientExperienceRating;
};
const getHelpTaskCompletionRowData = (dateBucket) => {
  const coordCareCompletionRate =
    dateBucket.completedHelperCoordCare / dateBucket.totalHelperCoordCare;
  const coordMedsCompletionRate =
    dateBucket.completedHelperCoordMeds / dateBucket.totalHelperCoordMeds;
  return ((coordCareCompletionRate + coordMedsCompletionRate) / 2) * 100;
};

const rowConfig = [
  {
    text: 'Follow-up surveys completed',
    type: RowConfigType.FollowupSurvey,
    dataFn: getFollowupSurveyRowData,
  },
  {
    text: 'Average rating (1-5)',
    type: RowConfigType.AverageRating,
    dataFn: getAverageRatingRowData,
  },
  {
    text: 'Help task completion',
    type: RowConfigType.HelpTaskCompletion,
    hideInSummary: true,
    dataFn: getHelpTaskCompletionRowData,
  },
];

const getDateBucketDetailsData = (isLoading, dateBucket, data) => {
  if (isLoading) {
    return null;
  }
  return data.dateBuckets[dateBucket];
};

export default function PatientExperienceBanner({ title, text, firstTask }) {
  const [expand, setExpand] = useState(false);
  const { isLoading: isDataLoading, data: landingData } =
    useGetPatientExperienceLandingData();
  const toggleExpand = () => {
    setExpand(!expand);
  };
  const { mergeParameters } = useQueryParameters();
  const handleNextTask = () => {
    mergeParameters({ openFirstTask: true });
  };

  const dateBuckets =
    isDataLoading || landingData === null ? [] : landingData.dateBuckets;
  const [selectedDateBucket, setSelectedDateBucket] = useState(
    dateBuckets.length === 0 ? 6 : dateBuckets.length - 1,
  );
  const patientExperienceDetailsData = getDateBucketDetailsData(
    isDataLoading,
    selectedDateBucket,
    landingData,
  );

  const rows = rowConfig.map((config) => dateBuckets.map(config.dataFn));
  const columns =
    isDataLoading || landingData === null
      ? []
      : landingData.dateBuckets.map((b) => b.shortLabel);
  return expand ? (
    <BannerBackgroundWithMargin>
      <Stack
        width="100%"
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <BoxCenter marginBottom={1}>
          <Typography component="h1" variant="headermedium">
            {title}
          </Typography>
        </BoxCenter>
        <Section>
          <BoxCenter>
            <SectionText>Last 6 months</SectionText>
          </BoxCenter>
          <BannerBackground>
            <PatientExperienceSummary
              rowConfig={rowConfig}
              rows={rows}
              columns={columns}
              selectedMonth={selectedDateBucket}
              setSelectedMonth={setSelectedDateBucket}
            />
          </BannerBackground>
        </Section>
        <PatientExperienceDetails
          firstTask={firstTask}
          detailsData={patientExperienceDetailsData}
        />
        <BoxCenter>
          <Button
            variant="text"
            size="small"
            onClick={toggleExpand}
            data-pendo-id="px-banner-minimize"
          >
            minimize
          </Button>
        </BoxCenter>
      </Stack>
    </BannerBackgroundWithMargin>
  ) : (
    <BannerBackgroundWithMargin>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        width="100%"
      >
        <Box>
          <Typography component="h1" variant="headermedium">
            {title}
          </Typography>
          <Typography mt={1} mb={1}>
            {text}
          </Typography>
          <Box>
            <StyledButton
              variant="secondary"
              data-pendo-id="px-banner-learn-more"
              href={LEARN_MORE_LINK_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </StyledButton>
            <StyledButton
              onClick={handleNextTask}
              disabled={!firstTask}
              data-pendo-id="px-banner-go-to-next-task"
            >
              {GO_TO_NEXT_TASK_BUTTON_TEXT}
            </StyledButton>
          </Box>
        </Box>
        <Box alignItems="center" justifyItems="center" display="flex">
          <Stack direction="column">
            <Box width={700}>
              {isDataLoading ? (
                <SpinnerWrapper data-testid="loading-spinner">
                  <BaseSpinner size={40} />
                </SpinnerWrapper>
              ) : (
                <PatientExperienceSummary
                  rowConfig={rowConfig}
                  rows={rows}
                  columns={columns}
                  summary
                  selectedMonth={selectedDateBucket}
                  setSelectedMonth={setSelectedDateBucket}
                />
              )}
            </Box>
            <BoxCenter>
              <Button
                variant="text"
                size="small"
                onClick={toggleExpand}
                data-pendo-id="px-banner-more-details"
                sx={{ marginTop: (theme) => theme.spacing(2) }}
              >
                More details
              </Button>
            </BoxCenter>
          </Stack>
        </Box>
      </Stack>
    </BannerBackgroundWithMargin>
  );
}

PatientExperienceBanner.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  firstTask: userInputTaskShape,
};

PatientExperienceBanner.defaultProps = {
  firstTask: undefined,
};

const BannerBackgroundNoPadding = styled('div')`
  background-color: ${(p) => p.theme.palette.background.base};
  border: 1px solid ${(p) => p.theme.palette.border.base};
  border-radius: ${(p) => 2 * p.theme.shape.borderRadius}px;
`;

const BannerBackground = styled(BannerBackgroundNoPadding)`
  padding: ${(p) => p.theme.spacing(2, 3)};
`;

const BannerBackgroundWithMargin = styled(BannerBackground)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

const StyledButton = styled(Button)`
  padding: ${(p) => p.theme.spacing(0, 1)};
  margin: ${(p) => p.theme.spacing(1)};
`;

const Section = styled(Box)`
  width: 816px;
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

const BoxCenter = styled(Box)`
  text-align: center;
`;

const SectionText = styled(Typography)`
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

const SpinnerWrapper = styled('div')`
  margin-top: 60px;
  text-align: center;
`;
