import PropTypes from 'prop-types';
import {
  List,
  ListItemButton,
  Typography,
  listItemButtonClasses,
  styled,
} from '@mui/material';
import useId from '@mui/utils/useId';

import { ReactComponent as ChevronRightIcon } from '@/assets/icons/chevronRight.svg';
import {
  stepHistoryShape,
  stepShape,
} from '@/utils/transformFrontendCareGapAction';
import { actionShape } from '@/utils/transformFrontendAction';
import { ReactComponent as CheckTrueIcon } from '@/assets/icons/checkTrue.svg';
import { ActionStep } from '@/pages/TaskBasedWorkflow/constants';

import { ReactComponent as ArrowRightIcon } from './assets/arrowRight.svg';
import SvuAmount from './SvuAmount';

function pendoIdTransformer(templateStep) {
  switch (templateStep) {
    case 'schedule_visit':
      return 'worklist-patient-schedule-step';
    case 'complete_visit':
      return 'worklist-patient-complete-step';
    default:
      return 'worklist-action-card-step';
  }
}

function getDescription(scheduleType, scheduledDateString, description) {
  switch (scheduleType) {
    case 'schedule':
      return `${description} (screening scheduled for ${scheduledDateString}`;
    case 'refer':
      return `${description} (results expected by ${scheduledDateString}`;
    default:
      return `${description} (screening
      ${new Date() < new Date(scheduledDateString) ? 'is on' : 'was on'}
      ${scheduledDateString}`;
  }
}

function StepListItem({ onClick, selected, step, completed, preferred }) {
  const labelId = useId();
  const descriptionId = useId();
  const color = completed ? 'status.successForeground' : 'text.link';
  function getIcon() {
    if (completed) {
      return <StyledCheckTrueIcon data-testid="check-icon" />;
    }
    if (preferred) {
      return <ArrowRightIcon data-testid="arrow-right-icon" />;
    }
    return null;
  }
  const icon = getIcon();

  return (
    <StyledListItemButton
      aria-labelledby={labelId}
      aria-describedby={descriptionId}
      data-dd-synthetics-id={
        completed ? undefined : `tbw-step-${step.templateStep}`
      }
      data-pendo-id={pendoIdTransformer(step.templateStep)}
      key={step.stepId}
      onClick={onClick}
      selected={selected}
    >
      <Typography color={color}>{icon}</Typography>
      <Typography
        color={color}
        ml={1}
        component="span"
        id={labelId}
        variant={selected ? 'bodybold' : 'body'}
        sx={{ ml: icon ? 1 : 3 }}
      >
        {step.description}
      </Typography>

      <SvuAmount step={step} sx={{ ml: 'auto' }} completed={completed} />
      <StyledChevronRightIcon />
    </StyledListItemButton>
  );
}

StepListItem.propTypes = {
  step: stepShape.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  completed: PropTypes.bool,
  preferred: PropTypes.bool,
};

StepListItem.defaultProps = {
  completed: false,
  preferred: false,
};

export default function StepList({
  action,
  steps,
  onSelect,
  selectedAction,
  selectedStep,
  stepHistories,
  sx,
  preferredPath,
}) {
  let latestStepHistory;
  stepHistories.forEach((stepHistory) => {
    if (
      !latestStepHistory ||
      new Date(latestStepHistory.executedAt) < new Date(stepHistory.executedAt)
    ) {
      latestStepHistory = stepHistory;
    }
  });

  let stepHistoryToDisplay;
  if (
    latestStepHistory?.templateStep === ActionStep.scheduleVisit ||
    latestStepHistory?.templateStep === ActionStep.rescheduleVisit
  ) {
    const scheduledDateString = latestStepHistory.executionInfo.scheduledDate;
    stepHistoryToDisplay = {
      ...latestStepHistory,
      description: `${latestStepHistory.description} (appointment ${
        new Date() < new Date(scheduledDateString) ? 'is' : 'was'
      } ${scheduledDateString})`,
    };
  }

  const qualityScheduleTemplateSteps = [
    ActionStep.scheduleCCS,
    ActionStep.scheduleCHL,
    ActionStep.scheduleCOLO,
    ActionStep.scheduleDEE,
    ActionStep.scheduleOSTEO,
    ActionStep.scheduleBCS,
  ];
  if (qualityScheduleTemplateSteps.includes(latestStepHistory?.templateStep)) {
    const scheduledDateString =
      latestStepHistory.executionInfo.scheduledDate ||
      latestStepHistory.executionInfo.dateOfService;
    const specialtyProviderString =
      latestStepHistory.executionInfo.optometristName ||
      latestStepHistory.executionInfo.specialtyProvider ||
      '';
    const phoneNumberInputString =
      latestStepHistory.executionInfo.phoneNumberInput || '';
    const scheduleType = latestStepHistory.executionInfo?.scheduleType || '';
    const dateDescription = getDescription(
      scheduleType,
      scheduledDateString,
      latestStepHistory.description,
    );

    const providerDescription = `${
      specialtyProviderString === '' ? '' : ` with ${specialtyProviderString}`
    }`;
    const phoneDescription = `${
      phoneNumberInputString === '' ? '' : `, phone: ${phoneNumberInputString}`
    }`;
    const description = `${
      dateDescription + providerDescription + phoneDescription
    })`;

    stepHistoryToDisplay = {
      ...latestStepHistory,
      description,
    };
  }

  // using some to shortcircuit when found a matching
  const isPreferred = (stepId) => {
    return !!preferredPath.some((step) => step.stepId === stepId);
  };

  return (
    <StyledList sx={sx}>
      {stepHistoryToDisplay ? (
        <StepListItem
          key={stepHistoryToDisplay.id}
          step={stepHistoryToDisplay}
          completed
          selected={false}
          onClick={() => {}}
        />
      ) : null}
      {steps.map((step) => (
        <StepListItem
          selected={Boolean(
            selectedStep &&
              selectedAction &&
              step.stepId === selectedStep.stepId &&
              action.id === selectedAction.id,
          )}
          key={step.stepId}
          step={step}
          onClick={() => onSelect(step)}
          preferred={isPreferred(step.stepId)}
        />
      ))}
    </StyledList>
  );
}

StepList.propTypes = {
  action: actionShape.isRequired,
  steps: PropTypes.arrayOf(stepShape).isRequired,
  stepHistories: PropTypes.arrayOf(stepHistoryShape),
  onSelect: PropTypes.func.isRequired,
  selectedAction: actionShape,
  selectedStep: stepShape,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  preferredPath: PropTypes.arrayOf(stepShape),
};

StepList.defaultProps = {
  selectedAction: undefined,
  selectedStep: undefined,
  stepHistories: [],
  sx: undefined,
  preferredPath: [],
};

const StyledList = styled(List)`
  padding: 0;
`;

const StyledListItemButton = styled(ListItemButton)`
  padding: ${(p) => p.theme.spacing(1, 0, 1, 1)};
  border-bottom: 1px solid;
  border-bottom-color: ${(p) => p.theme.palette.border.base};

  &:hover {
    background-color: ${(p) => p.theme.palette.background.tableZebra};
    border-bottom-color: ${(p) => p.theme.palette.border.disabled};
  }

  &.${listItemButtonClasses.selected} {
    background-color: ${(p) => p.theme.palette.background.secondary};
    border-bottom-color: ${(p) => p.theme.palette.background.primary};

    &:hover {
      cursor: default;
      background-color: ${(p) => p.theme.palette.background.secondary};
    }
  }
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  height: 16px;
`;

const StyledCheckTrueIcon = styled(CheckTrueIcon)`
  width: ${(p) => p.theme.spacing(2)};
  vertical-align: middle;
`;
