import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import transformResponse from '@ot/utils/transformResponse';

async function fetchCurrentUserStatsJson() {
  const response = await axios.get('/svus/current_user_stats_json');
  return transformResponse(response.data);
}

export default function useGetCurrentUserStats() {
  return useQuery(['current-user-stats'], fetchCurrentUserStatsJson, {
    select: (response) => ({
      totalValue: response.totalValue,
      startDate: response.startDate,
      shouldShowModal: response.shouldShowModal,
    }),
  });
}
