import { Stack, Typography } from '@mui/material';

import formatNumber from '@/utils/formatNumber';

import getGoalCalculations from './utils/getGoalCalculations';
import useIsHistoricalData from './hooks/useIsHistoricalData';
import { goalShape } from './shapes';

function getBackgroundColor({ isComplete, isHistoricalData }) {
  if (isComplete) {
    return 'colorPool.emerald.500';
  }
  if (isHistoricalData) {
    return 'background.baseInverse';
  }
  return 'background.primaryMidnight';
}

export default function GoalTileHeader({ goal }) {
  const { availableSvu, awardedSvu } = goal;
  const { isComplete } = getGoalCalculations(goal);
  const isHistoricalData = useIsHistoricalData();

  // The cases below check if the SVU feature flags are turned off and render nothing if so.
  // FFs: show_goals_available_svu_homepage, show_goals_awarded_svu_homepage
  if (isHistoricalData) {
    if (!awardedSvu) return null;
  } else {
    if (isComplete && !awardedSvu) return null;
    if (!isComplete && !availableSvu) return null;
  }

  return (
    <Stack
      sx={{
        bgcolor: getBackgroundColor({ isComplete, isHistoricalData }),
        width: '100%',
        textAlign: 'center',
        height: '40px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        component="span"
        variant="bodymedium"
        color="text.primaryInverse"
        sx={{
          // to help with when the text is split into two lines, fitting it in the div nicely
          lineHeight: '0.5rem',
        }}
      >
        {isComplete || isHistoricalData ? (
          <>
            <Typography component="span" variant="bodymediumbold">
              {formatNumber(awardedSvu)}
            </Typography>{' '}
            SVU earned
          </>
        ) : (
          <>
            Estimated
            <Typography component="span" variant="bodymediumbold">
              {' '}
              {formatNumber(availableSvu)} SVU
            </Typography>{' '}
            potential
          </>
        )}
      </Typography>
    </Stack>
  );
}

GoalTileHeader.propTypes = {
  goal: goalShape.isRequired,
};
