import { createSelector } from 'reselect';
import { pick, reduce, uniq } from 'lodash';

import { useQueryParameters } from '@/contexts/QueryParametersContext';

import { FILTER_KEYS } from '../constants';

/**
 * Returns the number of applied filters.
 */
const filterCountSelector = createSelector(
  (parameters) => parameters,
  (parameters) => {
    const filters = pick(parameters, FILTER_KEYS);
    const { actionSubtypeStates = [], ...otherFilters } = filters;

    // The filter count should only include unique action subtypes.
    // E.g. `actionSubtypeStates = ['adult_bmi,incomplete', 'adult_bmi,complete']` counts as one filter.
    const uniqueActionSubtypes = uniq(
      actionSubtypeStates.map((x) => x.slice(',')[0]),
    );

    return (
      uniqueActionSubtypes.length +
      reduce(otherFilters, (acc, value) => acc + value.length, 0)
    );
  },
);

export default function useFilterCount() {
  const { parameters } = useQueryParameters();

  return filterCountSelector(parameters);
}
