import { string } from 'prop-types';
import { FormLabel } from '@mui/material';
import useId from '@mui/utils/useId';

import RhfInput from '@/components/reactHookFormComponents/RhfInput';
import InputContainer from '@/pages/PaymentInstructions/components/InputContainer';

export default function SignatureInput({ name, control }) {
  const signatureId = useId();

  return (
    <InputContainer>
      <FormLabel htmlFor={signatureId}>Print name</FormLabel>
      <RhfInput
        name={name}
        id={signatureId}
        control={control}
        rules={{
          required: true,
        }}
      />
    </InputContainer>
  );
}

SignatureInput.propTypes = {
  name: string.isRequired,
};
