function transformNestedChallenges(nestedChallenges) {
  return nestedChallenges?.map((nestedChallenge) => {
    return {
      description: nestedChallenge.description,
      goal: nestedChallenge.goal,
      gridPosition: nestedChallenge.grid_position,
      id: nestedChallenge.id,
      name: nestedChallenge.name,
      progress: nestedChallenge.progress,
      svuAward: nestedChallenge.svu_award,
      status: nestedChallenge.status,
      url: nestedChallenge.url_for_hit_row,
      pendoId: nestedChallenge.pendo_id,
    };
  });
}

function transformStampCardChallenges(challenges) {
  return challenges.map((challenge) => ({
    id: challenge.id,
    name: challenge.name,
    type: challenge.challenge_type,
    status: challenge.status,
    progress: challenge.progress,
    goal: challenge.goal,
    officeName: challenge.office_name,
    startDate: challenge.start_date,
    daysRemaining: challenge.days_remaining,
    endDate: challenge.end_date,
    templateName: challenge.template_name,
    svuAward: challenge.svu_award,
    plans: challenge.plans,
    patientTag: challenge.patient_tag,
    hidden: challenge.hidden,
    description: challenge.description,
    expired: challenge.expired,
    isUserChallenge: challenge.is_user_challenge,
    conditionGroupNames: challenge.condition_group_names,
    gridSize: challenge?.grid_size,
    nestedChallenges: transformNestedChallenges(challenge?.nested_challenges),
    pendoId: challenge.pendo_id,
  }));
}
export default function transformStampCardChallengesResponse(response) {
  return {
    activeChallenges: transformStampCardChallenges(response.active_challenges),
    pendingReviewChallenges: transformStampCardChallenges(
      response.pending_review_challenges,
    ),
    completedChallenges: transformStampCardChallenges(
      response.completed_challenges,
    ),
    expiredChallenges: transformStampCardChallenges(
      response.expired_challenges,
    ),
  };
}
