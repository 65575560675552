import { TableCell, TableRow, Typography } from '@mui/material';
import { formatNumber } from 'chart.js/helpers';
import { meanBy } from 'lodash';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

import SvuHistoryChart, { MONTHS_TO_SHOW } from './SvuHistoryChart';
import BaseTable from './BaseTable';
import fillHistory from './fillHistory';

function SvuHistory({ medicalGroupName, medicalGroupHistory, userHistory }) {
  // only this month or no months
  if (medicalGroupHistory.length <= 1) {
    return null;
  }

  // Only last month
  if (medicalGroupHistory.length === 2) {
    const date = parseISO(medicalGroupHistory[0].startDate);
    const medicalGroupTotal = medicalGroupHistory[0].totalValue;

    // if user has values from last month use it otherwise 0.
    const userTotal = userHistory.length === 2 ? userHistory[0].totalValue : 0;

    return (
      <BaseTable title="History" mgHeader={medicalGroupName} userHeader="You">
        <TableRow>
          <TableCell>
            <Typography color="text.secondary" variant="bodymedium">
              {format(date, 'LLLL')}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography color="status.infoForeground" variant="metricmedium">
              {formatNumber(medicalGroupTotal)}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography color="svu.dark" variant="metricmedium">
              {formatNumber(userTotal)}
            </Typography>
          </TableCell>
        </TableRow>
      </BaseTable>
    );
  }

  const mgAvg = meanBy(medicalGroupHistory, (x) => Number(x.totalValue));
  const youAvg = meanBy(userHistory, (x) => Number(x.totalValue));

  const filledMedicalGroupHistory = fillHistory(medicalGroupHistory, 6);
  const filledUserHistory = fillHistory(userHistory, MONTHS_TO_SHOW);

  return (
    <BaseTable title="History" mgHeader={medicalGroupName} userHeader="You">
      <TableRow>
        <TableCell>
          <Typography color="text.secondary" variant="bodymedium">
            Monthly average
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography color="status.infoForeground" variant="metricmedium">
            {formatNumber(mgAvg, 'en-US', { maximumFractionDigits: 2 })}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography color="svu.dark" variant="metricmedium">
            {formatNumber(youAvg, 'en-US', { maximumFractionDigits: 2 })}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell />
        <TableCell align="right">
          <SvuHistoryChart
            color="status.infoForeground"
            months={filledMedicalGroupHistory}
          />
        </TableCell>
        <TableCell align="right">
          <SvuHistoryChart color="svu.dark" months={filledUserHistory} />
        </TableCell>
      </TableRow>
    </BaseTable>
  );
}

export default SvuHistory;

SvuHistory.propTypes = {
  medicalGroupName: PropTypes.string.isRequired,
  medicalGroupHistory: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  userHistory: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
};
