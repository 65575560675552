import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import BaseAutocomplete from '@/components/BaseAutocomplete';

export default function RhfSingleSelectAutocomplete({
  options = [],
  getOptionLabel,
  control,
  defaultValue,
  name,
  renderOption,
  id,
  isOptionEqualToValue,
  disabled,
  'data-pendo-id': dataPendoId,
  sx,
  rules,
}) {
  return (
    <Controller
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <BaseAutocomplete
          id={id}
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          onChange={(event, values) => onChange(values)}
          value={value}
          disabled={disabled}
          isOptionEqualToValue={isOptionEqualToValue}
          data-pendo-id={dataPendoId}
          sx={sx}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
}

RhfSingleSelectAutocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ]),
  ).isRequired,
  getOptionLabel: PropTypes.func,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  renderOption: PropTypes.func,
  id: PropTypes.string,
  isOptionEqualToValue: PropTypes.func,
  disabled: PropTypes.bool,
  'data-pendo-id': PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RhfSingleSelectAutocomplete.defaultProps = {
  getOptionLabel: undefined,
  defaultValue: undefined,
  renderOption: undefined,
  id: undefined,
  isOptionEqualToValue: undefined,
  disabled: false,
  'data-pendo-id': undefined,
  sx: undefined,
};
