import { TablePagination } from '@mui/material';
import PropTypes from 'prop-types';

export default function StyledTablePagination({
  numRows,
  rowsPerPage,
  page,
  handlePageChange,
  rowsPerPageOptions,
  sx,
}) {
  return (
    <TablePagination
      count={numRows}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      page={page}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page',
        },
        native: true,
      }}
      onPageChange={handlePageChange}
      sx={{
        backgroundColor: 'background.base',
        border: '1px solid',
        borderColor: 'border.base',
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        ...sx,
      }}
      component="div"
    />
  );
}

StyledTablePagination.propTypes = {
  numRows: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

StyledTablePagination.defaultProps = {
  sx: undefined,
};
