import { useToggle } from 'react-use';
import {
  List,
  ListItemButton,
  Typography,
  listItemButtonClasses,
  styled,
} from '@mui/material';
import propTypes from 'prop-types';

import BaseLinkButton from '@/components/BaseLinkButton';
import { ReactComponent as ChevronUpIcon } from '@/assets/icons/chevronUp.svg';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/chevronDown.svg';
import { color, spacing, typography } from '@/utils/stylingUtils';

export default function WriteStatusCell({
  choices,
  status,
  extraDetail,
  open,
  setOpen,
}) {
  const [choicesOpen, toggleChoicesOpen] = useToggle(false);
  const handleUpdateClick = () => {
    toggleChoicesOpen();
    if (open) {
      setOpen(false);
    }
  };

  return (
    <>
      <StyledLinkButton onClick={handleUpdateClick}>
        {status}
        {choicesOpen ? <StyledChevronUpIcon /> : <StyledChevronDownIcon />}
      </StyledLinkButton>
      <Typography variant="bodysmall">{extraDetail}</Typography>
      {choicesOpen && (
        <StyledList
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
        >
          {choices.map((choice) => (
            <StyledListItemButton
              key={choice.value}
              onClick={() => setOpen(choice.value)}
              selected={open === choice.value}
            >
              {choice.label}
            </StyledListItemButton>
          ))}
        </StyledList>
      )}
    </>
  );
}

WriteStatusCell.propTypes = {
  choices: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.string,
      label: propTypes.string,
    }),
  ).isRequired,
  status: propTypes.string.isRequired,
  extraDetail: propTypes.string,
  open: propTypes.oneOfType([propTypes.bool, propTypes.string]),
  setOpen: propTypes.func,
};

WriteStatusCell.defaultProps = {
  open: false,
  setOpen: () => {},
  extraDetail: undefined,
};

const StyledLinkButton = styled(BaseLinkButton)`
  color: ${color('text.link')};
  text-decoration: none;
  padding: ${spacing(1)};

  &:hover {
    background-color: ${color('background.tableZebra')};
  }
`;

const StyledList = styled(List)`
  padding: 0;
`;

const StyledListItemButton = styled(ListItemButton)`
  width: 100%;
  text-align: left;
  padding: ${spacing(1)};
  border-bottom: 1px solid;
  border-bottom-color: ${color('border.base')};
  color: ${color('text.link')};
  text-decoration: none;

  &:hover {
    background-color: ${color('background.tableZebra')};
    border-bottom-color: ${color('border.disabled')};
  }

  &.${listItemButtonClasses.selected} {
    ${typography('bodybold')}
    background-color: ${color('background.base')};

    &:hover {
      cursor: default;
      background-color: ${color('background.secondary')};
    }
  }
`;

const StyledChevronUpIcon = styled(ChevronUpIcon)`
  height: 16px;
  padding: 0 4px;
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  height: 16px;
`;
