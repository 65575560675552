import PropTypes from 'prop-types';
import { FormLabel } from '@mui/material';
import useId from '@mui/utils/useId';

import RhfInput from '@/components/reactHookFormComponents/RhfInput';
import InputContainer from '@/pages/PaymentInstructions/components/InputContainer';
import routingNumberCheckSum from '@/pages/PaymentInstructions/utils/routingNumberCheckSum';
import FormErrorMessage from '@/components/FormErrorMessage';

export default function RoutingNumberInput({ name, control, formErrors }) {
  const routingNumberId = useId();

  return (
    <InputContainer>
      <FormLabel htmlFor={routingNumberId}>
        Bank account routing number
      </FormLabel>
      <RhfInput
        name={name}
        id={routingNumberId}
        control={control}
        rules={{
          required: true,
          validate: {
            validateRoutingNumber: (v) =>
              routingNumberCheckSum(v) || 'Invalid routing number.',
          },
        }}
      />
      <FormErrorMessage errors={formErrors} name={name} />
    </InputContainer>
  );
}

RoutingNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  formErrors: PropTypes.objectOf(PropTypes.object),
};

RoutingNumberInput.defaultProps = { formErrors: {} };
