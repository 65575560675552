import { useCallback } from 'react';

import { useQueryParameters } from '@/contexts/QueryParametersContext';

export default function usePagination(paginationKey) {
  const { parameters, mergeParameters } = useQueryParameters();
  const page = parameters[paginationKey] || 1;

  const setPage = useCallback(
    (newPage) => {
      mergeParameters({ [paginationKey]: newPage });
    },
    [mergeParameters, paginationKey],
  );

  return [page, setPage];
}
