import { Typography } from '@mui/material';
import propTypes from 'prop-types';

export default function MeasureCell({
  text,
  date,
  providerName,
  measureTextValues,
}) {
  return (
    <>
      <Typography component="h4" variant="headersmall">
        {text}
      </Typography>
      {(date || providerName) && (
        <Typography variant="bodysmall">
          {measureTextValues.join(' ')}
        </Typography>
      )}
    </>
  );
}

MeasureCell.propTypes = {
  text: propTypes.string.isRequired,
  date: propTypes.string,
  providerName: propTypes.string,
  measureTextValues: propTypes.arrayOf(propTypes.string).isRequired,
};

MeasureCell.defaultProps = {
  date: undefined,
  providerName: undefined,
};
