import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { range } from 'lodash';
import Button from '@mui/material/Button';

const WINDOW_MIN = 1; // The minimum number in the window.
const WINDOW_WIDTH = 5; // The width of the window. MUST be an odd number.
const WINDOW_RADIUS = Math.floor(WINDOW_WIDTH / 2); // The distance from the middle of the window to the lower or upper bound.

function rangeInclusive(start, end) {
  return range(start, end + 1);
}

/**
 * Build a sliding window of numbers.
 * @param {number} page
 * @param {number} total
 * @returns {number[]}
 */
function buildWindow(page, total) {
  // The total is less than the window width.
  // Example: If page=1 and total=3, returns [*1*, 2, 3]
  if (total <= WINDOW_WIDTH) {
    return rangeInclusive(WINDOW_MIN, total);
  }

  // The current page is near the bottom of the range.
  // Example: If page=1 and total=10, returns [*1*, 2, 3, 4, 5]
  if (page - WINDOW_MIN < WINDOW_RADIUS) {
    return rangeInclusive(WINDOW_MIN, WINDOW_WIDTH);
  }

  // The current page is near the top of the range.
  // Example: If page=10 and total=10, returns [6, 7, 8, 9, *10*]
  if (total - page < WINDOW_RADIUS) {
    return rangeInclusive(total - WINDOW_WIDTH + 1, total);
  }

  // The current page is near the middle of the range.
  // Example: If page=5 and total=10, returns [3, 4, *5*, 6, 7]
  return rangeInclusive(page - WINDOW_RADIUS, page + WINDOW_RADIUS);
}

/**
 * A controlled Pagination component that shows a sliding window of up to 5 pages at a time.
 *
 * Important: `page` is 1-indexed.
 */
function BasePagination({ className, page, total, onChange }) {
  // Build a sliding window of numbers
  const numbers = useMemo(() => buildWindow(page, total), [page, total]);

  const handleClickNext = (e) => {
    onChange(e, page + 1);
  };
  const handleClickPrevious = (e) => {
    onChange(e, page - 1);
  };

  return (
    <nav className={className}>
      <StyledList>
        <li>
          <StyledButton
            aria-label="Go to previous page"
            disabled={page === 1}
            onClick={handleClickPrevious}
            size="small"
            variant="outlined"
          >
            Previous
          </StyledButton>
        </li>
        {numbers.map((number) => {
          const isCurrentPage = number === page;
          const ariaLabel = isCurrentPage
            ? `Page ${number}`
            : `Go to page ${number}`;
          return (
            <li key={number}>
              <StyledButton
                aria-current={isCurrentPage}
                aria-label={ariaLabel}
                onClick={(e) => onChange(e, number)}
                size="small"
                variant={isCurrentPage ? 'contained' : 'outlined'}
              >
                {number}
              </StyledButton>
            </li>
          );
        })}
        <li>
          <StyledButton
            aria-label="Go to next page"
            disabled={page === total}
            onClick={handleClickNext}
            size="small"
            variant="outlined"
          >
            Next
          </StyledButton>
        </li>
      </StyledList>
    </nav>
  );
}

BasePagination.propTypes = {
  className: PropTypes.string,
  /**
   * The current page, 1-indexed.
   */
  page: PropTypes.number.isRequired,
  total: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

BasePagination.defaultProps = {
  className: undefined,
  total: 1,
};

const StyledList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    flex-grow: 0;
    margin: 0 6px;
  }
`;

const StyledButton = styled(Button)`
  min-width: 30px;
`;

export default BasePagination;
