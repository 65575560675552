import { Box, Typography } from '@mui/material';
import { string } from 'prop-types';

export default function ContextualInfoLabel({ label, contextualInfoVal }) {
  return (
    <Box mr={5}>
      <Typography variant="bodysmall" color="text.label">
        {label}
      </Typography>
      <Typography>{contextualInfoVal}</Typography>
    </Box>
  );
}

ContextualInfoLabel.propTypes = {
  label: string.isRequired,
  contextualInfoVal: string,
};

ContextualInfoLabel.defaultProps = {
  contextualInfoVal: undefined,
};
