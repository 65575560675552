import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import produce from 'immer';

/**
 * Prepare the execution input to be submitted by removing blank fields.
 */
const prepareData = produce((draft, reqs) => {
  Object.keys(draft).forEach((key) => {
    const requirement = reqs.find((req) => req.name === key);
    if (requirement?.expected_backend_type === 'str' && !draft[key]) {
      delete draft[key];
    }
  });
});

async function submitUserDrivenEnrollmentForm({
  patientId,
  subtype,
  executionInput,
  executionRequirements,
}) {
  const data = prepareData(executionInput, executionRequirements);
  return axios.post(`/api/patient/${patientId}/user-driven-enrollment`, {
    subtype,
    execution_input: data,
  });
}

export default function useUpdateUserDrivenEnrollmentForms({
  onSuccess = undefined,
} = {}) {
  return useMutation(submitUserDrivenEnrollmentForm, {
    onSuccess,
  });
}
