/* eslint-disable no-shadow */
import { get } from 'lodash';

/**
 * Given a theme-based spacing number, return the literal pixels that spacing
 * represents in the application theme.
 *
 * Useful for writing styled components.
 *
 * @example
 * ```js
 * const MyStyledComponent = styled('div')`
 *   padding: ${spacing(1)} ${spacing(2)};
 * `;
 * ```
 *
 * @param num {number}
 * @returns a higher-order function that returns the multiplied spacing
 */
export const spacing =
  (num) =>
  ({ theme }) =>
    theme.spacing(num);

/**
 * Given a named color from the application's theme, return the literal
 * color code from the theme.
 *
 * Useful for writing styled components.
 *
 * @example
 * ```js
 * const MyStyledComponent = styled('div')`
 *   background-color: ${color('theme.base')};
 * `;
 * ```
 *
 * @param color {string}
 * @returns a higher-order function that retrieves the named color
 */
export const color =
  (color) =>
  ({ theme }) =>
    get(theme.palette, color);

/**
 * Given a named property from the typography theme subsection, return the
 * literal matching that key or keys.
 *
 * @example
 * ```js
 * const MyStyledComponent = styled('div')`
 *   ${typography('bodymedium') // import a whole set of styles
 *   font-weight: ${typography('bodymedium.fontWeight)}; // or just one property
 * `
 * ```
 *
 * @param keyOrKeys
 * @returns {function({theme: *}): *}
 */
export const typography =
  (keyOrKeys) =>
  ({ theme }) =>
    get(theme.typography, keyOrKeys);
