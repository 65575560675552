import { startCase } from 'lodash';

export default function buildPydanticErrorMessages(obj) {
  const messages = [];

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(messages, buildPydanticErrorMessages(value));
    } else {
      messages.push(`${startCase(key)} has an error: ${value[0]}.`);
    }
  });

  return messages;
}
