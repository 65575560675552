import { arrayOf, func, number, object } from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { patientShape } from '@/utils/shapes';
import { isIcdCodeRelevant } from '@/utils/diagnosisUtils';
import useFeatureFlag from '@/utils/useFeatureFlag';

import useHccTrumpingMap from '../../hooks/useHccTrumpingMap';
import DiagnosisFormSection from '../DiagnosisFormSection';
import { Checkbox } from '../DiagnosisActionFormCheckbox';

import DiagnosisIcdSearch from './DiagnosisIcdSearch';
import { RelatedConditionSeverityMessage } from './DiagnosisSeverityMessage';

const COMPLETE_WITHOUT_CODE_OPTION = {
  label: 'Complete without code',
  value: Symbol.for('COMPLETE_WITHOUT_CODE'),
};

function DiagnosisRelatedConditionSearch({
  diagnosisAction,
  onSelectCondition,
  onSetCompleteWithoutCode,
  patient,
  relatedCondition,
  selectedHcc,
}) {
  const hccTrumping = useHccTrumpingMap(patient);
  const [userSelectedCondition, setUserSelectedCondition] = useState(null);
  const allowNoCode = useFeatureFlag('patientSummaryV2MorphDxWithoutCode');

  const isRelevant = (condition) => {
    return isIcdCodeRelevant(
      condition,
      relatedCondition.code,
      diagnosisAction,
      hccTrumping,
    );
  };

  const handleChecked = (e, checked) => {
    const relevant = isRelevant(userSelectedCondition);
    if (userSelectedCondition === COMPLETE_WITHOUT_CODE_OPTION) {
      onSetCompleteWithoutCode(checked, relatedCondition.code);
    } else if (relevant) {
      onSelectCondition(userSelectedCondition);
    }
  };

  const handleSelectSearchResult = (result) => {
    setUserSelectedCondition(result);
    const relevant = isRelevant(result);

    if (result === COMPLETE_WITHOUT_CODE_OPTION) {
      onSetCompleteWithoutCode(true, relatedCondition.code);
    } else if (relevant) {
      onSelectCondition(result);
    } else {
      onSelectCondition(null);
    }
  };

  const additionalResults = useMemo(() => {
    return allowNoCode ? [COMPLETE_WITHOUT_CODE_OPTION] : [];
  }, [allowNoCode]);

  return (
    <>
      <Checkbox
        disabled={!userSelectedCondition || !isRelevant(userSelectedCondition)}
        onChange={handleChecked}
        checked={selectedHcc === relatedCondition.code}
        label={
          <Typography variant="bodymedium">{relatedCondition.name}</Typography>
        }
      />
      <Box my={0.5} ml={3}>
        <DiagnosisIcdSearch
          additionalResults={additionalResults}
          hcc={relatedCondition.code}
          onSelectIcd={handleSelectSearchResult}
          patient={patient}
          selectedIcd={userSelectedCondition}
          actionId={diagnosisAction.id}
        />
        {userSelectedCondition ? (
          <RelatedConditionSeverityMessage
            condition={userSelectedCondition}
            diagnosisAction={diagnosisAction}
            hccTrumping={hccTrumping}
            relatedCondition={relatedCondition}
          />
        ) : null}
      </Box>
    </>
  );
}
DiagnosisRelatedConditionSearch.propTypes = {
  diagnosisAction: object.isRequired,
  onSelectCondition: func.isRequired,
  onSetCompleteWithoutCode: func.isRequired,
  patient: patientShape.isRequired,
  relatedCondition: object.isRequired,
  selectedHcc: number,
};
DiagnosisRelatedConditionSearch.defaultProps = {
  selectedHcc: null,
};

export default function DiagnosisRelatedConditionSection({
  diagnosisAction,
  onSelectCondition,
  onSetCompleteWithoutCode,
  patient,
  relatedConditions,
  selectedHcc,
}) {
  if (isEmpty(relatedConditions)) return null;

  return (
    <DiagnosisFormSection header="Change to a related condition">
      {relatedConditions.map((condition) => {
        return (
          <DiagnosisRelatedConditionSearch
            key={condition.code}
            diagnosisAction={diagnosisAction}
            onSelectCondition={onSelectCondition}
            onSetCompleteWithoutCode={onSetCompleteWithoutCode}
            patient={patient}
            relatedCondition={condition}
            selectedHcc={selectedHcc}
          />
        );
      })}
    </DiagnosisFormSection>
  );
}

DiagnosisRelatedConditionSection.propTypes = {
  diagnosisAction: object.isRequired,
  onSelectCondition: func.isRequired,
  onSetCompleteWithoutCode: func.isRequired,
  patient: patientShape.isRequired,
  relatedConditions: arrayOf(object.isRequired).isRequired,
  selectedHcc: number,
};
DiagnosisRelatedConditionSection.defaultProps = {
  selectedHcc: null,
};
