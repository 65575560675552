import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { ThemeProvider } from '@mui/material';

import BaseRadioGroup from '@/components/BaseRadioGroup';
import BaseRadioButton from '@/components/BaseRadioButton';
import themeV2 from '@/themeV2';

export default function RhfRadioGroup({
  name,
  control,
  defaultValue,
  rules,
  pendoIdPrefixer,
  ...RadioGroupProps
}) {
  return (
    <ThemeProvider theme={themeV2}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <BaseRadioGroup
            name={name}
            onChange={(event, item) => {
              field.onChange(item);
            }}
            value={field.value || ''}
            data-pendo-id={pendoIdPrefixer(field.value.replace(/_/g, '-'))}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...RadioGroupProps}
          />
        )}
      />
    </ThemeProvider>
  );
}

RhfRadioGroup.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  name: PropTypes.string,
  // An array of the props to pass to each BaseRadioButton component
  options: PropTypes.arrayOf(PropTypes.shape(BaseRadioButton.propTypes))
    .isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  pendoIdPrefixer: PropTypes.func,
};
RhfRadioGroup.defaultProps = {
  className: undefined,
  defaultValue: '',
  label: undefined,
  name: undefined,
  sx: { ml: 1 },
  pendoIdPrefixer: () => {},
};
