import { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

function TransitionLeft(props) {
  // eslint-disable-next-line
  return <Slide {...props} direction="left" />;
}

function SnackbarNotification({ openSnackbar, errorCount }) {
  const [isOpen, setIsOpen] = useState(openSnackbar);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  const actionSnackbar = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseSnackbar}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={TransitionLeft}
      message={
        errorCount === 0
          ? 'Bulk updates completed successfully'
          : `Bulk updates completed, but with problems`
      }
      action={actionSnackbar}
    />
  );
}

SnackbarNotification.propTypes = {
  openSnackbar: PropTypes.bool,
  errorCount: PropTypes.number,
};

SnackbarNotification.defaultProps = {
  openSnackbar: false,
  errorCount: 0,
};

export default SnackbarNotification;
