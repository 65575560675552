import { INTERNAL_PERFORMANCE_METRIC_TO_COPY } from '../constants';

import getLink from './getLink';
import getPercentCompleted from './getPercentCompleted';

export default function getGoalCalculations(goal) {
  const { buttonCopy, title: goalTitle } =
    INTERNAL_PERFORMANCE_METRIC_TO_COPY[goal.internalPerformanceMetric];
  const percentCompleted = getPercentCompleted(goal);
  const isComplete = percentCompleted >= 100;
  const link = getLink(goal);

  return {
    buttonCopy,
    goalTitle,
    percentCompleted,
    isComplete,
    link,
  };
}
