import { Box, styled } from '@mui/material';
import { bool } from 'prop-types';

import { spacing } from '@/utils/stylingUtils';

import { singleColumnDefinition } from './propTypes';

const StyledTableCardLabel = styled(Box)`
  ${({ theme }) => theme.typography.label}
  padding: ${spacing(0.5)} ${spacing(1)};
`;

export default function TableCardLabel({ columnDefinition, narrow }) {
  const label =
    typeof columnDefinition.label === 'function'
      ? columnDefinition.label({ narrow })
      : columnDefinition.label;

  return <StyledTableCardLabel>{label}</StyledTableCardLabel>;
}
TableCardLabel.propTypes = {
  columnDefinition: singleColumnDefinition.isRequired,
  narrow: bool.isRequired,
};
