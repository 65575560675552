import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'axios';

type QueryKey = Array<string>;

type PatientResult = {
  id: number;
  member_number: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  gender: 'M' | 'F';
  provider: {
    id: number;
    name: string;
  };
  plan: {
    id: number;
    name: string;
  };
};

type Data = {
  results: Array<PatientResult>;
};

export type Patient = {
  id: number;
  memberNumber: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: 'M' | 'F';
  provider: {
    id: number;
    name: string;
  };
  plan: {
    id: number;
    name: string;
  };
};

const fetchPatients = async ({ queryKey }: QueryFunctionContext<QueryKey>) => {
  const [, , query] = queryKey;
  const response = await axios.get<Data>('/api/search/patients', {
    params: { query, page_size: 10 },
  });
  return response.data;
};

function transformData(data: Data): Array<Patient> {
  const { results } = data;
  return results.map((result) => ({
    id: result.id,
    memberNumber: result.member_number,
    firstName: result.first_name,
    lastName: result.last_name,
    dateOfBirth: result.date_of_birth,
    gender: result.gender,
    provider: result.provider,
    plan: result.plan,
  }));
}

export default function useGetSearchPatients(query: string) {
  return useQuery({
    queryFn: fetchPatients,
    queryKey: ['search', 'patients', query],
    select: transformData,
    enabled: Boolean(query),
  });
}
