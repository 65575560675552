import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { find } from 'lodash';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import useGetFundingPlans from '@ot/components/FundingPlanDropDown/useGetFundingPlans';

const PRACTICE_CHALLENGE = 'practice';
const USER_CHALLENGE = 'user';

function VerifyChallengesTable({
  challenges,
  onSetChallenges,
  challengeType,
  showPatientTag,
}) {
  const { data: planData, isLoading: fundingPlanNameLoading } =
    useGetFundingPlans();

  const getFundingPlanName = (id) => {
    if (fundingPlanNameLoading) {
      return 'loading...';
    }
    const plan = find(planData.results, { id });

    return plan?.name ?? id.toString();
  };

  const deleteRow = (row) => {
    const challengesCopy = challenges.filter((challenge) => {
      return challenge.row_num !== row;
    });
    onSetChallenges(challengesCopy);
  };
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 700 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Reward Text</TableCell>
            {challengeType === USER_CHALLENGE && <TableCell>User Id</TableCell>}
            {challengeType === USER_CHALLENGE && (
              <TableCell>User Name</TableCell>
            )}
            {challengeType === USER_CHALLENGE && (
              <TableCell>Attributed Medical Group</TableCell>
            )}
            {challengeType === PRACTICE_CHALLENGE && (
              <TableCell>Medical Group Id</TableCell>
            )}
            {challengeType === PRACTICE_CHALLENGE && (
              <TableCell>Medical Group Name</TableCell>
            )}
            {challengeType === PRACTICE_CHALLENGE && (
              <TableCell>Office Id</TableCell>
            )}
            {challengeType === PRACTICE_CHALLENGE && (
              <TableCell>Office Name</TableCell>
            )}
            {challengeType === PRACTICE_CHALLENGE && (
              <TableCell>SVU Awarded To</TableCell>
            )}
            <TableCell>SVU Award</TableCell>
            <TableCell>Plans</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Reveal Date</TableCell>
            <TableCell>Goal</TableCell>
            <TableCell>Hidden</TableCell>
            <TableCell>Template</TableCell>
            {showPatientTag && <TableCell>Patient Tag</TableCell>}
            <TableCell>Type</TableCell>
            <TableCell>Should Auto Approve</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {challenges &&
            challenges.map((challenge) => {
              return (
                <TableRow key={challenge.row_num}>
                  <TableCell style={{ width: 20 }}>
                    <Box
                      sx={{ height: 16, cursor: 'pointer' }}
                      onClick={() => deleteRow(challenge.row_num)}
                    >
                      <CloseIcon width="16px" height="16px" />
                    </Box>
                  </TableCell>
                  <TableCell>{challenge.name}</TableCell>
                  <TableCell>{challenge.description}</TableCell>
                  <TableCell>{challenge.reward_text}</TableCell>
                  {challengeType === USER_CHALLENGE && (
                    <TableCell>{challenge.user_id}</TableCell>
                  )}
                  {challengeType === USER_CHALLENGE && (
                    <TableCell>{challenge.user_name}</TableCell>
                  )}
                  {challengeType === USER_CHALLENGE && (
                    <TableCell>
                      {challenge.attributed_medical_group_id}:{' '}
                      {challenge.attributed_medical_group_name}
                    </TableCell>
                  )}
                  {challengeType === PRACTICE_CHALLENGE && (
                    <TableCell>{challenge.medical_group_id}</TableCell>
                  )}
                  {challengeType === PRACTICE_CHALLENGE && (
                    <TableCell>{challenge.medical_group_name}</TableCell>
                  )}
                  {challengeType === PRACTICE_CHALLENGE && (
                    <TableCell>{challenge.office_id}</TableCell>
                  )}
                  {challengeType === PRACTICE_CHALLENGE && (
                    <TableCell>{challenge.office_name}</TableCell>
                  )}
                  {challengeType === PRACTICE_CHALLENGE && (
                    <TableCell>{challenge.svus_awarded_to}</TableCell>
                  )}
                  <TableCell>{challenge.svu_award}</TableCell>

                  <TableCell>
                    {challenge.challenge_plans.map((challengePlan) => {
                      const isFunded =
                        challengePlan.plan_id !== challengePlan.funding_plan_id;
                      const fundingPlanName = getFundingPlanName(
                        challengePlan.funding_plan_id,
                      );
                      return (
                        <StyledSpan key={challengePlan.plan_id}>
                          {challengePlan.plan_id}:{' '}
                          {challenge.plan_names[challengePlan.plan_id]}{' '}
                          {isFunded && `(${fundingPlanName})`}
                        </StyledSpan>
                      );
                    })}
                  </TableCell>

                  <TableCell>{challenge.start_date}</TableCell>
                  <TableCell>{challenge.end_date}</TableCell>
                  <TableCell>{challenge.reveal_date}</TableCell>
                  <TableCell>{challenge.goal}</TableCell>
                  <TableCell>{challenge.hidden.toString()}</TableCell>
                  {challenge.template_id ? (
                    <TableCell>
                      {challenge.template_id}: {challenge.template_name}
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                  {showPatientTag && challenge.patient_tag_id ? (
                    <TableCell>
                      {challenge.patient_tag_id}: {challenge.patient_tag_name}
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                  <TableCell>{challenge.challenge_type}</TableCell>
                  <TableCell>{challenge.auto_approves.toString()}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default VerifyChallengesTable;
VerifyChallengesTable.propTypes = {
  challenges: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSetChallenges: PropTypes.func.isRequired,
  challengeType: PropTypes.string.isRequired,
  showPatientTag: PropTypes.bool,
};
VerifyChallengesTable.defaultProps = {
  showPatientTag: false,
};

const StyledSpan = styled.span`
  display: block;
`;
