import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionClasses,
  accordionSummaryClasses,
} from '@mui/material';
import { useMemo } from 'react';

import { stepHistoryShape } from '@/utils/transformFrontendCareGapAction';
import { flagHistoryShape } from '@/utils/transformFlagHistory';
import { hideForNowHistoryShape } from '@/utils/transformHideForNowHistory';
import { legacyNoteShape } from '@/utils/transformFrontendUserInputTask';
import { externalCareGapRecentActivityShape } from '@/utils/transformExternalCareGapRecentActivity';

import HistoryItem from '../../HistoryItem';

import { ReactComponent as TriangleRightIcon } from './assets/triangleRight.svg';

/**
 * Function to make hide for now history and step history the same shape.
 */
function normalizeHideForNowHistory(hideForNowHistory) {
  const { id, userFullName, hiddenUntil, createdAt, note } = hideForNowHistory;
  const description = hiddenUntil ? `Hidden until ${hiddenUntil}` : 'Un-hidden';

  return {
    id,
    date: createdAt,
    description,
    note: note?.text,
    userFullName,
  };
}

/**
 * Function to make flag history and step history the same shape.
 */
function normalizeFlagHistory(flagHistory) {
  const { id, userFullName, isFlagged, createdAt, note } = flagHistory;
  const description = isFlagged ? 'Flagged' : 'Unflagged';

  return {
    id,
    date: createdAt,
    description,
    note: note?.text,
    userFullName,
  };
}

/**
 * Function to make flag history and step history the same shape.
 */
function normalizeStepHistory(stepHistory) {
  const { id, executedAt, description, executionInfo, userFullName } =
    stepHistory;

  return {
    id,
    date: executedAt,
    description,
    note: executionInfo?.notes,
    userFullName,
  };
}

/**
 * Function to make legacy notes and step history the same shape.
 */
function normalizeLegacyNote(legacyNote) {
  const { date, author, text } = legacyNote;

  return {
    // The timestamps for these notes should all be distinct.
    id: date,
    date,
    note: text,
    userFullName: author,
  };
}

// As new status options come into the system, update this map with their
// user-facing values
const externalHistoryStatusStrings = new Map([['user_attested', 'attested']]);

/**
 * Function to make external care gaps and step history the same shape.
 */
function normalizeExternalCareGap(externalCareGap) {
  const {
    externalCareGapId,
    externalHistoryStatus,
    providerName,
    dateOfService,
    recordedAt,
    externalCareGapStatus,
    statusUpdateBy,
    statusUpdatedAt,
    sourceType,
  } = externalCareGap;
  let description;
  let date;
  if (externalCareGapStatus === 'inserted') {
    if (sourceType === 'appointment') {
      description = `Appointment with ${providerName} on ${dateOfService}`;
      date = recordedAt;
    } else {
      description = `${providerName} ${externalHistoryStatusStrings.get(
        externalHistoryStatus,
      )} on ${dateOfService}`;
      date = recordedAt;
    }
  } else if (externalCareGapStatus === 'dismissed') {
    description = `Dismissed updates from EHR (by ${providerName} on ${dateOfService})`;
    date = statusUpdatedAt;
  } else {
    description = `EHR update by ${providerName} on ${dateOfService}`;
    date = recordedAt;
  }

  return {
    id: `${externalCareGapId}-${externalCareGapStatus}`,
    date,
    description,
    userFullName: statusUpdateBy,
  };
}

export default function NotesAndRecentActivity({
  defaultExpanded,
  expanded,
  setNotesAndRecentActivityExpanded,
  flagHistories,
  stepHistories,
  hideForNowHistories,
  legacyNotes,
  externalCareGaps,
  sx,
}) {
  const historyItems = useMemo(() => {
    const output = [
      ...(flagHistories || []).map(normalizeFlagHistory),
      ...(stepHistories || []).map(normalizeStepHistory),
      ...(hideForNowHistories || []).map(normalizeHideForNowHistory),
      ...(legacyNotes || []).map(normalizeLegacyNote),
      ...(externalCareGaps || []).map(normalizeExternalCareGap),
    ];
    output.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });
    return output;
  }, [
    flagHistories,
    stepHistories,
    hideForNowHistories,
    legacyNotes,
    externalCareGaps,
  ]);

  return (
    <StyledAccordion
      disabled={historyItems.length === 0}
      sx={sx}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(_, isExpanded) => {
        setNotesAndRecentActivityExpanded(isExpanded);
      }}
    >
      <StyledAccordionSummary
        data-pendo-id="worklist-action-notes-recent-activities"
        expandIcon={<TriangleRightIcon />}
      >
        Notes and recent activity ({historyItems.length})
      </StyledAccordionSummary>

      <StyledAccordionDetails data-pendo-id="worklist-patient-notes-recent-activities">
        {historyItems.map((historyItem) => (
          <HistoryItem
            key={historyItem.id}
            date={historyItem.date}
            description={historyItem.description}
            note={historyItem.note}
            userFullName={historyItem.userFullName}
          />
        ))}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

NotesAndRecentActivity.propTypes = {
  defaultExpanded: PropTypes.bool,
  expanded: PropTypes.bool,
  setNotesAndRecentActivityExpanded: PropTypes.func,
  flagHistories: PropTypes.arrayOf(flagHistoryShape),
  stepHistories: PropTypes.arrayOf(stepHistoryShape),
  hideForNowHistories: PropTypes.arrayOf(hideForNowHistoryShape),
  legacyNotes: PropTypes.arrayOf(legacyNoteShape),
  externalCareGaps: PropTypes.arrayOf(externalCareGapRecentActivityShape),
  // eslint-disable-next-line react/require-default-props
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

NotesAndRecentActivity.defaultProps = {
  defaultExpanded: false,
  expanded: false,
  setNotesAndRecentActivityExpanded: () => {},
  flagHistories: undefined,
  stepHistories: undefined,
  hideForNowHistories: undefined,
  legacyNotes: undefined,
  externalCareGaps: undefined,
};

const StyledAccordion = styled(Accordion)`
  background-color: transparent;

  &.${accordionClasses.disabled} {
    background-color: transparent;
  }

  &:before {
    display: none;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  flex-direction: row-reverse;
  min-height: 24px;
  padding: ${(p) => p.theme.spacing(1, 0)};
  color: ${(p) => p.theme.palette.text.secondary};
  opacity: 1 !important; // Undo MUI disabled button opacity change.

  &.${accordionSummaryClasses.disabled} {
    color: ${(p) => p.theme.palette.text.placeholder};
  }

  .${accordionSummaryClasses.content} {
    margin: 0;
    margin-left: ${(p) => p.theme.spacing(1)};
  }

  .${accordionSummaryClasses.expandIconWrapper} {
    color: inherit;
    margin-left: 0;
  }

  .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded} {
    transform: rotate(90deg);
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  margin-left: ${(p) => p.theme.spacing(3)};
`;
