/* eslint-disable filenames/match-exported */
import { camelCase, first, mapKeys, snakeCase } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import useHistory from '@/utils/useHistory';
import baseGetQueryParameters from '@/utils/getQueryParameters';

// The array format must be 'bracket' in order for us to correctly synchronize arrays.
// Otherwise, arrays with lengths of one may be parsed as individual values.
// E.g. ?office_ids=1 -> Is this an number or array?
const ARRAY_FORMAT = 'bracket';

export const QueryParametersContext = createContext({
  /**
   * The URL's query parameters.
   */
  parameters: {},

  /**
   * Set the new parameters, removing any existing query parameters.
   */
  // eslint-disable-next-line no-unused-vars
  setParameters: (params) => {},

  /**
   * Merge the new parameters with any existing query parameters.
   */
  // eslint-disable-next-line no-unused-vars
  mergeParameters: (params) => {},

  /**
   * Add an item to a parameter that is an array.
   * @param {string} parameterKey
   * @param {boolean|string|number} item
   */
  // eslint-disable-next-line no-unused-vars
  addItemToArray: (parameterKey, item) => {},

  /**
   * Remove an item from a parameter that is an array.
   * @param {string} parameterKey
   * @param {boolean|string|number} item
   */
  // eslint-disable-next-line no-unused-vars
  removeItemFromArray: (parameterKey, item) => {},

  /**
   * Add selected task to parameter
   * @param {object} task
   * @param {boolean} [clearFirstTask=false] clear first task parameter
   */
  // eslint-disable-next-line no-unused-vars
  selectTask: (task, clearFirstTask) => {},
});

export function useQueryParameters() {
  return useContext(QueryParametersContext);
}

/**
 * Context provider that synchronizes the context state with the URL's query parameters.
 * The URL is treated as the source of truth. The value of this component is being able
 * to preserve page state across page refreshes and browser back/forward navigation.
 *
 * This component makes the following assumptions:
 * 1. Query parameters can only be strings, numbers, booleans, or an array of any of the previous types.
 * 2. Parameter keys use camelCase in code and snake_case in the URL.
 * 3. If a parameter value is undefined, null, or empty array; it will be omited from the url.
 */
export function QueryParametersProvider({
  children,
  parseNumbers,
  parseBooleans,
}) {
  const getQueryParameters = useCallback(() => {
    // Grab all the query parameters and convert the keys from snake_case to camelCase.
    return mapKeys(
      baseGetQueryParameters({
        parseNumbers,
        parseBooleans,
        arrayFormat: ARRAY_FORMAT,
      }),
      (value, key) => camelCase(key),
    );
  }, [parseBooleans, parseNumbers]);

  const [parameters, setParameters] = useState(
    // The URL is the source of truth, so getQueryParameters() should always be the initial state.
    // If you need to change the initial state, you should either enter the page with the correct query params, or
    // you should call setParameters() inside useEffectOnce().
    () => getQueryParameters(),
  );
  const history = useHistory();

  const setParametersProxy = useCallback(
    (input) => {
      const newParameters = { ...input };
      const newUrl = history.getCurrentLocation();
      newUrl.search = queryString.stringify(
        mapKeys(newParameters, (value, key) => snakeCase(key)),
        {
          arrayFormat: ARRAY_FORMAT,
        },
      );

      history.push(newUrl);
      setParameters(getQueryParameters());
    },
    [getQueryParameters, history],
  );

  const mergeParameters = useCallback(
    (input) => {
      setParametersProxy({
        ...parameters,
        ...input,
      });
    },
    [parameters, setParametersProxy],
  );

  const addItemToArray = useCallback(
    (parameterKey, item) => {
      const value = parameters[parameterKey] || [];
      const newValue = value.concat(item);

      mergeParameters({
        [parameterKey]: newValue,
      });
    },
    [mergeParameters, parameters],
  );

  const removeItemFromArray = useCallback(
    (parameterKey, item) => {
      const value = parameters[parameterKey];

      if (Array.isArray(value)) {
        // Removes all instances of an item from the array.
        const newValue = value.filter((x) => x !== item);

        mergeParameters({
          // If newValue is an empty array, the parameter will become undefined.
          [parameterKey]: newValue,
        });
      }
    },
    [mergeParameters, parameters],
  );

  const selectTask = useCallback(
    (task, clearFirstTask = false) => {
      const { action, patient } = task;
      const preferredNextStep = first(action.v2Dto.preferredPath);
      const newParameter = {
        actionId: action.id,
        stepId: preferredNextStep.stepId,
        patientId: patient.id,
        taskId: task.id,
        isSidebarOpen: undefined,
      };
      if (clearFirstTask) {
        newParameter.openFirstTask = undefined;
      }
      mergeParameters(newParameter);
    },
    [mergeParameters],
  );

  // Update context state in response to the browser navigation buttons.
  useEffect(() => {
    return history.addPopListener(() => {
      setParameters(getQueryParameters());
    });
  }, [getQueryParameters, history]);

  const contextValue = useMemo(() => {
    return {
      parameters,
      setParameters: setParametersProxy,
      mergeParameters,
      addItemToArray,
      removeItemFromArray,
      selectTask,
    };
  }, [
    addItemToArray,
    mergeParameters,
    parameters,
    removeItemFromArray,
    selectTask,
    setParametersProxy,
  ]);

  return (
    <QueryParametersContext.Provider value={contextValue}>
      {children}
    </QueryParametersContext.Provider>
  );
}

QueryParametersProvider.propTypes = {
  children: PropTypes.node,
  parseBooleans: PropTypes.bool,
  parseNumbers: PropTypes.bool,
};

QueryParametersProvider.defaultProps = {
  children: undefined,
  parseBooleans: true,
  parseNumbers: true,
};
