import { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Typography } from '@mui/material';

import useUpdateUserDrivenEnrollmentForms from '@/pages/TaskBasedWorkflow/hooks/useUpdateUserDrivenEnrollmentForms';
import BaseFormModal from '@/components/BaseFormModal';
import allForms from '@/pages/TaskBasedWorkflow/utils/userDrivenEnrollmentFormsExecutionRequirements';
import { usePage } from '@/pages/TaskBasedWorkflow/contexts/PageContext';
import { ReactComponent as defaultIcon } from '@/assets/icons/settings.svg';
import BaseSpinner from '@/components/BaseSpinner';
import BaseModal from '@/components/BaseModal';
import PatientDetailsButton from '@/pages/PatientSummary/components/PatientDetailsButton';
import useFeatureFlag from '@/utils/useFeatureFlag';
import BaseLinkButton from '@/components/BaseLinkButton';

import PatientBannerButton from './PatientBannerButton';

function lookupDynamicProperties(executionRequirement, config) {
  const clonedExecutionRequirement = { ...executionRequirement };

  if (config[executionRequirement.name]) {
    Object.assign(
      clonedExecutionRequirement,
      config[executionRequirement.name],
    );
  }

  return clonedExecutionRequirement;
}

function Icon({ IconComponent }) {
  return <IconComponent height={18} width={18} />;
}

Icon.propTypes = {
  IconComponent: PropTypes.elementType,
};

Icon.defaultProps = {
  IconComponent: undefined,
};

export default function UserDrivenEnrollmentFormWidget({
  patientId,
  subtype,
  userDrivenEnrollmentForm,
  visuallyHidden,
}) {
  const { config } = allForms[subtype] || {
    icon: defaultIcon,
    config: {},
  };
  const executionRequirements = userDrivenEnrollmentForm.fields.map((field) => {
    return lookupDynamicProperties(field, config);
  });

  const [showModal, setShowModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  const page = usePage();
  const { isLoading, mutate, reset } = useUpdateUserDrivenEnrollmentForms();

  const handleBannerButtonClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    reset();
    setShowModal(false);
    setErrorMessages(null);
  };

  const handleSuccess = () => {
    handleClose();
    window.location.reload();
  };

  const handleSubmit = (executionInput) => {
    mutate(
      { patientId, subtype, executionInput, executionRequirements },
      {
        onSuccess: handleSuccess,
        onError: (e) => {
          setErrorMessages(e.response.data);
        },
      },
    );
  };

  const alert = errorMessages ? (
    <Alert severity="error" sx={{ mb: 1 }}>
      {errorMessages.map((entry) => {
        const req = executionRequirements.find((req) => req.name === entry[0]);
        return (
          <Typography key={entry[0]}>
            {req ? `${req.label}: ${entry[1]}` : entry[1]}
          </Typography>
        );
      })}
    </Alert>
  ) : undefined;

  const defaultValues = userDrivenEnrollmentForm.fields.reduce(
    (values, field) => {
      if (typeof field.defaultValue !== 'undefined') {
        // eslint-disable-next-line no-param-reassign
        values[field.name] = field.defaultValue;
      }
      return values;
    },
    {},
  );

  const showUserDrivenEnrollmentFormTileButton =
    useFeatureFlag('ppvAtPointOfCare');
  const isSummaryPage = page.includes('summary');
  const buttonComponent = (() => {
    if (isSummaryPage && showUserDrivenEnrollmentFormTileButton) {
      return (
        <BaseLinkButton
          sx={{ color: 'text.secondary', fontSize: 14, underline: false }}
          onClick={handleBannerButtonClick}
        >
          {userDrivenEnrollmentForm.tile_button_text}
        </BaseLinkButton>
      );
    }
    if (isSummaryPage) {
      return (
        <PatientDetailsButton
          onClick={handleBannerButtonClick}
          visuallyHidden={visuallyHidden}
          label={userDrivenEnrollmentForm.button_text}
          pendoId={`ps-${subtype}-button`}
          size="medium"
        />
      );
    }
    return (
      <PatientBannerButton
        onClick={handleBannerButtonClick}
        data-pendo-id={`worklist-patient-detail-user-driven-enrollment-forms-${subtype}`}
      >
        {userDrivenEnrollmentForm.button_text}
      </PatientBannerButton>
    );
  })();

  return (
    <>
      {buttonComponent}

      {isLoading ? (
        <BaseModal
          title={userDrivenEnrollmentForm.modal_title}
          onClose={handleClose}
          open={showModal}
          sx={{ minWidth: '408px', p: 1 }}
        >
          <BaseSpinner size={50} sx={{ display: 'block', mx: 'auto' }} />
        </BaseModal>
      ) : (
        <BaseFormModal
          alert={alert}
          fields={executionRequirements}
          defaultValues={defaultValues}
          isLoading={isLoading}
          onCancel={handleClose}
          onClose={handleClose}
          onSubmit={handleSubmit}
          submitButtonPendoId={`worklist-patient-detail-user-driven-enrollment-forms-${subtype}-modal-save`}
          open={showModal}
          submitButtonLabel="Confirm"
          title={userDrivenEnrollmentForm.modal_title}
          headerVariant="h2"
          modalSx={{
            width: '700px',
            maxHeight: '100%',
            'overflow-y': 'auto',
          }}
        />
      )}
    </>
  );
}

UserDrivenEnrollmentFormWidget.propTypes = {
  patientId: PropTypes.number.isRequired,
  subtype: PropTypes.string.isRequired,
  userDrivenEnrollmentForm: PropTypes.shape({
    button_text: PropTypes.string,
    modal_title: PropTypes.string,
    tile_button_text: PropTypes.string,
    fields: PropTypes.array,
  }).isRequired,
  visuallyHidden: PropTypes.bool,
};

UserDrivenEnrollmentFormWidget.defaultProps = {
  visuallyHidden: false,
};
