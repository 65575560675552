/* eslint-disable camelcase */
import {
  differenceInCalendarDays,
  differenceInCalendarYears,
  format,
  isValid,
  parse,
  parseISO,
} from 'date-fns';

import register from '@/components/DynamicForm/register';
import { DISPLAY_DATE_FORMAT } from '@/pages/PatientSummary/utils/constants';

import {
  ELSE,
  POSITION_FORM_COMPONENT,
  beginningOfServiceYear,
  match,
  referenceActionField,
  references,
  subYears,
  today,
} from './executionRequirementUtils';

const formatDateForDisplay = (date) => {
  return format(parseISO(date), DISPLAY_DATE_FORMAT);
};

const datesWithinRange = (date1, date2, maxRange) =>
  isValid(date1) &&
  isValid(date2) &&
  Math.abs(differenceInCalendarDays(date1, date2)) < maxRange;

export const peds_immunization_flu = {
  'peds_immunization_flu-base-1-administer_dose-2': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
  },
};

export const peds_immunization_dtap = {
  'peds_immunization_dtap-base-1-administer_dose-2': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
  },
  'peds_immunization_dtap-base-1-administer_dose-3': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
    date_of_dose_3: {
      minDate: references('date_of_dose_2'),
    },
  },
  'peds_immunization_dtap-base-1-administer_dose-4': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
    date_of_dose_3: {
      minDate: references('date_of_dose_2'),
    },
    date_of_dose_4: {
      minDate: references('date_of_dose_3'),
    },
  },
  'peds_immunization_dtap-base-1-administer_dose-5': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
  },
  'peds_immunization_dtap-base-1-administer_dose-6': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
    date_of_dose_3: {
      minDate: references('date_of_dose_2'),
    },
  },
  'peds_immunization_dtap-base-1-administer_dose-7': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
    date_of_dose_3: {
      minDate: references('date_of_dose_2'),
    },
    date_of_dose_4: {
      minDate: references('date_of_dose_3'),
    },
  },
  'peds_immunization_dtap-base-1-administer_dose-8': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
    date_of_dose_3: {
      minDate: references('date_of_dose_2'),
    },
  },
  'peds_immunization_dtap-base-1-administer_dose-9': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
    date_of_dose_3: {
      minDate: references('date_of_dose_2'),
    },
    date_of_dose_4: {
      minDate: references('date_of_dose_3'),
    },
  },
  'peds_immunization_dtap-base-1-administer_dose-10': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
    date_of_dose_3: {
      minDate: references('date_of_dose_2'),
    },
    date_of_dose_4: {
      minDate: references('date_of_dose_3'),
    },
  },
};

export const adolescent_immunization_hpv = {
  'adolescent_immunization_hpv-base-1-administer_dose-1': {
    date_of_dose_2: {
      minDate: references('date_of_dose_1'),
    },
    date_of_dose_3: {
      minDate: references('date_of_dose_2'),
      required: register(['date_of_dose_1', 'date_of_dose_2'], (state) =>
        datesWithinRange(state.date_of_dose_1, state.date_of_dose_2, 146),
      ),
    },
    date_of_dose_3_informational_text: {
      name: 'date_of_dose_3_informational_text',
      type: 'informationalText',
      content:
        'Because Doses 1 and 2 were administered less than 146 days apart, a third dose is required to close this gap.',
      visible: register(['date_of_dose_1', 'date_of_dose_2'], (state) =>
        datesWithinRange(state.date_of_dose_1, state.date_of_dose_2, 146),
      ),
      position: POSITION_FORM_COMPONENT.BOTTOM,
    },
  },
};

export const helperDateOfServiceRangeInstructionalText = (
  _,
  { executionRequirements },
) => {
  const dateOfServiceField = executionRequirements.find(
    (x) => x.name === 'date_of_service',
  );
  const { maxDate, minDate } = dateOfServiceField;
  return `The date must be in the range: ${formatDateForDisplay(
    minDate,
    // eslint-disable-next-line no-irregular-whitespace
  )} ‑ ${formatDateForDisplay(maxDate)}`;
};

const date_of_service_instructional_text = {
  date_of_service_instructions: {
    name: 'date_of_service_instructions',
    type: 'label',
    label: helperDateOfServiceRangeInstructionalText,
    required: false,
    position: POSITION_FORM_COMPONENT.TOP,
  },
};

/*
 Add step ids of any steps whose patient summary modal has a date_of_service
 with a min_date/max_date we want to display the valid date range for.
 */
const date_of_service_range_instructions_step_ids = [
  'patient_experience_assess_physical_activity-base-1-discuss_physical_activity-2',
  'patient_experience_assess_bladder_control-base-1-discuss_bladder_control-2',
];

export const helper_date_of_service_range_instructions = {};
date_of_service_range_instructions_step_ids.forEach((step_id) => {
  helper_date_of_service_range_instructions[step_id] = {
    ...date_of_service_instructional_text,
  };
});

export const helperGetAwvInstructionalText = (_, { action }) => {
  return `To close this gap, the screening date must be between: ${formatDateForDisplay(
    action.startsAt,
    // eslint-disable-next-line no-irregular-whitespace
  )} ‑ ${formatDateForDisplay(action.expiresAt)}`;
};

const annual_visit_instructional_text = {
  instructional_text_1: {
    name: 'instructional_text_1',
    type: 'label',
    label: helperGetAwvInstructionalText,
    required: false,
    position: POSITION_FORM_COMPONENT.TOP,
  },
};

export const helperGetRecentVisitText = (_, { action }) => {
  return action.contextualInfo.mostRecentDateForDisplay === ''
    ? ''
    : `Last Wellness Visit Date: ${formatDateForDisplay(
        action.contextualInfo.mostRecentDateForDisplay,
      )}`;
};

const annual_visit_recent_visit_text = {
  recent_visit_text: {
    name: 'recent_visit_text',
    type: 'label',
    label: helperGetRecentVisitText,
    required: false,
    position: POSITION_FORM_COMPONENT.TOP,
  },
};

export const helperGetScheduledDate = (_, { action }) => {
  return action.migratedFromId && action.dateOfService
    ? `Appointment Date: ${formatDateForDisplay(action.dateOfService)}`
    : '';
};

const annual_visit_scheduled_date_text = {
  scheduled_date_text: {
    name: 'scheduled_date_text',
    type: 'label',
    label: helperGetScheduledDate,
    required: false,
    position: POSITION_FORM_COMPONENT.TOP,
  },
};

const schedule_visit_step_ids = [
  'annual_visit-11_1_99_codes-1-schedule_visit-1',
  'annual_visit-11_1_all_codes-1-schedule_visit-1',
  'annual_visit-11_1_G_codes-1-schedule_visit-1',
  'annual_visit-365_99_codes-1-schedule_visit-1',
  'annual_visit-365_all_codes-1-schedule_visit-1',
  'annual_visit-365_G_codes-1-schedule_visit-1',
  'annual_visit-calendar_99_codes-1-schedule_visit-1',
  'annual_visit-calendar_all_codes-1-schedule_visit-1',
  'annual_visit-calendar_G_codes-1-schedule_visit-1',
];

const reschedule_visit_step_ids = [
  'annual_visit-11_1_99_codes-1-reschedule_visit-1',
  'annual_visit-11_1_all_codes-1-reschedule_visit-1',
  'annual_visit-11_1_G_codes-1-reschedule_visit-1',
  'annual_visit-365_99_codes-1-reschedule_visit-1',
  'annual_visit-365_all_codes-1-reschedule_visit-1',
  'annual_visit-365_G_codes-1-reschedule_visit-1',
  'annual_visit-calendar_99_codes-1-reschedule_visit-1',
  'annual_visit-calendar_all_codes-1-reschedule_visit-1',
  'annual_visit-calendar_G_codes-1-reschedule_visit-1',
  'annual_visit-11_1_99_codes-1-complete_visit-1',
  'annual_visit-11_1_99_codes-1-complete_visit-2',
];

const complete_visit_step_ids = [
  'annual_visit-11_1_99_codes-1-complete_visit-1',
  'annual_visit-11_1_99_codes-1-complete_visit-2',
  'annual_visit-11_1_all_codes-1-complete_visit-1',
  'annual_visit-11_1_all_codes-1-complete_visit-2',
  'annual_visit-11_1_G_codes-1-complete_visit-1',
  'annual_visit-11_1_G_codes-1-complete_visit-2',
  'annual_visit-365_99_codes-1-complete_visit-1',
  'annual_visit-365_99_codes-1-complete_visit-2',
  'annual_visit-365_all_codes-1-complete_visit-1',
  'annual_visit-365_all_codes-1-complete_visit-2',
  'annual_visit-365_G_codes-1-complete_visit-1',
  'annual_visit-365_G_codes-1-complete_visit-2',
  'annual_visit-calendar_99_codes-1-complete_visit-1',
  'annual_visit-calendar_99_codes-1-complete_visit-2',
  'annual_visit-calendar_all_codes-1-complete_visit-1',
  'annual_visit-calendar_all_codes-1-complete_visit-2',
  'annual_visit-calendar_G_codes-1-complete_visit-1',
  'annual_visit-calendar_G_codes-1-complete_visit-2',
];

export const annual_visit = {};
schedule_visit_step_ids.forEach((step_id) => {
  annual_visit[step_id] = {
    ...annual_visit_instructional_text,
    ...annual_visit_recent_visit_text,
    ...annual_visit_scheduled_date_text,
  };
});
reschedule_visit_step_ids.forEach((step_id) => {
  annual_visit[step_id] = {
    ...annual_visit_scheduled_date_text,
  };
});
complete_visit_step_ids.forEach((step_id) => {
  annual_visit[step_id] = {
    ...annual_visit_instructional_text,
    ...annual_visit_scheduled_date_text,
  };
});

const colorectal_cancer_screening_date = {
  minDate: match('test_type', {
    fit: beginningOfServiceYear,
    fobt: beginningOfServiceYear,
    cologuard: subYears(beginningOfServiceYear, 2),
    flexsig: subYears(beginningOfServiceYear, 4),
    ct_colonography: subYears(beginningOfServiceYear, 4),
    colonoscopy: subYears(beginningOfServiceYear, 9),
    [ELSE]: beginningOfServiceYear,
  }),
};

/**
 * For the DynamicFormReferral modal, the action subtype is needed
 * to filter the referral providers based on the action subtype.
 * Here is where the information gets bubbled all the way down.
 */
const specialty_referral_field = {
  actionSubtype: referenceActionField('subtype', ''),
};

export const colorectal_cancer_screening_ccs = {
  'colorectal_cancer_screening_ccs-base-1-schedule_colo-1': {
    speciality_provider_single_name: specialty_referral_field,
  },
  'colorectal_cancer_screening_ccs-base-1-schedule_colo-2': {
    speciality_provider_single_name: specialty_referral_field,
  },
  'colorectal_cancer_screening_ccs-base-1-complete_colo-1': {
    date_of_service: colorectal_cancer_screening_date,
  },
  'colorectal_cancer_screening_ccs-base-1-complete_colo-2': {
    date_of_service: colorectal_cancer_screening_date,
  },
};

const cervical_cancer_screening_date = {
  minDate: match('test_type', {
    pap: subYears(beginningOfServiceYear, 2),
    hpv: subYears(beginningOfServiceYear, 4),
    [ELSE]: subYears(beginningOfServiceYear, 4),
  }),
};

const cervical_cancer_screening_age_warning_text = {
  age_warning_text_1: {
    name: 'age_warning_text_1',
    type: 'label',
    label:
      'Patient 29 or younger are not eligible for a Dual Pap/HPV Test and must receive a Pap Test.',
    required: false,
    visible: register(['test_type'], ({ test_type }, { patient }) => {
      const patientDoB = parse(
        patient.dateOfBirth,
        DISPLAY_DATE_FORMAT,
        new Date(),
      );
      return (
        test_type === 'hpv' &&
        Math.abs(differenceInCalendarYears(patientDoB, today())) <= 29
      );
    }),
    position: POSITION_FORM_COMPONENT.TOP,
  },
};

const action_permanently_removed_warning_text = {
  warning_text_1: {
    name: 'warning_text_1',
    type: 'label',
    label:
      "The action will be permanently removed for this patient and you won't be prompted to complete it again this year.",
    required: false,
    position: POSITION_FORM_COMPONENT.TOP,
  },
};

export const cervical_cancer_screening_ccs = {
  'cervical_cancer_screening_ccs-base-1-schedule_ccs-1': {
    speciality_provider_single_name: specialty_referral_field,
    ...cervical_cancer_screening_age_warning_text,
  },
  'cervical_cancer_screening_ccs-base-1-schedule_ccs-2': {
    speciality_provider_single_name: specialty_referral_field,
    ...cervical_cancer_screening_age_warning_text,
  },
  'cervical_cancer_screening_ccs-base-1-complete_ccs-1': {
    scheduled_date_of_screening: cervical_cancer_screening_date,
    ...cervical_cancer_screening_age_warning_text,
  },
  'cervical_cancer_screening_ccs-base-1-complete_ccs-2': {
    scheduled_date_of_screening: cervical_cancer_screening_date,
    ...cervical_cancer_screening_age_warning_text,
  },
  'cervical_cancer_screening_ccs-base-1-removal-1': {
    ...action_permanently_removed_warning_text,
  },
  'cervical_cancer_screening_ccs-base-1-removal-2': {
    ...action_permanently_removed_warning_text,
  },
};

export const chlamydia_screening_chl = {
  'chlamydia_screening_chl-base-1-removal-1': {
    ...action_permanently_removed_warning_text,
  },
  'chlamydia_screening_chl-base-1-removal-2': {
    ...action_permanently_removed_warning_text,
  },
};

const diabetic_eye_exam_date = {
  minDate: match('result', {
    negative: subYears(beginningOfServiceYear, 1),
    positive: beginningOfServiceYear,
    [ELSE]: subYears(beginningOfServiceYear, 1),
  }),
};

export const diabetic_eye_exam_dee = {
  'diabetic_eye_exam_dee-base-1-complete_dee-1': {
    date_of_service: diabetic_eye_exam_date,
  },
  'diabetic_eye_exam_dee-base-1-complete_dee-2': {
    date_of_service: diabetic_eye_exam_date,
  },
};

export const breast_cancer_screening_bcs = {
  'breast_cancer_screening_bcs-base-1-schedule_bcs-1': {
    speciality_provider_single_name: specialty_referral_field,
  },
  'breast_cancer_screening_bcs-base-1-schedule_bcs-2': {
    speciality_provider_single_name: specialty_referral_field,
  },
};
