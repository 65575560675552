import PropTypes from 'prop-types';
import { Box, Typography, styled } from '@mui/material';

import { patientShape } from '@/utils/transformFrontendPatient';
import Breadcrumbs from '@/pages/Tasks/components/Breadcrumbs';
import { useQueryParameters } from '@/contexts/QueryParametersContext';
import { ReactComponent as TableIcon } from '@/assets/icons/table.svg';

import SortSelect from '../patientDetailView/SortSelect';
import ShowMoreButton from '../taskTableView/ShowMoreButton';
import { usePage } from '../../contexts/PageContext';
import { Page } from '../../constants';

import TaskPanelCard from './TaskPanelCard';

export default function TaskPanel({
  tasks,
  onClickShowMore,
  isFetchingNextPage,
  hasNextPage,
  sx,
}) {
  const page = usePage();
  const { mergeParameters } = useQueryParameters();
  const handleClick = () => {
    mergeParameters({
      patientId: undefined,
      actionId: undefined,
      stepId: undefined,
      taskId: undefined,
    });
  };
  return (
    <TaskPanelRoot sx={sx}>
      <Box
        sx={{
          backgroundColor: 'background.secondary',
          position: 'sticky',
          top: 0,
          borderBottom: '1px solid',
          borderBottomColor: 'border.base',
          zIndex: 1,
        }}
      >
        {page === Page.tasks && (
          <Typography
            pt={2}
            px={2}
            pb={1}
            variant="bodymediumbold"
            color="text.label"
          >
            <Breadcrumbs />
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
            py: 1,
          }}
        >
          <SortSelect />

          {page === Page.tasks && (
            <TableViewButton
              onClick={handleClick}
              variant="bodysmall"
              component="button"
            >
              <TableIcon height={16} width={16} />
              <Box component="span" ml={0.5}>
                Table View
              </Box>
            </TableViewButton>
          )}
        </Box>
      </Box>

      {tasks.map((task) => {
        return <TaskPanelCard key={task.id} task={task} />;
      })}

      {hasNextPage && (
        <Box
          sx={{
            py: 3,
            textAlign: 'center',
          }}
        >
          <ShowMoreButton
            onClick={onClickShowMore}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Box>
      )}
    </TaskPanelRoot>
  );
}

TaskPanel.propTypes = {
  tasks: PropTypes.arrayOf(patientShape),
  onClickShowMore: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

TaskPanel.defaultProps = {
  tasks: undefined,
  sx: undefined,
};

const TaskPanelRoot = styled('div')`
  overflow: auto;
  position: relative;
`;

const TableViewButton = styled(Typography)`
  align-items: center;
  display: inline-flex;
  color: ${(p) => p.theme.palette.text.secondary};
  border-radius: ${(p) => 2 * p.theme.shape.borderRadius}px;

  // Increase the size of the button without taking up extra space
  padding: ${(p) => p.theme.spacing(0.5, 1)};
  margin: ${(p) => p.theme.spacing(-0.5, -1)};

  :hover {
    background-color: ${(p) => p.theme.palette.background.primaryLightest};
    color: ${(p) => p.theme.palette.icon.link};
  }
`;
