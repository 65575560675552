import { arrayOf, number, shape, string } from 'prop-types';
import { List, ListItem, Typography, styled } from '@mui/material';

import { stepShape } from './transformFrontendCareGapAction';

export const relatedActionShape = shape({
  id: number,
  description: string,
  availableNextSteps: arrayOf(stepShape),
  adtEventDate: string,
});

// adjust data to common fields so we can reuse codes across PS and TBW
export const getActionsToGroup = (actions) => {
  return actions
    .filter((action) => !!action.contextualInfo?.adtEventDate)
    .map((action) => {
      return {
        id: action.actionId,
        description: action.description,
        availableNextSteps: action.availableNextSteps,
        adtEventDate: action.contextualInfo.adtEventDate,
      };
    });
};

export const findRelatedActions = (adtEventDate, actionsToGroup) => {
  if (actionsToGroup.length > 0 && adtEventDate) {
    return actionsToGroup.filter((task) => task.adtEventDate === adtEventDate);
  }
  return [];
};

const transformBulk = (actions, executionInput, condition) => {
  const returnValue = {};

  actions.forEach((actionToRemove) => {
    const steps = actionToRemove.availableNextSteps.filter(condition);
    if (steps.length > 0) {
      returnValue[actionToRemove.id] = {
        step_id: steps[0].stepId,
        execution_input: executionInput,
      };
    }
  });

  return returnValue;
};

export const transformBulkRemoval = (actions, executionInput) => {
  return transformBulk(actions, executionInput, (step) => step.isRemovalStep);
};

export const transformBulkCompletion = (actions, executionInput) => {
  return transformBulk(
    actions,
    executionInput,
    (step) => step.isCompletionStep,
  );
};

export const BULK_REMOVE = 'bulkRemove';
export const REMOVE_CHECKBOX = 'removeCheckbox';
export const appendExecutionRequirements = (
  executionRequirements,
  relatedActions,
  actionId,
) => {
  if (executionRequirements) {
    // make a copy so it does not get modified per render
    const removeExecutionRequirements = [...executionRequirements];
    // filter out current action
    const otherActions = relatedActions.filter(
      (currentAction) => currentAction.id !== actionId,
    );
    if (otherActions.length > 0) {
      // insert to 2nd place before last
      removeExecutionRequirements.splice(
        removeExecutionRequirements.length - 1,
        0,
        {
          label: (
            <>
              <Typography>
                Also remove the following associated actions:
              </Typography>
              <StyledList sx={{ listStyleType: 'disc' }}>
                {otherActions.map((currentAction) => (
                  <StyledListItem
                    key={currentAction.id}
                    sx={{ display: 'list-item' }}
                  >
                    {currentAction.description}
                  </StyledListItem>
                ))}
              </StyledList>
            </>
          ),
          type: REMOVE_CHECKBOX,
          name: BULK_REMOVE,
        },
      );
    }
    return removeExecutionRequirements;
  }
  return executionRequirements;
};

// note adding list-style-type here does not seem to work
const StyledList = styled(List)`
  padding-left: ${(p) => p.theme.spacing(1)};
`;

const StyledListItem = styled(ListItem)`
  padding: 0;
`;
