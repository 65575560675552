import PropTypes from 'prop-types';
import { Button, keyframes } from '@mui/material';
import styled from 'styled-components';

const pulse = keyframes`
  0%, 100% {
    box-shadow: 0 0 0 0 var(--pulse-color);
  }
  50% {
    box-shadow: 0 0 0 10px var(--pulse-color);
  }
`;

// Define the styled button component with the animation
const StyledPulsatingButton = styled(Button)`
  --pulse-color: ${(props) => props.pulseColor || 'rgba(195, 194, 247, 1)'};
  animation: ${pulse} 1s ease-in-out 3;
`;

function PulsatingButton({
  className,
  children,
  ...props // Spread the rest of the props
}) {
  return (
    <StyledPulsatingButton
      data-testid="pulsating-button"
      className={className}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {children}
    </StyledPulsatingButton>
  );
}

PulsatingButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PulsatingButton.defaultProps = {
  className: undefined,
};

export default PulsatingButton;
