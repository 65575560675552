import useId from '@mui/utils/useId';
import { useController } from 'react-hook-form';
import { useMemo } from 'react';

import BaseSelect from '@/components/BaseSelect';
import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';
import useGetUserFilters from '@/hooks/useGetUserFilters';
import FormErrorMessage from '@/components/FormErrorMessage';
import transformOption, { getDefaultValue } from '@/utils/transformOption';

import DynamicFormLabel from '../DynamicFormLabel';

export default function DynamicFormProviderDropdown({ field }) {
  const { label, name } = field;
  const fieldId = useId();
  const { data, isLoading } = useGetUserFilters();
  const controller = useController({
    name,
    defaultValue: getDefaultValue(data?.providers),
    rules: {
      required: field.required ? 'Select a provider' : undefined,
    },
  });

  const { providers = [] } = data || {};

  const options = useMemo(() => providers.map(transformOption), [providers]);

  // Don't pass a value until the data is finished loading.
  // Otherwise MUI will complain that the form's default value (if there is one) isn't an available option.
  const value = isLoading ? undefined : controller.field.value;

  return (
    <>
      <DynamicFormLabel htmlFor={fieldId}>{label}</DynamicFormLabel>
      <BaseSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controller.field}
        data-pendo-id={field.dataPendoId}
        fullWidth
        label={label}
        options={options}
        value={value}
      />
      <FormErrorMessage errors={controller.formState.errors} name={name} />
    </>
  );
}

DynamicFormProviderDropdown.propTypes = {
  field: executionRequirementShape.isRequired,
};
