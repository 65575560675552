import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

async function executeStep({
  actionId,
  stepId,
  executionInput = undefined,
  createPatientVisit = false,
}) {
  return axios.post('/api/tbw/steps/execute', {
    action_id: actionId,
    step_id: stepId,
    execution_input: executionInput,
    create_patient_visit: createPatientVisit,
  });
}

export default function useExecuteStep({ onSuccess = undefined } = {}) {
  return useMutation(executeStep, {
    onSuccess,
  });
}

export function useExecuteSteps({
  onSuccess = undefined,
  onSettled = undefined,
  onError = undefined,
  createPatientVisits = false,
}) {
  return useMutation(
    (actions) => {
      return Promise.all(
        actions.map((action) => {
          return executeStep({
            actionId: action.actionId,
            stepId: action.stepId,
            executionInput: action.executionInput,
            createPatientVisit: createPatientVisits,
          });
        }),
      );
    },
    {
      onSettled,
      onError,
      onSuccess,
    },
  );
}
