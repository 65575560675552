import { Fragment } from 'react';
import { arrayOf, func, object } from 'prop-types';
import { Typography } from '@mui/material';

import DynamicFormField from './DynamicFormField';
import useDynamicFieldHandler from './useDynamicFieldHandler';

function defaultRenderAround({ children, field }) {
  if (field?.help_text) {
    return (
      <>
        {children}
        <Typography variant="bodysmall" sx={{ fontStyle: 'italic', mt: 0.5 }}>
          {field.help_text}
        </Typography>
      </>
    );
  }
  return children;
}

function defaultRenderBetween() {
  return null;
}

/**
 * Renders a collection of dynamic form fields, expecting it to live within a
 * DynamicForm. If not, this component will throw an error.
 *
 * The fields prop is an array of form field descriptions. Form fields are
 * polymorphic—they can be a date field, file upload, text, number, or other.
 * Each of these form fields take different props. How each of these fields are
 * rendered is determined by <DynamicFormField /> which ultimately delegates to
 * the fieldComponentMapper.
 *
 * `fieldComponentMapper` may be overridden, as a function that maps between a
 * field object and the React component to render that field. If the override
 * returns `null` or `undefined`, the default component will be used instead.
 *
 * This component accepts an `additionalProps` prop that is passed to every
 * field in the form and render delegates; this can be useful when a custom
 * field or validation function needs some kind of contextual information as
 * well as the current form state.
 *
 * This component accepts two additional props that allow for tighter control
 * over the way that fields are rendered. It shouldn't be necessary to use
 * these props commonly:
 *
 * renderAround will be called for each field rendered. This render delegate
 * is a plain function (**NOT** a React component) that may return additional
 * JSX. This function is passed an object of four properties:
 *   - additionalProps: passing through the `additionalProps` prop, if any
 *   - children: the rendered form field, expected to be wrapped and returned
 *   - index: the index of the form field in the form
 *   - field: the literal field definition object
 *
 * renderBetween is a plain function (**NOT** a React component) that may
 * return additional JSX. It will be called once for each "gap" between form
 * components. This allows for presentation components to be interposed with
 * the list of form fields. This could be a <hr /> for example, or some kind
 * of necessary table markup. This function is passed an object of two
 * properties:
 *   - additionalProps: passing through the `additionalProps` prop, if any
 *   - index: the index of the form field in the form
 *   - field: the literal field definition object
 */
function DynamicFormFields({
  additionalProps,
  fields,
  fieldComponentMapper,
  renderAround = defaultRenderAround,
  renderBetween = defaultRenderBetween,
}) {
  return useDynamicFieldHandler(fields, additionalProps)
    .filter((f) => f.visible !== false)
    .map((field, index, { length }) => {
      // Check if this iteration is not the last, and not the only. If so,
      // render a "between" node below.
      const isInterstitial = length !== 1 && index < length - 1;

      const renderedField = (
        <DynamicFormField
          field={field}
          fieldComponentMapper={fieldComponentMapper}
        />
      );

      return (
        <Fragment key={field.name || field.label}>
          {renderAround({
            additionalProps,
            children: renderedField,
            field,
            index,
          })}
          {isInterstitial
            ? renderBetween({ additionalProps, field, index })
            : null}
        </Fragment>
      );
    });
}

DynamicFormFields.propTypes = {
  additionalProps: object,
  fields: arrayOf(object).isRequired,
  fieldComponentMapper: func,

  // Render delegates:
  renderAround: func,
  renderBetween: func,
};
DynamicFormFields.defaultProps = {
  additionalProps: undefined,
};

export default DynamicFormFields;
