import { isEmpty } from 'lodash';
import { formatISO } from 'date-fns';

/**
 * @typedef DiagnosisSubmissionState
 * @property {{
 *   id: string,
 *   date_of_service: string,
 *   other_hccs: string,
 *   state: string,
 *   was_diagnosed: boolean
 *   user_added_diagnosis_codes: string[]
 * }} action
 * @property {string[]} userAddedDiagnosisCodes
 */

/**
 * Returns the only value of an object dictionary, throwing a range error if
 * there are multiple properties. Returns undefined if there are no properties.
 * @deprecated Import into unit tests only please.
 * @template {object} Value
 * @param {Record<string, Value>} obj
 * @returns {Value | undefined}
 */
export function only(obj) {
  const vs = Object.values(obj);
  if (vs.length > 1)
    throw new RangeError(`Object should contain at most one value!`);
  return vs[0];
}

/**
 * Returns true if this is a suggested diagnosis action.
 *
 * @param {DiagnosisAction} action
 * @returns {boolean} true if the diagnosis action is a suggested one.
 */
function isSuggestedDiagnosisAction(action) {
  return action.type === 'diagnosis' && action.subtype === 'suggestion';
}

/**
 * Calculates the new state of the action based on the data the user has
 * entered into the UI (and thus, the state manager)
 *
 * @deprecated Import into unit tests only please.
 * @param {DiagnosisReducerState} state
 * @param {DiagnosisAction} action
 * @returns {string}
 */
export function calculateActionState(state, action) {
  const isSuggestedAction = isSuggestedDiagnosisAction(action);

  if (isSuggestedAction) {
    // User selected an HCC, that amounts to marking the diagnosis incorrect.
    // In the context of a suggested diagnosis, this means marking the
    // diagnosis as not_present.
    if (state.selectedHcc) return 'not_present';

    // Selecting the 'incorrect diagnosis' option means that the suggested
    // diagnosis is not present, and the suggestion was incorrect.
    if (state.diagnosisIncorrect) return 'not_present';

    // If no diagnoses have been selected, it means the patient has not been
    // evaluated for the suggested diagnosis.
    if (isEmpty(state.selectedDiagnoses)) return 'not_evaluated';
  } else {
    // The user has selected a different HCC, mark the diagnosis as incorrect:
    if (state.selectedHcc) return 'diagnosis_incorrect';

    // If the user has marked this to complete without code, mark the state as
    // complete, I guess.
    if (state.completeWithoutCode) return 'complete';

    // Selecting the 'incorrect diagnosis' option means that the condition
    // is not applicable to the patient in question.
    if (state.diagnosisIncorrect) return 'diagnosis_incorrect';

    // If no diagnoses have been selected, leave the action open.
    if (isEmpty(state.selectedDiagnoses)) return 'incomplete';
  }

  return 'complete';
}

/**
 * Given a bag of properties, generate an object-lookalike of the form
 * data that will be submitted to the server for this action.
 *
 * @param diagnosisAction
 * @param dateOfService
 * @param selectedProviderId
 * @param state
 */
function actionStateForSubmission({
  diagnosisAction,
  dateOfService,
  selectedProviderId,
  state,
}) {
  const diagnosisCodes = Object.keys(state.selectedDiagnoses);

  return {
    id: diagnosisAction.id,
    updated_at: diagnosisAction.updatedAt,
    date_of_service: dateOfService,

    // Note that "otherHccs" will only ever be an empty string or a single
    // HCC code. Why it's called "otherHccs" and not "otherHcc" isn't clear
    // to me. --NH
    other_hccs: state.selectedHcc || '',

    state: calculateActionState(state, diagnosisAction),
    was_diagnosed: diagnosisCodes.length > 0 || state.completeWithoutCode,
    user_added_diagnosis_codes: diagnosisCodes,

    pcp_visit_date_of_service: dateOfService,
    pcp_visit_servicing_provider: selectedProviderId,
    servicing_provider: selectedProviderId,

    // TODO: is this the right property? Should this be visit_comments?
    notes: state.note,
  };
}

/**
 * Given a list of actions that are being changed (usually just one), and
 * a pre-existing form state, provided by the server and transformed by
 * useSummaryData, regenerate that form state as a flat object with proper
 * keys and values, such that Django can interpret it.
 *
 * @param actionStates
 * @param formState
 * @returns {{[p: string]: *}}
 */
function flattenActionStates(actionStates, formState) {
  const actionIds = new Set(actionStates.map((as) => as.id));
  const baseForms = Object.values(formState).filter(
    (fs) => !actionIds.has(fs.id),
  );
  const allForms = [...actionStates, ...baseForms];
  const formCount = allForms.length;

  // Each form gets an "index". The index doesn't matter to Django except as
  // a way to namespace action form data. It keys off of the `id` property
  // within each form namespace to actually parcel out the data into per-action
  // buckets.
  const actionFormStates = allForms.reduce((acc, actionState, i) => {
    const prefix = (name) => `form-${i}-${name}`;

    return Object.entries(actionState).reduce(
      // eslint-disable-next-line no-shadow
      (acc, [fieldName, fieldValue]) => {
        return {
          ...acc,
          [prefix(fieldName)]: fieldValue,
        };
      },
      acc,
    );
  }, {});

  return {
    ...actionFormStates,
    'form-TOTAL_FORMS': formCount,
    'form-INITIAL_FORMS': formCount,
    'form-MIN_NUM_FORMS': formCount,
    'form-MAX_NUM_FORMS': formCount,
  };
}

/**
 * Calculates what the camelCased form submission shape should be, based on the
 * input action and user-entered information.
 *
 * @param {DiagnosisAction} diagnosisAction
 * @param {Date} dateOfService
 * @param {Record<string, { supersedes: number[], supersededBy: number[] }>} hccTrumping
 * @param {Record<string, unknown>} formState
 * @param {string|number} selectedProviderId
 * @param {DiagnosisReducerState} state
 * @returns {DiagnosisSubmissionState} The submission form state
 */
export function stateForSubmission({
  diagnosisAction,
  dateOfService,
  formState,
  selectedProviderId,
  state,
}) {
  const diagnosisCodes = Object.keys(state.selectedDiagnoses);
  const actionState = actionStateForSubmission({
    dateOfService,
    diagnosisAction,
    selectedProviderId,
    state,
  });

  return {
    ...flattenActionStates([actionState], formState),

    // Note that the server wants selected diagnosis codes on both the action
    // submission _and_ as a top-level property of the submission itself. If
    // we were submitting multiple actions at a time (not something we do in
    // EHR contexts, but would in regular Patient Summary), we would want to
    // concatenate the top-level userAddedDiagnosisCodes together.
    //
    // Also note that this property is camelCased while the rest are
    // snake_case. This is what the server expects, which accounts for the
    // inconsistency.
    userAddedDiagnosisCodes: diagnosisCodes,

    pcp_visit_date_of_service: dateOfService,

    // django view reads this value from the post body if it is not in the path
    date_of_service: dateOfService,

    pcp_visit_servicing_provider: selectedProviderId,
    servicing_provider: selectedProviderId,

    // Unused, but included for compatibility:
    visit_comments: '',
    visit_type: '',
  };
}

/**
 * Make sure the value we send to the server is a string in the format we
 * expect it to be.
 *
 * @param {Date|string|number|boolean|string[]|null} value
 * @returns {string}
 */
function formatValue(value) {
  if (value instanceof Date) {
    return formatISO(value, { representation: 'date' });
  }
  if (Array.isArray(value)) {
    return value.join(',');
  }
  return String(value);
}

/**
 * Converts a flat, simple object into a formData object with the same
 * properties and values. Doesn't support nested object structures or
 * non-scalar values aside from dates and arrays of strings.
 *
 * @param {Record<string, Date|string|number|boolean|string[]|null>} obj
 * @returns {FormData}
 */
export function objectToFormData(obj) {
  const fd = new FormData();
  Object.entries(obj).forEach(([fieldName, fieldValue]) => {
    fd.append(fieldName, formatValue(fieldValue));
  });
  return fd;
}
