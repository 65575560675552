const rootKey = 'svu-menu';

const queryKeyFactory = {
  participants: ({
    svuMenuId = undefined,
    planId = undefined,
    groupBy = undefined,
  } = {}) => {
    if (!svuMenuId && !planId && !groupBy) {
      return [rootKey];
    }
    return [rootKey, { svuMenuId, planId, groupBy }];
  },
};

export default queryKeyFactory;
