import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { pickBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';

import useGetActivityLogs from '@ot/pages/Challenges/ChallengesView/hooks/useGetActivityLogs';
import usePostApproveChallenges from '@ot/hooks/usePostApproveChallenges';
import usePostUpdateChallenges from '@ot/hooks/usePostUpdateChallenges';

function setDefaultValues(resetField, challenge) {
  // Only the following fields are editable.  Populate them with their respective challenge values
  // and set them as the default values so that they can be restored by calling reset() if the user
  // chooses to cancel editing.
  resetField('challengeName', { defaultValue: challenge.name });
  resetField('challengeDescription', { defaultValue: challenge.description });
  resetField('challengeRewardText', { defaultValue: challenge.rewardText });
  resetField('challengeSVUAward', {
    defaultValue: challenge.svuAward ? challenge.svuAward : '',
  });
  resetField('challengeGoal', { defaultValue: challenge.goal });
  resetField('challengeHidden', { defaultValue: challenge.hidden });
  resetField('challengeEndDate', { defaultValue: challenge.endDate });

  challenge.challengePlans?.forEach((challengePlan) => {
    resetField(`challengePlans.${challengePlan.planId}`, {
      defaultValue: challengePlan.fundingPlanId,
    });
  });
}

export default function useDetailsSidebar(challenges) {
  const [sidebarChallengeId, setSidebarChallengeId] = useState(null);
  const [isSidebarShown, setIsSidebarShown] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const {
    mutate: mutateUpdate,
    data: updateFailedMessages,
    reset: resetMutation,
  } = usePostUpdateChallenges();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting, dirtyFields },
    register,
    reset,
    resetField,
  } = useForm({
    mode: 'onSubmit',
  });

  register('challengeName');
  register('challengeDescription');
  register('challengeRewardText');
  register('challengeSVUAward');
  register('challengeGoal');
  register('challengeHidden');
  register('challengeEndDate');

  function resetForm() {
    setIsEditEnabled(false);
    reset();
  }

  const sidebarChallenge = challenges?.find(
    (challenge) => challenge.id === sidebarChallengeId,
  );

  useEffect(() => {
    if (sidebarChallenge) {
      setDefaultValues(resetField, sidebarChallenge);
    }
  }, [resetField, sidebarChallenge]);

  const { data: activityLogs, isLoading } = useGetActivityLogs({
    challengeId: sidebarChallengeId,
  });

  const handleClose = () => {
    setIsSidebarShown(false);
    resetMutation();
  };

  const handleShowSidebarChallenge = (challenge) => {
    setIsSidebarShown(true);
    setSidebarChallengeId(challenge.id);
    setIsEditEnabled(false);
  };

  const { mutate } = usePostApproveChallenges();

  const handleApproveChallenge = (challenge) => {
    mutate({ challengeIdList: [challenge.id] });
    handleClose();
  };

  // Return date in YYYY-MM-DD format
  // The challenge end date comes in YYYY-MM-DD format, so I need to format dates in this same format
  // since multiple parts of the code is expecting it.
  function formatDateIgnoreTimeZoneYYYYMMDD(date) {
    if (!date) return undefined;

    if (typeof date === 'string') {
      return date;
    }

    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.padStart(2, '0');

    let day = date.getDate().toString();
    day = day.padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  function isChallengeNotEditable(type, status, endDate) {
    if (!endDate) return false;

    if (type === 'custom') {
      return !(status === 'in_progress' || status === 'pending_review');
    }

    let now = new Date().toLocaleDateString();
    let parts = now.split('/');
    now = new Date(parts[2], parts[0] - 1, parts[1]);

    // endDate can come in as either a string in YYYY-MM-DD format or as a Date object.
    // If it's a YYYY-MM-DD string, then convert it to a Date object
    // so that we can do a date comparison against the current time.
    let endDateObj = endDate;
    if (typeof endDate === 'string') {
      parts = endDate.split('-');
      endDateObj = new Date(parts[0], parts[1] - 1, parts[2]);
    }

    return (
      status === 'completed' || (status === 'in_progress' && now > endDateObj)
    );
  }

  const onSubmitSaveChanges = (data) => {
    const dirtyFieldKeys = Object.keys(dirtyFields);
    // Here we only submit the dirty values of the form.
    const dirtyValues = dirtyFieldKeys.reduce(
      (obj, key) => ({ ...obj, [key]: data[key] }),
      {},
    );
    const endDateFormatted = formatDateIgnoreTimeZoneYYYYMMDD(
      dirtyValues.challengeEndDate,
    );

    // The dirtyValues reducer above doesn't handle nested objects,
    // so we handle challengePlans manually.
    const dirtyChallengePlans = pickBy(
      dirtyValues.challengePlans ?? {},
      (_, key) => dirtyFields.challengePlans[key],
    );

    const updatePayload = {
      name: dirtyValues.challengeName,
      description: dirtyValues.challengeDescription,
      reward_text: dirtyValues.challengeRewardText,
      svu_award: dirtyValues.challengeSVUAward,
      goal: dirtyValues.challengeGoal,
      hidden: dirtyValues.challengeHidden,
      end_date: endDateFormatted,
      challenge_plans: dirtyChallengePlans,
    };
    mutateUpdate(
      {
        challengeIdList: [sidebarChallenge.id],
        challengeUpdates: updatePayload,
      },
      {
        onSettled: (mutateErrors) => {
          if (isEmpty(mutateErrors)) {
            setIsEditEnabled(false);
          }
        },
      },
    );
  };

  const onEnableEdit = (event) => {
    if (event.target.checked) {
      setIsEditEnabled(true);
    } else {
      resetForm();
    }
  };

  const validateEndDate = (endDate) => {
    let now = new Date().toLocaleDateString();
    let parts = now.split('/');
    now = new Date(parts[2], parts[0] - 1, parts[1]);

    let endDateObj = null;
    if (typeof endDate === 'string') {
      parts = endDate.split('-');
      endDateObj = new Date(parts[0], parts[1] - 1, parts[2]);
    } else {
      endDateObj = endDate;
    }

    return now <= endDateObj;
  };

  const validateGoal = (goal, progress) => {
    return progress <= goal;
  };

  const onSubmitCancel = () => {
    resetForm();
  };

  return {
    sidebarChallenge,
    handleShowSidebarChallenge,
    isSidebarShown,
    handleClose,
    activityLogs,
    isLoading,
    handleApproveChallenge,
    isChallengeNotEditable,
    isEditEnabled,
    onEnableEdit,
    isSubmitting,
    errors,
    updateFailedMessages,
    handleSubmit,
    onSubmitSaveChanges,
    onSubmitCancel,
    validateGoal,
    validateEndDate,
    resetForm,
    isDirty,
    control,
  };
}
