import { Box, styled } from '@mui/material';
import { number } from 'prop-types';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import {
  CommonFirstColumn,
  CommonProgressBar,
  CommonProgressText,
} from './commonStyles';

export default function PatientExperienceRowHelpTaskCompletion({ value }) {
  const roundedValue = value !== null ? Math.round(value) : null;
  return roundedValue && roundedValue >= 95 ? (
    <GreenBox>
      <CommonProgressText>{roundedValue}%</CommonProgressText>
      <StyledIcon
        fontSize="large"
        data-testid="emoji-events-icon"
        aria-label="Emoji Events Icon"
      />
    </GreenBox>
  ) : (
    <>
      <HelpTaskText>
        {roundedValue || roundedValue === 0 ? `${roundedValue}%` : '--'}
      </HelpTaskText>
      <ProgressBar variant="determinate" value={roundedValue} />
    </>
  );
}

PatientExperienceRowHelpTaskCompletion.propTypes = {
  value: number,
};

PatientExperienceRowHelpTaskCompletion.defaultProps = {
  value: undefined,
};

const GreenBox = styled(Box)`
  background-color: ${(p) => p.theme.palette.status?.successBackground};
  margin: ${(p) => p.theme.spacing(1, 0.5, 0, 0.5)};
`;

const ProgressBar = styled(CommonProgressBar)`
  background-color: ${(p) => p.theme.palette.status?.warningBackgroundHover};
  & .MuiLinearProgress-bar {
    background-color: #26ab83;
  }
`;

const StyledIcon = styled(EmojiEventsIcon)`
  color: #c2a200;
`;

const HelpTaskText = styled(CommonProgressText)`
  margin-top: ${(p) => p.theme.spacing(1)};
`;

export const RowHelpTaskCompletionFirstColumn = styled(CommonFirstColumn)`
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  margin-top: ${(p) => p.theme.spacing(1)};
`;
