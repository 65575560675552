import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, Input } from '@mui/material';
import useId from '@mui/utils/useId';

export default function RhfTextField({
  control,
  id: idProp,
  name,
  rules,
  placeholder,
  label,
  sx,
}) {
  const id = useId(idProp);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl>
          {label ? <FormLabel htmlFor={id}>{label}</FormLabel> : null}
          <Input
            id={id}
            placeholder={placeholder}
            value={field.value || ''}
            onChange={field.onChange}
            sx={{ height: 35, width: 400, ...sx }}
          />
        </FormControl>
      )}
    />
  );
}

RhfTextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

RhfTextField.defaultProps = {
  id: undefined,
  placeholder: undefined,
  label: undefined,
  sx: undefined,
};
