import { TableCell, TableRow, Typography } from '@mui/material';
import { formatNumber } from 'chart.js/helpers';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import BaseTable from '@/widgets/SvuTracker/SvuModal/BaseTable';

function SvuMonthlyOverview({ medicalGroupName, monthOverview, currentDate }) {
  const currentDateAsDate = parseISO(currentDate);
  const mgActions = Number(monthOverview.medicalGroup.actions);
  const youActions = Number(monthOverview.user.actions);
  const mgBonuses = Number(monthOverview.medicalGroup.bonuses);
  const mgPendingBonuses = Number(monthOverview.medicalGroup.pendingBonuses);
  const youBonuses = Number(monthOverview.user.bonuses);
  const mgTotal = mgActions + mgBonuses;
  const youTotal = youActions + youBonuses;

  const hasPendingChallenges = mgPendingBonuses > 0;

  return (
    <BaseTable
      title={`${format(currentDateAsDate, 'MMMM')} as of (${format(
        currentDateAsDate,
        'MM/dd',
      )})`}
      mgHeader={`${medicalGroupName} Contributed`}
      userHeader="You Contributed"
    >
      <TableRow>
        <TableCell>
          <Typography color="text.secondary" variant="bodymedium">
            Actions
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography color="status.infoForeground" variant="metricmedium">
            {formatNumber(mgActions)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography color="svu.dark" variant="metricmedium">
            {formatNumber(youActions)}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography color="text.secondary" variant="bodymedium">
            Bonuses
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography color="status.infoForeground" variant="metricmedium">
            {formatNumber(mgBonuses)}
          </Typography>
          {hasPendingChallenges && (
            <Typography color="status.infoForeground" variant="bodysmall">
              + {formatNumber(mgPendingBonuses)} pending approval
            </Typography>
          )}
        </TableCell>
        <TableCell align="right">
          <Typography color="svu.dark" variant="metricmedium">
            {formatNumber(youBonuses)}
          </Typography>

          {hasPendingChallenges && (
            <Typography color="status.infoForeground" variant="bodysmall">
              &nbsp;
            </Typography>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography color="text.secondary" variant="bodymediumbold">
            Total
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography color="status.infoForeground" variant="metricmediumbold">
            {formatNumber(mgTotal)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography color="svu.dark" variant="metricmediumbold">
            {formatNumber(youTotal)}
          </Typography>
        </TableCell>
      </TableRow>
    </BaseTable>
  );
}

export default SvuMonthlyOverview;

SvuMonthlyOverview.propTypes = {
  medicalGroupName: PropTypes.string.isRequired,
  currentDate: PropTypes.string.isRequired,
  monthOverview: PropTypes.shape({
    medicalGroup: PropTypes.shape({
      actions: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      bonuses: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      pendingBonuses: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
    user: PropTypes.shape({
      actions: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      bonuses: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      pendingBonuses: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  }).isRequired,
};
