import styled from 'styled-components';
import { Accordion, AccordionSummary, TableContainer } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';

import { challengePlanShape } from '@ot/pages/Challenges/ChallengesView/hooks/useGetChallenges';
import FundingPlanDropDown from '@ot/components/FundingPlanDropDown';

function PlanDetails({ challengePlans, planMix, isEditingEnabled, control }) {
  const sortedPlans = challengePlans.sort(function sortCaseInsensitive(a, b) {
    return a.planName.toLowerCase().localeCompare(b.planName.toLowerCase());
  });

  return (
    <Accordion disableGutters>
      <AccordionSummary
        sx={{ backgroundColor: 'gainsboro' }}
        expandIcon={<ExpandMoreIcon />}
      >
        <SectionHeading>Plan Details</SectionHeading>
      </AccordionSummary>
      <StyledSection>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Plan Id</TableCell>
                <TableCell>Plan Name</TableCell>
                <TableCell>Funding Plan</TableCell>
                <TableCell>Estimated SVUs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedPlans.map((challengePlan) => {
                return (
                  <TableRow key={challengePlan.planId}>
                    <TableCell>{challengePlan.planId}</TableCell>
                    <TableCell>{challengePlan.planName}</TableCell>
                    <TableCell>
                      {isEditingEnabled ? (
                        <FundingPlanDropDown
                          control={control}
                          planId={challengePlan.planId}
                          defaultValue={challengePlan.fundingPlanId}
                          name={`challengePlans.${challengePlan.planId}`}
                        />
                      ) : (
                        challengePlan.fundingPlanName
                      )}
                    </TableCell>
                    <TableCell>
                      {planMix[challengePlan.planId] ?? 'N/A'}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledSection>
    </Accordion>
  );
}

const SectionHeading = styled.h2`
  font-size: 20px;
  margin-bottom: 0;
`;

const StyledSection = styled.div`
  padding: 0 10px 10px;
`;

export default PlanDetails;

PlanDetails.propTypes = {
  challengePlans: PropTypes.arrayOf(challengePlanShape).isRequired,
  isEditingEnabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  planMix: PropTypes.object.isRequired,
};

PlanDetails.defaultProps = {
  isEditingEnabled: false,
};
