import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';

export default function BaseDialogActions({ sx, children, ...props }) {
  return (
    <DialogActions
      disableSpacing
      sx={{
        height: '10%',
        maxHeight: '80px',
        justifyContent: 'space-between',
        ...sx,
      }}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {children}
    </DialogActions>
  );
}

BaseDialogActions.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
BaseDialogActions.defaultProps = {
  sx: undefined,
};
