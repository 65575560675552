import { styled } from '@mui/material';

import ModalTextDisplay from '@/pages/PatientSummary/components/modals/ModalTextDisplay';

const ModalTextDisplayBold = styled(ModalTextDisplay)`
  margin-bottom: 5px;
  margin-left: 0px;
  font-weight: 600;
  padding: 0px;
`;

export default ModalTextDisplayBold;
