import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import RhfSelect from '@ot/components/reactHookFormComponents/RhfSelect';

import useGetFundingPlans from './useGetFundingPlans';

function FundingPlanDropDown({ planId, ...selectProps }) {
  const { data, isLoading, isError } = useGetFundingPlans();

  if (isLoading) {
    return 'Loading';
  }

  if (isError) {
    return (
      <Typography color="status.danger" role="alert">
        Error
      </Typography>
    );
  }

  const plans = data.results;

  const otherOptions = plans.map((plan) => {
    return { value: plan.id, label: plan.name };
  });

  return (
    <RhfSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...selectProps}
      options={[
        // prettier-ignore
        { value: planId, label: 'Eligible Plan' },
        ...otherOptions,
      ]}
    />
  );
}

export default FundingPlanDropDown;

FundingPlanDropDown.propTypes = {
  ...RhfSelect.propTypes,
  planId: PropTypes.number.isRequired,
};
