/* eslint-disable no-shadow */
import { Box, styled } from '@mui/material';

import { color } from '@/utils/stylingUtils';

import { columnDefinition } from './propTypes';
import TableCardLabel from './TableCardLabel';

const StyledRule = styled(Box)`
  grid-column-start: start;
  grid-column-end: end;
  border-bottom: 1px solid ${color('border.base')};
`;

export default function TableCardHeader({ columnDefinition }) {
  return (
    <Box role="rowgroup" sx={{ display: 'contents' }}>
      <Box role="row" sx={{ display: 'contents' }}>
        {columnDefinition.map((cd) => {
          return (
            <Box key={cd.id} role="columnheader">
              <TableCardLabel narrow={false} columnDefinition={cd} />
            </Box>
          );
        })}
      </Box>
      <StyledRule aria-hidden />
    </Box>
  );
}

TableCardHeader.propTypes = {
  columnDefinition: columnDefinition.isRequired,
};
