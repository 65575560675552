import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import RhfRadioGroup from '@/components/reactHookFormComponents/RhfRadioGroup';
import FormErrorMessage from '@/components/FormErrorMessage';

export default function ModalRadioGroup({
  label,
  options,
  defaultValue,
  errorMsg,
  actionId,
  BoxSx,
  InputSx,
  name,
  control,
  errors,
  pendoIdPrefixer,
  ...RadioGroupProps
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        mt: 1,
        flexDirection: 'column',
        ...BoxSx,
      }}
    >
      <RhfRadioGroup
        control={control}
        defaultValue={defaultValue}
        name={name}
        label={label}
        options={options}
        rules={
          errorMsg
            ? {
                required: errorMsg,
              }
            : {}
        }
        pendoIdPrefixer={pendoIdPrefixer}
        sx={{ ...InputSx }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...RadioGroupProps}
      />
      <FormErrorMessage name={name} errors={errors} />
    </Box>
  );
}

ModalRadioGroup.propTypes = {
  label: PropTypes.string,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // options is given as value, label to match ModalSelect options
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
  actionId: PropTypes.number,
  defaultValue: PropTypes.string,
  BoxSx: PropTypes.object,
  InputSx: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  pendoIdPrefixer: PropTypes.func,
};

ModalRadioGroup.defaultProps = {
  label: undefined,
  errorMsg: undefined,
  actionId: undefined,
  defaultValue: '',
  BoxSx: undefined,
  InputSx: undefined, // { width: 600 },
  pendoIdPrefixer: () => {},
};
