import { formatISO } from 'date-fns';

// Generate a params object for Axios:
function generateParams({ patientId, dateOfService }) {
  const params = {};
  // Eslint you crazy.
  // eslint-disable-next-line no-unneeded-ternary
  params.patient_id = patientId ? patientId : null;
  params.date_of_service = dateOfService
    ? formatISO(dateOfService, { representation: 'date' })
    : null;

  return params;
}

/**
 * Creates a fetcher function that will perform a GET request at the provided
 * path.
 *
 * @param path {string}
 * @returns {function(axios: Axios, patientId: string | number, dateOfService?: Date): Promise<unknown>}
 */
export const createFetcher =
  (path) =>
  (axios, patientId = undefined, dateOfService = undefined) => {
    return axios
      .get(path, {
        params: generateParams({ patientId, dateOfService }),
      })
      .then((response) => response.data);
  };

export const fetchV1Summary = createFetcher('/api/ehr/patient_summary');
export const fetchV1ActionFormStates = createFetcher(
  '/api/ehr/patient_summary/forms',
);
export const fetchCompletedActions = createFetcher(
  '/api/ehr/patient_summary/completed_actions',
);
export const fetchV2Actions = createFetcher('/api/ehr/patient_summary/actions');
