import { startCase } from 'lodash';

import { Page } from '../constants';

export default function getPageTitle(page) {
  switch (page) {
    case Page.medicationManagement:
      return 'Medications';
    case Page.visits:
      return 'Visits';
    case Page.transitionOfCare:
      return 'Transitions of Care';
    case Page.priorityPatient:
      return 'ED Prevention';
    default:
      // Ideally the page URL and name match the title so that we don't have to add cases above.
      return startCase(page);
  }
}
