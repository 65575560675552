import { createTheme } from '@mui/material';

import components from './components';
import typography from './typography';
import palette from './palette';

/**
 * Our custom theme for MUI.
 *
 * Documentation: https://mui.com/customization/theming/
 */
const theme = createTheme({
  components,
  palette,

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1440,
    },
  },

  // Our standard step size for border radius.
  // Allows you to do things like `sx={{ borderRadius: 2 }}` which becomes 8px.
  shape: { borderRadius: 4 },

  // We only have 3 levels of shadows: small, regular, and large.
  shadows: [
    'none',
    '0px 2px 4px rgba(0, 0, 0, 0.075)',
    '0px 8px 16px rgba(0, 0, 0, 0.15)',
    '0px 16px 48px rgba(0, 0, 0, 0.176)',
  ],

  typography,
});

export default theme;
