import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

export default function ClearFilterSectionButton({
  filtersToClear,
  resetField,
  sx,
}) {
  const handleClearFilterSection = () => {
    filtersToClear.forEach((filter) => resetField(filter));
  };
  return (
    <Button variant="text" onClick={handleClearFilterSection} sx={sx}>
      Clear All
    </Button>
  );
}

ClearFilterSectionButton.propTypes = {
  resetField: PropTypes.func.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtersToClear: PropTypes.arrayOf(PropTypes.string).isRequired,
};
ClearFilterSectionButton.defaultProps = {
  sx: undefined,
};
