import axios from 'axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

// @ts-expect-error - js module
import { useQueryParameters } from '@/contexts/QueryParametersContext';

import { Goal } from '../types';

export type ResponseData = Array<Goal>;

export async function getGoals({ queryKey }: QueryFunctionContext) {
  const [, dateMonth] = queryKey;
  const response = await axios.get<ResponseData>('/api/goals', {
    params: { date_month: dateMonth },
  });
  return response.data;
}

export default function useGetGoals({ enabled } = { enabled: true }) {
  const { parameters } = useQueryParameters();
  return useQuery({
    queryFn: getGoals,
    queryKey: ['goals', parameters.dateMonth],
    enabled,
  });
}
