import { func, instanceOf } from 'prop-types';
import { Button, Card, Stack, Typography } from '@mui/material';

export default function ErrorView({ onReload, onReset, onGoBack }) {
  return (
    <Card variant="outlined" sx={{ padding: 2 }}>
      <Typography py={2}>An unexpected error occurred</Typography>
      <Stack direction="row" gap={1}>
        <Button onClick={onReset}>Try reset</Button>
        <Button variant="outlined" onClick={onGoBack}>
          Go back
        </Button>
        <Button variant="outlined" onClick={onReload}>
          Reload the page
        </Button>
      </Stack>
    </Card>
  );
}

ErrorView.propTypes = {
  // Reserve this prop for future usage.
  // eslint-disable-next-line react/no-unused-prop-types
  error: instanceOf(Error).isRequired,
  onReset: func.isRequired,
  onReload: func.isRequired,
  onGoBack: func.isRequired,
};

// eslint-disable-next-line react/jsx-props-no-spreading
ErrorView.renderer = ({ error, reload, reset, goBack }) => (
  <ErrorView
    error={error}
    onReload={reload}
    onReset={reset}
    onGoBack={goBack}
  />
);
