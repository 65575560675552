import PropTypes from 'prop-types';
import { Box, ThemeProvider, Typography, styled } from '@mui/material';

import themeV2 from '@/themeV2';

import { ReactComponent as CheckIcon } from './svuAmount/check.svg';
import { ReactComponent as TimerIcon } from './svuAmount/timer.svg';

export default function SvuAmount({
  svuPrePromo,
  svuPostPromo,
  svuText,
  typographyVariant,
  sx,
  isComplete,
}) {
  const hasPromo = Boolean(
    svuPostPromo && svuPostPromo > 0 && svuPostPromo > svuPrePromo,
  );
  const chipSvuAmount = !hasPromo && isComplete ? svuPrePromo : svuPostPromo;
  return (
    <ThemeProvider theme={themeV2}>
      <Box
        sx={[
          { display: 'flex', alignItems: 'center' },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {(!isComplete || hasPromo) && (
          <Typography
            variant={typographyVariant}
            color="text.secondary"
            sx={{
              textDecorationLine: hasPromo ? 'line-through' : undefined,
              fontWeight: hasPromo
                ? (themeV2?.typography?.[typographyVariant]?.fontWeight ??
                    600) - 200
                : undefined,
            }}
            component="span"
          >
            {svuPrePromo} {svuText && !hasPromo ? 'SVU' : ''}
          </Typography>
        )}
        {(hasPromo || isComplete) && (
          <PromoContainer $isComplete={isComplete}>
            {isComplete && <StyledCheckIcon sx={{ mr: 0.5 }} />}
            <Typography variant={typographyVariant}>
              {chipSvuAmount} {svuText ? 'SVU' : ''}
            </Typography>
            {!isComplete && <StyledTimerIcon sx={{ ml: 0.5 }} />}
          </PromoContainer>
        )}
      </Box>
    </ThemeProvider>
  );
}

SvuAmount.propTypes = {
  svuPrePromo: PropTypes.number.isRequired,
  svuPostPromo: PropTypes.number,
  typographyVariant: PropTypes.string,
  svuText: PropTypes.bool,
  isComplete: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

SvuAmount.defaultProps = {
  svuPostPromo: undefined,
  svuText: undefined,
  typographyVariant: 'bodylargebold',
  sx: undefined,
  isComplete: false,
};

const StyledTimerIcon = styled(TimerIcon)`
  vertical-align: middle;
`;
const StyledCheckIcon = styled(CheckIcon)`
  vertical-align: middle;
`;

const PromoContainer = styled('span')`
  color: ${(p) =>
    p.$isComplete
      ? p.theme.palette.status.successForeground
      : p.theme.palette.svu.dark};
  display: inline-flex;
  align-items: center;
  padding: ${(p) => p.theme.spacing(0.5, 1)};
  background-color: ${(p) =>
    p.$isComplete
      ? p.theme.palette.status.successBackground
      : p.theme.palette.svu.lightest};
  border-radius: ${(p) => p.theme.shape.borderRadius * 4}px;
  margin-left: ${(p) => p.theme.spacing(1)};
`;
