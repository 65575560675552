import PropTypes from 'prop-types';
import { useState } from 'react';
import { useThrottle } from 'react-use';
import styled from 'styled-components';
import { chipClasses } from '@mui/material/Chip';

import useGetMedicalGroups from '@ot/hooks/useGetMedicalGroups';
import { medicalGroupShape } from '@ot/commonShapes';
import BaseAutocomplete from '@/components/BaseAutocomplete';

const getValue = (medicalGroups, multiple) => {
  if (multiple) {
    return medicalGroups;
  }
  if (medicalGroups) {
    return medicalGroups[0];
  }
  return undefined;
};

function MedicalGroupAutocomplete({
  medicalGroups,
  onChange,
  id,
  disabled,
  tinOnly,
  multiple,
  'data-pendo-id': dataPendoId,
  limitTags,
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const throttledQuery = useThrottle(searchQuery);

  const { data: medicalGroupOptions, isFetched } = useGetMedicalGroups(
    throttledQuery,
    tinOnly,
  );

  const handleInputChangeMedicalGroup = async (e) => {
    if (e) {
      setSearchQuery(e.target.value);
    }
  };

  const handleBlur = () => {
    setSearchQuery('');
  };

  const handleChangeMedicalGroup = (e, newMedicalGroups) => {
    setSearchQuery('');
    onChange(e, newMedicalGroups);
  };

  const placeholderString = tinOnly
    ? 'Type MG TIN to search'
    : 'Type MG name or TIN to search';
  return (
    <div>
      <StyledBaseAutocomplete
        multiple={multiple}
        autoHighlight
        disableListWrap
        disabled={disabled}
        fullWidth
        id={id}
        onChange={handleChangeMedicalGroup}
        onBlur={handleBlur}
        onInputChange={handleInputChangeMedicalGroup}
        options={isFetched ? medicalGroupOptions : []}
        getOptionLabel={(option) => `${option.name} - Tin:[${option.tin}]`}
        placeholder={placeholderString}
        value={getValue(medicalGroups, multiple)}
        open={isFetched}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        limitTags={limitTags}
        data-pendo-id={dataPendoId}
      />
    </div>
  );
}
MedicalGroupAutocomplete.propTypes = {
  medicalGroups: PropTypes.arrayOf(medicalGroupShape),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  tinOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  'data-pendo-id': PropTypes.string,
  limitTags: PropTypes.number,
};

MedicalGroupAutocomplete.defaultProps = {
  medicalGroups: undefined,
  id: undefined,
  disabled: false,
  tinOnly: false,
  multiple: true,
  'data-pendo-id': undefined,
  limitTags: undefined,
};

export default MedicalGroupAutocomplete;

const StyledBaseAutocomplete = styled(BaseAutocomplete)`
  .${chipClasses.root} {
    height: initial;
  }
  .${chipClasses.label} {
    white-space: initial;
  }
`;
