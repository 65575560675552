import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import {
  any,
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types';
import { useState } from 'react';

import PatientExperienceRowFollowupSurvey from './PatientExperience/PatientExperienceRowFollowupSurvey';
import PatientExperienceRowAverageRating, {
  RowAverageRatingFirstColumn,
} from './PatientExperience/PatientExperienceRowAverageRating';
import PatientExperienceRowHelpTaskCompletion, {
  RowHelpTaskCompletionFirstColumn,
} from './PatientExperience/PatientExperienceRowHelpTaskCompletion';
import { CommonFirstColumn } from './PatientExperience/commonStyles';

export const RowConfigType = {
  FollowupSurvey: 'FollowupSurvey',
  AverageRating: 'AverageRating',
  HelpTaskCompletion: 'HelpTaskCompletion',
};

const BannerRowConfig = shape({
  text: string,
  type: oneOf([
    RowConfigType.FollowupSurvey,
    RowConfigType.AverageRating,
    RowConfigType.HelpTaskCompletion,
  ]),
  hideInSummary: bool,
});

export default function PatientExperienceSummary({
  rowConfig,
  rows,
  columns,
  summary,
  selectedMonth,
  setSelectedMonth,
}) {
  const componentMapper = (type) => {
    switch (type) {
      case RowConfigType.FollowupSurvey:
        return PatientExperienceRowFollowupSurvey;
      case RowConfigType.AverageRating:
        return PatientExperienceRowAverageRating;
      case RowConfigType.HelpTaskCompletion:
        return PatientExperienceRowHelpTaskCompletion;
      default:
        return null;
    }
  };

  function getCellContents(type, value) {
    const Component = componentMapper(type);
    return Component ? <Component value={value} /> : null;
  }

  function renderFirstColumn(type, text) {
    let Component = CommonFirstColumn;
    switch (type) {
      case RowConfigType.AverageRating:
        Component = RowAverageRatingFirstColumn;
        break;
      case RowConfigType.HelpTaskCompletion:
        Component = RowHelpTaskCompletionFirstColumn;
        break;
      default:
        Component = CommonFirstColumn;
        break;
    }
    return <Component>{text}</Component>;
  }

  const handleColumnSelection = (index) => {
    setSelectedMonth(index);
  };

  function isSelected(index) {
    return !summary && selectedMonth === index;
  }

  const [hoverIndex, setHoverIndex] = useState();
  const handleHover = (index) => {
    if (summary) {
      return;
    }
    setHoverIndex(index);
  };
  function isHover(index) {
    return !summary && hoverIndex === index;
  }

  return (
    <TableContainer>
      <StyledTable>
        <TableHead>
          <TableRow>
            <FirstColumn />
            {columns.map((column, columnIndex) => (
              <HeaderCell
                key={column}
                onClick={() => handleColumnSelection(columnIndex)}
                $isSelected={isSelected(columnIndex)}
                onMouseOver={() => handleHover(columnIndex)}
                onMouseLeave={() => handleHover(undefined)}
                $isHover={isHover(columnIndex)}
                $isSummary={summary}
              >
                {column}
              </HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowConfig
            .filter((config) => (summary && !config.hideInSummary) || !summary)
            .map(({ text: rowText, type: rowType }, index) => (
              <TableRow key={rowText}>
                <FirstColumn>{renderFirstColumn(rowType, rowText)}</FirstColumn>
                {rows[index].map((value, columnIndex) => {
                  const key = `${index} ${columnIndex}`;
                  return (
                    <DataCell
                      key={key}
                      onClick={() => handleColumnSelection(columnIndex)}
                      $isSelected={isSelected(columnIndex)}
                      onMouseOver={() => handleHover(columnIndex)}
                      onMouseLeave={() => handleHover(undefined)}
                      $isHover={isHover(columnIndex)}
                      $isLastRow={index === rowConfig.length - 1}
                      $isSummary={summary}
                    >
                      {getCellContents(rowType, value)}
                    </DataCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}

PatientExperienceSummary.propTypes = {
  rowConfig: arrayOf(BannerRowConfig).isRequired,
  rows: arrayOf(arrayOf(any)).isRequired,
  columns: arrayOf(string).isRequired,
  summary: bool,
  selectedMonth: number.isRequired,
  setSelectedMonth: func.isRequired,
};

PatientExperienceSummary.defaultProps = {
  summary: false,
};

const CommonCellStyle = styled(TableCell)`
  border-bottom: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
  border-color: ${(p) => {
    if (p.$isSelected) return p.theme.palette.border.primaryActive;
    if (p.$isHover) return p.theme.palette.background.primaryLighter;
    return p.theme.palette.background.tableZebra;
  }};
  border-style: solid;
  border-left-width: 2px;
  border-right-width: 2px;
  border-top-width: 0px;
  border-bottom-width: 0px;
`;

const ClickableCell = styled(CommonCellStyle)`
  background-color: ${(p) => p.theme.palette.background.tableZebra};
  cursor: ${(p) => (p.$isSummary ? 'hand' : 'pointer')};
`;

const DataCell = styled(ClickableCell)`
  padding-bottom: ${(p) => (p.$isLastRow ? p.theme.spacing(1) : 0)};
  border-bottom-width: ${(p) => (p.$isLastRow ? `2px` : `0px`)};
  border-bottom-left-radius: ${(p) =>
    p.$isLastRow ? p.theme.shape.borderRadius : 0}px;
  border-bottom-right-radius: ${(p) =>
    p.$isLastRow ? p.theme.shape.borderRadius : 0}px;
`;

const HeaderCell = styled(ClickableCell)`
  border-top-width: 2px;
  border-top-left-radius: ${(p) => p.theme.shape.borderRadius}px;
  border-top-right-radius: ${(p) => p.theme.shape.borderRadius}px;
  color: ${(p) => p.theme.palette.text.secondary};
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  padding-top: ${(p) => p.theme.spacing(0.75)};
`;

const FirstColumn = styled(CommonCellStyle)`
  text-align: right;
`;

const StyledTable = styled(Table)`
  width: 700px;
  border-collapse: separate;
  border-spacing: ${(p) => p.theme.spacing(0.5, 0)};
`;
