/* eslint-disable no-shadow */
import { any, arrayOf, number, object } from 'prop-types';
import { useMeasure } from 'react-use';
import { Box, styled } from '@mui/material';

import { color } from '@/utils/stylingUtils';

import { columnDefinition, rowDefinition } from './propTypes';
import TableCardHeader from './TableCardHeader';
import TableCardRows from './TableCardRows';

const StyledTableCardWrapper = styled(Box)`
  background-color: ${color('background.base')};
  overflow-wrap: anywhere;
  word-break: normal;
  display: ${({ narrow }) => (narrow ? 'block' : 'grid')};
`;

/**
 * Given a column definition, generate a grid template pattern for an instance
 * of TableCard. It specifies some useful named areas that other components
 * hook into.
 *
 * @param columnDefinition
 * @param hasBadges
 * @returns {string}
 */
function generateGridTemplateColumns(columnDefinition, hasBadges) {
  // TODO: allow specifying widths via column definition
  //       this would let a user of this component specify how wide/narrow
  //       each column should be. this might react strangely with the
  //       overflow-wrap and word-break rules defined above, so make sure
  //       to test that combination and/or work around it.
  return `[start] ${columnDefinition.map(() => `1fr`).join(' ')} ${
    hasBadges ? `[badge] auto ` : ''
  }[end]`;
}

/**
 * A component that's good at displaying rich data in narrow contexts.
 *
 * @param cardBreakpoint
 * @param columnDefinition
 * @param data
 * @param rowDefinition
 * @param additionalProps
 * @returns {JSX.Element}
 * @constructor
 */
export default function TableCard({
  cardBreakpoint,
  columnDefinition,
  data,
  rowDefinition,
  additionalProps,
}) {
  // Measure the containing component, which should try to take up as much
  // horizontal room as it can. If its size falls below the threshold, show
  // a "card" layout rather than a table one, to conserve horizontal space:
  const [ref, { width }] = useMeasure();
  const narrow = width > 0 && width <= cardBreakpoint;
  const hasBadges = Boolean(rowDefinition.renderRowBadge);

  return (
    <StyledTableCardWrapper
      ref={ref}
      narrow={narrow}
      role={narrow ? 'list' : 'table'}
      sx={{
        gridTemplateColumns: narrow
          ? null
          : generateGridTemplateColumns(columnDefinition, hasBadges),
      }}
    >
      {narrow ? null : <TableCardHeader columnDefinition={columnDefinition} />}
      <TableCardRows
        additionalProps={additionalProps}
        columnDefinition={columnDefinition}
        data={data}
        hasBadges={hasBadges}
        narrow={narrow}
        rowDefinition={rowDefinition}
      />
    </StyledTableCardWrapper>
  );
}

TableCard.propTypes = {
  cardBreakpoint: number.isRequired,
  rowDefinition: rowDefinition.isRequired,
  columnDefinition: columnDefinition.isRequired,
  additionalProps: any,
  data: arrayOf(object.isRequired).isRequired,
};
TableCard.defaultProps = {
  additionalProps: null,
};
