import CompactPatientSummary from './components/CompactPatientSummary';
import CompactPatientSummarySection from './components/CompactPatientSummarySection';
import NonDiagnosisSection from './components/NonDiagnosisSection';
import RecaptureDiagnosisSection from './components/RecaptureDiagnosisSection';
import SuggestedDiagnosisSection from './components/SuggestedDiagnosisSection';
import CompactPatientDetails from './components/CompactPatientDetails';

export default CompactPatientSummary;

export {
  CompactPatientSummarySection,
  NonDiagnosisSection,
  RecaptureDiagnosisSection,
  SuggestedDiagnosisSection,
  CompactPatientDetails,
};
