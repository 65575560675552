import { useMemo, useState } from 'react';

const ROWS_PER_PAGE_DEFAULT = 10;
export default function usePagination({
  rows,
  rowsPerPage = ROWS_PER_PAGE_DEFAULT,
}) {
  const [page, setPage] = useState(0);

  const restartFromFirstPage = () => {
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const currentPage = useMemo(() => {
    return rows?.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  }, [page, rows, rowsPerPage]);

  return {
    currentPage,
    paginationProps: {
      handlePageChange,
      page,
      rowsPerPage,
      rowsPerPageOptions: [rowsPerPage],
      numRows: rows?.length,
    },
    restartFromFirstPage,
  };
}
