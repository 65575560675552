import { useCookies } from 'react-cookie';
import { Cookie, CookieSetOptions } from 'universal-cookie';

const ONE_YEAR_IN_SECONDS = 365 * 24 * 60 * 60;

/**
 * An ergonomic wrapper around react-cookie's setCookie.
 */
export default function useSetCookie() {
  const [, setCookie] = useCookies();
  return (name: string, value: Cookie, options?: CookieSetOptions) =>
    setCookie(name, value, {
      path: '/', // make cookie accessible on all pages
      maxAge: ONE_YEAR_IN_SECONDS,
      ...options,
    });
}
