import { string } from 'prop-types';
import { Box, FormLabel } from '@mui/material';
import useId from '@mui/utils/useId';

import RhfInput from '@/components/reactHookFormComponents/RhfInput';

export default function PayableToInput({ name, control }) {
  const payableToId = useId();

  return (
    <Box sx={{ width: '100%' }}>
      <FormLabel htmlFor={payableToId}>Payable to</FormLabel>
      <RhfInput
        name={name}
        id={payableToId}
        control={control}
        rules={{ required: true }}
      />
    </Box>
  );
}

PayableToInput.propTypes = {
  name: string.isRequired,
};
