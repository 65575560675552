import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@reach/disclosure';
import { FormControl, FormGroup, Input, Typography } from '@mui/material';
import { matchSorter } from 'match-sorter';

import NestedCheckbox from '@/components/FiltersSidebar/NestedCheckbox';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUpIcon } from '@/assets/icons/chevronUp.svg';
import { ReactComponent as MagnifyingGlassIcon } from '@/assets/icons/magnifyingGlass.svg';

function Filter({ label, filterKey, options, search }) {
  const [isOpen, setIsOpen] = useState(
    () => window?.history?.state?.[filterKey] ?? false,
  );
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    window.history.replaceState(
      { ...window.history.state, [filterKey]: isOpen },
      '',
    );
  }, [filterKey, isOpen]);

  if (!Array.isArray(options)) {
    return null;
  }

  const filteredOptions = search
    ? matchSorter(options, searchValue, {
        keys: ['name'],
        threshold: matchSorter.rankings.CONTAINS,
      })
    : options;

  return (
    <FilterRoot>
      <Disclosure open={isOpen} onChange={() => setIsOpen((x) => !x)}>
        <StyledDisclosureButton
          $open={isOpen}
          data-pendo-id={`filter-disclosure-${label}`}
        >
          {label}
          {isOpen ? (
            <StyledChevronUpIcon width="13" />
          ) : (
            <StyledChevronDownIcon width="13" />
          )}
        </StyledDisclosureButton>
        <StyledDisclosurePanel>
          {search && (
            <StyledInput
              inputProps={{
                'aria-label': label,
              }}
              id="search"
              name={label}
              onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
              placeholder={`Search ${label.toLowerCase()}\u2026`}
              startAdornment={<StyledMagnifyingGlassIcon />}
              type="search"
              data-pendo-id={`filter-search-${label}`}
            />
          )}

          {search && filteredOptions.length === 0 && (
            <Typography sx={{ color: 'status.errorForeground' }}>
              No {label.toLowerCase()} found.
            </Typography>
          )}
          <FormControl>
            <FormGroup>
              {filteredOptions.map((option) => (
                <div data-pendo-id={`filter-checkbox-${label}`} key={option.id}>
                  <NestedCheckbox
                    filterKey={filterKey}
                    name={option.name}
                    id={option.id}
                    options={option.options}
                    pendoIdPrefix={`filter-checkbox-${label}-`}
                  />
                </div>
              ))}
            </FormGroup>
          </FormControl>
        </StyledDisclosurePanel>
      </Disclosure>
    </FilterRoot>
  );
}

Filter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  search: PropTypes.bool,
};

Filter.defaultProps = {
  options: undefined,
  search: false,
};

const FilterRoot = styled.div`
  box-shadow: inset 0px -1px 0px ${(p) => p.theme.palette.border.base};
`;

const StyledDisclosureButton = styled(DisclosureButton)`
  background: transparent;
  border-radius: 0;
  border: none;
  text-align: left;
  width: 100%;
  font-size: 1rem;
  display: flex;
  align-items: center;
  line-height: 22px;
  padding: 12px;
  padding-bottom: ${(props) => (props.$open ? '6px' : '12px')};
  font-weight: 600;
  cursor: pointer;
  color: ${(p) => p.theme.palette.text.primary};
  &:focus,
  &:focus-within {
    box-shadow: 0px 0px 0px 3.2px ${(p) => p.theme.palette.border.primaryActive};
    outline: none;
  }
`;

const StyledChevronUpIcon = styled(ChevronUpIcon)`
  margin-left: auto;
  color: ${(p) => p.theme.palette.icon.secondary};
`;
const StyledChevronDownIcon = styled(ChevronDownIcon)`
  margin-left: auto;
  color: ${(p) => p.theme.palette.icon.secondary};
`;

const StyledDisclosurePanel = styled(DisclosurePanel)`
  padding: 0 12px 12px 12px;
`;

const StyledInput = styled(Input)`
  margin: 0 auto 12px;
  padding: 6px;
  width: 100%;
`;

const StyledMagnifyingGlassIcon = styled(MagnifyingGlassIcon)`
  width: 17px;
  margin-right: 6px;
  color: ${(p) => p.theme.palette.icon.secondary};
`;

export default Filter;
