import { createGlobalStyle } from 'styled-components';

import avenirBookUrl from '@/assets/fonts/Avenir-Book.ttf';
import avenirBookObliqueUrl from '@/assets/fonts/Avenir-BookOblique.ttf';
import avenirMediumUrl from '@/assets/fonts/Avenir-Medium.ttf';
import avenirMediumObliqueUrl from '@/assets/fonts/Avenir-MediumOblique.ttf';
import avenirHeavyUrl from '@/assets/fonts/Avenir-Heavy.ttf';
import avenirHeavyObliqueUrl from '@/assets/fonts/Avenir-HeavyOblique.ttf';
import avenirBlackUrl from '@/assets/fonts/Avenir-Black.ttf';
import avenirBlackObliqueUrl from '@/assets/fonts/Avenir-BlackOblique.ttf';

const TheFontStyles = createGlobalStyle`
  @font-face {
    font-family: 'Avenir';
    font-size: normal;
    font-weight: 400;
    src: url(${avenirBookUrl});
  }

  @font-face {
    font-family: 'Avenir';
    font-size: normal;
    font-weight: 500;
    src: url(${avenirMediumUrl});
  }

  @font-face {
    font-family: 'Avenir';
    font-size: normal;
    font-weight: 600;
    src: url(${avenirHeavyUrl});
  }

  @font-face {
    font-family: 'Avenir';
    font-size: normal;
    font-weight: 700;
    src: url(${avenirBlackUrl});
  }

  @font-face {
    font-family: 'Avenir';
    font-size: normal;
    font-weight: 400;
    font-style: italic;
    src: url(${avenirBookObliqueUrl});
  }

  @font-face {
    font-family: 'Avenir';
    font-size: normal;
    font-weight: 500;
    font-style: italic;
    src: url(${avenirMediumObliqueUrl});
  }

  @font-face {
    font-family: 'Avenir';
    font-size: normal;
    font-weight: 600;
    font-style: italic;
    src: url(${avenirHeavyObliqueUrl});
  }

  @font-face {
    font-family: 'Avenir';
    font-size: normal;
    font-weight: 700;
    font-style: italic;
    src: url(${avenirBlackObliqueUrl});
  }
`;

export default TheFontStyles;
