import { css } from 'styled-components';

import palette from '@/theme/palette';

/**
 *
 */
const focusOutline = css`
  &:focus,
  &:focus-within {
    box-shadow: 0px 0px 0px 3.2px ${palette.focus.border};
    outline: none;
  }
`;

const lightFocusOutline = css`
  &:focus,
  &:focus-within {
    box-shadow: 0px 0px 0px 3.2px ${palette.focus.shadow};
    outline: none;
  }
`;

export { lightFocusOutline };
export default focusOutline;
