import { useMemo } from 'react';

import useFeatureFlag from '@/utils/useFeatureFlag';

/**
 * @typedef UseConditionsForDisplayHook
 * @property {Diagnosis[]} pastDiagnoses
 * @property {Diagnosis[]} commonCodes
 * @property {unknown[]} relatedConditions
 */

/**
 * Given a diagnosis action, tease out the various options that the user can
 * choose in the UI. This looks at feature flags for filtering out some
 * conditions.
 *
 * The UI is expected to not render sections that have no options within them.
 *
 * @param diagnosisAction
 * @returns {UseConditionsForDisplayHook}
 */
export default function useConditionsForDisplay(diagnosisAction) {
  const allowNonRafDiagnosisCodes = useFeatureFlag(
    'psAllowNonRafDiagnosisCodes',
  );
  const commonDiagnosesWithConditionEnabled = useFeatureFlag(
    'commonDiagnosesWithConditionEnabled',
  );
  const changeToRelatedConditionEnabled = useFeatureFlag(
    'changeToRelatedConditionEnabled',
  );

  return useMemo(
    () => ({
      pastDiagnoses: diagnosisAction.pastDiagnoses,
      commonCodes: commonDiagnosesWithConditionEnabled
        ? diagnosisAction.commonCodes.filter((code) => {
            if (allowNonRafDiagnosisCodes) return true;
            return Boolean(code.raf);
          })
        : [],
      relatedConditions: changeToRelatedConditionEnabled
        ? diagnosisAction.supersededHccs
        : [],
    }),
    [
      allowNonRafDiagnosisCodes,
      changeToRelatedConditionEnabled,
      commonDiagnosesWithConditionEnabled,
      diagnosisAction.commonCodes,
      diagnosisAction.pastDiagnoses,
      diagnosisAction.supersededHccs,
    ],
  );
}
