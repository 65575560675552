import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import BaseCheckbox from '@/components/BaseCheckbox';

const RhfCheckbox = forwardRef(function RhfCheckbox(
  {
    control,
    className,
    dataPendoId,
    defaultValue,
    disabled,
    label,
    name,
    onChangeExtraHandler,
    rules,
    sx,
  },
  ref,
) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <StyledCheckbox
          checked={field.value}
          className={className}
          data-pendo-id={dataPendoId}
          disabled={disabled}
          label={label}
          onChange={(e) => {
            field.onChange(e.target.checked);
            onChangeExtraHandler?.(e.target.checked);
          }}
          ref={ref}
          sx={sx}
        />
      )}
    />
  );
});

export default RhfCheckbox;

RhfCheckbox.propTypes = {
  control: PropTypes.any,
  className: PropTypes.string,
  dataPendoId: PropTypes.string,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onChangeExtraHandler: PropTypes.func,
  rules: PropTypes.any,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

RhfCheckbox.defaultProps = {
  control: undefined,
  className: undefined,
  dataPendoId: undefined,
  defaultValue: false,
  disabled: false,
  onChangeExtraHandler: undefined,
  rules: undefined,
  sx: undefined,
};

const StyledCheckbox = styled(BaseCheckbox)`
  align-items: baseline;
`;
