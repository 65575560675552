import { arrayOf, shape, string } from 'prop-types';
import { Box } from '@mui/material';

import { actionShape } from '@/utils/transformFrontendAction';

import ContextualInfoLabel from './ContextualInfo/ContextualInfoLabel';
import ContextualInfoDate from './ContextualInfo/ContextualInfoDate';

export const ContextualInfoType = {
  label: 'label',
  date: 'date',
};

export const ContextualInfoSource = {
  v2Dto: 'v2Dto',
  contextualInfo: 'contextualInfo',
};

export const contextualInfoConfigurationShape = shape({
  name: string,
  label: string,
  type: string,
  source: string,
});

function displayComponent(contextualInfo, configuration) {
  const { name, type, label, default: defaultVal } = configuration;
  let Component;
  const contextualInfoVal = contextualInfo[name] || defaultVal || '';
  switch (type) {
    case ContextualInfoType.label:
      Component = ContextualInfoLabel;
      break;
    case ContextualInfoType.date:
      Component = ContextualInfoDate;
      break;
    default:
      break;
  }
  if (Component) {
    return (
      <Component
        key={label}
        label={label}
        contextualInfoVal={contextualInfoVal.toString()}
      />
    );
  }
  return null;
}

export default function ContextualInfo({ action, configurations }) {
  return (
    <Box sx={{ mt: 1, display: 'flex', mb: 2 }}>
      {configurations.map((configuration) =>
        displayComponent(
          configuration.source === ContextualInfoSource.v2Dto
            ? action.v2Dto
            : action.v2Dto?.contextualInfo,
          configuration,
        ),
      )}
    </Box>
  );
}

ContextualInfo.propTypes = {
  action: actionShape.isRequired,
  configurations: arrayOf(contextualInfoConfigurationShape),
};

ContextualInfo.defaultProps = {
  configurations: undefined,
};
