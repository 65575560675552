import { arrayOf, bool, func, object } from 'prop-types';

import { patientShape } from '@/utils/shapes';

import DiagnosisFormSection from '../DiagnosisFormSection';
import DiagnosisActionFormCheckbox from '../DiagnosisActionFormCheckbox';

import DiagnosisCommonCodeIcdSearch from './DiagnosisCommonCodeIcdSearch';

export default function DiagnosisCommonCodesSection({
  commonCodes,
  diagnosisAction,
  onSelectCondition,
  patient,
  selectedConditions,
  disabled,
}) {
  return (
    <DiagnosisFormSection
      header="Possible other common diagnoses often encountered with this
              condition"
    >
      {commonCodes.map((condition, i) => {
        const key = `${condition.icd}--${i}`;
        return (
          <DiagnosisActionFormCheckbox
            key={key}
            checked={condition.icd in selectedConditions}
            diagnosis={condition}
            onChange={() => onSelectCondition(condition)}
            disabled={disabled}
          />
        );
      })}
      <DiagnosisCommonCodeIcdSearch
        diagnosisAction={diagnosisAction}
        onSelectCondition={onSelectCondition}
        patient={patient}
        selectedConditions={selectedConditions}
      />
    </DiagnosisFormSection>
  );
}

DiagnosisCommonCodesSection.propTypes = {
  commonCodes: arrayOf(object.isRequired).isRequired,
  diagnosisAction: object.isRequired,
  onSelectCondition: func.isRequired,
  patient: patientShape.isRequired,
  selectedConditions: object.isRequired,
  disabled: bool,
};

DiagnosisCommonCodesSection.defaultProps = {
  disabled: false,
};
