import { useEffect } from 'react';

import PrintToolBar from '@/pages/PrintSummary/components/PrintToolBar';
import TheDocumentTitle from '@/components/TheDocumentTitle';

import useGetPageMetadata from '../hooks/useGetPageMetadata';
import useGetTasks from '../hooks/useGetTasks';
import getPageTitle from '../utils/getPageTitle';
import { usePage } from '../contexts/PageContext';

import TaskTableView from './TaskTableView';
import ExportPrintLoadingDialog from './ExportPrintLoadingDialog';

export default function PrintPreviewPage() {
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading } =
    useGetTasks({
      pageSize: 200,
    });
  const { data: metadata } = useGetPageMetadata();

  const { pages = [] } = data || {};
  const { columnConfigurations = [] } = metadata || {};
  const tasks = pages.map((x) => x.results).flat();

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return (
    <>
      <TheDocumentTitle title={`Stellar ${getPageTitle(usePage())}`} />
      <PrintToolBar />

      <div style={{ pointerEvents: 'none' }}>
        <TaskTableView
          printPreview
          columnConfigurations={columnConfigurations}
          tasks={tasks}
        />
      </div>

      <ExportPrintLoadingDialog isPrint open={isLoading || hasNextPage} />
    </>
  );
}
