import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

async function fetchUrl(url) {
  const response = await axios.get(url);
  return response.data;
}

export default function useGetUrl(url, { enabled }) {
  return useQuery(['patient-summary-modal-url', url], () => fetchUrl(url), {
    enabled,
  });
}
