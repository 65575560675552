import PropTypes from 'prop-types';

/**
 * Transform data that came from action.test_types
 */
export default function transformTestTypes(testTypes) {
  if (!testTypes) {
    return [];
  }
  return Object.entries(testTypes).map(([value, label]) => ({
    label,
    value,
  }));
}

export const testTypeShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});
