import { useState } from 'react';
import PropTypes, { bool, string } from 'prop-types';
import { Skeleton } from '@mui/material';

import PatientBannerButton from '@/pages/TaskBasedWorkflow/components/patientDetailView/PatientBannerButton';
import { ButtonLabel } from '@/pages/PrintSummary/utils/printStyles';
import PatientProgramEnrollmentsModal from '@/pages/PatientSummary/components/PatientProgramEnrollmentsModal';
import useGetPatientProgramEnrollments from '@/pages/TaskBasedWorkflow/hooks/useGetPatientProgramEnrollments';

import PatientDetailsButton from './PatientDetailsButton';

export default function PatientProgramEnrollments({
  patientName,
  visuallyHidden,
  useBannerButton,
  patientId,
}) {
  const {
    data: programEnrollments,
    isLoading,
    isError,
  } = useGetPatientProgramEnrollments({
    patientId,
  });
  const [showModal, setShowModal] = useState(false);

  if (isLoading) {
    return <Skeleton width={110} height={22} />;
  }

  if (isError) return null;

  const buttonDisabled = programEnrollments.length === 0;

  return (
    <>
      {useBannerButton ? (
        <PatientBannerButton
          onClick={() => {
            setShowModal(true);
          }}
          tabIndex={visuallyHidden ? -1 : undefined}
          pendoId="worklist-patient-detail-enrollments-button"
          disabled={buttonDisabled}
        >
          <ButtonLabel>{`Enrollments (${programEnrollments.length})`}</ButtonLabel>
        </PatientBannerButton>
      ) : (
        <PatientDetailsButton
          onClick={() => {
            setShowModal(true);
          }}
          visuallyHidden={visuallyHidden}
          label={`Enrollments (${programEnrollments.length})`}
          size="medium"
          pendoId="ps-enrollments-button"
          disabled={buttonDisabled}
        />
      )}
      <PatientProgramEnrollmentsModal
        title="Current enrollments"
        patientName={patientName}
        programEnrollments={programEnrollments}
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        sx={{ minWidth: '480px' }}
      />
    </>
  );
}
PatientProgramEnrollments.propTypes = {
  patientName: string.isRequired,
  visuallyHidden: bool.isRequired,
  useBannerButton: bool,
  patientId: PropTypes.number.isRequired,
};
PatientProgramEnrollments.defaultProps = {
  useBannerButton: false,
};
