import useId from '@mui/utils/useId';
import { useController, useFormContext } from 'react-hook-form';
import { useMemo } from 'react';

import BaseSelect from '@/components/BaseSelect';
import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';
import useGetUserFilters from '@/hooks/useGetUserFilters';
import FormErrorMessage from '@/components/FormErrorMessage';
import transformOption, { getDefaultValue } from '@/utils/transformOption';

import DynamicFormLabel from '../DynamicFormLabel';

export default function DynamicFormOfficeDropdown({ field }) {
  const { label, name } = field;
  const fieldId = useId();
  const { resetField } = useFormContext();
  const { data, isLoading } = useGetUserFilters({
    onSuccess: (data) => {
      const defaultValue = getDefaultValue(data.offices);
      if (defaultValue) {
        resetField(name, { defaultValue });
      }
    },
  });
  const controller = useController({
    name,
    defaultValue: getDefaultValue(data?.offices),
    rules: {
      required: field.required ? 'Select an office' : undefined,
    },
  });

  const { offices = [] } = data || {};

  const options = useMemo(() => offices.map(transformOption), [offices]);

  if (!isLoading && offices.length === 0 && !field.isRequired) {
    // If the medical group has no offices, don't render this component.
    // If the above is true but this field is required, there is probably a data or config issue,
    // so render the empty dropdown anyway so that it's easier to debug.
    return null;
  }

  return (
    <>
      <DynamicFormLabel htmlFor={fieldId}>{label}</DynamicFormLabel>
      <BaseSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controller.field}
        data-pendo-id={field.dataPendoId}
        fullWidth
        label={label}
        options={options}
      />
      <FormErrorMessage errors={controller.formState.errors} name={name} />
    </>
  );
}

DynamicFormOfficeDropdown.propTypes = {
  field: executionRequirementShape.isRequired,
};
