/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import useId from '@mui/utils/useId';

function BaseFileUpload({
  label,
  id: idOverride,
  onChange,
  accept,
  multiple,
  disabled,
  ...UploadProps
}) {
  const id = useId(idOverride);

  return (
    <label htmlFor={id}>
      <input
        type="file"
        accept={accept}
        id={id}
        multiple={multiple}
        onChange={onChange}
        disabled={disabled}
        hidden
      />
      <Button
        variant="contained"
        component="span"
        disabled={disabled}
        {...UploadProps}
      >
        {label || 'Upload'}
      </Button>
    </label>
  );
}

export default BaseFileUpload;

BaseFileUpload.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  accept: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

BaseFileUpload.defaultProps = {
  label: undefined,
  id: undefined,
  onChange: undefined,
  multiple: false,
  disabled: false,
};
