import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import BaseDatePicker from '@/components/BaseDatePicker';

export default function RhfDatePicker({
  control,
  defaultValue,
  name,
  rules,
  ...DatePickerProps
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <BaseDatePicker
          fullWidth
          value={field.value}
          onChange={field.onChange}
          data-testid={name}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...DatePickerProps}
        />
      )}
    />
  );
}

RhfDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  PopperProps: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RhfDatePicker.defaultProps = {
  id: undefined,
  defaultValue: '',
  disabled: false,
  sx: undefined,
  PopperProps: undefined,
};
