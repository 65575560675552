import transformFrontendPatient from '@/utils/transformFrontendPatient';
import transformFeatureFlags from '@/utils/transformFeatureFlags';
import transformFrontendAction from '@/utils/transformFrontendAction';
import transformPatientAction from '@/pages/PatientSummary/utils/transformPatientAction';
import {
  ActionType,
  DIAGNOSIS_ACTION_SUBTYPE_RECAPTURE,
  DIAGNOSIS_ACTION_SUBTYPE_SUGGESTION,
} from '@/pages/PatientSummary/utils/constants';

/**
 * Given a collection and a fn that returns a string, return an object that
 * partitions the array by the string value returned by fn. Similar to lodash's
 * groupBy function.
 *
 * @param array {Record<string, T>[]}
 * @param fn {(value: Record<string, T>) => string}
 * @returns {Record<string, Record<string, T>>}
 */
export function group(array, fn) {
  const output = Object.create(null);
  for (const it of array) {
    const k = fn(it);
    if (!output[k]) output[k] = [];
    output[k].push(it);
  }
  return output;
}

/**
 * Converts the form state response into something that's easier to use
 * in other places. For example, the response from the request is something
 * like:
 * {
 *   form-0-attestation: null,
 *   form-0-date_of_service: "2022-10-14",
 *   form-0-id: 280803
 * }
 *
 * When what we really want is to aggregate the other way 'round:
 * {
 *   "280803": {
 *     // note: attestation is omitted because it's null
 *     date_of_service: "2022-10-14",
 *     id: 280803
 *   }
 * }
 *
 * This function performs that transformation, first by mapping all related
 * form fields to their form "index" (e.g. form-0, form-1, ... form-n) and
 * then using that to remap all forms to their id (280803 in the above).
 *
 * @deprecated Use in unit tests only please.
 * @template {object} FormState
 * @param formStates {Record<string, unknown>}
 * @returns {Record<string, Record<string, FormState>>}
 */
export function transformFormState(formStates) {
  const keyReg = /^form-(\d+)-(.+)$/;
  const formMap = {};

  for (const [key, value] of Object.entries(formStates)) {
    if (!keyReg.test(key)) {
      // Ignore properties that don't match the form. Those properties are:
      // pcp_visit_date_of_service
      // pcp_visit_servicing_provider
      // userAddedDiagnosisCodes
      // visit_comments
      // visit_type
      //
      // eslint-disable-next-line no-continue
      continue;
    }
    if (value === null) {
      // Ignore properties that are null.
      //
      // eslint-disable-next-line no-continue
      continue;
    }

    const [, formIndex, formProperty] = keyReg.exec(key);
    if (formMap[formIndex] === undefined) formMap[formIndex] = {};

    const form = formMap[formIndex];
    form[formProperty] = value;
  }

  return Object.values(formMap).reduce(
    (acc, form) => ({
      ...acc,
      [form.id]: form,
    }),
    {},
  );
}

function prePopulateFormState(formState, actions) {
  return actions.reduce((acc, action) => {
    return {
      ...acc,
      [action.id]: {
        ...acc[action.id],
        date_of_service: new Date(),
        updated_at: action.updated_at,
      },
    };
  }, formState);
}

/**
 * Given an action, return the kind of action it is for the purposes of the UI.
 * This is used by `splitActions` to parcel out actions into recaptureDiagnoses,
 * suggestedDiagnoses, and nonDiagnosis actions.
 *
 * @param action
 * @returns {'suggestedDiagnosis'|'recaptureDiagnosis'|'nonDiagnosis'|'_'}
 */
export function getActionCategory(action) {
  if (
    action.type === ActionType.diagnosis &&
    action.subtype === DIAGNOSIS_ACTION_SUBTYPE_SUGGESTION
  ) {
    return 'suggestedDiagnosis';
  }
  if (
    action.type === ActionType.diagnosis &&
    action.subtype === DIAGNOSIS_ACTION_SUBTYPE_RECAPTURE
  ) {
    return 'recaptureDiagnosis';
  }
  if (action.type !== ActionType.diagnosis) {
    return 'nonDiagnosis';
  }
  return '_';
}

/**
 * @typedef SplitActionsReturn
 * @property {{ incomplete: unknown[], complete: unknown[] }} recaptureDiagnosisActions
 * @property {{ incomplete: unknown[], complete: unknown[] }} suggestedDiagnosisActions
 * @property {{ incomplete: unknown[], complete: unknown[] }} nonDiagnosisActions
 */
/**
 * This fn takes in incomplete and complete v1 and v2 actions and reformulates
 * them into recapture diagnosis, suggested diagnosis, and non-diagnosis action collections,
 * where each collection includes v1 and v2 actions where appropriate, and each
 * includes complete and incomplete action states, with incomplete sorted
 * ahead of incomplete ones.
 *
 * @param v1IncompleteActions
 * @param v2IncompleteActions
 * @param v1CompleteActions
 * @param v2CompleteActions
 * @returns {SplitActionsReturn}
 */
export function splitActions({
  v1IncompleteActions,
  v2IncompleteActions,
  v1CompleteActions,
  v2CompleteActions,
}) {
  const {
    recaptureDiagnosis: v1IncompleteRecaptureDiagnosisActions = [],
    suggestedDiagnosis: v1IncompleteSuggestedDiagnosisActions = [],
    nonDiagnosis: v1IncompleteNonDiagnosisActions = [],
  } = group(v1IncompleteActions, getActionCategory);

  const {
    recaptureDiagnosis: v1CompleteRecaptureDiagnosisActions = [],
    suggestedDiagnosis: v1CompleteSuggestedDiagnosisActions = [],
    nonDiagnosis: v1CompleteNonDiagnosisActions = [],
  } = group(v1CompleteActions, getActionCategory);

  return {
    recaptureDiagnosisActions: {
      incomplete: v1IncompleteRecaptureDiagnosisActions,
      complete: v1CompleteRecaptureDiagnosisActions,
    },
    suggestedDiagnosisActions: {
      incomplete: v1IncompleteSuggestedDiagnosisActions,
      complete: v1CompleteSuggestedDiagnosisActions,
    },
    nonDiagnosisActions: {
      incomplete: [...v1IncompleteNonDiagnosisActions, ...v2IncompleteActions],
      complete: [...v1CompleteNonDiagnosisActions, ...v2CompleteActions],
    },
  };
}

/**
 * Given the response from many different GET requests, transform the result
 * into something that is easy for the UI to work with. This separates out
 * non-diagnosis (quality, medication, enrollment, etc.) actions and diagnosis
 * actions, as well as merging the v1 and v2 action types, and appending
 * completed actions (if any).
 *
 * This also passes along feature flags, and returns a map of HCC trumping.
 *
 * @template F
 * @param summaryData {unknown}
 * @param formStateData {Record<string, unknown>}
 * @param completedActions {{ v1: unknown[], v2: unknown[] }}
 * @param v2ActionsData {{ incomplete_actions: unknown[] }}
 * @returns {{
 *   defaultProviderChoiceId: number | string,
 *   actions: {
 *     nonDiagnosis: { incomplete: Action[], complete: {action: Action, formState: F}[] },
 *     recaptureDiagnosis: { incomplete: Action[], complete: {action: Action, formState: F}[] },
 *     suggestedDiagnosis: { incomplete: Action[], complete: {action: Action, formState: F}[] },
 *   },
 *   patient: P,
 *   featureFlags: Record<string, boolean>,
 *   formState: Record<string, Record<string, unknown>>
 * }}
 */
export default function transformResponses({
  summaryData,
  formStateData,
  completedActions = { v1: [], v2: [] },
  v2ActionsData,
}) {
  const patient = transformFrontendPatient(summaryData.patient);
  const formState = transformFormState(formStateData);

  const {
    recaptureDiagnosisActions,
    suggestedDiagnosisActions,
    nonDiagnosisActions,
  } = splitActions({
    v1IncompleteActions: summaryData.incomplete_actions.map(
      transformFrontendAction,
    ),
    v2IncompleteActions: v2ActionsData.incomplete_actions.map(
      transformPatientAction,
    ),
    v1CompleteActions: completedActions.v1.map(transformFrontendAction),
    v2CompleteActions: completedActions.v2.map(transformPatientAction),
  });

  return {
    patient,
    user: summaryData.user,
    defaultProviderChoiceId: summaryData.default_provider_choice_id,
    featureFlags: transformFeatureFlags(summaryData.feature_flags),

    // Used for submitting diagnosis actions: to submit via the v1 codepath,
    // the submission payload needs to include the whole incoming form state,
    // which could contain a whole lot of actions that aren't going to change.
    formState: prePopulateFormState(formState, summaryData.incomplete_actions),
    actions: {
      recaptureDiagnosis: recaptureDiagnosisActions,
      suggestedDiagnosis: suggestedDiagnosisActions,
      nonDiagnosis: nonDiagnosisActions,
    },
  };
}

export function countIncompleteActions(actions) {
  const scheduleVisitsSubtypes = [
    'annual_visit',
    'infant_well_child_visit_0_15',
    'infant_well_child_visit_15_30',
    'priority_patient_visit',
  ];
  const lengthRecapture = actions.recaptureDiagnosis.incomplete.length;
  const lengthSuggested = actions.suggestedDiagnosis.incomplete.length;

  const lengthNonDiagnosis = actions.nonDiagnosis.incomplete.filter(
    (action) => !scheduleVisitsSubtypes.includes(action.subtype),
  ).length;
  return lengthRecapture + lengthSuggested + lengthNonDiagnosis;
}
