import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import { isArray } from 'lodash';

import usePagination from '@ot/components/utils/usePagination';
import StyledPaginatedTable from '@ot/components/StyledPaginatedTable';

function DictErrorTable({ errorMessages }) {
  const { currentPage, paginationProps } = usePagination({
    rows: Object.entries(errorMessages),
    rowsPerPage: 50,
  });
  return (
    <StyledPaginatedTable
      paginationProps={paginationProps}
      sx={{ maxHeight: 400 }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Row</TableCell>
          <TableCell>Errors</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {currentPage.map(([rowNum, errors]) => {
          return (
            <TableRow key={rowNum}>
              <TableCell>{Number(rowNum) + 1}</TableCell>
              <TableCell>
                {isArray(errors) ? (
                  <Typography>{errors.join(', ')}</Typography>
                ) : (
                  Object.entries(errors).map(([column, error]) => {
                    return (
                      <Typography key={column}>
                        Entry {column} has the error: {error}
                      </Typography>
                    );
                  })
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledPaginatedTable>
  );
}
export default DictErrorTable;
DictErrorTable.propTypes = {
  errorMessages: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.any),
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ).isRequired,
};
