/**
 * Transforms object keys from snake case into camel case.
 */

export default function snakeToCamel(str: string): string {
  return str
    .toLowerCase()
    .replace(/([-_][a-z0-9])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    );
}
