import { useMemo } from 'react';

/**
 * Given two collections of superseding and superseded HCC values, generate a
 * map of HCC codes to the codes that supersede it and the codes it supersedes.
 * The intent is to simplify the UI that depends on this data when determining
 * HCC trumping.
 *
 * @deprecated Use in unit tests only please
 * @param hccIsSupersededBy {Record<string, number[]>?}
 * @param hccSupersedes {Record<string, number[]>?}
 * @returns {Record<string, { supersedes: number[], supersededBy: number[] }>}
 */
export function processHccTrumping(hccIsSupersededBy = [], hccSupersedes = []) {
  // Collect all HCC keys, because the two collections might not be identical.
  const allHccs = new Set([
    ...Object.keys(hccIsSupersededBy),
    ...Object.keys(hccSupersedes),
  ]);
  const output = Object.create(null);

  for (const hcc of allHccs) {
    output[hcc] = {
      supersedes: hccSupersedes[hcc] ?? [],
      supersededBy: hccIsSupersededBy[hcc] ?? [],
    };
  }

  return output;
}

export default function useHccTrumpingMap(patient) {
  return useMemo(() => {
    return processHccTrumping(patient.hccIsSupersededBy, patient.hccSupersedes);
  }, [patient]);
}
