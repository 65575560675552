import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import queryKeyFactory from '@ot/pages/SvuMenu/queryKeyFactory';

function transformResponse(data) {
  return {
    participants: data.data.participants
      .map((participant) => {
        return {
          planId: participant.plan_id,
          planName: participant.plan_name,
          svuMenuId: participant.svu_menu_id,
          svuMenuName: participant.svu_menu_name,
          isDefault: participant.is_default,
          isInert: participant.is_inert,
          medicalGroups: participant.medical_groups.map((medicalGroup) => {
            return {
              id: medicalGroup.id,
              tin: medicalGroup.tin,
              participantId: medicalGroup.participant_id,
              name: medicalGroup.name,
            };
          }),
          svuCohorts: participant.svu_cohorts.map((cohort) => {
            return {
              id: cohort.id,
              name: cohort.name,
              participantId: cohort.participant_id,
            };
          }),
        };
      })
      // Default menu will always be first
      .sort((menu1, menu2) => menu2.isDefault - menu1.isDefault),
  };
}

async function getParticipants(params) {
  const response = await axios.get('/ops_tools/api/svu_menu/participants', {
    params,
  });
  return response;
}

export default function useGetParticipants({
  svuMenuId = null,
  planId = null,
  groupBy,
}) {
  const params = { svuMenuId, planId, groupBy };
  return useQuery(
    queryKeyFactory.participants(params),
    () => getParticipants(params),
    { select: transformResponse },
  );
}
