import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@reach/disclosure';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUpIcon } from '@/assets/icons/chevronUp.svg';
import focusOutline from '@/mixins/focusOutline';

export default function Filter({ label, filterKey, children }) {
  const [isOpen, setIsOpen] = useState(
    () => window?.history?.state?.[filterKey] ?? true,
  );

  useEffect(() => {
    window.history.replaceState(
      { ...window.history.state, [filterKey]: isOpen },
      '',
    );
  }, [filterKey, isOpen]);

  return (
    <FilterRoot>
      <Disclosure open={isOpen} onChange={() => setIsOpen((x) => !x)}>
        <StyledDisclosureButton $open={isOpen}>
          {label}
          {isOpen ? (
            <StyledChevronUpIcon width="13" />
          ) : (
            <StyledChevronDownIcon width="13" />
          )}
        </StyledDisclosureButton>
        <StyledDisclosurePanel>{children}</StyledDisclosurePanel>
      </Disclosure>
    </FilterRoot>
  );
}

Filter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const FilterRoot = styled.div`
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
`;

const StyledDisclosureButton = styled(DisclosureButton)`
  background: transparent;
  border-radius: 0;
  border: none;
  text-align: left;
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  line-height: 22px;
  padding: 12px;
  padding-bottom: ${(props) => (props.$open ? '6px' : '12px')};
  font-weight: 500;
  cursor: pointer;

  ${focusOutline}
`;

const StyledChevronUpIcon = styled(ChevronUpIcon)`
  margin-left: auto;
`;
const StyledChevronDownIcon = styled(ChevronDownIcon)`
  margin-left: auto;
`;

const StyledDisclosurePanel = styled(DisclosurePanel)`
  padding: 0 12px 12px 12px;
`;
