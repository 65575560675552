import { Typography } from '@mui/material';
import { bool, func, shape, string } from 'prop-types';

import useFeatureFlag from '@/utils/useFeatureFlag';

import DiagnosisFormSection from './DiagnosisFormSection';
import { Checkbox } from './DiagnosisActionFormCheckbox';

const DEFAULT_LABELS = {
  header:
    'If patient does not have or is no longer being managed for this condition',
  assessedAndNotPresent: 'Assessed and not present',
  assessedAndPresent:
    'Mark condition as assessed & present without selecting diagnosis',
};

export default function DiagnosisIncorrectFormSection({
  completeWithoutCode,
  diagnosisIncorrect,
  notAssessed,
  labels: {
    header: headerLabel = DEFAULT_LABELS.header,
    assessedAndNotPresent:
      assessedAndNotPresentLabel = DEFAULT_LABELS.assessedAndNotPresent,
    assessedAndPresent:
      assessedAndPresentLabel = DEFAULT_LABELS.assessedAndPresent,
  } = {},
  onSetCompleteWithoutCode,
  onSetDiagnosisIncorrect,
  onSetNotAssessed,
  loading,
}) {
  const completeWithoutCodeEnabled = useFeatureFlag(
    'patientSummaryV2EnableCompleteWithoutCode',
  );
  const showNotAssessedOption = useFeatureFlag('psShowNotAssessedOption');

  return (
    <DiagnosisFormSection header={headerLabel}>
      <Checkbox
        checked={diagnosisIncorrect}
        onChange={() => onSetDiagnosisIncorrect(!diagnosisIncorrect)}
        label={
          <Typography variant="bodymedium">
            {assessedAndNotPresentLabel}
          </Typography>
        }
        disabled={loading}
      />
      {completeWithoutCodeEnabled ? (
        <Checkbox
          checked={completeWithoutCode}
          onChange={() => {
            onSetCompleteWithoutCode(!completeWithoutCode);
          }}
          label={
            <Typography variant="bodymedium">
              {assessedAndPresentLabel}
            </Typography>
          }
          disabled={loading}
        />
      ) : null}
      {showNotAssessedOption ? (
        <>
          <Checkbox
            checked={notAssessed}
            onChange={() => {
              onSetNotAssessed(!notAssessed);
            }}
            label={<Typography variant="bodymedium">Not Assessed</Typography>}
            disabled={loading}
          />

          {notAssessed && (
            <Typography variant="bodymedium" sx={{ fontStyle: 'italic' }}>
              This condition was marked{' '}
              <Typography
                variant="bodymediumbold"
                as="span"
                sx={{ fontWeight: 800 }}
              >
                Not assessed
              </Typography>{' '}
              and will remain here for assessment during a future visit.
            </Typography>
          )}
        </>
      ) : null}
    </DiagnosisFormSection>
  );
}
DiagnosisIncorrectFormSection.propTypes = {
  completeWithoutCode: bool.isRequired,
  diagnosisIncorrect: bool.isRequired,
  notAssessed: bool.isRequired,
  labels: shape({
    header: string,
    assessedAndNotPresent: string,
    assessedAndPresent: string,
  }),
  onSetCompleteWithoutCode: func.isRequired,
  onSetDiagnosisIncorrect: func.isRequired,
  onSetNotAssessed: func.isRequired,
  loading: bool,
};
DiagnosisIncorrectFormSection.defaultProps = {
  labels: DEFAULT_LABELS,
  loading: false,
};
