import { Box, List, ListItem, Typography, styled } from '@mui/material';

import BaseCheckbox from '@/components/BaseCheckbox';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import { ReactComponent as PrinterIcon } from '@/assets/icons/printer.svg';
import { ReactComponent as ChevronRight } from '@/assets/icons/chevronRight.svg';

const Page = styled(Box)`
  @media screen {
    width: 216mm;
    margin: 16px auto;
    padding: 16px 0;
    background-color: ${(p) => p.theme.palette.stellar.white};
    min-height: 279mm;
    box-shadow: 0 0 5px 1px ${(p) => p.theme.palette.stroke.light};
  }

  /* The styles are necessary to ensure that background colors/boxes are included by default when using the browser to print */
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }

  /* Set explicit default print size and margins */
  @media print {
    @page {
      size: letter portrait;
      margin: 0.5cm 0;
    }
  }
`;

const Underline = styled('span')`
  text-decoration: underline;
`;

const Text = styled(Typography)`
  font-size: 12px;
`;

const FullWidthBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
`;

const FullWidthStretchedBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 4px;
`;

const HeaderBox = styled(Box)`
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 4px;
`;

const StyledListItem = styled(ListItem)`
  padding: 0;
`;

const Logo = styled('img')`
  height: 10mm;
`;

const LogoCenter = styled(Logo)`
  margin: 0 auto;
`;

const Subhead = styled(Typography)`
  font-weight: 400;
  font-style: italic;
  font-size: 10px;
  color: ${(p) => p.theme.palette.font.lightest};
`;

const BannerBox = styled(Box)`
  width: 100%;
  display: flex;
  font-size: 12px;
  border: 1px solid black;
  border-radius: 8px;
`;

const BannerImg = styled('img')`
  width: 30px;
  height: 30px;
  margin: auto 0 auto 16px;
`;

const Headline = styled(Typography)`
  font-weight: 600;
  margin: 8px 0 0;
`;

const OptionsBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  break-inside: avoid;
`;

const SubStepList = styled('ul')`
  padding: 0 0 0 12px;
  margin: 4px 0 0 0;
`;

const SVUBox = styled(Box)`
  width: 15%;
  text-align: center;
  font-size: 11px;
  margin-right: 16px;
`;

const AddressBox = styled(Box)`
  display: flex;
  border: 1px gray solid;
  div {
    border-right: 1px gray solid;
  }
  div:last-child {
    border: 0;
  }
`;

const StyledTable = styled('table')`
  border-collapse: collapse;
  width: 100%;
  thead {
    vertical-align: top;
    font-size: 11px;
  }
  tr {
    border-bottom: 1px black solid;
    vertical-align: top;
  }
  tbody tr:nth-child(odd) {
    background-color: ${(p) => p.theme.palette.background.darker};
  }

  tbody td {
    padding: 0 8px 8px;
  }

  thead td {
    padding: 4px 8px;
  }
`;

const StyledNotesTable = styled(StyledTable)`
  tr {
    border-top: 1px black solid;
  }

  tbody td {
    padding: 8px;
  }
`;

const StyledCheckbox = styled(BaseCheckbox)`
  margin: 8px 16px 0 0;
  align-items: flex-start;

  .MuiCheckbox-root > svg > rect {
    stroke: black;
  }
`;

const VisitCheckbox = styled(StyledCheckbox)`
  font-size: 14px;
  align-items: center;
`;

const VisitList = styled(List)`
  padding: 0;

  hr:last-child {
    display: none;
  }
`;

const ButtonLabel = styled('span')`
  margin-left: 6px;
`;

const CancelIcon = styled(CloseIcon)`
  width: 18px;
  height: 18px;
`;

const PrintIcon = styled(PrinterIcon)`
  width: 18px;
  height: 18px;
  color: white;
`;

const RightIcon = styled(ChevronRight)`
  width: 12px;
  height: 12px;
  padding-right: 4px;
  color: ${(p) => p.theme.palette.icon.default};
`;

const StyledVisitsAndDxHistoryTable = styled('table')`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 40px;

  thead {
    font-size: 12px;
    color: #2b2b36;
  }

  thead tr td {
    vertical-align: middle;
  }
  thead tr:first-child {
    background-color: #71717d;
    color: #ffffff;
  }
  thead tr.chronic-conditions {
    background-color: #2b2b36;
    color: #ffffff;
  }
  thead tr:last-child {
    background-color: #f2f2f2ff;
  }

  tr {
    border-bottom: 1px solid #e3e3ee;
    vertical-align: top;
  }

  tbody td {
    padding: 8px;
    font-size: 14px;
  }

  thead td {
    padding: 4px 8px;
  }
`;

const VisitsAndDxHistoryTableColumnLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
`;

const VisitsAndDxHistoryTableHeader = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
`;

const VisitsAndDxHistoryTableSubHeader = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  font-style: italic;
`;

export {
  Page,
  Underline,
  Text,
  FullWidthBox,
  FullWidthStretchedBox,
  Logo,
  LogoCenter,
  Subhead,
  Headline,
  OptionsBox,
  AddressBox,
  BannerBox,
  BannerImg,
  SVUBox,
  StyledTable,
  StyledVisitsAndDxHistoryTable,
  StyledNotesTable,
  StyledCheckbox,
  StyledListItem,
  SubStepList,
  HeaderBox,
  VisitCheckbox,
  VisitList,
  VisitsAndDxHistoryTableColumnLabel,
  VisitsAndDxHistoryTableHeader,
  VisitsAndDxHistoryTableSubHeader,
  ButtonLabel,
  CancelIcon,
  PrintIcon,
  RightIcon,
};
