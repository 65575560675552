import { Component } from 'react';
import { func, node } from 'prop-types';

import { ErrorBoundaryNavigationContext } from './ErrorBoundaryNavigationProvider';

// eslint-disable-next-line no-unused-vars
function defaultRenderError({ error, reset, reload, goBack }) {
  return null; // do nothing, just quietly fail.
}

/**
 Implements a React error boundary that can be reused in different contexts.
 The boundary exposes tools for handling caught errors, both for error
 reporting purposes, and to potentially allow a user to self-serve an
 intervention. They have the choice of "resetting" the view, which re-mounts
 the subtree, reloading the whole page, or going back (i.e. hitting the
 browser's back button).

 Props:
 children - A normal React subtree

 onError - Invoked when this component catches an error in any of its child
 components. The intention is that this callback can trigger any side
 effects (e.g. reporting the error to Datadog), but should probably not
 affect the React UI state directly.

 renderError - Invoked when this component catches an error and is rendering
 the fallback view. This render delegate function may call out to regular
 React components to do its work. It is passed a single object that
 contains several useful callbacks and the error that triggered the
 boundary:

 - error - the Error instance that triggered this error boundary
 - onReset - when invoked it will remount the failing subtree, perhaps
 clearing the error and allowing the user to proceed.
 - onReload - when invoked it will trigger a hard browser refresh
 - onGoBack - when invoked it will trigger the browser's back button
 functionality, using History's popState if appropriate.

 The renderError delegate may return undefined or null to indicate that
 no fallback subtree should be rendered.

 The callbacks passed to renderError are available as methods on this
 class instance, and can be invoked with a ref to the ErrorBoundary.
 */
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };

    this.reset = () => {
      this.setState({ error: null });
    };

    this.reload = () => {
      // eslint-disable-next-line react/destructuring-assignment
      this.context.reload();
    };

    this.goBack = () => {
      // eslint-disable-next-line react/destructuring-assignment
      this.context.back();
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    const { onError } = this.props;

    if (onError) onError({ error, errorInfo });
  }

  render() {
    const { error } = this.state;
    const { children, renderError } = this.props;

    if (error) {
      const rendered = renderError({
        error,
        reset: this.reset,
        reload: this.reload,
        goBack: this.goBack,
      });
      if (rendered === undefined) return null;
      return rendered;
    }

    return children;
  }
}

ErrorBoundary.contextType = ErrorBoundaryNavigationContext;
ErrorBoundary.propTypes = {
  children: node,
  onError: func,
  renderError: func,
};
ErrorBoundary.defaultProps = {
  children: null,
  onError: undefined,
  renderError: defaultRenderError,
};
