/* eslint-disable react/no-array-index-key */
import { Box, styled, useTheme } from '@mui/material';
import { get, sum } from 'lodash';
import PropTypes from 'prop-types';

export default function CircularProgressBar({
  children,
  size,
  progressArray,
  trackWidth,
  trackColor,
  indicatorColorArray,
}) {
  const theme = useTheme();
  const center = size / 2;
  const radius = center - trackWidth / 2;
  const dashArray = 2 * Math.PI * radius;
  // a progressArray of [25, 50]
  // becomes a dashOffsetArray of [.75dA, .25dA]
  // so that bars "stack" properly
  // also: reverse the array so the longest bar is drawn first
  // and the rest go on top in proper z order
  const dashOffsetArray = progressArray
    .map(
      (progress, i) =>
        dashArray * ((100 - sum(progressArray.slice(0, i + 1))) / 100),
    )
    .reverse();

  // also reverse the colors for the bars
  const reversedIndicators = [...indicatorColorArray].reverse();

  const element = (
    <svg
      style={{
        width: size,
        height: size,
        transform: 'rotate(-90deg)',
        display: 'block',
      }}
    >
      <circle
        cx={center}
        cy={center}
        fill="transparent"
        r={radius}
        stroke={get(theme.palette, trackColor)}
        strokeWidth={trackWidth}
      />
      {dashOffsetArray.map((dashOffset, i) => (
        <circle
          key={`${dashOffset}-${i}`}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={get(
            theme.palette,
            reversedIndicators[i],
            reversedIndicators[i],
          )}
          strokeWidth={trackWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap="round"
        />
      ))}
    </svg>
  );

  return children ? (
    <CircularProgressBarRoot>
      {element}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {children}
      </Box>
    </CircularProgressBarRoot>
  ) : (
    element
  );
}
CircularProgressBar.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
  progressArray: PropTypes.arrayOf(PropTypes.number),
  trackWidth: PropTypes.number,
  trackColor: PropTypes.string,
  indicatorColorArray: PropTypes.arrayOf(PropTypes.string),
};
CircularProgressBar.defaultProps = {
  children: undefined,
  size: 150,
  progressArray: [0],
  trackWidth: 7,
  trackColor: `status.noneBackground`,
  indicatorColorArray: [`status.successForeground`],
};

const CircularProgressBarRoot = styled('div')`
  position: relative;
`;
