import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

function BaseTable({ title, children, mgHeader, userHeader }) {
  return (
    <Box>
      <Typography variant="headersmall">{title}</Typography>
      <TableContainer>
        <Table
          padding="none"
          sx={{
            '& .MuiTableBody-root .MuiTableRow-root': {
              backgroundColor: 'background.tableZebra',
            },
            '& .MuiTableBody-root .MuiTableRow-root:nth-child(2n)': {
              backgroundColor: 'background.base',
            },
            '& .MuiTableCell-root': {
              borderBottom: 0,
            },
            '& .MuiTableCell-root:first-child': {
              pl: 1,
            },
            '& .MuiTableCell-root:last-child': {
              pr: 1,
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right" sx={{ width: 260 }}>
                <Typography
                  variant="bodymediumbold"
                  color="status.infoForeground"
                >
                  {mgHeader}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ width: 260 }}>
                <Typography variant="bodymediumbold" color="svu.dark">
                  {userHeader}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

BaseTable.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  mgHeader: PropTypes.string.isRequired,
  userHeader: PropTypes.string.isRequired,
};

export default BaseTable;
