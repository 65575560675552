import PropTypes from 'prop-types';

import ErrorMessage from '@/pages/PaymentInstructions/components/ErrorMessage';
import BaseForm from '@/components/BaseForm';

export default function SimpleFormWithError({
  children,
  onSubmit,
  isError,
  error,
}) {
  return (
    <>
      <BaseForm onSubmit={onSubmit} sx={{ width: '100%' }}>
        {children}
      </BaseForm>
      {isError ? (
        <ErrorMessage
          error={error.response.data.error}
          errorType={error.response.data.type}
        />
      ) : null}
    </>
  );
}

SimpleFormWithError.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  error: PropTypes.object,
};

SimpleFormWithError.defaultProps = { isError: false, error: undefined };
