import * as dateFns from 'date-fns';

import register from '@/components/DynamicForm/register';
import { DISPLAY_DATE_FORMAT } from '@/pages/PatientSummary/utils/constants';

export const POSITION_FORM_COMPONENT = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
};

export const actionMinDate = (_, { action }) => {
  return action.minDate ? dateFns.parseISO(action.minDate) : undefined;
};

export const actionExpiresAt = (_, { action }) => {
  return action.expiresAt ? dateFns.parseISO(action.expiresAt) : undefined;
};

export const mostRecentClaimDate = (_, { action }) => {
  return action.mostRecentClaimDate
    ? dateFns.parseISO(action.mostRecentClaimDate)
    : undefined;
};

export const today = () => dateFns.startOfDay(new Date());

export const dateOfService = (_, { dateOfService: dos }) => dos;

export const patientDateOfBirth = (_, { patient }) => {
  const date = dateFns.parse(
    patient.dateOfBirth,
    DISPLAY_DATE_FORMAT,
    new Date(),
  );
  return dateFns.isValid(date) ? date : new Date('1900-01-01T08:00');
};

export const beginningOfServiceYear = (...args) =>
  dateFns.startOfYear(dateOfService(...args));

export const endOfServiceYear = (...args) =>
  dateFns.endOfYear(dateOfService(...args));

export const subDays =
  (fn, days) =>
  (...args) =>
    dateFns.subDays(fn(...args), days);

export const addDays =
  (fn, days) =>
  (...args) =>
    dateFns.addDays(fn(...args), days);

export const subYears =
  (fn, years) =>
  (...args) =>
    dateFns.subYears(fn(...args), years);

export const addYears =
  (fn, years) =>
  (...args) =>
    dateFns.addYears(fn(...args), years);

// Given list of functions of type (_, additionalProps) => Date, return the
// date that is the earliest of all of those. Non-date values are silently
// filtered out.
export const earliestOf =
  (...fns) =>
  (...args) => {
    const dates = fns
      .map((fn) => fn(...args))
      .filter((d) => dateFns.isValid(d));
    if (dates.length) return new Date(Math.min(...dates));
    return undefined;
  };

// Given list of functions of type (_, additionalProps) => Date, return the
// date that is the latest of all of those. Non-date values are silently
// filtered out.
export const latestOf =
  (...fns) =>
  (...args) => {
    const dates = fns
      .map((fn) => fn(...args))
      .filter((d) => dateFns.isValid(d));
    if (dates.length) return new Date(Math.max(...dates));
    return undefined;
  };

export const ELSE = Symbol('ELSE');

// Given a property in the form state, observe that property and return a value
// generated from the property map. If no matching key is found for the value,
// this function will return undefined, unless an [ELSE] clause is specified.
export const match = (property, propertyMap) => {
  return register([property], (formState, additionalProps) => {
    const exec = (val) => {
      if (typeof val === 'function') {
        return val(formState, additionalProps);
      }
      return val;
    };

    const formValue = formState[property];

    if (formValue in propertyMap) {
      return exec(propertyMap[formValue]);
    }
    if (ELSE in propertyMap) {
      return exec(propertyMap[ELSE]);
    }
    return undefined;
  });
};

// Using a getter function, observe a property in the DynamicForms
// additionalProps object, matching to options within propertyMap. If no
// matching key is found for the value, this function will return undefined,
// unless an [ELSE] clause is specified.
export const matchAdditionalProp = (additionalPropGetter, propertyMap) => {
  return (formState, additionalProps) => {
    const exec = (val) => {
      if (typeof val === 'function') {
        return val(formState, additionalProps);
      }
      return val;
    };

    const additionalPropValue = additionalPropGetter(additionalProps);

    if (additionalPropValue in propertyMap) {
      return exec(propertyMap[additionalPropValue]);
    }
    if (ELSE in propertyMap) {
      return exec(propertyMap[ELSE]);
    }
    return undefined;
  };
};

// Return the value from the formState corresponding to the named property.
export const references = (field) => register([field], (state) => state[field]);

// Reference a property on the passed action, falling back to the fallback
// property if the result is null or undefined.
export const referenceActionField =
  (field, fallback) =>
  (_, { action }) =>
    action[field] ?? fallback;

// Given a template literal, tag the template literal as needing to have any
// leading whitespace removed. It is a fairly naive algorithm, just removing
// the amount of whitespace found before the first line with text in it.
export const trimmed = ([text]) => {
  const lines = text.split('\n').filter(Boolean);
  const [, leadingWs] = lines[0].match(/^(\s+)/);
  return lines.map((line) => line.slice(leadingWs.length)).join('\n');
};

export const ifAnyFieldEquals = (fieldsToValues) => {
  const fields = fieldsToValues.map((tuple) => tuple[0]);
  return register(fields, (state) =>
    fieldsToValues.some((tuple) => state[tuple[0]] === tuple[1]),
  );
};

export const ifNotAnyFieldEquals = (fieldsToValues) => {
  const fields = fieldsToValues.map((tuple) => tuple[0]);
  return register(
    fields,
    (state) => !fieldsToValues.some((tuple) => state[tuple[0]] === tuple[1]),
  );
};

export const ifAllFieldsEqual = (fieldsToValues) => {
  const fields = fieldsToValues.map((tuple) => tuple[0]);
  return register(fields, (state) =>
    fieldsToValues.every((tuple) => state[tuple[0]] === tuple[1]),
  );
};

export const ifNotAllFieldsEqual = (fieldsToValues) => {
  const fields = fieldsToValues.map((tuple) => tuple[0]);
  return register(
    fields,
    (state) => !fieldsToValues.every((tuple) => state[tuple[0]] === tuple[1]),
  );
};
