import { format, parse } from 'date-fns';
import produce from 'immer';
import { replace } from 'lodash';

import uploadFile from './uploadFile';

/**
 * Returns a date in the format YYYY-MM-DD.
 * @param {Date} date
 */
function formatDate(date) {
  return format(date, 'yyyy-MM-dd');
}

function getFileName({
  extension,
  uploadDate,
  medicalGroupName,
  patientMemberNumber,
  actionName,
}) {
  const name = [
    uploadDate,
    replace(medicalGroupName, /\s/g, '_'),
    patientMemberNumber,
    replace(actionName, /\s/g, '_'),
  ].join('-');

  return `${name}.${extension}`;
}

/**
 * Prepare the execution input to be submitted by converting some fields to the expected format.
 */
const prepareData = produce((draft) => {
  Object.entries(draft).forEach(([key, value]) => {
    // Convert date objects to strings.
    if (value instanceof Date) {
      draft[key] = formatDate(value);
    }

    // Convert File objects to strings.
    if (value instanceof File) {
      draft[key] = value.name;
    }
  });
});

/**
 * Fills in the Chart Upload fields that aren't visible to the user.
 */
const addChartUploadFields = produce(
  (draft, { originalFileName, fileName, uploadDate, parentDateOfService }) => {
    draft.date_of_service_of_chart_upload = uploadDate;
    draft.chart_provider_filenamename = originalFileName;
    draft.chartupload_system_filename = fileName;
    draft.parent_date_of_service = parentDateOfService
      ? formatDate(parse(parentDateOfService, 'MM-dd-yyyy', new Date()))
      : undefined;
  },
);

export default async function buildExecutionInput({
  action,
  data,
  currentDate,
  patient,
  csrfToken,
}) {
  switch (action.type) {
    case 'chart_upload': {
      const uploadDate = format(currentDate, 'yyyy-MM-dd');
      const extension = data.file.name.split('.').pop();
      const fileName = getFileName({
        extension,
        uploadDate,
        medicalGroupName: patient.medicalGroup.name,
        patientMemberNumber: patient.memberNumber,
        actionName: action.v2Dto.contextualInfo.action_name,
      });

      // Upload the file before executing the step.
      await uploadFile({
        actionId: action.id,
        patientId: patient.id,
        file: data.file,
        fileName,
        csrfToken,
      });

      return prepareData(
        addChartUploadFields(data, {
          fileName,
          originalFileName: data.file.name,
          uploadDate,
          parentDateOfService: action.v2Dto.contextualInfo.parentDateOfService,
        }),
      );
    }
    default:
      if (action.subtype === 'adult_bmi') {
        const latestExternalCareGap =
          action.v2Dto?.contextualInfo?.latestExternalCareGap;

        // For Adult BMI in MTL, use Date of Service from External Care Gap if available, otherwise use today's date.
        const dateOfService = latestExternalCareGap?.dateOfService
          ? latestExternalCareGap?.dateOfService
          : new Date();

        return prepareData({ ...data, date_of_service: dateOfService });
      }

      return prepareData(data);
  }
}
