import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { formatISODateIgnoreTimeZone } from '@/utils/formatDate';

import useGetPrescriptionHistory from '../../../hooks/useGetPrescriptionHistory';

function PrescriptionHistoryLoadingRows() {
  return (
    <>
      <TableRow>
        <StyledTableCell colSpan={5}>
          <Skeleton height={28} />
        </StyledTableCell>
      </TableRow>

      <TableRow>
        <StyledTableCell colSpan={5}>
          <Skeleton height={28} />
        </StyledTableCell>
      </TableRow>
    </>
  );
}

export default function PrescriptionHistory({ id, actionId, patientId }) {
  const {
    data: prescriptionHistoryData,
    isLoading: prescriptionHistoryIsLoading,
  } = useGetPrescriptionHistory({
    actionId,
    patientId,
  });

  return (
    <Table id={id}>
      <StyledTableHead>
        <TableRow>
          <StyledTableCell> Date </StyledTableCell>
          <StyledTableCell> Prescriber </StyledTableCell>
          <StyledTableCell> Pharmacy </StyledTableCell>
          <StyledTableCell> Medication </StyledTableCell>
          <StyledTableCell> Days Supply </StyledTableCell>
        </TableRow>
      </StyledTableHead>

      <TableBody>
        {prescriptionHistoryIsLoading ? (
          <PrescriptionHistoryLoadingRows />
        ) : (
          prescriptionHistoryData?.map((history) => {
            return (
              <TableRow key={`${history.fromDate}${history.drugName}`}>
                <StyledTableCell>
                  {formatISODateIgnoreTimeZone(history.fromDate)}
                </StyledTableCell>
                <StyledTableCell>{history.prescriber}</StyledTableCell>
                <StyledTableCell>{history.pharmacy}</StyledTableCell>
                <StyledTableCell>{history.drugName}</StyledTableCell>
                <StyledTableCell>{history.daysSupply}</StyledTableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
}

PrescriptionHistory.propTypes = {
  id: PropTypes.string,
  patientId: PropTypes.number.isRequired,
  actionId: PropTypes.number.isRequired,
};

PrescriptionHistory.defaultProps = {
  id: undefined,
};

const StyledTableHead = styled(TableHead)``;

const StyledTableCell = styled(TableCell)`
  background-color: ${(p) => p.theme.palette.background.base};
  color: ${(p) => p.theme.palette.text.primary};
  font-size: ${(p) => p.theme.typography.bodysmall.fontSize};
  padding: 4px 0;

  ${StyledTableHead} & {
    box-shadow: none;
    font-weight: ${(p) => p.theme.typography.bodybold.fontWeight};
  }
`;
