import { Typography } from '@mui/material';

import { flagHistoryShape } from '@/utils/transformFlagHistory';
import { formatISODateIgnoreTimeZone } from '@/utils/formatDate';
import { flagReasonsShape } from '@/utils/getFlagReasons';

export default function IsFlaggedAlert({ flagHistory, flagReasons }) {
  const flagReason = flagReasons.find(
    (reason) => reason[0] === flagHistory.flagStatus,
  );
  const flagReasonLabel = flagReason ? flagReason[1] : 'Unknown';

  return (
    <>
      <Typography pt={2.5} variant="label">
        Reason
      </Typography>
      <Typography pt={1.5}>{flagReasonLabel}</Typography>

      {flagHistory.note ? (
        <Typography pt={2} variant="bodysmall">
          {flagHistory.note.text}
        </Typography>
      ) : null}

      <Typography pt={0.5} pb={1.5} variant="bodysmall" color="text.secondary">
        {formatISODateIgnoreTimeZone(flagHistory.createdAt)} by{' '}
        {flagHistory.userFullName}
      </Typography>
    </>
  );
}

IsFlaggedAlert.propTypes = {
  flagHistory: flagHistoryShape.isRequired,
  flagReasons: flagReasonsShape.isRequired,
};
