import {
  accordionDetailsClasses,
  accordionSummaryClasses,
  dialogClasses,
  formControlLabelClasses,
  inputClasses,
} from '@mui/material';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/chevronDown.svg';
import { ReactComponent as RadioDefaultIcon } from '@/assets/icons/radioDefault.svg';
import { ReactComponent as RadioCheckedIcon } from '@/assets/icons/radioChecked.svg';

import palette from './palette';
import typography, { LINE_HEIGHT } from './typography';

import '@mui/lab/themeAugmentation';

/**
 * These selectors use MUI's global state classes to select a given state.
 *
 * Docs: https://mui.com/customization/how-to-customize/#heading-state-classes
 */
const stateClasses = {
  active: 'Mui-active',
  checked: 'Mui-checked',
  completed: 'Mui-completed',
  disabled: 'Mui-disabled',
  error: 'Mui-error',
  expanded: 'Mui-expanded',
  focusVisible: 'Mui-focusVisible', // https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
  focused: 'Mui-focused',
  required: 'Mui-required',
  selected: 'Mui-selected',
};

const stateSelectors = {
  active: '&.Mui-active',
  checked: '&.Mui-checked',
  completed: '&.Mui-completed',
  disabled: '&.Mui-disabled',
  error: '&.Mui-error',
  expanded: '&.Mui-expanded',
  focusVisible: '&.Mui-focusVisible',
  focused: '&.Mui-focused',
  required: '&.Mui-required',
  selected: '&.Mui-selected',
};

const focusOutline = {
  [`:focus, :focus-within, ${stateSelectors.focused}, ${stateSelectors.focusVisible}`]:
    {
      boxShadow: `0px 0px 0px 3.2px ${palette.focus.border}`,
      outline: 'none !important',
    },
};
const lightFocusOutline = {
  [`:focus, :focus-within, ${stateSelectors.focused}, ${stateSelectors.focusVisible}`]:
    {
      boxShadow: `0px 0px 0px 3.2px ${palette.focus.shadow}`,
      outline: 'none !important',
    },
};
/**
 * Component Customization
 *
 * @type {import('@mui/material').Components}
 *
 * Documentation: https://mui.com/customization/theme-components/
 */
const components = {
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
      elevation: 0,
      TransitionProps: { unmountOnExit: true }, // Improve performance by rendering content only when expanded.
    },
    variants: [
      {
        props: { variant: 'disclosure' },
        style: {
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },

          [`.${accordionSummaryClasses.root}`]: {
            display: 'inline-flex',
            minHeight: 'auto',
            padding: 0,

            [stateSelectors.focusVisible]: {
              backgroundColor: 'transparent',
              borderRadius: '2px',
              ...focusOutline,
            },
          },
          [`.${accordionSummaryClasses.content}`]: {
            margin: 0,
            flexGrow: 0,
          },
          [`.${accordionDetailsClasses.root}`]: {
            padding: 0,
          },
          [`.${accordionSummaryClasses.expandIconWrapper}`]: {
            marginLeft: '8px',
          },
        },
      },
    ],
  },

  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: (
        <ChevronDownIcon
          width={24}
          height={24}
          color={palette.icon.secondary}
        />
      ),
    },
  },

  MuiAppBar: {
    defaultProps: {
      elevation: 3,
    },
  },

  MuiAutocomplete: {
    styleOverrides: {
      root: {
        'label + &': {
          marginTop: '4px',
        },

        // Override the Input padding styles added by MuiAutocomplete
        [`&& .${inputClasses.root} .${inputClasses.input}`]: {
          padding: 0,
        },
        // Override the Input padding styles added by MuiAutocomplete
        [`&& .${inputClasses.root}`]: {
          paddingBottom: '3px',
        },
      },
      option: {
        [`${stateSelectors.focused}`]: {
          backgroundColor: `${palette.background.primaryLightest} !important`,
        },
        [`&[aria-selected="true"]`]: {
          backgroundColor: `${palette.background.primaryLighter} !important`,
        },
      },
      endAdornment: {
        right: '8px',
      },
    },
    variants: [
      {
        props: { size: 'large' },
        style: {
          [`&& .${inputClasses.root}`]: {
            paddingBottom: '7px',
          },
        },
      },
    ],
  },

  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
      variant: 'contained',
    },

    styleOverrides: {
      root: {
        minWidth: '44px',
        transition: 'none',
        boxShadow: 'none',
        fontSize: '1rem',
        lineHeight: '22px',
        fontWeight: '400',
        textTransform: 'none',

        ':hover': {
          boxShadow: 'none',
        },
        [stateSelectors.disabled]: {
          color: palette.disabled.disabled,
          borderColor: palette.disabled.disabled,
        },

        ...focusOutline,
      },
      contained: {
        color: 'white',
        padding: '4px 12px',
      },
      containedSizeSmall: {
        fontSize: '0.875rem',
        lineHeight: '19px',
        padding: '4px 8px',
      },
      containedSizeLarge: {
        fontSize: '1.125rem',
        lineHeight: '25px',
        padding: '8px 16px',
      },
      outlined: {
        padding: '3px 11px',
        ':hover': {
          backgroundColor: palette.background.primary,
          color: palette.text.primaryInverse,
        },
      },
      outlinedSizeSmall: {
        fontSize: '0.875rem',
        lineHeight: '19px',
        padding: '3px 7px',
      },
      outlinedSizeLarge: {
        fontSize: '1.125rem',
        lineHeight: '25px',
        padding: '7px 15px',
      },
      text: {
        padding: '4px 12px',
      },
      textSizeSmall: {
        fontSize: '0.875rem',
        lineHeight: '19px',
        padding: '4px 8px',
      },
      textSizeLarge: {
        fontSize: '1.125rem',
        lineHeight: '25px',
        padding: '8px 16px',
      },
    },
  },

  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        '& [role="presentation"]': {
          overflow: 'visible', // Prevent clipping of the dropdown arrow that opens up the Year Picker.
        },
      },
    },
  },

  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
      },
    },
  },

  MuiChip: {
    variants: [
      {
        props: { variant: 'call-to-action' },
        style: {
          backgroundColor: palette.status.warningBackground,
          borderRadius: 8,
          color: palette.status.warningForeground,
          fontSize: '0.875rem',
          textTransform: 'uppercase',

          '& .MuiChip-label': {
            padding: '4px 8px',
          },
        },
      },
    ],
  },

  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: '0',
        borderRadius: '2px',
      },
    },
  },

  MuiScopedCssBaseline: {
    styleOverrides: {
      root: {
        lineHeight: LINE_HEIGHT,
      },
    },
  },

  MuiCssBaseline: {
    styleOverrides: {
      body: {
        lineHeight: LINE_HEIGHT,
      },
      label: {
        margin: 0, // override spacing added by bootstrap within app.scss
      },
      ul: {
        paddingInlineStart: '20px',
      },
      p: {
        margin: 0,
      },
      button: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        margin: 0,
        padding: 0,
        borderRadius: '2px',
        ...focusOutline,
      },

      // Fixes a bug where tooltips inside modals are being rendered behind the modal.
      [`.${dialogClasses.root} + [role="tooltip"]`]: {
        zIndex: 1301, // modals have a z-index of 1300
      },
    },
  },

  MuiDrawer: {
    defaultProps: { elevation: 3 },
  },

  MuiSnackbar: {
    defaultProps: { elevation: 3 },
  },

  MuiSnackbarContent: {
    defaultProps: { elevation: 3 },
  },

  MuiCircularProgress: {
    defaultProps: {
      disableShrink: true,
    },
  },

  MuiDialog: {
    defaultProps: { scroll: 'body', PaperProps: { elevation: 2 } },
    styleOverrides: {
      root: {
        // We have to specify the line height here, because modals get rendered in a portal
        // and won't get the correct line-height if ScopedCssBaseline is being used.
        lineHeight: LINE_HEIGHT,
      },
      paper: {
        margin: '128px 32px',
        maxWidth: '680px',
        borderRadius: '16px',
      },
    },
  },

  MuiDialogActions: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.secondary,
        boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)',
        justifyContent: 'space-between',
        padding: '16px 24px',
      },
    },
  },

  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '24px',
      },
    },
  },

  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: '24px 24px 16px',
      },
    },
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: palette.text.secondary,
        fontSize: '0.875rem',
        lineHeight: 1.125,
        margin: '4px 0 0',
        [stateSelectors.disabled]: {
          color: palette.text.secondary,
        },
      },
    },
  },

  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: '0',
        marginRight: '0',
        // Bold the checkbox/radio label when the input is checked.
        [`.${stateClasses.checked} + .${formControlLabelClasses.label}`]: {
          ...typography.bodybold,
        },
      },
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      root: {
        ...typography.label,
        display: 'block',

        [`${stateSelectors.disabled}, ${stateSelectors.error}, ${stateSelectors.focused}`]:
          {
            color: palette.font.lightest,
          },
      },
    },
  },

  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: 0,
        borderRadius: '1px',
      },
    },
  },

  MuiInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        backgroundColor: palette.background.base,
        borderRadius: '4px',
        lineHeight: '22px',
        padding: '3px 11px',
        [stateSelectors.disabled]: {
          backgroundColor: palette.disabled.disabled,
        },
        [stateSelectors.error]: {
          borderColor: palette.status.errorForeground,
        },
        border: `1px solid ${palette.border.input}`,
        ...lightFocusOutline,
        'label + &': {
          marginTop: '4px',
        },
      },
      input: {
        height: 'auto',
        padding: '0',
        '&::placeholder': {
          color: palette.text.placeholder,
          opacity: 1,
        },
      },
      sizeSmall: {
        fontSize: '0.875rem',
        lineHeight: '19px',
        padding: '3px 7px',
      },
    },
    // MUI doesn't support size "large" by default, so we have to add a new variant.
    variants: [
      {
        props: { size: 'large' },
        style: {
          fontSize: '1.125rem',
          lineHeight: '25px',
          padding: '7px 15px',
        },
      },
    ],
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        ...focusOutline,
        borderRadius: '4px',
      },
    },
  },

  MuiLink: {
    styleOverrides: {
      root: {
        color: palette.text.link,
        textDecorationColor: 'inherit',
        textDecorationThickness: '1px',
        textUnderlineOffset: '1px',
        ':hover': {
          // overrides orange color from app.scss
          color: palette.text.link,
        },
      },
    },
    variants: [
      // Styles for our <BaseLinkButton> component
      {
        props: { component: 'button' },
        style: { borderRadius: '2px', ...focusOutline },
      },
    ],
  },

  MuiListItemButton: {
    defaultProps: {
      disableRipple: true,
    },
  },

  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&&': {
          [`:hover, ${stateSelectors.focused}, ${stateSelectors.focusVisible}`]:
            {
              backgroundColor: `${palette.background.primaryLightest}`,
            },
          [`${stateSelectors.selected}:hover, ${stateSelectors.selected}`]: {
            backgroundColor: `${palette.background.primaryLighter}`,
            color: palette.text.link,
          },
        },
      },
    },
  },

  MuiPaper: {
    defaultProps: {
      elevation: 2, // "regular" shadow
    },
    styleOverrides: {
      root: {
        borderRadius: '8px',
      },
    },
  },

  MuiPickersDay: {
    styleOverrides: {
      root: {
        boxShadow: 'none !important', // Disable our custom focusOutline because DatePicker has a built-in focus.
      },
    },
  },

  MuiPopover: {
    defaultProps: { elevation: 3 },
  },

  MuiRadio: {
    defaultProps: {
      disableRipple: true,
      icon: <RadioDefaultIcon />,
      checkedIcon: <RadioCheckedIcon />,
    },
    styleOverrides: {
      root: {
        marginRight: '8px',
        padding: 0,
      },
    },
  },

  MuiSelect: {
    styleOverrides: {
      select: {
        // The default padding has ridiculously high specificity, so use this '&&&' trick to increase the specificity.
        '&&&': {
          paddingRight: '30px',
        },
      },
    },
  },

  MuiStep: {
    styleOverrides: {
      root: {
        padding: 0,

        '&:not(:first-of-type)': {
          marginLeft: '16px',
        },
      },
    },
  },

  MuiStepConnector: {
    styleOverrides: {
      root: {
        display: 'none',
      },
    },
  },

  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: palette.background.primaryLightest,

        '& .MuiStepIcon-text': {
          fill: palette.background.primary,
        },

        [stateSelectors.active]: {
          color: palette.background.primary,
          '& .MuiStepIcon-text': {
            fill: palette.background.base,
          },
        },
      },
      text: {
        fontWeight: 700,
        fontSize: '1rem',
        transform: 'translateY(1px)', // re-position because of the modified fontSize
      },
    },
  },

  MuiStepLabel: {
    styleOverrides: {
      root: {
        borderRadius: '16px',
        paddingRight: '16px',

        [stateSelectors.disabled]: {
          backgroundColor: 'transparent',
        },
      },
      label: {
        [stateSelectors.active]: {
          ...typography.bodybold,
          lineHeight: '24px',
        },
      },
    },
  },

  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: palette.text.primary,
          backgroundColor: palette.background.primaryLightest,
          fontWeight: 900,
        },
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
        lineHeight: 1.5,
        padding: '12px',
        borderBottom: 'none',
      },
      head: {
        fontSize: '0.875rem',
        color: palette.text.label,
        boxShadow: `inset 0px -1px 0px ${palette.border.base}`,
      },
    },
  },

  MuiTableRow: {
    styleOverrides: {
      root: {
        'tbody &:nth-child(2n)': {
          backgroundColor: palette.background.tableZebra,
        },
        ':not(:last-of-type)': {
          boxShadow: `inset 0px -1px 0px ${palette.border.base}`,
        },
      },
    },
  },

  MuiTypography: {
    defaultProps: {
      variant: 'body',
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        body: 'p',
        bodysmall: 'p',
        bodymedium: 'p',
        bodymediumbold: 'p',
        bodylarge: 'p',

        bodybold: 'p',
        inherit: 'p',
        subtext: 'p',
        label: 'p',
      },
    },
  },
};

export default components;
