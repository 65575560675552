import { string } from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';

import { getIconComponent } from '@/utils/pastDiagnosisUtils';
import { diagnosisShape } from '@/utils/transformFrontendAction';

function DiagnosisSubtextIcon({ sourceType }) {
  const Icon = getIconComponent(sourceType);

  // This prevents the SVG from shrinking to accommodate other elements
  return (
    <Box
      component="span"
      sx={{
        flex: '0 0 16px',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          color: 'icon.secondary',
        },
      }}
    >
      <Icon height={16} width={16} alt-text={sourceType.toLowerCase()} />
    </Box>
  );
}
DiagnosisSubtextIcon.propTypes = {
  sourceType: string.isRequired,
};

export default function DiagnosisSubtext({
  diagnosis,
  locationString,
  dateString,
  variant,
}) {
  if (!locationString && !dateString) {
    return null;
  }
  return (
    <Stack direction="row" gap={1} component="span" sx={{ pl: 1 }}>
      <DiagnosisSubtextIcon sourceType={diagnosis.sourceType} />
      <Stack direction="row" component="span">
        <Typography
          component="span"
          variant={variant}
          sx={{
            flex: '1 1',
            minWidth: 0,
            color: 'text.secondary',
            overflow: 'hidden',
            whiteSpace: 'pre',
            textOverflow: 'ellipsis',
          }}
        >
          {locationString}
        </Typography>
        <Typography component="span" variant={variant}>
          {dateString}
        </Typography>
      </Stack>
    </Stack>
  );
}
DiagnosisSubtext.propTypes = {
  diagnosis: diagnosisShape.isRequired,
  locationString: string,
  dateString: string,
  variant: string,
};
DiagnosisSubtext.defaultProps = {
  locationString: null,
  dateString: null,
  variant: 'bodysmall',
};
