import { CircularProgress } from '@mui/material';

/**
 * BaseSpinner is an alias for MUI's CircularProgress component.
 *
 * Documentation: https://mui.com/api/circular-progress/
 */
const BaseSpinner = CircularProgress;

export default BaseSpinner;
