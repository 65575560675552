import PropTypes, { shape } from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import useId from '@mui/utils/useId';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

/**
 * A wrapper for MUI's `<Dialog>` component that handles the title, action buttons, and close icon.
 *
 * We call this component "modal" instead of "dialog" to have the same terminology as the design library.
 */
export default function BaseModal({
  'data-dd-synthetics-id': dataDdSyntheticsId,
  className,
  dataPendoId,
  children,
  onClose,
  onSubmit,
  open,
  submitButtonDisabled,
  sx,
  title,
  disableRestoreFocus,
  scroll,
  dialogTitleProps,
}) {
  const showButtons = Boolean(onSubmit);

  const titleId = useId();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        'data-dd-synthetics-id': dataDdSyntheticsId,
        'data-pendo-id': dataPendoId,
        className,
        elevation: 2,
        sx,
      }}
      disableRestoreFocus={disableRestoreFocus}
      scroll={scroll}
    >
      <Box display="flex" alignItems="start">
        <DialogTitle
          id={titleId}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dialogTitleProps}
          sx={{ flex: '1 0 auto', ...dialogTitleProps.sx }}
        >
          {title}
        </DialogTitle>

        {!showButtons && (
          <StyledButton aria-label="Close modal" onClick={onClose}>
            <CloseIcon />
          </StyledButton>
        )}
      </Box>
      <DialogContent>
        {typeof children === 'string' ? (
          <Typography>{children}</Typography>
        ) : (
          children
        )}
      </DialogContent>

      {showButtons && (
        <DialogActions>
          <Button
            onClick={onClose}
            variant="text"
            data-pendo-id={`${dataPendoId}-cancel-button`}
          >
            Cancel
          </Button>
          {onSubmit && (
            <Button
              disabled={submitButtonDisabled}
              onClick={onSubmit}
              data-pendo-id={`${dataPendoId}-confirm-button`}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

const DialogTitlePropTypes = shape({
  variant: PropTypes.string,
  sx: PropTypes.object,
});

BaseModal.propTypes = {
  'data-dd-synthetics-id': PropTypes.string,
  className: PropTypes.string,
  dataPendoId: PropTypes.string,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submitButtonDisabled: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disableRestoreFocus: PropTypes.bool,
  scroll: PropTypes.oneOf(['body', 'paper']),
  dialogTitleProps: DialogTitlePropTypes,
};

BaseModal.defaultProps = {
  'data-dd-synthetics-id': undefined,
  className: undefined,
  dataPendoId: undefined,
  onSubmit: undefined,
  submitButtonDisabled: false,
  sx: undefined,
  disableRestoreFocus: false,
  scroll: 'body',
  dialogTitleProps: {},
};

const StyledButton = styled.button`
  flex: 0 0 auto;
  color: ${(p) => p.theme.palette.icon.primary};
  margin-right: ${(p) => p.theme.spacing(3)};
  margin-top: ${(p) => p.theme.spacing(3)};

  :hover {
    color: ${(p) => p.theme.palette.icon.link};
  }
`;
