import { useState } from 'react';
import PropTypes, { element, string } from 'prop-types';
import { Box, IconButton, styled } from '@mui/material';

import BaseModal from '@/components/BaseModal';
import BaseLinkButton from '@/components/BaseLinkButton';

export default function LearnMoreWidget({
  helpText,
  title,
  buttonSize,
  label,
  icon,
}) {
  const [showModal, setShowModal] = useState(false);

  if (!helpText) {
    return null;
  }

  return (
    <>
      {icon ? (
        <StyledInfoIconWrapperButton
          onClick={(event) => {
            event.stopPropagation();
            setShowModal(true);
          }}
        >
          {icon}
        </StyledInfoIconWrapperButton>
      ) : (
        <BaseLinkButton
          sx={{
            color: 'text.secondary',
            fontSize: buttonSize,
            underline: false,
          }}
          onClick={(event) => {
            event.stopPropagation();
            setShowModal(true);
          }}
          data-pendo-id="mtl-learn-more-button"
        >
          {label}
        </BaseLinkButton>
      )}
      <BaseModal
        title={title}
        open={showModal}
        onClose={(event) => {
          event.stopPropagation();
          setShowModal(false);
        }}
        sx={{ minWidth: '480px' }}
      >
        <Box
          dangerouslySetInnerHTML={{
            __html: helpText,
          }}
        />
      </BaseModal>
    </>
  );
}

LearnMoreWidget.propTypes = {
  title: string,
  helpText: string,
  buttonSize: PropTypes.number,
  label: string,
  icon: element,
};

LearnMoreWidget.defaultProps = {
  title: undefined,
  helpText: undefined,
  buttonSize: 12,
  label: 'Learn more',
  icon: null,
};

const StyledInfoIconWrapperButton = styled(IconButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: ${(p) => p.theme.spacing(1)};
  text-align: left;
  border-radius: 0;
  &:focus {
    outline: 2px solid ${(p) => p.theme.palette.primary.main};
    outline-offset: 2px;
  }
  &:active {
    border: 2px solid ${(p) => p.theme.palette.primary.main};
  }
`;
