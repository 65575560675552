import { useMemo } from 'react';
import { bool, func, number } from 'prop-types';
import { FormLabel, Stack } from '@mui/material';
import useId from '@mui/utils/useId';

import BaseSelect from '@/components/BaseSelect';
import useGetUserFilters from '@/hooks/useGetUserFilters';
import transformOption from '@/utils/transformOption';

export default function DiagnosisProviderSelect({
  selectedProviderId,
  onChangeSelectedProviderId,
  disabled,
}) {
  const selectId = useId();

  const { data, isLoading } = useGetUserFilters();
  const { providers = [] } = data || {};

  const options = useMemo(() => providers.map(transformOption), [providers]);

  return (
    <Stack mb={1}>
      <FormLabel htmlFor={selectId}>Servicing provider</FormLabel>
      <BaseSelect
        id={selectId}
        label="Servicing provider"
        options={options}
        value={selectedProviderId}
        onChange={(e) => onChangeSelectedProviderId(Number(e.target.value))}
        disabled={disabled || isLoading}
      />
    </Stack>
  );
}

DiagnosisProviderSelect.propTypes = {
  selectedProviderId: number,
  onChangeSelectedProviderId: func.isRequired,
  disabled: bool,
};
DiagnosisProviderSelect.defaultProps = {
  selectedProviderId: undefined,
  disabled: false,
};
