import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

async function updateHideForNow({ taskId, hideUntil, note }) {
  return axios.put(`/api/tbw/tasks/${taskId}/update_hide_for_now/`, {
    task_id: taskId,
    note_text: note,
    hide_until: hideUntil,
  });
}

export default function useUpdateHideForNow({ onSuccess = undefined } = {}) {
  return useMutation(updateHideForNow, {
    onSuccess,
  });
}
