import axios from 'axios';
import DataLoader from 'dataloader';
import { useQuery } from '@tanstack/react-query';

import { transformContextualInfo } from '@/utils/transformFrontendAction';
import queryKeyFactory from '@/pages/TaskBasedWorkflow/queryKeyFactory';

async function batchGetContextualInfo(actionIds) {
  const params = { action_ids: actionIds };
  const response = await axios.get('/api/tbw/task_actions_context', {
    params,
  });

  return response.data;
}

const contextualInfoLoader = new DataLoader(
  (actionIds) => batchGetContextualInfo(actionIds),
  {
    cache: false, // Allow react-query to handle the caching
  },
);

export default function useGetContextualInfo(
  action,
  { onSuccess = undefined } = {},
) {
  const enabled = !action?.v2Dto?.contextualInfo;
  const params = { actionId: action.id };

  return useQuery({
    queryKey: queryKeyFactory.taskActionsContext(params),
    queryFn: () => contextualInfoLoader.load(action.id),
    enabled,
    initialData: enabled ? undefined : action.v2Dto.contextualInfo,
    select: transformContextualInfo,
    onSuccess,
  });
}
