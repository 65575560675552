import { Typography } from '@mui/material';
import propTypes from 'prop-types';

export default function CompletedStatusCell({ status, subtext }) {
  return (
    <>
      <Typography
        sx={{
          color: 'status.successForeground',
          '::first-letter': {
            textTransform: 'capitalize',
          },
        }}
      >
        {status}
      </Typography>
      <Typography variant="bodysmall">{subtext}</Typography>
    </>
  );
}

CompletedStatusCell.propTypes = {
  status: propTypes.string.isRequired,
  subtext: propTypes.element.isRequired,
};
