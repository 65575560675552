import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '@/assets/icons/minus.svg';
import BaseCheckbox from '@/components/BaseCheckbox';
import { useQueryParameters } from '@/contexts/QueryParametersContext';

function NestedCheckbox({
  className,
  filterKey,
  id,
  name,
  options,
  pendoIdPrefix,
}) {
  const { parameters, mergeParameters } = useQueryParameters();
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    if (e.target.checked) {
      mergeParameters({
        [filterKey]: (parameters[filterKey] || []).concat(id),
        page: undefined,
      });
    } else {
      mergeParameters({
        [filterKey]: parameters[filterKey].filter((x) => x !== id),
        page: undefined,
      });
    }
  };

  const checked = useMemo(
    () => Boolean(parameters[filterKey]?.some((x) => x === id)),
    [parameters, filterKey, id],
  );

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const hasOptions = options && options.length > 0;
  const shouldShowNestedCheckboxes = hasOptions && isOpen;

  if (hasOptions) {
    return (
      <>
        <StyledButton
          variant="outlined"
          startIcon={
            shouldShowNestedCheckboxes ? (
              <StyledMinusIcon />
            ) : (
              <StyledPlusIcon />
            )
          }
          onClick={handleClick}
        >
          {name}
        </StyledButton>
        {shouldShowNestedCheckboxes && (
          <FormGroup>
            {options.map((subOption) => (
              <InnerNestedCheckbox
                key={subOption.id}
                filterKey={filterKey}
                id={`${id},${subOption.id}`}
                name={subOption.name}
                pendoIdPrefix={pendoIdPrefix}
              />
            ))}
          </FormGroup>
        )}
      </>
    );
  }

  return (
    <BaseCheckbox
      checked={checked}
      className={className}
      label={name}
      onChange={handleChange}
      key={id}
      sx={{ color: 'text.primary' }}
      data-pendo-id={`${pendoIdPrefix}${id}`}
    />
  );
}

NestedCheckbox.propTypes = {
  className: PropTypes.string,
  filterKey: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  pendoIdPrefix: PropTypes.string,
};

NestedCheckbox.defaultProps = {
  className: undefined,
  options: undefined,
  pendoIdPrefix: '',
};

const InnerNestedCheckbox = styled(NestedCheckbox)`
  margin-left: 18px;
`;

const StyledMinusIcon = styled(MinusIcon)`
  width: 13px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  width: 13px;
`;

const StyledButton = styled(Button)`
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: 0px;
  color: ${(p) => p.theme.palette.font.default};
  &:hover {
    border: 0px;
  }
`;

export default NestedCheckbox;
