import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@mui/material';

function SidebarItem({ title, value, width }) {
  return (
    <StyledGrid item xs={width}>
      <div>
        <StyledLabel>{title}</StyledLabel>:
      </div>
      <div>{value}</div>
    </StyledGrid>
  );
}

SidebarItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number,
};

SidebarItem.defaultProps = {
  width: 6,
  value: '',
};

const StyledGrid = styled(Grid)({
  padding: 10,
});

const StyledLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #73767b;
`;

export default SidebarItem;
