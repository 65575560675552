import { Button } from '@mui/material';
import styled from 'styled-components';
import { bool, element, func, string } from 'prop-types';

export default function PatientDetailsButton({
  href,
  target,
  icon,
  label,
  onClick,
  ddSyntheticsId,
  pendoId,
  visuallyHidden,
  size,
  disabled,
}) {
  return (
    <Button
      href={href}
      onClick={onClick}
      tabIndex={visuallyHidden ? -1 : undefined}
      variant="text"
      size={size}
      data-dd-synthetics-id={ddSyntheticsId}
      data-pendo-id={pendoId}
      target={target}
      rel={
        target === '_blank' || target === 'blank'
          ? 'noopener noreferrer'
          : undefined
      }
      disabled={disabled}
    >
      {icon}
      <ButtonLabel>{label}</ButtonLabel>
    </Button>
  );
}
PatientDetailsButton.propTypes = {
  href: string,
  target: string,
  icon: element,
  size: string,
  label: string.isRequired,
  onClick: func,
  ddSyntheticsId: string,
  pendoId: string.isRequired,
  visuallyHidden: bool.isRequired,
  disabled: bool,
};

PatientDetailsButton.defaultProps = {
  href: undefined,
  onClick: undefined,
  target: undefined,
  size: 'large',
  icon: null,
  ddSyntheticsId: undefined,
  disabled: false,
};

const ButtonLabel = styled.span`
  margin-left: 6px;
`;
