import { Box, Typography } from '@mui/material';
import { format as formatDate, isToday, isYesterday, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

const DATE_FORMAT = 'MM-dd-yyyy';
const TIME_FORMAT = 'h:mm aaa';

function getDateString(dateString) {
  const parsedDate = parseISO(dateString);
  const formattedTime = formatDate(parsedDate, TIME_FORMAT);

  if (isToday(parsedDate)) {
    return `Today at ${formattedTime}`;
  }
  if (isYesterday(parsedDate)) {
    return `Yesterday at ${formattedTime}`;
  }

  const formattedDate = formatDate(parsedDate, DATE_FORMAT);
  return `${formattedDate} at ${formattedTime}`;
}

/**
 * Component to display a normalized step history or flag history item.
 */
export default function HistoryItem({ date, description, note, userFullName }) {
  return (
    <Box sx={{ my: 1 }}>
      <Typography>
        <Box component="span">{description}</Box>
        {description ? <span> – </span> : null}
        {note ? (
          <span>Note: {note}</span>
        ) : (
          <Box component="span" sx={{ color: 'text.placeholder' }}>
            no notes
          </Box>
        )}
      </Typography>

      <Typography
        sx={{
          color: 'text.secondary',
          fontSize: 'bodysmall.fontSize',
        }}
      >
        {userFullName
          ? `${userFullName}, ${getDateString(date)}`
          : getDateString(date)}
      </Typography>
    </Box>
  );
}
HistoryItem.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string,
  note: PropTypes.string,
  userFullName: PropTypes.string,
};
HistoryItem.defaultProps = {
  description: undefined,
  note: undefined,
  userFullName: undefined,
};
