import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

import BaseFormModal from '@/components/BaseFormModal';
import BaseLinkButton from '@/components/BaseLinkButton';

import useLogOutreach from '../../hooks/useLogOutreach';
import useGetLogOutreach from '../../hooks/useGetLogOutreach';
import HistoryItem from '../HistoryItem';

import PatientBannerButton from './PatientBannerButton';

const formFields = [
  {
    name: 'outcome',
    label: 'Outcome',
    type: 'radiogroup',
    required: true,
    choices: [
      ['talked_to_patient_or_caregiver', 'Talked to patient or caregiver'],
      ['left_message', 'Left message (voicemail, text, email)'],
      ['patient_asked_to_call_back', 'Patient asked to call back'],
      ['patient_declined_to_talk', 'Patient declined to talk'],
      ['phone_number_not_in_service', 'Phone number not in service'],
      ['unable_to_leave_message', 'Unable to leave message'],
    ],
    pendoIdPrefixer: (id) => `worklist-patient-detail-outreach-log-modal-${id}`,
  },
  {
    name: 'note',
    label: 'Note (optional)',
    type: 'textarea',
    required: false,
    minRows: 3,
    placeholder: 'Enter note',
    sx: {
      resize: 'vertical',
      padding: '4px 8px',
    },
    'data-pendo-id': 'worklist-patient-detail-outreach-log-modal-note',
  },
];

export default function OutreachLogModal({
  patientId,
  patientName,
  patientPhone,
}) {
  const [showModal, setShowModal] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const { isLoading, mutate, reset } = useLogOutreach();
  const { isLoading: isHistoryLoading, data: dataHistory } = useGetLogOutreach({
    patientId,
  });

  const handleBannerButtonClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    reset();
    setShowModal(false);
  };

  const handleSubmit = (data) => {
    mutate({ patientId, ...data }, { onSuccess: handleClose });
  };

  const handleHistoryButtonClick = () => {
    setShowHistory((previousValue) => !previousValue);
  };

  return (
    <>
      <PatientBannerButton
        onClick={handleBannerButtonClick}
        pendoId="worklist-patient-detail-outreach-log"
      >
        Outreach log
      </PatientBannerButton>

      <BaseFormModal
        alert={
          <>
            <Typography
              variant="h4"
              sx={{ mt: 2 }}
              data-dd-action-name="Patient Name and Phone"
            >
              {patientName} {patientPhone}
            </Typography>

            <Box pt={1}>
              {isHistoryLoading || dataHistory?.length === 0 ? null : (
                <>
                  <BaseLinkButton onClick={handleHistoryButtonClick}>
                    {showHistory
                      ? 'Hide outreach history'
                      : 'View outreach history'}
                  </BaseLinkButton>
                  {showHistory ? (
                    <>
                      <Typography variant="bodysmall" fontWeight={900}>
                        Recent:
                      </Typography>
                      {dataHistory?.map(
                        ({ id, note, createdAt, outcome, user }) => (
                          <HistoryItem
                            key={id}
                            note={note}
                            date={createdAt}
                            description={outcome}
                            userFullName={user}
                          />
                        ),
                      )}
                      <hr />
                    </>
                  ) : null}
                </>
              )}
            </Box>
          </>
        }
        fields={formFields}
        isLoading={isLoading}
        onCancel={handleClose}
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitButtonPendoId="worklist-patient-detail-outreach-log-modal-save"
        open={showModal}
        submitButtonLabel="Confirm"
        title="Outreach log"
        headerVariant="h2"
        modalSx={{ width: '408px' }}
      />
    </>
  );
}

OutreachLogModal.propTypes = {
  patientId: PropTypes.number.isRequired,
  patientName: PropTypes.string,
  patientPhone: PropTypes.string,
};

OutreachLogModal.defaultProps = {
  patientName: null,
  patientPhone: null,
};
