import { useEffect } from 'react';

type Props = {
  title: string;
};

export default function TheDocumentTitle({ title }: Props) {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return null;
}
