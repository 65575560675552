import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

const NAVBAR_SELECTOR = 'nav.navbar';

// Undo the styles applied by MuiCssBaseline so that the navbar looks consistent between legacy and React pages.
const NavbarStyleOverrides = createGlobalStyle`
  ${NAVBAR_SELECTOR} {
    line-height: 1.25;
    -webkit-font-smoothing: auto;
  }
`;

/**
 * A component to manipulate the legacy navbar.
 */
export default function TheLegacyNavbarAdapter({ removeShadow, removeSticky }) {
  useEffect(() => {
    const navbar = document.querySelector(NAVBAR_SELECTOR);
    if (navbar) {
      if (removeShadow) {
        navbar.style.boxShadow = 'none';
      }
      if (removeSticky) {
        navbar.classList.remove('sticky-top');
      }
    }
  }, [removeShadow, removeSticky]);
  return <NavbarStyleOverrides />;
}

TheLegacyNavbarAdapter.propTypes = {
  removeShadow: PropTypes.bool,
  removeSticky: PropTypes.bool,
};

TheLegacyNavbarAdapter.defaultProps = {
  removeShadow: false,
  removeSticky: false,
};
