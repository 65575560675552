import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

async function dismissExternalUpdates({ actionId, externalCareGapId }) {
  return axios.post('/api/tbw/external_update/dismiss', {
    action_id: actionId,
    external_care_gap_id: externalCareGapId,
  });
}

export default function useDismissExternalUpdates({
  onSuccess = undefined,
} = {}) {
  return useMutation(dismissExternalUpdates, {
    onSuccess,
  });
}
