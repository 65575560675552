import { func, string } from 'prop-types';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import useAbound from '@/pages/PaymentInstructions/hooks/useAbound';
import queryKeyFactory from '@/pages/PaymentInstructions/queryKeyFactory';
import ErrorMessage from '@/pages/PaymentInstructions/components/ErrorMessage';

export default function AboundDropIn({ aboundAccessToken, onAdvance }) {
  const [errorMessage, setErrorMessage] = useState(false);

  const queryClient = useQueryClient();

  const onSubmit = () => {
    setErrorMessage(false);
  };

  const onSubmitSuccess = (w9) => {
    if (w9.payee.tinVerificationStatus === 'MATCH') {
      onAdvance();
    } else if (w9.payee.tinVerificationStatus === 'MISMATCH') {
      setErrorMessage(
        'Tin verification mismatch. Please check your info and try again.',
      );
    }
  };

  // When there is a tin mismatch or a system error, both results in the same error message of
  // 'invalid employer identification number' so we are unable to tell which error it is
  const onSubmitError = () => {
    setErrorMessage('Submission failed.');
    queryClient.invalidateQueries(queryKeyFactory.aboundToken());
  };

  useAbound({
    targetId: 'abound-ui-wrapper',
    accessToken: aboundAccessToken,
    onSubmit,
    onSubmitSuccess,
    onSubmitError,
  });

  return (
    <>
      <div id="abound-ui-wrapper" />
      {errorMessage ? <ErrorMessage error={errorMessage} /> : null}
    </>
  );
}

AboundDropIn.propTypes = {
  onAdvance: func.isRequired,
  aboundAccessToken: string.isRequired,
};
