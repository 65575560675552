import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function ModalTextDisplay({ label, variant, InputSx, className }) {
  return (
    <Typography variant={variant} sx={InputSx} className={className}>
      {label}
    </Typography>
  );
}

export default ModalTextDisplay;

ModalTextDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  InputSx: PropTypes.object,
  className: PropTypes.string,
};

ModalTextDisplay.defaultProps = {
  variant: undefined,
  InputSx: { p: 1 },
  className: 'ModalTextDisplay',
};
