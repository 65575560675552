import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import {
  bool,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  string,
} from 'prop-types';
import { isEmpty } from 'lodash';

import { reactQueryStatusValues } from '@/utils/shapes';
import BaseForm from '@/components/BaseForm';

import useConditionsForDisplay from '../../hooks/useConditionsForDisplay';
import useDiagnosisActionStateManager from '../../hooks/useDiagnosisActionStateManager';
import DiagnosisProviderSelect from '../DiagnosisProviderSelect';
import DiagnosisActionFormControls from '../DiagnosisActionFormControls';
import DiagnosisIncorrectFormSection from '../DiagnosisIncorrectFormSection';
import DiagnosisFormCheckboxGroup from '../DiagnosisFormCheckboxGroup';

function CollapsedSuggestedDiagnosisActionForm({ onExpandForm }) {
  return (
    <Stack direction="row" flexWrap="wrap">
      <Button variant="text" onClick={onExpandForm}>
        Specify diagnosis
      </Button>
    </Stack>
  );
}
CollapsedSuggestedDiagnosisActionForm.propTypes = {
  onExpandForm: func.isRequired,
};

function ExpandedSuggestedDiagnosisActionForm({
  defaultProviderChoiceId,
  diagnosisAction,
  onCancel,
  onSaveDiagnosisAction,
  saveStatus,
  isSubmitDisabled,
}) {
  const [selectedProviderId, setSelectedProviderId] = useState(
    defaultProviderChoiceId,
  );

  const {
    state,
    conditionNotPresent,
    completeWithoutCode,
    conditionNotAssessed,
    selectDiagnosis,
    unselectDiagnosis,
  } = useDiagnosisActionStateManager();
  const { commonCodes } = useConditionsForDisplay(diagnosisAction);

  const handleChange = (diagnosis) => {
    if (diagnosis.icd in state.selectedDiagnoses) unselectDiagnosis(diagnosis);
    else selectDiagnosis(diagnosis);
  };

  const handleSubmit = () => {
    onSaveDiagnosisAction({
      selectedProviderId,
      diagnosisAction,
      state,
    });
  };

  return (
    <BaseForm onSubmit={handleSubmit}>
      <Stack px={1} gap={1}>
        {!isEmpty(commonCodes) ? (
          <DiagnosisFormCheckboxGroup
            conditions={commonCodes}
            header="If condition is present, specify diagnosis"
            onSelectCondition={handleChange}
            selectedConditions={state.selectedDiagnoses}
          />
        ) : null}
        <DiagnosisIncorrectFormSection
          completeWithoutCode={state.completeWithoutCode}
          diagnosisIncorrect={state.diagnosisIncorrect}
          notAssessed={state.notAssessed}
          labels={{ assessedAndPresent: 'Assessed and present without code' }}
          onSetCompleteWithoutCode={completeWithoutCode}
          onSetDiagnosisIncorrect={conditionNotPresent}
          onSetNotAssessed={conditionNotAssessed}
        />
        <DiagnosisProviderSelect
          selectedProviderId={selectedProviderId}
          onChangeSelectedProviderId={setSelectedProviderId}
        />
        <DiagnosisActionFormControls
          onCancel={onCancel}
          loading={saveStatus === 'loading'}
          disabled={!selectedProviderId || isSubmitDisabled}
        />
      </Stack>
    </BaseForm>
  );
}
ExpandedSuggestedDiagnosisActionForm.propTypes = {
  defaultProviderChoiceId: number,
  diagnosisAction: object.isRequired,
  onCancel: func.isRequired,
  onSaveDiagnosisAction: func.isRequired,
  saveStatus: reactQueryStatusValues.isRequired,
  isSubmitDisabled: bool.isRequired,
};
ExpandedSuggestedDiagnosisActionForm.defaultProps = {
  defaultProviderChoiceId: undefined,
};

export default function SuggestedDiagnosisActionForm({
  defaultProviderChoiceId,
  diagnosisAction,
  onSaveDiagnosisAction,
  conditionNotAssessed,
  open,
  setOpen,
  saveStatus,
  isSubmitDisabled,
}) {
  if (!onSaveDiagnosisAction) return null;

  if (!open) {
    return (
      <CollapsedSuggestedDiagnosisActionForm
        onExpandForm={() => setOpen(true)}
      />
    );
  }

  return (
    <ExpandedSuggestedDiagnosisActionForm
      defaultProviderChoiceId={defaultProviderChoiceId}
      diagnosisAction={diagnosisAction}
      onCancel={() => setOpen(false)}
      onSaveDiagnosisAction={onSaveDiagnosisAction}
      conditionNotAssessed={conditionNotAssessed}
      saveStatus={saveStatus}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
}

SuggestedDiagnosisActionForm.propTypes = {
  defaultProviderChoiceId: number,
  diagnosisAction: object.isRequired,
  onSaveDiagnosisAction: func,
  conditionNotAssessed: func,
  open: oneOfType([bool, string]).isRequired,
  saveStatus: oneOf(['idle', 'loading', 'error', 'success']).isRequired,
  setOpen: func.isRequired,
  isSubmitDisabled: bool.isRequired,
};
SuggestedDiagnosisActionForm.defaultProps = {
  defaultProviderChoiceId: undefined,
  onSaveDiagnosisAction: undefined,
  conditionNotAssessed: undefined,
};
