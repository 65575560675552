import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export default function InputContainer({ children, sx }) {
  return <Box sx={{ maxWidth: '480px', width: '100%', ...sx }}>{children}</Box>;
}

InputContainer.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

InputContainer.defaultProps = { sx: undefined };
