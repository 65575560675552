import { useState } from 'react';
import { bool, func, object } from 'prop-types';
import { Box, Typography } from '@mui/material';

import { patientShape } from '@/utils/shapes';

import { Checkbox } from '../DiagnosisActionFormCheckbox';
import useHccTrumpingMap from '../../hooks/useHccTrumpingMap';

import DiagnosisIcdSearch from './DiagnosisIcdSearch';
import { CommonCodeSeverityMessage } from './DiagnosisSeverityMessage';

function convertSearchResultToCondition(result) {
  if (result === null) return null;

  return {
    icd: result.value,
    description: result.label,
    hcc: result.hcc,
  };
}

export default function DiagnosisCommonCodeIcdSearch({
  diagnosisAction,
  onSelectCondition,
  patient,
  selectedConditions,
  disabled,
}) {
  const hccTrumping = useHccTrumpingMap(patient);
  const [userSelectedCondition, setUserSelectedCondition] = useState(null);

  const maybeUnselectCurrentCondition = () => {
    if (
      userSelectedCondition &&
      userSelectedCondition.value in selectedConditions
    ) {
      onSelectCondition(convertSearchResultToCondition(userSelectedCondition));
    }
  };
  const handleChecked = (e, checked) => {
    if (!checked) {
      maybeUnselectCurrentCondition();
    } else if (userSelectedCondition) {
      onSelectCondition(convertSearchResultToCondition(userSelectedCondition));
    }
  };

  const handleSelectSearchResult = (result) => {
    maybeUnselectCurrentCondition();
    setUserSelectedCondition(result);

    if (result) {
      onSelectCondition(convertSearchResultToCondition(result));
    }
  };

  const userChoseSelectedCondition = Boolean(
    userSelectedCondition && userSelectedCondition.value in selectedConditions,
  );

  return (
    <>
      <Checkbox
        disabled={!userSelectedCondition || disabled}
        checked={userChoseSelectedCondition}
        label={
          <Typography variant="bodymedium">Select a different code</Typography>
        }
        onChange={handleChecked}
      />
      <Box my={0.5} ml={3}>
        <DiagnosisIcdSearch
          hcc={diagnosisAction.hcc.code}
          onSelectIcd={handleSelectSearchResult}
          patient={patient}
          selectedIcd={userSelectedCondition}
          actionId={diagnosisAction.id}
        />
        {userSelectedCondition ? (
          <CommonCodeSeverityMessage
            condition={userSelectedCondition}
            diagnosisAction={diagnosisAction}
            hccTrumping={hccTrumping}
          />
        ) : null}
      </Box>
    </>
  );
}
DiagnosisCommonCodeIcdSearch.propTypes = {
  diagnosisAction: object.isRequired,
  onSelectCondition: func.isRequired,
  patient: patientShape.isRequired,
  selectedConditions: object.isRequired,
  disabled: bool,
};

DiagnosisCommonCodeIcdSearch.defaultProps = {
  disabled: false,
};
