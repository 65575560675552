import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { actionShape } from '@/utils/transformFrontendAction';
import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';
import HideForNowIcon from '@/pages/TaskBasedWorkflow/components/HideForNowIcon';
import LearnMoreWidget from '@/pages/TaskBasedWorkflow/components/LearnMoreWidget';
import HelpTextInfoIcon from '@/pages/TaskBasedWorkflow/components/HelpTextInfoIcon';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';

export default function ActionDescription({ action, task, rowIndex }) {
  const { data: contextualInfo } = useGetContextualInfo(action);

  const showHiddenIcon = task.hiddenForNow;
  const helpTextInfoIcon = <HelpTextInfoIcon />;
  return (
    <div>
      <Typography
        variant="bodymediumbold"
        data-dd-synthetics-id={
          rowIndex === 0 ? 'first-action-description' : undefined
        }
      >
        {action.v2Dto.description}
      </Typography>
      {contextualInfo && (
        <LearnMoreWidget
          helpText={contextualInfo.listViewHelpText}
          title={contextualInfo.category}
          label=""
          icon={helpTextInfoIcon}
        />
      )}
      {showHiddenIcon ? <HideForNowIcon /> : null}
    </div>
  );
}

ActionDescription.propTypes = {
  task: userInputTaskShape.isRequired,
  action: actionShape.isRequired,
  rowIndex: PropTypes.number.isRequired,
};
