import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import queryKeyFactory from '../queryKeyFactory';

function transformDiagnosis(diagnosis) {
  return {
    id: diagnosis.id,
    code: diagnosis.code,
    description: diagnosis.description,
  };
}

function transformAdtEventHistory(history) {
  return {
    id: history.id,
    eventDate: history.event_date,
    eventType: history.event_type,
    facilityName: history.facility_name,
    facilityType: history.facility_type,
    diagnoses: history.diagnoses.map(transformDiagnosis),
    lengthOfStay: history.length_of_stay,
  };
}

function transformResponse(data) {
  return data.history.map(transformAdtEventHistory);
}

async function fetchAdtEventHistory({ queryKey }) {
  const [, { actionId }] = queryKey;
  const response = await axios.get(`/api/tbw/adt_event_history/${actionId}`);

  return response.data;
}

export default function useGetAdtEventHistory({ actionId }) {
  const params = { actionId };
  return useQuery({
    queryKey: queryKeyFactory.adtEventHistory(params),
    queryFn: fetchAdtEventHistory,
    select: transformResponse,
  });
}
