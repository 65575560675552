import styled from 'styled-components';
import { FormControl, RadioGroup, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import BaseRadioButton from '@/components/BaseRadioButton';
import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';

import DynamicFormLabel from '../DynamicFormLabel';

const YES_VALUE = 'yes';
const NO_VALUE = 'no';

const OPTIONS = [
  { value: YES_VALUE, label: 'Yes' },
  { value: NO_VALUE, label: 'No' },
];

const SELECT_ALL_KEY = 'select_all';
const SELECT_ALL_ROW = [SELECT_ALL_KEY, 'Select all'];

/**
 * Renders multiple radio groups that each contain a "Yes" and "No" option.
 * The radio group labels are provided in the `rows` array.
 */
export default function DynamicFormYesNoRadioGroups({
  field: { label, name, choices, pendoIdPrefixer },
}) {
  const { control, setValue, formState } = useFormContext();
  const modifiedRows =
    choices.length > 3 ? [...choices, SELECT_ALL_ROW] : choices;
  const rowKeys = modifiedRows.map(([key]) => key);

  const handleRadioGroupChange = (event, item, field, key) => {
    field.onChange(item);

    if (formState.isSubmitting || formState.isSubmitted) {
      return;
    }

    // If the user selects "Yes" or "No" in the "Select all" row,
    // then we want to do the same for all other rows.
    if (key === SELECT_ALL_KEY) {
      rowKeys.forEach((rowKey) => {
        setValue(rowKey, item);
      });
    } else if (rowKeys.includes(SELECT_ALL_KEY)) {
      // A row other than the "Select all" row was selected, so clear the
      // "Select all" row.
      setValue(SELECT_ALL_KEY, '');
    }
  };

  return (
    <>
      <StyledDynamicFormLabel>{label}</StyledDynamicFormLabel>

      {modifiedRows.map((row, rowCount) => {
        const [key, rowLabel] = row;
        const isSelectAllRow = key === SELECT_ALL_KEY;

        return (
          <OuterFormRow
            key={key}
            $rowCount={rowCount}
            $isSelectAllRow={isSelectAllRow}
          >
            <Controller
              name={key}
              control={control}
              defaultValue={NO_VALUE}
              render={({ field }) => (
                <FormControl>
                  <InnerFormRow>
                    <Typography
                      fontWeight={isSelectAllRow ? 'bold' : undefined}
                    >
                      {rowLabel}
                    </Typography>
                    <RadioGroup
                      name={name}
                      onChange={(event, item) =>
                        handleRadioGroupChange(event, item, field, key)
                      }
                      value={field.value || ''}
                      row
                      sx={{
                        flexShrink: 0,
                        alignItems: 'center',
                      }}
                    >
                      {OPTIONS.map((option) => (
                        <StyledRadioButton
                          key={option.value}
                          data-pendo-id={
                            pendoIdPrefixer
                              ? pendoIdPrefixer(`${key}_${option.value}`)
                              : undefined
                          }
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...option}
                        />
                      ))}
                    </RadioGroup>
                  </InnerFormRow>
                </FormControl>
              )}
            />
          </OuterFormRow>
        );
      })}
    </>
  );
}

DynamicFormYesNoRadioGroups.propTypes = {
  field: executionRequirementShape.isRequired,
};

DynamicFormYesNoRadioGroups.defaultProps = {};

const StyledDynamicFormLabel = styled(DynamicFormLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

const OuterFormRow = styled('div')(({ theme, $rowCount, $isSelectAllRow }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor:
    $rowCount % 2 ? 'inherit' : theme.palette.background.tableZebra,
  padding: theme.spacing(1.0, 0),
  borderTop: $isSelectAllRow
    ? `1px solid ${theme.palette.font.default}`
    : 'none',
}));

const InnerFormRow = styled('div')(() => ({
  display: 'inline-flex',
  gap: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledRadioButton = styled(BaseRadioButton)(() => ({
  marginLeft: '20px',
  '& .MuiRadio-root': {
    marginRight: '5px',
  },
  alignItems: 'center',
  '&:not(:first-of-type)': {
    marginTop: 0,
  },
}));
