import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from '@/pages/PaymentInstructions/components/WelcomeStep/assets/arrowRight.svg';
import Instruction from '@/pages/PaymentInstructions/components/WelcomeStep/Instruction';
import buildingIcon from '@/pages/PaymentInstructions/components/WelcomeStep/assets/building.png';
import handshakeIcon from '@/pages/PaymentInstructions/components/WelcomeStep/assets/handshake.png';
import moneyIcon from '@/pages/PaymentInstructions/components/WelcomeStep/assets/money.png';

export default function Instructions() {
  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h1">
        Welcome to Stellar Health. You&apos;re almost ready to start earning!
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <Instruction
          text="Agree to Stellar Health's terms"
          listNumber="1"
          imageSrc={handshakeIcon}
        />
        <StyledArrowRightIcon />
        <Instruction
          text="Provide your payment details and set your preferences"
          listNumber="2"
          imageSrc={buildingIcon}
        />
        <StyledArrowRightIcon />
        <Instruction
          text="Start earning SVUs!"
          listNumber="3"
          imageSrc={moneyIcon}
        />
      </Stack>
    </Stack>
  );
}

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  width: 80px;
  height: 80px;
`;
