/* eslint-disable no-shadow */
import { useState } from 'react';
import { any, arrayOf, bool, func, node } from 'prop-types';
import { Box, Button, Dialog, DialogTitle, Stack, styled } from '@mui/material';
import useId from '@mui/utils/useId';

import { color } from '@/utils/stylingUtils';
import CompactPatientSummaryContainer from '@/components/CompactPatientSummary/components/CompactPatientSummaryContainer';
import useNonDiagnosisActionMutator from '@/pages/PatientSummaryEhr/hooks/useNonDiagnosisActionMutator';

import MasterDetailView from './MasterDetailView';

/**
 * @template D
 * @param {D} data
 * @param {(data: D) => number} dataToPatientId
 * @param {((data: D) => number | string)?} getRowKey
 * @param {D?} initiallySelected
 * @param {() => void} onClose
 * @param {boolean} open
 * @param {(data: D) => React.ReactNode} renderPatientRow
 * @param {(data: D[]) => React.ReactNode?} renderPatientHeader
 * @param {(data: D[]) => React.ReactNode?} renderPatientFooter
 * @param {React.ReactNode} title
 */
export default function MultiPatientModal({
  data,
  dataToPatientId,
  getRowKey,
  initiallySelected,
  onClose,
  open,
  renderPatientRow,
  renderPatientHeader,
  renderPatientFooter,
  title,
  ...dialogProps
}) {
  const [selected, setSelected] = useState(initiallySelected);
  const titleId = useId();

  const { mutateAsync: onSaveNonDiagnosisAction, status: nonDxSaveStatus } =
    useNonDiagnosisActionMutator();

  const rowKeyFn = ({ data }) => {
    if (getRowKey) return getRowKey(data);
    return dataToPatientId(data);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      aria-labelledby={titleId}
      PaperProps={{
        elevation: 2,
        sx: {
          display: 'inline-flex',
          flexDirection: 'column',
          margin: '32px',
          height: '80vh',
          maxWidth: '1400px',
          width: '80vw',
        },
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...dialogProps}
    >
      <StyledDialogTitleBar direction="row" alignItems="center">
        <DialogTitle id={titleId} sx={{ flex: '1 0 auto' }}>
          {title}
        </DialogTitle>
        <Box p={1}>
          <Button variant="text" onClick={onClose}>
            Close
          </Button>
        </Box>
      </StyledDialogTitleBar>
      <Box display="flex" flex="1 1" overflow="hidden">
        <MasterDetailView
          renderDetailView={({ data }) => (
            <CompactPatientSummaryContainer
              patientId={dataToPatientId(data)}
              onSaveNonDiagnosisAction={onSaveNonDiagnosisAction}
              nonDxSaveStatus={nonDxSaveStatus}
            />
          )}
          renderMasterHeader={renderPatientHeader}
          renderMasterRow={renderPatientRow}
          renderMasterFooter={renderPatientFooter}
          data={data}
          getRowKey={rowKeyFn}
          onSelectRow={({ data }) => setSelected(data)}
          selectedRow={selected}
        />
      </Box>
    </Dialog>
  );
}
MultiPatientModal.propTypes = {
  data: arrayOf(any.isRequired).isRequired,
  dataToPatientId: func.isRequired,
  getRowKey: func,
  initiallySelected: any,
  onClose: func.isRequired,
  open: bool.isRequired,
  renderPatientRow: func.isRequired,
  renderPatientHeader: func,
  renderPatientFooter: func,
  title: node.isRequired,
};
MultiPatientModal.defaultProps = {
  getRowKey: null,
  initiallySelected: null,
  renderPatientHeader: () => null,
  renderPatientFooter: () => null,
};

const StyledDialogTitleBar = styled(Stack)`
  border-bottom: 1px solid ${color('border.base')};
`;
