import axios from 'axios';
import { useInfiniteQuery } from '@tanstack/react-query';
import { last } from 'lodash';

import { useQueryParameters } from '@/contexts/QueryParametersContext';
import transformFrontendUserInputTask from '@/utils/transformFrontendUserInputTask';

import queryKeyFactory from '../queryKeyFactory';
import { usePage } from '../contexts/PageContext';

async function fetchTasks({
  page,
  query,
  preferredNextStep,
  pageParam,
  pageSize,
  providerIds,
  officeIds,
  planIds,
  patientTags,
  ageBuckets,
  includeHidden,
  sortField,
  sortDirection,
  filterTab,
  adlMin,
  adlMax,
  tbwSubtypeChoices,
  avYears,
  allSubtypes,
  resolveContextualInfo,
}) {
  const params = {
    preferred_next_step: preferredNextStep,
    query,
    page_number: pageParam,
    page_size: pageSize,
    provider_ids: providerIds,
    office_ids: officeIds,
    plan_ids: planIds,
    patient_tags: patientTags,
    age_buckets: ageBuckets,
    include_hidden: includeHidden,
    sort_field: sortField,
    sort_direction: sortDirection,
    filter_tab: filterTab,
    adl_min: adlMin,
    adl_max: adlMax,
    action_subtypes: tbwSubtypeChoices,
    av_years: avYears,
    all_subtypes: allSubtypes,
    resolve_contextual_info: resolveContextualInfo,
  };
  const response = await axios.get(`/api/tbw/${page}/tasks`, { params });
  return response.data;
}

function transformResponse(data) {
  const lastPage = last(data.pages);

  return {
    // pages and pageParams are required return values
    pages: data.pages.map((page) => ({
      results: page.results.map(transformFrontendUserInputTask),
    })),
    pageParams: data.pageParams,

    // These fields should be consistent across all pages
    totalPages: lastPage.total_pages,
    pageSize: lastPage.page_size,
    totalResults: lastPage.total_results,
  };
}

export default function useGetTasks({
  query = undefined,
  onSuccess = undefined,
  pageSize = undefined,
  resolveContextualInfo = true,
} = {}) {
  const { parameters } = useQueryParameters();

  const params = {
    page: usePage(),
    query,
    pageSize,
    resolveContextualInfo,
    preferredNextStep: parameters.preferredNextStep,
    providerIds: parameters.providerIds,
    officeIds: parameters.officeIds,
    planIds: parameters.planIds,
    patientTags: parameters.patientTags,
    ageBuckets: parameters.ageBuckets,
    includeHidden: parameters.includeHidden,
    sortField: parameters.sortField,
    sortDirection: parameters.sortDirection,
    filterTab: parameters.filterTab,
    adlMin: parameters.adlMin,
    adlMax: parameters.adlMax,
    tbwSubtypeChoices: parameters.tbwSubtypeChoices,
    avYears: parameters.avYears,
    allSubtypes: parameters.allSubtypes,
  };

  return useInfiniteQuery({
    queryKey: queryKeyFactory.tasksSearch(params),
    queryFn: ({ pageParam = 0 }) =>
      fetchTasks({
        pageParam,
        ...params,
      }),
    select: transformResponse,
    getNextPageParam: (lastPage) =>
      // page_number is 0-index, so we must add 1 when comparing to total_pages
      lastPage.page_number + 1 < lastPage.total_pages
        ? lastPage.page_number + 1
        : undefined,
    onSuccess,
    keepPreviousData: true,
  });
}
