import PropTypes from 'prop-types';

const statusLabels = {
  user_attested: 'Accepted',
  user_removed: 'Dismissed',
  user_ignored: 'Ignored',
  user_unmapped: 'Unknown',
};

export function getStatusLabel(status) {
  return status in statusLabels
    ? statusLabels[status]
    : statusLabels.user_unmapped;
}

export function extractPatientExternalCareGapDetails(details) {
  return details
    ? {
        appointmentStatus: details.appointment_status,
        appointmentType: details.appointment_type,
      }
    : {};
}

export function transformLatestExternalCareGap(latestExternalCareGap) {
  return latestExternalCareGap
    ? {
        externalCareGapId: latestExternalCareGap.external_care_gap_id,
        actionId: latestExternalCareGap.action_id,
        patientId: latestExternalCareGap.patient_id,
        providerId: latestExternalCareGap.provider_id,
        userName: latestExternalCareGap.user_name,
        measureStartsAt: latestExternalCareGap.measure_starts_at,
        measureEndsAt: latestExternalCareGap.measure_ends_at,
        dateOfService: latestExternalCareGap.date_of_service,
        status: getStatusLabel(latestExternalCareGap.status),
        recordedAt: latestExternalCareGap.recorded_at,
        reason: latestExternalCareGap.reason,
        cptCodes: latestExternalCareGap.cpt_codes,
        notes: latestExternalCareGap.notes,
        details: extractPatientExternalCareGapDetails(
          latestExternalCareGap.details,
        ),
        sourceType: latestExternalCareGap.source_type,
      }
    : {};
}

export const latestExternalCareGapShape = PropTypes.shape({
  externalCareGapId: PropTypes.number,
  userName: PropTypes.string,
  dateOfService: PropTypes.string,
  status: PropTypes.string,
  reason: PropTypes.string,
  cptCodes: PropTypes.string,
  notes: PropTypes.string,
});
