import { arrayOf, bool, func, node, object } from 'prop-types';

import DiagnosisFormSection from './DiagnosisFormSection';
import DiagnosisActionFormCheckbox from './DiagnosisActionFormCheckbox';

export default function DiagnosisFormCheckboxGroup({
  conditions,
  header,
  onSelectCondition,
  selectedConditions,
  disabled,
}) {
  return (
    <DiagnosisFormSection header={header}>
      {conditions.map((condition, i) => {
        const key = `${condition.icd}--${i}`;
        return (
          <DiagnosisActionFormCheckbox
            key={key}
            checked={condition.icd in selectedConditions}
            diagnosis={condition}
            onChange={() => onSelectCondition(condition)}
            disabled={disabled}
          />
        );
      })}
    </DiagnosisFormSection>
  );
}
DiagnosisFormCheckboxGroup.propTypes = {
  conditions: arrayOf(object.isRequired).isRequired,
  header: node.isRequired,
  onSelectCondition: func.isRequired,
  selectedConditions: object.isRequired,
  disabled: bool,
};

DiagnosisFormCheckboxGroup.defaultProps = {
  disabled: false,
};
