function hasKey(obj, key) {
  return (
    Object.keys(obj).includes(key) ||
    Object.values(obj)
      .filter((val) => typeof val === 'object' && val !== null)
      .some((val) => hasKey(val, key))
  );
}
export default function areRequiredFieldsInDirtyFields({
  requiredFields,
  dirtyFields,
}) {
  return requiredFields.every((field) => hasKey(dirtyFields, field));
}
