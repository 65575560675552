/**
 * For use on Select options that come from the backend as {id, name}
 * @param option
 * @returns {{label, value}}
 */
export default function transformOption(option) {
  return {
    value: option.id,
    label: option.name,
  };
}

/**
 * Returns the default value if one exists or undefined otherwise.
 */
export function getDefaultValue(array) {
  if (Array.isArray(array) && array.length === 1) {
    return array[0].id;
  }
  return undefined;
}
