import { styled } from '@mui/material';

import { actionShape } from '@/utils/transformFrontendAction';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';

export default function ParentActionDos({ action }) {
  const { data: contextualInfo, isLoading } = useGetContextualInfo(action);

  return isLoading ? (
    <FieldSkeleton />
  ) : (
    <Date>{contextualInfo.parentDateOfService || 'None'}</Date>
  );
}

ParentActionDos.propTypes = {
  action: actionShape.isRequired,
};

const Date = styled('span')`
  white-space: nowrap;
`;
