import { bool, func, number, object, oneOfType, string } from 'prop-types';

import { patientShape, reactQueryStatusValues } from '@/utils/shapes';

import useConditionsForDisplay from '../../hooks/useConditionsForDisplay';

import DiagnosisActionFormLong from './DiagnosisActionFormLong';
import DiagnosisActionFormShort from './DiagnosisActionFormShort';
import CollapsedDiagnosisActionForm from './CollapsedDiagnosisActionForm';

const FORMS = {
  short: DiagnosisActionFormShort,
  long: DiagnosisActionFormLong,
};

export default function DiagnosisActionForm({
  defaultProviderChoiceId,
  diagnosisAction,
  narrow,
  onSaveDiagnosisAction,
  open,
  patient,
  saveStatus,
  setOpen,
  isSubmitDisabled,
}) {
  const { pastDiagnoses } = useConditionsForDisplay(diagnosisAction);

  if (!onSaveDiagnosisAction) return null;

  if (!open) {
    return (
      <CollapsedDiagnosisActionForm
        firstDiagnosis={pastDiagnoses[0]}
        narrow={narrow}
        onExpandLongForm={() => setOpen('long')}
        onExpandShortForm={() => setOpen('short')}
      />
    );
  }

  const Component = FORMS[open];

  return (
    <Component
      defaultProviderChoiceId={defaultProviderChoiceId}
      diagnosisAction={diagnosisAction}
      narrow={narrow}
      onCancel={() => setOpen(false)}
      onExpandLongForm={() => setOpen('long')}
      onExpandShortForm={() => setOpen('short')}
      onSaveDiagnosisAction={onSaveDiagnosisAction}
      patient={patient}
      saveStatus={saveStatus}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
}

DiagnosisActionForm.propTypes = {
  defaultProviderChoiceId: number,
  diagnosisAction: object.isRequired,
  narrow: bool.isRequired,
  onSaveDiagnosisAction: func,
  open: oneOfType([bool, string]).isRequired,
  patient: patientShape.isRequired,
  saveStatus: reactQueryStatusValues.isRequired,
  setOpen: func.isRequired,
  isSubmitDisabled: bool.isRequired,
};

DiagnosisActionForm.defaultProps = {
  defaultProviderChoiceId: undefined,
  onSaveDiagnosisAction: undefined,
};
