import PropTypes from 'prop-types';
import { Box, Typography, styled } from '@mui/material';

import { stepShape } from '@/utils/transformFrontendCareGapAction';

import { ReactComponent as TimerIcon } from './assets/timer.svg';

export default function SvuAmount({ step, sx, completed }) {
  const { svuPrePromo, svuPostPromo, svusPrePromo, svusPostPromo } = step;
  const prePromo = completed ? svusPrePromo : svuPrePromo;
  const postPromo = completed ? svusPostPromo : svuPostPromo;
  const hasPromo = Boolean(postPromo && prePromo > 0);
  const color = completed ? 'status.successForeground' : 'text.secondary';

  return (
    <Box
      sx={[
        { display: 'flex', alignItems: 'center' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography
        variant="bodymediumbold"
        color={color}
        sx={{
          textDecorationLine: hasPromo ? 'line-through' : undefined,
        }}
      >
        {prePromo} SVU
      </Typography>
      {hasPromo ? (
        <PromoContainer>
          <Typography variant="bodymediumbold">{postPromo} SVU</Typography>
          <StyledTimerIcon sx={{ ml: 0.5 }} />
        </PromoContainer>
      ) : null}
    </Box>
  );
}

SvuAmount.propTypes = {
  step: stepShape.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  completed: PropTypes.bool,
};

SvuAmount.defaultProps = {
  sx: undefined,
  completed: false,
};

const StyledTimerIcon = styled(TimerIcon)`
  vertical-align: 'middle';
`;

const PromoContainer = styled('div')`
  color: ${(p) => p.theme.palette.svu.dark};
  display: flex;
  align-items: center;
  padding: ${(p) => p.theme.spacing(0.5, 1)};
  background-color: ${(p) => p.theme.palette.svu.lightest};
  border-radius: ${(p) => p.theme.shape.borderRadius * 4}px;
  margin-left: ${(p) => p.theme.spacing(1)};
`;
