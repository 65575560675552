import { formatISO, parseISO, subMonths } from 'date-fns';

export default function fillHistory(history, months) {
  if (history.length === months) {
    return history;
  }
  if (history.length > months) {
    return history.slice(history.length - months);
  }
  const startDate = parseISO(history[0].startDate);
  const monthsOfPadding = months - history.length;
  const padding = Array(monthsOfPadding)
    .fill(0)
    .map((_, index) => {
      return {
        startDate: formatISO(subMonths(startDate, monthsOfPadding - index), {
          representation: 'date',
        }),
        // totalValue null renders better in the chart than 0
        totalValue: null,
      };
    });
  return padding.concat(history);
}
