import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import BaseTextarea from '@/components/BaseTextarea';

export default function RhfTextArea({
  control,
  name,
  defaultValue,
  rules,
  placeholder,
  id,
  sx,
  minRows,
  'data-pendo-id': dataPendoId,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <BaseTextarea
          id={id}
          fullWidth
          placeholder={placeholder}
          onChange={field.onChange}
          value={field.value}
          sx={sx}
          minRows={minRows}
          data-pendo-id={dataPendoId}
        />
      )}
    />
  );
}

RhfTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  minRows: PropTypes.number,
  'data-pendo-id': PropTypes.string,
};

RhfTextArea.defaultProps = {
  placeholder: undefined,
  defaultValue: '',
  id: undefined,
  sx: undefined,
  minRows: 3,
  'data-pendo-id': undefined,
};
