import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import BaseSpinner from '@/components/BaseSpinner';

export default function Spinner({ size, mt }) {
  return (
    <Box mt={mt} textAlign="center" width="100%">
      <BaseSpinner size={size} />
    </Box>
  );
}

Spinner.propTypes = {
  size: PropTypes.number,
  mt: PropTypes.number,
};

Spinner.defaultProps = {
  size: 50,
  mt: 8,
};
