import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import queryKeyFactory from '@/pages/TaskBasedWorkflow/queryKeyFactory';

async function bulkExecuteSteps({ bulkUpdate, executionInput }) {
  return axios.post('/api/tbw/steps/bulk-execute', {
    bulk_update: bulkUpdate,
    execution_input: executionInput,
  });
}

export default function useBulkExecuteSteps({
  onSuccess = undefined,
  onError = undefined,
} = {}) {
  const queryClient = useQueryClient();
  return useMutation(bulkExecuteSteps, {
    onSuccess,
    onError,
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKeyFactory.tasksSearch());
    },
  });
}
