import PropTypes from 'prop-types';

export type Address = {
  id: number;
  phoneNumber: string;
  secondaryPhoneNumber: string;
  phoneNumberChangedByUser: boolean;
  faxNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  hasPhysicalAddress: boolean;
  hasAddressData: boolean;
};

type APIAddress = {
  id: number;
  phone_number: string;
  secondary_phone_number: string;
  phone_number_changed_by_user: boolean;
  fax_number: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  has_physical_address: boolean;
  has_address_data: boolean;
};

/**
 * Transform data that came from the `FrontendAddress` class.
 */
export default function transformFrontendAddress(address: APIAddress): Address {
  return {
    id: address.id,
    phoneNumber: address.phone_number,
    secondaryPhoneNumber: address.secondary_phone_number,
    phoneNumberChangedByUser: address.phone_number_changed_by_user,
    faxNumber: address.fax_number,
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    state: address.state,
    zip: address.zip,
    hasPhysicalAddress: address.has_physical_address,
    hasAddressData: address.has_address_data,
  };
}

export const addressShape = PropTypes.shape({
  id: PropTypes.number,
  phoneNumber: PropTypes.string,
  secondaryPhoneNumber: PropTypes.string,
  phoneNumberChangedByUser: PropTypes.bool,
  faxNumber: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  hasPhysicalAddress: PropTypes.bool,
  hasAddressData: PropTypes.bool,
});

export const UsStatesTupleWithNone = [
  ['', '---------'],
  ['AK', 'Alaska'],
  ['AL', 'Alabama'],
  ['AR', 'Arkansas'],
  ['AS', 'American Samoa'],
  ['AZ', 'Arizona'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DC', 'District of Columbia'],
  ['DE', 'Delaware'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['GU', 'Guam'],
  ['HI', 'Hawaii'],
  ['IA', 'Iowa'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['MA', 'Massachusetts'],
  ['MD', 'Maryland'],
  ['ME', 'Maine'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MO', 'Missouri'],
  ['MP', 'Northern Mariana Islands'],
  ['MS', 'Mississippi'],
  ['MT', 'Montana'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['NE', 'Nebraska'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NV', 'Nevada'],
  ['NY', 'New York'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PA', 'Pennsylvania'],
  ['PR', 'Puerto Rico'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VA', 'Virginia'],
  ['VI', 'U.S. Virgin Islands'],
  ['VT', 'Vermont'],
  ['WA', 'Washington'],
  ['WI', 'Wisconsin'],
  ['WV', 'West Virginia'],
  ['WY', 'Wyoming'],
];
