/* eslint-disable no-shadow */

import hccToDiagnosis from './hccToDiagnosis';

/**
 * @template {object} T
 * @typedef ReducerAction
 * @property {string} type
 * @property {T} payload
 */
/**
 * @typedef DiagnosisReducerState
 * @property {boolean} completeWithoutCode
 * @property {boolean} diagnosisIncorrect
 * @property {Record<string, Diagnosis>} selectedDiagnoses
 * @property {number|null} selectedHcc
 * @property {string} note
 */
export const initialState = {
  // This flag is set when the user selects "Mark condition as assessed &
  // present without selecting diagnosis"
  completeWithoutCode: false,

  // This flag is set when the user selects "Assessed and Not Present"
  diagnosisIncorrect: false,

  selectedDiagnoses: {},
  selectedHcc: null,

  notAssessed: false,

  note: '',
};

/**
 * @template {object} Payload
 * @param {string} type
 * @returns {(payload: Payload) => ReducerAction<Payload>}
 */
const actionCreator = (type) => (payload) => ({ type, payload });

// Reducer actions:
export const changeToRelatedCondition = actionCreator(
  'CHANGE_TO_RELATED_CONDITION',
);
export const completeWithoutCode = actionCreator('COMPLETE_WITHOUT_CODE');
export const conditionNotPresent = actionCreator('CONDITION_NOT_PRESENT');
export const conditionNotAssessed = actionCreator('CONDITION_NOT_ASSESSED');
export const selectDiagnosis = actionCreator('SELECT_DIAGNOSIS');
export const updateNote = actionCreator('UPDATE_NOTE');
export const unselectDiagnosis = actionCreator('UNSELECT_DIAGNOSIS');

/**
 * @param {DiagnosisReducerState} reducerState
 * @param {ReducerAction} reducerAction
 * @returns {DiagnosisReducerState}
 */
export function reducer(reducerState, reducerAction) {
  const { type, payload } = reducerAction;
  if (type === 'SELECT_DIAGNOSIS') {
    const { diagnosis } = payload;
    const selectedDiagnoses = reducerState.selectedHcc
      ? {}
      : reducerState.selectedDiagnoses;

    return {
      ...reducerState,
      completeWithoutCode: false,
      diagnosisIncorrect: false,
      selectedDiagnoses: {
        ...selectedDiagnoses,
        [diagnosis.icd]: diagnosis,
      },
      selectedHcc: null,
      notAssessed: false,
    };
  }
  if (type === 'UNSELECT_DIAGNOSIS') {
    // shallow clone:
    const selectedDiagnoses = { ...reducerState.selectedDiagnoses };
    delete selectedDiagnoses[payload.diagnosis.icd];

    return {
      ...reducerState,
      completeWithoutCode: false,
      diagnosisIncorrect: false,
      selectedDiagnoses,
      selectedHcc: null,
      notAssessed: false,
    };
  }
  if (type === 'CHANGE_TO_RELATED_CONDITION') {
    const { condition } = payload;
    const diagnosis = condition ? hccToDiagnosis(condition) : null;
    const selectedDiagnoses = diagnosis ? { [diagnosis.icd]: diagnosis } : {};

    return {
      ...reducerState,
      completeWithoutCode: false,
      diagnosisIncorrect: false,
      selectedDiagnoses,
      selectedHcc: condition ? condition.hcc : null,
      notAssessed: false,
    };
  }
  if (type === 'COMPLETE_WITHOUT_CODE') {
    const { selectedHcc, completeWithoutCode } = payload;

    return {
      ...reducerState,
      completeWithoutCode,
      diagnosisIncorrect: false,
      selectedDiagnoses: {},
      selectedHcc: completeWithoutCode ? (selectedHcc ?? null) : null,
      notAssessed: false,
    };
  }
  if (type === 'CONDITION_NOT_PRESENT') {
    const { diagnosisIncorrect } = payload;
    return {
      ...reducerState,
      completeWithoutCode: false,
      diagnosisIncorrect,
      selectedDiagnoses: {},
      selectedHcc: null,
      notAssessed: false,
    };
  }
  if (type === 'CONDITION_NOT_ASSESSED') {
    const { notAssessed } = payload;
    return {
      ...reducerState,
      completeWithoutCode: false,
      diagnosisIncorrect: false,
      selectedDiagnoses: {},
      selectedHcc: null,
      notAssessed,
    };
  }
  if (type === 'UPDATE_NOTE') {
    return {
      ...reducerState,
      note: payload.note,
    };
  }
  return reducerState;
}
