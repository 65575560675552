import { Box, Typography } from '@mui/material';

import { actionShape } from '@/utils/transformFrontendAction';

import {
  ED_TOC_CONTACT_PATIENT,
  TOC_ED_FOLLOW_UP_FMC,
  TOC_NOTIFICATION_OF_ADMISSION,
} from '../../constants';

export default function TocTriggerEvent({ action }) {
  if (action.subtype === ED_TOC_CONTACT_PATIENT) {
    return (
      <Box sx={{ mt: 1, display: 'flex', mb: 2 }}>
        <Box>
          <Typography variant="bodysmall" color="text.label">
            Event
          </Typography>
          <Typography>ED discharge</Typography>
        </Box>

        <Box ml={5}>
          <Typography variant="bodysmall" color="text.label">
            Visit date
          </Typography>
          <Typography>{action.v2Dto.contextualInfo?.adtEventDate}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{ mt: 1, display: 'flex', mb: 2 }}>
      <Box>
        <Typography variant="bodysmall" color="text.label">
          Event
        </Typography>
        <Typography>{action.v2Dto.contextualInfo?.trigger}</Typography>
      </Box>

      <Box ml={5}>
        <Typography variant="bodysmall" color="text.label">
          Facility
        </Typography>
        <Typography>{action.v2Dto.contextualInfo?.adtEventFacility}</Typography>
      </Box>

      {action.subtype !== TOC_NOTIFICATION_OF_ADMISSION &&
        action.subtype !== TOC_ED_FOLLOW_UP_FMC && (
          <Box ml={5}>
            <Typography variant="bodysmall" color="text.label">
              Length of stay
            </Typography>
            <Typography>
              {action.v2Dto.contextualInfo?.lengthOfStay > 0
                ? `${action.v2Dto.contextualInfo?.lengthOfStay} day(s)`
                : 'Unknown'}
            </Typography>
          </Box>
        )}

      <Box ml={5}>
        <Typography variant="bodysmall" color="text.label">
          Diagnoses
        </Typography>
        <Typography>
          {action.v2Dto.contextualInfo?.adtEventDiagnosisCode}{' '}
          {action.v2Dto.contextualInfo?.adtEventDiagnosisHccDescription ??
            action.v2Dto.contextualInfo?.adtEventDiagnosisDescription}
        </Typography>
      </Box>
    </Box>
  );
}

TocTriggerEvent.propTypes = {
  action: actionShape.isRequired,
};
