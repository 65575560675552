const auditDataKey = 'audit-data';

const auditDataQueryKeyFactory = {
  auditData: ({ type = undefined, modelId = undefined } = {}) => {
    if (!type && !modelId) {
      return [auditDataKey];
    }
    if (!modelId) {
      return [auditDataKey, { type }];
    }
    return [auditDataKey, { type, modelId }];
  },
};

export default auditDataQueryKeyFactory;
