import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

async function logOutreach({ patientId, outcome, note }) {
  return axios.post(`/api/patient/${patientId}/outreaches/log`, {
    outcome,
    note,
  });
}

export default function useLogOutreach({ onSuccess = undefined } = {}) {
  return useMutation(logOutreach, {
    onSuccess,
  });
}
