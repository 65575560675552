import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import BaseRadioGroup from '@/components/BaseRadioGroup';
import Address from '@/pages/PaymentInstructions/components/PaymentMethodStep/Address';
import addressShape from '@/pages/PaymentInstructions/utils/addressShape';

const INPUTTED_ADDRESS = 'inputtedAddress';
const VALIDATED_ADDRESS = 'validatedAddress';

export default function AddressSelection({
  validatedAddress,
  inputtedAddress,
  onSelectAddress,
}) {
  const options = [
    {
      label: (
        <Box sx={{ mr: 5 }}>
          <Typography>What you entered</Typography>
          <Box sx={{ ml: 1, mt: 1 }}>
            <Address address={inputtedAddress} />
          </Box>
        </Box>
      ),
      value: INPUTTED_ADDRESS,
    },
    {
      label: (
        <Box sx={{ mr: 5 }}>
          <Typography>USPS Recommendation</Typography>
          <Box sx={{ ml: 1, mt: 1 }}>
            <Address address={validatedAddress} />
          </Box>
        </Box>
      ),
      value: VALIDATED_ADDRESS,
    },
  ];

  const handleSelectAddress = (event) => {
    const selectedAddress =
      event.target.value === VALIDATED_ADDRESS
        ? validatedAddress
        : inputtedAddress;
    onSelectAddress(selectedAddress);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: 1,
      }}
    >
      <Typography sx={{ mb: 1 }}>
        We found a more precise version of the address you entered. Please
        choose which version of the address you would like to use.
      </Typography>
      <BaseRadioGroup
        defaultValue={VALIDATED_ADDRESS}
        options={options}
        sx={{ ml: 1 }}
        onChange={handleSelectAddress}
        row
      />
    </Box>
  );
}

AddressSelection.propTypes = {
  validatedAddress: addressShape,
  inputtedAddress: addressShape,
  onSelectAddress: PropTypes.func.isRequired,
};

AddressSelection.defaultProps = {
  validatedAddress: undefined,
  inputtedAddress: undefined,
};
