import { differenceInDays, endOfMonth } from 'date-fns';

export function areAllGoalsComplete(goals) {
  return goals.every((goal) => goal.completed >= goal.total);
}

export function getDaysTilEndOfMonth(today) {
  const endOfMonthDate = endOfMonth(today);
  const daysTilEndOfMonth = differenceInDays(endOfMonthDate, today);
  return daysTilEndOfMonth;
}

export function computeDifficulties(today) {
  // the product requirements are as follows:
  // If the difference between time of month (TOM) and goal completion % =
  // 25% or greater → Red
  // 10 - 24% → Yellow
  // <= 9% → Green
  // If the difference of TOM - goal completion % = negative # → Green
  // For example, if 50% through the month, but goal completion is 40% complete → display as yellow.
  // For example, if 60% through the month, but goal completion is 75% complete → display as green.

  // so, we do some algebra to come up with the below
  // YELLOW: TOM% - GOAL_COMPLETED% <= 24
  // YELLOW: TOM% - 24 <= GOAL_COMPLETED%
  // YELLOW: GOAL_COMPLETED% >= TOM% - 24

  // GREEN: TOM% - GOAL_COMPLETED% <= 9
  // GREEN: TOM% - 9 <= GOAL_COMPLETED%
  // GREEN: GOAL_COMPLETED% >= TOM% - 9

  // thus the threshold for a green goal is TOM% - 9
  // and the threshold for a yellow goal is TOM% - 24
  // else the goal is red
  const lastDayOfMonth = endOfMonth(today);
  const daysInMonth = lastDayOfMonth.getDate();

  const dayOfMonth = today.getDate();
  const percentOfMonth = (dayOfMonth / daysInMonth) * 100;
  const difficulties = {
    easy: percentOfMonth - 9,
    medium: percentOfMonth - 24,
  };

  return difficulties;
}

const GOALS_ORDER = {
  ACV: 1,
  HCC: 2,
  'Quality C': 3,
  'Quality D': 4,
};

export function getSortedGoals(goals) {
  return goals.sort(
    (a, b) =>
      GOALS_ORDER[a.internalPerformanceMetric] -
      GOALS_ORDER[b.internalPerformanceMetric],
  );
}

export function doesOneGoalHaveProgress(goals) {
  return goals.some((goal) => goal.completed > 0);
}
