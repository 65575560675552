import TableContainer from '@mui/material/TableContainer';
import { Table } from '@mui/material';
import PropTypes from 'prop-types';

import StyledTablePagination from '@ot/components/StyledTablePagination';

export default function StyledPaginatedTable({
  children,
  paginationProps,
  sx,
}) {
  return (
    <div>
      <TableContainer
        sx={{
          backgroundColor: 'background.base',
          border: '1px solid',
          borderColor: 'border.base',
          borderTopRightRadius: 2,
          borderTopLeftRadius: 2,
          borderBottom: 'none',
          ...sx,
        }}
      >
        <Table stickyHeader>{children}</Table>
      </TableContainer>
      <StyledTablePagination
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...paginationProps}
      />
    </div>
  );
}

StyledPaginatedTable.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paginationProps: PropTypes.object.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
StyledPaginatedTable.defaultProps = {
  sx: undefined,
};
