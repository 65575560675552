import * as everything from '@/pages/PatientSummary/utils/stepBasedActionExecutionRequirements';

/**
 * While `stepBasedActionExecutionRequirements` is partitioned by the action
 * subtype, that's just an affordance for organization. Step ids in step-based
 * actions are globally unique, so to simplify lookups (and not require the
 * action subtype at lookup time) convert the imported file into a two-level
 * lookup:
 *
 * {
 *   [step_id]: {
 *     [form_field_name]: { config }
 *   }
 * }
 *
 * @type {Record<string, Record<string, function>>}
 */
export const dynamicPropertiesByStepId = Object.values(everything).reduce(
  (acc, action) => {
    return Object.entries(action).reduce(
      // eslint-disable-next-line no-shadow
      (acc, [stepId, executionRequirements]) => {
        if (Object.keys(executionRequirements).length === 0) {
          // No configurations for this step id, do nothing:
          return acc;
        }
        return {
          ...acc,
          [stepId]: executionRequirements,
        };
      },
      acc,
    );
  },
  {},
);

function lookupDynamicProperties(executionRequirement, step) {
  const clonedExecutionRequirement = { ...executionRequirement };

  if (
    dynamicPropertiesByStepId[step.stepId] &&
    dynamicPropertiesByStepId[step.stepId][executionRequirement.name]
  ) {
    Object.assign(
      clonedExecutionRequirement,
      dynamicPropertiesByStepId[step.stepId][executionRequirement.name],
    );
  }

  return clonedExecutionRequirement;
}

/**
 * Molding the Execution Requirements to convey
 * extra information to Dynamic Form Modals.
 *
 * Date -> add '' customDateMessage
 *         Step-Based Actions do not require a helper text
 *         within the modal along with DatePicker.
 *
 * Required = False -> add ' (optional)' to existing
 *                     label
 */
export default function enhanceStepExecutionRequirements(
  execReq,
  step,
  stepExclusions = [],
) {
  const updatedExecReq = lookupDynamicProperties(execReq, step);
  if (stepExclusions.includes(step.stepId)) {
    return updatedExecReq;
  }
  if (execReq.type === 'date') {
    updatedExecReq.customDateMessage = '';
  }
  const isLabel = ['label', 'boldlabel'].includes(execReq.type);
  if (execReq.required === false && !isLabel) {
    updatedExecReq.label = `${execReq.label} (optional)`;
  }
  return updatedExecReq;
}
