import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import * as Qs from 'query-string';

import queryKeyFactory from '../queryKeyFactory';

function transformResponse(data) {
  return Object.fromEntries(
    data.svu_summaries.map((svuSummary) => [
      svuSummary.patient_id,
      svuSummary.point_of_care_svus_after_promo,
    ]),
  );
}

async function fetchPatientSvuSummaries({ patientIds }) {
  const response = await axios.get(`/api/patient_svu_summary`, {
    params: {
      ids: patientIds,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'bracket' });
    },
  });
  return response.data;
}

export default function useGetPatientSvuSummaries({
  patientIds = [],
  enabled = true,
} = {}) {
  const params = { patientIds };
  return useQuery(
    queryKeyFactory.patientSvuSummaries({
      patientIds,
    }),
    () => fetchPatientSvuSummaries(params),
    {
      select: transformResponse,
      keepPreviousData: true,
      enabled,
    },
  );
}
