import Typography from '@mui/material/Typography';

import addressShape from '@/pages/PaymentInstructions/utils/addressShape';

export default function Address({ address }) {
  return (
    <>
      {Object.keys(address)
        .sort()
        .map((key) => (
          <Typography key={key}>{address[key]}</Typography>
        ))}
    </>
  );
}

Address.propTypes = {
  address: addressShape,
};

Address.defaultProps = {
  address: undefined,
};
