import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';

export default function ExportPrintLoadingDialog({ open, isPrint }) {
  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: '300px', p: 4 }}>
        <Box textAlign="center">
          <CircularProgress size={56} color="info" />
          <Typography variant="bodylargebold" mt={4}>
            Preparing to {isPrint ? 'print' : 'export'}...
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

ExportPrintLoadingDialog.propTypes = {
  isPrint: PropTypes.bool,
  open: PropTypes.bool,
};

ExportPrintLoadingDialog.defaultProps = {
  isPrint: false,
  open: false,
};
