import PropTypes from 'prop-types';

export const actionShape = PropTypes.shape({
  id: PropTypes.number,
  isSwitchTo90DayAction: PropTypes.bool,
  allowableDays: PropTypes.number,
  dateOfService: PropTypes.string,
  drugName: PropTypes.string,
  medicationAdherenceDrugName: PropTypes.string,
  notes: PropTypes.string,
  prescriber: PropTypes.string,
  refillInProgressReason: PropTypes.string,
  refillNumberWritten: PropTypes.number,
  refillPrescriptionDate: PropTypes.string,
  refillProjectedDate: PropTypes.string,
  refillUnableToRenewReason: PropTypes.string,
  state: PropTypes.string,
  stateLabel: PropTypes.string,
  substitutionDrugs: PropTypes.arrayOf(PropTypes.string),
  subtype: PropTypes.string,
  svu: PropTypes.number,
  type: PropTypes.string,
});

export const patientShape = PropTypes.shape({
  actions: PropTypes.arrayOf(actionShape),
  attributionStatus: PropTypes.string,
  dateOfBirth: PropTypes.string,
  firstName: PropTypes.string,
  fullName: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string,
  medicalGroupId: PropTypes.number,
  planId: PropTypes.number,
  providerId: PropTypes.number,
  providerName: PropTypes.string,
  medAdhPriorityScore: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string),
});

export const actionStateShape = PropTypes.shape({
  count: PropTypes.number,
  label: PropTypes.string,
  slug: PropTypes.string,
});
