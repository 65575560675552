import palette from './palette';

// The default global line height
export const LINE_HEIGHT = 1.375;

/**
 * Typography Customization
 *
 * @type {import('@mui/material').TypographyVariantsOptions}
 *
 * Documentation: https://mui.com/customization/typography/
 */
const typography = {
  fontFamily: 'Avenir, sans-serif',
  fontWeightBold: 600,
  h1: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: LINE_HEIGHT,
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: LINE_HEIGHT,
  },
  h3: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: LINE_HEIGHT,
  },
  h4: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: LINE_HEIGHT,
  },
  bodylarge: {
    fontSize: '1.125rem',
  },
  body: {
    fontSize: '1rem',
  },
  bodysmall: {
    fontSize: '0.875rem',
  },
  bodyxsmall: {
    fontSize: '0.75rem',
  },

  bodybold: {
    fontWeight: '600',
    fontSize: '1rem',
  },
  subtext: {
    color: palette.font.lighter,
    fontSize: '0.875rem',
  },
  label: {
    color: palette.text.label,
    fontSize: '0.875rem',
    fontWeight: '600',
  },
  metriclarge: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: 1,
  },
  metric: {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  metricsmall: {
    fontSize: '1rem',
    fontWeight: 700,
  },
};

export default typography;
