// Only add values to this file as necessary to avoid bloating the JavaScript bundle with unused variables.

const emerald = {
  500: '#26ab83',
  300: '#4fd4ac',
};

const colorPool = {
  emerald,
};

export default colorPool;
