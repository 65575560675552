import { Box, Typography } from '@mui/material';
import { get } from 'lodash';

import { actionShape } from '@/utils/transformFrontendAction';
import useGetVisitsAndDxHistory from '@/pages/PrintSummary/hooks/useGetVisitsAndDxHistory';

const FIELDS = [
  { label: 'Last Office Visit', field: 'dateLastOfficeVisit' },
  { label: 'ED Visits (last 12 mos)', field: 'edVisitsLastTwelveMonths' },
  { label: 'Admissions (last 12 mos)', field: 'admissionsLastTwelveMonths' },
  { label: 'Last Admission', field: 'dateLastAdmission' },
];

export default function PriorityPatientStats({ action }) {
  const patientId = action?.v2Dto?.patientId;
  const {
    data: visitsAndDxHistoryData,
    isLoading: visitsAndDxHistoryIsLoading,
  } = useGetVisitsAndDxHistory({
    patientIds: [patientId],
  });

  const visitsAndDxHistoryStats = visitsAndDxHistoryData?.[patientId]?.stats;
  const ciStats = action?.v2Dto?.contextualInfo;
  const stats = visitsAndDxHistoryStats || ciStats;

  if (visitsAndDxHistoryIsLoading) {
    return null;
  }

  if (!stats) {
    return null;
  }

  return (
    <Box mt={1} display="flex" justifyContent="space-between">
      {FIELDS.map(({ label, field }) => {
        const value = get(stats, field);
        const displayValue =
          value !== null && value !== undefined && value !== '' ? value : '--';
        return (
          <Box mr={4} key={label}>
            <Typography variant="bodysmall" data-testid={`label-${field}`}>
              {label}
            </Typography>
            <Typography data-testid={`value-${field}`}>
              {displayValue}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

PriorityPatientStats.propTypes = {
  action: actionShape.isRequired,
};
