import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import challengeSearchQueryKeyFactory from '@ot/hooks/queryKeyFactories/challengeSearchQueryKeyFactory';

async function postInvalidateChallenges(challengeIdList) {
  const params = { challenge_ids: challengeIdList };
  const response = await axios.post(
    `/ops_tools/api/challenges/invalidate_challenges`,
    params,
  );
  return response.data;
}

export default function usePostInvalidateChallenges() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ challengeIdList }) => postInvalidateChallenges(challengeIdList),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['challenges-page-challenges']);
        queryClient.invalidateQueries(challengeSearchQueryKeyFactory.all());
      },
    },
  );
}
