import { Box, Button, FormHelperText } from '@mui/material';
import { useState } from 'react';

import useChartUploadCsv from '@ot/hooks/usePostChartUploadCsv';
import parseCsv, { HEADERS } from '@ot/pages/Actions/ChartUploadCsv/parseCsv';

function ChartUploadCsvPage() {
  // Hack to allow us to reset the file input:
  // https://stackoverflow.com/questions/69835186/how-to-clear-file-type-input-fields-file-name-in-react
  const [fileInputKey, setFileInputKey] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [csvParsingErrorMessages, setCsvParsingErrorMessages] = useState(null);
  const [csvProcessingErrorData, setCsvProcessingErrorData] = useState(null);

  const resetFile = () => {
    setFileInputKey(Math.random().toString());
    setSelectedFile(null);
  };
  const { mutate, data, isLoading } = useChartUploadCsv({
    onError: (e) => {
      setCsvProcessingErrorData(e?.response?.data);
    },
    onSettled: () => {
      resetFile();
    },
  });

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setCsvParsingErrorMessages(null);
    setCsvProcessingErrorData(null);
  };

  const handleCsvUpload = async () => {
    if (selectedFile) {
      try {
        const result = await parseCsv(selectedFile);
        mutate({
          requests: result.data,
        });
      } catch (err) {
        setCsvParsingErrorMessages(err);
      }
    }
  };
  return (
    <Box sx={{ mt: 3, ml: 3 }}>
      <div>
        Please upload a CSV of requested chart upload actions. The csv columns
        should be: {Object.values(HEADERS).join(', ')}.
      </div>
      <div style={{ height: '8px' }} />
      <input
        type="file"
        onChange={handleFileSelection}
        accept="csv"
        key={fileInputKey}
      />
      {csvParsingErrorMessages && (
        <FormHelperText error>
          Error parsing csv file:
          {csvParsingErrorMessages.map((a) => (
            <p>{`Error row: ${a.row}, ${a.message}`}</p>
          ))}
        </FormHelperText>
      )}
      {csvProcessingErrorData && csvProcessingErrorData?.errors && (
        <FormHelperText error>
          Errors processing csv file. Please fix and re-upload:
          {csvProcessingErrorData?.errors.map((errorMessage) => (
            <p key={errorMessage}>{errorMessage}</p>
          ))}
        </FormHelperText>
      )}
      <div style={{ height: '8px' }} />
      <Button onClick={handleCsvUpload} disabled={isLoading}>
        Submit
      </Button>
      <br />
      <FormHelperText component="span">
        {data && data?.message && <span>{data.message}</span>}
      </FormHelperText>
    </Box>
  );
}

export default ChartUploadCsvPage;
