import { useState } from 'react';
import PropTypes from 'prop-types';

import ChallengesTable from '@ot/pages/Challenges/ChallengesView/components/ChallengesTable';
import ChallengesActionBar from '@ot/pages/Challenges/ChallengesView/components/ChallengesActionBar';
import { challengeShape } from '@ot/pages/Challenges/ChallengesView/hooks/useGetChallenges';

function isChallengeEditable(challenge) {
  let now = new Date().toLocaleDateString();
  let parts = now.split('/');
  now = new Date(parts[2], parts[0] - 1, parts[1]);

  parts = challenge.endDate.split('-');
  const endDateObj = new Date(parts[0], parts[1] - 1, parts[2]);

  return (
    challenge.status !== 'completed' &&
    (challenge.status !== 'in_progress' || now <= endDateObj)
  );
}

function ChallengesTableSupportingUpdates({
  challengesRequiringApproval,
  ariaLabel,
  onClickChallenge,
  allowApprove,
  showApprovalDate,
}) {
  const [selectedChallengeIds, setSelectedChallengeIds] = useState([]);

  const handleSelect = (event, id) => {
    setSelectedChallengeIds((oldChallenges) =>
      event.target.checked
        ? [...oldChallenges, id]
        : oldChallenges.filter((x) => x !== id),
    );
  };

  const handleSelectAll = () => {
    if (selectedChallengeIds.length === 0) {
      const editabledSelectedChallengeIds =
        challengesRequiringApproval.filter(isChallengeEditable);
      const newSelectedChallengeIds = editabledSelectedChallengeIds.map(
        (x) => x.id,
      );
      setSelectedChallengeIds(newSelectedChallengeIds);
    } else {
      setSelectedChallengeIds([]);
    }
  };

  const clearSelectedChallengeIds = () => {
    setSelectedChallengeIds([]);
  };

  return (
    <div>
      <ChallengesTable
        challenges={challengesRequiringApproval}
        selectable
        selectedIds={selectedChallengeIds}
        onSelect={handleSelect}
        onToggleSelectAll={handleSelectAll}
        ariaLabel={ariaLabel}
        onClickChallenge={onClickChallenge}
        showApprovalDate={showApprovalDate}
      />

      <ChallengesActionBar
        selectedChallengeIds={selectedChallengeIds}
        clearSelectedChallengeIds={clearSelectedChallengeIds}
        allowApprove={allowApprove}
      />
    </div>
  );
}

export default ChallengesTableSupportingUpdates;

ChallengesTableSupportingUpdates.propTypes = {
  challengesRequiringApproval: PropTypes.arrayOf(challengeShape).isRequired,
  ariaLabel: PropTypes.string,
  onClickChallenge: PropTypes.func,
  allowApprove: PropTypes.bool,
  showApprovalDate: PropTypes.bool,
};
ChallengesTableSupportingUpdates.defaultProps = {
  onClickChallenge: undefined,
  ariaLabel: undefined,
  allowApprove: false,
  showApprovalDate: false,
};
