import { isBefore, isValid, parse } from 'date-fns';
import { Typography } from '@mui/material';

import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';
import useDate from '@/hooks/useDate';
import {
  TOC_ED_FOLLOW_UP_FMC,
  TOC_ED_FOLLOW_UP_FUA,
  TOC_ED_FOLLOW_UP_FUM,
  TOC_SCHEDULE_VISIT,
} from '@/pages/TaskBasedWorkflow/constants';

export default function TaskScheduledVisit({ task }) {
  const now = useDate();
  if (task.scheduledVisit) {
    const appointmentDate = parse(task.scheduledVisit, 'MM-dd-yyyy', now);

    const shouldBoldDate =
      isValid(appointmentDate) && isBefore(appointmentDate, now);
    return (
      <Typography
        component="span"
        variant={shouldBoldDate ? 'bodymediumbold' : 'bodymedium'}
      >
        {task.scheduledVisit}
      </Typography>
    );
  }
  const scheduleableTocSubtypes = [
    TOC_SCHEDULE_VISIT,
    TOC_ED_FOLLOW_UP_FMC,
    TOC_ED_FOLLOW_UP_FUM,
    TOC_ED_FOLLOW_UP_FUA,
  ];
  if (task.action && scheduleableTocSubtypes.includes(task.action.subtype)) {
    return 'Not scheduled';
  }
  return '--';
}

TaskScheduledVisit.propTypes = {
  task: userInputTaskShape.isRequired,
};
