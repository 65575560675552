import { Button, Stack } from '@mui/material';
import { bool, func } from 'prop-types';

export default function DiagnosisActionFormControls({
  disabled,
  loading,
  onSubmit,
  onCancel,
}) {
  return (
    <Stack direction="row-reverse" justifyContent="flex-end" gap={2} pb={1}>
      <Button
        type="submit"
        onSubmit={onSubmit}
        disabled={disabled || loading}
        data-pendo-id="compact-patient-summary-diagnosis-action-submit"
      >
        {loading ? 'Saving...' : 'Confirm'}
      </Button>
      <Button variant="outlined" onClick={onCancel} disabled={loading}>
        Cancel
      </Button>
    </Stack>
  );
}
DiagnosisActionFormControls.propTypes = {
  disabled: bool,
  loading: bool,
  onSubmit: func,
  onCancel: func,
};
DiagnosisActionFormControls.defaultProps = {
  disabled: false,
  loading: false,
  onSubmit: undefined,
  onCancel: undefined,
};
