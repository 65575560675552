import { Typography, styled } from '@mui/material';

import { patientShape } from '@/utils/transformFrontendPatient';

/**
 * Display the patient's name and date of birth.
 */
export default function PatientNameDob({ patient }) {
  return (
    <>
      <Name data-dd-action-name="Patient Name">
        {patient.lastName}, {patient.firstName}
      </Name>
      <br />
      <Typography
        component="span"
        variant="bodysmall"
        color="text.secondary"
        data-dd-action-name="Date Of Birth"
      >
        {patient.dateOfBirth}
      </Typography>
    </>
  );
}

PatientNameDob.propTypes = {
  patient: patientShape.isRequired,
};

const Name = styled('span')`
  display: inline-block; // inline-block helps avoid the text wrapping between the first and last name
  margin-right: ${(p) => p.theme.spacing(1)};
`;
