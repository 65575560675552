import { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { SnackbarContent, Typography, useTheme } from '@mui/material';

const ERROR = 'error';
const SUCCESS = 'success';
const WARNING = 'warning';

function TransitionLeft(props) {
  // eslint-disable-next-line
  return <Slide {...props} direction="left" />;
}

function SnackbarNotification({ openSnackbar, variant, children }) {
  const theme = useTheme();

  const foregroundColor = () => {
    if (variant === SUCCESS) {
      return theme.palette.status.successForeground;
    }
    if (variant === ERROR) {
      return theme.palette.status.errorForeground;
    }
    if (variant === WARNING) {
      return theme.palette.status.warningForeground;
    }
    return theme.palette.status.noneForeground;
  };

  const backgroundColor = () => {
    if (variant === SUCCESS) {
      return theme.palette.status.successBackground;
    }
    if (variant === ERROR) {
      return theme.palette.status.errorBackground;
    }
    if (variant === WARNING) {
      return theme.palette.status.warningBackground;
    }
    return theme.palette.status.noneBackground;
  };

  const headerMessage = () => {
    if (variant === SUCCESS) {
      return 'Success!';
    }
    if (variant === ERROR) {
      return 'Error!';
    }
    if (variant === WARNING) {
      return 'Warning!';
    }
    return '';
  };

  const [isOpen, setIsOpen] = useState(openSnackbar);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  const actionSnackbar = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseSnackbar}
    >
      <CloseIcon width="16px" height="16px" sx={{ color: foregroundColor() }} />
    </IconButton>
  );

  const message = (
    <>
      <Typography sx={{ color: foregroundColor() }} variant="h4">
        {headerMessage()}
      </Typography>
      {children}
    </>
  );

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={TransitionLeft}
    >
      <SnackbarContent
        style={{
          backgroundColor: backgroundColor(),
        }}
        message={message}
        action={actionSnackbar}
      />
    </Snackbar>
  );
}

SnackbarNotification.propTypes = {
  openSnackbar: PropTypes.bool,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([SUCCESS, ERROR, WARNING]),
};

SnackbarNotification.defaultProps = {
  openSnackbar: false,
  variant: SUCCESS,
};

export default SnackbarNotification;
