import PropTypes from 'prop-types';

const idMapping = {
  schedule_visit: {
    scheduled_date: 'worklist-schedule-step-panel-new-visit-date',
    servicing_provider_id: 'worklist-schedule-step-panel-provider',
    office_id: 'worklist-schedule-step-panel-office',
    notes: 'worklist-schedule-step-panel-notes',
  },
  reschedule_visit: {
    scheduled_date: 'worklist-reschedule-step-panel-new-visit-date',
    servicing_provider_id: 'worklist-reschedule-step-panel-provider',
    office_id: 'worklist-reschedule-step-panel-office',
    notes: 'worklist-reschedule-step-panel-notes',
  },
  need_to_reschedule: {
    notes: 'worklist-need-to-reschedule-step-panel-notes',
  },
  complete_visit: {
    completed_date: 'worklist-complete-step-panel-new-visit-date',
    servicing_provider_id: 'worklist-complete-step-panel-provider',
    office_id: 'worklist-complete-step-panel-office',
    notes: 'worklist-complete-step-panel-notes',
  },
};

export default function getStepExecutionFieldPendoId({
  templateStep,
  requirementName,
}) {
  return idMapping[templateStep]?.[requirementName];
}

getStepExecutionFieldPendoId.propTypes = {
  requirementName: PropTypes.string.isRequired,
  templateStep: PropTypes.string.isRequired,
};
