import { isEmpty } from 'lodash';

const METADATA = 'metadata';
const PATIENTS = 'patients';
const TASKS = 'tasks';
const PATIENT_TASKS = 'patient_tasks';

const queryKeyFactory = {
  metadata: (page) => [METADATA, page],
  patientsSearch: (params) => {
    if (params) {
      return [PATIENTS, 'search', params];
    }
    return [PATIENTS];
  },
  patient: ({ patientId, ...otherParams }) => {
    if (patientId && !isEmpty(otherParams)) {
      return [PATIENT_TASKS, patientId, otherParams];
    }
    if (patientId) {
      return [PATIENT_TASKS, patientId];
    }
    return [PATIENT_TASKS];
  },
  patientProgramEnrollments: ({ patientId }) => {
    return [PATIENTS, patientId, 'patient-program-enrollments'];
  },
  patientSvuSummaries: (params) => {
    if (params) {
      return [PATIENTS, 'svu-summaries', params];
    }
    return [PATIENTS];
  },
  prescriptionHistory: ({ patientId, actionId }) => {
    return ['prescription-history', { patientId, actionId }];
  },
  adtEventHistory: ({ actionId }) => {
    return ['adt-event-history', { actionId }];
  },
  logOutreach: (patientId) => {
    return ['log-outreach', { patientId }];
  },
  taskCounts: (params) => {
    if (params) {
      return [TASKS, 'counts', params];
    }
    return [TASKS, 'counts'];
  },
  tasksSearch: (params) => {
    if (params) {
      return [TASKS, 'search', params];
    }
    return [TASKS, 'search'];
  },
  taskActionsContext: ({ actionId }) => {
    return [TASKS, 'contextual-info', { actionId }];
  },
  completedTasks: (params) => {
    if (params) {
      return [TASKS, 'completed', params];
    }
    return [TASKS, 'completed'];
  },
  patientExperienceLandingData: (params) => {
    return ['patient-experience-landing-data', params];
  },
};

export default queryKeyFactory;
