import Box from '@mui/material/Box';
import { string } from 'prop-types';
import Typography from '@mui/material/Typography';

export default function Instruction({ imageSrc, text, listNumber }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '304px',
      }}
    >
      <img src={imageSrc} width="264px" height="264px" alt={text} />
      <Box
        sx={{
          width: '264px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Typography variant="bodybold" sx={{ mr: 1 }}>
          {listNumber}.
        </Typography>
        <Typography variant="bodybold">{text}</Typography>
      </Box>
    </Box>
  );
}

Instruction.propTypes = {
  imageSrc: string.isRequired,
  text: string.isRequired,
  listNumber: string.isRequired,
};
