import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Radio,
  css,
  radioClasses,
  styled,
} from '@mui/material';
import { useState } from 'react';

/**
 * BaseRadioButton is a complete form control including a label and input.
 *
 * You might want to use the BaseRadioGroup component instead which renders multiple radio buttons
 * and handles all the accessibility logic (e.g. keyboard shortcuts) for you.
 *
 * Documentation: https://mui.com/components/radio-buttons
 */
function BaseRadioButton({
  checked,
  label,
  className,
  disabled,
  id,
  name,
  onChange,
  sx,
  value,
  'data-pendo-id': dataPendoId,

  // ⬇ themeV2 props only ⬇
  relaxed,
  highEmphasis,
}) {
  const [focusVisible, setFocusVisible] = useState(false);
  const handleFocusVisible = () => {
    setFocusVisible(true);
  };
  const handleBlur = () => {
    setFocusVisible(false);
  };
  return (
    <StyledFormControlLabel
      $focusVisible={focusVisible}
      $relaxed={relaxed}
      className={className}
      control={
        <StyledRadio
          $highEmphasis={highEmphasis}
          checked={checked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          onFocusVisible={handleFocusVisible}
          onBlur={handleBlur}
          value={value}
        />
      }
      label={label}
      sx={sx}
      value={value}
      data-pendo-id={dataPendoId}
    />
  );
}

BaseRadioButton.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  'data-pendo-id': PropTypes.string,
  relaxed: PropTypes.bool,
  highEmphasis: PropTypes.bool,
};

BaseRadioButton.defaultProps = {
  checked: undefined,
  className: undefined,
  disabled: undefined,
  id: undefined,
  name: undefined,
  onChange: undefined,
  sx: undefined,
  value: undefined,
  'data-pendo-id': undefined,
  relaxed: false,
  highEmphasis: false,
};

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: auto; // Prevents the focus outline from spanning the entire row

  ${(p) =>
    p.$focusVisible &&
    css`
      box-shadow: 0 0 0 2px ${p.theme.palette.focus.border};
      border-radius: 12px;
    `}

  ${(p) =>
    p.$relaxed &&
    css`
      &:not(:first-of-type) {
        margin-top: 16px;
      }
    `}
`;

const StyledRadio = styled(Radio)`
  ${(p) =>
    p.$highEmphasis &&
    css`
      color: ${p.theme.palette.background.primary};

      &.${radioClasses.checked} {
        color: ${p.theme.palette.background.primary};
      }
    `}
`;

export default BaseRadioButton;
