import { createContext, useContext } from 'react';

/**
 * Gets the CSRF token from a hidden input rendered on the page by the server.
 *
 * @returns {*|string}
 */
function defaultCsrfTokenGetter() {
  return document.querySelector('[name=csrfmiddlewaretoken]')?.value ?? '';
}

const CsrfContext = createContext(defaultCsrfTokenGetter);

export const CsrfContextProvider = CsrfContext.Provider;

/**
 * Returns the CSRF token for submitting form-based actions. This value can
 * be overridden using the CsrfContextProvider.
 *
 * @returns {string}
 */
export default function useCsrfToken() {
  const getCsrfToken = useContext(CsrfContext);
  return getCsrfToken();
}
