import { format } from 'date-fns';

/**
 * Takes any datetime string and returns it in MM/DD/YYYY HH:mm format
 * @param {string} datetime
 */
function formatDateTime(datetime?: string | null): string {
  if (!datetime) return '';

  const dt = new Date(Date.parse(datetime));
  return format(dt, 'MM/dd/yyyy HH:mm');
}

/**
 * Takes any datetime string and returns it in MM/DD/YYYY HH:mm:ss format
 * @param datetime
 * @returns {string}
 */
function formatDateTimeSeconds(datetime?: string | null): string {
  if (!datetime) return '';

  const dt = new Date(Date.parse(datetime));
  return format(dt, 'MM/dd/yyyy HH:mm:ss');
}

export { formatDateTime, formatDateTimeSeconds };
