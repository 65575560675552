import { Fragment } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { arrayOf, bool, exact, func, node, number, object } from 'prop-types';
import { isEmpty } from 'lodash';

import { color, spacing, typography } from '@/utils/stylingUtils';
import { reactQueryStatusValues } from '@/utils/shapes';
import TableCard from '@/components/TableCard';

import CompactPatientSummarySection from '../CompactPatientSummarySection';
import SvuCell from '../SvuCell';
import CompletedDiagnosisStatusCell from '../CompletedDiagnosisStatusCell';

import SuggestedDiagnosisActionForm from './SuggestedDiagnosisActionForm';

const COLUMN_DEFINITION_INCOMPLETE = [
  {
    id: 'condition',
    label: 'Condition',
    renderCell: ({ data: action }) => (
      <Typography component="h4">{action.hcc.name}</Typography>
    ),
  },
  {
    id: 'clinicalEvidence',
    label: 'Supporting Evidence',
    renderCell: ({ data: action }) => {
      return action.clinicalEvidence.map((evidence) => {
        return (
          <Fragment key={`${evidence.icd}-${evidence.assessedOn}`}>
            <Typography variant="bodysmall">{evidence.evidence}</Typography>
            {evidence.icd && (
              <Typography variant="bodysmall">{`Code: ${evidence.icd}`}</Typography>
            )}
          </Fragment>
        );
      });
    },
  },
];

const ROW_DEFINITION_INCOMPLETE = {
  rowId: (action) => action.id,
  renderActions: ({
    additionalProps: {
      onSaveDiagnosisAction,
      defaultProviderChoiceId,
      saveStatus,
      isSubmitDisabled,
    },
    data: action,
    open,
    setOpen,
  }) => {
    return (
      <SuggestedDiagnosisActionForm
        defaultProviderChoiceId={defaultProviderChoiceId}
        diagnosisAction={action}
        onSaveDiagnosisAction={onSaveDiagnosisAction}
        open={open}
        saveStatus={saveStatus}
        setOpen={setOpen}
        isSubmitDisabled={isSubmitDisabled}
      />
    );
  },
  renderRowBadge: ({ data }) => <SvuCell action={data} />,
};

const COLUMN_DEFINITION_COMPLETE = [
  {
    id: 'condition',
    label: 'Condition',
    renderCell: ({ data: action }) => (
      <Typography component="h4">{action.hcc.name}</Typography>
    ),
  },
  {
    id: 'clinicalEvidence',
    label: 'Status',
    renderCell: ({ data: action }) => {
      return <CompletedDiagnosisStatusCell action={action} />;
    },
  },
];

const ROW_DEFINITION_COMPLETE = {
  rowId: (action) => action.id,
  renderRowBadge: ({ data }) => <SvuCell action={data} complete />,
};

/**
 * @param {{ incomplete: Action[], complete: Action[] }} actions
 * @param {number?} defaultProviderChoiceId
 * @param {() => Promise<unknown>?} onSaveDiagnosisAction
 * @param {(section: string) => void?} onSetSelected
 * @param {Patient} patient
 * @param {'idle' | 'loading' | 'error' | 'success'} saveStatus
 * @param {boolean?} selected
 * @param {number?} svus
 * @returns {JSX.Element|null}
 */
export default function SuggestedDiagnosisSection({
  actions,
  defaultProviderChoiceId,
  onSaveDiagnosisAction,
  onSetSelected,
  saveStatus,
  selected,
  svus,
  aboveBodySlot,
  isSubmitDisabled,
}) {
  const hasIncompleteActions = !isEmpty(actions.incomplete);
  const hasCompletedActions = !isEmpty(actions.complete);

  // If there are no incomplete or complete actions, render nothing.
  if (!hasIncompleteActions && !hasCompletedActions) return null;

  return (
    <CompactPatientSummarySection
      headline="Suspected Diagnoses"
      onSetSelected={onSetSelected}
      selected={selected}
      svus={svus}
      aboveBodySlot={
        aboveBodySlot ? <Box sx={{ pl: 1 }}>{aboveBodySlot}</Box> : null
      }
    >
      {hasIncompleteActions ? (
        <TableCard
          additionalProps={{
            onSaveDiagnosisAction,
            defaultProviderChoiceId,
            saveStatus,
            isSubmitDisabled,
          }}
          data={actions.incomplete}
          cardBreakpoint={500}
          rowDefinition={ROW_DEFINITION_INCOMPLETE}
          columnDefinition={COLUMN_DEFINITION_INCOMPLETE}
        />
      ) : null}
      {hasCompletedActions ? (
        <>
          <StyledCompletedLabel>
            Completed ({actions.complete.length})
          </StyledCompletedLabel>
          <Typography component="span" variant="bodysmall" sx={{ pl: 1 }}>
            Some of the completed measures below may earn additional SVUs once a
            claim confirms completion.
          </Typography>
          <TableCard
            data={actions.complete}
            cardBreakpoint={500}
            rowDefinition={ROW_DEFINITION_COMPLETE}
            columnDefinition={COLUMN_DEFINITION_COMPLETE}
          />
        </>
      ) : null}
    </CompactPatientSummarySection>
  );
}

SuggestedDiagnosisSection.propTypes = {
  actions: exact({
    complete: arrayOf(object.isRequired).isRequired,
    incomplete: arrayOf(object.isRequired).isRequired,
  }).isRequired,
  defaultProviderChoiceId: number,
  onSaveDiagnosisAction: func,
  onSetSelected: func,
  saveStatus: reactQueryStatusValues.isRequired,
  selected: bool,
  svus: number,
  aboveBodySlot: node,
  isSubmitDisabled: bool,
};
SuggestedDiagnosisSection.defaultProps = {
  defaultProviderChoiceId: undefined,
  onSaveDiagnosisAction: undefined,
  onSetSelected: undefined,
  selected: false,
  svus: undefined,
  aboveBodySlot: undefined,
  isSubmitDisabled: false,
};

const StyledCompletedLabel = styled(Typography)`
  color: ${color('status.successForeground')};
  padding: ${spacing(1)};
  ${typography('bodybold')}
`;
