import { FormLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import useId from '@mui/utils/useId';
import PropTypes from 'prop-types';

import FormErrorMessage from '@/components/FormErrorMessage';
import RhfInput from '@/components/reactHookFormComponents/RhfInput';
import Spinner from '@/pages/PaymentInstructions/components/Spinner';

/* eslint-disable-next-line */
const EMAIL_REGEX = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;

const INVALID_EMAIL_MESSAGE = 'Email has an invalid format.';

export default function Electronic1099Form({
  isLoadingAgreement,
  control,
  emailFor1099InputName,
  formErrors,
  agreement,
  electronic1099SignatureName,
}) {
  const electronic1099SignatureId = useId();

  const emailPatternRule = {
    pattern: {
      value: EMAIL_REGEX,
      message: INVALID_EMAIL_MESSAGE,
    },
  };

  const emailFor1099Id = useId();

  if (isLoadingAgreement) {
    return <Spinner size={20} mt={2} />;
  }

  return (
    <>
      <Box
        sx={{
          p: 1,
          border: '1px solid',
          width: '480px',
          borderColor: 'border.base',
          borderRadius: '8px',
          mt: 2,
        }}
      >
        <Typography variant="h2">{agreement.name}</Typography>
        <AgreementContainer>
          <div
            /* We're using dangerouslySetInnerHTML here because the agreement text is HTML created within Django Admin. */
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: agreement.text.trim(),
            }}
          />
        </AgreementContainer>
      </Box>
      <InputContainer>
        <FormLabel htmlFor={electronic1099SignatureId}>
          Print name for consent to electronic 1099
        </FormLabel>
        <RhfInput
          name={electronic1099SignatureName}
          id={electronic1099SignatureId}
          control={control}
          rules={{
            required: true,
          }}
        />
      </InputContainer>
      <InputContainer>
        <FormLabel htmlFor={emailFor1099Id}>
          Email address to which Stellar Health should send your 1099
        </FormLabel>
        <RhfInput
          name={emailFor1099InputName}
          id={emailFor1099Id}
          control={control}
          rules={{ ...emailPatternRule, required: true }}
        />
        <FormErrorMessage errors={formErrors} name={emailFor1099InputName} />
      </InputContainer>
    </>
  );
}

const AgreementContainer = styled(Box)`
  max-width: 480px;
  width: 100%;
  max-height: 480px;
  overflow: auto;
  margin-top: 8px;
`;

const InputContainer = styled(Box)`
  max-width: 480px;
  width: 100%;
`;

Electronic1099Form.propTypes = {
  formErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  emailFor1099InputName: PropTypes.string.isRequired,
  electronic1099SignatureName: PropTypes.string.isRequired,
  isLoadingAgreement: PropTypes.bool,
  agreement: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    version: PropTypes.number,
    text: PropTypes.string,
  }),
};

Electronic1099Form.defaultProps = {
  formErrors: undefined,
  isLoadingAgreement: false,
  agreement: undefined,
};
