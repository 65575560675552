/* eslint-disable no-shadow */

import { Fragment, useState } from 'react';
import { any, bool } from 'prop-types';
import { Box, css, styled } from '@mui/material';

import { color, spacing } from '@/utils/stylingUtils';

import { columnDefinition, rowDefinition } from './propTypes';
import TableCardLabel from './TableCardLabel';

const StyledTableCardRow = styled(Box)`
  display: ${({ narrow }) => (narrow ? 'block' : 'contents')};
`;

const StyledTableCardSubRow = styled(Box)`
  display: ${({ narrow }) => (narrow ? 'block' : 'contents')};
  position: relative;
`;

const infoCellNarrowPadding = css`
  ${spacing(0)} ${spacing(1)} ${spacing(1)}
`;
const StyledInfoCell = styled(Box)`
  padding: ${({ narrow }) => (narrow ? infoCellNarrowPadding : spacing(1))};
`;

const StyledActionsCell = styled(Box)`
  background-color: ${color('background.secondary')};
  grid-column-start: start;
  grid-column-end: end;
  padding: ${spacing(1)};

  // If the action cell contains no DOM nodes, hide it rather than show a
  // tiny padded box with background.secondary background color.
  &:empty {
    display: none;
  }
`;

const ruleTableStyles = css`
  grid-column-start: start;
  grid-column-end: end;
`;

const StyledRule = styled(Box)`
  ${({ narrow }) => (narrow ? {} : ruleTableStyles)}
  border-bottom: 1px solid ${color('border.base')};

  &:last-child {
    display: none;
  }
`;

const badgeCardStyles = css`
  position: absolute;
  top: 0;
  right: ${spacing(1)};
`;

const badgeTableStyles = css`
  padding: ${spacing(1)};
`;

const StyledBadgeCell = styled(Box)`
  ${({ narrow }) => (narrow ? badgeCardStyles : badgeTableStyles)}
`;

export default function TableCardRow({
  additionalProps,
  columnDefinition,
  data,
  hasBadges,
  narrow,
  rowDefinition,
}) {
  const [open, setOpen] = useState(false);

  const getKey = (data, columnDefinition) =>
    `${rowDefinition.rowId(data)}__${columnDefinition.id}`;
  const actions = rowDefinition.renderActions?.({
    additionalProps,
    narrow,
    data,
    open,
    setOpen,
  });
  const badge = rowDefinition.renderRowBadge
    ? rowDefinition.renderRowBadge({
        additionalProps,
        narrow,
        data,
        open,
        setOpen,
      })
    : null;

  return (
    <>
      <StyledTableCardRow role={narrow ? 'listitem' : null} narrow={narrow}>
        <StyledTableCardSubRow narrow={narrow} role={narrow ? null : 'row'}>
          {columnDefinition.map((cd) => {
            return (
              <Fragment key={getKey(data, cd)}>
                {narrow ? (
                  <TableCardLabel narrow columnDefinition={cd}>
                    {cd.label}
                  </TableCardLabel>
                ) : null}
                <StyledInfoCell role={narrow ? null : 'cell'} narrow={narrow}>
                  {cd.renderCell({
                    additionalProps,
                    narrow,
                    data,
                    open,
                    setOpen,
                  })}
                </StyledInfoCell>
              </Fragment>
            );
          })}
          {hasBadges ? (
            <StyledBadgeCell narrow={narrow}>{badge}</StyledBadgeCell>
          ) : null}
        </StyledTableCardSubRow>
        {actions ? (
          <StyledTableCardSubRow narrow={narrow} role={narrow ? null : 'row'}>
            <StyledActionsCell
              role="cell"
              aria-colspan={columnDefinition.length}
            >
              {actions}
            </StyledActionsCell>
          </StyledTableCardSubRow>
        ) : null}
      </StyledTableCardRow>
      <StyledRule aria-hidden narrow={narrow} />
    </>
  );
}
TableCardRow.propTypes = {
  additionalProps: any,
  columnDefinition: columnDefinition.isRequired,
  data: any.isRequired,
  hasBadges: bool.isRequired,
  narrow: bool.isRequired,
  rowDefinition: rowDefinition.isRequired,
};
TableCardRow.defaultProps = {
  additionalProps: null,
};
