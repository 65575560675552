import { TableCell, TableRow, Typography } from '@mui/material';
import { format as formatDate, parseISO } from 'date-fns';
import { startCase } from 'lodash';
import { Fragment } from 'react';

import {
  careGapActionShape,
  stepHistoryShape,
} from '@/utils/transformFrontendCareGapAction';
import { DISPLAY_DATE_FORMAT } from '@/pages/PatientSummary/utils/constants';
import ActionProgressV2 from '@/pages/PatientSummary/components/SectionCareGaps/ActionProgressV2';
import SvuAmount from '@/components/SvuAmount';
import useFeatureFlag from '@/utils/useFeatureFlag';
import { latestExternalCareGapShape } from '@/utils/transformLatestExternalCareGap';

import CptPopover from './CptPopover';

export function CompletedSubtext({ lastHistory, qualityEvidence }) {
  const qualityEvidenceFlagged = useFeatureFlag('showEhrQualityEvidence')
    ? qualityEvidence
    : null;

  if (
    !lastHistory.userFullName &&
    !lastHistory.executedAt &&
    !qualityEvidenceFlagged
  ) {
    return null;
  }
  const cptCodesArray = qualityEvidenceFlagged?.cptCodes?.split(' ');
  return (
    <>
      Data entered
      {lastHistory.userFullName ? ` by ${lastHistory.userFullName}` : null}
      {lastHistory.executedAt ? (
        <>
          {' '}
          on{' '}
          <Typography
            component="span"
            fontSize="inherit"
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {formatDate(
              Date.parse(lastHistory.executedAt),
              DISPLAY_DATE_FORMAT,
            )}
          </Typography>
        </>
      ) : null}
      {qualityEvidenceFlagged && cptCodesArray && (
        <>
          <br />
          <Typography component="span" variant="bodysmall">
            EHR status:
          </Typography>{' '}
          {cptCodesArray.map((code, idx) => (
            <Fragment key={code}>
              <CptPopover cptCode={code} cptText="Some placeholder text" />
              {idx < cptCodesArray.length - 1 ? ', ' : ' '}
            </Fragment>
          ))}
          <Typography variant="bodysmall" component="span">
            on{' '}
            {formatDate(
              parseISO(qualityEvidenceFlagged.dateOfService),
              DISPLAY_DATE_FORMAT,
            )}
          </Typography>
        </>
      )}
    </>
  );
}

CompletedSubtext.propTypes = {
  lastHistory: stepHistoryShape,
  qualityEvidence: latestExternalCareGapShape,
};

CompletedSubtext.defaultProps = {
  lastHistory: {},
  qualityEvidence: {},
};

function CompletedCareGapActionRowV2({ action }) {
  const lastHistory =
    action.stepHistories.length > 0
      ? action.stepHistories[action.stepHistories.length - 1]
      : {};
  return (
    <TableRow>
      <TableCell>
        {action.contextualInfo?.category || startCase(action.type)}
      </TableCell>
      <TableCell>{action.description}</TableCell>
      <TableCell>
        {action?.contextualInfo?.trigger || action?.triggerForDisplay}
      </TableCell>
      <TableCell />
      <TableCell align="center">
        <ActionProgressV2 action={action} />
      </TableCell>
      <TableCell align="center">
        <Typography
          sx={{
            '::first-letter': {
              textTransform: 'capitalize',
            },
          }}
        >
          {lastHistory.description || 'Completed'}
        </Typography>
        <Typography variant="subtext" component="span" color="text.primary">
          <CompletedSubtext lastHistory={lastHistory} />
        </Typography>
      </TableCell>
      <TableCell align="center">
        <SvuAmount
          svuPrePromo={Number(action.possibleSvusRemainingPrePromo)}
          svuPostPromo={Number(action.possibleSvusRemainingPostPromo)}
          sx={{ display: 'block' }}
          isComplete
        />
      </TableCell>
    </TableRow>
  );
}

export default CompletedCareGapActionRowV2;

CompletedCareGapActionRowV2.propTypes = {
  action: careGapActionShape,
};

CompletedCareGapActionRowV2.defaultProps = {
  action: undefined,
};
