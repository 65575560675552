import { bool, func } from 'prop-types';

import BaseLinkButton from '@/components/BaseLinkButton';

export default function ShowMoreButton({ isFetchingNextPage, onClick }) {
  return isFetchingNextPage ? (
    <span>Loading...</span>
  ) : (
    <BaseLinkButton onClick={onClick}>Show more</BaseLinkButton>
  );
}

ShowMoreButton.propTypes = {
  isFetchingNextPage: bool.isRequired,
  onClick: func.isRequired,
};
