import PropTypes, { node, string } from 'prop-types';
import { styled } from '@mui/material';
import useId from '@mui/utils/useId';

const StyledFieldset = styled('fieldset')`
  border: 0;
  padding: ${(p) => p.theme.spacing(1)} 0;
  border-top: 1px solid ${(p) => p.theme.palette.border.base};
  border-bottom: 1px solid ${(p) => p.theme.palette.border.base};
`;
const StyledFieldsetLabel = styled('p')`
  margin-top: 0;
  margin-bottom: 0;
`;
const StyledDescription = styled('p')`
  margin-top: ${(p) => p.theme.spacing(1)};
`;

/**
 * Generates a <fieldset> element that logically groups a set of form fields
 * together. It requires a label describing the grouping, which is displayed
 * above the fieldset.
 *
 * In addition, an optional description field can provide additional context
 * for the contained form fields. As of writing, the description is presented
 * below all the contained fields.
 */
export default function BaseFieldset({ label, children, description, sx }) {
  const fieldsetLabelId = useId();
  const fieldsetDescriptionId = useId();

  return (
    <StyledFieldset
      aria-labelledby={fieldsetLabelId}
      aria-describedby={fieldsetDescriptionId}
      sx={sx}
    >
      <StyledFieldsetLabel id={fieldsetLabelId}>{label}</StyledFieldsetLabel>
      {children}
      {description ? (
        <StyledDescription id={fieldsetDescriptionId}>
          {description}
        </StyledDescription>
      ) : null}
    </StyledFieldset>
  );
}

BaseFieldset.propTypes = {
  label: string.isRequired,
  children: node.isRequired,
  description: node,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

BaseFieldset.defaultProps = {
  description: '',
  sx: undefined,
};
