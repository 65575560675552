import { useController } from 'react-hook-form';
import useId from '@mui/utils/useId';
import styled from 'styled-components';
import { Alert, Button, Typography } from '@mui/material';

import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';
import { ReactComponent as CheckFalseIcon } from '@/assets/icons/checkFalse.svg';

import DynamicFormLabel from '../DynamicFormLabel';

/**
 * A field for uploading a file. Does not support multiple files right now.
 */
export default function DynamicFormFileUpload({ field }) {
  const { label, name, disabled } = field;

  const controller = useController({
    name,
    rules: {
      required: 'Please provide a file',
    },
  });
  const fieldId = useId();

  const onChange = (event) => {
    controller.field.onChange(event.target.files[0]);
  };

  const isFileSelected = controller.field.value;

  return (
    <DynamicFormLabel htmlFor={fieldId}>
      <input
        type="file"
        accept=".jpg,.jpeg,.pdf,.png,.tif,.tiff,.doc,.docx,.xls,.xlsx"
        id={fieldId}
        multiple={false}
        onChange={onChange}
        disabled={disabled}
        hidden
      />
      {isFileSelected ? (
        <>
          <DynamicFormLabel>File selected</DynamicFormLabel>
          <StyledLink>{controller.field.value.name}</StyledLink>
          <StyledAlert icon={<CheckFalseIcon />}>
            File successfully uploaded. Press &quot;Confirm&quot; to submit the
            file, or click on the file name to select another file.
          </StyledAlert>
        </>
      ) : (
        <StyledButton variant="contained" component="span" disabled={disabled}>
          {label || 'Upload'}
        </StyledButton>
      )}
    </DynamicFormLabel>
  );
}

DynamicFormFileUpload.propTypes = {
  field: executionRequirementShape.isRequired,
};

const StyledButton = styled(Button)`
  background-color: ${(p) => p.theme.palette.background.primary};
  border-color: ${(p) => p.theme.palette.background.primary};
  color: ${(p) => p.theme.palette.background.primaryInverse};

  &:hover {
    background-color: ${(p) => p.theme.palette.background.primaryDarker};
    border-color: ${(p) => p.theme.palette.background.primaryDarker};
    color: ${(p) => p.theme.palette.background.primaryInverse};
  }
`;

const StyledAlert = styled(Alert)`
  border: 1px solid;
  color: ${(p) => p.theme.palette.status.successForeground};
  border-color: ${(p) => p.theme.palette.status.successForeground};
  background-color: ${(p) => p.theme.palette.status.successBackground};
  padding: ${(p) => p.theme.spacing(1, 2)};
  margin-top: ${(p) => p.theme.spacing(2)};
  line-height: 1.5rem;

  a {
    color: ${(p) => p.theme.palette.status.successForeground};
  }
`;

const StyledLink = styled(Typography)`
  color: ${(p) => p.theme.palette.text.link};
`;
