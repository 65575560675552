import type { SxProps } from '@mui/material';

/**
 * Concatenates multiple sx values into a single sx value.
 */
export default function concatSx(...args: Array<SxProps | undefined>): SxProps {
  const output: SxProps = [];

  args.forEach((sx) => {
    if (sx) {
      output.push(...(Array.isArray(sx) ? sx : [sx]));
    }
  });

  return output;
}
