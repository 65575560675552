import { Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import AlertTitle from '@mui/material/AlertTitle';

import { actionShape } from '@/utils/transformFrontendAction';

const FOLLOW_UP = 'follow_up';

export default function ProgramEnrollmentFollowUpInformationBanner({ action }) {
  if (!action.v2Dto.contextualInfo.enrollmentDaysAgo) {
    return null;
  }

  // We only want to show the banner if the next preferred step is follow up
  if (
    action.v2Dto.preferredPath.length === 0 ||
    action.v2Dto.preferredPath[0].templateStep !== FOLLOW_UP
  ) {
    return null;
  }

  // We want to remove the banner after 90 days
  if (action.v2Dto.contextualInfo.enrollmentDaysAgo > 90) {
    return null;
  }

  return (
    <Alert severity="info" sx={{ mt: 1 }}>
      <AlertTitle>
        Patient was enrolled {action.v2Dto.contextualInfo.enrollmentDaysAgo}{' '}
        days ago.
      </AlertTitle>
      <Typography variant="bodymedium">
        We recommend giving the patient some time to experience the program
        before checking in on their progress.
      </Typography>
    </Alert>
  );
}

ProgramEnrollmentFollowUpInformationBanner.propTypes = {
  action: actionShape.isRequired,
};
