import { bool, number, shape, string } from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { useIntersection } from 'react-use';
import { useRef } from 'react';

import { patientShape } from '@/utils/transformFrontendPatient';
import logo from '@/assets/stellarHealthLogo.svg';

import SvuDisplay from './SvuDisplay';

const intersectionOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 1,
};

function EhrRibbon({ user }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
        height: '32px',
        borderBottom: '1px solid',
        borderColor: 'border.base',
      }}
    >
      <img src={logo} alt="Stellar Health" height="32px" />
      <Typography variant="bodysmall">{user.name}</Typography>
    </Box>
  );
}
EhrRibbon.propTypes = {
  user: shape({ name: string.isRequired }).isRequired,
};

/**
 * @param {Patient} patient
 * @param {{ id: number, name: string }} user
 * @param {number?} incompleteSvus
 * @param {number?} completeSvus
 * @param {boolean} showEhrRibbon
 * @returns {JSX.Element}
 */
function CompactPatientDetails({
  completeSvus,
  incompleteSvus,
  patient,
  showEhrRibbon,
  user,
}) {
  const headerRef = useRef(null);
  const headerIntersection = useIntersection(headerRef, intersectionOptions);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.secondary',
          boxShadow: headerIntersection?.intersectionRatio < 1 ? 2 : 0,
          p: 1,
          position: 'sticky',
          top: -1,
          transition: 'box-shadow 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          zIndex: 1,
        }}
        ref={headerRef}
      >
        {showEhrRibbon ? <EhrRibbon user={user} /> : null}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ mr: 2, whiteSpace: 'nowrap' }} variant="h3">
              {patient.firstName} {patient.lastName}
            </Typography>
            <Typography variant="bodysmall" sx={{ color: 'text.secondary' }}>
              DOB: {patient.dateOfBirth}
            </Typography>
          </Box>
          <SvuDisplay
            incompleteSvus={incompleteSvus}
            completeSvus={completeSvus}
          />
        </Box>
      </Box>

      <Box sx={{ bgcolor: 'background.secondary', mx: 1 }}>
        {patient.tags.length > 0 ? (
          <Stack direction="row" flexWrap="wrap" py={1} gap={0.5}>
            {patient.tags.map((tag) => (
              <Chip key={tag}>{tag}</Chip>
            ))}
          </Stack>
        ) : null}
      </Box>
    </>
  );
}

CompactPatientDetails.propTypes = {
  completeSvus: number,
  incompleteSvus: number,
  patient: patientShape.isRequired,
  showEhrRibbon: bool.isRequired,
  user: shape({
    id: number,
    name: string,
  }).isRequired,
};
CompactPatientDetails.defaultProps = {
  completeSvus: undefined,
  incompleteSvus: undefined,
};

const Chip = styled.div`
  border: 1px solid ${(p) => p.theme.palette.border.base};
  border-radius: 6px;
  padding: 3px 12px;
  white-space: nowrap;
  font-size: 12px;
`;

export default CompactPatientDetails;
