import { Box, Stack, styled } from '@mui/material';
import { any, arrayOf, func, number, object } from 'prop-types';

import { color, spacing } from '@/utils/stylingUtils';
import ErrorBoundary from '@/components/ErrorBoundary';

/**
 * The MasterDetailView is an implementation of the Master-Detail view pattern
 * described by <https://blogs.windows.com/windowsdeveloper/2017/05/01/master-master-detail-pattern/>.
 *
 * This pattern is defined by a "master" view, which is usually a list of
 * abbreviated entities, where selecting one of those list items shows that
 * entity in much more detail in the "detail" view.
 *
 * This component does not define a "mobile" or narrow view, which typically
 * separates the master and detail views into two separate views, rather than
 * present them side-by-side. That would be a useful enhancement in the future.
 *
 * @template D
 * @template A
 * @param {A?} additionalProps
 * @param {D[]} data
 * @param {(({ data: D, additionalProps: A }) => void)?} getRowkey
 * @param {({ data: D, additionalProps: A }) => void} onSelectRow
 * @param {({ data: D, additionalProps: A }) => void} renderDetailView
 * @param {(({ additionalProps: A }) => React.ReactNode)?} renderEmptyView
 * @param {(data: D) => React.ReactNode} renderMasterRow
 * @param {(({ data: D[], additionalProps: A }) => React.ReactNode)?} renderMasterFooter
 * @param {(({ data: D[], additionalProps: A }) => React.ReactNode)?} renderMasterHeader
 * @param {D?} selectedRow
 * @param {number?} sidebarWidth
 */
export default function MasterDetailView({
  additionalProps,
  data,
  getRowKey,
  onSelectRow,
  renderDetailView,
  renderEmptyView,
  renderMasterRow,
  renderMasterHeader,
  renderMasterFooter,
  selectedRow,
  sidebarWidth,
}) {
  const body = selectedRow
    ? renderDetailView({ data: selectedRow, additionalProps })
    : renderEmptyView({ additionalProps });
  const selectedKey = selectedRow
    ? getRowKey({ data: selectedRow, additionalProps })
    : null;
  const headerNode = renderMasterHeader({ data, additionalProps });
  const footerNode = renderMasterFooter({ data, additionalProps });

  return (
    <StyledMasterDetailView direction="row">
      <StyledMasterColumn width={sidebarWidth}>
        <StyledMasterColumnHeader>{headerNode}</StyledMasterColumnHeader>
        <StyledMasterList role="list">
          {data.map((d) => {
            const key = getRowKey({ data: d, additionalProps });
            return (
              <StyledMasterListItem
                role="listitem"
                key={key}
                onClick={() => onSelectRow({ data: d, additionalProps })}
                selected={key === selectedKey}
              >
                {renderMasterRow({ data: d, additionalProps })}
              </StyledMasterListItem>
            );
          })}
        </StyledMasterList>
        <StyledMasterColumnFooter>{footerNode}</StyledMasterColumnFooter>
      </StyledMasterColumn>
      <StyledDetailView>
        <ErrorBoundary>{body}</ErrorBoundary>
      </StyledDetailView>
    </StyledMasterDetailView>
  );
}
MasterDetailView.propTypes = {
  additionalProps: object,
  getRowKey: func.isRequired,
  data: arrayOf(any).isRequired,
  onSelectRow: func.isRequired,
  renderDetailView: func.isRequired,
  renderEmptyView: func,
  renderMasterRow: func.isRequired,
  renderMasterFooter: func,
  renderMasterHeader: func,
  selectedRow: any,
  sidebarWidth: number,
};
MasterDetailView.defaultProps = {
  additionalProps: undefined,
  renderEmptyView: () => null,
  renderMasterFooter: () => null,
  renderMasterHeader: () => null,
  selectedRow: null,
  sidebarWidth: 350,
};

const StyledMasterDetailView = styled(Stack)`
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  align-items: stretch;
  background-color: ${color('background.secondary')};
`;

const StyledMasterList = styled(Box)`
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: ${color('background.secondary')};
  border-right: 1px solid ${color('border.input')};
`;

const StyledMasterListItem = styled(Box)`
  padding: ${spacing(1)} ${spacing(2)};
  border-bottom: 1px solid ${color('border.base')};
  position: relative;
  cursor: pointer;
  background-color: ${({ selected }) =>
    selected ? color('background.base') : 'transparent'};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 8px;

    transition: background-color 125ms;
    background-color: ${({ selected }) =>
      selected ? color('border.primaryActive') : 'transparent'};
  }
`;

const StyledMasterColumn = styled(Stack)`
  flex-grow: 0;
  flex-shrink: 0;
`;

const StyledMasterColumnHeader = styled(Box)`
  &:not(:empty) {
    padding: ${spacing(1)} ${spacing(2)};
    border-bottom: 1px solid ${color('border.base')};
  }
`;

const StyledMasterColumnFooter = styled(Box)`
  &:not(:empty) {
    margin-top: -1px;
    padding: ${spacing(1)} ${spacing(2)};
    border-top: 1px solid ${color('border.base')};
  }
`;

const StyledDetailView = styled(Stack)`
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
  border-left: 1px solid ${color('border.input')};
  margin-left: -1px;
`;
