import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';

import PriorityScoreBadge from '../PriorityScoreBadge';

export default function TaskPriorityScore({ task }) {
  return <PriorityScoreBadge score={task.score} />;
}

TaskPriorityScore.propTypes = {
  task: userInputTaskShape.isRequired,
};
