import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export const SelectedTasksContext = createContext({
  /**
   *
   * @param task
   */
  // eslint-disable-next-line no-unused-vars
  selectTask: (task) => {},

  /**
   *
   * @param task
   */
  // eslint-disable-next-line no-unused-vars
  unselectTask: (task) => {},

  /**
   *
   * @param task
   */
  // eslint-disable-next-line no-unused-vars
  isTaskSelected: (task) => {},

  clearSelectedTasks: () => {},
});

export function useSelectedTasksContext() {
  return useContext(SelectedTasksContext);
}

export function SelectedTasksProvider({ children, initialSelectedTaskIds }) {
  // TODO: Improve how we determine which tasks are selected by default.
  // During the TDD we had decided that simply having an entry in PECGH would be enough
  // to determine that an action is ready to complete, and that might be fine for MVP but
  // eventually this would need to be more granular and hopefully come from the API.
  const [selectedTaskIds, setSelectedTaskIds] = useState(
    initialSelectedTaskIds,
  );

  const selectTask = useCallback(
    (task) => {
      setSelectedTaskIds((oldValue) => [...oldValue, task.id]);
    },
    [setSelectedTaskIds],
  );

  const unselectTask = useCallback((task) => {
    setSelectedTaskIds((oldValue) => oldValue.filter((x) => x !== task.id));
  }, []);

  const isTaskSelected = useCallback(
    (task) => selectedTaskIds.indexOf(task.id) > -1,
    [selectedTaskIds],
  );

  const clearSelectedTasks = useCallback(() => {
    setSelectedTaskIds([]);
  }, []);

  const contextValue = useMemo(() => {
    return {
      selectTask,
      unselectTask,
      isTaskSelected,
      clearSelectedTasks,
    };
  }, [selectTask, unselectTask, isTaskSelected, clearSelectedTasks]);

  return (
    <SelectedTasksContext.Provider value={contextValue}>
      {children}
    </SelectedTasksContext.Provider>
  );
}

SelectedTasksProvider.propTypes = {
  children: PropTypes.node,
  // Intended for only testing purposes
  initialSelectedTaskIds: PropTypes.arrayOf(PropTypes.number),
};

SelectedTasksProvider.defaultProps = {
  children: undefined,
  initialSelectedTaskIds: [],
};
