import { Box, Stack, Typography, styled } from '@mui/material';
import { arrayOf, bool, func, number, object, objectOf } from 'prop-types';
import { last } from 'lodash';

import { ReactComponent as CheckTrue } from '@/assets/icons/checkTrue.svg';
import { patientShape } from '@/utils/shapes';
import { color } from '@/utils/stylingUtils';
import MultiPatientModal from '@/components/MultiPatientModal';
import { formatISODateIgnoreTimeZone } from '@/utils/formatDate';

import CompletedTaskSvuBadge from './CompletedTaskSvuBadge';

function PatientHeader({ totalSvuOpportunity }) {
  return (
    <Stack gap={2}>
      <Typography variant="bodysmall">
        Visits below were recently updated and likely have other actions
        addressed during the visit. Check each patient in the list below and
        update actions to earn SVUs.
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="bodysmall">
            Estimated total SVU opportunity
          </Typography>
        </Box>
        <CompletedTaskSvuBadge>
          {totalSvuOpportunity} SVUs
        </CompletedTaskSvuBadge>
      </Stack>
    </Stack>
  );
}
PatientHeader.propTypes = {
  totalSvuOpportunity: number.isRequired,
};

function CompletedTaskRow({ action, patient }) {
  const lastProgressStep = last(action.v2Dto.progress);
  const completionDate = lastProgressStep
    ? formatISODateIgnoreTimeZone(lastProgressStep.executedAt)
    : null;

  return (
    <Stack direction="row" gap={2} sx={{ position: 'relative' }}>
      <StyledCheckIcon height={24} width={24} />
      <Stack gap={1}>
        <Typography variant="bodybold">{action.v2Dto.text}</Typography>
        <Stack direction="row" gap={1} alignItems="flex-end">
          <Stack flex="1 1">
            <Typography>
              {patient.lastName}, {patient.firstName}
            </Typography>
            <Typography>Visit date: {completionDate}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
CompletedTaskRow.propTypes = {
  action: object.isRequired,
  patient: patientShape,
};
CompletedTaskRow.defaultProps = {
  patient: {},
};

export default function CompleteAdditionalActionsModal({
  completedActions,
  open,
  onCancel,
  patientMap,
  totalSvuOpportunity,
}) {
  return (
    <MultiPatientModal
      data={completedActions}
      dataToPatientId={(action) => action.v2Dto.patientId}
      getRowKey={(action) => action.id}
      initiallySelected={completedActions[0]}
      onClose={onCancel}
      open={open}
      renderPatientHeader={() => (
        <PatientHeader totalSvuOpportunity={totalSvuOpportunity} />
      )}
      renderPatientRow={({ data }) => (
        <CompletedTaskRow
          action={data}
          patient={patientMap[data.v2Dto.patientId]}
        />
      )}
      title={
        <Typography variant="headermediumbold" component="span">
          Earn additional SVUs for recently completed visits
        </Typography>
      }
    />
  );
}

CompleteAdditionalActionsModal.propTypes = {
  completedActions: arrayOf(object.isRequired).isRequired,
  onCancel: func.isRequired,
  open: bool,
  patientMap: objectOf(patientShape.isRequired).isRequired,
  totalSvuOpportunity: number.isRequired,
};

CompleteAdditionalActionsModal.defaultProps = {
  open: false,
};

const StyledCheckIcon = styled(CheckTrue)`
  flex: 0 0 24px;
  color: ${color('status.success')};
`;
