import PropTypes from 'prop-types';

import { formatISODateIgnoreTimeZone } from '@/utils/formatDate';

export type HideForNowHistory = {
  id: number;
  userId: number;
  userFullName: string;
  createdAt: string;
  hiddenUntil: string;
  note: {
    createdAt: string;
    author: string;
    text: string;
  };
};

export type APIHideForNowHistory = {
  id: number;
  user_id: number;
  user_full_name: string;
  created_at: string;
  hidden_until: string;
  note: {
    createdAt: string;
    author: string;
    text: string;
  };
};

export default function transformHideForNowHistory(
  hideForNowHistory: APIHideForNowHistory,
): HideForNowHistory {
  return {
    id: hideForNowHistory.id,
    userId: hideForNowHistory.user_id,
    userFullName: hideForNowHistory.user_full_name,
    createdAt: hideForNowHistory.created_at,
    hiddenUntil: formatISODateIgnoreTimeZone(hideForNowHistory.hidden_until),
    note: hideForNowHistory.note,
  };
}
export const hideForNowHistoryShape = PropTypes.shape({
  id: PropTypes.number,
  userId: PropTypes.number,
  userFullName: PropTypes.string,
  createdAt: PropTypes.string,
  hiddenUntil: PropTypes.string,
  note: PropTypes.shape({
    createdAt: PropTypes.string,
    author: PropTypes.string,
    text: PropTypes.string,
  }),
});
