import { useState } from 'react';
import { Stack } from '@mui/material';
import { bool, func, number, object } from 'prop-types';

import { patientShape, reactQueryStatusValues } from '@/utils/shapes';
import BaseForm from '@/components/BaseForm';

import useConditionsForDisplay from '../../hooks/useConditionsForDisplay';
import useDiagnosisActionStateManager from '../../hooks/useDiagnosisActionStateManager';
import DiagnosisActionFormControls from '../DiagnosisActionFormControls';
import DiagnosisProviderSelect from '../DiagnosisProviderSelect';
import DiagnosisIncorrectFormSection from '../DiagnosisIncorrectFormSection';
import DiagnosisFormCheckboxGroup from '../DiagnosisFormCheckboxGroup';

import DiagnosisCommonCodesSection from './DiagnosisCommonCodesSection';
import DiagnosisRelatedConditionSection from './DiagnosisRelatedConditionSection';

export default function DiagnosisActionFormLong({
  defaultProviderChoiceId,
  diagnosisAction,
  onCancel,
  onSaveDiagnosisAction,
  patient,
  saveStatus,
  isSubmitDisabled,
}) {
  const [selectedProviderId, setSelectedProviderId] = useState(
    defaultProviderChoiceId,
  );

  const {
    state,
    changeToRelatedCondition,
    conditionNotPresent,
    conditionNotAssessed,
    completeWithoutCode,
    selectDiagnosis,
    unselectDiagnosis,
  } = useDiagnosisActionStateManager();

  const { commonCodes, pastDiagnoses, relatedConditions } =
    useConditionsForDisplay(diagnosisAction);

  const handleSelectDiagnosis = (diagnosis) => {
    if (diagnosis.icd in state.selectedDiagnoses) unselectDiagnosis(diagnosis);
    else selectDiagnosis(diagnosis);
  };

  const handleSelectRelatedCondition = (condition) => {
    if (!condition || condition.value in state.selectedDiagnoses) {
      changeToRelatedCondition(null);
    } else {
      changeToRelatedCondition(condition);
    }
  };

  const handleSubmit = () => {
    onSaveDiagnosisAction({
      selectedProviderId,
      diagnosisAction,
      state,
    });
  };

  const isLoading = saveStatus === 'loading';

  return (
    <BaseForm onSubmit={handleSubmit} aria-label="Complete diagnosis action">
      <Stack px={1} gap={1}>
        <DiagnosisFormCheckboxGroup
          conditions={pastDiagnoses}
          header="Previously billed conditions not yet confirmed by claims this year"
          onSelectCondition={handleSelectDiagnosis}
          selectedConditions={state.selectedDiagnoses}
          loading={isLoading}
        />
        <DiagnosisCommonCodesSection
          commonCodes={commonCodes}
          diagnosisAction={diagnosisAction}
          onSelectCondition={handleSelectDiagnosis}
          patient={patient}
          selectedConditions={state.selectedDiagnoses}
          loading={isLoading}
        />
        <DiagnosisRelatedConditionSection
          diagnosisAction={diagnosisAction}
          onSelectCondition={handleSelectRelatedCondition}
          onSetCompleteWithoutCode={completeWithoutCode}
          patient={patient}
          relatedConditions={relatedConditions}
          selectedConditions={state.selectedDiagnoses}
          selectedHcc={state.selectedHcc}
          loading={isLoading}
        />
        <DiagnosisIncorrectFormSection
          completeWithoutCode={state.completeWithoutCode}
          diagnosisIncorrect={state.diagnosisIncorrect}
          notAssessed={state.notAssessed}
          onSetCompleteWithoutCode={completeWithoutCode}
          onSetDiagnosisIncorrect={conditionNotPresent}
          onSetNotAssessed={conditionNotAssessed}
          loading={isLoading}
        />
        <DiagnosisProviderSelect
          selectedProviderId={selectedProviderId}
          loading={isLoading}
          onChangeSelectedProviderId={setSelectedProviderId}
        />
        <DiagnosisActionFormControls
          onCancel={onCancel}
          loading={isLoading}
          disabled={!selectedProviderId || isSubmitDisabled}
        />
      </Stack>
    </BaseForm>
  );
}
DiagnosisActionFormLong.propTypes = {
  defaultProviderChoiceId: number,
  diagnosisAction: object.isRequired,
  onCancel: func.isRequired,
  onSaveDiagnosisAction: func.isRequired,
  patient: patientShape.isRequired,
  saveStatus: reactQueryStatusValues.isRequired,
  isSubmitDisabled: bool.isRequired,
};
DiagnosisActionFormLong.defaultProps = {
  defaultProviderChoiceId: undefined,
};
