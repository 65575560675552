import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@mui/material/Button';

import { ReactComponent as FilterIcon } from '@/assets/icons/filter.svg';

export default function FilterButton({ onClick, sx, filterCount }) {
  const text = filterCount > 0 ? `Filter - ${filterCount} selected` : 'Filter';
  const variant = filterCount > 0 ? 'contained' : 'text';
  const color = filterCount > 0 ? 'secondary' : 'primary';

  return (
    <Button
      color={color}
      variant={variant}
      onClick={onClick}
      size="large"
      sx={sx}
    >
      <StyledFilterIcon />
      <span>{text}</span>
    </Button>
  );
}

FilterButton.propTypes = {
  filterCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

FilterButton.defaultProps = {
  sx: undefined,
};

const StyledFilterIcon = styled(FilterIcon)`
  margin-right: 6px;
  height: 16px;
  width: 16px;
`;
