import { camelCase } from 'lodash';

/**
 * Convert an object containing `snake_case` properties to one that contains
 * `camelCase` properties. The second parameter, if an object, describes how
 * child properties should be transformed. The converter does not recursively
 * transform the input object, otherwise.
 *
 * @param object
 * @param propertyMapper
 * @returns {{}}
 */
export default function camelCaseify(
  object: Record<string, unknown>,
  propertyMapper: Record<string, (x: unknown) => unknown> = {},
): Record<string, unknown> {
  // If the input value isn't an object, just pass through:
  if (typeof object !== 'object' || object === null) return object;

  const output: Record<string, unknown> = {};
  const mapValue =
    typeof propertyMapper === 'object' && propertyMapper !== null
      ? (k: string, v: unknown) =>
          k in propertyMapper ? propertyMapper[k]?.(v) : v
      : (_: unknown, v: unknown) => v;

  Object.entries(object).forEach(([key, value]) => {
    const mapped: unknown = mapValue(key, value);
    if (mapped !== undefined) output[camelCase(key)] = mapped;
  });
  return output;
}
