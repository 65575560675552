import PropTypes from 'prop-types';

import transformFrontendPreferredReferrals, {
  preferredReferralShape,
} from '@/pages/PatientSummary/utils/transformFrontendPreferredReferrals';
import transformTestTypes, {
  testTypeShape,
} from '@/pages/PatientSummary/utils/transformTestTypes';

import { formatISODateIgnoreTimeZone } from './formatDate';

function transformFrontendActionTrigger(trigger) {
  return {
    dataUrl: trigger.data_url,
    text: trigger.text,
  };
}

function transformFrontendActionBullet(bullet) {
  return {
    displayType: bullet.display_type,
    stepActive: bullet.step_active,
    state: bullet.state,
    svuAwardType: bullet.svu_award_type,
    svuAwardAmount: Number(bullet.svu_award_amount),
    svuAwardAmountAfterPromo: Number(bullet.svu_award_amount_after_promo),
  };
}

export function transformFrontendActionProgress(progress) {
  return {
    dataUrl: progress.data_url,
    bullets: progress.bullets.map((b) => transformFrontendActionBullet(b)),
    show: progress.show,
  };
}

export function transformStep(step) {
  return {
    stepId: step.step_id,
    templateStep: step.template_step,
    description: step.description,
    svuPrePromo: step.svu_pre_promo,
    svuPostPromo: step.svu_post_promo,
    isPreferredNextStep: step.is_preferred_next_step,
    isRemovalStep: step.is_removal_step,
    isCompletionStep: step.is_completion_step,
    executionRequirements: step.execution_requirements,
    actionConfigConstants: step.action_config_constants,
  };
}

function transformExecutionInfo(executionInfo = {}) {
  // Schedule type is returned as an Array and we need the first value
  const scheduleType = executionInfo?.schedule_type
    ? executionInfo.schedule_type[0]
    : '';
  return {
    notes: executionInfo.notes,
    removalReason: executionInfo.removal_reason,
    scheduledDate: formatISODateIgnoreTimeZone(executionInfo.scheduled_date),
    scheduleType,
    dateOfService: formatISODateIgnoreTimeZone(executionInfo.date_of_service),
    scheduledDateOfScreening: formatISODateIgnoreTimeZone(
      executionInfo.scheduled_date_of_screening,
    ),
    specialtyProvider: executionInfo.speciality_provider_single_name,
    // both have to be present in order to show the name of the optometrist
    optometristName:
      executionInfo.optometrist_first_name &&
      executionInfo.optometrist_last_name
        ? `${executionInfo.optometrist_first_name} ${executionInfo.optometrist_last_name}`
        : null,
    phoneNumberInput: executionInfo.phone_number_input,
  };
}

export function transformStepHistory(stepHistory) {
  return {
    id: stepHistory.id,
    templateStep: stepHistory.template_step,
    description: stepHistory.description,
    initiator: stepHistory.initiator,
    userId: stepHistory.user_id,
    userFullName: stepHistory.user_full_name,
    svusPrePromo: stepHistory.svus_pre_promo,
    svusPostPromo: stepHistory.svus_post_promo,
    executedAt: stepHistory.executed_at,
    executionInfo: transformExecutionInfo(stepHistory.execution_info),
  };
}

function transformFrontendCareGapActionV2(actionV2Dto) {
  return {
    id: actionV2Dto.action_id,
    type: actionV2Dto.type,
    subtype: actionV2Dto.subtype,
    updatedAt: actionV2Dto.updated_at,
    mostRecentlyCompletedByName: actionV2Dto.most_recently_completed_by_name,
    mostRecentlyCompletedByDate: actionV2Dto.most_recently_completed_by_date,
    mostRecentProviderForDisplay:
      actionV2Dto.contextual_info?.most_recent_provider_name_for_display,
    staff: actionV2Dto.source === 'staff', // should this be source: action.source?
    typeDisplay: actionV2Dto.contextual_info?.display_type,
    text: actionV2Dto.description,
    help: actionV2Dto.contextual_info?.help?.show
      ? actionV2Dto.contextual_info.help
      : null,
    trigger: actionV2Dto.contextual_info?.trigger
      ? transformFrontendActionTrigger(actionV2Dto.contextual_info.trigger)
      : null,
    date: actionV2Dto.contextual_info?.last_visit_date,
    patientId: actionV2Dto.patient_id,
    progress: actionV2Dto.step_histories?.map(transformStepHistory),
    promo: Boolean(actionV2Dto.possible_svus_remaining_post_promo),
    respondChoices: actionV2Dto.available_next_steps?.map(transformStep),
    isScheduledToOtherReferral:
      actionV2Dto.contextual_info?.is_scheduled_to_other_referral,
    isScheduled: actionV2Dto.contextual_info?.is_scheduled,
    extraDetailForDisplay:
      actionV2Dto.contextual_info?.extra_detail_for_display || '',
    userWhoLastClicked: actionV2Dto.user_who_last_clicked || '',
    supportsPrintScheduledPdf:
      actionV2Dto.contextual_info?.supports_print_scheduled_pdf,
    contactHistoryCount:
      actionV2Dto.contextual_info?.contact_history_count || 0,
    showAddressingHistory: actionV2Dto.contextual_info?.show_addressing_history,
    wasPreviouslyScheduled:
      actionV2Dto.contextual_info?.was_previously_scheduled,
    svusBeforePromo: Number(actionV2Dto.possible_svus_remaining_pre_promo),
    svusAfterPromo: Number(actionV2Dto.possible_svus_remaining_post_promo),
    svuAmountScheduledFromIncomplete: Number(
      actionV2Dto.contextual_info?.svu_amount_scheduled_from_incomplete,
    ),
    svuAmountCompletedFromScheduled: Number(
      actionV2Dto.contextual_info?.svu_amount_completed_from_scheduled,
    ),
    preferredReferrals: transformFrontendPreferredReferrals(
      actionV2Dto.contextual_info?.preferred_referrals,
    ),
    hasPreferredReferrals:
      Array.isArray(actionV2Dto.contextual_info?.preferred_referrals) &&
      actionV2Dto.contextual_info?.preferred_referrals.length > 0,
    testTypes: transformTestTypes(actionV2Dto.contextual_info?.test_types),
  };
}

export default function transformFrontendCareGapAction(action) {
  return {
    id: action.id,
    subtype: action.subtype,
    active: action.active,
    type: action.type,
    state: action.state,
    updatedAt: action.updated_at,
    expiresAt: action.expires_at,
    minDate: action.min_date,
    mostRecentlyCompletedByName: action.most_recently_completed_by_name,
    mostRecentlyCompletedByDate: action.most_recently_completed_by_date,
    mostRecentProviderForDisplay: action.most_recent_provider_name_for_display,
    staff: action.staff,
    typeDisplay: action.type_display,
    text: action.text,
    help: action.help?.show ? action.help : null,
    trigger: action.trigger
      ? transformFrontendActionTrigger(action.trigger)
      : null,
    providerName: action.provider_name,
    date: action.date,
    progress: action.progress?.show
      ? transformFrontendActionProgress(action.progress)
      : null,
    promo: action.promo,
    respondChoices: action.respond_choices
      ? Object.entries(action.respond_choices).map(([value, label]) => ({
          label,
          value,
        }))
      : [],
    isScheduledToOtherReferral: action.is_scheduled_to_other_referral,
    isScheduled: action.is_scheduled,
    extraDetailForDisplay: action.extra_detail_for_display || '',
    userWhoLastClicked: action.user_who_last_clicked || '',
    supportsPrintScheduledPdf: action.supports_print_scheduled_pdf,
    contactHistoryCount: action.contact_history_count || 0,
    showAddressingHistory: action.show_addressing_history,
    wasPreviouslyScheduled: action.was_previously_scheduled,
    svusBeforePromo: Number(action.svus_before_promo),
    svusAfterPromo: Number(action.svus_after_promo),
    svuAmountScheduledFromIncomplete: Number(
      action.svu_amount_scheduled_from_incomplete,
    ),
    svuAmountCompletedFromScheduled: Number(
      action.svu_amount_completed_from_scheduled,
    ),
    preferredReferrals: transformFrontendPreferredReferrals(
      action.preferred_referrals,
    ),
    hasPreferredReferrals:
      Array.isArray(action.preferred_referrals) &&
      action.preferred_referrals.length > 0,
    testTypes: transformTestTypes(action.test_types),
    medAdherenceStatus: action.med_adherence_status,
    v2Dto: action.v2_dto
      ? transformFrontendCareGapActionV2(action.v2_dto)
      : null,
    ldlcCutoffForNeedingStatin: action.ldlc_cutoff_for_needing_statin,
    mostRecentClaimDate: action.most_recent_claim_date,
  };
}

export const actionHelpShape = PropTypes.shape({
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.string,
  show: PropTypes.bool,
});

export const actionTriggerShape = PropTypes.shape({
  dataUrl: PropTypes.string,
  text: PropTypes.string,
});

export const actionProgressShape = PropTypes.shape({
  dataUrl: PropTypes.string,
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      stepType: PropTypes.string,
      stepActive: PropTypes.bool,
      state: PropTypes.string,
      svuAwardType: PropTypes.string,
    }),
  ),
  show: PropTypes.bool,
});

export const executionRequirementShape = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dataPendoId: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  // eslint-disable-next-line consistent-return
  choices: PropTypes.arrayOf((propValue, key, componentName) => {
    // `choices` should be an array of two-tuples of strings.
    if (
      !Array.isArray(propValue) ||
      !propValue.every((tuple) => {
        // Validate each choice is a two-tuple of strings.
        return (
          Array.isArray(tuple) &&
          tuple.length === 2 &&
          tuple.every((x) => typeof x === 'string')
        );
      })
    ) {
      return new Error(
        `Invalid prop 'choices' supplied to '${componentName}'.`,
      );
    }
  }),
});

export const stepShape = PropTypes.shape({
  stepId: PropTypes.string,
  templateStep: PropTypes.string,
  description: PropTypes.string,
  svuPrePromo: PropTypes.number,
  svuPostPromo: PropTypes.number,
  isPreferredNextStep: PropTypes.bool,
  isRemovalStep: PropTypes.bool,
  executionRequirements: PropTypes.arrayOf(executionRequirementShape),
  actionConfigConstants: PropTypes.object,
});

export const stepHistoryShape = PropTypes.shape({
  id: PropTypes.number,
  templateStep: PropTypes.string,
  description: PropTypes.string,
  initiator: PropTypes.string,
  userId: PropTypes.number,
  userFullName: PropTypes.string,
  svusPrePromo: PropTypes.number,
  svusPostPromo: PropTypes.number,
  executedAt: PropTypes.string,
  scheduleType: PropTypes.string,
  executionInfo: PropTypes.object,
});

const commonCareGapShape = {
  id: PropTypes.number,
  subtype: PropTypes.string,
  type: PropTypes.string,
  updatedAt: PropTypes.number,
  expiresAt: PropTypes.string,
  mostRecentlyCompletedByName: PropTypes.string,
  mostRecentlyCompletedByDate: PropTypes.string,
  mostRecentProviderForDisplay: PropTypes.string,
  staff: PropTypes.bool,
  typeDisplay: PropTypes.string,
  text: PropTypes.string,
  help: actionHelpShape,
  trigger: actionTriggerShape,
  date: PropTypes.string,
  promo: PropTypes.bool,
  isScheduledToOtherReferral: PropTypes.bool,
  isScheduled: PropTypes.bool,
  extraDetailForDisplay: PropTypes.string,
  userWhoLastClicked: PropTypes.string,
  supportsPrintScheduledPdf: PropTypes.bool,
  contactHistoryCount: PropTypes.number,
  showAddressingHistory: PropTypes.bool,
  wasPreviouslyScheduled: PropTypes.bool,
  svusBeforePromo: PropTypes.number,
  svusAfterPromo: PropTypes.number,
  svuAmountScheduledFromIncomplete: PropTypes.number,
  svuAmountCompletedFromScheduled: PropTypes.number,
  preferredReferrals: PropTypes.arrayOf(preferredReferralShape),
  hasPreferredReferrals: PropTypes.bool,
  testTypes: PropTypes.arrayOf(testTypeShape),
  medAdherenceStatus: PropTypes.string,
};

export const careGapActionShapeV2 = PropTypes.shape({
  ...commonCareGapShape,
  progress: PropTypes.arrayOf(stepHistoryShape),
  respondChoices: PropTypes.arrayOf(stepShape),
  testTypes: PropTypes.arrayOf(testTypeShape),
});

export const careGapActionShape = PropTypes.shape({
  ...commonCareGapShape,
  state: PropTypes.string,
  providerName: PropTypes.string,
  progress: actionProgressShape,
  respondChoices: PropTypes.arrayOf(PropTypes.object),
  v2Dto: careGapActionShapeV2,
});
