import { Link, Stack, styled } from '@mui/material';
import { bool, func, object } from 'prop-types';

import { color, spacing } from '@/utils/stylingUtils';

export default function CollapsedDiagnosisActionForm({
  firstDiagnosis,
  narrow,
  onExpandLongForm,
  onExpandShortForm,
}) {
  return (
    <Stack sx={{ ml: narrow ? null : '50%' }}>
      <StyledLink onClick={onExpandShortForm}>
        {firstDiagnosis.icd} addressed in this visit
      </StyledLink>
      <StyledLink onClick={onExpandLongForm}>Select other diagnosis</StyledLink>
    </Stack>
  );
}

CollapsedDiagnosisActionForm.propTypes = {
  firstDiagnosis: object.isRequired,
  narrow: bool.isRequired,
  onExpandLongForm: func.isRequired,
  onExpandShortForm: func.isRequired,
};

const StyledLink = styled(Link)`
  color: ${color('text.link')};
  text-decoration: none;
  padding: ${spacing(1)};
  cursor: pointer; // not sure why this isn't default styling for links 🤔 -- nh

  &:hover {
    background-color: ${color('background.tableZebra')};
  }
`;
