import { Stack, Typography, styled } from '@mui/material';
import { object } from 'prop-types';

import {
  getDiagnosisHistorySubtext,
  getMostRecentServiceProvider,
} from '@/utils/diagnosisUtils';
import { typography } from '@/utils/stylingUtils';

export default function CompletedDiagnosisStatusCell({ action }) {
  return (
    <Stack>
      <StyledCompletedInfo>
        Previously billed codes:{' '}
        {action.pastDiagnoses
          .map((d) => `${d.icd} - ${d.description}`)
          .join(', ')}
      </StyledCompletedInfo>
      <StyledCompletedInfo>
        {getDiagnosisHistorySubtext(action)}
      </StyledCompletedInfo>
      <StyledCompletedInfo>
        {getMostRecentServiceProvider(action)}
      </StyledCompletedInfo>
    </Stack>
  );
}

CompletedDiagnosisStatusCell.propTypes = {
  action: object.isRequired,
};

const StyledCompletedInfo = styled(Typography)`
  ${typography('bodysmall')}
`;
