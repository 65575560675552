import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { zipObject } from 'lodash';

import {
  getFormDependencyNames,
  supportedProperties,
} from '@/components/DynamicForm/register';

function processFormField(field, additionalProps, formState) {
  try {
    const clonedField = { ...field };

    supportedProperties.forEach((property) => {
      // Only allow label type instructional text to be dynamic
      if (property === 'label' && field.type !== 'label') return;

      if (property in field && typeof field[property] === 'function') {
        clonedField[property] = field[property](formState, additionalProps);
      }
    });

    if (clonedField.visible !== false) {
      clonedField.visible = true;
    }

    if (clonedField.required !== true) {
      clonedField.required = false;
    }

    return clonedField;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Generating ${field.name} failed with error`, e);

    // Throwing an error shouldn't propagate upward: while it might produce a
    // less-pleasant user experience, in most cases the resulting form should
    // still be usable. The alternative is to crash the whole UI, which would
    // be bad.
    const clonedField = { ...field };

    // Strip out fancy properties that are functions:
    supportedProperties.forEach((prop) => {
      if (typeof clonedField[prop] === 'function') {
        delete clonedField[prop];
      }
    });
    return clonedField;
  }
}

/**
 * Compares two values. If both A and B are dates, they are compared by
 * milliseconds-since-the-epoch. All other comparisons are by-reference.
 *
 * @param a {any}
 * @param b {any}
 * @returns {boolean}
 */
function equal(a, b) {
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }

  return a === b;
}

function useNormalizedFormWatcher(fields) {
  // Annoyingly, react-hook-form provides two different shapes of return values
  // for `useWatch`. If you call `useWatch` with no fields, one field, or many
  // fields. If no fields it returns an object of the formstate. If one field,
  // it returns that one form field's value. If an array of fields, it will
  // return an array of form field values.
  //
  // This normalizes that behavior to always return an object in the form of
  // { [field_name]: field_value }
  const deps = getFormDependencyNames(fields);
  const values = useWatch({ name: deps });
  return zipObject(deps, values);
}

/**
 * @typedef DynamicField
 * @property visible
 * @property defaultValue
 */

/**
 *
 * @param fields {DynamicField[]}
 * @param additionalProps {Record<string, unknown>}
 * @returns {DynamicField[]}
 */
export default function useDynamicFieldHandler(fields, additionalProps = {}) {
  const form = useFormContext();

  // `defaultValues` can change over the course of the component lifetime, so
  // grab a reference to the original default values as soon as the containing
  // component mounts.
  const [originalDefaultValues] = useState(form.formState.defaultValues ?? {});
  if (!form) {
    throw new RangeError(
      `Missing form context, did you forget to wrap in <DynamicForm />?`,
    );
  }

  const { getFieldState, getValues, formState, resetField } = form;
  const formValues = useNormalizedFormWatcher(fields);

  const modifiedFields = useMemo(() => {
    return fields.map((field) => {
      return processFormField(field, additionalProps, formValues);
    });
  }, [fields, additionalProps, formValues]);

  const fieldsToReset = useMemo(() => {
    // eslint-disable-next-line no-shadow
    return modifiedFields.reduce((fieldsToReset, field) => {
      // If this field doesn't have any defaultValue config on it, just skip:
      if (!('defaultValue' in field)) return fieldsToReset;

      const fieldValue = getValues(field.name);

      // Using the alternate form of `getFieldState` here because it seems
      // that, on occasion, the fieldState falls out of date using the
      // single-argument form. Don't love that! --NH
      const fieldState = getFieldState(field.name, formState);
      const defaultValue =
        field.defaultValue ?? originalDefaultValues[field.name] ?? '';

      const shouldReset =
        // don't reset invisible fields
        field.visible &&
        // ensure that the field's current value isn't the default value, so we
        // aren't resetting the field over and over again.
        !equal(defaultValue, fieldValue) &&
        // ensure that the user hasn't fiddled with the field's value, if they
        // have we definitely don't want to reset it.
        fieldState.isDirty === false;

      // N.B.: It's possible one desirable behavior is that if the user
      // clears a form field that is auto-populated like this, that we then
      // refill it with the default value. This is both intuitive in the
      // general case and potentially very annoying at the edge. But maybe
      // there's a pragmatic solution here?

      if (shouldReset) {
        return fieldsToReset.concat({
          name: field.name,
          defaultValue,
        });
      }
      return fieldsToReset;
    }, []);
  }, [
    modifiedFields,
    getFieldState,
    getValues,
    formState,
    originalDefaultValues,
  ]);

  useEffect(() => {
    if (fieldsToReset.length === 0) return;
    fieldsToReset.forEach(({ name, defaultValue }) => {
      resetField(name, { defaultValue });
    });
  }, [fieldsToReset, resetField]);

  return modifiedFields;
}
