import { first } from 'lodash';
import { styled } from '@mui/material';

import { actionShape } from '@/utils/transformFrontendAction';
import BaseLinkButton from '@/components/BaseLinkButton';

export default function ActionPreferredNextStep({ action }) {
  const { version } = action.v2Dto;
  const preferredNextStep = first(action.v2Dto.preferredPath);
  const taskDescription = preferredNextStep
    ? preferredNextStep.description
    : '';
  return (
    // This is a fake button. The row component will handle the click.
    <ActionPreferredNextStepRoot tabIndex={0}>
      {version ? taskDescription : 'Review updated information'}
    </ActionPreferredNextStepRoot>
  );
}

ActionPreferredNextStep.propTypes = {
  action: actionShape.isRequired,
};

const ActionPreferredNextStepRoot = styled(BaseLinkButton)`
  color: ${(p) => p.theme.palette.text.link};
  text-align: left;

  :not(:hover) {
    text-decoration: none;
  }
`;
