import PropTypes from 'prop-types';

import BaseFilterButton from '@/components/BaseFilterButton';

import useFilterCount from './hooks/useFilterCount';

function FilterButton({ className, onClick, sx, pulseEnabled }) {
  const filterCount = useFilterCount();

  const variant = filterCount > 0 ? 'contained' : 'text';
  return (
    <BaseFilterButton
      color="primary"
      className={className}
      variant={variant}
      onClick={onClick}
      sx={sx}
      pulseEnabled={pulseEnabled}
    />
  );
}

FilterButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  pulseEnabled: PropTypes.bool,
};

FilterButton.defaultProps = {
  className: undefined,
  sx: undefined,
  pulseEnabled: true,
};

export default FilterButton;
