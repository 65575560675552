import Box from '@mui/material/Box';
import { array, bool, object, oneOfType, string } from 'prop-types';
import Button from '@mui/material/Button';

import BaseSpinner from '@/components/BaseSpinner';

export default function Footer({
  buttonName,
  isLoading,
  sx,
  isSubmitButtonDisabled,
  'data-pendo-id': dataPendoId,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        ...sx,
      }}
    >
      {isLoading ? (
        <BaseSpinner size={20} />
      ) : (
        <Button
          type="submit"
          disabled={isSubmitButtonDisabled}
          fullWidth
          size="large"
          data-pendo-id={dataPendoId}
        >
          {buttonName}
        </Button>
      )}
    </Box>
  );
}

Footer.propTypes = {
  buttonName: string.isRequired,
  isLoading: bool,
  sx: oneOfType([object, array]),
  isSubmitButtonDisabled: bool,
  'data-pendo-id': string,
};
Footer.defaultProps = {
  isLoading: false,
  sx: undefined,
  isSubmitButtonDisabled: false,
  'data-pendo-id': '',
};
