import { arrayOf, number, shape, string } from 'prop-types';

export const difficultiesShape = shape({
  easy: number.isRequired,
  medium: number.isRequired,
});

export const subtypeGoalShape = shape({
  completed: number,
  total: number,
  subtype: string,
  subtypeDisplay: string,
  awardedSvu: number,
});

export const goalShape = shape({
  internalPerformanceMetric: string.isRequired,
  completed: number.isRequired,
  total: number.isRequired,
  availableSvu: number,
  awardedSvu: number,
  subtypeGoals: arrayOf(subtypeGoalShape),
});
