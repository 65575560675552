import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { arrayOf, number, shape, string } from 'prop-types';

export default function Agreements({ agreements }) {
  return (
    <>
      {agreements.map((agreement) => (
        <Box
          sx={{
            p: 1,
            border: '1px solid',
            width: '480px',
            borderColor: 'border.base',
            borderRadius: '8px',
          }}
          key={agreement.id}
        >
          <Typography variant="h2">{agreement.name}</Typography>
          <AgreementContainer>
            <div
              /* We're using dangerouslySetInnerHTML here because the agreement text is HTML created within Django Admin. */
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: agreement.text.trim(),
              }}
            />
          </AgreementContainer>
        </Box>
      ))}
    </>
  );
}

Agreements.propTypes = {
  agreements: arrayOf(
    shape({
      id: number,
      name: string,
      version: number,
      text: string,
    }),
  ),
};
Agreements.defaultProps = {
  agreements: [],
};

const AgreementContainer = styled(Box)`
  max-width: 480px;
  width: 100%;
  max-height: 480px;
  overflow: auto;
  margin-top: 8px;
`;
