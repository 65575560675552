import { useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import axios from 'axios';

import transformFrontendPreferredReferrals from '@/pages/PatientSummary/utils/transformFrontendPreferredReferrals';
import { usePage } from '@/pages/TaskBasedWorkflow/contexts/PageContext';

const CACHE_TIME = 1000 * 60 * 60 * 24; // 24 hours in milliseconds

function transformTags(tags) {
  return tags.map((tag, index) => ({
    name: tag,

    // Use the tag name as the id, because that's what the Search API expects.
    id: tag,

    // Hack-ily save the actual tag ID, because that's what the CSV/PDF Export uses.
    // Ideally the endpoint would return an array of `{ id, name }` objects.
    tagId: index + 1,
  }));
}

function buildActionSubtypeStates(subtypeChoices, subtypeLabels, stateLabels) {
  return map(subtypeChoices, (options, key) => ({
    id: key,
    name: subtypeLabels[key],
    options: options.map((optionId) => ({
      id: optionId,
      name: stateLabels[optionId],
    })),
  }));
}

function transformResponse(data) {
  return {
    isMultiPlan: data.is_multi_plan,
    isMultiOffice: data.is_multi_office,
    noTags: data.no_tags,
    userIsRosterManager: data.user_is_roster_manager,
    providers: sortBy(data.providers, 'name'),
    plans: sortBy(data.plans, 'name'),
    tags: sortBy(transformTags(data.tags), 'name'),
    offices: data.offices,
    actionSubtypeStates: sortBy(
      buildActionSubtypeStates(
        data.subtype_choices,
        data.subtype_labels,
        data.state_labels,
      ),
      'name',
    ),
    subtypeLabels: data.subtype_labels,
    stateLabels: data.state_labels,
    attributionStatus: data.attribution_status,
    totalPatientsCount: data.total_patients_count,
    ageBuckets: data.age_buckets,
    tbwSubtypeChoices: data.tbw_subtype_choices,
    preferredReferrals: transformFrontendPreferredReferrals(
      data.preferred_referrals,
    ),
    annualVisitYearOptions: data.annual_visit_year_options,
  };
}

async function fetchUserFilters(page) {
  const response = await axios.get('/api/view-panel/filters', {
    params: { page },
  });
  return response.data;
}

function getQueryKey(page) {
  return ['user', 'filters', page];
}

export default function useGetUserFilters({ onSuccess = undefined } = {}) {
  const page = usePage();

  return useQuery({
    queryKey: getQueryKey(page),
    queryFn: () => fetchUserFilters(page),
    select: transformResponse,
    onSuccess,
    cacheTime: CACHE_TIME,
  });
}

export function usePrefetchUserFilters(shouldFetch = true) {
  const page = usePage();
  const queryClient = useQueryClient();
  // 🚨Use a ref here to avoid setState rerender behavior. If the effect is
  //   called, it should immediately flag that it shouldn't be called again,
  //   and it shouldn't trigger a rerender when that happens. Ref gets us that
  //   behavior:
  const hasBeenCalledRef = useRef(false);

  useEffect(() => {
    if (!hasBeenCalledRef.current && shouldFetch) {
      hasBeenCalledRef.current = true;
      queryClient.prefetchQuery({
        queryKey: getQueryKey(page),
        queryFn: () => fetchUserFilters(page),
        cacheTime: CACHE_TIME,
      });
    }
    // queryClient should be static, but we don't want to invoke this if the
    // value happens to change for some reason, so disable exhaustive deps:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);
}
