import { Typography } from '@mui/material';
import { oneOf } from 'prop-types';

import { locationAndDateText } from '@/utils/pastDiagnosisUtils';
import { diagnosisShape } from '@/utils/transformFrontendAction';

import DiagnosisSubtext from './DiagnosisSubtext';

export default function DiagnosisDisplay({ diagnosis, variant }) {
  return (
    <>
      {diagnosis.icd ? (
        <Typography variant={variant} sx={{ color: 'text.primary' }}>
          {diagnosis.icd} - {diagnosis.description}
        </Typography>
      ) : null}
      {diagnosis.pastDiagnoses
        ? diagnosis.pastDiagnoses.map((pastDiag) => {
            const { locationString, dateString } =
              locationAndDateText(pastDiag);
            return (
              <DiagnosisSubtext
                key={`${locationString}-${dateString}`}
                diagnosis={pastDiag}
                locationString={locationString}
                dateString={dateString}
                variant={variant}
              />
            );
          })
        : null}
    </>
  );
}
DiagnosisDisplay.propTypes = {
  diagnosis: diagnosisShape.isRequired,
  variant: oneOf(['bodysmall', 'bodymedium', 'body']).isRequired,
};
