import PropTypes from 'prop-types';

export function transformExternalCareGapRecentActivity(
  externalCareGapRecentActivity,
) {
  return externalCareGapRecentActivity &&
    externalCareGapRecentActivity.length > 0
    ? externalCareGapRecentActivity.map((activity) => {
        return {
          externalCareGapId: activity.external_care_gap_id,
          externalHistoryStatus: activity.external_history_status,
          cptCodes: activity.cpt_codes,
          providerName: activity.servicing_provider,
          dateOfService: activity.date_of_service,
          recordedAt: activity.recorded_at,
          externalCareGapStatus: activity.external_care_gap_status,
          sourceType: activity.source_type,
          statusUpdateBy: activity.status_updated_by,
          statusUpdatedAt: activity.status_updated_at,
        };
      })
    : [];
}

export const externalCareGapRecentActivityShape = PropTypes.shape({
  externalCareGapId: PropTypes.number,
  externalHistoryStatus: PropTypes.string,
  cptCodes: PropTypes.string,
  providerName: PropTypes.string,
  dateOfService: PropTypes.string,
  recordedAt: PropTypes.string,
  externalCareGapStatus: PropTypes.string,
  sourceType: PropTypes.string,
  statusUpdateBy: PropTypes.string,
  statusUpdatedAt: PropTypes.string,
});
