/* eslint-disable camelcase, import/prefer-default-export */

import register from '@/components/DynamicForm/register';
import { ReactComponent as CalendarIcon } from '@/assets/icons/calendarVisits.svg';

export default {
  priority_patient_visit: {
    icon: CalendarIcon,
    config: {
      enrollment_status: {
        labelPlacement: 'end',
      },
      visit_frequency: {
        visible: register(['enrollment_status'], ({ enrollment_status }) => {
          return enrollment_status;
        }),
        required: register(['enrollment_status'], ({ enrollment_status }) => {
          return enrollment_status;
        }),
        BoxSx: { display: 'block' },
      },
      enrollment_reasons: {
        visible: register(['enrollment_status'], ({ enrollment_status }) => {
          return enrollment_status;
        }),
        required: register(['enrollment_status'], ({ enrollment_status }) => {
          return enrollment_status;
        }),
        minSelectionCount: 1,
      },
      checkbox_label: {
        variant: 'bodysmall',
      },
    },
  },
};
