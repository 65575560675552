import axios from 'axios';
import queryString from 'query-string';

import getCsrfToken from './getCsrfToken';

export default function configureAxiosGlobalDefaults() {
  axios.defaults.paramsSerializer = (params) => queryString.stringify(params);

  // The CSRFToken is only necessary for POST and PUT requests, not for GET requests.
  axios.defaults.headers.common['X-CSRFToken'] = getCsrfToken();
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
}
