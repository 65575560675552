/* eslint-disable react/forbid-prop-types */
import { Box, Button, FormLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import BaseFileUpload from '@/components/BaseFileUpload';
import FormErrorMessage from '@/components/FormErrorMessage';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

function ModalFileUpload({
  label,
  errorMsg,
  actionId,
  multiple,
  BoxSx,
  InputSx,
  name,
  control,
  errors,
}) {
  const validate = {
    fileSize: (files) =>
      files?.every((f) => f.size <= 100000000) ||
      'File(s) must be <= 100MB each',
    fileType: (files) =>
      files?.every(
        (f) => f.type.match(/image\/*/) || f.type === 'application/pdf',
      ) || 'File(s) must be PDF, PNG, JPG, or TIF',
  };
  const rules = errorMsg ? { required: errorMsg, validate } : { validate };
  const { field } = useController({ control, name, rules, defaultValue: [] });

  const onChange = (event) => {
    field.onChange(Array.from(event.target.files));
  };

  const handleDelete = (index) => {
    const newFiles = [...field.value];
    newFiles.splice(index, 1);
    field.onChange(newFiles);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        mt: 1,
        justifyContent: 'space-between',
        ...BoxSx,
      }}
    >
      <FormLabel htmlFor={`${actionId}_${name}`} sx={{ mr: 1, mt: 1 }}>
        {label}
      </FormLabel>
      <div>
        <BaseFileUpload
          accept=".pdf,image/*"
          onChange={onChange}
          multiple={multiple}
          id={`${actionId}_${name}`}
          sx={InputSx}
        />
        {field.value.map((file, i) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`${file.name}_${i}`}
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }}
          >
            <p>{file.name}</p>
            <Button onClick={() => handleDelete(i)} variant="text">
              <StyledCloseIcon />
            </Button>
          </Box>
        ))}
        <FormErrorMessage name={name} errors={errors} />
      </div>
    </Box>
  );
}

export default ModalFileUpload;

ModalFileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  actionId: PropTypes.number,
  multiple: PropTypes.bool,
  BoxSx: PropTypes.object,
  InputSx: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

ModalFileUpload.defaultProps = {
  errorMsg: undefined,
  actionId: undefined,
  multiple: false,
  BoxSx: undefined,
  InputSx: undefined,
};

const StyledCloseIcon = styled(CloseIcon)`
  width: 20px;
  height: 20px;
  pointer-events: none;
`;
