import PropTypes from 'prop-types';
import { Link } from '@mui/material';

export default function LinkRenderer({ href, children }) {
  // links begins with http should go to other website and we should create a new tab
  if (href.startsWith('http')) {
    return (
      <Link href={href} target="_blank" rel="noreferrer">
        {children}
      </Link>
    );
  }
  return <Link href={href}>{children}</Link>;
}

LinkRenderer.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
