import queryString from 'query-string';
import JsFileDownloader from 'js-file-downloader';
import { useState } from 'react';

import { useQueryParameters } from '@/contexts/QueryParametersContext';
import { formatDateIgnoreTimeZone } from '@/utils/formatDate';
import useDate from '@/hooks/useDate';

import { usePage } from '../contexts/PageContext';
import getPageTitle from '../utils/getPageTitle';

const TIMEOUT = 1000 * 60 * 20; // 20 minutes in milliseconds

export default function useExportCsv() {
  const { parameters } = useQueryParameters();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const page = usePage();
  const today = useDate();

  const exportCsv = async () => {
    const params = {
      page,
      preferred_next_step: parameters.preferredNextStep,
      provider_ids: parameters.providerIds,
      office_ids: parameters.officeIds,
      plan_ids: parameters.planIds,
      patient_tags: parameters.patientTags,
      age_buckets: parameters.ageBuckets,
      include_hidden: parameters.includeHidden,
      sort_field: parameters.sortField,
      sort_direction: parameters.sortDirection,
      filter_tab: parameters.filterTab,
      adl_min: parameters.adlMin,
      adl_max: parameters.adlMax,
      action_subtypes: parameters.tbwSubtypeChoices,
      av_years: parameters.avYears,
    };

    const url = queryString.stringifyUrl({
      url: '/api/tbw/export',
      query: params,
    });

    setIsLoading(true);

    try {
      await new JsFileDownloader({
        method: 'GET',
        url,
        contentType: 'text/csv',
        filename: `Stellar ${getPageTitle(page)}_${formatDateIgnoreTimeZone(
          today,
          'MM-dd-yyyy',
        )}.csv`,
        timeout: TIMEOUT,
      });
    } catch (e) {
      setError(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    exportCsv,
    isLoading,
    error,
  };
}
