import PropTypes from 'prop-types';
import { Input, Paper } from '@mui/material';
import { useRef, useState } from 'react';
import CalendarPicker from '@mui/lab/CalendarPicker';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import { format, isValid, parse } from 'date-fns';
import { useKey, useUpdateEffect } from 'react-use';
import isNil from 'lodash/isNil';

// A reference date used by date-fns' parse() function.
const referenceDate = new Date();

/**
 * This is the list of accepted date formats if the user decides to manually input a date.
 *
 * The `format` field is used by date-fns to parse the date.
 */
const dateFormats = [
  { pattern: /^\d{1,2}-\d{1,2}-\d{2}$/, format: 'MM-dd-yy' },
  { pattern: /^\d{1,2}\/\d{1,2}\/\d{2}$/, format: 'MM/dd/yy' },
  { pattern: /^\d{1,2}-\d{1,2}-\d{4}$/, format: 'MM-dd-yyyy' },
  { pattern: /^\d{1,2}\/\d{1,2}\/\d{4}$/, format: 'MM/dd/yyyy' },
  { pattern: /^\d{4}-\d{1,2}-\d{1,2}$/, format: 'yyyy-MM-dd' },
  { pattern: /^\d{4}\/\d{1,2}\/\d{1,2}$/, format: 'yyyy/MM/dd' },
];

// This is the format of the input's display value
const DATE_FORMAT = 'MM-dd-yyyy';

// Make the Popper render correctly inside modals.
// Using a value of 1300 for the z-index to match the same value as used in the official MUI DatePicker.
const POPPER_Z_INDEX = 1300;

/**
 * Parse a Date object from a Date or string.
 * @param {Date|string} value
 * @returns {Date}
 */
function parseDate(value) {
  // Return early if it's already a valid date
  if (isValid(value)) {
    return value;
  }

  const dateFormat = dateFormats.find(({ pattern }) =>
    pattern.test(value),
  )?.format;

  if (dateFormat) {
    const parsedDate = parse(value, dateFormat, referenceDate);
    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }

  return null;
}

/**
 * Formats a Date or string to the MM-DD-YYYY format.
 *
 * @param {Date|string} value
 * @returns {string}
 */
function formatDate(value) {
  const parsedDate = parseDate(value);

  return isValid(parsedDate) ? format(parsedDate, DATE_FORMAT) : '';
}

/**
 * An Input that shows a DatePicker when the input is clicked.
 */
export default function BaseDatePicker({
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledby,
  'data-testid': dataTestId,
  'data-pendo-id': dataPendoId,
  error,
  fullWidth,
  id,
  name,
  onChange,
  placeholder,
  size,
  minDate,
  maxDate,
  sx,
  value,
  disabled,
  PopperProps,
  className,
}) {
  const [wasInputChanged, setWasInputChanged] = useState(false);
  const inputWrapperRef = useRef(null);
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const date = parseDate(value) || undefined;
  const [inputValue, setInputValue] = useState(() =>
    date ? formatDate(date) : '',
  );

  useUpdateEffect(() => {
    // If `value` gets updated outside of DatePicker, we want to update the input value to match the new date.
    if (!isNil(value)) {
      const formattedDate = formatDate(value);
      if (inputValue !== formattedDate) {
        setInputValue(formattedDate);
      }
    }
  }, [value]);

  /**
   * Focus the input and select its contents.
   * @param {boolean} selectAll
   */
  const focusInput = (selectAll = true) => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(
        selectAll ? 0 : inputValue.length,
        inputValue.length,
      );
    }
  };

  /**
   * @param {Date} newDate
   */
  const handleChangeDate = (newDate) => {
    // Close the popper and update the text field
    setOpen(false);
    setInputValue(formatDate(newDate));
    focusInput(false);

    // Dispatch callbacks
    onChange(newDate);
  };

  useKey('Tab', () => {
    // Wrap the handler in setTimeout so that document.activeElement is the focused element *after* pressing Tab.
    setTimeout(() => {
      if (document.activeElement === inputRef.current) {
        // Open up the popper if the user tabbed into the text field.
        if (!disabled) {
          setOpen(true);
          focusInput();
        }
      } else {
        setOpen(false);
      }
    }, 0);
  });

  /**
   * Handle the text field being clicked.
   *
   * Don't use the `onFocus` handler, because that makes it hard to keep the popper closed
   * while focusing the text field after a date is selected from the popper.
   *
   * If you *do* want to programmatically open the popper, try using the `simulateMouseClick()` utility.
   */
  const handleClickInput = () => {
    if (!disabled) {
      setOpen(true);
      focusInput();
    }
  };

  const handleChangeInput = (e) => {
    setWasInputChanged(true);
    setInputValue(e.target.value.trim());
  };

  const handleBlurInput = () => {
    const parsedDate = parseDate(inputValue);

    if (parsedDate) {
      const newInputValue = formatDate(parsedDate);
      const formattedDate = formatDate(date);

      // Format the date to MM-DD-YYYY if it's not already properly formatted.
      if (newInputValue !== inputValue) {
        setInputValue(newInputValue);
      }

      // Call onChange only if the new date has changed from the previous value.
      if (newInputValue !== formattedDate) {
        onChange(parseDate(newInputValue));
      }
    } else if (wasInputChanged) {
      // The input was manually edited, but the value is not a valid date.
      onChange(inputValue === '' ? null : 'Invalid Date');
    }

    setWasInputChanged(false);
  };

  const handleClose = (e) => {
    // Collapse the popper only if the user clicked outside the text input.
    if (e.target !== inputWrapperRef.current && e.target !== inputRef.current) {
      setOpen(false);
    }
  };

  // CalendarPicker requires minDate and maxDate to be Date objects.
  // Convert these props to Date objects if necessary.
  const parsedMinDate = minDate ? parseDate(minDate) : undefined;
  const parsedMaxDate = maxDate ? parseDate(maxDate) : undefined;

  return (
    <>
      <Input
        autoComplete="off"
        className={className}
        error={error}
        fullWidth={fullWidth}
        disabled={disabled}
        id={id}
        inputProps={{
          'aria-label': ariaLabel,
          'aria-labelledby': ariaLabelledby,
          'data-testid': dataTestId,
        }}
        data-pendo-id={dataPendoId}
        inputRef={inputRef}
        name={name}
        onBlur={handleBlurInput}
        onChange={handleChangeInput}
        onClick={handleClickInput}
        placeholder={placeholder}
        size={size}
        sx={[
          { width: fullWidth ? undefined : 125 },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        type="tel"
        value={inputValue}
        ref={inputWrapperRef}
      />
      <Popper
        anchorEl={inputWrapperRef.current}
        open={open}
        transition
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...PopperProps}
        style={{ zIndex: POPPER_Z_INDEX }}
      >
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Fade {...TransitionProps} exit={false} timeout={150}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <CalendarPicker
                  minDate={parsedMinDate}
                  maxDate={parsedMaxDate}
                  date={date}
                  onChange={handleChangeDate}
                />
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
}

BaseDatePicker.propTypes = {
  'aria-label': PropTypes.string,
  'aria-labelledby': PropTypes.string,
  'data-testid': PropTypes.string,
  'data-pendo-id': PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  PopperProps: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // Either a Date object or a string in the format "MM-DD-YYYY"
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

BaseDatePicker.defaultProps = {
  'aria-label': undefined,
  'aria-labelledby': undefined,
  'data-testid': undefined,
  'data-pendo-id': undefined,
  className: '',
  disabled: false,
  error: false,
  fullWidth: false,
  id: undefined,
  name: undefined,
  placeholder: 'mm-dd-yyyy',
  size: undefined,
  minDate: undefined,
  maxDate: undefined,
  sx: undefined,
  PopperProps: undefined,
  value: '',
};
