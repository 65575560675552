const pcbFilters = 'pcb-filters';
const filteredMgs = 'filtered-mgs';
const verifiedMedicalGroups = 'verified-medical-groups';
const featurePropertyMappings = 'feature-property-mappings';

const pcbQueryKeyFactory = {
  pcbFilters: () => {
    return [pcbFilters];
  },
  filteredMgs: (filters) => {
    if (!Object.keys(filters).length) {
      return [filteredMgs];
    }
    return [filteredMgs, filters];
  },
  verifiedMedicalGroups: ({ tins = undefined } = {}) => {
    if (!tins) {
      return [verifiedMedicalGroups];
    }
    return [verifiedMedicalGroups, { tins }];
  },
  featurePropertyMappings: () => {
    return [featurePropertyMappings];
  },
};

export default pcbQueryKeyFactory;
