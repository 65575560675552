import useId from '@mui/utils/useId';
import { Box, FormLabel, ThemeProvider } from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import themeV2 from '@/themeV2';
import BaseCheckbox from '@/components/BaseCheckbox';
import FormErrorMessage from '@/components/FormErrorMessage';

export default function ModalCheckbox({
  label,
  defaultValue,
  errorMsg,
  actionId,
  BoxSx,
  InputSx,
  name,
  control,
  errors,
  pendoIdPrefixer,
  labelPlacement,
  ...CheckboxProps
}) {
  const idPrefix = useId(actionId);
  return (
    <ThemeProvider theme={themeV2}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          my: 1,
          ...BoxSx,
        }}
      >
        {!!label && !labelPlacement && (
          //  if no labelPlacement is provided,
          //  we use the label as a group label (FormLabel component)
          //  otherwise we use it as a checkbox label (label prop to the Checkbox component)
          <FormLabel htmlFor={`${idPrefix}_${label}`} sx={{ mt: 'auto' }}>
            {label}
          </FormLabel>
        )}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={errorMsg ? { required: errorMsg } : {}}
          render={({ field }) => (
            <BaseCheckbox
              name={name}
              id={`${idPrefix}_${label}`}
              label={!!labelPlacement && !!label ? label : ''}
              onChange={(event, item) => {
                field.onChange(item);
              }}
              checked={field.value || false}
              data-pendo-id={pendoIdPrefixer(field.value)}
              sx={{ ml: 1, ...InputSx }}
              labelPlacement={labelPlacement}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...CheckboxProps}
            />
          )}
        />
      </Box>
      <FormErrorMessage name={name} errors={errors} />
    </ThemeProvider>
  );
}

ModalCheckbox.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  actionId: PropTypes.number,
  BoxSx: PropTypes.object,
  InputSx: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  pendoIdPrefixer: PropTypes.func,
  labelPlacement: PropTypes.string,
};

ModalCheckbox.defaultProps = {
  label: undefined,
  defaultValue: undefined,
  errorMsg: undefined,
  actionId: undefined,
  BoxSx: undefined,
  InputSx: { alignItems: 'flex-start' },
  pendoIdPrefixer: () => {},
  labelPlacement: 'end',
};
