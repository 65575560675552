import { useState } from 'react';
import { useDebounce } from 'react-use';

export default function useDebouncedValue<T>(value: T, timeout = 100) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    timeout,
    [value],
  );

  return debouncedValue;
}
