import PropTypes from 'prop-types';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import useSummaryData from '@/pages/PatientSummaryEhr/hooks/useSummaryData';
import { countIncompleteActions } from '@/pages/PatientSummaryEhr/hooks/useSummaryData/transformResponses';
import PatientSummaryModal from '@/components/PatientSummaryModal/components/PatientSummaryModal';

const today = new Date();

export default function PatientSummaryModalButton({ patientId }) {
  const [patientSummaryModalOpen, setPatientSummaryModalOpen] = useState(false);

  const patientSummaryModalOnOpen = () => setPatientSummaryModalOpen(true);
  const patientSummaryModalOnClose = () => setPatientSummaryModalOpen(false);

  const { actions, isLoading } = useSummaryData({
    patientId,
    dateOfService: today,
    fetchCompleted: false,
  });

  if (isLoading) {
    return <Skeleton height={70} sx={{ mx: 2 }} />;
  }

  const numIncompleteActions = countIncompleteActions(actions);

  if (numIncompleteActions <= 0) {
    return null;
  }
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        py={2}
        px={3}
        data-pendo-id="patient-summary-modal-button-row"
        borderBottom="1px solid"
        borderBottomColor="border.primaryActive"
      >
        <Typography component="span" variant="headermediumbold">
          {numIncompleteActions}
          <Typography component="span" variant="bodylarge">
            {' '}
            other incomplete action
            {numIncompleteActions > 1 ? 's' : null}
          </Typography>
        </Typography>
        <Button
          variant="secondary"
          onClick={patientSummaryModalOnOpen}
          size="medium"
          sx={{
            width: 110,
          }}
          data-pendo-id="patient-summary-modal-button"
        >
          View
        </Button>
      </Stack>
      <PatientSummaryModal
        onClose={patientSummaryModalOnClose}
        open={patientSummaryModalOpen}
        patientId={patientId}
      />
    </>
  );
}

PatientSummaryModalButton.propTypes = {
  patientId: PropTypes.number.isRequired,
};
