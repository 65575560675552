import {
  Box,
  FormLabel,
  Typography,
  formLabelClasses,
  inputClasses,
} from '@mui/material';
import { endOfYear, format, isValid, isWithinInterval, set } from 'date-fns';
import PropTypes from 'prop-types';
import useId from '@mui/utils/useId';
import styled from 'styled-components';

import RhfDatePicker from '@/components/reactHookFormComponents/RhfDatePicker';
import FormErrorMessage from '@/components/FormErrorMessage';
import { parseDate } from '@/components/DynamicForm/fieldComponents/DynamicFormDatePicker';
import { DISPLAY_DATE_FORMAT } from '@/pages/PatientSummary/utils/constants';

function isInvalidTimeRange(minDate, maxDate) {
  if (minDate === undefined || maxDate === undefined) return false;
  return parseDate(minDate) > parseDate(maxDate);
}

function getDateMessage(startDate, endDate, customDateMessage) {
  if (isInvalidTimeRange(startDate, endDate)) {
    return `This field is disabled because it has no valid time range.`;
  }
  if (customDateMessage !== undefined) {
    return customDateMessage;
  }

  // all spaces and hyphens non-breaking, so that the date range always appears on one line for easy reading
  const formattedMinDate = format(startDate, DISPLAY_DATE_FORMAT);
  const formattedEndDate = format(endDate, DISPLAY_DATE_FORMAT);
  // eslint-disable-next-line no-irregular-whitespace
  const formattedDateRange = `${formattedMinDate} ‑ ${formattedEndDate}`;

  return `To close this gap, the screening date must be in the range ${formattedDateRange}`;
}

/* Three types of quality action date ranges:
 * minDate: When entering a past date (e.g. for the Completed state),
 *                will accept between minDate and today. (In the format yyyy-mm-dd)
 * maxDate: [Optional] When entering a date (e.g. for the Completed state),
 *                will accept between minDate and maxDate. (In the format yyyy-mm-dd)
 * yearsBack: Most past dates use this. If we want a date between 1/1/xx and today,
 *            like "cancer screening within the last 3 calendar years."
 * isFutureDate: All future dates (e.g. for the Scheduled state) use this. Will
 *               accept any date in the next 12 months.
 *
 * Legacy Actions versus Step-Based Actions contain
 * different field properties for validation.
 *
 * Legacy Action: yearsBack, isFutureDate, minDate
 * Step-Based Action: minDate, maxDate
 */
function setDateRange(minDate, maxDate, customDateMessage) {
  if (minDate === undefined && maxDate === undefined) {
    return [undefined, undefined, undefined];
  }

  const realMinDate = minDate ?? new Date(1900, 0, 1);
  const realMaxDate = maxDate ?? endOfYear(new Date());
  const dateMessage = getDateMessage(
    realMinDate,
    realMaxDate,
    customDateMessage,
  );

  // Make the range include the whole min and max day
  const flooredMinDate = set(realMinDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const ceilingedMaxDate = set(realMaxDate, {
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 999,
  });

  return [flooredMinDate, ceilingedMaxDate, dateMessage];
}

export default function ModalDatePicker({
  label,
  defaultValue,
  errorMsg,
  disabled: disabledProp,
  actionId,
  BoxSx,
  InputSx,
  customDateMessage,
  minDate,
  maxDate,
  name,
  control,
  errors,
}) {
  const idPrefix = useId(actionId);
  const [finalMinDate, finalMaxDate, dateMessage] = setDateRange(
    minDate ? parseDate(minDate) : undefined,
    maxDate ? parseDate(maxDate) : undefined,
    customDateMessage,
  );
  const disabled = disabledProp || isInvalidTimeRange(minDate, maxDate);

  return (
    <>
      {dateMessage && (
        <Box>
          <Typography variant="bodybold" whiteSpace="pre-wrap">
            {dateMessage}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          mt: 1,
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          ...BoxSx,
        }}
      >
        <StyledFormLabel
          htmlFor={`${idPrefix}_${name}`}
          sx={{ mr: 1, mt: 1 }}
          disabled={disabled}
        >
          {label}
        </StyledFormLabel>
        <div>
          <StyledDatePicker
            control={control}
            name={name}
            defaultValue={defaultValue}
            disabled={disabled}
            rules={{
              required: errorMsg,
              validate: {
                isValidDate: (v) => !v || isValid(v) || 'Enter a valid date',
                isInBounds: (v) =>
                  !v ||
                  finalMinDate === undefined ||
                  isWithinInterval(v, {
                    start: finalMinDate,
                    end: finalMaxDate,
                  }) ||
                  'Enter a date within the correct range',
              },
              disabled,
            }}
            id={`${idPrefix}_${name}`}
            sx={InputSx}
            minDate={finalMinDate}
            maxDate={finalMaxDate}
          />
          <FormErrorMessage name={name} errors={errors} />
        </div>
      </Box>
    </>
  );
}

const StyledFormLabel = styled(FormLabel)`
  &.${formLabelClasses.disabled} {
    color: ${(p) => p.theme.palette.status.noneBackgroundHover};
  }
`;

const StyledDatePicker = styled(RhfDatePicker)`
  &.${inputClasses.disabled} {
    background-color: ${(p) => p.theme.palette.status.noneBackground};
  }
  input:disabled {
    color: ${(p) => p.theme.palette.status.noneForeground};
    -webkit-text-fill-color: ${(p) => p.theme.palette.status.noneForeground};
  }
`;

ModalDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  customDateMessage: PropTypes.string,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  actionId: PropTypes.number,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  disabled: PropTypes.bool,
  BoxSx: PropTypes.object,
  InputSx: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

ModalDatePicker.defaultProps = {
  errorMsg: undefined,
  actionId: undefined,
  defaultValue: '',
  disabled: false,
  BoxSx: undefined,
  InputSx: undefined,
  minDate: undefined,
  maxDate: undefined,
  customDateMessage: undefined,
};
