import * as dateFns from 'date-fns';

import register from '@/components/DynamicForm/register';
import {
  ELSE,
  actionExpiresAt,
  actionMinDate,
  addYears,
  beginningOfServiceYear,
  dateOfService,
  earliestOf,
  endOfServiceYear,
  ifNotAnyFieldEquals,
  latestOf,
  match,
  mostRecentClaimDate,
  patientDateOfBirth,
  referenceActionField,
  subYears,
  today,
  trimmed,
} from '@/pages/PatientSummary/utils/executionRequirementUtils';

const acvScheduledConstraints = {
  minDate: dateOfService,
  maxDate: addYears(dateOfService, 10),
};

const acvCompletedConstraints = {
  minDate: patientDateOfBirth,
  maxDate: dateOfService,
};

// The flu season runs from August 1 (month 7) of one year to March 31 (month 2) of the next year
// If the action was generated last year, we reference the season starting August 1 of the previous year
// If the action was generated this year, we reference the season starting August 1 of the current year
// At the time of creation, the Flu Vaccination expires at date is set to the following year end of July.
// Utilizing the expires at date year as reference
// we can always reference the previous August (month 7) as minDate
const fluMinDate =
  (fn) =>
  (...args) => {
    const year = fn(...args).getFullYear();
    return new Date(year - 1, 7, 1);
  };

// Utilizing the expires at date year as reference
// we can always reference the next March (month 2) as maxDate
// limit the user from being able to select a date in the future by choosing
// earliest of next March or today
const fluMaxDate =
  (fn) =>
  (...args) => {
    const year = fn(...args).getFullYear();
    return earliestOf(() => new Date(year, 2, 31), today)();
  };

const adultBmiCalculation = () => {
  return register(['height', 'weight'], (formState) => {
    const { height, weight } = formState;
    if (
      height &&
      weight &&
      Number.isFinite(Number(height)) &&
      Number.isFinite(Number(weight))
    ) {
      const bmi = ((weight / (height * height)) * 703).toFixed(2);
      if (Number.isFinite(Number(bmi))) {
        return bmi;
      }
      return '';
    }
    return '';
  });
};

// Breast Cancer Screenings must have been addressed at the earliest
// October 1st 2 years ago
const breastCancerScreeningMinDate = () => {
  const octoberMonthIndex = 9; // month index for October where January == 0
  const twoYearsAgoToday = dateFns.subYears(new Date(), 2);
  return new Date(twoYearsAgoToday.getFullYear(), octoberMonthIndex, 1);
};

export default {
  adult_bmi: {
    complete: [
      {
        type: 'integer',
        label: 'Height (inches)',
        name: 'height',
        required: true,
      },
      {
        type: 'integer',
        label: 'Weight (pounds)',
        name: 'weight',
        required: true,
      },
      {
        type: 'integer',
        label: 'BMI',
        name: 'bmi',
        required: false,
        defaultValue: adultBmiCalculation(),
      },
      {
        type: 'integer',
        label: 'BMI Percentile',
        name: 'bmi_percentile',
        required: false,
      },
    ],
  },
  annual_care_visit: {
    scheduled: [
      {
        type: 'date',
        label: 'Date of Visit',
        name: 'date_of_service',
        required: true,
        ...acvScheduledConstraints,
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    postponed: [
      {
        type: 'integer',
        label: 'Postponed month',
        name: 'postponed_month',
        required: true,
        min: 1,
        max: 12,
      },
    ],
    removed: [],
    note: [
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    complete: [
      {
        type: 'date',
        label: 'Date of Visit',
        name: 'date_of_service',
        required: true,
        ...acvCompletedConstraints,
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    already_completed: [
      {
        type: 'date',
        label: 'Date of Visit',
        name: 'date_of_service',
        required: true,
        ...acvCompletedConstraints,
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    rescheduled: [
      {
        type: 'date',
        label: 'Date of Visit',
        name: 'date_of_service',
        required: true,
        ...acvScheduledConstraints,
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
      {
        type: 'dropdown',
        label: 'Reschedule reason',
        name: 'reschedule_reason',
        required: true,
        options: [
          ['default', '-----------'],
          ['patient_requested', 'Patient requested rescheduling'],
          ['patient_no_show', 'Patient was a no show'],
          ['provider_requested', 'Provider requested rescheduling'],
          ['other', 'Other (include details in notes)'],
        ],
      },
    ],
  },
  breast_cancer_screening: {
    complete: [
      {
        type: 'date',
        label: 'Date of Mammography',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: breastCancerScreeningMinDate,
      },
      {
        type: 'dropdown',
        label: 'Result',
        name: 'result_bool',
        required: true,
        options: [
          ['True', 'Positive'],
          ['False', 'Negative'],
        ],
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    scheduled: [
      {
        type: 'date',
        label: 'Scheduled or Expected Date Of Mammography',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: latestOf(breastCancerScreeningMinDate, dateOfService),
      },
      {
        type: 'referral',
        name: '__referral_fields',
        label: 'Refer to provider',
        providers: referenceActionField('preferredReferrals', []),
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    bilateral_mastectomy: [
      {
        type: 'date',
        label: 'Date of Bilateral Mastectomy',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: patientDateOfBirth,
      },
    ],
    patient_refuses: [],
    rescheduled: [
      {
        type: 'date',
        label: 'Scheduled or Expected Date Of Mammography',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: latestOf(breastCancerScreeningMinDate, dateOfService),
      },
      {
        type: 'referral',
        name: '__referral_fields',
        label: 'Refer to provider',
        providers: referenceActionField('preferredReferrals', []),
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    upload_chart: [
      {
        type: 'date',
        label: 'Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: subYears(dateOfService, 9),
      },
      {
        type: 'fileupload',
        label: 'File',
        name: 'file',
        required: true,
      },
    ],
  },
  cervical_cancer_screening: {
    complete: [
      {
        type: 'dropdown',
        label: 'Test Type',
        name: 'test_type',
        required: true,
        options: [
          ['pap', 'PAP Test'],
          ['hpv', 'Dual PAP/HPV Test'],
        ],
      },
      {
        type: 'label',
        name: '__dos_help_text',
        // Question: could this be JSX?
        label: trimmed`
          Valid date range by test type is as follows:
            PAP: This year or the prior 2 years
            Dual PAP/HPV: This year or the prior 4 years`,
      },
      {
        type: 'date',
        label: 'Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        // prettier-ignore
        minDate: match('test_type', {
          pap:    subYears(dateOfService, 2),
          hpv:    subYears(dateOfService, 4),
          [ELSE]: subYears(dateOfService, 2),
        }),
      },
      {
        type: 'dropdown',
        label: 'Result',
        name: 'result_bool',
        required: true,
        options: [
          ['True', 'Positive'],
          ['False', 'Negative'],
        ],
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    scheduled: [
      {
        type: 'date',
        label: 'Scheduled or Expected Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: dateOfService,
      },
      {
        type: 'dropdown',
        label: 'Test Type',
        name: 'test_type',
        required: true,
        options: [
          ['pap', 'PAP Test'],
          ['hpv', 'Dual PAP/HPV Test'],
        ],
      },
      {
        type: 'referral',
        name: '__referral_fields',
        label: 'Refer to provider',
        providers: referenceActionField('preferredReferrals', []),
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    hysterectomy: [
      {
        type: 'date',
        label: 'Date of Hysterectomy',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: patientDateOfBirth,
      },
    ],
    patient_refuses: [],
    rescheduled: [
      {
        type: 'date',
        label: 'Scheduled or Expected Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: dateOfService,
      },
      {
        type: 'dropdown',
        label: 'Test Type',
        name: 'test_type',
        required: true,
        options: [
          ['pap', 'PAP Test'],
          ['hpv', 'Dual PAP/HPV Test'],
        ],
      },
      {
        type: 'referral',
        name: '__referral_fields',
        label: 'Refer to provider',
        providers: referenceActionField('preferredReferrals', []),
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    upload_chart: [
      {
        type: 'date',
        label: 'Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: subYears(dateOfService, 9),
      },
      {
        type: 'fileupload',
        label: 'File',
        name: 'file',
        required: true,
      },
    ],
  },
  chlamydia_screening: {
    complete: [
      {
        type: 'date',
        label: 'Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
      {
        type: 'dropdown',
        label: 'Test Type',
        name: 'test_type',
        required: true,
        options: [
          ['urine', 'Urine Test'],
          ['swab', 'Swab Test'],
        ],
      },
      {
        type: 'dropdown',
        label: 'Result',
        name: 'result_bool',
        required: true,
        options: [
          ['True', 'Positive'],
          ['False', 'Negative'],
        ],
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    patient_refuses: [],
    scheduled: [
      {
        type: 'date',
        label: 'Scheduled or Expected Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: dateOfService,
      },
      {
        type: 'dropdown',
        label: 'Test Type',
        name: 'test_type',
        required: true,
        options: [
          ['urine', 'Urine Test'],
          ['swab', 'Swab Test'],
        ],
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    rescheduled: [
      {
        type: 'date',
        label: 'Scheduled or Expected Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: dateOfService,
      },
      {
        type: 'dropdown',
        label: 'Test Type',
        name: 'test_type',
        required: true,
        options: [
          ['urine', 'Urine Test'],
          ['swab', 'Swab Test'],
        ],
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    not_sexually_active: [],
  },
  colorectal_cancer_screening: {
    complete: [
      {
        type: 'dropdown',
        label: 'Test Type',
        name: 'test_type',
        required: true,
        options: [
          ['fit', 'iFOBT (FIT FOBT)'],
          ['fobt', 'FOBT (Guaiac)'],
          ['cologuard', 'FIT-DNA (Cologuard)'],
          ['flexsig', 'FlexSig'],
          ['ct_colonography', 'CT Colonography'],
          ['colonoscopy', 'Colonoscopy'],
        ],
      },
      {
        type: 'label',
        name: '__dos_help_text',
        // Question: could this be JSX?
        label: trimmed`
          Valid date range by test type is as follows:
            iFOBT: This year
            FOBT: This year
            FIT-DNA: This year or the prior 2 years
            FlexSig: This year or the prior 4 years
            CT Colonography: This year or the prior 4 years
            Colonoscopy: This year or the prior 9 years
        `,
      },
      {
        type: 'date',
        label: 'Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        // prettier-ignore
        minDate: match('test_type', {
          fit:             beginningOfServiceYear,
          fobt:            beginningOfServiceYear,
          cologuard:       subYears(beginningOfServiceYear, 2),
          flexsig:         subYears(beginningOfServiceYear, 4),
          ct_colonography: subYears(beginningOfServiceYear, 4),
          colonoscopy:     subYears(beginningOfServiceYear, 9),
          [ELSE]:          beginningOfServiceYear,
        }),
      },
      {
        type: 'dropdown',
        label: 'Test result',
        name: 'result_bool',
        required: true,
        options: [
          ['True', 'Positive'],
          ['False', 'Negative'],
        ],
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    patient_refuses: [],
    scheduled: [
      {
        type: 'date',
        label: 'Scheduled or Expected Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: dateOfService,
      },
      {
        type: 'dropdown',
        label: 'Test Type',
        name: 'test_type',
        required: true,
        options: [
          ['fit', 'iFOBT (FIT FOBT)'],
          ['fobt', 'FOBT (Guaiac)'],
          ['cologuard', 'FIT-DNA (Cologuard)'],
          ['flexsig', 'FlexSig'],
          ['ct_colonography', 'CT Colonography'],
          ['colonoscopy', 'Colonoscopy'],
        ],
      },
      {
        type: 'referral',
        name: '__referral_fields',
        label: 'Refer to provider',
        visible: match('test_type', {
          flexsig: true,
          ct_colonography: true,
          colonoscopy: true,
          [ELSE]: false,
        }),
        providers: referenceActionField('preferredReferrals', []),
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    rescheduled: [
      {
        type: 'date',
        label: 'Scheduled or Expected Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: dateOfService,
      },
      {
        type: 'dropdown',
        label: 'Test Type',
        name: 'test_type',
        required: true,
        options: [
          ['fit', 'iFOBT (FIT FOBT)'],
          ['fobt', 'FOBT (Guaiac)'],
          ['cologuard', 'FIT-DNA (Cologuard)'],
          ['flexsig', 'FlexSig'],
          ['ct_colonography', 'CT Colonography'],
          ['colonoscopy', 'Colonoscopy'],
        ],
      },
      {
        type: 'referral',
        name: '__referral_fields',
        label: 'Refer to provider',
        visible: match('test_type', {
          flexsig: true,
          ct_colonography: true,
          colonoscopy: true,
          [ELSE]: false,
        }),
        providers: referenceActionField('preferredReferrals', []),
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    colorectal_cancer: [
      {
        type: 'date',
        label: 'Date of Colorectal Cancer Diagnosis',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: patientDateOfBirth,
      },
    ],
    total_colectomy: [
      {
        type: 'date',
        label: 'Date of Total Colectomy',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: patientDateOfBirth,
      },
    ],
    upload_chart: [
      {
        type: 'date',
        label: 'Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: subYears(dateOfService, 9),
      },
      {
        type: 'fileupload',
        label: 'File',
        name: 'file',
        required: true,
      },
    ],
  },
  depression_screening: {
    complete: [
      {
        type: 'date',
        label: 'Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
      {
        type: 'dropdown',
        label: 'Result',
        name: 'result_bool',
        required: ifNotAnyFieldEquals([
          ['depression_screening_patient_refused', true],
          ['depression_screening_patient_has_bipolar_or_depression', true],
        ]),
        options: [
          ['True', 'Positive'],
          ['False', 'Negative'],
        ],
      },
      {
        type: 'dropdown',
        label: 'Was a follow up documented?',
        name: 'depression_screening_followup_plan_documented',
        required: true,
        visible: register(
          ['result_bool'],
          ({ result_bool: result }) => result === 'True',
        ),
        options: [
          ['True', 'Yes'],
          ['False', 'No'],
        ],
      },
      {
        type: 'checkboxlist',
        label: 'Screening not completed',
        name: 'depression_screening_not_completed_reason',
        required: false,
        options: [
          ['depression_screening_patient_refused', 'Patient Refused'],
          [
            'depression_screening_patient_has_bipolar_or_depression',
            'Patient already has active diagnosis of Bipolar or Depression',
          ],
        ],
      },
    ],
  },
  diabetes_eye_exam: {
    scheduled: [
      {
        type: 'date',
        label: 'Scheduled Or Expected Date of Eye Exam',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: dateOfService,
      },
      {
        type: 'referral',
        name: '__referral_fields',
        label: 'Refer to optometrist',
        required: false,
        hasFirstAndLastNames: true,
        providers: referenceActionField('preferredReferrals', []),
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    rescheduled: [
      {
        type: 'date',
        label: 'Scheduled Or Expected Date of Eye Exam',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: dateOfService,
      },
      {
        type: 'referral',
        name: '__referral_fields',
        label: 'Refer to optometrist',
        required: false,
        hasFirstAndLastNames: true,
        providers: referenceActionField('preferredReferrals', []),
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    complete: [
      {
        type: 'date',
        label: 'Date of Eye Exam',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: subYears(beginningOfServiceYear, 1),
      },
      {
        type: 'dropdown',
        label: 'Result',
        name: 'result_bool',
        required: true,
        options: [
          ['True', 'Positive'],
          ['False', 'Negative'],
        ],
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    diagnosis_incorrect: [],
    upload_chart: [
      {
        type: 'date',
        label: 'Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: subYears(beginningOfServiceYear, 1),
      },
      {
        type: 'fileupload',
        label: 'File',
        name: 'file',
        required: true,
      },
    ],
    patient_refuses: [
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
  },
  diabetes_hba1c_control: {
    complete: [
      {
        type: 'date',
        label: 'Date of HbA1C Test',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
      {
        type: 'integer',
        label: 'Exam result',
        name: 'result_value',
        required: true,
      },
    ],
    diagnosis_incorrect: [],
    upload_chart: [
      {
        type: 'label',
        label: 'Please upload the results from the latest HbA1C test',
      },
      {
        type: 'fileupload',
        label: 'File',
        name: 'file',
        required: true,
      },
      {
        type: 'date',
        label: 'Date of HbA1C Test',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
      {
        type: 'integer',
        label: 'Exam result',
        name: 'result_value',
        required: true,
      },
    ],
  },
  diabetic_kidney_evaluation: {
    complete: [
      {
        type: 'date',
        label: 'eGFR Test',
        name: 'egfr_date',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
      {
        type: 'date',
        label: 'uACR Test',
        name: 'uacr_date',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
    ],
    diagnosis_incorrect: [],
  },
  diabetes_kidney_screening: {
    complete: [
      {
        type: 'date',
        label: 'Date of Kidney Screening',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
    ],
    diagnosis_incorrect: [],
    ace_arb_medication_dispensed: [],
  },
  dmard_prescription: {
    complete: [
      {
        type: 'date',
        label: 'Date of DMARD Prescription or Administration',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: subYears(beginningOfServiceYear, 1),
      },
      {
        type: 'label',
        label: 'Provider who Administered or Prescribed',
      },
      {
        type: 'text',
        name: 'dmard_provider_first_name',
        label: 'First Name',
        required: true,
        maxLength: 100,
      },
      {
        type: 'text',
        name: 'dmard_provider_last_name',
        label: 'Last Name',
        required: true,
        maxLength: 100,
      },
    ],
  },
  enrollment_bhi: {
    patient_consented: [
      {
        type: 'text',
        label: 'Phone number',
        name: 'phone_number_input',
        required: true,
      },
    ],
    patient_not_interested: [],
    enrollment_not_appropriate: [],
  },
  enrollment_ccm: {
    patient_consented: [
      {
        type: 'text',
        label: 'Phone number',
        name: 'phone_number_input',
        required: true,
      },
    ],
    patient_not_interested: [],
    enrollment_not_appropriate: [],
  },
  enrollment_hc: {
    patient_consented: [
      {
        type: 'text',
        label: 'Phone number',
        name: 'phone_number_input',
        required: true,
      },
    ],
    patient_not_interested: [],
    enrollment_not_appropriate: [],
  },
  enrollment_rpm: {
    patient_consented: [
      {
        type: 'text',
        label: 'Phone number',
        name: 'phone_number_input',
        required: true,
      },
    ],
    patient_not_interested: [],
    enrollment_not_appropriate: [],
  },
  enrollment_prc: {
    patient_consented: [
      {
        type: 'text',
        label: 'Phone number',
        name: 'phone_number_input',
        required: true,
      },
    ],
    patient_not_interested: [],
    enrollment_not_appropriate: [],
  },
  enrollment_livio: {
    patient_consented: [
      {
        type: 'text',
        label: 'Phone number',
        name: 'phone_number_input',
        required: true,
      },
    ],
    patient_not_interested: [],
    enrollment_not_appropriate: [],
  },
  frequent_ed: {
    // TODO: For all of the TODOs in frequent_ed, see this Asana ticket for more details:
    // TODO: https://app.asana.com/0/1203323797202146/1203364675465251
    scheduled: [
      // TODO: Should display read-only category
      {
        type: 'date',
        label: 'Scheduled Date',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: dateOfService,
      },
    ],
    rescheduled: [
      // TODO: Should display read-only category
      {
        type: 'date',
        label: 'Scheduled Date',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: dateOfService,
      },
    ],
    log_visit: [
      // TODO: Should display category, next scheduled visit, key factor notes, clinical action plan and visit notes
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: true,
        maxLength: 1000,
      },
      {
        type: 'date',
        label: 'Date of Service',
        name: 'pcp_visit_date_of_service',
        required: true,
        customDateMessage: '',
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: subYears(beginningOfServiceYear, 1),
      },
    ],
    setup: [
      // TODO: Category must have a default value based on the action ppa_category
      {
        type: 'dropdown',
        label: 'Category',
        name: 'ppa_category',
        required: true,
        options: [
          ['high_admission_risk', 'High Admission Risk'],
          ['high_ed_utilization', 'High ED Utilization'],
          ['high_medication_count', 'High Medication Count'],
          ['high_specialist_utilization', 'High Specialist Utilization'],
          ['medication_adherence_issues', 'Medication Adherence Issues'],
          ['quality_opportunities', 'Quality Opportunities'],
        ],
      },
      // TODO: Either Key Factors or Other must be provided
      {
        type: 'checkboxlist',
        label: 'Key Factors',
        name: 'frequent_ed_key_factor',
        required: false,
        options: [
          [
            'frequent_ed_key_factor_clinical_diagnosis',
            'Clinical Diagnosis / Behavioral Health',
          ],
          ['frequent_ed_key_factor_environmental_needs', 'Environmental Needs'],
          ['frequent_ed_key_factor_clinician_input', 'Clinician Input'],
          ['frequent_ed_key_factor_psychosocial_needs', 'Psychosocial Needs'],
          ['frequent_ed_key_factor_functional_needs', 'Functional Needs'],
        ],
      },
      // TODO: Other must have a default value based on the action
      {
        type: 'text',
        name: 'frequent_ed_key_factor_other',
        label: 'Other',
        required: false,
        maxLength: 100,
      },
      // TODO: Clinical Action Plan must have a default value based on the action
      {
        type: 'textarea',
        name: 'frequent_ed_clinical_action_plan',
        label: 'Clinical Action Plan',
        required: true,
        maxLength: 100,
      },
    ],
  },
  future_fall_risk: {
    complete: [
      {
        type: 'date',
        label: 'Date of Assessment',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
      {
        type: 'checkboxlist',
        label: '',
        name: 'assessment_not_completed_reason',
        required: false,
        options: [
          [
            'assessment_not_completed',
            'Assessment not completed: patient is not ambulatory',
          ],
        ],
      },
    ],
  },
  high_blood_pressure: {
    complete: [
      {
        type: 'label',
        InputSx: {
          fontWeight: 600,
        },
        label: 'Date must be after most recent hypertension diagnosis.',
      },
      {
        type: 'date',
        label: 'Date of BP Measurement',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: latestOf(
          subYears(beginningOfServiceYear, 1),
          mostRecentClaimDate,
        ),
      },
      {
        type: 'integer',
        label: 'Systolic BP',
        name: 'systolic_blood_pressure',
        required: true,
      },
      {
        type: 'integer',
        label: 'Diastolic BP',
        name: 'diastolic_blood_pressure',
        required: true,
      },
    ],
  },
  influenza_immunization: {
    complete: [
      {
        type: 'date',
        label: 'Date of Vaccination',
        name: 'date_of_service',
        required: true,
        maxDate: fluMaxDate(actionExpiresAt),
        minDate: fluMinDate(actionExpiresAt),
      },
      {
        type: 'checkboxlist',
        label: 'Vaccine Not Administered',
        name: 'influenza_immunization_not_administered_reason',
        required: false,
        options: [
          ['influenza_immunization_no_vaccine_allergic', 'Patient is allergic'],
          ['influenza_immunization_no_vaccine_declined', 'Patient declined'],
          [
            'influenza_immunization_no_vaccine_unavailable',
            'Vaccine not available',
          ],
        ],
      },
    ],
  },
  lead_screening: {
    complete: [
      {
        type: 'date',
        label: 'Date of Lead Screening Test',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(
          endOfServiceYear,
          today,
          addYears(patientDateOfBirth, 2),
        ),
        minDate: latestOf(
          patientDateOfBirth,
          subYears(beginningOfServiceYear, 1),
        ),
      },
      {
        type: 'dropdown',
        label: 'Result',
        name: 'result_bool',
        required: true,
        options: [
          ['True', 'Positive'],
          ['False', 'Negative'],
        ],
      },
    ],
    scheduled: [
      {
        type: 'date',
        label: 'Scheduled or Expected Date Of Lead Screening Test',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(patientDateOfBirth, 2),
        minDate: latestOf(patientDateOfBirth, dateOfService),
      },
    ],
    rescheduled: [
      {
        type: 'date',
        label: 'Scheduled or Expected Date Of Lead Screening Test',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(patientDateOfBirth, 2),
        minDate: latestOf(patientDateOfBirth, dateOfService),
      },
    ],
  },
  osteoporosis: {
    complete: [
      {
        type: 'dropdown',
        label: 'Action Taken',
        name: 'osteoporosis_action_taken',
        required: true,
        options: [
          ['bmd', 'BMD Administration'],
          ['rx', 'Rx Therapy'],
        ],
      },
      {
        type: 'date',
        label: 'Date of Osteo Rx Prescription or BMD Administration',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: actionMinDate,
      },
    ],
    scheduled: [
      {
        type: 'date',
        label: 'Scheduled Or Expected Date of BMD Administration',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: latestOf(actionMinDate, today),
      },
    ],
    rescheduled: [
      {
        type: 'date',
        label: 'Scheduled Or Expected Date of BMD Administration',
        name: 'date_of_service',
        required: true,
        maxDate: addYears(dateOfService, 1),
        minDate: latestOf(actionMinDate, today),
      },
    ],
    diagnosis_incorrect: [],
  },
  tobacco_cessation: {
    complete: [
      {
        type: 'date',
        label: 'Date of Screening',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: subYears(beginningOfServiceYear, 1),
      },
      {
        type: 'dropdown',
        label: 'Patient is a tobacco user',
        name: 'tobacco_cessation_is_user',
        required: true,
        options: [
          ['True', 'Yes'],
          ['False', 'No'],
        ],
      },
      {
        type: 'dropdown',
        label: 'Gave tobacco cessation intervention',
        name: 'tobacco_cessation_received_intervention',
        required: true,
        visible: register(
          ['tobacco_cessation_is_user'],
          ({ tobacco_cessation_is_user: user }) => user === 'True',
        ),
        options: [
          ['True', 'Yes'],
          ['False', 'No'],
        ],
      },
    ],
  },
  aco_scd: {
    enter_test_result: [
      {
        type: 'date',
        label: 'LDL-C test date',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: subYears(beginningOfServiceYear, 1),
      },
      {
        type: 'integer',
        label: 'LDL-C result',
        name: 'ldlc_test_result',
        required: true,
      },
      {
        type: 'checkboxlist',
        label: 'Consider prescribing statins to patient',
        name: 'consider_statins',
        minSelectionCount: 1,
        visible: register(
          ['ldlc_test_result'],
          ({ ldlc_test_result: result }, { action }) => {
            const resultInt = parseInt(result, 10);
            if (Number.isNaN(resultInt)) return false;
            return resultInt >= action.ldlcCutoffForNeedingStatin;
          },
        ),
        options: [
          ['drug_prescribed', 'Statin prescribed for patient'],
          [
            'may_prescribe_later',
            'Will consider prescribing statins at a later time',
          ],
          ['unable_to_prescribe', 'Unable to prescribe statins to patient'],
        ],
      },
      {
        type: 'dropdown',
        label: 'Unable to prescribe due to',
        name: 'unable_to_prescribe_reason',
        required: true,
        visible: register(
          ['unable_to_prescribe'],
          ({ unable_to_prescribe: unableToPrescribe }) =>
            Boolean(unableToPrescribe),
        ),
        options: [
          [
            'adverse_effect_allergy_or_intolerance',
            'Adverse effect, allergy, or intolerance',
          ],
          [
            'active_liver_or_hepatic_disease_or_insufficiency',
            'Liver or hepatic disease or insufficiency',
          ],
          ['end_stage_renal_disease', 'End stage renal disease'],
          ['other', 'Other'],
        ],
      },
    ],
    not_tested: [],
  },
  med_rasa: {
    non_adherent: [],
    addressed: [
      {
        type: 'text',
        name: 'medication_adherence_drug_name',
        label: 'Drug name',
        required: true,
        maxLength: 100,
      },
      {
        type: 'date',
        label: 'Date of writing script',
        name: 'date_of_service',
        required: true,
        customDateMessage: '',
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
      {
        type: 'checkboxlist',
        label: '',
        name: 'medication_action_unable_to_switch_checkbox',
        required: false,
        options: [['medication_action_unable_to_switch', 'Unable to switch']],
      },
      {
        type: 'checkboxlist',
        label: 'If unable to switch, select reason:',
        name: 'medication_action_no_switch_',
        required: false,
        visible: register(
          ['medication_action_unable_to_switch'],
          ({ medication_action_unable_to_switch: unable }) => unable === true,
        ),
        options: [
          [
            'medication_action_no_switch_titrating',
            'Currently titrating medication',
          ],
          [
            'medication_action_no_switch_cannot_afford',
            'Patient cannot afford 90-day script',
          ],
          ['medication_action_no_switch_other', 'Other (please describe)'],
        ],
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    no_longer_on_meds: [
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    at_risk: [],
    adherent: [],
  },
  med_diabetes: {
    non_adherent: [],
    addressed: [
      {
        type: 'text',
        name: 'medication_adherence_drug_name',
        label: 'Drug name',
        required: true,
        maxLength: 100,
      },
      {
        type: 'date',
        label: 'Date of writing script',
        name: 'date_of_service',
        required: true,
        customDateMessage: '',
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
      {
        type: 'checkboxlist',
        label: '',
        name: 'medication_action_unable_to_switch_checkbox',
        required: false,
        options: [['medication_action_unable_to_switch', 'Unable to switch']],
      },
      {
        type: 'checkboxlist',
        label: 'If unable to switch, select reason:',
        name: 'medication_action_no_switch_',
        required: false,
        visible: register(
          ['medication_action_unable_to_switch'],
          ({ medication_action_unable_to_switch: unable }) => unable === true,
        ),
        options: [
          [
            'medication_action_no_switch_titrating',
            'Currently titrating medication',
          ],
          [
            'medication_action_no_switch_cannot_afford',
            'Patient cannot afford 90-day script',
          ],
          ['medication_action_no_switch_other', 'Other (please describe)'],
        ],
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    no_longer_on_meds: [
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    at_risk: [],
    adherent: [],
  },
  med_statins: {
    non_adherent: [],
    addressed: [
      {
        type: 'text',
        name: 'medication_adherence_drug_name',
        label: 'Drug name',
        required: true,
        maxLength: 100,
      },
      {
        type: 'date',
        label: 'Date of writing script',
        name: 'date_of_service',
        required: true,
        customDateMessage: '',
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: beginningOfServiceYear,
      },
      {
        type: 'checkboxlist',
        label: '',
        name: 'medication_action_unable_to_switch_checkbox',
        required: false,
        options: [['medication_action_unable_to_switch', 'Unable to switch']],
      },
      {
        type: 'checkboxlist',
        label: 'If unable to switch, select reason:',
        name: 'medication_action_no_switch_',
        required: false,
        visible: register(
          ['medication_action_unable_to_switch'],
          ({ medication_action_unable_to_switch: unable }) => unable === true,
        ),
        options: [
          [
            'medication_action_no_switch_titrating',
            'Currently titrating medication',
          ],
          [
            'medication_action_no_switch_cannot_afford',
            'Patient cannot afford 90-day script',
          ],
          ['medication_action_no_switch_other', 'Other (please describe)'],
        ],
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    no_longer_on_meds: [
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    at_risk: [],
    adherent: [],
  },
  pediatric_annual_care_visit: {
    scheduled: [
      {
        type: 'date',
        label: 'Date of Visit',
        name: 'date_of_service',
        required: true,
        ...acvScheduledConstraints,
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    postponed: [
      {
        type: 'integer',
        label: 'Postponed month',
        name: 'postponed_month',
        required: true,
        min: 1,
        max: 12,
      },
    ],
    removed: [],
    note: [
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    complete: [
      {
        type: 'date',
        label: 'Date of Visit',
        name: 'date_of_service',
        required: true,
        ...acvCompletedConstraints,
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    already_completed: [
      {
        type: 'date',
        label: 'Date of Visit',
        name: 'date_of_service',
        required: true,
        ...acvCompletedConstraints,
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
    ],
    rescheduled: [
      {
        type: 'date',
        label: 'Date of Visit',
        name: 'date_of_service',
        required: true,
        ...acvScheduledConstraints,
      },
      {
        type: 'textarea',
        label: 'Notes',
        name: 'notes',
        required: false,
        maxLength: 100,
      },
      {
        type: 'dropdown',
        label: 'Reschedule reason',
        name: 'reschedule_reason',
        required: true,
        options: [
          ['default', '-----------'],
          ['patient_requested', 'Patient requested rescheduling'],
          ['patient_no_show', 'Patient was a no show'],
          ['provider_requested', 'Provider requested rescheduling'],
          ['other', 'Other (include details in notes)'],
        ],
      },
    ],
  },
  scd: {
    addressed: [],
    unable_to_prescribe: [],
    diagnosis_incorrect: [],
  },
  supd: {
    addressed: [],
    unable_to_prescribe: [],
    diagnosis_incorrect: [],
  },
  visit_checklist: {
    complete: [
      {
        type: 'date',
        label: 'Date of Visit',
        name: 'date_of_service',
        required: true,
        maxDate: earliestOf(endOfServiceYear, today),
        minDate: actionMinDate,
      },
      {
        type: 'checkboxlist',
        label: 'Please record what was discussed with the patient',
        name: 'checklist_items',
        required: false,
        options: [
          [
            'checklist_item_Discuss and address any difficulties in getting PCP appointment',
            'Discuss and address any difficulties in getting PCP appointment',
          ],
          [
            'checklist_item_Discuss and address any wait times patient has in seeing PCP',
            'Discuss and address any wait times patient has in seeing PCP',
          ],
          [
            'checklist_item_Discuss and address patient difficulty getting specialist referrals',
            'Discuss and address patient difficulty getting specialist referrals',
          ],
          [
            'checklist_item_Review and discuss patient care received from specialist',
            'Review and discuss patient care received from specialist',
          ],
          [
            'checklist_item_Review prescription medications with patient',
            'Review prescription medications with patient',
          ],
          [
            'checklist_item_Review test results with patient (blood test, x-ray, other)',
            'Review test results with patient (blood test, x-ray, other)',
          ],
          [
            'checklist_item_Assess fall risk and advise on fall prevention',
            'Assess fall risk and advise on fall prevention',
          ],
          [
            'checklist_item_Assess issues with bladder control or urine leakage',
            'Assess issues with bladder control or urine leakage',
          ],
          [
            'checklist_item_Assess exercise levels and recommend adjustments if necessary',
            'Assess exercise levels and recommend adjustments if necessary',
          ],
        ],
      },
      {
        type: 'checkboxlist',
        name: 'given',
        label: '',
        required: false,
        options: [
          [
            'given_to_patient',
            'Printed copy of completed Visit Checklist given, emailed, or mailed to patient',
          ],
        ],
      },
    ],
  },
};
