import { Box, FormLabel } from '@mui/material';
import PropTypes from 'prop-types';
import useId from '@mui/utils/useId';

import FormErrorMessage from '@/components/FormErrorMessage';
import RhfSelect from '@/components/reactHookFormComponents/RhfSelect';

export default function ModalSelect({
  label,
  defaultValue,
  options,
  errorMsg,
  actionId,
  BoxSx,
  InputSx,
  name,
  control,
  errors,
  inputProps,
  className,
}) {
  const idPrefix = useId(actionId);
  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        mt: 1,
        justifyContent: 'space-between',
        ...BoxSx,
      }}
    >
      <FormLabel htmlFor={`${idPrefix}_${name}`} sx={{ mr: 1, mt: 1 }}>
        {label}
      </FormLabel>
      <div style={{ width: InputSx.width ? InputSx.width : {} }}>
        <RhfSelect
          control={control}
          name={name}
          defaultValue={defaultValue}
          rules={{ required: errorMsg }}
          id={`${idPrefix}_${name}`}
          options={options}
          label={label}
          inputProps={inputProps}
          sx={InputSx}
        />
        <FormErrorMessage name={name} errors={errors} />
      </div>
    </Box>
  );
}

ModalSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  actionId: PropTypes.number,
  defaultValue: PropTypes.string,
  BoxSx: PropTypes.object,
  InputSx: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  inputProps: PropTypes.shape({ 'data-testid': PropTypes.string }),
  className: PropTypes.string,
};

ModalSelect.defaultProps = {
  actionId: undefined,
  defaultValue: '',
  BoxSx: undefined,
  errorMsg: undefined,
  InputSx: { width: 200 },
  inputProps: undefined,
  className: 'ModalSelect',
};
