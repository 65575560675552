export const INTERNAL_PERFORMANCE_METRIC_TO_COPY = {
  ACV: {
    title: 'Annual Visits',
    buttonCopy: 'Complete annual visits',
  },
  HCC: {
    title: 'Diagnosis',
    buttonCopy: 'Update diagnoses',
  },
  'Quality C': {
    title: 'Quality Screenings',
    buttonCopy: 'Address screenings',
  },
  'Quality D': {
    title: 'Medications',
    buttonCopy: 'Address prescriptions',
  },
};

export const InternalPerformanceMetric = {
  hcc: 'HCC',
  acv: 'ACV',
  qualityC: 'Quality C',
  qualityD: 'Quality D',
};
