import { Backdrop } from '@mui/material';
import PropTypes from 'prop-types';

import BaseSpinner from '@/components/BaseSpinner';

export default function SpinnerOverlay({ sx }) {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open
    >
      <BaseSpinner size={50} sx={{ ml: 1, ...sx }} />
    </Backdrop>
  );
}

SpinnerOverlay.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

SpinnerOverlay.defaultProps = {
  sx: undefined,
};
