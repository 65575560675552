import { useController } from 'react-hook-form';
import useId from '@mui/utils/useId';
import { endOfDay, format, isAfter, isBefore, isValid, parse } from 'date-fns';

import BaseDatePicker from '@/components/BaseDatePicker';
import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';

import DynamicFormLabel from '../DynamicFormLabel';
import FormErrorMessage from '../../FormErrorMessage';

const referenceDate = new Date();

export function parseDate(date) {
  if (date instanceof Date && isValid(date)) {
    return date;
  }
  const parsedDate = parse(date, 'yyyy-MM-dd', referenceDate);
  return isValid(parsedDate) ? parsedDate : undefined;
}

function formatDate(date) {
  return format(date, 'MM-dd-yyyy');
}

export default function DynamicFormDatePicker({ field }) {
  const { label, name, required, disabled, contextualInfo } = field;
  const parsedMinDate = parseDate(field.minDate);
  // Use endOfDay in case there's a time component on the field value.
  const parsedMaxDate = endOfDay(parseDate(field.maxDate));
  const defaultValue =
    contextualInfo && contextualInfo[name]
      ? parseDate(contextualInfo[name])
      : null;

  const controller = useController({
    name,
    rules: {
      required: required === true ? 'Enter a date' : required,
      validate: {
        isValidDate: (v) => {
          // if there is no value, then we need to return true in case this field is not required
          if (v) {
            return isValid(v) || 'Enter a date in the format MM-DD-YYYY';
          }
          return true;
        },
        isWithinMinBound: (v) => {
          if (parsedMinDate && isBefore(v, parsedMinDate)) {
            return `Enter a date no earlier than ${formatDate(parsedMinDate)}`;
          }
          return true;
        },
        isWithinMaxBound: (v) => {
          if (parsedMaxDate && isAfter(v, parsedMaxDate)) {
            return `Enter a date no later than ${formatDate(parsedMaxDate)}`;
          }
          return true;
        },
      },
    },
    defaultValue,
  });
  const fieldId = useId();

  return (
    <>
      <DynamicFormLabel htmlFor={fieldId}>{label}</DynamicFormLabel>
      <BaseDatePicker
        data-pendo-id={field.dataPendoId}
        id={fieldId}
        minDate={parsedMinDate}
        maxDate={parsedMaxDate}
        onChange={controller.field.onChange}
        value={controller.field.value}
        disabled={disabled}
        name={controller.field.name}
      />
      <FormErrorMessage errors={controller.formState.errors} name={name} />
    </>
  );
}

DynamicFormDatePicker.propTypes = {
  field: executionRequirementShape.isRequired,
};
