import { LinearProgress, Typography, styled } from '@mui/material';

export const CommonProgressText = styled(Typography)`
  font-size: ${(p) => p.theme.typography.metricsmall?.fontSize};
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

export const CommonProgressBar = styled(LinearProgress)`
  width: 54px;
  height: 8px;
  margin: auto;
  border-radius: ${(p) => 0.5 * p.theme.shape.borderRadius}px;
`;

export const CommonFirstColumn = styled(Typography)`
  font-size: ${(p) => p.theme.typography.bodymedium?.fontSize};
`;
