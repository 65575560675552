import PropTypes from 'prop-types';

export type FlagHistory = {
  id: number;
  userId: number;
  userFullName: string;
  flagStatus: string;
  isFlagged: boolean;
  createdAt: string;
  note: {
    createdAt: string;
    author: string;
    text: string;
  };
};

export type APIFlagHistory = {
  id: number;
  user_id: number;
  user_full_name: string;
  flag_status: string;
  is_flagged: boolean;
  created_at: string;
  note: {
    createdAt: string;
    author: string;
    text: string;
  };
};

export default function transformFlagHistory(
  flagHistory: APIFlagHistory,
): FlagHistory {
  return {
    id: flagHistory.id,
    userId: flagHistory.user_id,
    userFullName: flagHistory.user_full_name,
    flagStatus: flagHistory.flag_status,
    isFlagged: flagHistory.is_flagged,
    createdAt: flagHistory.created_at,
    note: flagHistory.note,
  };
}
export const flagHistoryShape = PropTypes.shape({
  id: PropTypes.number,
  userId: PropTypes.number,
  userFullName: PropTypes.string,
  flagStatus: PropTypes.string,
  isFlagged: PropTypes.bool,
  createdAt: PropTypes.string,
  note: PropTypes.shape({
    createdAt: PropTypes.string,
    author: PropTypes.string,
    text: PropTypes.string,
  }),
});
