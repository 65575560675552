import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import BaseAutocomplete from '@/components/BaseAutocomplete';

export default function RhfMultipleSelectAutocomplete({
  'data-pendo-id': dataPendoId,
  options,
  id,
  name,
  control,
  rules,
  disabled,
  getOptionLabel,
  isOptionEqualToValue,
  limitTags,
  disableCloseOnSelect,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <BaseAutocomplete
          data-pendo-id={dataPendoId}
          multiple
          autoHighlight
          autoSelect
          fullWidth
          data
          id={id}
          onChange={(event, item) => {
            field.onChange(item);
          }}
          options={options}
          getOptionLabel={getOptionLabel}
          placeholder="Select an option"
          value={field.value || []}
          disabled={disabled}
          sx={{
            m: 'auto',
            p: 0,
            backgroundColor: 'stellar.white',
          }}
          isOptionEqualToValue={isOptionEqualToValue}
          limitTags={limitTags}
          disableCloseOnSelect={disableCloseOnSelect}
        />
      )}
    />
  );
}

RhfMultipleSelectAutocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func.isRequired,
  limitTags: PropTypes.number,
  disableCloseOnSelect: PropTypes.bool,
  'data-pendo-id': PropTypes.string,
};
RhfMultipleSelectAutocomplete.defaultProps = {
  options: [],
  id: undefined,
  disabled: false,
  limitTags: undefined,
  disableCloseOnSelect: false,
  'data-pendo-id': undefined,
};
