import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

function ChallengeActionBarButton({
  label,
  icon,
  handleButtonClick,
  disabled,
  showCircularProgress,
  marginLeft,
  marginRight,
  'data-pendo-id': dataPendoId,
}) {
  return (
    <Box
      sx={{
        mt: 1,
        mb: 1,
        ml: marginLeft,
        mr: marginRight,
        position: 'relative',
      }}
    >
      <Button
        sx={{ my: 0, bgcolor: 'chocolate' }}
        disabled={disabled}
        onClick={handleButtonClick}
        data-pendo-id={dataPendoId}
      >
        {icon}
        {label}
      </Button>
      {showCircularProgress && (
        <CircularProgress
          size={24}
          sx={{
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
}

ChallengeActionBarButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  handleButtonClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showCircularProgress: PropTypes.bool,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  'data-pendo-id': PropTypes.string,
};

ChallengeActionBarButton.defaultProps = {
  icon: undefined,
  disabled: false,
  showCircularProgress: false,
  marginLeft: 1,
  marginRight: 1,
  'data-pendo-id': undefined,
};

export default ChallengeActionBarButton;
