import { styled } from '@mui/material';
import { any, bool, func } from 'prop-types';

import BaseCheckbox from '@/components/BaseCheckbox';

import DiagnosisDisplay from './DiagnosisDisplay';

export default function DiagnosisActionFormCheckbox({
  checked,
  diagnosis,
  onChange,
  disabled,
}) {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      label={<DiagnosisDisplay variant="bodymedium" diagnosis={diagnosis} />}
      disabled={disabled}
    />
  );
}
DiagnosisActionFormCheckbox.propTypes = {
  checked: bool.isRequired,
  diagnosis: any.isRequired,
  onChange: func.isRequired,
  disabled: bool,
};

DiagnosisActionFormCheckbox.defaultProps = {
  disabled: false,
};

export const Checkbox = styled(BaseCheckbox)`
  // The focus ring is very tight around the content, fix that.
  margin-left: -4px;
  padding-left: 4px;
  margin-right: 4px;
  padding-right: 4px;

  // Don't highlight text when rapidly clicking options
  user-select: none;

  /* These styles seem very disruptive. */
  .MuiCheckbox-root:focus-within {
    outline: none;
    box-shadow: none;
  }
  &:not(:first-of-type) {
    margin-top: 0;
  }
`;
